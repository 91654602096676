import React, { useRef, useState } from "react";
import LoaderButton from "./LoaderButton";
import SectionInfo from "./SectionInfo";
import TextButton from "./TextButton";
import "./OrgTokenPanel.css";

const helpUrl = "https://seed.run/docs/deploying-with-the-seed-cli";

export default function OrgTokenPanel({
  token,
  showing,
  enabling,
  removing,
  onShowClick,
  onHideClick,
  onEnableClick,
  onRemoveClick,
}) {
  const textareaRef = useRef(null);
  const [copyStatus, setCopyStatus] = useState(false);

  function handleCopy() {
    textareaRef.current.select();
    document.execCommand("copy");
    textareaRef.current.focus();
    setCopyStatus(true);
  }

  function handleBlur() {
    setCopyStatus(false);
  }

  function renderToken() {
    return (
      <div className="token">
        <input readOnly value={token} ref={textareaRef} onBlur={handleBlur} />
        <div>
          {document.queryCommandSupported("copy") && (
            <>
              {copyStatus && <span className="copied-status">Copied!</span>}
              <TextButton onClick={handleCopy}>Copy</TextButton>
            </>
          )}
        </div>
      </div>
    );
  }

  function renderEnableButton() {
    return (
      <LoaderButton bsSize="large" loading={enabling} onClick={onEnableClick}>
        Generate CLI Token
      </LoaderButton>
    );
  }

  function renderShowButton() {
    return (
      <LoaderButton
        bsSize="large"
        loading={enabling}
        onClick={() => onShowClick(true)}
      >
        Show CLI Token
      </LoaderButton>
    );
  }

  function renderControls() {
    return (
      <div className="controls">
        <LoaderButton
          bsSize="large"
          bsStyle="danger"
          loading={removing}
          onClick={onRemoveClick}
          text="Remove Token"
        />
        <LoaderButton
          bsStyle="link"
          text="Hide CLI Token"
          onClick={onHideClick}
        />
      </div>
    );
  }

  return (
    <div className="OrgTokenPanel">
      <SectionInfo
        label="Organization CLI Token"
        description={
          <span>
            Generate a token to use for the Seed CLI.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn about the Seed CLI.
            </a>
          </span>
        }
        button={!showing && (token ? renderShowButton() : renderEnableButton())}
      >
        {showing && (
          <>
            {renderToken()}
            {renderControls()}
          </>
        )}
      </SectionInfo>
    </div>
  );
}
