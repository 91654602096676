import React, { useState } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import SectionDescriptionLabel from "./SectionDescriptionLabel";
import SectionInfo from "./SectionInfo";
import TextButton from "./TextButton";
import { testOrgName } from "../lib/regexLib";
import { useFormReducer } from "../lib/hooksLib";
import "./OrgNameForm.css";

export default function OrgNameForm({
  name,
  isDefaultOrg = false,
  isMyDefaultOrg = false,

  saving = false,
  updated = false,

  onSaveClick,
}) {
  const [showStatus, setShowStatus] = useState(updated);
  const [
    { values: formData, isDirty, validation },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ name });

  const editing = isDirty.name;

  const displayCs = editing ? "" : "display";

  function canSubmit() {
    return isDirty.name === true && formData.name.trim() !== "";
  }

  function handleCancelClick() {
    setShowStatus(false);
    formDispatch({ type: "reset" });
  }

  function handleSaveClick(event) {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    const name = formData.name;

    if (!testOrgName(name)) {
      formDispatch({
        id: "name",
        type: "validate",
      });
      return;
    }

    onSaveClick(name);
  }

  function renderForm() {
    return (
      <>
        {isDefaultOrg && (
          <SectionDescriptionLabel>
            {isMyDefaultOrg && (
              <>
                You cannot change the name of your personal organization. If you
                want to change your username, you can do so from{" "}
                <Link to="/settings">your user settings</Link>.
              </>
            )}
            {!isMyDefaultOrg &&
              "You cannot change the name of a user's personal organization."}
          </SectionDescriptionLabel>
        )}
        <form className={displayCs} onSubmit={handleSaveClick}>
          <FormGroup controlId="name" validationState={validation.name}>
            <FormControl
              type="text"
              bsSize="large"
              disabled={saving || isDefaultOrg}
              value={formData.name}
              onChange={handleFieldChange}
            />
            {editing && validation.name && (
              <HelpBlock>
                Org names must start with a letter or number and can only
                contain lowercase letters, numbers, and hyphens.
              </HelpBlock>
            )}
          </FormGroup>
          {!editing && showStatus && (
            <p className="status">Org name updated!</p>
          )}
          {editing && (
            <div className="controls">
              <LoaderButton
                type="submit"
                bsSize="large"
                loading={saving}
                disabled={!canSubmit() || isDefaultOrg}
                onClick={handleSaveClick}
              >
                Update
              </LoaderButton>
              <TextButton onClick={handleCancelClick}>Cancel</TextButton>
            </div>
          )}
        </form>
      </>
    );
  }

  return (
    <div className="OrgNameForm">
      <SectionInfo label="Org Name">{renderForm()}</SectionInfo>
    </div>
  );
}
