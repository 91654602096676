import React, { Component } from "react";
import ItemDeletePanel from "../../components/ItemDeletePanel";
import { errorHandler, isAPIErrorWithCode } from "../../lib/errorLib";

const noop = () => {};

const STAGE_BUSY_ERROR = 2003;

const defaultProps = {
  type: "",
  itemName: "",
  onDelete: noop,
  disabled: false,
  disabledMessage: "",
};

const defaultState = {
  showing: false,
  deleting: false,
  stageBusyError: false,
};

class ItemDelete extends Component {
  state = { ...defaultState };

  handleShowClick = async (event) => {
    this.setState({ showing: true });
  };

  handleDeleteClick = async (event, fullRemove) => {
    this.setState({ deleting: true, stageBusyError: false });

    try {
      await this.props.onDelete(event, fullRemove);
    } catch (e) {
      if (isAPIErrorWithCode(e, STAGE_BUSY_ERROR)) {
        this.setState({
          deleting: false,
          stageBusyError: true,
        });
      } else {
        this.setState({ deleting: false });
        errorHandler(e);
      }
    }
  };

  handleHideClick = (event) => {
    this.setState(defaultState);
  };

  render() {
    const { disabled, disabledMessage } = this.props;
    const { showing, deleting, stageBusyError } = this.state;

    return (
      <ItemDeletePanel
        showing={showing}
        deleting={deleting}
        disabled={disabled}
        type={this.props.type}
        itemName={this.props.itemName}
        stageBusyError={stageBusyError}
        disabledMessage={disabledMessage}
        onShowClick={this.handleShowClick}
        onHideClick={this.handleHideClick}
        onDeleteClick={this.handleDeleteClick}
      />
    );
  }
}

ItemDelete.defaultProps = defaultProps;

export default ItemDelete;
