import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TextButton from "./TextButton";
import SectionInfo from "./SectionInfo";
import OrgGitModal from "./OrgGitModal";
import LoaderToggle from "./LoaderToggle";
import { getOrgBillingUrl } from "../lib/urlLib";
import { useFormReducer } from "../lib/hooksLib";
import "./OrgGitPanel.css";

const copyMapping = {
  github: {
    label: "GitHub Enterprise",
  },
  gitlab: {
    label: "GitLab Self-Managed",
  },
};

const helpUrl = {
  github: "https://seed.run/docs/enabling-github-enterprise",
  gitlab: "https://seed.run/docs/enabling-gitlab-self-managed",
};

export default function OrgGitPanel({
  type,
  owner,
  domain,
  clientId,
  available,

  editing,
  enabling,
  disabling,
  resetKey,
  onEditClick,
  onSaveClick,
  onCancelClick,
}) {
  const [modalKey, setModalKey] = useState(0);

  const [
    { values: formData },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ domain });

  useEffect(() => {
    formDispatch({ type: "reset" });
  }, [resetKey, formDispatch]);

  useEffect(() => {
    if (editing) {
      setModalKey((modalKey) => modalKey + 1);
    }
  }, [editing]);

  function handleChange(e) {
    handleFieldChange(e);

    if (e.target.checked) {
      onEditClick();
    } else {
      onSaveClick({ domain: null });
    }
  }

  return (
    <div className="OrgGitPanel">
      <SectionInfo
        label={copyMapping[type].label}
        description={
          <span>
            Enable {copyMapping[type].label} for your organization.&nbsp;
            <a target="_blank" href={helpUrl[type]} rel="noopener noreferrer">
              Learn more.
            </a>
          </span>
        }
      >
        {!available && (
          <p className="unavailable">
            Please{" "}
            <Link to={getOrgBillingUrl(owner)}>
              upgrade to the Enterprise plan
            </Link>{" "}
            to enable {copyMapping[type].label}.
          </p>
        )}
        <LoaderToggle
          id="domain"
          loading={disabling}
          onChange={handleChange}
          checked={formData.domain}
          disabled={!available}
        >
          Enable {copyMapping[type].label}
        </LoaderToggle>
        {domain && (
          <span className="edit">
            .&nbsp;
            <TextButton
              onClick={onEditClick}
              disabled={!available || enabling || disabling}
            >
              Edit settings
            </TextButton>
            .
          </span>
        )}
      </SectionInfo>

      <OrgGitModal
        key={`OrgGitModal-${modalKey}`}
        type={type}
        domain={domain}
        clientId={clientId}
        show={editing}
        saving={enabling}
        onCloseClick={onCancelClick}
        onSaveClick={onSaveClick}
      />
    </div>
  );
}
