import React, { useEffect } from "react";
import SectionInfo from "./SectionInfo";
import LoaderToggle from "./LoaderToggle";
import { useFormReducer } from "../lib/hooksLib";
import "./ReportSettingsPanel.css";

const helpUrl = "https://seed.run/docs/running-tests";

export default function ReportSettingsPanel({
  email,
  resetKey,
  reportType,
  updating = false,
  onUpdateClick,
}) {
  const [
    { values: formData },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ reportType });

  useEffect(() => {
    formDispatch({ type: "reset" });
  }, [resetKey, formDispatch]);

  function handleChange(e) {
    handleFieldChange(e);

    onUpdateClick(e.target.checked ? "weekly" : "no_report");
  }

  return (
    <div className="ReportSettingsPanel">
      <SectionInfo
        label="App Reports"
        description={
          <span>
            Email notification settings for App Reports.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn about App Reports.
            </a>
          </span>
        }
      >
        <LoaderToggle
          id="reportType"
          loading={updating}
          onChange={handleChange}
          checked={formData.reportType === "weekly"}
        >
          {reportType === "no_report" && "Receive weekly reports"}
          {reportType === "weekly" && (
            <>
              Weekly reports are emailed to{" "}
              <a href={`mailto:${email}`}>
                <b>{email}</b>
              </a>
            </>
          )}
        </LoaderToggle>
      </SectionInfo>
    </div>
  );
}
