import React from "react";
import { Button, Glyphicon, ButtonGroup } from "react-bootstrap";
import FaIcon from "./FaIcon";
import "./BuildHelpVoteButton.css";

export default function BuildHelpVoteButton({
  onVoteClick,
  voted = false,
  votingUp = false,
  votingDown = false,
  copy = "Did this help fix your build?",
  ...props
}) {
  return (
    <div className="BuildHelpVoteButton">
      {!voted && (
        <>
          <ButtonGroup>
            <Button
              disabled={votingUp || votingDown}
              onClick={(e) => onVoteClick(e, "up")}
            >
              {!votingUp && <FaIcon name="thumbs-up" />}
              {votingUp && <Glyphicon glyph="refresh" />}
            </Button>
            <Button
              disabled={votingUp || votingDown}
              onClick={(e) => onVoteClick(e, "down")}
            >
              {!votingDown && <FaIcon name="thumbs-down" />}
              {votingDown && <Glyphicon glyph="refresh" />}
            </Button>
          </ButtonGroup>
          <span className="copy">{copy}</span>
        </>
      )}
      {voted && (
        <span className="copy highlight">Thanks for the feedback!</span>
      )}
    </div>
  );
}
