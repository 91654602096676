import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import TextButton from "./TextButton";
import AccessLogSettingsPanel from "./AccessLogSettingsPanel";
import {
  stripProtocol,
  getAppStageApiLogsUrl,
  getAppStageApiMetricsUrl,
  getAppStageLambdaLogsUrl,
  getAppStageLambdaMetricsUrl,
} from "../lib/urlLib";
import "./ResourceItem.css";

export default function ResourceItem({
  type,
  region,
  resource,
  serviceId,
  updatingLogs,
  pathParams = {},
  onUpdateAccessLogClick,
}) {
  let render;

  const { ownerId, appId, appStageId } = pathParams;

  const textareaRef = useRef(null);
  const [showStatus, setShowStatus] = useState(false);

  function handleCopy() {
    textareaRef.current.select();
    document.execCommand("copy");
    textareaRef.current.focus();
    setShowStatus(true);
  }

  function handleBlur() {
    setShowStatus(false);
  }

  function renderEndpointLink(endpoint) {
    return (
      <a target="_blank" href={endpoint} rel="noopener noreferrer">
        {stripProtocol(endpoint)}
      </a>
    );
  }

  function renderOther(other) {
    return (
      <>
        <div>
          <p className="name">{other.name}</p>
          <p className="info">{other.type}</p>
        </div>
        <div></div>
      </>
    );
  }

  function renderOutput(output) {
    return (
      <>
        <div>
          <p className="name">{output.name}</p>
          <input
            readOnly
            ref={textareaRef}
            onBlur={handleBlur}
            value={output.value}
          />
        </div>
        <div>
          {document.queryCommandSupported("copy") && (
            <>
              {showStatus && <span className="copied-status">Copied!</span>}
              <TextButton onClick={handleCopy}>Copy</TextButton>
            </>
          )}
        </div>
      </>
    );
  }

  function renderApi(api) {
    const { apiId, stack, endpoint, domain_endpoint, access_log_status } = api;

    return (
      <>
        <div>
          <p className="name">
            {renderEndpointLink(domain_endpoint || endpoint)}
          </p>
          <div className="controls">
            <AccessLogSettingsPanel
              updating={updatingLogs}
              status={access_log_status}
              onEnableClick={() => onUpdateAccessLogClick(serviceId, true)}
              onDisableClick={() => onUpdateAccessLogClick(serviceId, false)}
            />
          </div>
        </div>
        <div>
          {access_log_status === "success" ? (
            <Link
              to={getAppStageApiLogsUrl(
                ownerId,
                appId,
                appStageId,
                region,
                stack,
                apiId
              )}
            >
              Access Logs
            </Link>
          ) : (
            <TextButton disabled>Access Logs</TextButton>
          )}
          <span className="separator">&#124;</span>
          <Link
            to={getAppStageApiMetricsUrl(
              ownerId,
              appId,
              appStageId,
              region,
              stack,
              apiId
            )}
          >
            Metrics
          </Link>
        </div>
      </>
    );
  }

  function renderWebsite(stage) {
    return (
      <div>
        <p className="name">
          {renderEndpointLink(stage.cfEndpointDomain || stage.cfEndpoint)}
        </p>
      </div>
    );
  }

  function renderLambda(lambda) {
    return (
      <>
        <div>
          {lambda.method && <p className="tag">{lambda.method}</p>}
          <p className="name">{lambda.uri || lambda.name}</p>
          <p className="info">{lambda.fullName}</p>
        </div>
        <div>
          <Link
            to={getAppStageLambdaLogsUrl(
              ownerId,
              appId,
              appStageId,
              region,
              lambda.fullName
            )}
          >
            Logs
          </Link>
          <span className="separator">&#124;</span>
          <Link
            to={getAppStageLambdaMetricsUrl(
              ownerId,
              appId,
              appStageId,
              region,
              lambda.fullName
            )}
          >
            Metrics
          </Link>
        </div>
      </>
    );
  }

  switch (type) {
    case "api":
      render = renderApi;
      break;
    case "website":
      render = renderWebsite;
      break;
    case "lambda":
      render = renderLambda;
      break;
    case "output":
      render = renderOutput;
      break;
    case "other":
      render = renderOther;
      break;
    default:
  }

  return <li className={`ResourceItem ${type} `}>{render(resource)}</li>;
}
