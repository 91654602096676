import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import StageRemoveServiceTable from "../components/StageRemoveServiceTable";
import ItemRemoveStatusPanel from "../components/ItemRemoveStatusPanel";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import LoadingSpinner from "../components/LoadingSpinner";
import SectionHeader from "../components/SectionHeader";
import ErrorAlert from "../components/ErrorAlert";
import useAPILoad from "../lib/apiLoadLib";
import { errorHandler, getLoadError } from "../lib/errorLib";
import { getHomeUrl, getStageServiceRemoveLogUrl } from "../lib/urlLib";
import { useRemoveStatusReducer } from "../lib/hooksLib";
import title from "../lib/titleLib";
import "./AppRemove.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
};

function AppRemove(props) {
  let isLoading = true;
  let loadError = null;

  let isCleaningUp;

  const { ownerId, appId } = props.match.params;

  const [isInitialRemoving, setIsInitialRemoving] = useState(false);
  const [removeStatus, statusDispatch] = useRemoveStatusReducer();

  useEffect(() => {
    document.title = title(appId);
  }, [appId]);

  const {
    error,
    data: removeInfo,
    reload: reloadRemoveInfo,
  } = useAPILoad(`/${ownerId}/${appId}/delete_status`, (path) =>
    props.invokeAppsApig({ path })
  );

  // Tracks if this is in the removing state initially
  useEffect(() => {
    if (removeInfo) {
      setIsInitialRemoving(true);
    }
  }, [removeInfo]);

  if (removeInfo !== null) {
    isLoading = false;

    const stagesData = getStagesBeingRemoved(removeInfo.stagesData);

    isCleaningUp = stagesData.length === 0;

    removeInfo.stagesData = stagesData;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  function getStagesBeingRemoved(stagesData) {
    return stagesData
      .map((stageDatum) => {
        stageDatum.servicesData = stageDatum.servicesData.filter(
          (serviceDatum) => serviceDatum.stage
        );
        return stageDatum;
      })
      .filter((stageDatum) => stageDatum.servicesData.length > 0);
  }

  /////////
  // API //
  /////////

  function removeApp(quickRemove) {
    return props.invokeAppsApig({
      method: "DELETE",
      path: `/${ownerId}/${appId}`,
      body: { mode: quickRemove ? "quick" : undefined },
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleAppRemove(quickRemove) {
    try {
      await removeApp(quickRemove);
      await reloadRemoveInfo();
      statusDispatch({ type: quickRemove ? "quick-removed" : "removed" });
    } catch (e) {
      errorHandler(e);
    }
  }

  function handleRemoveClick(e) {
    statusDispatch({ type: "removing" });
    handleAppRemove(false);
  }

  function handleShowRetryClick(e) {
    statusDispatch({ type: "show-retry" });
  }

  function handleHideRetryClick(e) {
    statusDispatch({ type: "hide-retry" });
  }

  function handleQuickRemoveClick(e) {
    statusDispatch({ type: "quick-removing" });
    handleAppRemove(true);
  }

  ////////////
  // Render //
  ////////////

  return (
    <div className="AppRemove">
      {isLoading && <LoadingSpinner />}

      {!isLoading && loadError && isInitialRemoving && (
        <Redirect to={getHomeUrl()} />
      )}

      {!isLoading && loadError && !isInitialRemoving && (
        <ContainerErrorPanel
          type="appRemove"
          code={loadError}
          context={{
            appName: appId,
          }}
        />
      )}

      {error && !loadError && <ErrorAlert error={error} />}

      {!isLoading && !loadError && removeInfo && (
        <>
          <ItemRemoveStatusPanel
            type="app"
            retry={removeStatus.retry}
            isCleaningUp={isCleaningUp}
            removing={removeStatus.removing}
            status={removeInfo.overallStatus}
            onRemoveClick={handleRemoveClick}
            onShowRetryClick={handleShowRetryClick}
            onHideRetryClick={handleHideRetryClick}
            quickRemoving={removeStatus.quickRemoving}
            onQuickRemoveClick={handleQuickRemoveClick}
            errorMessage={removeInfo.overallErrorMessage}
          />
          <div className="stages-status">
            {removeInfo.stagesData.length > 0 && (
              <SectionHeader>Stages</SectionHeader>
            )}
            {removeInfo.stagesData.map((stageDatum) => (
              <div key={stageDatum.appStage.name} className="stage-status">
                <h4>{stageDatum.appStage.name}</h4>
                <StageRemoveServiceTable
                  services={stageDatum.servicesData.map((serviceDatum) => ({
                    service_name: serviceDatum.service.service_name,
                    current_action: serviceDatum.stage.current_action,
                    errorMessage: serviceDatum.stage.removeErrorMessage,
                    link: getStageServiceRemoveLogUrl(
                      ownerId,
                      appId,
                      stageDatum.appStage.name,
                      serviceDatum.service.service_name
                    ),
                  }))}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(AppRemove));
