import React from "react";
import { Link } from "react-router-dom";
import { Glyphicon } from "react-bootstrap";
import ServiceIcon from "./ServiceIcon";
import FaIcon from "./FaIcon";
import "./PipelineTableServiceCell.css";

export default ({
  link,
  status,
  removeLink,
  serviceName,
  serviceType,
  mode = "large-left",
}) => {
  const isDeleting = status === "deleting";
  const isDeleteFailed = status === "delete_failed";
  const failCs = isDeleteFailed ? "btn-red-link" : "";
  const className = `PipelineTableServiceCell ${status} ${mode}`;

  return (
    <div className={className}>
      <Link
        className={failCs}
        to={isDeleting || isDeleteFailed ? removeLink : link}
      >
        <ServiceIcon
          type={serviceType}
          size={mode === "large-left" ? "large" : "small"}
        />
        {!isDeleting && !isDeleteFailed && (
          <span title={serviceName}>{serviceName}</span>
        )}
        {isDeleting && (
          <span title={serviceName}>
            <Glyphicon glyph="refresh" />
            {serviceName}
          </span>
        )}
        {isDeleteFailed && (
          <span title={serviceName}>
            <FaIcon name="exclamation-triangle" />
            {serviceName}
          </span>
        )}
      </Link>
    </div>
  );
};
