import React from "react";
import { Link } from "react-router-dom";
import { Glyphicon } from "react-bootstrap";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import ScrollShadowContainer from "./ScrollShadowContainer";
import PipelineTableStageCell from "./PipelineTableStageCell";
import PipelineTableBuildCell from "./PipelineTableBuildCell";
import PipelineTableServiceCell from "./PipelineTableServiceCell";
import PipelineTablePromoteCell from "./PipelineTablePromoteCell";
import PipelineTableStageStatusCell from "./PipelineTableStageStatusCell";
import {
  getServiceUrl,
  getAppActivityUrl,
  getServiceRemoveUrl,
  getAppStageRemoveUrl,
  getAppPipelineEditUrl,
} from "../lib/urlLib";
import "./AppPipelineTable.css";

export default function AppPipelineTable({
  app,
  onPromote,
  services = [],
  appStages = [],
  onManualDeploy,
  onCancelDeploy,
  cancellingBuilds,
  onAddServiceClick,
}) {
  if (services.length === 0) {
    return (
      <div className="AppPipelineTable empty">
        <p>Add a service to get started</p>
        <LoaderButton
          bsSize="large"
          bsStyle="warning"
          onClick={onAddServiceClick}
        >
          <FaIcon name="plus" />
          Add a Service
        </LoaderButton>
      </div>
    );
  }

  // Sort stages by dev/staging/prod
  const { dev, staging, prod } = appStages.reduce(
    (sortedStages, stage) => {
      if (stage.is_production) {
        sortedStages.prod.push(stage);
      } else if (stage.is_staging) {
        sortedStages.staging.push(stage);
      } else {
        sortedStages.dev.push(stage);
      }

      return sortedStages;
    },
    { dev: [], staging: [], prod: [] }
  );

  function isBuildInactive(deployment, latestStageBuild) {
    return (
      deployment &&
      deployment.successContext &&
      deployment.successContext.successType === "git_diff_deploy_skip"
    );
  }

  function renderSetting(key, value) {
    const onCs = value === true ? "on" : "";
    const disabledCs = value === null ? "disabled" : "";

    return (
      <div className={`setting ${disabledCs} ${onCs}`}>
        <span>{key}</span>
        <SectionHeader>
          {value === null ? "—" : value === true ? "On" : "Off"}
        </SectionHeader>
      </div>
    );
  }

  function renderAppSettings() {
    let deployBranch = app.branch_enabled || false;
    let deployPr = app.pr_enabled || false;

    if (app.git_provider === undefined || app.git_provider === null) {
      deployBranch = null;
      deployPr = null;
    }

    return (
      <>
        {renderSetting("Auto-Deploy Branches", deployBranch)}
        {renderSetting("Auto-Deploy PRs", deployPr)}
      </>
    );
  }

  function renderServiceStatus(
    serviceData,
    appStage,
    downstreamStages,
    isLastServiceRow
  ) {
    const service = serviceData.service;
    const stage = serviceData.stages.find((per) => per.name === appStage.name);
    const notDeleting =
      stage &&
      service.status !== "deleting" &&
      service.status !== "delete_failed" &&
      appStage.status !== "deleting" &&
      appStage.status !== "delete_failed";

    // Do not render if stage not found
    // (this happens when the stage is removed)
    return (
      <div key={service.service_name} className="stage-service">
        {notDeleting ? (
          <PipelineTableBuildCell
            service={service}
            appSlug={app.slug}
            appStage={appStage}
            onPromote={onPromote}
            dropup={isLastServiceRow}
            ownerName={app.owner_name}
            downstreamStages={downstreamStages}
            deployment={stage.latest_deployment}
            onManualDeploy={() => onManualDeploy(appStage, service)}
            inactive={isBuildInactive(
              stage.latest_deployment,
              appStage.latestBuild
            )}
          />
        ) : (
          <PipelineTableBuildCell empty />
        )}
      </div>
    );
  }

  function renderStageHeader(stage) {
    const { slug, owner_name } = app;
    const notDeleting =
      stage.status !== "deleting" && stage.status !== "delete_failed";
    const activity = notDeleting ? stage.latestBuild : null;

    return (
      <>
        <PipelineTableStageCell
          appStage={stage}
          status={stage.status}
          cancelling={cancellingBuilds[stage.name]}
          onManualDeploy={() => onManualDeploy(stage)}
          onCancelDeploy={() => onCancelDeploy(stage)}
          activityStatus={activity && activity.status}
          appStageRemoveLink={getAppStageRemoveUrl(
            owner_name,
            slug,
            stage.name
          )}
          appStageLink={getAppActivityUrl(owner_name, slug, {
            stage: stage.name,
          })}
        />
        <hr />
        {notDeleting ? (
          <PipelineTableStageStatusCell
            appSlug={slug}
            activity={activity}
            ownerName={owner_name}
          />
        ) : (
          <PipelineTableStageStatusCell />
        )}
      </>
    );
  }

  function renderStage(stage, downstreamStages, label, hasMore) {
    const isDeleting =
      stage.status === "deleting" || stage.status === "delete_failed";
    const cs =
      "stage-container" +
      (hasMore ? " more" : "") +
      (stage.is_production ? " prod" : "");

    return (
      <div key={stage.name} className={cs}>
        <div className="header">
          <SectionHeader>{label || <>&nbsp;</>}</SectionHeader>
          {hasMore && <Glyphicon glyph="arrow-right" />}
        </div>
        <div className="stage">
          <div className="header">{renderStageHeader(stage)}</div>
          <div className="body">
            {services.map((service, i) =>
              renderServiceStatus(
                service,
                stage,
                downstreamStages,
                i + 1 === services.length
              )
            )}
          </div>
          <div className="footer">
            <PipelineTablePromoteCell
              appStage={stage}
              onPromote={onPromote}
              isDeleting={isDeleting}
              latestBuild={stage.latestBuild}
              isProduction={stage.is_production}
              downstreamStages={downstreamStages}
              srcStage={stage.latestBuild && stage.latestBuild.src_stage_id}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderService(serviceData, i) {
    const service = serviceData.service;

    return (
      <div key={i} className="service">
        <PipelineTableServiceCell
          mode="small"
          status={service.status}
          serviceType={service.serviceType}
          serviceName={service.service_name}
          link={getServiceUrl(app.owner_name, app.slug, service.service_name)}
          removeLink={getServiceRemoveUrl(
            app.owner_name,
            app.slug,
            service.service_name
          )}
        />
      </div>
    );
  }

  function renderStages(stages, downstreamStages = []) {
    if (stages.length === 0) {
      return null;
    }

    const label = stages[0].is_production
      ? "Production"
      : stages[0].is_staging
      ? "Staging"
      : "Development";

    return stages.map((stage, i) =>
      renderStage(
        stage,
        downstreamStages,
        i === 0 && label,
        downstreamStages.length > 0 && i + 1 === stages.length
      )
    );
  }

  return (
    <div className="AppPipelineTable">
      <div className="pipelinetable-content">
        <div className="col1">
          <div className="header">
            <Link to={getAppPipelineEditUrl(app.owner_name, app.slug)}>
              <FaIcon name="pencil" />
              Edit Pipeline
            </Link>
            <hr />
            {renderAppSettings()}
          </div>
          <div className="services">{services.map(renderService)}</div>
        </div>
        <ScrollShadowContainer>
          <div className="col2">
            {renderStages(dev, staging.length > 0 ? staging : prod)}
            {renderStages(staging, prod)}
            {renderStages(prod)}
            {/** Hack to force right padding inside the scroll container **/}
            <div className="spacer-col"></div>
          </div>
        </ScrollShadowContainer>
      </div>
    </div>
  );
}
