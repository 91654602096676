import React, { Component } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { areFieldsNotEmpty, isPasswordValid } from "../lib/formLib";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import "./ResetPwConfirmForm.css";

const noop = () => {};

const defaultProps = {
  email: "",
  isConfirming: false,
  onConfirmClick: noop,
};

const defaultState = {
  formData: {
    code: "",
    password: "",
    confirmPassword: "",
  },
  validation: {},
};

export default class ResetPwConfirmForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
    };
  }

  formatFields(fields) {
    fields = { ...fields };
    delete fields.confirmPassword;

    return fields;
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      validation: {
        ...this.state.validation,
        [id]: null,
      },
    });
  };

  handleConfirmClick = async (event) => {
    event.preventDefault();

    const formData = { ...this.state.formData };

    formData.code = formData.code.trim();

    if (!areFieldsNotEmpty(formData)) {
      return;
    }

    const isPasswordLong = isPasswordValid(formData.password);
    const isPasswordConfirmed = formData.password === formData.confirmPassword;

    if (!(isPasswordLong && isPasswordConfirmed)) {
      const validation = {};
      if (!isPasswordLong) {
        validation.password = "error";
      }
      if (!isPasswordConfirmed) {
        validation.confirmPassword = "error";
      }

      this.setState({
        validation: {
          ...this.state.validation,
          ...validation,
        },
      });
      return;
    }

    await this.props.onConfirmClick(event, this.formatFields(formData));
  };

  render() {
    const { email, isConfirming } = this.props;
    const { formData, validation } = this.state;

    return (
      <form className="ResetPwConfirmForm" onSubmit={this.handleConfirmClick}>
        <FormGroup bsSize="large" controlId="code">
          <StyledControlLabel>Confirmation Code</StyledControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={formData.code}
            onChange={this.handleFieldChange}
          />
          <HelpBlock>
            Please check your email ({email}) for the confirmation code.
          </HelpBlock>
        </FormGroup>
        <hr />
        <FormGroup
          bsSize="large"
          controlId="password"
          validationState={validation.password}
        >
          <StyledControlLabel>New Password</StyledControlLabel>
          <FormControl
            type="password"
            autoComplete="new-password"
            value={formData.password}
            placeholder="Set a new password"
            onChange={this.handleFieldChange}
          />
          {validation.password && (
            <HelpBlock>
              Please use a password longer than 8 characters.
            </HelpBlock>
          )}
        </FormGroup>
        <FormGroup
          bsSize="large"
          controlId="confirmPassword"
          validationState={validation.confirmPassword}
        >
          <StyledControlLabel>Confirm Password</StyledControlLabel>
          <FormControl
            type="password"
            autoComplete="new-password"
            value={formData.confirmPassword}
            placeholder="Confirm your new password"
            onChange={this.handleFieldChange}
          />
          {validation.confirmPassword && (
            <HelpBlock>Please confirm your password.</HelpBlock>
          )}
        </FormGroup>
        <div className="controls">
          <LoaderButton
            type="submit"
            bsSize="large"
            bsStyle="warning"
            loading={isConfirming}
            onClick={this.handleConfirmClick}
          >
            Confirm
          </LoaderButton>
        </div>
      </form>
    );
  }
}

ResetPwConfirmForm.defaultProps = defaultProps;
