import React from "react";
import SectionHeader from "./SectionHeader";
import ResourceItem from "./ResourceItem";
import "./ApiResourceList.css";

export default function ApiResourceList({
  apis,
  pathParams,
  updatingLogs = {},
  onUpdateAccessLogClick,
}) {
  function renderEmpty(tab) {
    return (
      <div className="empty">There are no Lambdas deployed in this API.</div>
    );
  }

  function renderLambdas(lambdas) {
    return lambdas.length === 0 ? (
      renderEmpty()
    ) : (
      <ul className="lambdas">
        {lambdas.map((lambda) => (
          <ResourceItem
            type="lambda"
            resource={lambda}
            key={lambda.fullName}
            region={lambda.region}
            serviceId={lambda.serviceId}
            pathParams={pathParams}
          />
        ))}
      </ul>
    );
  }

  function renderApi(api) {
    return (
      <div key={api.stage.endpoint} className="api">
        <div className="header">
          <h4>{api.serviceName}</h4>
        </div>
        <ul className="endpoint">
          <ResourceItem
            type="api"
            resource={api.stage}
            pathParams={pathParams}
            region={api.stage.region}
            serviceId={api.serviceName}
            updatingLogs={updatingLogs[api.serviceName]}
            onUpdateAccessLogClick={onUpdateAccessLogClick}
          />
        </ul>
        <div className="separator">
          <hr />
          <SectionHeader>Lambdas</SectionHeader>
        </div>
        {renderLambdas(api.lambdas)}
      </div>
    );
  }

  return <div className="ApiResourceList">{apis.map(renderApi)}</div>;
}
