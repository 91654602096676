import React, { useEffect } from "react";
import SectionInfo from "./SectionInfo";
import LoaderToggle from "./LoaderToggle";
import { useFormReducer } from "../lib/hooksLib";
import "./AppUnitTestPanel.css";

const helpUrl = "https://seed.run/docs/running-tests";

export default function AppUnitTestPanel({
  saving,
  resetKey,
  onSaveClick,
  enabled = true,
}) {
  const [
    { values: formData },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ enabled });

  useEffect(() => {
    formDispatch({ type: "reset" });
  }, [resetKey, formDispatch]);

  function handleChange(e) {
    handleFieldChange(e);

    onSaveClick(e.target.checked);
  }

  return (
    <div className="AppUnitTestPanel">
      <SectionInfo
        label="Unit Tests"
        description={
          <span>
            Configure Seed to run your unit tests automatically.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn more about running tests.
            </a>
          </span>
        }
      >
        <LoaderToggle
          id="enabled"
          loading={saving}
          onChange={handleChange}
          checked={formData.enabled}
        >
          Run unit tests
        </LoaderToggle>
      </SectionInfo>
    </div>
  );
}
