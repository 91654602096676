import React from "react";
import { NavLink, useParams } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { sortStages } from "../lib/stagesLib";
import { getAppStageUrl } from "../lib/urlLib";
import "./StagesSelectList.css";

export default function StagesSelectList({ stages }) {
  const isLoading = !stages;
  const loadingCs = isLoading ? "loading" : "";

  const { ownerId, appId } = useParams();

  return (
    <div className={`StagesSelectList ${loadingCs}`}>
      {isLoading && <LoadingSpinner />}
      {!isLoading &&
        sortStages(stages, { filterDeleted: true }).map(({ name }) => (
          <NavLink key={name} to={getAppStageUrl(ownerId, appId, name)}>
            {name}
          </NavLink>
        ))}
    </div>
  );
}
