import React, { Component } from "react";
import {
  Form,
  Alert,
  HelpBlock,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Elements, StripeProvider } from "react-stripe-elements";
import withCancel from "../components/ComponentWithCancel";
import SectionInfo from "../components/SectionInfo";
import ScreenHeader from "../components/ScreenHeader";
import LoaderButton from "../components/LoaderButton";
import SectionHeader from "../components/SectionHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import StripeCardForm from "../components/StripeCardForm";
import PricingPlansPanel from "../components/PricingPlansPanel";
import CurrentUsagePanel from "../components/CurrentUsagePanel";
import withAppHeader from "../components/ComponentWithAppHeader";
import PricingAddonsPanel from "../components/PricingAddonsPanel";
import StyledControlLabel from "../components/StyledControlLabel";
import BillingHistoryTable from "../components/BillingHistoryTable";
import { errorHandler, formatStripeError } from "../lib/errorLib";
import { dateToFullDate, dateNextMonth } from "../lib/timeLib";
import { orgSettingsBreadcrumb } from "../lib/breadcrumbLib";
import title from "../lib/titleLib";
import {
  isFreePlan,
  isTeamPlan,
  isCustomPlan,
  isEnterprisePlan,
} from "../lib/billingLib";
import config from "../config";
import "./OrgBilling.css";

const helpUrl = "https://seed.run/pricing";

const defaultState = {
  stripe: null,
  invoices: [],
  planCost: null,
  usageLimit: null,
  seatsUsed: null,
  seatsLimit: null,
  currentInvoice: null,
  isLoading: true,
  promoCode: null,
  isUpdating: false,
  showCardForm: false,
  customPlan: null,
  billingEmail: null,
  currentPlanId: null,
  selectedPlanId: null,
  isInvoiceBillingEnabled: false,
  formData: {
    seats: 0,
    promo_code: "",
    GitHubEnterprise: false,
    GitLabEnterprise: false,
    SingleSignOn: false,
    PrioritySupport: false,
    SupportSLA: false,
    SupportSLA_v2: false,
    UptimeSLA: false,
  },
  validationError: null,
};

class OrgBilling extends Component {
  state = { ...defaultState };

  async componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(config.stripeKey) });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(config.stripeKey) });
      });
    }

    document.title = title("Billing");

    await this.loadBillingInfo();

    this.setState({ isLoading: false });
  }

  async loadBillingInfo() {
    try {
      const billingInfo = await this.getBilling();

      const currentPlanId = billingInfo.org.plan;
      const currentPlan =
        billingInfo.customPlan || config.pricingPlans[currentPlanId];
      const addons = this.addonsListToObject(billingInfo.planAddons);

      this.setState({
        currentPlanId,
        currentAddons: addons,
        customPlan: billingInfo.customPlan,
        planCost: billingInfo.org.planCost,
        invoices: billingInfo.past_invoices,
        promoCode: billingInfo.org.promoCode,
        seatsUsed: billingInfo.org.seatsUsed,
        seatsLimit: billingInfo.org.seatsLimit,
        usageLimit: billingInfo.org.minutesLimit,
        billingEmail: billingInfo.org.billingEmail,
        currentInvoice: billingInfo.current_invoice,
        isInvoiceBillingEnabled: billingInfo.org.isInvoiceBillingEnabled,
        formData: {
          ...this.state.formData,
          ...this.getInitialAddonsState(addons),
          seats: Math.max(billingInfo.org.seatsLimit, currentPlan.baseSeats),
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  }

  getSelectedPlanId() {
    return this.state.selectedPlanId || this.state.currentPlanId;
  }

  getPlanForId(planId) {
    return isCustomPlan(planId) && this.state.customPlan
      ? { ...config.pricingPlans[planId], ...this.state.customPlan }
      : config.pricingPlans[planId];
  }

  canShowUsage() {
    return (
      isFreePlan(this.state.currentPlanId) ||
      isTeamPlan(this.state.currentPlanId) ||
      isEnterprisePlan(this.state.currentPlanId)
    );
  }

  formatNumber(seats) {
    seats = parseFloat(seats, 10);

    return !isNaN(seats) && seats % 1 === 0 ? seats : null;
  }

  formatSelectedSeats(seats) {
    const selectedPlanId = this.getSelectedPlanId();
    const selectedPlan = this.getPlanForId(selectedPlanId);
    const { seatsUsed } = this.state;

    seats = this.formatNumber(seats);

    return seats !== null &&
      seats >= Math.max(seatsUsed, selectedPlan.baseSeats)
      ? seats
      : null;
  }

  canSubmitForm() {
    const selectedPlanId = this.getSelectedPlanId();

    return (isTeamPlan(selectedPlanId) || isEnterprisePlan(selectedPlanId)) &&
      this.formatSelectedSeats(this.state.formData.seats) === null
      ? false
      : true;
  }

  getGrandfatheredAddonIds() {
    return config.activePlanAddons.map((addon) =>
      this.state.currentAddons["SupportSLA"] && addon === "SupportSLA_v2"
        ? "SupportSLA"
        : addon
    );
  }

  addonsListToObject(addons = []) {
    const obj = {};

    addons.forEach((addon) => (obj[addon] = true));

    return obj;
  }

  getInitialAddonsState(addons) {
    return {
      GitHubEnterprise: !!addons.GitHubEnterprise,
      GitLabEnterprise: !!addons.GitLabEnterprise,
      SingleSignOn: !!addons.SingleSignOn,
      UptimeSLA: !!addons.UptimeSLA,
      PrioritySupport: !!addons.PrioritySupport,
      SupportSLA: !!addons.SupportSLA,
      SupportSLA_v2: !!addons.SupportSLA_v2,
    };
  }

  /////////
  // API //
  /////////

  getBilling() {
    const { ownerId } = this.props.match.params;
    return this.props.invokeApig({
      path: `/orgs/${ownerId}/billing/method`,
    });
  }

  setPlan(fields) {
    const { ownerId } = this.props.match.params;
    return this.props.invokeApig({
      method: "PUT",
      path: `/orgs/${ownerId}/billing/method?version=v20200910`,
      body: fields,
    });
  }

  //////////////
  // Handlers //
  //////////////

  handleFieldChange = (event) => {
    const { id, value, type, checked } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: type === "checkbox" ? checked : value,
      },
      validationError:
        id === "seats"
          ? this.formatSelectedSeats(value)
            ? null
            : "error"
          : this.state.validationError,
    });
  };

  handleAddonChange = (addon, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [addon]: value,
      },
    });
  };

  handleShowFormClick = (event) => {
    this.setState({ showCardForm: true });
  };

  handleCardCancel = (event) => {
    this.setState({ showCardForm: false });
  };

  handlePlanSelect = (event, selectedPlanId) => {
    const selectedPlan = this.getPlanForId(selectedPlanId);

    this.setState({
      selectedPlanId,
      // Reset any validation errors
      validationError: null,
      formData: {
        ...this.state.formData,
        ...this.getInitialAddonsState(this.state.currentAddons),
        seats: Math.max(this.state.seatsLimit, selectedPlan.baseSeats),
      },
    });
  };

  async handleUpdatePlan(fields) {
    this.setState({ isUpdating: true });

    try {
      await this.setPlan(fields);

      await this.loadBillingInfo();

      this.setState((prevState) => ({
        isUpdating: false,
        showCardForm: false,
        formData: {
          ...prevState.formData,
          promo_code: defaultState.formData.promo_code,
        },
      }));
    } catch (e) {
      errorHandler(e);
      this.setState({ isUpdating: false });
    }
  }

  handleCardSubmitFree = async () => {
    const plan = this.getSelectedPlanId();

    await this.handleUpdatePlan({ plan });
  };

  handleCardSubmitPaid = async ({ token, error }) => {
    if (error) {
      errorHandler(formatStripeError(error));
      return;
    }

    const selectedPlanId = this.getSelectedPlanId();
    const promo_code =
      this.state.formData.promo_code.trim() === ""
        ? null
        : this.state.formData.promo_code.trim();

    // build addons
    const addons = [];
    if (isEnterprisePlan(selectedPlanId)) {
      Object.keys(config.planAddons).forEach(
        (addon) => this.state.formData[addon] && addons.push(addon)
      );
    }

    await this.handleUpdatePlan({
      plan: selectedPlanId,
      promo_code,
      token: token && token.id,
      seat_count: this.state.formData.seats,
      addons: JSON.stringify(addons),
    });
  };

  ////////////
  // Render //
  ////////////

  renderFutureBillingInfo() {
    let isDirty,
      amount,
      usage,
      selectedAddons = [];

    const { formData, currentAddons } = this.state;

    const planAddons = config.planAddons;

    const currentPlanId = this.state.currentPlanId;
    const isCurrentFree = isFreePlan(currentPlanId);

    const billingDate = isCurrentFree
      ? dateToFullDate(dateNextMonth(new Date()))
      : dateToFullDate(this.state.currentInvoice.end_at);

    const selectedPlanId = this.getSelectedPlanId();
    const plan = this.getPlanForId(selectedPlanId);
    const seats = this.formatSelectedSeats(this.state.formData.seats);

    const isEnterprise = isEnterprisePlan(selectedPlanId);
    const isTeam = isTeamPlan(selectedPlanId);

    if (seats) {
      amount = plan.baseAmount + (seats - plan.baseSeats) * plan.perSeatAmount;
      isDirty =
        seats !== this.state.seatsLimit || currentPlanId !== selectedPlanId;

      if (isTeam || isEnterprise) {
        usage = plan.baseUsage + (seats - plan.baseSeats) * plan.perSeatUsage;

        if (isEnterprise) {
          const addonsOrder = this.getGrandfatheredAddonIds();
          selectedAddons = addonsOrder.filter((addon) => formData[addon]);

          for (let i = 0, l = selectedAddons.length; i < l; i++) {
            const addon = selectedAddons[i];
            amount += planAddons[addon].amount;
          }

          isDirty =
            isDirty ||
            !!formData.GitHubEnterprise !== !!currentAddons.GitHubEnterprise ||
            !!formData.GitLabEnterprise !== !!currentAddons.GitLabEnterprise ||
            !!formData.SingleSignOn !== !!currentAddons.SingleSignOn ||
            !!formData.UptimeSLA !== !!currentAddons.UptimeSLA ||
            !!formData.PrioritySupport !== !!currentAddons.PrioritySupport ||
            !!formData.SupportSLA !== !!currentAddons.SupportSLA ||
            !!formData.SupportSLA_v2 !== !!currentAddons.SupportSLA_v2;
        }
      }
    }

    return (
      <>
        <h4>Review</h4>
        <div className="future-info">
          {amount && (
            <>
              <p>{this.getPlanForId(selectedPlanId).name}</p>
              <ul className="fa-ul">
                <li>
                  <i className="fa-li fa fa-check-circle"></i>
                  {seats} users
                </li>
                {(isTeam || isEnterprise) && (
                  <li>
                    <i className="fa-li fa fa-check-circle"></i>
                    {usage} build minutes limit
                  </li>
                )}
                {selectedAddons.length > 0 &&
                  selectedAddons.map((addon) => (
                    <li key={addon}>
                      <i className="fa-li fa fa-plus-circle"></i>
                      {planAddons[addon].name}
                    </li>
                  ))}
              </ul>
              <hr />
              {isDirty && (
                <>
                  {isCurrentFree && (
                    <span>
                      You&apos;ll be billed <b>${amount}</b> on{" "}
                      <b>{billingDate}</b>.
                    </span>
                  )}
                  {!isCurrentFree && (
                    <span>
                      Your new monthly rate will be <b>${amount}</b>.
                      You&apos;ll be billed a prorated amount for your current
                      billing cycle on <b>{billingDate}</b>.
                    </span>
                  )}
                </>
              )}
              {!isDirty && (
                <span>
                  You&apos;ll be billed on <b>{billingDate}</b>.
                </span>
              )}
            </>
          )}
          {!amount && (
            <span>
              You&apos;ll be billed on <b>{billingDate}</b>.
            </span>
          )}
        </div>
      </>
    );
  }

  renderFields() {
    const { seatsUsed, validationError } = this.state;

    const seats = this.state.formData.seats;

    const selectedPlanId = this.getSelectedPlanId();
    const selectedPlan = this.getPlanForId(selectedPlanId);

    const isCustom = isCustomPlan(selectedPlanId);
    const isEnterprise = isEnterprisePlan(selectedPlanId);

    const baseSeats = selectedPlan.baseSeats;
    const seatsNumber = this.formatNumber(seats);

    const usersCopy = seatsUsed === 1 ? "user" : "users";
    const baseUsersCopy = baseSeats === 1 ? "user" : "users";

    const errorMessage = validationError
      ? seatsNumber === null
        ? "Please select a valid number of seats"
        : seatsNumber >= seatsUsed
        ? isCustom
          ? `Your Custom plan includes ${baseSeats} ${baseUsersCopy}.`
          : isEnterprise
          ? `Enterprise plans start with ${baseSeats} ${baseUsersCopy}.`
          : `Team plans start with ${baseSeats} ${baseUsersCopy}.`
        : `Your organization currently has ${seatsUsed} ${usersCopy}.`
      : null;

    return (
      <>
        <h4>Select the Number of Users</h4>
        <Form className="option-fields">
          <FormGroup
            bsSize="large"
            controlId="seats"
            className="seats"
            validationState={validationError}
          >
            <StyledControlLabel>Number of Users</StyledControlLabel>
            <FormControl
              type="number"
              value={seats}
              min={selectedPlan.baseSeats}
              onChange={this.handleFieldChange}
            />
            {validationError && <HelpBlock>{errorMessage}</HelpBlock>}
          </FormGroup>
          <FormGroup bsSize="large" controlId="promo_code">
            <StyledControlLabel>Promo Code (Optional)</StyledControlLabel>
            <FormControl
              type="text"
              onChange={this.handleFieldChange}
              value={this.state.formData.promo_code}
            />
          </FormGroup>
        </Form>
      </>
    );
  }

  renderAddons() {
    return (
      <>
        <h4>Select Add-Ons</h4>
        <PricingAddonsPanel
          addons={this.state.formData}
          onSelect={this.handleAddonChange}
          addonIds={this.getGrandfatheredAddonIds()}
        />
      </>
    );
  }

  renderCurrentPlanInfo() {
    const { planCost, seatsLimit } = this.state;
    const usersCopy = seatsLimit === 1 ? "user" : "users";

    return (
      <p className="plan-info">
        ${Math.trunc(planCost / 100)} / month for {seatsLimit} {usersCopy}
      </p>
    );
  }

  renderUpdatePlan() {
    const {
      stripe,
      seatsUsed,
      usageLimit,
      isUpdating,
      showCardForm,
      billingEmail,
      currentPlanId,
      isInvoiceBillingEnabled,
    } = this.state;

    const currentUsage = this.state.currentInvoice.minutesUsed;
    const selectedPlanId = this.getSelectedPlanId();
    const isFree = isFreePlan(selectedPlanId);
    const isTeam = isTeamPlan(selectedPlanId);
    const isEnterprise = isEnterprisePlan(selectedPlanId);

    return (
      <div className="update-plan">
        {!showCardForm && (
          <>
            <LoaderButton bsSize="large" onClick={this.handleShowFormClick}>
              Update Plan
            </LoaderButton>
            {this.canShowUsage() && currentUsage > usageLimit && (
              <Alert className="account-alert" bsStyle="danger">
                <span>You have exceeded the monthly build minute limit.</span>
                &nbsp;
                {(isTeam || isEnterprise) && (
                  <span>
                    To upgrade, add another user to your organization.
                  </span>
                )}
                {isFree && (
                  <span>To upgrade, select one of our paid plans.</span>
                )}
              </Alert>
            )}
          </>
        )}
        {showCardForm && (
          <div className="billing-form">
            <hr />

            <h4>Pick a Plan</h4>
            <div className="pick-plan">
              <div className="header">
                <SectionHeader>Plans</SectionHeader>
                <a target="_blank" href={helpUrl} rel="noopener noreferrer">
                  Learn more about our pricing plans
                </a>
              </div>
              <PricingPlansPanel
                planId={selectedPlanId}
                plans={config.pricingPlans}
                onSelect={this.handlePlanSelect}
                customPlan={this.state.customPlan}
                activePlanIds={config.activePlanIds.map((plan) =>
                  plan === "enterprise_v2" && currentPlanId === "enterprise"
                    ? "enterprise"
                    : plan
                )}
              />
            </div>

            {isEnterprise && this.renderAddons()}

            {!isFree && this.renderFields()}

            {!isFree && this.renderFutureBillingInfo()}

            {!isFree && <hr className="credit-card" />}

            <StripeProvider stripe={stripe}>
              <Elements
                fonts={[
                  {
                    cssSrc:
                      "https://fonts.googleapis.com/css?family=Rubik:400,500,700",
                  },
                ]}
              >
                <StripeCardForm
                  isFree={isFree}
                  loading={isUpdating}
                  onCancel={this.handleCardCancel}
                  disabled={!this.canSubmitForm()}
                  freeValidateError={seatsUsed > 1}
                  invoiceBillingEmail={billingEmail}
                  invoiceBilling={isInvoiceBillingEnabled}
                  onSubmitFree={this.handleCardSubmitFree}
                  onSubmitPaid={this.handleCardSubmitPaid}
                />
              </Elements>
            </StripeProvider>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      invoices,
      promoCode,
      isLoading,
      usageLimit,
      currentPlanId,
      currentInvoice,
    } = this.state;

    return (
      <div className="OrgBilling">
        <ScreenHeader border breadcrumb={orgSettingsBreadcrumb(this.props)}>
          Billing
        </ScreenHeader>

        {isLoading && <LoadingSpinner />}

        {!isLoading && currentPlanId !== null && (
          <div className="form-container">
            <SectionInfo className="current-plan" label="Current Plan">
              <span className="info-text">
                {this.getPlanForId(currentPlanId).name}
              </span>
              {promoCode && (
                <span className="promo">
                  Using promo code &ldquo;{promoCode}&rdquo;
                </span>
              )}
              {this.renderCurrentPlanInfo()}
              {this.renderUpdatePlan()}
            </SectionInfo>
            {this.canShowUsage() && (
              <SectionInfo label="Current Usage">
                <CurrentUsagePanel
                  usageLimit={usageLimit}
                  endDate={currentInvoice.end_at}
                  billAmount={currentInvoice.amount}
                  startDate={currentInvoice.start_at}
                  currentUsage={currentInvoice.minutesUsed}
                />
              </SectionInfo>
            )}
            {invoices.length > 0 && (
              <SectionInfo label="Billing History">
                <BillingHistoryTable invoices={invoices} />
              </SectionInfo>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withAppHeader(withCancel(OrgBilling));
