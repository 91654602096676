import React from "react";
import Modal from "./Modal";
import TextButton from "./TextButton";
import LoaderButton from "./LoaderButton";
import GitBranchSelect from "./GitBranchSelect";
import { useFormReducer } from "../lib/hooksLib";
import "./StageBranchModal.css";

export default function StageBranchModal({
  branch = null,
  branches = null,

  show = false,
  saving = false,
  hasMoreBranches = false,
  loadingMoreBranches = null,
  onSaveClick,
  onCloseClick,
  onLoadMoreBranchesClick,
}) {
  const branchExists = branches.some((per) => per === branch);
  const [{ values: formData, isDirty }, , handleFieldEdit] = useFormReducer({
    branch: branchExists ? branch : null,
  });

  function canSubmit() {
    const { branch } = formData;

    if (branch === null) {
      return false;
    }

    return isDirty.branch;
  }

  function handleEnableClick(event) {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    const { branch } = formData;

    onSaveClick(branch);
  }

  return (
    <Modal show={show} className="StageBranchModal" onHide={onCloseClick}>
      <Modal.Header closeButton>
        <Modal.Title>
          {branch ? "Edit Auto-Deploy Branch" : "Enable Auto-Deploy"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GitBranchSelect
          branches={branches}
          disabled={saving}
          onChange={handleFieldEdit}
          hasMore={hasMoreBranches}
          isLoadingMore={loadingMoreBranches}
          onLoadMoreClick={onLoadMoreBranchesClick}
          selectedBranch={formData.branch}
        />
      </Modal.Body>
      <Modal.Footer>
        <TextButton onClick={onCloseClick}>Cancel</TextButton>
        <LoaderButton
          type="submit"
          bsSize="large"
          bsStyle="warning"
          disabled={!canSubmit()}
          loading={saving}
          onClick={handleEnableClick}
        >
          {branch ? "Update" : "Enable"}
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
