import React, { useRef, useEffect, useCallback } from "react";
import { makeCancelable, onFinally } from "../lib/promiseLib";

const INTERVAL = 4000;
const MAX_INTERVAL = INTERVAL * Math.pow(2, 7);

export default function withPolling(Comp) {
  return function PollComponent(props) {
    // Temporary flag to ensure the API is initially loaded even if
    // the browser isn't in focus.
    const firstLoad = useRef(true);

    const registerId = useRef(0);
    const callback = useRef(null);
    const pollTimer = useRef(null);
    const requestRegister = useRef({});

    // Track timer interval for exponential backoff
    const currentInterval = useRef(INTERVAL);

    const runCallback = useCallback(async () => {
      function delayCallback() {
        currentInterval.current = browserHasFocus()
          ? INTERVAL
          : Math.min(2 * currentInterval.current, MAX_INTERVAL);

        pollTimer.current = window.setTimeout(
          runCallback,
          currentInterval.current
        );
      }

      // Temporary hack to fix any timers not getting cleared
      if (!callback.current) {
        return;
      }

      const results = await callback.current();

      firstLoad.current = false;

      if (results === false) {
        clear();
        return;
      }

      pollTimer.current = null;
      delayCallback();
    }, []);

    useEffect(() => {
      function reload() {
        // Temporarily save current callback
        const fn = callback.current;
        clear();

        // Set previously used callback
        callback.current = fn;
        runCallback();
      }

      function onBrowserFocus() {
        // If there is a callback set to poll and the next interval
        // is longer because of the exponential backoff, then load
        // right away.
        if (callback.current && currentInterval.current > INTERVAL) {
          reload();
        }
      }

      window.addEventListener("focus", onBrowserFocus);

      return function () {
        clear();
        window.removeEventListener("focus", onBrowserFocus);
      };
    }, [runCallback]);

    function browserHasFocus() {
      return !document.hasFocus || document.hasFocus();
    }

    function clear() {
      pollTimer.current !== null && window.clearTimeout(pollTimer.current);
      for (let id in requestRegister.current) {
        requestRegister.current[id].cancel();
      }

      registerId.current = 0;
      callback.current = null;
      pollTimer.current = null;
      requestRegister.current = {};
    }

    async function start(fn) {
      if (callback.current) {
        throw new Error("Already polling for something else");
      }

      callback.current = fn;
      await runCallback();
    }

    function register(promise) {
      if (callback.current === null) {
        return promise;
      }

      const id = registerId.current++;
      const cancelablePromise = makeCancelable(promise);

      requestRegister.current[id] = cancelablePromise;

      return onFinally(cancelablePromise.promise, () => {
        delete requestRegister.current[id];
      });
    }

    return (
      <Comp
        poll={{
          start,
          clear,
          register,
        }}
        {...props}
      />
    );
  };
}
