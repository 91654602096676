import React from "react";
import SectionHeader from "./SectionHeader";
import "./AccessLogFieldsPanel.css";

export default function AccessLogFieldsPanel({ fields }) {
  const keys = Object.keys(fields);

  return keys.length === 0 ? null : (
    <div className="AccessLogFieldsPanel">
      {keys.map((key) => (
        <div key={key} className="field">
          <SectionHeader>{key}</SectionHeader>
          <p className="value">{fields[key]}</p>
        </div>
      ))}
    </div>
  );
}
