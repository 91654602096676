import React, { Component } from "react";
import AppIamPanel from "../../components/AppIamPanel";
import { errorHandler } from "../../lib/errorLib";

const noop = () => {};

const defaultProps = {
  iamRole: undefined,
  iamAccess: undefined,

  policyInfoLoading: false,
  onShowIamRoleClick: noop,

  onSave: noop,
};

const defaultState = {
  iamRole: undefined,
  iamAccess: undefined,
  showing: false,
  saving: false,
};

class AppIam extends Component {
  state = { ...defaultState };

  handleShowClick = (event) => {
    this.setState({ showing: true });
  };

  handleSaveClick = async (event, iamData) => {
    this.setState({ saving: true });

    try {
      await this.props.onSave(event, iamData);

      this.setState((prevState) => ({
        showing: false,
        saving: false,
        iamRole: iamData.iam_role,
        iamAccess: iamData.iam_access,
      }));
    } catch (e) {
      this.setState({ saving: false });
      errorHandler(e);
    }
  };

  handleHideClick = (event) => {
    this.setState({ showing: false });
  };

  render() {
    const { showing, saving } = this.state;
    const { policyInfoLoading, onShowIamRoleClick } = this.props;
    const iamRole =
      this.state.iamRole === undefined
        ? this.props.iamRole
        : this.state.iamRole;
    const iamAccess =
      this.state.iamAccess === undefined
        ? this.props.iamAccess
        : this.state.iamAccess;

    return (
      <AppIamPanel
        showing={showing}
        saving={saving}
        iamRole={iamRole}
        iamAccess={iamAccess}
        onShowClick={this.handleShowClick}
        onSaveClick={this.handleSaveClick}
        onHideClick={this.handleHideClick}
        policyInfoLoading={policyInfoLoading}
        onShowIamRoleClick={onShowIamRoleClick}
      />
    );
  }
}

AppIam.defaultProps = defaultProps;

export default AppIam;
