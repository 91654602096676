import React, { useState } from "react";
import { Form, Checkbox, FormGroup, HelpBlock } from "react-bootstrap";
import Modal from "./Modal";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import LoaderButton from "./LoaderButton";
import GitBranchForm from "./GitBranchForm";
import ServiceButtonGroup from "./ServiceButtonGroup";
import { errorHandler } from "../lib/errorLib";
import { useFormReducer } from "../lib/hooksLib";
import "./ManualDeployModal.css";

const noop = () => {};

const defaultFields = {
  force: false,
  branch: null,
};

export default function ManualDeployModal({
  show,
  deployTo = null,
  loadError = null,
  deploying = false,
  defaultBranch = null,
  lastDeployedRefs = [],

  onCloseClick = noop,
  onDeployClick = noop,
}) {
  const lastDeployedRef =
    lastDeployedRefs.length > 0 ? lastDeployedRefs[0] : null;
  defaultBranch = lastDeployedRef || defaultBranch;

  const showPickerDefault = defaultBranch === null ? true : false;

  const [showPicker, setShowPicker] = useState(showPickerDefault);
  const [formData, formDispatch, handleFieldEdit] = useFormReducer({
    ...defaultFields,
    branch: defaultBranch,
  });

  const selectedBranch = showPicker ? formData.values.branch : defaultBranch;

  function handleDeployClick(event) {
    event.preventDefault();

    if (selectedBranch === null || selectedBranch === "") {
      return;
    }

    onDeployClick(event, selectedBranch, deployTo, formData.values.force);
  }

  function handleChangeClick() {
    if (loadError) {
      errorHandler(loadError);
    }

    formDispatch({ id: "branch", value: "", type: "edit" });

    setShowPicker(true);
  }

  return (
    <Modal show={show} onHide={onCloseClick} className="ManualDeployModal">
      <Modal.Header closeButton>
        <Modal.Title>
          {!deployTo.serviceId && `Deploy ${deployTo.appStageId}`}
          {deployTo.serviceId && (
            <>
              <ServiceButtonGroup
                serviceName={deployTo.serviceId}
                serviceType={deployTo.serviceType}
              />
              <span className="separator">/</span>
              {deployTo.appStageId}
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="branches">
          {showPicker && (
            <GitBranchForm
              disabled={deploying}
              onChange={handleFieldEdit}
              onSubmit={handleDeployClick}
              lastDeployedRefs={lastDeployedRefs}
            />
          )}
          <Form
            className="form"
            componentClass="div"
            onSubmit={handleDeployClick}
          >
            {!showPicker && (
              <div className="default-branch">
                <div className="branch">
                  <p className="name">
                    <FaIcon name="code-fork" />
                    {defaultBranch}
                  </p>
                  {lastDeployedRef && (
                    <p className="copy">(Previously selected)</p>
                  )}
                </div>
                <TextButton disabled={deploying} onClick={handleChangeClick}>
                  Change
                </TextButton>
              </div>
            )}
            <FormGroup className="force-option">
              <Checkbox
                id="force"
                disabled={deploying}
                onChange={handleFieldEdit}
                checked={formData.values.force}
              >
                Force deploy even if there are no changes
              </Checkbox>
              <HelpBlock>
                {deployTo && deployTo.serviceId
                  ? "Enable this if you want to force deploy the service"
                  : "Enable this if you want to force deploy the services in your app"}
              </HelpBlock>
            </FormGroup>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <TextButton onClick={onCloseClick}>Cancel</TextButton>
        <LoaderButton
          bsSize="large"
          bsStyle="warning"
          loading={deploying}
          onClick={handleDeployClick}
          disabled={selectedBranch === null || selectedBranch === ""}
        >
          Deploy
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
