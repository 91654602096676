import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ErrorAlert from "../components/ErrorAlert";
import AuditLogList from "../components/AuditLogList";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import {
  querystring,
  getOrgBillingUrl,
  getOrgAuditLogUrl,
  buildQueryStringUrl,
} from "../lib/urlLib";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import "./OrgAuditLog.css";

function OrgAuditLog(props) {
  let isLoading = true;

  let records = undefined;
  let nextPageToken = null;
  let prevPageToken = null;
  let isAuditLogAvailable = null;

  const page = querystring("page");

  const { action } = useHistory();
  const { ownerId } = props.match.params;

  useEffect(() => {
    document.title = title("Audit Log");
  }, []);

  useEffect(() => {
    if (action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [action, page]);

  const { data, error } = useAPILoad(getLogsAPI());

  if (data) {
    isLoading = false;

    records = data.records;
    nextPageToken = data.nextPageToken;
    prevPageToken = data.prevPageToken;
    isAuditLogAvailable = data.isAuditLogAvailable;
  }

  /////////
  // API //
  /////////

  function getLogsAPI() {
    return buildQueryStringUrl(`/orgs/${ownerId}/audit_log`, {
      nextToken: page,
    });
  }

  //////////////
  // Handlers //
  //////////////

  ////////////
  // Render //
  ////////////

  function getNextPageLink() {
    if (isLoading) {
      return null;
    }

    return nextPageToken ? getOrgAuditLogUrl(ownerId, nextPageToken) : null;
  }

  function getPrevPageLink() {
    if (isLoading) {
      return null;
    }

    return page
      ? prevPageToken
        ? getOrgAuditLogUrl(ownerId, prevPageToken)
        : getOrgAuditLogUrl(ownerId)
      : null;
  }

  return (
    <div className="OrgAuditLog">
      {error && <ErrorAlert error={error} />}

      <AuditLogList
        records={records}
        loading={isLoading}
        nextPageLink={getNextPageLink()}
        prevPageLink={getPrevPageLink()}
        unavailable={!isAuditLogAvailable}
        billingLink={getOrgBillingUrl(ownerId)}
      />
    </div>
  );
}

export default withAppHeader(withCancel(OrgAuditLog));
