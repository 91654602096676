import React from "react";
import { MenuItem, SplitButton } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import LoaderButton from "./LoaderButton";
import config from "../config";
import "./GitProviderSelect.css";

const gitAuthUrls = config.gitAuthUrls;

function handleClick(e, url) {
  e.preventDefault();
  window.open(url);
}

export default ({ org }) => {
  const ghUrl =
    `https://${gitAuthUrls.github.domain}${gitAuthUrls.github.path}` +
    `&client_id=${config.github.client_id}`;
  const bbUrl =
    `https://${gitAuthUrls.bitbucket.domain}${gitAuthUrls.bitbucket.path}` +
    `&client_id=${config.bitbucket.client_id}`;
  const glUrl =
    `https://${gitAuthUrls.gitlab.domain}${gitAuthUrls.gitlab.path}` +
    `&client_id=${config.gitlab.client_id}` +
    `&redirect_uri=${config.gitlab.redirect_uri}`;

  let gheUrl;
  if (org.githubEnterpriseDomain) {
    gheUrl =
      `https://${org.githubEnterpriseDomain}${gitAuthUrls.github.path}` +
      `&client_id=${org.githubEnterpriseClientId}&state=${org.githubEnterpriseDomain}`;
  }

  let gleUrl;
  if (org.gitlabEnterpriseDomain) {
    gleUrl =
      `https://${org.gitlabEnterpriseDomain}${gitAuthUrls.gitlab.path}` +
      `&client_id=${org.gitlabEnterpriseClientId}&state=${org.gitlabEnterpriseDomain}` +
      `&redirect_uri=${config.gitlabEE.redirect_uri}`;
  }

  function handleGitHubEnterpriseClick(e) {
    if (!gheUrl) {
      e.preventDefault();
      alert("Please enable GitHub Enterprise in your organization settings.");
      return;
    }

    handleClick(e, gheUrl);
  }

  function handleGitLabEnterpriseClick(e) {
    if (!gleUrl) {
      e.preventDefault();
      alert("Please enable GitLab Self-Managed in your organization settings.");
      return;
    }

    handleClick(e, gleUrl);
  }

  return (
    <div className="GitProviderSelect">
      <SplitButton
        bsSize="large"
        id="github-button"
        title={
          <>
            <FontAwesome name="github" />
            <span>GitHub</span>
          </>
        }
        onClick={(e) => handleClick(e, ghUrl)}
      >
        <MenuItem onClick={handleGitHubEnterpriseClick}>
          GitHub Enterprise
        </MenuItem>
      </SplitButton>
      <SplitButton
        bsSize="large"
        id="gitlab-button"
        title={
          <>
            <FontAwesome name="gitlab" />
            <span>GitLab</span>
          </>
        }
        onClick={(e) => handleClick(e, glUrl)}
      >
        <MenuItem onClick={handleGitLabEnterpriseClick}>
          GitLab Self-Managed
        </MenuItem>
      </SplitButton>
      <LoaderButton bsSize="large" onClick={(e) => handleClick(e, bbUrl)}>
        <FontAwesome name="bitbucket" />
        <span>Bitbucket</span>
      </LoaderButton>
    </div>
  );
};
