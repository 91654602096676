import React from "react";
import { Link } from "react-router-dom";
import FaIcon from "./FaIcon";
import ServiceIcon from "./ServiceIcon";
import RightChevron from "./RightChevron";
import {
  getAppStageBuildServiceUrl,
  getAppStageBuildTestUrl,
} from "../lib/urlLib";
import { shortHash } from "../lib/gitLib";
import { formatDuration } from "../lib/timeLib";
import "./AppActivityWorkflowBuild.css";

function buildStatusToActive(stepStatus) {
  if (stepStatus === "queued" || stepStatus === "skipped") {
    return false;
  } else {
    return true;
  }
}

function buildStatusToGlyphicon(status) {
  const textArray = {
    queued: "cog fa-spin",
    deploying: "cog fa-spin",
    success: "check-circle",
    failure: "times-circle",
    skipped: "minus-circle",
  };

  return textArray[status];
}

function buildStatusToCopy(status) {
  const textArray = {
    pending: "Pending",
    deploying: "In progress",
    success: "Completed",
    failure: "Failed",
    skipped: "Skipped",
  };

  return textArray[status];
}

function isCompletedSkipped(deployment) {
  return (
    deployment.successContext &&
    deployment.successContext.successType === "git_diff_deploy_skip"
  );
}

function hasValidDuration(startedAt, endedAt) {
  return startedAt && endedAt && endedAt > startedAt;
}

function renderDuration({ startedAt, endedAt }) {
  return hasValidDuration(startedAt, endedAt) ? (
    <>
      <FaIcon name="clock-o" />
      {formatDuration(startedAt, endedAt)}
    </>
  ) : (
    ""
  );
}

export default ({ stepId, phaseId, activity, pathParams }) => {
  const step = activity.progress[phaseId][stepId];

  function renderDeploy() {
    const { ownerId, appId, appStageId, buildId } = pathParams;
    const { project, deployment } = step;

    let projectName, projectLink, projectType;
    let commitId, commitLink, buildGlyphicon, buildMessage;
    let isStageRemoved = false;
    let isServiceRemoved = false;
    let completedSkipped = false;

    // Case 1: NORMAL => project and deployment exists
    if (project && deployment) {
      completedSkipped = isCompletedSkipped(deployment);
      projectName = project.service_name;
      projectType = project.serviceType;
      projectLink = getAppStageBuildServiceUrl(
        ownerId,
        appId,
        appStageId,
        buildId,
        project.service_name
      );
      commitLink = deployment.commit.url;
      commitId = deployment.commit.id;
      buildGlyphicon = buildStatusToGlyphicon(step.status);
    }
    // Case 2: stage REMOVED => project exists, deployment not exists
    else if (project) {
      isStageRemoved = true;
      projectName = "[Removed]";
      buildGlyphicon = buildStatusToGlyphicon(step.status);
      buildMessage = buildStatusToCopy(step.status);
    }
    // Case 3: project REMOVED => project not exists, deployment not exists
    else {
      isServiceRemoved = true;
      projectName = "[Removed]";
      buildGlyphicon = buildStatusToGlyphicon(step.status);
      buildMessage = buildStatusToCopy(step.status);
    }

    const isRemoved = isServiceRemoved || isStageRemoved;
    const activeCs = buildStatusToActive(step.status) ? "active" : "";
    const stateClass = isRemoved
      ? "removed"
      : completedSkipped
      ? "completed_skipped"
      : "";

    return (
      <div className={`AppActivityWorkflowBuild ${stateClass}`}>
        <div className={`build-status ${step.status}`}>
          {!isRemoved && (
            <Link className="icon" to={projectLink}>
              <i
                aria-hidden="true"
                className={`fa fa-${buildGlyphicon} ${activeCs}`}
              />
            </Link>
          )}
          {isRemoved && (
            <span className="icon">
              <i aria-hidden="true" className={`fa fa-${buildGlyphicon}`} />
            </span>
          )}
          <div className="content">
            <div className="name">
              {!isRemoved && (
                <Link className="id" to={projectLink}>
                  <ServiceIcon size="small" type={projectType} />
                  {projectName}
                </Link>
              )}
              {isRemoved && (
                <span className="id">
                  <ServiceIcon size="small" type="default" />
                  {projectName}
                </span>
              )}
              {!isRemoved && (
                <span className="duration">{renderDuration(deployment)}</span>
              )}
            </div>
            <hr />
            <div className="info">
              {!isRemoved && (
                <>
                  <a
                    target="_blank"
                    className="commit"
                    rel="noopener noreferrer"
                    href={commitLink}
                  >
                    {shortHash(commitId)}
                  </a>
                  <Link to={projectLink} className="view-logs">
                    View logs
                    <RightChevron />
                  </Link>
                </>
              )}
              {isRemoved && <span className="message">{buildMessage}</span>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderPostDeployTest() {
    const { ownerId, appId, appStageId, buildId } = pathParams;
    const projectName = "Post-Deploy";
    const projectLink = getAppStageBuildTestUrl(
      ownerId,
      appId,
      appStageId,
      buildId
    );
    const buildGlyphicon = buildStatusToGlyphicon(step.status);
    const activeCs = buildStatusToActive(step.status) ? "active" : "";

    return (
      <div className="AppActivityWorkflowBuild">
        <div className={`build-status ${step.status}`}>
          <Link className="icon" to={projectLink}>
            <i
              aria-hidden="true"
              className={`fa fa-${buildGlyphicon} ${activeCs}`}
            />
          </Link>
          <div className="content">
            <div className="name">
              <Link className="id" to={projectLink}>
                {projectName}
              </Link>
              <span className="duration">{renderDuration(step)}</span>
            </div>
            <hr />
            <div className="info">
              <Link to={projectLink} className="view-logs">
                View logs
                <RightChevron />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return step.type === "post_deploy_test"
    ? renderPostDeployTest()
    : renderDeploy();
};
