import React from "react";
import { Link, useParams } from "react-router-dom";
import Alert from "./Alert";
import { getAppIssuesSettingsUrl } from "../lib/urlLib";
import "./IssuesExceededLimitAlert.css";

export default function IssuesExceededLimitAlert() {
  const { ownerId, appId } = useParams();

  return (
    <Alert className="IssuesExceededLimitAlert" bsStyle="danger">
      <>
        Issues hourly limit exceeded. Lambda monitoring is currently paused.
        Please, fix the problem and re-enable Issues in&nbsp;
        <Link to={getAppIssuesSettingsUrl(ownerId, appId)}>
          your app settings
        </Link>
        .
      </>
    </Alert>
  );
}
