import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ErrorAlert from "../components/ErrorAlert";
import ScreenHeader from "../components/ScreenHeader";
import RightChevron from "../components/RightChevron";
import LoaderButton from "../components/LoaderButton";
import withCancel from "../components/ComponentWithCancel";
import RequestLogPanel from "../components/RequestLogPanel";
import LogFilterSelect from "../components/LogFilterSelect";
import LogDurationSelect from "../components/LogDurationSelect";
import withAppHeader from "../components/ComponentWithAppHeader";
import ResourceSearchModal from "../components/ResourceSearchModal";
import ResourceSearchButton from "../components/ResourceSearchButton";
import PermissionErrorPanel from "../components/PermissionErrorPanel";
import {
  parseDateString,
  dateToFullTimeNoYearWithUTCTimeZone as dateToUTC,
  dateToFullTimeNoYearWithLocalTimeZone as dateToLocal,
} from "../lib/timeLib";
import {
  querystring,
  getAppStageUrl,
  getAppStageApiLogsUrl,
  getAppStageApiLogsUrlOld,
  getAppStageLambdaLogsUrl,
  getAppStageLambdaLogsUrlOld,
  buildQueryStringUrl,
} from "../lib/urlLib";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import { isAwsPermissionError } from "../lib/errorLib";
import { appResourcesBreadcrumb } from "../lib/breadcrumbLib";
import {
  useLogQueries,
  useLogTailing,
  useCustomCompare,
  useSearchModalReducer,
} from "../lib/hooksLib";
import "./Logs.css";

function Logs(props) {
  const logType = querystring("type", props.location.search);
  const service = querystring("service", props.location.search);
  const lambda = querystring("lambda", props.location.search);
  const region = querystring("region", props.location.search);
  const apiId = querystring("apiId", props.location.search);
  const stackName = querystring("stack", props.location.stack);
  const filter = querystring("filter", props.location.search);
  const span = querystring("span", props.location.search);
  const logStreamName = querystring("logStreamName", props.location.search);
  const logTimestamp = querystring("logTimestamp", props.location.search);
  const errorOnly = querystring("errorOnly", props.location.search);
  const isAccessLog = logType === "api";
  const logTitle = isAccessLog ? "Access Logs" : "Lambda Logs";
  const spanInfo = span && parseDateString(span);
  const isTailingMode = !span;
  const isSingleRequestMode = !!(span && logStreamName && logTimestamp);
  const isErrorOnlyMode = errorOnly === "true";

  const { ownerId, appId, appStageId } = props.match.params;

  const invalidQueryParams = checkInvalidQueryParams();

  //////////
  // Load //
  //////////

  useEffect(() => {
    document.title = title(logTitle);
  }, [logTitle]);

  const [searchModalState, searchModalDispatch] = useSearchModalReducer();
  const [urlRequests, setUrlRequests] = useState([]);
  const [urlError, setUrlError] = useState(null);
  const [permissionError, setPermissionError] = useState(null);

  // load url data
  let tailUrl;
  let loadUrl;
  const queryUrls = [];
  urlRequests.forEach((urlRequest) => {
    if (urlRequest.loadMode === "tail") {
      tailUrl = urlRequest.url;
    } else if (urlRequest.loadMode === "request") {
      loadUrl = urlRequest.url;
    } else if (urlRequest.loadMode === "range") {
      queryUrls.push(urlRequest.url);
    }
  });

  const { data: loadResult, error: loadError } = useAPILoad(loadUrl, {
    polling: true,
  });
  const { data: tailResult, error: tailError } = useLogTailing(tailUrl);
  const { data: queryResults, error: queryErrors } = useLogQueries({
    queries: queryUrls,
    cancelUrl: buildCancelApiUrl(),
  });

  const { data: resourceLinks, error: resourceLinksError } = useAPILoad(
    `/${ownerId}/${appId}/resources/links?version=v20200224&mode=logs`
  );

  // handle response data
  const urlResultsByUrl = {};
  let queryResultsIndex = -1;
  let tailingStartCutoffAt;
  urlRequests.forEach((urlRequest) => {
    const url = urlRequest.url;
    if (urlRequest.loadMode === "tail") {
      urlResultsByUrl[url] = tailResult && tailResult.response;
      tailingStartCutoffAt = tailResult && tailResult.startCutoffAt;
    } else if (urlRequest.loadMode === "request") {
      urlResultsByUrl[url] = loadResult;
    } else if (urlRequest.loadMode === "range") {
      queryResultsIndex++;
      urlResultsByUrl[url] =
        queryResults[queryResultsIndex] && queryResults[queryResultsIndex].data;
    }
  });

  // handle response errors
  // note: use useCustomCompare() to detect changes in complex data structure
  const queryUrlsCached = useCustomCompare(
    queryUrls,
    (a, b) => a.length === b.length && a.every((url) => b.includes(url))
  );
  const queryErrorsCached = useCustomCompare(queryErrors, (a, b) => {
    const idsA = a
      .filter((error) => error !== null)
      .map(({ requestId }) => requestId);
    const idsB = b
      .filter((error) => error !== null)
      .map(({ requestId }) => requestId);
    return (
      idsA.length === idsB.length &&
      idsA.every((requestId, i) => requestId === idsB[i])
    );
  });
  useEffect(() => {
    if (loadError) {
      isAwsPermissionError(loadError)
        ? setPermissionError(loadError)
        : setUrlError({ url: loadUrl, error: loadError });
    }

    if (tailError) {
      isAwsPermissionError(tailError)
        ? setPermissionError(tailError)
        : setUrlError({ url: tailUrl, error: tailError });
    }

    queryErrorsCached
      .filter((error) => error !== null)
      .forEach(({ error, requestId }, i) => {
        isAwsPermissionError(error)
          ? setPermissionError(error)
          : setUrlError({ url: queryUrlsCached[i], error, requestId });
      });
  }, [
    loadUrl,
    loadError,
    tailUrl,
    tailError,
    queryUrlsCached,
    queryErrorsCached,
  ]);

  // hide the resource search modal on route change
  useEffect(() => {
    searchModalDispatch({ type: "hide" });
  }, [props.location.search, searchModalDispatch]);

  ///////////////
  // Functions //
  ///////////////

  function buildCancelApiUrl() {
    const params = !region
      ? {
          lambdaName: lambda,
          serviceName: service,
        }
      : {
          version: "v20200224",
          lambdaName: lambda,
          region,
        };
    return buildQueryStringUrl(
      `${getAppStageAPI()}/resources/stop_query`,
      params
    );
  }

  function buildBaseAppUrl(params = {}) {
    if (!region) {
      return isAccessLog
        ? getAppStageApiLogsUrlOld(
            ownerId,
            appId,
            appStageId,
            service,
            stackName,
            params
          )
        : getAppStageLambdaLogsUrlOld(
            ownerId,
            appId,
            appStageId,
            service,
            lambda,
            params
          );
    }

    return isAccessLog
      ? getAppStageApiLogsUrl(
          ownerId,
          appId,
          appStageId,
          region,
          stackName,
          apiId,
          params
        )
      : getAppStageLambdaLogsUrl(
          ownerId,
          appId,
          appStageId,
          region,
          lambda,
          params
        );
  }

  function buildAppUrl({ newSpan = null, newFilter = null }) {
    const params = {};

    newSpan = newSpan !== null ? newSpan : span;
    if (newSpan !== null) {
      params.span = newSpan;
    }

    newFilter = newFilter !== null ? newFilter : filter;
    if (newFilter !== null && newFilter !== "") {
      params.filter = newFilter;
    }

    if (!region) {
      return isAccessLog
        ? getAppStageApiLogsUrlOld(
            ownerId,
            appId,
            appStageId,
            service,
            stackName,
            params
          )
        : getAppStageLambdaLogsUrlOld(
            ownerId,
            appId,
            appStageId,
            service,
            lambda,
            params
          );
    }

    return isAccessLog
      ? getAppStageApiLogsUrl(
          ownerId,
          appId,
          appStageId,
          region,
          stackName,
          apiId,
          params
        )
      : getAppStageLambdaLogsUrl(
          ownerId,
          appId,
          appStageId,
          region,
          lambda,
          params
        );
  }

  function getAppAPI() {
    return `/${ownerId}/${appId}`;
  }

  function getAppStageAPI() {
    return `/${ownerId}/${appId}/stages/${appStageId}`;
  }

  function checkInvalidQueryParams() {
    // Validate cannot have lambda and access log
    if (isAccessLog && lambda) {
      return true;
    }

    // Validate:
    // - cannot be both single request and error only mode
    // - cannot be both tailing and error only mode
    if (isSingleRequestMode && isErrorOnlyMode) {
      return true;
    }
    if (isTailingMode && isErrorOnlyMode) {
      return true;
    }

    // Not able to parse span
    if (span && !spanInfo) {
      return true;
    }

    // Case request
    if (logStreamName && !logTimestamp) {
      return true;
    }
    if (logStreamName && !span) {
      return true;
    }

    return false;
  }

  /////////
  // API //
  /////////

  function saveRecentSearches(fullName, type) {
    const mode = "logs";
    return props.invokeApig({
      path: `${getAppAPI()}/resources/link_search`,
      method: "POST",
      body: { fullName, type, mode },
    });
  }

  //////////////
  // Handlers //
  //////////////

  function buildResourceSearchUrl({ fullName, region, type, stage, apiId }) {
    switch (type) {
      case "lambda":
        return getAppStageLambdaLogsUrl(
          ownerId,
          appId,
          stage,
          region,
          fullName
        );
      case "api":
        return getAppStageApiLogsUrl(
          ownerId,
          appId,
          stage,
          region,
          fullName,
          apiId
        );
      default:
        return null;
    }
  }

  function handleResourceSearchSelect({ fullName, type }, url) {
    saveRecentSearches(fullName, type);

    props.history.push(url);
  }

  function handleResourceSearchClick() {
    searchModalDispatch({ type: "show" });
  }

  function handleResourceSearchModalClose() {
    searchModalDispatch({ type: "hide" });
  }

  function handleDurationChange(newSpan) {
    // Normalize span
    if (newSpan !== "") {
      const parsed = parseDateString(newSpan);
      if (!parsed) {
        newSpan = "";
      } else if (parsed.type === "absolute") {
        newSpan = parsed.isUTC ? dateToUTC(parsed.ts) : dateToLocal(parsed.ts);
      } else if (parsed.type === "range") {
        newSpan = parsed.isUTC
          ? `${dateToUTC(parsed.startTs)} - ${dateToUTC(parsed.endTs)}`
          : `${dateToLocal(parsed.startTs)} - ${dateToLocal(parsed.endTs)}`;
      } else if (parsed.type === "relative") {
        newSpan = parsed.normalized;
      }
    }

    const newParams = newSpan === "" ? { newSpan, newFilter: "" } : { newSpan };
    props.history.push(buildAppUrl(newParams));
  }

  function handleFilterCange(newFilter) {
    const newParams = { newFilter };
    props.history.push(buildAppUrl(newParams));
  }

  function handleUrlsUpdate(urlRequests) {
    setUrlRequests(urlRequests);
  }

  function handleResetUrlError() {
    setUrlError(null);
    setPermissionError(null);
  }

  ////////////
  // Render //
  ////////////

  return (
    <div className="Logs">
      <ScreenHeader breadcrumb={appResourcesBreadcrumb(props)}>
        {logTitle}
      </ScreenHeader>

      <PermissionErrorPanel type="logs" error={permissionError}>
        Add a couple of permissions to view logs
      </PermissionErrorPanel>

      {resourceLinksError && <ErrorAlert error={resourceLinksError} />}

      {invalidQueryParams && (
        <div className="error-invalid">
          <div className="content">
            <h2>There was a problem loading this page.</h2>
            {lambda && (
              <p>
                Please check the URL and try again. Or{" "}
                <Link to={buildBaseAppUrl()}>click here</Link> to view all the
                logs.
              </p>
            )}
            {!lambda && (
              <p>
                Please check the URL and try again. Or{" "}
                <Link to={getAppStageUrl(ownerId, appId, appStageId)}>
                  click here
                </Link>{" "}
                to view all the deployed resources.
              </p>
            )}
          </div>
        </div>
      )}

      {!invalidQueryParams && (
        <>
          <div className="title-bar">
            <ResourceSearchButton
              mode="logs"
              onClick={handleResourceSearchClick}
              text={isAccessLog ? stackName : lambda}
            />
            {(isSingleRequestMode || isErrorOnlyMode) && (
              <div className="single-request-info">
                <Link to={buildBaseAppUrl()}>
                  <LoaderButton bsStyle="block">
                    View all logs
                    <RightChevron />
                  </LoaderButton>
                </Link>
              </div>
            )}
            {!(isSingleRequestMode || isErrorOnlyMode) && (
              <>
                <LogFilterSelect
                  value={filter || ""}
                  isAccessLog={isAccessLog}
                  onChange={handleFilterCange}
                  key={`filter-${filter || ""}`}
                />
                <LogDurationSelect
                  onChange={handleDurationChange}
                  value={isTailingMode ? "" : span}
                  key={`duration-${isTailingMode ? "" : span}`}
                />
              </>
            )}
          </div>

          {/* Render logs */}
          <RequestLogPanel
            span={span}
            apiId={apiId}
            lambda={lambda}
            filter={filter}
            region={region}
            service={service}
            urlError={urlError}
            isAccessLog={isAccessLog}
            key={props.location.search}
            logTimestamp={logTimestamp}
            isErrorOnly={isErrorOnlyMode}
            logStreamName={logStreamName}
            onUrlsUpdate={handleUrlsUpdate}
            buildShareUrl={buildBaseAppUrl}
            urlResultsByUrl={urlResultsByUrl}
            appStageApiLink={getAppStageAPI()}
            isSingleRequest={isSingleRequestMode}
            onResetUrlError={handleResetUrlError}
            tailingStartCutoffAt={tailingStartCutoffAt}
          />

          <ResourceSearchModal
            key={searchModalState.key}
            show={searchModalState.show}
            buildUrl={buildResourceSearchUrl}
            onSelect={handleResourceSearchSelect}
            value={isAccessLog ? stackName : lambda}
            onCloseClick={handleResourceSearchModalClose}
            resourceLinks={resourceLinks ? resourceLinks.links : null}
            recentSearches={resourceLinks ? resourceLinks.recentSearches : []}
          />
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(Logs));
