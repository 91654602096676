import React from "react";
import { Glyphicon } from "react-bootstrap";
import "./UserImage.css";

export default function UserImage({
  src,
  name,
  initials = "",
  size = "medium",
  inactive = false,
}) {
  const type = src ? "src" : "initials";
  const activeCs = inactive ? "inactive" : "active";

  return (
    <div title={name} className={`UserImage ${type} size-${size} ${activeCs}`}>
      {src && <img alt="" src={src} />}
      {!src && initials !== "" && (
        <div>
          <span>{initials}</span>
        </div>
      )}
      {!src && initials === "" && (
        <div>
          <Glyphicon glyph="user" />
        </div>
      )}
    </div>
  );
}
