import React from "react";
import FaIcon from "./FaIcon";
import UserImage from "./UserImage";
import SectionHeader from "./SectionHeader";
import { dateToSinceTimeIssues } from "../lib/timeLib";
import "./IssueActivityList.css";

const typesCopy = {
  reopened: "Redetected issue",
  muted: "Marked as ignored",
  automuted: "Automatically ignored",
  resolved: "Resolved issue",
  unmuted: "Unignored issue",
  unresolved: "Marked as unresolved",
};

export default function IssueActivityList({
  firstErrorAt,
  recentHasMore,
  recentActivities = [],
  oldestActivities = [],
}) {
  function renderSeedIcon() {
    return (
      <span className="seed" title="seed">
        <FaIcon name="circle" />
      </span>
    );
  }

  function renderActivity(activity) {
    return (
      <li key={activity.createdAt} className="item">
        <div className="col1">
          {activity.user ? (
            <UserImage
              size="xsmall"
              name={activity.user.name}
              src={activity.user.imageLink}
              initials={activity.user.nameInitials}
            />
          ) : (
            renderSeedIcon()
          )}
          <span className="copy">{typesCopy[activity.type]}</span>
        </div>
        <span className="time">
          {dateToSinceTimeIssues(activity.createdAt)} ago
        </span>
      </li>
    );
  }

  function renderFirstActivity() {
    return (
      <li className="item">
        <div className="col1">
          {renderSeedIcon()}
          <span className="copy">First detected</span>
        </div>
        <span className="time">{dateToSinceTimeIssues(firstErrorAt)} ago</span>
      </li>
    );
  }

  return (
    <div className="IssueActivityList">
      <SectionHeader>Issue History</SectionHeader>
      <ul>
        {recentActivities.map(renderActivity)}
        {recentHasMore && (
          <>
            <li className="more">
              <FaIcon name="ellipsis-v" />
            </li>
            {oldestActivities.map(renderActivity)}
          </>
        )}
        {renderFirstActivity()}
      </ul>
    </div>
  );
}
