import React, { useReducer } from "react";
import SettingsTogglePanel from "../../components/SettingsTogglePanel";
import { errorHandler } from "../../lib/errorLib";

const defaultState = { key: 0, updated: false, updating: false };

function reducer(state, action) {
  switch (action.type) {
    case "update":
      return { ...state, updating: true };
    case "update-failed":
      return { ...state, updating: false };
    case "update-done":
      return { ...state, updating: false, updated: true, key: state.key + 1 };
    default:
      return state;
  }
}

function SettingsToggle({
  sectionLabel,
  sectionDescription,
  helperLink,
  helperDescription,
  controlLabel,
  available,
  unavailableLabel,
  enabled,
  onUpdated,
}) {
  const [state, dispatch] = useReducer(reducer, defaultState);

  async function handleUpdateClick(value) {
    dispatch({ type: "update" });
    try {
      await onUpdated(value);
      dispatch({ type: "update-done" });
    } catch (e) {
      dispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  return (
    <SettingsTogglePanel
      sectionLabel={sectionLabel}
      sectionDescription={sectionDescription}
      helperLink={helperLink}
      helperDescription={helperDescription}
      controlLabel={controlLabel}
      available={available}
      unavailableLabel={unavailableLabel}
      resetKey={`SettingsTogglePanel-${state.key}`}
      updating={state.updating}
      enabled={enabled}
      onUpdateClick={handleUpdateClick}
    />
  );
}

export default SettingsToggle;
