import React from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import TextButton from "./TextButton";
import LoaderButton from "./LoaderButton";
import StyledControlLabel from "./StyledControlLabel";
import { useFormReducer } from "../lib/hooksLib";
import { testTwoFactorCode } from "../lib/regexLib";
import "./TwoFactorLoginForm.css";

export default function TwoFactorLoginForm({
  onCancelClick,
  onValidateClick,
  isValidating = false,
}) {
  const [
    {
      values: { code },
      validation,
    },
    formDispatch,
    handleChange,
  ] = useFormReducer({ code: "" });

  async function handleLoginClick(event) {
    event.preventDefault();

    const formattedCode = code.trim();

    if (!testTwoFactorCode(formattedCode)) {
      formDispatch({ type: "validate", id: "code" });
      return;
    }

    await onValidateClick(formattedCode);
  }

  return (
    <form className="TwoFactorLoginForm" onSubmit={handleLoginClick}>
      <FormGroup
        bsSize="large"
        controlId="code"
        validationState={validation.code}
      >
        <StyledControlLabel>Two-Factor Code</StyledControlLabel>
        <FormControl
          autoFocus
          type="text"
          value={code}
          pattern="[0-9]*"
          inputMode="numeric"
          onChange={handleChange}
          placeholder="6-digit code"
          autoComplete="one-time-code"
        />
        {validation.code && (
          <HelpBlock>Please enter a valid six-digit code.</HelpBlock>
        )}
      </FormGroup>
      <div className="controls">
        <LoaderButton
          type="submit"
          bsSize="large"
          bsStyle="warning"
          loading={isValidating}
          onClick={handleLoginClick}
          disabled={code.trim() === ""}
        >
          Verify
        </LoaderButton>
        <TextButton disabled={isValidating} onClick={onCancelClick}>
          Cancel
        </TextButton>
      </div>
    </form>
  );
}
