import React from "react";
import { Link } from "react-router-dom";
import RightChevron from "./RightChevron";
import { getOrgBillingUrl } from "../lib/urlLib";
import { getOrgName, sortByNumApps } from "../lib/orgsLib";
import config from "../config";
import "./OrgsUpgradeList.css";

export default function OrgsUpgradeList({ orgs = [] }) {
  return (
    <ul className="OrgsUpgradeList">
      {sortByNumApps(orgs).map(({ org }) => (
        <li key={org.name}>
          <Link to={getOrgBillingUrl(org.name)}>
            <span>
              <span className="name">{getOrgName(org)}</span>
              <span className="cost">
                {config.pricingPlans[org.plan].name}
                &nbsp;—&nbsp; ${Math.trunc(org.planCost / 100)} / month
              </span>
            </span>
            <span>
              Upgrade
              <RightChevron />
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );
}
