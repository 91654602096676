import React from "react";
import { Link } from "react-router-dom";
import UserImage from "./UserImage";
import ServiceIcon from "./ServiceIcon";
import SectionInfo from "./SectionInfo";
import RightChevron from "./RightChevron";
import LoaderButton from "./LoaderButton";
import CommitInfoGroup from "./CommitInfoGroup";
import ServiceButtonGroup from "./ServiceButtonGroup";
import AppActivityWorkflow from "./AppActivityWorkflow";
import {
  getAppStageUrl,
  getAppStageBuildTestUrl,
  getAppStageBuildServiceUrl,
  getAppDeployPhaseSettingsUrl,
} from "../lib/urlLib";
import { shortHash } from "../lib/gitLib";
import { truncate } from "../lib/stringLib";
import { dateToFullTimeNoYear } from "../lib/timeLib";
import "./BuildReportPanel.css";

const ERROR_LENGTH = 200;

const buildErrors = {
  build: 1,
  canceled: 2,
};

function statusStateToText(statusState, errorMessage) {
  const textArray = {
    skipped: "Skipped",
    success: "Completed",
    deploying: "In Progress",
    queued: "Queued",
    failure: errorMessage || "Failed",
  };
  return textArray[statusState];
}

function statusStateToIcon(statusState) {
  const textArray = {
    skipped: "minus-circle",
    success: "check-circle",
    deploying: "cog fa-spin",
    queued: "cog fa-spin",
    failure: "times-circle",
  };

  return textArray[statusState];
}

function statusStateToStyle(statusState) {
  const textArray = {
    skipped: "skipped",
    success: "success",
    deploying: "deploying",
    queued: "queued",
    failure: "failure",
  };

  return textArray[statusState];
}

function buildSource(activity) {
  if (activity.actor) {
    switch (activity.action) {
      case "deploy_branch":
      case "deploy_pr":
        return "auto-deployed to";
      case "deploy_cli":
        return "deployed to";
      case "deploy_manual":
        return "manually deployed to";
      case "promote":
        return `promoted ${activity.src_stage_id} to`;
      case "rollback":
        return "rolled back";
      default:
        return "";
    }
  } else {
    switch (activity.action) {
      case "deploy_branch":
      case "deploy_pr":
        return "Auto-deploy to";
      case "deploy_cli":
        return "Deploy to";
      case "deploy_manual":
        return "Manual deploy to";
      case "promote":
        return `Promote ${activity.src_stage_id} to`;
      case "rollback":
        return "Rollback";
      default:
        return "";
    }
  }
}

function renderErrorStep(step, urlArgs, key) {
  if (step.status !== "failure") {
    return;
  }
  if (step.type === "deploy") {
    if (step.project && step.deployment) {
      const logUrl = getAppStageBuildServiceUrl(
        ...urlArgs,
        step.project.service_name
      );
      return (
        <div key={key} className="step">
          <Link to={logUrl}>
            <ServiceIcon size="small" type={step.project.serviceType} />
            {step.project.service_name}
          </Link>
          <p className="message">
            <span className="copy">
              {truncate(step.deployment.message, ERROR_LENGTH)}
            </span>
            <Link to={logUrl}>
              View logs
              <RightChevron />
            </Link>
          </p>
        </div>
      );
    }
  } else if (step.type === "post_deploy_test") {
    return (
      <div key={key} className="post-deploy">
        <p className="message">
          <span className="copy">
            {truncate(step.error_message, ERROR_LENGTH)}
          </span>
          <Link to={getAppStageBuildTestUrl(...urlArgs)}>
            View logs
            <RightChevron />
          </Link>
        </p>
      </div>
    );
  }
}

function renderError(activity, urlArgs) {
  let renderedSteps = [];

  urlArgs.push(activity.stage_id, activity.build_id);

  activity.progress.forEach((phase, i) =>
    phase.forEach((step, j) => {
      const renderedStep = renderErrorStep(step, urlArgs, `${i}-${j}`);
      renderedStep && renderedSteps.push(renderedStep);
    })
  );

  return (
    renderedSteps.length !== 0 && (
      <div className="section-row">
        <SectionInfo stacked className="error" label="Error">
          <div className="steps">{renderedSteps}</div>
        </SectionInfo>
      </div>
    )
  );
}

function renderSkippedError() {
  return (
    <div className="section-row">
      <SectionInfo stacked className="error" label="Error">
        <div className="steps">
          <div className="skipped">
            <p className="message">
              <span className="copy">
                Build minutes limit exceeded. Please upgrade your account.
              </span>
            </p>
          </div>
        </div>
      </SectionInfo>
    </div>
  );
}

function renderActorImage({ imageLink, name, nameInitials }) {
  return (
    <span className="actor-image">
      <UserImage
        size="small"
        name={name}
        src={imageLink}
        initials={nameInitials}
      />
    </span>
  );
}

function renderCommitDiffLink({ from, to, link }) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <span>{shortHash(from)}</span>
      &hellip;
      <span>{shortHash(to)}</span>
    </a>
  );
}

export default function BuildReportPanel({
  appId,
  ownerId,
  activity,
  canRetry,
  pathParams,

  onRetry,
  onCancel,
  isRetrying,
  isCanceling,
}) {
  const action = activity.action;

  function renderHeader() {
    const actor = activity.actor;

    return (
      <>
        {actor && (
          <>
            {renderActorImage(actor)}
            {<span>{actor.name}</span>}&nbsp;
          </>
        )}
        {buildSource(activity)}&nbsp;
        <Link to={getAppStageUrl(ownerId, appId, activity.stage_id)}>
          {activity.stage_id}
        </Link>
      </>
    );
  }

  function renderPromoteRollbackInfo() {
    const servicesDeployed = activity.servicesDeployed;

    return servicesDeployed.type === "all" ? (
      <>All services</>
    ) : servicesDeployed.serviceName ? (
      <ServiceButtonGroup
        appName={appId}
        ownerName={ownerId}
        serviceName={servicesDeployed.serviceName}
        serviceType={servicesDeployed.serviceType}
      />
    ) : (
      <ServiceButtonGroup
        className="removed"
        serviceName="[Removed]"
        serviceType="default"
      />
    );
  }

  function renderCommitDiffs() {
    const commitDiffs = activity.commitDiffs;

    return commitDiffs.length === 1 ? (
      <p>{renderCommitDiffLink(commitDiffs[0])}</p>
    ) : (
      <ul>
        {commitDiffs.map((diff, i) => (
          <li key={i}>{renderCommitDiffLink(diff)}</li>
        ))}
      </ul>
    );
  }

  return (
    <div className="BuildReportPanel">
      <h4>{renderHeader()}</h4>
      <div className="body">
        <div className="section-row">
          <SectionInfo stacked className="status" label="Status">
            <div className={statusStateToStyle(activity.status)}>
              <i className={`fa fa-${statusStateToIcon(activity.status)}`}></i>
              <span className="copy">
                {statusStateToText(activity.status, activity.error_message)}
              </span>
              {(activity.status === "deploying" ||
                activity.status === "queued") && (
                <LoaderButton
                  bsSize="small"
                  bsStyle="danger"
                  onClick={onCancel}
                  loading={isCanceling}
                >
                  Cancel
                </LoaderButton>
              )}
              {activity.status === "failure" && (
                <LoaderButton
                  bsSize="small"
                  onClick={() => onRetry({ force: false })}
                  loading={isRetrying}
                >
                  Retry
                </LoaderButton>
              )}
              {activity.status === "success" && canRetry && (
                <LoaderButton
                  bsSize="small"
                  onClick={() => onRetry({ force: true })}
                  loading={isRetrying}
                >
                  Redeploy
                </LoaderButton>
              )}
            </div>
          </SectionInfo>
          <SectionInfo stacked label="Date" className="date">
            {dateToFullTimeNoYear(activity.created_at)}
          </SectionInfo>
        </div>
        {activity.status === "failure" &&
          activity.error_code !== buildErrors.canceled &&
          renderError(activity, [ownerId, appId])}
        {activity.status === "skipped" &&
          activity.skippedDueToMinutes &&
          renderSkippedError()}
        {activity.commitDiffs &&
          (action === "promote" || action === "rollback") && (
            <div className="section-row commit-changes">
              <SectionInfo stacked label="Updated Services">
                {renderPromoteRollbackInfo()}
              </SectionInfo>
              <SectionInfo stacked label="Commits">
                {renderCommitDiffs()}
              </SectionInfo>
            </div>
          )}
        {activity.commit && (
          <div className="section-row commit-info">
            <SectionInfo stacked label="Commit">
              <CommitInfoGroup activity={activity} />
            </SectionInfo>
          </div>
        )}
        <div className="section-row workflow">
          <SectionInfo stacked label="Workflow">
            <>
              <AppActivityWorkflow
                activity={activity}
                pathParams={pathParams}
              />
              <p className="current">
                Edit the worklow by&nbsp;
                <Link to={getAppDeployPhaseSettingsUrl(ownerId, appId)}>
                  configuring deploy phases
                </Link>
                .
              </p>
            </>
          </SectionInfo>
        </div>
      </div>
    </div>
  );
}
