import React from "react";
import AppTabNav from "../components/AppTabNav";

export default function withAppHeader(Comp) {
  return function ComponentWithAppHeader(props) {
    const { ownerId, appId } = props.match.params;
    return (
      <div className="withAppHeader">
        <div className="app-header">
          {(appId || ownerId) && (
            <AppTabNav type={appId ? "app" : "org"} match={props.match} />
          )}
        </div>
        <Comp {...props} />
      </div>
    );
  };
}
