import React, { useState, useEffect, useReducer } from "react";
import { Link, Redirect } from "react-router-dom";
import withAppHeader from "../components/ComponentWithAppHeader";
import withCancel from "../components/ComponentWithCancel";
import Alert from "../components/Alert";
import ErrorAlert from "../components/ErrorAlert";
import ManualDeploy from "./widgets/ManualDeploy";
import AppAddService from "./widgets/AppAddService";
import ConfirmPromote from "./widgets/ConfirmPromote";
import EmptyListPanel from "../components/EmptyListPanel";
import LoadingSpinner from "../components/LoadingSpinner";
import MiniActivityList from "../components/MiniActivityList";
import MiniPipelineTable from "../components/MiniPipelineTable";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import ResourceSearchModal from "../components/ResourceSearchModal";
import DashboardIssuesPanel from "../components/DashboardIssuesPanel";
import ResourceSearchButton from "../components/ResourceSearchButton";
import PermissionErrorModal from "../components/PermissionErrorModal";
import SelectKeyMetricsModal from "../components/SelectKeyMetricsModal";
import DashboardMetricsPanel, {
  durations,
} from "../components/DashboardMetricsPanel";
import DashboardServicesPanel from "../components/DashboardServicesPanel";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import {
  useSearchModalReducer,
  usePermissionErrorReducer,
} from "../lib/hooksLib";
import {
  errorHandler,
  getLoadError,
  isInvalidIamError,
  isAwsPermissionError,
} from "../lib/errorLib";
import {
  getAppUrl,
  getAppRemoveUrl,
  getAppStageApiLogsUrl,
  getAppStageApiMetricsUrl,
  getAppStageLambdaLogsUrl,
  getAppStageLambdaMetricsUrl,
} from "../lib/urlLib";
import "./Apps.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
  OrgNotExist: "APP_NOT_FOUND",
  4006: "APP_NOT_FOUND",
};

const defaultSelectMetricsState = {
  key: 0,
  show: false,
  updating: false,
};

const defaultPromoteState = {
  show: false,
  tarType: null,
  tarAppStageId: null,
  srcAppStageId: null,
};

const defaultManualDeployState = {
  key: 0,
  show: false,
  stageName: null,
  defaultBranch: null,
  lastDeployedRefs: [],
};

function selectMetricsReducer(state, action) {
  switch (action.type) {
    case "show":
      return { ...state, show: true, key: state.key + 1 };
    case "update":
      return { ...state, updating: true };
    case "hide":
      return { ...state, show: false, updating: false };
    default:
      return state;
  }
}

function cancelBuildReducer(state, action) {
  switch (action.type) {
    case "cancelling":
      return {
        ...state,
        [action.stage]: true,
      };
    case "cancelled":
      return {
        ...state,
        [action.stage]: false,
      };
    default:
      return state;
  }
}

function manualDeployReducer(state, action) {
  switch (action.type) {
    case "show":
      return {
        ...defaultManualDeployState,
        show: true,
        key: state.key + 1,
        stageName: action.stageName,
        defaultBranch: action.defaultBranch,
        lastDeployedRefs: action.lastDeployedRefs,
      };
    case "hide":
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}

function promoteReducer(state, action) {
  switch (action.type) {
    case "show":
      return {
        ...state,
        show: true,
        tarType: action.tarType,
        srcAppStageId: action.srcAppStageId,
        tarAppStageId: action.tarAppStageId,
      };
    case "hide":
      return { ...defaultPromoteState };
    default:
      return state;
  }
}

function addServiceReducer(state, action) {
  switch (action.type) {
    case "show":
      return {
        ...state,
        show: true,
        key: state.key + 1,
      };
    case "hide":
      return {
        ...state,
        show: false,
        key: state.key,
      };
    default:
      return state;
  }
}

function Apps(props) {
  const { ownerId, appId } = props.match.params;

  let isLoading = true;
  let isLoadingSidePanels = true;
  let loadError = null;
  let metricsPermissionError = null;
  let resourcesPermissionError = null;
  let invalidIamError = null;
  let apiError = null;

  let isRemovingOrRemoveFailed = false;
  let isDeployedToProdStage;
  let isDeployedToAnyStage;
  let noServices = false;
  let hasMultiProd = false;
  let prodStageNames = [];
  let prodResourceLinks = null;

  let renderServicesOrMetrics;

  const [keyMetricsDuration, setKeyMetricsDuration] = useState(durations.day);
  const [searchModalState, searchModalDispatch] = useSearchModalReducer();
  const [selectMetricsState, selectMetricsDispatch] = useReducer(
    selectMetricsReducer,
    defaultSelectMetricsState
  );
  const [promoteState, promoteDispatch] = useReducer(
    promoteReducer,
    defaultPromoteState
  );
  const [manualDeployState, manualDeployDispatch] = useReducer(
    manualDeployReducer,
    defaultManualDeployState
  );
  const [cancellingBuilds, cancelBuildDispatch] = useReducer(
    cancelBuildReducer,
    {}
  );
  const [addServiceState, addServiceDispatch] = useReducer(addServiceReducer, {
    key: 0,
    show: false,
  });
  const [
    { error: urlPermissionError, shown: hasUrlPermissionErrorShown },
    urlPermissionErrorDispatch,
  ] = usePermissionErrorReducer();

  //////////
  // Load //
  //////////

  useEffect(() => {
    document.title = title(appId);
  }, [appId]);

  const {
    data: appInfo,
    error: appError,
    reload: reloadAppInfo,
  } = useAPILoad(`/${ownerId}/${appId}`, (path) =>
    props.invokeAppsApig({ path })
  );

  const {
    data: resourceLinksInfo,
    error: resourceLinksError,
  } = useAPILoad(
    `/${ownerId}/${appId}/resources/links?version=v20200224&mode=logs`,
    { polling: false }
  );

  const {
    data: metricsNoDataInfo,
    error: metricsNoDataError,
  } = useAPILoad(
    `/${ownerId}/${appId}/resources/key_metrics?mode=exclude-data`,
    { polling: false }
  );

  const {
    data: metricsWithDataInfo,
    error: metricsWithDataError,
    reload: reloadMetricsInfo,
  } = useAPILoad(
    metricsNoDataInfo &&
      `/${ownerId}/${appId}/resources/key_metrics?mode=include-data&duration=${keyMetricsDuration}`,
    { pollingInterval: 60000 }
  );
  const metricsInfo = metricsWithDataInfo || metricsNoDataInfo;
  const metricsError = metricsWithDataError || metricsNoDataError;

  const {
    data: activitiesInfo,
    error: activitiesError,
    reload: reloadActivitiesInfo,
  } = useAPILoad(`/${ownerId}/${appId}/activities`);

  const { data: issuesInfo, error: issuesError } = useAPILoad(
    `/${ownerId}/${appId}/errors/default_stage/new`,
    {
      polling: false,
    }
  );

  // Render the page when appInfo and metricsData (without data) are loaded
  // b/c there are enough data to render the main panel
  isLoading = !(
    (appInfo !== null || appError) &&
    (resourceLinksInfo !== null || resourceLinksError) &&
    (metricsNoDataInfo !== null || metricsNoDataError)
  );

  // Render the activitiesInfo and issuesInfo together so the side panel does not
  // shift twice
  isLoadingSidePanels = !(
    (activitiesInfo !== null || activitiesError) &&
    (issuesInfo !== null || issuesError)
  );

  // handle responses
  if (appInfo !== null) {
    // deleting or delete_failed => stop polling
    if (
      appInfo.app.status === "deleting" ||
      appInfo.app.status === "delete_failed"
    ) {
      isRemovingOrRemoveFailed = true;
    }

    // Get prod stages
    const prodStages = appInfo.stages.filter((stage) => stage.is_production);
    hasMultiProd = prodStages.length > 1;
    prodStageNames = prodStages.map(({ name }) => name);
    if (resourceLinksInfo !== null) {
      prodResourceLinks = resourceLinksInfo.links.filter(({ stage }) =>
        prodStageNames.includes(stage)
      );
    }

    // Check has deployed
    isDeployedToProdStage = prodStages.some(
      (prodStage) => !!prodStage.latestBuildId
    );
    isDeployedToAnyStage = appInfo.stages.some(
      (stage) => !!stage.latestBuildId
    );

    noServices = appInfo.services.length === 0;
  }

  // handle errors
  // - ignore metrics error (with data) includiing faiiling due to AWS Throttled
  if (appError) {
    loadError = getLoadError(appError, loadErrorCodes);
  }
  if (resourceLinksError) {
    if (isInvalidIamError(resourceLinksError)) {
      invalidIamError = resourceLinksError;
    }
    if (isAwsPermissionError(resourceLinksError)) {
      resourcesPermissionError = resourceLinksError;
    }
  }
  if (metricsError) {
    if (isInvalidIamError(metricsError)) {
      invalidIamError = metricsError;
    }
    if (isAwsPermissionError(metricsError)) {
      metricsPermissionError = metricsError;
    }
  }
  apiError =
    loadError ||
    resourcesPermissionError ||
    metricsPermissionError ||
    invalidIamError
      ? null
      : appError ||
        activitiesError ||
        resourceLinksError ||
        metricsNoDataError ||
        issuesError;

  // Decide render services or metrics
  // case: app NOT deployed (ie. new app, to show a service has been added)
  if (!isDeployedToAnyStage) {
    renderServicesOrMetrics = "services";
  }
  // case: app deployed && app NOT deployed to prod
  else if (!isDeployedToProdStage) {
    renderServicesOrMetrics = "metrics";
  }
  // case: app deployed && app deployed to prod && HAS permission error
  else if (
    resourcesPermissionError ||
    metricsPermissionError ||
    invalidIamError
  ) {
    renderServicesOrMetrics = "metrics";
  }
  // case: app deployed && app deployed to prod && NO permissioon error && HAS resource links
  else if (prodResourceLinks && prodResourceLinks.length > 0) {
    renderServicesOrMetrics = "metrics";
  } else {
    renderServicesOrMetrics = "services";
  }

  function getAppAPI() {
    return `/${ownerId}/${appId}`;
  }

  function getAppStageAPI(stageName) {
    return `/${ownerId}/${appId}/stages/${stageName}`;
  }

  function getAppStageBuildAPI(stageId, buildId) {
    return `/${ownerId}/${appId}/stages/${stageId}/builds/${buildId}`;
  }

  //////////////////
  // API: Metrics //
  //////////////////

  function favoriteAppMetrics(links) {
    return props.invokeApig({
      path: `${getAppAPI()}/resources/link_favorite`,
      method: "POST",
      body: { links: JSON.stringify(links) },
    });
  }

  /////////////////
  // API: Search //
  /////////////////

  function saveRecentSearches(fullName, type) {
    const mode = "logs";
    return props.invokeApig({
      path: `${getAppAPI()}/resources/link_search`,
      method: "POST",
      body: { fullName, type, mode },
    });
  }

  //////////////////
  // API: Promote //
  //////////////////

  function promote() {
    return props.invokeApig({
      path: `${getAppStageAPI(
        promoteState.tarAppStageId
      )}/promote?version=v20200425`,
      method: "POST",
      body: {
        sourceStageName: promoteState.srcAppStageId,
      },
    });
  }

  function getChangeset() {
    return props.invokeApig({
      path: `${getAppStageAPI(
        promoteState.tarAppStageId
      )}/promote_changeset?version=v20200425`,
      queryStringParameters: {
        sourceStageName: promoteState.srcAppStageId,
      },
    });
  }

  function reportChangeset(changeset) {
    return props.invokeApig({
      path: `${getAppStageAPI(promoteState.srcAppStageId)}/report_changeset`,
      method: "POST",
      body: changeset,
    });
  }

  ////////////////////////
  // API: Manual Deploy //
  ////////////////////////

  function deployStage({ appStageId }, branch, force_deploy) {
    return props.invokeApig({
      path: `${getAppStageAPI(appStageId)}/deploy`,
      method: "POST",
      body: { branch, force_deploy },
    });
  }

  /////////////////
  // API: Cancel //
  /////////////////

  function cancelBuild(stageId, buildId) {
    return props.invokeApig({
      path: `${getAppStageBuildAPI(stageId, buildId)}/cancel_build`,
      method: "POST",
    });
  }

  //////////////////////
  // API: Add Service //
  //////////////////////

  function getSlsInfo(path) {
    return props.invokeAppsApig({
      path: `${getAppAPI()}/git_service_info`,
      queryStringParameters: {
        path,
      },
    });
  }

  function addService(name, path, serviceFramework) {
    return props.invokeApig({
      path: `${getAppAPI()}/services?version=v20200823`,
      method: "POST",
      body: { name, path, serviceFramework },
    });
  }

  /////////////////////////////////
  // Handlers - Permission Error //
  /////////////////////////////////

  function handleShowPermissionError() {
    urlPermissionErrorDispatch({
      type: "set",
      error: resourcesPermissionError || metricsPermissionError,
    });
  }

  function handleDismissPermissionError() {
    // clear the error b/c 'dismiss' will not be shown again
    urlPermissionErrorDispatch({ type: "clear" });
  }

  ////////////////////////
  // Handlers - Metrics //
  ////////////////////////

  function buildMetricsUrl({ type, stage, region, fullName, apiId, span }) {
    return type === "lambda"
      ? getAppStageLambdaMetricsUrl(ownerId, appId, stage, region, fullName, {
          span,
        })
      : getAppStageApiMetricsUrl(
          ownerId,
          appId,
          stage,
          region,
          fullName,
          apiId,
          { span }
        );
  }

  async function handleUpdateKeyMetrics(fullNames) {
    selectMetricsDispatch({ type: "update" });

    const links = prodResourceLinks
      .filter(({ fullName }) => fullNames.includes(fullName))
      .sort((linkA, linkB) => {
        if (linkA.type === linkB.type) {
          if (linkA.fullName < linkB.fullName) {
            return -1;
          } else if (linkA.fullName > linkB.fullName) {
            return 1;
          }
          return 0;
        } else if (linkA.type === "api_logs") {
          return -1;
        }
        return 1;
      });

    await favoriteAppMetrics(links);

    await reloadMetricsInfo();

    selectMetricsDispatch({ type: "hide" });
  }

  ///////////////////////
  // Handlers - Search //
  ///////////////////////

  function buildResourceSearchUrl({ fullName, region, type, stage, apiId }) {
    switch (type) {
      case "lambda":
        return getAppStageLambdaLogsUrl(
          ownerId,
          appId,
          stage,
          region,
          fullName
        );
      case "api":
        return getAppStageApiLogsUrl(
          ownerId,
          appId,
          stage,
          region,
          fullName,
          apiId
        );
      default:
        return null;
    }
  }

  function handleResourceSearchSelect({ fullName, type }, url) {
    saveRecentSearches(fullName, type);

    props.history.push(url);
  }

  function handleResourceSearchClick() {
    searchModalDispatch({ type: "show" });
  }

  function handleResourceSearchModalClose() {
    searchModalDispatch({ type: "hide" });
  }

  ////////////////////////
  // Handlers - Promote //
  ////////////////////////

  async function handlePromote(appStage, downstreamStage) {
    promoteDispatch({
      type: "show",
      tarType: downstreamStage.is_production ? "Production" : "Staging",
      srcAppStageId: appStage.id,
      tarAppStageId: downstreamStage.id,
    });
  }

  async function handleConfirmPromoteClick(event) {
    await promote();
    await reloadAppInfo();
    await reloadActivitiesInfo();

    promoteDispatch({ type: "hide" });
  }

  function handleConfirmPromoteCloseClick(event) {
    promoteDispatch({ type: "hide" });
  }

  async function handleConfirmPromoteReportClick(event, changeset) {
    return await reportChangeset(changeset);
  }

  async function handleLoadChangeset(event) {
    return await getChangeset();
  }

  /////////////////////////////
  // Handlers: Manual Deploy //
  /////////////////////////////

  function handleManualDeployModalShow(stage) {
    manualDeployDispatch({
      type: "show",
      stageName: stage.name,
      defaultBranch: stage.branch,
      lastDeployedRefs: stage.lastDeployedRefs,
    });
  }

  function handleManualDeployModalClose(event) {
    manualDeployDispatch({ type: "hide" });
  }

  async function handleManualDeploy(event, branch, deployTo, force_deploy) {
    await deployStage(deployTo, branch, force_deploy);
    await reloadAppInfo();
    await reloadActivitiesInfo();

    manualDeployDispatch({ type: "hide" });
  }

  //////////////////////
  // Handlers: Cancel //
  //////////////////////

  async function handleCancelDeployClick(stage) {
    cancelBuildDispatch({ type: "cancelling", stage: stage.name });

    try {
      await cancelBuild(stage.name, stage.latestBuild.build_id);
      await reloadAppInfo();
      await reloadActivitiesInfo();
    } catch (e) {
      errorHandler(e);
    }

    cancelBuildDispatch({ type: "cancelled", stage: stage.name });
  }

  ////////////////////////////
  // Handlers - Add Service //
  ////////////////////////////

  function handleAddServiceModalShow() {
    addServiceDispatch({ type: "show" });
  }

  function handleAddServiceModalClose(event) {
    addServiceDispatch({ type: "hide" });
  }

  async function handleAddService(name, path, serviceFramework) {
    await addService(name, path, serviceFramework);
    await reloadAppInfo();
    await reloadActivitiesInfo();
    addServiceDispatch({ type: "hide" });
  }

  async function handleSearchPath(path) {
    return await getSlsInfo(path);
  }

  ////////////
  // Render //
  ////////////

  function renderServices() {
    const prodAppStage = appInfo.stages.find((stage) => stage.is_production);

    const servicesData = appInfo.services
      .map(({ service, stages }) => {
        const prodStage = stages.find(
          (stage) => stage.name === prodAppStage.name
        );
        return (
          prodStage && {
            name: service.service_name,
            serviceType: service.serviceType,
            region: prodStage.region,
            stack: prodStage.stack,
            stacks: prodStage.stacks,
            isDeployed: !!prodStage.active_deployment,
          }
        );
      })
      .filter((per) => !!per)
      .slice(0, 4);

    return (
      <DashboardServicesPanel
        pathParams={{ ...props.match.params, appStageId: prodAppStage.name }}
        services={servicesData}
      />
    );
  }

  function renderStages() {
    return (
      <MiniPipelineTable
        onPromote={handlePromote}
        appStages={appInfo.stages}
        pathParams={props.match.params}
        cancellingBuilds={cancellingBuilds}
        emptyPipeline={!isDeployedToAnyStage}
        onCancelDeploy={handleCancelDeployClick}
        onManualDeploy={handleManualDeployModalShow}
      />
    );
  }

  function renderLogSearch() {
    return (
      <ResourceSearchButton
        mode="dashboard"
        text="View Lambda logs or API logs"
        onClick={handleResourceSearchClick}
        disabled={noServices || resourcesPermissionError || invalidIamError}
      />
    );
  }

  function renderNewIssues() {
    return (
      <DashboardIssuesPanel
        issues={issuesInfo.errorGroups}
        stageName={issuesInfo.stageName}
        isDeployed={issuesInfo.isDeployed}
        isIssuesDisabled={appInfo.app.errorMonitorStatus !== "enabled"}
      />
    );
  }

  function renderMetrics() {
    const isLoaded = metricsWithDataInfo !== null || metricsWithDataError;
    return (
      <DashboardMetricsPanel
        loading={!isLoaded}
        hasMultiProd={hasMultiProd}
        duration={keyMetricsDuration}
        isDeployed={isDeployedToProdStage}
        metricsLinkBuilder={buildMetricsUrl}
        onDurationChange={setKeyMetricsDuration}
        metrics={metricsInfo && metricsInfo.metrics}
        metricsPermissionsError={metricsPermissionError}
        onPermissionsErrorClick={handleShowPermissionError}
        resourcesPermissionsError={resourcesPermissionError}
        onEditClick={() => selectMetricsDispatch({ type: "show" })}
      />
    );
  }

  function renderActivities() {
    return (
      <MiniActivityList
        pathParams={props.match.params}
        activities={activitiesInfo.activities.slice(0, 7)}
      />
    );
  }

  return (
    <div className="Apps">
      {invalidIamError && (
        <Alert className="iam-alert" bsStyle="danger">
          <Link to={`${getAppUrl(ownerId, appId)}/settings`}>
            The IAM credentials for this app is invalid. Please update it in the
            app settings.
          </Link>
        </Alert>
      )}

      {isLoading && <LoadingSpinner />}

      {!isLoading && isRemovingOrRemoveFailed && (
        <Redirect to={getAppRemoveUrl(ownerId, appId)} />
      )}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="app"
          code={loadError}
          context={{
            name: appId,
          }}
        />
      )}

      {!isLoading && apiError && <ErrorAlert error={apiError} />}

      {!isLoading && appInfo && (
        <div className="cols">
          <div className="col-1">
            {!noServices && (
              <>
                {renderServicesOrMetrics === "services" && (
                  <div className="cell services">{renderServices()}</div>
                )}
                {renderServicesOrMetrics === "metrics" && (
                  <div className="cell resources">{renderMetrics()}</div>
                )}
                <div className="cell stages">{renderStages()}</div>
              </>
            )}
            {noServices && (
              <EmptyListPanel
                onClick={handleAddServiceModalShow}
                message="Add a service to get started."
              />
            )}
          </div>
          <div className="col-2">
            <div className="col-3">
              <div className="cell search">{renderLogSearch()}</div>
              {!isLoadingSidePanels && issuesInfo && (
                <div className="cell issues">{renderNewIssues()}</div>
              )}
            </div>
            <div className="col-4">
              {!isLoadingSidePanels && activitiesInfo && (
                <div className="cell activity">{renderActivities()}</div>
              )}
            </div>
          </div>

          <ConfirmPromote
            show={promoteState.show}
            onLoad={handleLoadChangeset}
            pathParams={props.match.params}
            onConfirm={handleConfirmPromoteClick}
            promoteTo={promoteState.tarType}
            onClose={handleConfirmPromoteCloseClick}
            onReport={handleConfirmPromoteReportClick}
            downstreamStageId={promoteState.tarAppStageId}
            source={{
              appStageId: promoteState.srcAppStageId,
            }}
          />

          <ManualDeploy
            onDeploy={handleManualDeploy}
            show={manualDeployState.show}
            onClose={handleManualDeployModalClose}
            key={`deploy-${manualDeployState.key}`}
            defaultBranch={manualDeployState.defaultBranch}
            lastDeployedRefs={manualDeployState.lastDeployedRefs}
            deployTo={{
              appStageId: manualDeployState.stageName,
            }}
          />

          {metricsInfo && (
            <SelectKeyMetricsModal
              hasMultiProd={hasMultiProd}
              show={selectMetricsState.show}
              updating={selectMetricsState.updating}
              resources={prodResourceLinks || null}
              key={`metrics-${selectMetricsState.key}`}
              selectedFullNames={metricsInfo.metrics.map(
                ({ fullName }) => fullName
              )}
              onCloseClick={() => selectMetricsDispatch({ type: "hide" })}
              onUpdateClick={handleUpdateKeyMetrics}
            />
          )}

          <ResourceSearchModal
            show={searchModalState.show}
            buildUrl={buildResourceSearchUrl}
            onSelect={handleResourceSearchSelect}
            key={`search-${searchModalState.key}`}
            onCloseClick={handleResourceSearchModalClose}
            recentSearches={
              resourceLinksInfo ? resourceLinksInfo.recentSearches : []
            }
            resourceLinks={resourceLinksInfo ? resourceLinksInfo.links : null}
          />
        </div>
      )}

      <AppAddService
        onAdd={handleAddService}
        show={addServiceState.show}
        onSearch={handleSearchPath}
        onClose={handleAddServiceModalClose}
        key={`service-${addServiceState.key}`}
      />

      <PermissionErrorModal
        type="dashboard"
        error={urlPermissionError}
        onCloseClick={handleDismissPermissionError}
        show={urlPermissionError && !hasUrlPermissionErrorShown}
      />
    </div>
  );
}

export default withAppHeader(withCancel(Apps));
