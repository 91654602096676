import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { Glyphicon } from "react-bootstrap";
import { errorHandler } from "../lib/errorLib";
import { loginBreadcrumb } from "../lib/breadcrumbLib";
import title from "../lib/titleLib";
import ResetPwForm from "../components/ResetPwForm";
import ScreenHeader from "../components/ScreenHeader";
import ResetPwConfirmForm from "../components/ResetPwConfirmForm";
import "./ResetPassword.css";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    document.title = title("Reset your password");

    this.state = {
      isSendingCode: false,
      isConfirming: false,
      confirmedEmail: null,
      confirmed: false,
      email: null,
      user: null,
    };
  }

  async tryForgotPassword(email) {
    try {
      return { user: await Auth.forgotPassword(email) };
    } catch (error) {
      return { error };
    }
  }

  handleCancelClick = () => {
    this.props.history.push("/");
  };

  handleSendCodeClick = async (email) => {
    let user;
    let error;
    let confirmedEmail;

    this.setState({
      email,
      isSendingCode: true,
    });

    const result1 = await this.tryForgotPassword(email);

    if (result1.user) {
      user = result1.user;
      confirmedEmail = email;
    } else if (
      result1.error &&
      result1.error.code === "UserNotFoundException" &&
      email !== email.toLowerCase()
    ) {
      const result2 = await this.tryForgotPassword(email.toLowerCase());

      if (result2.user) {
        user = result2.user;
        confirmedEmail = email.toLowerCase();
      } else {
        error = result2.error;
      }
    } else {
      error = result1.error;
    }

    if (error) {
      errorHandler(error);
      this.setState({ isSendingCode: false });
      return;
    }

    this.setState({ user, confirmedEmail });
  };

  handleConfirmClick = async (event, { code, password }) => {
    this.setState({ isConfirming: true });

    try {
      await Auth.forgotPasswordSubmit(
        this.state.confirmedEmail,
        code,
        password
      );
      this.setState({
        confirmed: true,
      });
    } catch (e) {
      errorHandler(e);
      this.setState({ isConfirming: false });
    }
  };

  render() {
    const { user, confirmed } = this.state;

    return (
      <div className="ResetPassword">
        <ScreenHeader border breadcrumb={loginBreadcrumb(this.props)}>
          Reset Your Password
        </ScreenHeader>

        {user === null && (
          <ResetPwForm
            isSendingCode={this.state.isSendingCode}
            onCancelClick={this.handleCancelClick}
            onSendCodeClick={this.handleSendCodeClick}
          />
        )}
        {user && !confirmed && (
          <ResetPwConfirmForm
            email={this.state.confirmedEmail}
            isConfirming={this.state.isConfirming}
            onConfirmClick={this.handleConfirmClick}
          />
        )}
        {user && confirmed && (
          <div className="confirm-success">
            <Glyphicon glyph="ok" />
            <p>Your password has been reset.</p>
            <p>
              Click <Link to="/login">here</Link> to login with your new
              credentials.
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default ResetPassword;
