import React, { Component } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import FaIcon from "./FaIcon";
import { testOrgName } from "../lib/regexLib";
import LoaderButton from "./LoaderButton";
import "./OrgAddPanel.css";

const noop = () => {};

const defaultProps = {
  adding: false,
  showForm: false,
  onAddClick: noop,
  onShowClick: noop,
  onHideClick: noop,
};

const defaultState = {
  name: "",
  validationError: false,
};

class OrgAddPanel extends Component {
  state = { ...defaultState };

  handleFieldEdit = (event) => {
    const { id, value } = event.target;

    if (id === "name") {
      this.setState({
        validationError: false,
      });
    }

    this.setState({ [id]: value });
  };

  handleAddClick = async (event) => {
    event.preventDefault();

    const { name } = this.state;

    if (name.trim() === "") {
      return;
    }

    if (!testOrgName(name)) {
      this.setState({ validationError: true });
      return;
    }

    await this.props.onAddClick(event, name);
  };

  render() {
    const { name, validationError } = this.state;
    const { adding, showForm } = this.props;

    return (
      <div className="OrgAddPanel">
        {!showForm && (
          <LoaderButton
            bsSize="large"
            bsStyle="block"
            className="newOrg"
            onClick={this.props.onShowClick}
          >
            <FaIcon name="plus" />
            <span className="copy">New Organization</span>
          </LoaderButton>
        )}
        {showForm && (
          <div className="form-container">
            <Form inline onSubmit={this.handleAddClick}>
              <FormGroup controlId="name">
                <FormControl
                  autoFocus
                  type="text"
                  bsSize="large"
                  value={name}
                  disabled={adding}
                  onChange={this.handleFieldEdit}
                  placeholder={
                    name === "" ? "Name of the organization" : undefined
                  }
                />
              </FormGroup>
              <LoaderButton
                bsSize="large"
                className="btnCancel"
                disabled={adding}
                onClick={this.props.onHideClick}
              >
                Cancel
              </LoaderButton>
              <LoaderButton
                type="submit"
                bsSize="large"
                bsStyle="warning"
                className="btnAdd"
                loading={adding}
                onClick={this.handleAddClick}
              >
                Create
              </LoaderButton>
            </Form>
            {validationError && (
              <div className="validation">
                Organization names must start with a letter or number and can
                only contain lowercase letters, numbers, and hyphens.
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

OrgAddPanel.defaultProps = defaultProps;

export default OrgAddPanel;
