import React, { useRef, useMemo, useState } from "react";
import { FormGroup, FormControl } from "react-bootstrap";
import Modal from "./Modal";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import RightChevron from "./RightChevron";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import { useFormReducer } from "../lib/hooksLib";
import "./SelectKeyMetricsModal.css";

const MAX_ITEM = 10;

function find(items, text) {
  text = text.split(" ");

  return items.filter(({ fullName, type }) => {
    const searchName = `${type} ${fullName}`.toLowerCase();
    return text.every((part) => searchName.indexOf(part.toLowerCase()) > -1);
  });
}

export default function SelectKeyMetricsModal({
  show,
  updating,
  hasMultiProd,
  onCloseClick,
  onUpdateClick,
  resources = null,
  selectedFullNames = [],
}) {
  const searchEl = useRef(null);

  const [hitLimit, setHitLimit] = useState(false);

  const defaultUserSelected = {};
  selectedFullNames.forEach(
    (fullName) => (defaultUserSelected[fullName] = true)
  );
  const [userSelected, setUserSelected] = useState(defaultUserSelected);

  const selectedCount = Object.keys(userSelected).length;

  const dirty = !(
    Object.keys(userSelected).length === selectedFullNames.length &&
    Object.keys(userSelected).every((fullName) =>
      selectedFullNames.includes(fullName)
    )
  );
  const hitLimitCs = hitLimit ? "limit" : "";

  const [
    {
      values: { search },
    },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ search: "" });

  const isLoading = resources === null;

  const sortedResources = useMemo(
    () =>
      !isLoading &&
      resources.sort((first, second) => {
        if (first.stage < second.stage) {
          return -1;
        }
        if (first.stage > second.stage) {
          return 1;
        }

        return first.type === second.type
          ? first.fullName < second.fullName
            ? -1
            : 1
          : first.type === "lambda" && second.type === "api"
          ? 1
          : -1;
      }),
    [resources, isLoading]
  );

  const results = useMemo(
    () => (isLoading ? [] : find(sortedResources, search)),
    [search, isLoading, sortedResources]
  );

  function clearFormData() {
    formDispatch({
      value: "",
      id: "search",
      type: "edit",
    });
  }

  function handleClearClick() {
    clearFormData();
    searchEl.current.focus();
  }

  function handleItemClick(fullName) {
    // Case: already select => deselect
    if (userSelected[fullName] === true) {
      setHitLimit(false);
      delete userSelected[fullName];
    }
    // Case: NOT select => select
    else if (Object.keys(userSelected).length < MAX_ITEM) {
      userSelected[fullName] = true;
    }
    // Case: trying to select but at limit
    else if (Object.keys(userSelected).length === MAX_ITEM) {
      setHitLimit(true);
    }

    setUserSelected({ ...userSelected });
  }

  function renderSelected() {
    return Object.keys(userSelected).map((item) => (
      <div key={item} className="item">
        <span>{item}</span>
        <TextButton onClick={() => handleItemClick(item)}>
          <FaIcon name="times" />
        </TextButton>
      </div>
    ));
  }

  return (
    <Modal show={show} onHide={onCloseClick} className="SelectKeyMetricsModal">
      <Modal.Header closeButton>
        <Modal.Title>Key Metrics</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="copy">
          Select a maximum of {MAX_ITEM} API endpoints or Lambda functions.
        </p>
        {!isLoading && resources.length > 6 && (
          <div className="form">
            <FormGroup controlId="search">
              <FormControl
                type="text"
                value={search}
                autoComplete="off"
                placeholder="Filter"
                onChange={handleFieldChange}
                inputRef={(el) => (searchEl.current = el)}
              />
              <label htmlFor="search">
                <FaIcon name="search" />
              </label>
              {search !== "" && (
                <TextButton onMouseDown={handleClearClick}>
                  <FaIcon name="times-circle" />
                </TextButton>
              )}
            </FormGroup>
          </div>
        )}
        {(isLoading || resources.length === 0) && (
          <div className="empty">
            {isLoading && <>Loading resources&hellip;</>}
            {!isLoading && <>No resources found.</>}
          </div>
        )}
        {!isLoading && resources.length > 0 && (
          <div className="list">
            {results.length > 0 &&
              results.map(({ type, fullName, stage }) => (
                <div
                  key={fullName}
                  className="item"
                  onClick={() => handleItemClick(fullName)}
                >
                  <FaIcon name={userSelected[fullName] ? "star" : "star-o"} />
                  <p>
                    {hasMultiProd && (
                      <>
                        <span>{stage}</span>
                        <RightChevron />
                      </>
                    )}
                    <span>{type === "lambda" ? "Lambda" : "API"}</span>
                    <RightChevron />
                    <span>{fullName}</span>
                  </p>
                </div>
              ))}
            {results.length === 0 && (
              <div className="empty">No matching resources found</div>
            )}
          </div>
        )}
        {selectedCount !== 0 && (
          <div className={`selected count-${selectedCount} ${hitLimitCs}`}>
            <SectionHeader>Selected ({selectedCount})</SectionHeader>
            <div className="list">{renderSelected()}</div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <LoaderButton bsStyle="link" onClick={onCloseClick}>
          Cancel
        </LoaderButton>
        <LoaderButton
          bsSize="large"
          bsStyle="warning"
          loading={updating}
          className="btnUpdate"
          disabled={!dirty || selectedCount === 0}
          onClick={() => onUpdateClick(Object.keys(userSelected))}
        >
          Update
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
