import React, { useEffect } from "react";
import { Form, HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import ServiceIcon from "./ServiceIcon";
import TextButton from "./TextButton";
import FaIcon from "./FaIcon";
import { normalizeSearchPath, getServiceNameFromPath } from "../lib/stringLib";
import { trimFields, areFieldsNotEmpty } from "../lib/formLib";
import { testServiceName } from "../lib/regexLib";
import { useFormReducer } from "../lib/hooksLib";
import "./NewAppDetectServicePanel.css";
import config from "../config";

const defaultSelectPath = "select a path";

const noop = () => {};

const defaultFramework = "sst";
const formFields = {
  path: "",
  name: "",
  framework: defaultFramework,
  selected_path: defaultSelectPath,
};

export default function NewAppDetectServicePanel({
  adding = false,
  disabled = false,
  confirmed = false,
  searching = false,
  detectError = false,

  manualAdd = false,
  manuallyAdding = false,

  serviceName = null,
  searchError = null,
  selectedPath = null,
  servicePaths = null,
  serviceFramework = undefined,

  onAddClick = noop,
  onEditClick = noop,
  onChangeClick = noop,
  onCancelClick = noop,
  onConfirmClick = noop,
  onManualAddClick = noop,
  onCancelSearchClick = noop,
}) {
  const [formData, formDispatch] = useFormReducer(formFields);

  useEffect(() => {
    formDispatch({
      type: "edit",
      id: "path",
      value: selectedPath === null ? "" : normalizeSearchPath(selectedPath),
    });
    formDispatch({
      type: "edit",
      id: "name",
      value: selectedPath === null ? "" : getServiceNameFromPath(selectedPath),
    });
  }, [selectedPath, formDispatch]);

  useEffect(() => {
    formDispatch({
      type: "edit",
      id: "framework",
      value:
        serviceFramework === undefined ? defaultFramework : serviceFramework,
    });
  }, [serviceFramework, formDispatch]);

  const status = disabled
    ? "disabled"
    : searching
    ? "searching"
    : confirmed
    ? "confirmed"
    : manualAdd || detectError
    ? "add"
    : searchError
    ? "not-found"
    : "detected";

  const slsYmlPath = selectedPath && normalizeSearchPath(selectedPath);

  const showFrameworkSelect =
    status === "add" && searchError
      ? searchError === config.serviceSearchErrors.GENERIC_NOT_FOUND ||
        searchError === config.serviceSearchErrors.UNKNOWN
      : true;

  function canSubmit() {
    return areFieldsNotEmpty(getFormFields());
  }

  function getFormFields() {
    return trimFields({
      path: formData.values.path,
      name: formData.values.name,
      framework: formData.values.framework,
    });
  }

  function handleFieldChange(event) {
    const { id, value } = event.target;

    formDispatch({
      id,
      value,
      type: "edit",
    });
  }

  function handleConfirmClick(event) {
    event.preventDefault();

    const formFields = getFormFields();

    if (!canSubmit()) {
      return;
    }

    if (!testServiceName(formFields.name)) {
      formDispatch({
        id: "name",
        type: "validate",
      });
      return;
    }

    onConfirmClick(event, formFields);
  }

  function handleAddClick(event) {
    const path =
      servicePaths.length === 1
        ? servicePaths[0]
        : formData.values.selected_path;

    onAddClick(event, path);
  }

  return (
    <div className={`NewAppDetectServicePanel ${status}`}>
      <div className="wrapper">
        {status === "detected" && (
          <>
            <div className="icon">
              <FaIcon name="lightbulb-o" />
            </div>
            <div className="body">
              {servicePaths.length === 1 && (
                <p>
                  We detected a service in your repo. Do you want to add this
                  service? You can always add additional services later.
                </p>
              )}
              {servicePaths.length > 1 && (
                <p>
                  We detected a couple of services in your repo. Select a
                  service you want to add. You can always add additional
                  services later.
                </p>
              )}
              <Form inline className="select-add">
                <FormGroup controlId="selected_path">
                  <FormControl
                    bsSize="large"
                    disabled={adding}
                    componentClass="select"
                    value={formData.values.selected_path}
                    onChange={handleFieldChange}
                  >
                    {servicePaths.length > 1 && (
                      <option disabled value={defaultSelectPath}>
                        Select a service
                      </option>
                    )}
                    {servicePaths.map((servicePath, i) => (
                      <option key={i} value={servicePath}>
                        {servicePath}
                      </option>
                    ))}
                  </FormControl>
                </FormGroup>
                <LoaderButton
                  bsSize="large"
                  bsStyle="warning"
                  loading={adding}
                  onClick={handleAddClick}
                  disabled={
                    servicePaths.length > 1 &&
                    formData.values.selected_path === defaultSelectPath
                  }
                >
                  Add Service
                </LoaderButton>
              </Form>
              <TextButton
                disabled={adding}
                className="btnChange"
                onClick={(e) => onManualAddClick(e, true)}
              >
                Can&apos;t find the service you are looking for? Add it
                manually.
              </TextButton>
            </div>
          </>
        )}
        {status === "confirmed" && (
          <>
            <div className="icon">
              <ServiceIcon size="medium" type={serviceFramework} />
            </div>
            <div className="body">
              <p>
                Adding the <b>{serviceName}</b> service to your app.
              </p>
            </div>
          </>
        )}
        {status === "not-found" && (
          <>
            <div className="icon">
              <FaIcon name="exclamation-triangle" />
            </div>
            <div className="body">
              <p>
                {(searchError === config.serviceSearchErrors.UNKNOWN ||
                  searchError ===
                    config.serviceSearchErrors.GENERIC_NOT_FOUND ||
                  searchError === config.serviceSearchErrors.SST_NOT_FOUND ||
                  searchError === config.serviceSearchErrors.SLS_NOT_FOUND) && (
                  <>
                    There was a problem loading the service in{" "}
                    <code>{slsYmlPath}</code>.
                  </>
                )}
                {(searchError === config.serviceSearchErrors.SLS_CANNOT_PARSE ||
                  searchError ===
                    config.serviceSearchErrors.SST_CANNOT_PARSE) && (
                  <>
                    We could not parse the service in <code>{slsYmlPath}</code>.
                  </>
                )}
                {(searchError ===
                  config.serviceSearchErrors.SLS_INVALID_SERVICE_NAME ||
                  searchError ===
                    config.serviceSearchErrors.SST_INVALID_SERVICE_NAME) && (
                  <>
                    We could not parse the service name in{" "}
                    <code>{slsYmlPath}</code>.
                  </>
                )}
                {searchError ===
                  config.serviceSearchErrors.SLS_JS_NOT_SUPPORTED && (
                  <>
                    We could not parse the <code>serverless.js</code> file in{" "}
                    <code>{selectedPath}</code>.
                  </>
                )}
              </p>

              <div className="controls">
                <LoaderButton bsSize="large" onClick={onManualAddClick}>
                  Add it manually
                </LoaderButton>
                {servicePaths && (
                  <TextButton onClick={onChangeClick}>
                    {servicePaths.length === 1 && "Try again"}
                    {servicePaths.length > 1 && "Try a different service"}
                  </TextButton>
                )}
              </div>
            </div>
          </>
        )}
        {status === "add" && (
          <>
            <div className="icon">
              <FaIcon name="plus" />
            </div>
            <div className="body">
              <p>
                {detectError === "error" &&
                  "We were unable to auto-detect your services. "}
                {serviceFramework === "sst" || formData.values.framework === "sst"
                  ? (<>Enter the path to the <b>sst.config.ts</b> or <b>sst.json</b>, and the <b>name</b> of a service to add it manually.</>)
                  : (<>Enter the path to the <b>serverless.yml</b> and the <b>name</b> of a service to add it manually.</>)
                } You can always add other services later.
              </p>
              <Form inline className="manual-add" onSubmit={handleConfirmClick}>
                <div className="fields">
                  <FormGroup controlId="path">
                    <StyledControlLabel>Service Path</StyledControlLabel>
                    <FormControl
                      bsSize="large"
                      type="text"
                      value={formData.values.path}
                      onChange={handleFieldChange}
                      placeholder="/path/to/service"
                    />
                  </FormGroup>
                  {showFrameworkSelect && (
                    <FormGroup className="service-type" controlId="framework">
                      <StyledControlLabel>Service Type</StyledControlLabel>
                      <FormControl
                        bsSize="large"
                        componentClass="select"
                        onChange={handleFieldChange}
                        value={formData.values.framework}
                      >
                        <option value="sst">SST</option>
                        <option value="sls">Serverless Framework</option>
                      </FormControl>
                    </FormGroup>
                  )}
                  <FormGroup
                    controlId="name"
                    validationState={formData.validation.name}
                  >
                    <StyledControlLabel>Service Name</StyledControlLabel>
                    <FormControl
                      type="text"
                      bsSize="large"
                      placeholder="my-service"
                      value={formData.values.name}
                      onChange={handleFieldChange}
                    />
                  </FormGroup>
                </div>
                {formData.validation.name && (
                  <HelpBlock>
                    Service names must start with a letter or number and can
                    only contain uppercase or lowercase letters, numbers, and
                    hyphens.
                  </HelpBlock>
                )}
                <div className="controls">
                  <LoaderButton
                    type="submit"
                    bsSize="large"
                    bsStyle="warning"
                    disabled={!canSubmit()}
                    loading={manuallyAdding}
                    onClick={handleConfirmClick}
                  >
                    Add Service
                  </LoaderButton>
                  {!detectError && (
                    <TextButton onClick={onCancelClick}>Cancel</TextButton>
                  )}
                </div>
              </Form>
            </div>
          </>
        )}
        {status === "searching" && (
          <>
            <div className="icon">
              <FaIcon name="spin fa-circle-o-notch" />
            </div>
            <div className="body">
              <p>
                Auto-detecting services in your repo&hellip;&nbsp; Or
                alternatively,&nbsp;
                <TextButton onClick={onCancelSearchClick}>
                  you can add a service manually
                </TextButton>
                .
              </p>
            </div>
          </>
        )}
        {status === "disabled" && (
          <>
            <div className="icon">
              <FaIcon name="plus" />
            </div>
            <div className="body">
              <p>Add a service from your repo&hellip;</p>
            </div>
          </>
        )}
      </div>
      {confirmed && (
        <div className="controls">
          <TextButton onClick={onEditClick}>Change the service</TextButton>
        </div>
      )}
    </div>
  );
}
