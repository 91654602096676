import React, { useState } from "react";
import TextButton from "./TextButton";
import { isCustomPlan } from "../lib/billingLib";
import "./PricingPlansPanel.css";

const noop = () => {};

export default function PricingPlansPanel({
  plans = null,
  planId = null,
  customPlan = null,
  activePlanIds = null,
  onSelect = noop,
}) {
  const [selectedPlanId, setSelectedPlanId] = useState(planId);

  function handlePlanClick(event, selectedPlanId) {
    setSelectedPlanId(selectedPlanId);
    onSelect(event, selectedPlanId);
  }

  function renderCustomPlanDesc() {
    const { baseSeats, baseAmount, perSeatAmount } = customPlan;
    const usersCopy = baseSeats === 1 ? "user" : "users";

    return `Custom $${baseAmount} / month plan. Includes ${baseSeats} ${usersCopy}, add a user for $${perSeatAmount} / month.`;
  }

  function renderPlan(id) {
    const isCustom = isCustomPlan(id);
    const disabled = isCustom && customPlan === null;

    const selectedCs = selectedPlanId === id ? "selected" : "";
    const disabledCs = disabled ? "disabled" : "";

    return (
      <li
        key={id}
        className={`${selectedCs} ${disabledCs}`}
        onClick={disabled ? undefined : (e) => handlePlanClick(e, id)}
      >
        <div>&nbsp;</div>
        <div>
          <p>
            <TextButton disabled={disabled}>{plans[id].name}</TextButton>
          </p>
          <p>
            {isCustom
              ? disabled
                ? plans[id].disabledDesc
                : renderCustomPlanDesc()
              : plans[id].desc}
          </p>
        </div>
      </li>
    );
  }

  return <ul className="PricingPlansPanel">{activePlanIds.map(renderPlan)}</ul>;
}
