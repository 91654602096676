import React, { useState } from "react";
import OrgTokenPanel from "../../components/OrgTokenPanel";
import { errorHandler } from "../../lib/errorLib";

export default function OrgToken({ token, onSave }) {
  const [showing, setShowing] = useState(false);
  const [enabling, setEnabling] = useState(false);
  const [removing, setRemoving] = useState(false);

  async function handleEnableClick(event, token) {
    setEnabling(true);

    try {
      await onSave({ token: true });

      setEnabling(false);
      setShowing(true);
    } catch (e) {
      setEnabling(false);
      errorHandler(e);
    }
  }

  async function handleRemoveClick() {
    if (!window.confirm("Are you sure you want to remove the CLI token?")) {
      return;
    }

    setRemoving(true);

    try {
      await onSave({ token: null });

      setRemoving(false);
      setShowing(false);
    } catch (e) {
      setRemoving(false);
      errorHandler(e);
    }
  }

  return (
    <OrgTokenPanel
      token={token}
      showing={showing}
      enabling={enabling}
      removing={removing}
      onEnableClick={handleEnableClick}
      onRemoveClick={handleRemoveClick}
      onShowClick={() => setShowing(true)}
      onHideClick={() => setShowing(false)}
    />
  );
}
