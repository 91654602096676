const REQUEST_WITH_ID_END_CUSHION = 10;
const REQUEST_WITHOUT_ID_END_CUSHION = 100;

export const LOG_LEVEL = {
  INFO: 1,
  WARN: 2,
  ERROR: 3,
};

export function buildRequestsWithGrouping(eventsAndButtons) {
  const requestsAndButtons = [];
  let requestsById = {};
  const latestRequestWithoutIdByStream = {};
  const latestRequestWithIdByStream = {};

  eventsAndButtons.forEach((event) => {
    // do not merge across more buttons
    if (event.type === "moreButton") {
      requestsAndButtons.push(event);
      requestsById = {};
      return;
    }

    const logStreamName = event.ls;
    const requestId = event.meta.requestId;
    let request;

    // Case 1: group logs with request id => group by request id
    if (requestId) {
      request = requestsById[requestId];
      // Case 1a: request with id does not exist => create new request
      // Case 1b: request with id exists, AND existing request ended => create new request
      if (
        !request ||
        (request.meta.endTime &&
          event.meta.startTime &&
          event.meta.startTime > request.meta.endTime)
      ) {
        request = {
          id: requestId,
          logs: [],
          logStreamName,
          meta: {},
        };
        requestsAndButtons.push(request);
        requestsById[requestId] = request;
      }
      // Case 1c: request with id exists, AND existing request NOT ended => group with request
      else {
      }
      // update open groups
      latestRequestWithIdByStream[logStreamName] = request;
    }

    // Case 2: group logs without request id => group by stream name
    // note: group consecutive requests without request id within 10ms together
    else {
      // case 2a: group event into an open group with request id
      if (
        latestRequestWithIdByStream[logStreamName] &&
        (!latestRequestWithIdByStream[logStreamName].meta.endTime ||
          event.t - latestRequestWithIdByStream[logStreamName].meta.endTime <=
            REQUEST_WITH_ID_END_CUSHION ||
          event.m.startsWith("Unknown application error occurred"))
      ) {
        request = latestRequestWithIdByStream[logStreamName];
      }
      // case 2b: group event into an previous request, and is within 100ms timespan
      // note: longest observed was 70ms
      else if (
        latestRequestWithoutIdByStream[logStreamName] &&
        event.t - latestRequestWithoutIdByStream[logStreamName].logs[0].t <=
          REQUEST_WITHOUT_ID_END_CUSHION
      ) {
        request = latestRequestWithoutIdByStream[logStreamName];
      }
      // case 2c: create new group
      else {
        request = {
          id: event.i,
          logs: [],
          logStreamName,
          meta: {},
        };
        requestsAndButtons.push(request);
      }
      latestRequestWithoutIdByStream[logStreamName] = request;
    }

    // append request log
    request.meta = { ...request.meta, ...event.meta };
    request.logs.push(event);
  });

  return requestsAndButtons;
}
export function findSingleRequestForLambda(
  requestsAndButtons,
  requestTimestamp
) {
  return requestsAndButtons.find((request) => {
    if (request.type === "moreButton") {
      return false;
    }
    const begin = request.logs[0].t;
    const end = request.logs[request.logs.length - 1].t;
    return begin <= requestTimestamp && end >= requestTimestamp;
  });
}

export function parseLambdaLogMetadata(event, runtime) {
  // Metadata format:
  // {
  //    level,
  //    summary,
  //    requestId,
  //    startTime,
  //    endTime,
  //    duration,
  //    memSize,
  //    memUsed,
  //    xrayTraceId,
  // }

  let meta;

  try {
    meta =
      meta ||
      parseLambdaSTART(event) ||
      parseLambdaEND(event) ||
      parseLambdaREPORT(event);

    const tabParts = event.m.split("\t");
    const spcParts = event.m.split(" ");

    meta =
      meta ||
      parseLambdaUnknownApplicationError(event) ||
      parseLambdaModuleInitializationError(event) ||
      parseLambdaExited(event, spcParts) ||
      parseLambdaTimeoutOrMessage(event, spcParts);

    ///////////////////
    // Node Errors
    ///////////////////
    if (runtime.startsWith("nodejs")) {
      meta = meta || parseLambdaNodeLog(event, tabParts);
    }

    ///////////////////
    // Python Errors
    ///////////////////
    if (runtime.startsWith("python")) {
      meta =
        meta ||
        parseLambdaPythonLog(event, tabParts) ||
        parseLambdaPythonTraceback(event);
    }
  } catch (e) {
    // TODO send to sentry
  }

  return (
    meta || {
      level: LOG_LEVEL.INFO,
      summary: event.m,
    }
  );
}
function parseLambdaSTART(event) {
  // START RequestId: 184b0c52-84d2-4c63-b4ef-93db5bb2189c Version: $LATEST
  if (event.m.startsWith("START RequestId: ")) {
    return {
      requestId: event.m.substr(17, 36),
      startTime: event.t,
      level: LOG_LEVEL.INFO,
    };
  }
}
function parseLambdaEND(event) {
  // END RequestId: 184b0c52-84d2-4c63-b4ef-93db5bb2189c
  if (event.m.startsWith("END RequestId: ")) {
    return {
      requestId: event.m.substr(15, 36),
      endTime: event.t,
      level: LOG_LEVEL.INFO,
    };
  }
}
function parseLambdaREPORT(event) {
  // REPORT RequestId: 6cbfe426-927b-43a3-b7b6-a525a3fd2756	Duration: 2.63 ms	Billed Duration: 100 ms	Memory Size: 1024 MB	Max Memory Used: 58 MB	Init Duration: 2.22 ms
  if (event.m.startsWith("REPORT RequestId: ")) {
    const meta = {
      requestId: event.m.substr(18, 36),
      level: LOG_LEVEL.INFO,
    };
    event.m.split("\t").forEach((part) => {
      part = part.trim();
      if (part.startsWith("Duration")) {
        meta.duration = part.split(" ")[1];
      } else if (part.startsWith("Memory Size")) {
        meta.memSize = part.split(" ")[2];
      } else if (part.startsWith("Max Memory Used")) {
        meta.memUsed = part.split(" ")[3];
      } else if (part.startsWith("XRAY TraceId")) {
        meta.xrayTraceId = part.split(" ")[2];
      }
    });
    return meta;
  }
}
function parseLambdaTimeoutOrMessage(event, spcParts) {
  // 2018-01-05T23:48:40.404Z f0fc759e-f272-11e7-87bd-577699d45526 hello
  // 2018-01-05T23:48:40.404Z f0fc759e-f272-11e7-87bd-577699d45526 Task timed out after 6.00 seconds
  if (
    spcParts.length >= 3 &&
    spcParts[0].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/) !==
      null &&
    spcParts[1].match(/^[0-9a-fA-F-]{36}$/) !== null
  ) {
    const requestId = spcParts[1];
    const summary = spcParts.slice(2).join(" ");
    const level = summary.startsWith("Task timed out after")
      ? LOG_LEVEL.ERROR
      : LOG_LEVEL.INFO;
    return {
      requestId,
      level,
      summary,
    };
  }
}
function parseLambdaNodeLog(event, tabParts) {
  // - Nodejs 8.10
  // 2019-11-12T20:00:30.183Z	cc81b998-c7de-46fb-a9ef-3423ccdcda98	log hello
  // - Nodejs 10.x
  // 2019-11-12T20:00:30.183Z	cc81b998-c7de-46fb-a9ef-3423ccdcda98	INFO	log hello
  // 2019-11-12T20:00:30.184Z	cc81b998-c7de-46fb-a9ef-3423ccdcda98	WARN	warn hello
  // 2019-11-12T20:00:30.184Z	cc81b998-c7de-46fb-a9ef-3423ccdcda98	ERROR	error hello
  // 2019-11-12T20:15:19.686Z	77c628d3-d6cf-4643-88ac-bc9520ed3858	ERROR	Invoke Error
  // {
  //     "errorType": "ReferenceError",
  //     "errorMessage": "b is not defined",
  //     "stack": [
  //         "ReferenceError: b is not defined",
  //         "    at Runtime.module.exports.main [as handler] (/var/task/handler.js:9:15)",
  //         "    at Runtime.handleOnce (/var/runtime/Runtime.js:66:25)"
  //     ]
  // }
  // 2019-11-12T20:45:05.363Z	undefined	ERROR	Uncaught Exception
  // {
  //     "errorType": "ReferenceError",
  //     "errorMessage": "bad is not defined",
  //     "stack": [
  //         "ReferenceError: bad is not defined",
  //         "    at Object.<anonymous> (/var/task/handler.js:1:1)",
  //         "    at Module._compile (internal/modules/cjs/loader.js:778:30)",
  //     ]
  // }
  if (
    tabParts.length >= 3 &&
    tabParts[0].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/) !== null
  ) {
    // parse request id
    const requestId =
      tabParts[1].match(/^[0-9a-fA-F-]{36}$/) !== null
        ? tabParts[1]
        : undefined;
    let level;
    let summary;
    // parse level
    if (tabParts[2] === "INFO") {
      level = LOG_LEVEL.INFO;
      summary = tabParts.slice(3).join("\t");
    } else if (tabParts[2] === "WARN") {
      level = LOG_LEVEL.WARN;
      summary = `Warn: ${tabParts.slice(3).join("\t")}`;
    } else if (tabParts[2] === "ERROR") {
      level = LOG_LEVEL.ERROR;
      try {
        const errorObject = JSON.parse(tabParts[4]);
        summary = errorObject.stack[0];
      } catch (e) {
        summary = `Error: ${tabParts.slice(3).join("\t")}`;
      }
    } else {
      level = LOG_LEVEL.INFO;
      summary = tabParts.slice(2).join("\t");
    }
    return {
      requestId,
      level,
      summary,
    };
  }
}
function parseLambdaExited(event, spcParts) {
  // - Nodejs, Python 3.8
  // RequestId: 80925099-25b1-4a56-8f76-e0eda7ebb6d3 Error: Runtime exited with error: signal: aborted (core dumped)
  // - Python 2.7, 3.6, 3.7
  // RequestId: 80925099-25b1-4a56-8f76-e0eda7ebb6d3 Process exited before completing request
  if (
    spcParts.length >= 3 &&
    spcParts[0] === "RequestId:" &&
    spcParts[1].match(/^[0-9a-fA-F-]{36}$/) !== null
  ) {
    return {
      requestId: spcParts[1],
      level: LOG_LEVEL.ERROR,
      summary: spcParts.slice(2).join(" "),
    };
  }
}
function parseLambdaUnknownApplicationError(event) {
  // Unknown application error occurred
  if (event.m.startsWith("Unknown application error occurred")) {
    return {
      level: LOG_LEVEL.ERROR,
      summary: event.m,
    };
  }
}
function parseLambdaPythonLog(event, tabParts) {
  // [WARNING] 2019-11-12T20:00:30.183Z	cc81b998-c7de-46fb-a9ef-3423ccdcda98 this is a warn
  // [ERROR] 2019-11-12T20:00:30.184Z	cc81b998-c7de-46fb-a9ef-3423ccdcda98 this is an error
  // [CRITICAL] 2019-11-12T20:00:30.184Z	cc81b998-c7de-46fb-a9ef-3423ccdcda98 this is critical
  if (
    tabParts.length >= 4 &&
    tabParts[1].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/) !==
      null &&
    tabParts[2].match(/^[0-9a-fA-F-]{36}$/) !== null
  ) {
    let level;
    // parse level
    if (tabParts[0] === "[INFO]") {
      level = LOG_LEVEL.INFO;
    } else if (tabParts[0] === "[WARNING]") {
      level = LOG_LEVEL.WARN;
    } else if (tabParts[0] === "[ERROR]" || tabParts[0] === "[CRITICAL]") {
      level = LOG_LEVEL.ERROR;
    } else {
      level = LOG_LEVEL.INFO;
    }
    return {
      requestId: tabParts[2],
      level,
      summary: `${tabParts[0]} ${tabParts.slice(3).join("\t")}`,
    };
  }
}
function parseLambdaPythonTraceback(event) {
  // ...  Traceback (most recent call last): ...
  if (event.m.match(/\sTraceback \(most recent call last\):\s/) !== null) {
    const lineParts = event.m.split("\n");
    return {
      level: LOG_LEVEL.ERROR,
      summary: lineParts[0],
    };
  }
}
function parseLambdaModuleInitializationError(event) {
  // module initialization error
  if (event.m.startsWith("module initialization error")) {
    return { level: LOG_LEVEL.ERROR, summary: event.m };
  }
}

export function parseAccessLogFormatFieldNames(format) {
  // ie. message = "requestId: 1ba3c3c0-ef1c-4ecc-85ac-aa2249ed22c6, ip: 99.244.188.99";

  // defined a regex that search for $context.X.Y.Z
  const regex = new RegExp(/\$context[.a-zA-Z0-9_]*/, "g");

  try {
    // get all $context variable names
    // ie. format = "requestId: $context.requestId, ip: $context.identity.sourceIp";
    // ie. fieldNames = [ '$context.requestId', '$context.identity.sourceIp' ];
    return format.match(regex);
  } catch (e) {
    return null;
  }
}
export function parseAccessLogFormatRegex(format) {
  // ie. message = "requestId: 1ba3c3c0-ef1c-4ecc-85ac-aa2249ed22c6, ip: 99.244.188.99";

  // defined a regex that search for $context.X.Y.Z
  const regex = new RegExp(/\$context[.a-zA-Z0-9_]*/, "g");

  try {
    // create a regex used to match against messages
    // ie. formatRegex = "requestId: (\.*), ip: (\.*)";
    return format
      .replace(regex, "__REGEX_PLACE_HOLDER__")
      .replace(/[|\\{}()[\]^$+*?.-]/g, "\\$&") // escape regex characters
      .replace(/__REGEX_PLACE_HOLDER__/g, "(.*)");
  } catch (e) {
    return null;
  }
}
export function parseAccessLogMetaData(event, fieldNames, regex) {
  const messageFields = {};
  let requestId;
  let httpStatus;
  let httpMethod;
  let resourcePath;
  let xrayTraceId;
  let sourceIp;
  let responseLatency;

  try {
    if (fieldNames === null || regex == null) {
      throw new Error("Regex failed.");
    }

    // ie. message = "requestId: 1ba3c3c0-ef1c-4ecc-85ac-aa2249ed22c6, ip: 99.244.188.99";

    // get all $context values
    // ie. ret =
    //        [ 'requestId: 1ba3c3c0-ef1c-4ecc-85ac-aa2249ed22c6, ip: 99.244.188.99',
    //          '1ba3c3c0-ef1c-4ecc-85ac-aa2249ed22c6',
    //          '99.244.188.99',
    //          index: 0,
    //          input: 'requestId: 1ba3c3c0-ef1c-4ecc-85ac-aa2249ed22c6, ip: 99.244.188.99',
    //          groups: undefined ]
    const fieldValues = event.m.match(regex).slice(1);

    if (fieldValues.length !== fieldNames.length) {
      throw new Error("$context variable count mismatch.");
    }

    fieldNames.forEach((varName, i) => {
      const fieldValue = fieldValues[i];

      messageFields[parseAccessLogEventName(varName)] = fieldValue;

      if (varName === "$context.requestId") {
        requestId = fieldValue;
      } else if (varName === "$context.status") {
        httpStatus = fieldValue;
      } else if (varName === "$context.httpMethod") {
        httpMethod = fieldValue;
      } else if (varName === "$context.identity.sourceIp") {
        sourceIp = fieldValue;
      } else if (varName === "$context.resourcePath") {
        resourcePath = fieldValue;
      } else if (varName === "$context.responseLatency") {
        responseLatency = fieldValue;
      } else if (varName === "$context.xrayTraceId" && fieldValue !== "-") {
        const parts = fieldValue.split("=");
        if (parts.length === 2 && parts[0] === "Root") {
          xrayTraceId = parts[1];
        }
      }
    });
  } catch (e) {}

  return {
    level:
      httpStatus && parseInt(httpStatus) >= 400
        ? LOG_LEVEL.ERROR
        : LOG_LEVEL.INFO,
    requestId,
    httpStatus,
    httpMethod,
    resourcePath,
    xrayTraceId,
    sourceIp,
    responseLatency,
    messageFields,
  };
}
function parseAccessLogEventName(name) {
  const parts = name.split(".");

  if (
    name.startsWith("$context.authorizer.claims.") ||
    name.startsWith("$context.requestOverride.header.") ||
    name.startsWith("$context.requestOverride.path.") ||
    name.startsWith("$context.requestOverride.querystring.") ||
    name.startsWith("$context.responseOverride.header.")
  ) {
    return `${camelToSpace(parts[1])} ${camelToSpace(parts[2])} ${parts
      .slice(3)
      .join(".")}`;
  }

  if (
    name.startsWith("$context.authorizer.") &&
    name !== "$context.authorizer.principalId"
  ) {
    return `${camelToSpace(parts[1])} ${parts.slice(2).join(".")}`;
  }

  return parts
    .slice(1)
    .map((part) => camelToSpace(part))
    .join(" ");
}

function camelToSpace(str) {
  const spacedStr = str.replace(/([A-Z])/g, " $1");
  return spacedStr.charAt(0).toUpperCase() + spacedStr.substring(1);
}
