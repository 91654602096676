export function isFreePlan(planId) {
  return planId === "101";
}

export function isTeamPlan(planId) {
  return planId === "109";
}

export function isEnterprisePlan(planId) {
  return planId === "enterprise" || planId === "enterprise_v2";
}

export function isCustomPlan(planId) {
  return planId === "108";
}
