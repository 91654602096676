import React from "react";
import { Form, HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import Modal from "./Modal";
import LoaderButton from "./LoaderButton";
import StyledControlLabel from "./StyledControlLabel";
import { useFormReducer } from "../lib/hooksLib";
import { trimFields } from "../lib/formLib";
import "./OrgGitModal.css";

const copyMapping = {
  github: {
    label: "GitHub Enterprise",
    domainPlaceholder: "ghe.my-corp-domain.com",
    clientId: "Client ID",
    clientSecret: "Client Secret",
  },
  gitlab: {
    label: "GitLab Self-Managed",
    domainPlaceholder: "gitlab.my-corp-domain.com",
    clientId: "Application ID",
    clientSecret: "Secret",
  },
};

export default function OrgGitModal({
  type = null,
  available = false,
  domain = null,
  clientId = null,

  show = false,
  saving = false,
  onSaveClick,
  onCloseClick,
}) {
  const [
    { values: formData, isDirty, validation },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ domain, clientId });

  function canSubmit() {
    return isDirty.clientSecret || isDirty.domain || isDirty.clientId;
  }

  function handleSaveClick(event) {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    const { domain, clientId, clientSecret } = trimFields(formData);

    if (domain === "") {
      formDispatch({
        id: "domain",
        type: "validate",
      });
      return;
    }

    if (clientId === "") {
      formDispatch({
        id: "clientId",
        type: "validate",
      });
      return;
    }

    if (clientSecret === "") {
      formDispatch({
        id: "clientSecret",
        type: "validate",
      });
      return;
    }

    onSaveClick({ domain, clientId, clientSecret });
  }

  function renderForm() {
    return (
      <Form onSubmit={handleSaveClick}>
        <FormGroup controlId="domain" validationState={validation.domain}>
          <StyledControlLabel>Domain</StyledControlLabel>
          <FormControl
            type="text"
            bsSize="large"
            value={formData.domain}
            disabled={saving}
            onChange={handleFieldChange}
            placeholder={copyMapping[type].domainPlaceholder}
          />
          {validation.domain && (
            <HelpBlock>Please enter a valid domain.</HelpBlock>
          )}
        </FormGroup>
        <FormGroup controlId="clientId" validationState={validation.clientId}>
          <StyledControlLabel>{copyMapping[type].clientId}</StyledControlLabel>
          <FormControl
            type="text"
            bsSize="large"
            value={formData.clientId}
            disabled={saving}
            onChange={handleFieldChange}
          />
          {validation.clientId && (
            <HelpBlock>
              Please enter a valid {copyMapping[type].clientId}.
            </HelpBlock>
          )}
        </FormGroup>
        <FormGroup
          controlId="clientSecret"
          validationState={validation.clientSecret}
        >
          <StyledControlLabel>
            {copyMapping[type].clientSecret}
          </StyledControlLabel>
          <FormControl
            type="text"
            bsSize="large"
            disabled={saving}
            value={formData.clientSecret}
            onChange={handleFieldChange}
          />
          {validation.clientSecret && (
            <HelpBlock>
              Please enter a valid {copyMapping[type].clientSecret}.
            </HelpBlock>
          )}
        </FormGroup>
      </Form>
    );
  }

  return (
    <Modal show={show} className="OrgGitModal" onHide={onCloseClick}>
      <Modal.Header closeButton>
        <Modal.Title>{copyMapping[type].label} Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderForm()}</Modal.Body>
      <Modal.Footer>
        <LoaderButton bsStyle="link" onClick={onCloseClick}>
          Cancel
        </LoaderButton>
        <LoaderButton
          type="submit"
          bsSize="large"
          bsStyle="warning"
          disabled={!canSubmit()}
          loading={saving}
          onClick={handleSaveClick}
        >
          {domain ? "Update" : "Enable"}
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
