import ReactDOM from "react-dom";
import React, { useRef, useState } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import SectionHeader from "./SectionHeader";
import LoaderButton from "./LoaderButton";
import TextButton from "./TextButton";
import CaretIcon from "./CaretIcon";
import FaIcon from "./FaIcon";
import { useFormReducer, useBlurDropdown } from "../lib/hooksLib";
import "./LogFilterSelect.css";

export default function LogFilterSelect({
  onChange,
  value = "",
  isAccessLog = false,
}) {
  const selectEl = useRef(null);
  const buttonEl = useRef(null);

  const [open, setOpen] = useState(false);
  const [
    {
      values: formData,
      isDirty: { search: isDirty },
    },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ search: value });

  useBlurDropdown({
    toggleEl: () => ReactDOM.findDOMNode(buttonEl.current),
    selectEl: () => selectEl.current,
    onClick: () => setOpen(false),
  });

  const emptyCs = value === "" ? "empty" : "";

  function handleSubmit(e) {
    e.preventDefault();

    setOpen(false);

    // Ignore if the value hasn't changed
    if (isDirty) {
      onChange(formData.search);
    }
  }

  function handleOptionClick(e) {
    formDispatch({
      id: "search",
      type: "edit",
      value: e.target.innerText,
    });
  }

  function renderOptions() {
    const options = isAccessLog
      ? ["500", "24.1.4.229", "/users/{userId}/posts"]
      : ["ERROR", '"Task timed out"', '"out of memory"'];

    return options.map((option) => (
      <LoaderButton key={option} bsSize="xsmall" onClick={handleOptionClick}>
        {option}
      </LoaderButton>
    ));
  }

  return (
    <div className="LogFilterSelect">
      <LoaderButton
        bsSize="large"
        active={open}
        className={emptyCs}
        onClick={() => setOpen(!open)}
        ref={(el) => (buttonEl.current = el)}
      >
        <FaIcon name="search" />
        <span className="copy">{value !== "" ? value : "Search logs"}</span>
        <CaretIcon direction={open ? "up" : "down"} />
      </LoaderButton>
      {open && (
        <div className="select" ref={(el) => (selectEl.current = el)}>
          <Form onSubmit={handleSubmit}>
            <FormGroup controlId="search">
              <FormControl
                autoFocus
                type="text"
                autoComplete="off"
                value={formData.search}
                onChange={handleFieldChange}
                placeholder="Search for logs with…"
              />
            </FormGroup>
            <SectionHeader>Common Searches</SectionHeader>
            <div className="options">{renderOptions()}</div>
            <div className="tips">
              <SectionHeader>Search Tips</SectionHeader>
              <ul>
                <li>Search a single term: Hello</li>
                <li>Search the exact phrase: "Hello World"</li>
                <li>Search for multiple terms: Hello Welcome</li>
                <li>Search for a phrase with quotes: 'Hello "World"'</li>
                <li>Search for one term but not the other: Hello -World</li>
              </ul>
            </div>
            <hr />
            <div className="controls">
              <TextButton onClick={() => setOpen(false)}>Cancel</TextButton>
              <LoaderButton type="submit" onClick={handleSubmit}>
                Search
              </LoaderButton>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}
