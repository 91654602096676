import React, { Component } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { isPasswordValid } from "../lib/formLib";
import { errorHandler, createError } from "../lib/errorLib";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import "./UpdatePasswordForm.css";

const noop = () => {};

const defaultProps = {
  isUpdating: false,
  onCancelClick: noop,
  onPasswordUpdate: noop,
};

const defaultState = {
  formData: {
    password: "",
    oldPassword: "",
    confirmPassword: "",
  },
  validation: {},
};

export default class UpdatePasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
    };
  }

  formatFields(fields) {
    fields = { ...fields };
    delete fields.confirmPassword;

    return fields;
  }

  validateFields(formData) {
    formData = { ...formData };

    const isPasswordsDirty =
      formData.oldPassword !== "" ||
      formData.password !== "" ||
      formData.confirmPassword !== "";

    if (!isPasswordsDirty) {
      return false;
    }

    const hasOldPassword = formData.oldPassword !== "";
    const isPasswordLong = isPasswordValid(formData.password);
    const isPasswordConfirmed = formData.password === formData.confirmPassword;

    if (
      isPasswordsDirty &&
      !(hasOldPassword && isPasswordLong && isPasswordConfirmed)
    ) {
      const validation = {};

      if (!hasOldPassword) {
        validation.oldPassword = "error";
      }
      if (!isPasswordLong) {
        validation.password = "error";
      }
      if (!isPasswordConfirmed) {
        validation.confirmPassword = "error";
      }

      this.setState({
        validation: {
          ...this.state.validation,
          ...validation,
        },
      });

      return false;
    }

    return this.formatFields(formData);
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      validation: {
        ...this.state.validation,
        [id]: null,
      },
    });
  };

  handleUpdateClick = async (event) => {
    event.preventDefault();

    const validatedFields = this.validateFields(this.state.formData);

    if (validatedFields === false) {
      return;
    }

    const { password, oldPassword } = validatedFields;

    try {
      await this.props.onPasswordUpdate(event, oldPassword, password);
    } catch (e) {
      errorHandler(
        e.code === "NotAuthorizedException" &&
          e.message === "Incorrect username or password."
          ? createError("INVALID_CURRENT_PASSWORD")
          : e
      );
      return;
    }
  };

  handleCancelClick = (event) => {
    this.props.onCancelClick(event);
  };

  render() {
    const { formData, validation } = this.state;
    const { isUpdating } = this.props;

    return (
      <form className="UpdatePasswordForm" onSubmit={this.handleUpdateClick}>
        <FormGroup
          bsSize="large"
          controlId="oldPassword"
          validationState={validation.oldPassword}
        >
          <StyledControlLabel>Current Password</StyledControlLabel>
          <FormControl
            type="password"
            value={formData.oldPassword}
            onChange={this.handleFieldChange}
          />
          {validation.oldPassword && (
            <HelpBlock>Please enter your current password.</HelpBlock>
          )}
        </FormGroup>
        <FormGroup
          bsSize="large"
          controlId="password"
          validationState={validation.password}
        >
          <StyledControlLabel>New Password</StyledControlLabel>
          <FormControl
            type="password"
            autoComplete="new-password"
            value={formData.password}
            placeholder="Your new password"
            onChange={this.handleFieldChange}
          />
          {validation.password && (
            <HelpBlock>
              Please use a password longer than 8 characters.
            </HelpBlock>
          )}
        </FormGroup>
        <FormGroup
          bsSize="large"
          controlId="confirmPassword"
          validationState={validation.confirmPassword}
        >
          <StyledControlLabel>Confirm New Password</StyledControlLabel>
          <FormControl
            type="password"
            autoComplete="new-password"
            value={formData.confirmPassword}
            placeholder="Confirm your new password"
            onChange={this.handleFieldChange}
          />
          {validation.confirmPassword && (
            <HelpBlock>Please confirm your password.</HelpBlock>
          )}
        </FormGroup>
        <div className="controls">
          <LoaderButton
            type="submit"
            bsSize="large"
            loading={isUpdating}
            onClick={this.handleUpdateClick}
          >
            Update
          </LoaderButton>
          <LoaderButton
            bsStyle="link"
            disabled={isUpdating}
            onClick={this.handleCancelClick}
          >
            Hide Update Password
          </LoaderButton>
        </div>
      </form>
    );
  }
}

UpdatePasswordForm.defaultProps = defaultProps;
