import React from "react";
import { Route, Redirect } from "react-router-dom";
import RenderContainerComponent from "./RenderContainerComponent";

function redirectURI(path, search) {
  return `/login?redirect=${encodeURIComponent(path + search)}`;
}

export default function AuthenticatedRoute({
  component: C,
  props: cProps,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        cProps.isAuthenticated ? (
          <RenderContainerComponent component={C} {...props} {...cProps} />
        ) : (
          <Redirect
            to={redirectURI(props.location.pathname, props.location.search)}
          />
        )
      }
    />
  );
}
