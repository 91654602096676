import React, { useState, useEffect } from "react";
import {
  Checkbox,
  HelpBlock,
  FormGroup,
  Glyphicon,
  FormControl,
} from "react-bootstrap";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import SectionInfo from "./SectionInfo";
import LoaderButton from "./LoaderButton";
import LoaderToggle from "./LoaderToggle";
import PermissionErrorModal from "./PermissionErrorModal";
import { useFormReducer } from "../lib/hooksLib";
import "./EnableIssuesPanel.css";

const helpUrl = "https://seed.run/docs/issues-and-alerts";
const retryHelpUrl = "https://seed.run/docs/issues-and-alerts#enabling-issues";

const logGroupErrorCopy = {
  existing_subscription:
    "There was an existing Lambda log group subscription. Enable the exclusive subscription option and try again.",
  iam_permission:
    "Seed does not have the right IAM permissions. Grant the appropriate permissions and try again.",
  unknown:
    "There was a problem adding the log group subscription. Please try again. And contact us if the problem persists.",
};

const serviceErrorCopy = {
  cannot_fetch_log_groups:
    "Seed does not have the right IAM permissions. Grant the appropriate permissions and try again.",
  cannot_fetch_account_id:
    "Seed does not have the right IAM permissions. Grant the appropriate permissions and try again.",
  cannot_create_destination:
    "There was a problem adding the log group subscriptions for this service. Please try again. And contact us if the problem persists.",
  unknown:
    "There was a problem adding the log group subscriptions for this service. Please try again. And contact us if the problem persists.",
};

const STATUS = {
  ENABLED: "enabled",
  ENABLING: "enabling",
  DISABLED: "disabled",
  DISABLING: "disabling",
};

const retryStageAll = "";

export default function EnableIssuesPanel({
  status,
  saving,
  resetKey,
  onSaveClick,
  stages = [],
  enableErrors = null,
}) {
  const [hoistedErrors, hasIamError] = enableErrors
    ? hoistErrors(enableErrors)
    : [null, null];

  const [
    { values: formData },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({
    retryStage: retryStageAll,
    retryExclusive: false,
    enabled:
      status === STATUS.ENABLING || status === STATUS.ENABLED ? true : false,
  });
  const [retryLoading, setRetryLoading] = useState(false);
  const [disableRetryStage, setDisableRetryStage] = useState(true);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  const inProgress = status === STATUS.ENABLING || status === STATUS.DISABLING;

  useEffect(() => {
    setRetryLoading(false);
    formDispatch({ type: "reset" });
  }, [resetKey, formDispatch, setRetryLoading]);

  function handleExclusiveCheck(e) {
    handleFieldChange(e);

    setDisableRetryStage(!e.target.checked);
  }

  function handleChange(e) {
    handleFieldChange(e);

    onSaveClick({ enabled: e.target.checked });
  }

  function handleRetryClick() {
    let errorMonitorForce = false;
    let errorMonitorForceStageName = undefined;

    if (formData.retryExclusive) {
      errorMonitorForce = true;

      if (formData.retryStage !== retryStageAll) {
        errorMonitorForceStageName = formData.retryStage;
      }
    }

    setRetryLoading(true);

    onSaveClick({
      enabled: true,
      force: errorMonitorForce,
      forceStageName: errorMonitorForceStageName,
    });
  }

  function hoistErrors(stages) {
    let hasIamError = false;

    stages.forEach((stage) => {
      stage.hasError = 0;

      stage.services.forEach((service) => {
        service.hasError = 0;

        if (service.logGroupStatuses) {
          service.logGroupStatuses.forEach(({ status }) => {
            service.hasError += status !== "success" ? 1 : 0;
            hasIamError = hasIamError || status === "iam_permission";
          });
        } else {
          service.hasError = 1;
        }

        stage.hasError +=
          service.status !== "success"
            ? 1
            : service.hasError
            ? service.hasError
            : 0;

        hasIamError =
          hasIamError ||
          service.status === "cannot_fetch_log_groups" ||
          service.status === "cannot_fetch_account_id";
      });

      stage.services.sort((a, b) => b.hasError - a.hasError);
    });

    stages.sort((a, b) => {
      if (a.hasError === 0 && b.hasError === 0) {
        return 0;
      }
      if (a.hasError === 0) {
        return -1;
      }
      if (b.hasError === 0) {
        return 1;
      }
      return b.hasError - a.hasError;
    });

    return [stages, hasIamError];
  }

  function renderLogGroupStatus({ name, status }) {
    const isSuccess = status === "success";

    const successCs = isSuccess ? "success" : "fail";
    const statusCs = isSuccess ? "check" : "times";

    return (
      <li key={name} className={`log-group ${successCs}`}>
        <i className={`fa-li fa fa-${statusCs}`}></i>
        <p className="name">{name}</p>
        {!isSuccess && (
          <p className="message">
            {logGroupErrorCopy[status] || logGroupErrorCopy.unknown}
          </p>
        )}
      </li>
    );
  }

  function renderServiceStatus({ stack, status, hasError, logGroupStatuses }) {
    const isSuccess = status === "success" && hasError === 0;

    const successCs = isSuccess ? "success" : "fail";
    const statusCs = isSuccess ? "check" : "times";

    return (
      <li key={stack} className={`service ${successCs}`}>
        <i className={`fa-li fa fa-${statusCs}`}></i>
        <p className="name">{stack}</p>
        {status !== "success" && (
          <p className="message">
            {serviceErrorCopy[status] || serviceErrorCopy.unknown}
          </p>
        )}
        {logGroupStatuses && (
          <ul className="fa-ul">
            {logGroupStatuses.map(renderLogGroupStatus)}
          </ul>
        )}
      </li>
    );
  }

  function renderStageStatus({ stageId, services, hasError }) {
    const isSuccess = hasError === 0;

    const statusCs = isSuccess ? "check" : "times";
    const successCs = isSuccess ? "success" : "fail";

    return (
      <li key={stageId} className={`stage ${successCs}`}>
        <i className={`fa-li fa fa-${statusCs}`}></i>
        <p>{stageId}</p>
        <ul className="fa-ul">{services.map(renderServiceStatus)}</ul>
      </li>
    );
  }

  function renderErrorLog() {
    return <ul className="fa-ul">{hoistedErrors.map(renderStageStatus)}</ul>;
  }

  function renderIamError() {
    return (
      <p>
        Seed doesn&apos;t have the right permissions to enable Issues.{" "}
        <TextButton onClick={() => setShowPermissionsModal(true)}>
          Click here to view the IAM permissions
        </TextButton>{" "}
        we need.
      </p>
    );
  }

  function renderErrors() {
    return (
      <div className="errors">
        <p className="header">
          <FaIcon name="exclamation-triangle" />
          Problems Enabling Issues
        </p>
        <div className="logs">{renderErrorLog()}</div>
        <hr />
        <div className="action">
          {hasIamError && <div className="iam">{renderIamError()}</div>}
          <Checkbox
            id="retryExclusive"
            disabled={retryLoading}
            onChange={handleExclusiveCheck}
            checked={formData.retryExclusive}
          >
            Use Seed to exclusively subscribe to all Lambda functions
          </Checkbox>
          <FormGroup controlId="retryStage" className="retry-stage">
            <FormControl
              componentClass="select"
              value={formData.retryStage}
              onChange={handleFieldChange}
              disabled={disableRetryStage}
            >
              <option value="">Enable for all stages</option>
              <option disabled>--------------------</option>
              {stages.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </FormControl>
            <HelpBlock>
              Enable this if you want to remove all existing Lambda Log group
              subscriptions and replace it with Seed. You can also enable it for
              a single stage to start.&nbsp;
              <a target="_blank" href={retryHelpUrl} rel="noopener noreferrer">
                Learn more about this setting.
              </a>
            </HelpBlock>
          </FormGroup>
          <LoaderButton
            bsSize="large"
            loading={retryLoading}
            onClick={handleRetryClick}
          >
            Try Again
          </LoaderButton>
        </div>
      </div>
    );
  }

  function renderStatus() {
    return (
      <span className="status">
        <Glyphicon glyph="refresh" />
        {status === STATUS.ENABLING && (
          <span>Enabling, this can take a few minutes&hellip;</span>
        )}
        {status === STATUS.DISABLING && (
          <span>Disabling, this can take a few minutes&hellip;</span>
        )}
      </span>
    );
  }

  return (
    <div className="EnableIssuesPanel">
      <SectionInfo
        label="Issues"
        description={
          <span>
            Enable Seed to monitor your Lambda functions and report
            errors.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn more about issues.
            </a>
          </span>
        }
      >
        <LoaderToggle
          id="enabled"
          loading={saving}
          disabled={inProgress}
          onChange={handleChange}
          checked={formData.enabled}
        >
          Enable Issues
        </LoaderToggle>
        {inProgress && renderStatus()}
        {enableErrors && renderErrors()}
      </SectionInfo>
      <PermissionErrorModal
        type="issues"
        show={showPermissionsModal}
        onCloseClick={() => setShowPermissionsModal(false)}
      />
    </div>
  );
}
