import React from "react";
import ButtonCheckbox from "./ButtonCheckbox";
import config from "../config";
import "./RolesPanel.css";

const userPermissions = config.userPermissions;

function summarizePermissions(type, permissionNames) {
  if (
    permissionNames.length === 3 ||
    (permissionNames.length === 2 && type === "dev")
  ) {
    return `${type}:*`;
  } else {
    return `${type}:${permissionNames.join(",")}`;
  }
}

function formatPermissions(permissions) {
  const formattedPermissions = [];

  const devPermissions = [];
  const stagingPermissions = [];
  const prodPermissions = [];

  permissions.forEach((permission) => {
    switch (permission) {
      case userPermissions.dev_deploy.value:
      case userPermissions.dev_manage.value:
        devPermissions.push(userPermissions[permission].name);
        break;
      case userPermissions.staging_deploy.value:
      case userPermissions.staging_promote.value:
      case userPermissions.staging_manage.value:
        stagingPermissions.push(userPermissions[permission].name);
        break;
      case userPermissions.prod_deploy.value:
      case userPermissions.prod_promote.value:
      case userPermissions.prod_manage.value:
        prodPermissions.push(userPermissions[permission].name);
        break;
      default:
        formattedPermissions.push(userPermissions[permission].name);
        break;
    }
  });

  devPermissions.length > 0 &&
    formattedPermissions.push(summarizePermissions("dev", devPermissions));
  stagingPermissions.length > 0 &&
    formattedPermissions.push(
      summarizePermissions("staging", stagingPermissions)
    );
  prodPermissions.length > 0 &&
    formattedPermissions.push(summarizePermissions("prod", prodPermissions));

  return formattedPermissions;
}

export default function RolesPanel({ permissions = [], appPermissions = {} }) {
  return (
    <div className="RolesPanel">
      {formatPermissions(permissions).map((permission) => (
        <ButtonCheckbox key={permission} checked disabled>
          {permission}
        </ButtonCheckbox>
      ))}
      {Object.keys(appPermissions).map((app) => (
        <ButtonCheckbox key={app} checked disabled className="app">
          {app}
        </ButtonCheckbox>
      ))}
    </div>
  );
}
