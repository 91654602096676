import React, { Component } from "react";
import withPolling from "../../components/ComponentWithPolling";
import ConfirmPromoteModal from "../../components/ConfirmPromoteModal";
import { errorHandler, getAPIError } from "../../lib/errorLib";
import { safeParse } from "../../lib/jsonLib";

const noop = () => {};

const APP_STAGE_PROMOTE_BUSY = 5106;
const APP_STAGE_PROMOTE_NO_BUILD = 5107;
const APP_STAGE_PROMOTE_BUSY_BUILD = 5111;
const APP_STAGE_PROMOTE_FAILED_BUILD = 5108;
const DEPLOYMENT_PROMOTE_NO_ARTIFACT = 3006;
const APP_STAGE_PROMOTE_NO_ACTIVE_SERVICES = 5113;
const INSUFFICIENT_PERMISSIONS = "AWSPermissionGeneric";

const AWS_THROTTLING = 8103;
const UNEXPECTED = 9999;

const defaultProps = {
  ...ConfirmPromoteModal.defaultProps,

  onLoad: noop,
  onClose: noop,
  onConfirm: noop,
};

const defaultState = {
  isReporting: false,
  reportSent: false,
  status: "pending",

  error: null,
  errorContext: null,

  changeset: null,
  commitDiffs: null,
  serviceStatuses: null,
  isConfirmingPromote: false,
};

class ConfirmPromote extends Component {
  key = 0;

  state = { ...defaultState };

  componentDidMount() {
    if (this.props.show) {
      ++this.key;
      this.props.poll.start(this.handleLoad);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.show && this.props.show) {
      this.setState(defaultState);
    }
    if (this.props.show && this.props.show !== prevProps.show) {
      ++this.key;
      // Clear polling for the case where the changeset for
      // a different stage was being viewed
      this.props.poll.clear();
      this.props.poll.start(this.handleLoad);
    }
  }

  handleLoad = async () => {
    // Stop polling
    if (!this.props.show) {
      return false;
    }

    try {
      const results = await this.props.poll.register(this.props.onLoad());

      if (results.changes) {
        this.setState({
          status: results.status,
          changeset: results.changes,
          commitDiffs: results.commitDiffs,
          serviceStatuses: results.serviceStatuses,
        });
      }

      if (results.status !== "pending") {
        return false;
      }
    } catch (e) {
      const errorObject = getAPIError(e);

      if (errorObject) {
        const { code, context } = errorObject;

        switch (code) {
          case AWS_THROTTLING:
            return true;
          case APP_STAGE_PROMOTE_BUSY:
          case APP_STAGE_PROMOTE_NO_BUILD:
          case APP_STAGE_PROMOTE_BUSY_BUILD:
          case DEPLOYMENT_PROMOTE_NO_ARTIFACT:
          case APP_STAGE_PROMOTE_FAILED_BUILD:
          case APP_STAGE_PROMOTE_NO_ACTIVE_SERVICES:
            this.setState({ status: "error", error: code });
            break;
          case INSUFFICIENT_PERMISSIONS:
            this.setState({
              error: code,
              status: "error",
              errorContext: safeParse(context),
            });
            break;
          default:
            this.setState({ status: "error", error: UNEXPECTED });
        }
      }

      return false;
    }
  };

  handleConfirmClick = async (event) => {
    this.setState({ isConfirmingPromote: true });

    // CORNER case: in the case where after a user clicks on confirm,
    // a load call is fired. The load call could return with the error
    // downstream stage is busy, b/c a promote is happening.
    // Solution is to cancel polling after confirm is clicked.
    this.props.poll.clear();

    try {
      await this.props.onConfirm(event);
    } catch (e) {
      this.setState({ isConfirmingPromote: false });
      errorHandler(e);
    }
  };

  handleReportClick = async (event) => {
    const comments = window.prompt(
      "A report with this change set will be sent back to us. Feel free to add any additional comments."
    );

    if (comments === null) {
      return;
    }

    this.setState({ isReporting: true });

    try {
      await this.props.onReport(event, {
        status: this.state.status,
        changeset: this.state.changeset,
        serviceStatuses: this.state.serviceStatuses,
        error: this.state.error,
        comments,
      });
    } catch (e) {
      errorHandler(e);
    }

    this.setState({ isReporting: false, reportSent: true });
  };

  render() {
    return (
      <ConfirmPromoteModal
        {...this.props}
        key={this.key}
        error={this.state.error}
        status={this.state.status}
        changes={this.state.changeset}
        onCloseClick={this.props.onClose}
        reporting={this.state.isReporting}
        reportSent={this.state.reportSent}
        commitDiffs={this.state.commitDiffs}
        errorContext={this.state.errorContext}
        onReportClick={this.handleReportClick}
        onConfirmClick={this.handleConfirmClick}
        confirming={this.state.isConfirmingPromote}
        serviceStatuses={this.state.serviceStatuses}
      />
    );
  }
}

ConfirmPromote.defaultProps = defaultProps;

export default withPolling(ConfirmPromote);
