import React from "react";
import FaIcon from "./FaIcon";
import CaretIcon from "./CaretIcon";
import "./ResourceSearchButton.css";

export default function ResourceSearchButton({
  text,
  onClick,
  disabled,
  mode = "resources", // dashboard or logs
}) {
  const disabledCs = disabled ? "disabled" : "";
  const cs = `ResourceSearchButton ${mode} ${disabledCs}`;

  return (
    <div tabIndex="0" className={cs} onClick={!disabled ? onClick : undefined}>
      {mode === "dashboard" && <FaIcon name="search" />}
      <span>{text}</span>
      {mode === "logs" && <CaretIcon direction="down" />}
    </div>
  );
}
