import React, {
  useRef,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import { useFormReducer } from "../lib/hooksLib";
import DropdownSearchItem from "./DropdownSearchItem";
import LoaderButton from "./LoaderButton";
import TextButton from "./TextButton";
import CaretIcon from "./CaretIcon";
import FaIcon from "./FaIcon";
import "./GitBranchSelect.css";

const noop = () => {};

const defaultFields = {
  search: "",
};

function find(items, text) {
  text = text.split(" ");

  return items.filter((branch) => {
    const branchNormalised = branch.toLowerCase();
    return text.every(
      (part) => branchNormalised.indexOf(part.toLowerCase()) > -1
    );
  });
}

export default function GitBranchSelect({
  branches = null,
  onChange = noop,
  hasMore = false,
  disabled = false,
  startOpened = false,
  selectedBranch = null,
  isLoadingMore = false,
  onLoadMoreClick = noop,
}) {
  const searchEl = useRef(null);
  const cursorEl = useRef(null);
  let [open, setOpen] = useState(startOpened);
  const [cursor, _setCursor] = useState({ index: -1, focus: false });
  const [formData, formDispatch] = useFormReducer(defaultFields);
  const filteredBranches = useMemo(
    () =>
      formData.values.search === ""
        ? branches
        : find(branches, formData.values.search),
    [formData.values.search, branches]
  );
  const resetCursor = useCallback(() => setCursor(-1), []);

  useEffect(() => {
    resetCursor();
  }, [resetCursor, formData.values.search]);

  useLayoutEffect(() => {
    if (cursorEl.current) {
      cursorEl.current.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "start",
      });
    }
  }, []);

  if (branches === null || disabled) {
    open = false;
  }

  function setCursor(index, focus = false) {
    _setCursor({ index, focus });
  }

  function isFocusRef(i) {
    return i === cursor.index && cursor.focus;
  }

  function isCurrentCs(branch) {
    return selectedBranch === branch ? "current" : "";
  }

  function isOnCursorCs(i) {
    return i === cursor.index ? "cursor" : "";
  }

  function clearFormData() {
    resetCursor();

    formDispatch({
      value: "",
      id: "search",
      type: "edit",
    });
  }

  function handleFieldChange(event) {
    const { id, value } = event.target;

    formDispatch({
      id,
      value,
      type: "edit",
    });
  }

  function handleFieldKeyDown(e) {
    const keyCode = e.keyCode;
    const count = filteredBranches.length;

    if (keyCode === 38 || keyCode === 40) {
      e.preventDefault();
    }

    if (keyCode === 38 && cursor.index > 0) {
      setCursor(cursor.index - 1, true);
    } else if (keyCode === 40 && cursor.index < count - 1) {
      setCursor(cursor.index + 1, true);
    }
  }

  function handleSearch(e) {
    e.preventDefault();

    // Select item on enter key
    if (cursor.index !== -1 && cursor.focus) {
      handleChange(filteredBranches[cursor.index]);
    }
  }

  function handleClick() {
    clearFormData();
    setOpen(!open);
  }

  function handleChange(value) {
    clearFormData();
    setOpen(false);

    onChange({ target: { id: "branch", value } });
  }

  function handleClearClick() {
    clearFormData();
    searchEl.current.focus();
  }

  return (
    <div className="GitBranchSelect">
      {branches === null && (
        <LoaderButton loading bsSize="large" className="loading">
          <span className="text">Loading branches</span>
        </LoaderButton>
      )}
      {branches && branches.length === 0 && (
        <LoaderButton disabled bsSize="large">
          <span className="text">No branches found</span>
        </LoaderButton>
      )}
      {branches && branches.length !== 0 && (
        <>
          <LoaderButton
            bsSize="large"
            active={open}
            disabled={disabled}
            onClick={handleClick}
          >
            {selectedBranch && <span className="text">{selectedBranch}</span>}
            {!selectedBranch && <span className="text">Select a branch</span>}
            <CaretIcon direction={open ? "up" : "down"} />
          </LoaderButton>
          {open && (
            <div className="list-container">
              <Form
                className="form"
                componentClass="div"
                onSubmit={handleSearch}
              >
                <FormGroup bsSize="large" controlId="search">
                  <label htmlFor="search">
                    <FaIcon name="search" />
                  </label>
                  <FormControl
                    autoFocus
                    type="text"
                    autoComplete="off"
                    onChange={handleFieldChange}
                    value={formData.values.search}
                    onKeyDown={handleFieldKeyDown}
                    inputRef={(el) => {
                      searchEl.current = el;
                    }}
                  />
                  {formData.values.search !== "" && (
                    <TextButton onClick={handleClearClick}>
                      <FaIcon name="times-circle" />
                    </TextButton>
                  )}
                </FormGroup>
              </Form>
              <div className="list">
                {filteredBranches.length > 0 &&
                  filteredBranches.map((branch, i) => (
                    <DropdownSearchItem
                      key={branch}
                      onHover={() => setCursor(i)}
                      onClick={(e) => handleChange(branch)}
                      elRef={(el) => {
                        isFocusRef(i) && (cursorEl.current = el);
                      }}
                      className={`item ${isOnCursorCs(i)}  ${isCurrentCs(
                        branch
                      )}`}
                    >
                      {branch}
                    </DropdownSearchItem>
                  ))}
                {filteredBranches.length === 0 && (
                  <span className="empty">No matching branches found</span>
                )}
              </div>
            </div>
          )}
          {hasMore && open && (
            <div className="controls">
              <LoaderButton
                bsStyle="link"
                loading={isLoadingMore}
                disabled={isLoadingMore}
                onClick={onLoadMoreClick}
              >
                Searching {branches.length} branches, load more
              </LoaderButton>
            </div>
          )}
        </>
      )}
    </div>
  );
}
