import React from "react";
import { Link } from "react-router-dom";
import { Panel } from "react-bootstrap";
import { shortHash } from "../lib/gitLib";
import { dateToSinceTime } from "../lib/timeLib";
import { getAppStageBuildUrl } from "../lib/urlLib";
import CommitMessageSpan from "./CommitMessageSpan";
import LoaderButton from "./LoaderButton";
import UserImage from "./UserImage";
import FaIcon from "./FaIcon";
import "./AppActivityPanel.css";

const stateCopyArray = {
  success: "Completed",
  failure: "Failed",
  deploying: "In Progress",
  queued: "Queued",
  skipped: "Skipped",
};

function statusStateToIcon(statusState) {
  const textArray = {
    success: "check-circle",
    failure: "times-circle",
    deploying: "cog fa-spin",
    queued: "cog fa-spin",
    skipped: "minus-circle",
  };

  return textArray[statusState];
}

function statusStateToStyle(statusState) {
  const textArray = {
    deploying: "in_progress",
    queued: "in_progress",
    skipped: "skipped",
    success: "completed",
    failure: "failed",
  };

  return textArray[statusState];
}

function buildHeader(activity, link, createdAt) {
  const { action, stage_id: stage } = activity;

  return (
    <Link to={link}>
      {(action === "deploy_branch" || action === "deploy_pr") && (
        <span>
          Auto-deploy to <b>{stage}</b>
        </span>
      )}
      {action === "deploy_cli" && (
        <span>
          Deploy to <b>{stage}</b>
        </span>
      )}
      {action === "deploy_manual" && (
        <span>
          Manual deploy to <b>{stage}</b>
        </span>
      )}
      {activity.action === "promote" && (
        <span>
          Promote {activity.src_stage_id} to <b>{stage}</b>
        </span>
      )}
      {activity.action === "rollback" && (
        <span>
          Rollback <b>{stage}</b>
        </span>
      )}
    </Link>
  );
}

function renderActor({ imageLink, name, nameInitials }, status) {
  return (
    <span>
      <UserImage
        name={name}
        src={imageLink}
        initials={nameInitials}
        inactive={status !== "deploying" && status !== "queued"}
      />
    </span>
  );
}

export default ({
  activity,
  pathParams,
  isRollingBack,
  isActive = false,
  handleRollbackClick,
}) => {
  const { ownerId, appId } = pathParams;
  const action = activity.action;
  const actor = activity.actor || {};
  const buildId = activity.build_id;
  const status = activity.status;
  const stageName = activity.stage_id;
  const createdAt = activity.created_at;
  const commit = activity.commit;
  const href = getAppStageBuildUrl(ownerId, appId, stageName, buildId);
  const statusStyle = statusStateToStyle(status);

  let branch, branchUrl;

  if (activity.branch || activity.pr) {
    branch = activity.pr ? `pr#${activity.pr}` : activity.branch;
    branchUrl = activity.pr ? activity.pr_url : activity.branch_url;
  }

  function renderErrorMessage() {
    if (!activity.error_message && !activity.skippedDueToMinutes) {
      return;
    }
    const message = activity.error_message
      ? activity.error_message
      : "Build minutes limit exceeded";

    return (
      <>
        <span>&bull;</span>
        <Link className="info-link" to={href} title={message}>
          {message}
        </Link>
      </>
    );
  }

  function renderCommitMessage() {
    const hasCommitMessage = commit && commit.hasOwnProperty("message");

    if (action === "promote" || action === "rollback") {
      const statusCopy =
        status === "deploying" || status === "queued"
          ? action === "promote"
            ? "Promoting"
            : "Rolling back"
          : action === "promote"
          ? "Promoted"
          : "Rolled back";
      const servicesCopy =
        activity.servicesDeployed.type === "all"
          ? "all services"
          : activity.servicesDeployed.serviceName
          ? `${activity.servicesDeployed.serviceName} service`
          : "a service";

      return (
        <span>
          {statusCopy} {servicesCopy}
        </span>
      );
    } else if (hasCommitMessage) {
      return <CommitMessageSpan commit={commit} />;
    }

    return null;
  }

  function renderInfo() {
    if (commit && !(action === "promote" || action === "rollback")) {
      return (
        <div className="info">
          {branch && (
            <>
              <a
                target="_blank"
                href={branchUrl}
                className="info-link branch"
                rel="noopener noreferrer"
              >
                <FaIcon name="code-fork" />
                {branch}
              </a>
              <span>&bull;</span>
            </>
          )}
          <a
            target="_blank"
            href={commit.url}
            className="info-link commit"
            rel="noopener noreferrer"
          >
            {shortHash(commit.id)}
          </a>
          {renderErrorMessage()}
        </div>
      );
    }

    return null;
  }

  function renderPanelBody() {
    return (
      <div className="content">
        <div className="col1">
          <Link className="icon" to={href}>
            <i className={`fa fa-${statusStateToIcon(status)}`}></i>
          </Link>
          <div className="content-body">
            <Link className="build-link" to={href}>
              v{buildId}
            </Link>
            <Link className="commit-message" to={href}>
              {renderCommitMessage()}
            </Link>
            {renderInfo()}
          </div>
        </div>
        <div className="col2">
          <div className="info">
            {commit && !(action === "promote" || action === "rollback") && (
              <p className="status">{stateCopyArray[status]}</p>
            )}
            <p className="date">{dateToSinceTime(createdAt)}</p>
          </div>
          {isActive ? (
            <LoaderButton disabled className="active">
              Active
            </LoaderButton>
          ) : (
            <LoaderButton
              className="rollback"
              loading={isRollingBack}
              disabled={status !== "success"}
              onClick={() => handleRollbackClick(activity)}
            >
              Rollback
            </LoaderButton>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`AppActivityPanel ${statusStyle}`}>
      <div className="actor">{renderActor(actor, status)}</div>
      <Panel>
        <Panel.Heading>{buildHeader(activity, href, createdAt)}</Panel.Heading>
        <Panel.Body>{renderPanelBody()}</Panel.Body>
      </Panel>
    </div>
  );
};
