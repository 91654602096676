import React, { Component } from "react";
import { Auth } from "aws-amplify";
import withCancel from "../components/ComponentWithCancel";
//import Alert from "../components/Alert";
import LoginForm from "../components/LoginForm";
import ScreenHeader from "../components/ScreenHeader";
import SsoLoginPanel from "../components/SsoLoginPanel";
import TwoFactorLoginForm from "../components/TwoFactorLoginForm";
import { errorHandler, createError } from "../lib/errorLib";
import { setIdentityIdAttribute } from "../lib/awsLib";
import { getDomainFromEmail } from "../lib/regexLib";
import { addBreadcrumb } from "../lib/sentryLib";
import title from "../lib/titleLib";
import "./Login.css";

const defaultState = {
  isLoggingIn: false,
  identityProvider: null,

  // Two-factor auth
  showTwoFactor: false,
  validatingUser: null,
  validatingUserEmail: null,
  isValidatingTwoFactor: false,
};

class Login extends Component {
  state = { ...defaultState };

  constructor(props) {
    super(props);

    document.title = title("Login");
  }

  getIdentityProvider(domain) {
    return this.props.invokePublicApig({
      path: "/identity_providers",
      queryStringParameters: {
        domain,
      },
    });
  }

  handleCancelClick = () => {
    this.setState({ showTwoFactor: false });
  };

  handleTwoFactorCancelClick = (event) => {
    this.setState({ showTwoFactor: false, isLoggingIn: false });
  };

  handleSsoLoginClick = async (email) => {
    // Log login info
    addBreadcrumb({
      message: "SSO Login",
      category: "requests",
      data: { email },
    });

    this.setState({ isLoggingIn: true });

    try {
      const {
        identity_provider: identityProvider,
      } = await this.getIdentityProvider(getDomainFromEmail(email));

      if (identityProvider) {
        this.setState({ identityProvider });
      } else {
        alert(
          "We couldn't find the organization associated with this email address. Please contact support if you need some help."
        );
      }
    } catch (e) {
      errorHandler(e);
    }

    this.setState({ isLoggingIn: false });
  };

  handleLoginClick = async (email, password) => {
    // Normalize case
    email = email.toLowerCase();

    // Log login info
    addBreadcrumb({
      message: "Login",
      category: "requests",
      data: { email },
    });

    this.setState({ isLoggingIn: true });

    try {
      const user = await Auth.signIn(email, password);

      // Case 1: two-factor auth enabled
      if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        this.setState({
          showTwoFactor: true,
          validatingUser: user,
          validatingUserEmail: email,
        });
      }
      // Case 2: two-factor auth NOT enabled
      else {
        await this.handleLoginSuccess(email);
      }
    } catch (e) {
      errorHandler(e, {
        NotAuthorizedException: true,
        UserNotFoundException: true,
      });
      this.setState({ isLoggingIn: false });
    }
  };

  handleValidateTwoFactorClick = async (code) => {
    this.setState({ isValidatingTwoFactor: true });

    try {
      await Auth.confirmSignIn(
        this.state.validatingUser,
        code,
        "SOFTWARE_TOKEN_MFA"
      );
      await this.handleLoginSuccess(this.state.validatingUserEmail);
    } catch (e) {
      // AWS throws the same error for MFA code mismatch as sign in
      // verification code mismatch
      if (e.code === "CodeMismatchException") {
        errorHandler(createError("CodeMismatchException_LOGIN"), {
          CodeMismatchException_LOGIN: true,
        });
      }
      // Use waited for too long to enter the confirmation code, need to log in again
      else if (e.code === "NotAuthorizedException") {
        errorHandler(createError("NotAuthorizedException_SESSION"), {
          NotAuthorizedException_SESSION: true,
        });
        this.handleTwoFactorCancelClick();
      } else {
        errorHandler(e, {
          ExpiredCodeException: true,
        });
      }

      this.setState({ isValidatingTwoFactor: false });
    }
  };

  handleSsoCancelClick = (event) => {
    this.setState({
      identityProvider: defaultState.identityProvider,
    });
  };

  async handleLoginSuccess(email) {
    await setIdentityIdAttribute();
    this.props.setUserAsAuthenticated(email);
  }

  render() {
    const {
      isLoggingIn,
      showTwoFactor,
      identityProvider,
      isValidatingTwoFactor,
    } = this.state;

    return (
      <div className="Login">
        <ScreenHeader border>Login</ScreenHeader>
        {/**
        <div className="message-banner">
          <Alert>
            There&apos;s a large scale AWS outage that&apos;s causing logins to
            fail and some pages to not load. We are currently monitoring the
            issue.&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/SEED_run"
            >
              Follow us on Twitter for further updates.
            </a>
          </Alert>
        </div>
        **/}
        {!identityProvider && (
          <>
            {!showTwoFactor && (
              <LoginForm
                isLoggingIn={isLoggingIn}
                onLoginClick={this.handleLoginClick}
                onCancelClick={this.handleCancelClick}
                onSsoLoginClick={this.handleSsoLoginClick}
              />
            )}
            {showTwoFactor && (
              <TwoFactorLoginForm
                isValidating={isValidatingTwoFactor}
                onCancelClick={this.handleTwoFactorCancelClick}
                onValidateClick={this.handleValidateTwoFactorClick}
              />
            )}
          </>
        )}
        {identityProvider && (
          <SsoLoginPanel
            name={identityProvider.name}
            domain={identityProvider.domain}
            source={identityProvider.source}
            provider={identityProvider.provider}
            onCancelClick={this.handleSsoCancelClick}
          />
        )}
      </div>
    );
  }
}

export default withCancel(Login);
