import React from "react";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import "./NewIamRoleHelpButton.css";

export default function NewIamRoleHelpButton({
  loading,
  className = "",
  ...props
}) {
  return (
    <LoaderButton
      tabIndex="-1"
      bsStyle="link"
      loading={loading}
      className={`NewIamRoleHelpButton ${className}`}
      {...props}
    >
      {!loading && <FaIcon name="info-circle" />}
      Help me create an IAM Role
    </LoaderButton>
  );
}
