import React, { Fragment } from "react";
import { HelpBlock, FormGroup, ListGroup, FormControl } from "react-bootstrap";
import ScrollShadowContainer from "./ScrollShadowContainer";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import ServiceIcon from "./ServiceIcon";
import TextButton from "./TextButton";
import FaIcon from "./FaIcon";
import "./AppSettingDeployWorkflowPanel.css";

function renderNewPhase(onAddPhaseClick) {
  return (
    <div className="add">
      <LoaderButton bsSize="large" bsStyle="warning" onClick={onAddPhaseClick}>
        <FaIcon name="plus" />
        Add a Phase
      </LoaderButton>
    </div>
  );
}

function renderPhase(
  phase,
  phaseId,
  services,
  onPhaseChange,
  onRemovePhaseClick
) {
  return (
    <div className="phase">
      <div className="header">
        <StyledControlLabel>Phase {phaseId + 1}</StyledControlLabel>
        {phase.length === 0 && (
          <TextButton onClick={() => onRemovePhaseClick(phaseId)}>
            Remove
          </TextButton>
        )}
      </div>
      <ListGroup className="list">
        {phase.map((service, stepId) => renderPhaseService(service, stepId))}
        {phase.length > 0 && <hr />}
        {renderPhaseAddService(phase, phaseId, services, onPhaseChange)}
      </ListGroup>
    </div>
  );
}

function renderPhaseAddService(phase, phaseId, services, onPhaseChange) {
  return (
    <FormGroup controlId="service">
      <FormControl
        componentClass="select"
        value={"pick service"}
        onChange={(e) => {
          onPhaseChange(e.target.value, phaseId);
        }}
      >
        <option disabled value="pick service">
          Pick a service
        </option>
        {services.map((service, i) =>
          phase.includes(service) ? (
            <option disabled key={i} value={service.projectId}>
              {service.service_name}
            </option>
          ) : (
            <option key={i} value={service.projectId}>
              {service.service_name}
            </option>
          )
        )}
      </FormControl>
    </FormGroup>
  );
}

function renderPhaseService(service, stepId) {
  return (
    <div key={stepId} className="service">
      <ServiceIcon size="medium" type={service.serviceType} />
      <span>{service.service_name}</span>
    </div>
  );
}

export default ({
  services,
  phases,
  updating,
  isDirty,
  hasUpdated,
  onClearClick,
  onPhaseChange,
  onUpdateClick,
  onAddPhaseClick,
  onRemovePhaseClick,
}) => {
  return (
    <div className="AppSettingDeployWorkflowPanel">
      <ScrollShadowContainer>
        <div className="graphic">
          <div className="tag start">
            <hr />
            <span className="separator">&nbsp;</span>
            <span className="copy">begin</span>
          </div>
          {phases.map((phase, phaseId) => (
            <Fragment key={phaseId}>
              <div className="pointers">
                <hr />
              </div>
              {renderPhase(
                phase,
                phaseId,
                services,
                onPhaseChange,
                onRemovePhaseClick
              )}
            </Fragment>
          ))}
          <div className="pointers">
            <hr />
          </div>
          {renderNewPhase(onAddPhaseClick)}
          <div className="pointers">
            <hr />
          </div>
          <div className="post-deploy-phase">
            <div className="header">
              <StyledControlLabel>Post-Deploy Phase</StyledControlLabel>
            </div>
            <div className="copy">
              Add a Post-Deploy Phase for a stage below
            </div>
          </div>
          <div className="pointers">
            <hr />
          </div>
          <div className="tag end">
            <hr />
            <span className="separator">&nbsp;</span>
            <span className="copy">end</span>
          </div>
          <div className="spacer"></div>
        </div>
      </ScrollShadowContainer>
      <div className="controls">
        <LoaderButton
          bsSize="large"
          disabled={!isDirty}
          loading={updating}
          onClick={onUpdateClick}
        >
          Update
        </LoaderButton>
        {isDirty && (
          <LoaderButton bsStyle="link" onClick={onClearClick}>
            Clear Changes
          </LoaderButton>
        )}
        {hasUpdated && (
          <HelpBlock>Deploy Phases have been updated successfully!</HelpBlock>
        )}
      </div>
    </div>
  );
};
