import React from "react";
import { Tab, Button, ButtonGroup } from "react-bootstrap";
import ResourceItem from "./ResourceItem";
import "./ResourcesPanel.css";

export const TabEnum = {
  lambdas: "lambdas",
  outputs: "outputs",
  others: "others",
};

export default function ResourcesPanel({
  serviceId,
  serviceType,
  onTabClick,
  pathParams,
  activeTab = null,
  resources = null,
  updatingLogs = false,
  onUpdateAccessLogClick,
  ...props
}) {
  activeTab = activeTab === null ? TabEnum.lambdas : activeTab;

  function getStacksWithResources(stacks, resourceType) {
    return stacks.filter((stack) => stack[resourceType].length > 0);
  }

  function normalizeStacks(resources, resourceType) {
    return resources.stacks
      ? resources.stacks
      : [{ [resourceType]: resources[resourceType] }];
  }

  function renderEmptyPane(tab) {
    return (
      <div className="empty">
        {tab === TabEnum.lambdas &&
          `There are no Lambdas deployed in this service.`}
        {tab === TabEnum.outputs &&
          `There are no stack outputs in this service.`}
        {tab === TabEnum.others &&
          `There are no other resources deployed in this service.`}
      </div>
    );
  }

  function renderOutputs(stacks) {
    const stacksWithResources = getStacksWithResources(stacks, "outputs");

    if (stacksWithResources.length > 0) {
      return stacksWithResources.map((stack) =>
        renderOutputsList(stack.outputs, stack.stack)
      );
    } else {
      return renderEmptyPane(TabEnum.outputs);
    }
  }

  function renderOutputsList(outputs, header) {
    return (
      <div key={header}>
        {header && <p className="stack-name">{header}</p>}
        <ul className="outputs">
          {outputs.map((res, i) => (
            <ResourceItem key={i} type="output" resource={res} />
          ))}
        </ul>
      </div>
    );
  }

  function renderMinorResources(stacks) {
    const stacksWithResources = getStacksWithResources(stacks, "resources");

    if (stacksWithResources.length > 0) {
      return stacksWithResources.map((stack) =>
        renderMinorResourcesList(stack.resources, stack.stack)
      );
    } else {
      return renderEmptyPane(TabEnum.others);
    }
  }

  function renderMinorResourcesList(resources, header) {
    return (
      <div key={header}>
        {header && <p className="stack-name">{header}</p>}
        <ul className="minor-resources">
          {resources.map((res) => (
            <ResourceItem key={res.name} type="other" resource={res} />
          ))}
        </ul>
      </div>
    );
  }

  function renderLambdas(stacks) {
    const stacksWithResources = getStacksWithResources(stacks, "lambdas");

    if (stacksWithResources.length > 0) {
      return stacksWithResources.map((stack) =>
        renderLambdasList(stack.lambdas, stack.stack)
      );
    } else {
      return renderEmptyPane(TabEnum.lambdas);
    }
  }

  function renderLambdasList(lambdas, header) {
    return (
      <div key={header}>
        {header && <p className="stack-name">{header}</p>}
        <ul className="lambdas">
          {lambdas.map((res) => (
            <ResourceItem
              type="lambda"
              resource={res}
              key={res.fullName}
              pathParams={pathParams}
              region={resources.stage.region}
            />
          ))}
        </ul>
      </div>
    );
  }

  function renderEndpoint() {
    return (
      <ul className="api-gateway">
        <li className="header">
          <span>API Endpoint</span>
        </li>
        <ResourceItem
          type="api"
          serviceId={serviceId}
          pathParams={pathParams}
          region={resources.stage.region}
          resource={resources.stage}
          updatingLogs={updatingLogs}
          onUpdateAccessLogClick={onUpdateAccessLogClick}
        />
      </ul>
    );
  }

  function renderCfEndpoint() {
    return (
      <ul className="website-component">
        <li className="header">
          <span>Website URL</span>
        </li>
        <ResourceItem type="website" resource={resources.stage} />
      </ul>
    );
  }

  return (
    <div className="ResourcesPanel">
      {resources.stage.endpoint && renderEndpoint()}
      {resources.stage.cfEndpoint && renderCfEndpoint()}
      <div className="content-container">
        <Tab.Container
          id="resource-type-tabs"
          onSelect={onTabClick}
          activeKey={activeTab}
        >
          <>
            <div className="tab-controls">
              <ButtonGroup>
                <Button
                  active={activeTab === TabEnum.lambdas}
                  onClick={() => onTabClick(serviceId, TabEnum.lambdas)}
                >
                  Lambdas
                </Button>
                <Button
                  active={activeTab === TabEnum.outputs}
                  onClick={() => onTabClick(serviceId, TabEnum.outputs)}
                >
                  Outputs
                </Button>
                <Button
                  active={activeTab === TabEnum.others}
                  onClick={() => onTabClick(serviceId, TabEnum.others)}
                >
                  Others
                </Button>
              </ButtonGroup>
            </div>
            <Tab.Content animation>
              <Tab.Pane eventKey={TabEnum.lambdas}>
                {renderLambdas(normalizeStacks(resources, "lambdas"))}
              </Tab.Pane>
              <Tab.Pane eventKey={TabEnum.outputs}>
                {renderOutputs(normalizeStacks(resources, "outputs"))}
              </Tab.Pane>
              <Tab.Pane eventKey={TabEnum.others}>
                {renderMinorResources(normalizeStacks(resources, "resources"))}
              </Tab.Pane>
            </Tab.Content>
          </>
        </Tab.Container>
      </div>
    </div>
  );
}
