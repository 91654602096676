import React from "react";
import { Link } from "react-router-dom";
import { shortHash } from "../lib/gitLib";
import { dateToSinceTime } from "../lib/timeLib";
import { getAppStageBuildUrl } from "../lib/urlLib";
import UserImage from "./UserImage";
import FaIcon from "./FaIcon";
import "./MiniActivityItem.css";

const stateCopyArray = {
  success: "Completed",
  failure: "Failed",
  deploying: "In Progress",
  queued: "Queued",
  skipped: "Skipped",
};

function statusStateToIcon(statusState) {
  const textArray = {
    success: "check",
    failure: "times",
    deploying: "cog fa-spin",
    queued: "cog fa-spin",
    skipped: "minus",
  };

  return textArray[statusState];
}

function statusStateToStyle(statusState) {
  const textArray = {
    deploying: "in_progress",
    queued: "in_progress",
    skipped: "skipped",
    success: "completed",
    failure: "failed",
  };

  return textArray[statusState];
}

export default ({
  activity,
  pathParams,
  isRollingBack,
  isFirst = false,
  handleRollbackClick,
  showRollback = false,
}) => {
  const { ownerId, appId } = pathParams;

  const action = activity.action;
  const actor = activity.actor || {};
  const buildId = activity.build_id;
  const status = activity.status;
  const stageName = activity.stage_id;
  const createdAt = activity.created_at;
  const commit = activity.commit;
  const href = getAppStageBuildUrl(ownerId, appId, stageName, buildId);
  const statusStyle = statusStateToStyle(status);

  function renderActor() {
    const { imageLink, name, nameInitials } = actor;
    return (
      <UserImage
        size="xsmall"
        name={name}
        src={imageLink}
        initials={nameInitials}
      />
    );
  }

  function renderStatus() {
    return (
      <Link to={href}>
        <span>{dateToSinceTime(createdAt)}</span>
      </Link>
    );
  }

  function renderBody() {
    const stage = activity.stage_id;

    const commitEl =
      commit && !(action === "promote" || action === "rollback") ? (
        <a
          target="_blank"
          className="commit"
          href={commit.url}
          rel="noopener noreferrer"
        >
          {shortHash(commit.id)}
        </a>
      ) : null;

    return (
      <>
        <span>
          {(action === "deploy_branch" ||
            action === "deploy_pr" ||
            action === "deploy_cli") && (
            <>
              Auto-deploy {commitEl} to <b>{stage}</b>
            </>
          )}
          {action === "deploy_manual" && (
            <>
              Manual deploy {commitEl} to <b>{stage}</b>
            </>
          )}
          {activity.action === "promote" && (
            <>
              Promote {activity.src_stage_id} to <b>{stage}</b>
            </>
          )}
          {activity.action === "rollback" && (
            <>
              Rollback <b>{stage}</b>
            </>
          )}
        </span>
        <Link className="build" title={stateCopyArray[status]} to={href}>
          <FaIcon name={statusStateToIcon(status)} />
        </Link>
      </>
    );
  }

  return (
    <div className={`MiniActivityItem ${statusStyle}`}>
      <div className="actor">{renderActor()}</div>
      <p className="body">{renderBody()}</p>
      <div className="status">{renderStatus()}</div>
    </div>
  );
};
