import React, { useRef, useState } from "react";
import SectionInfo from "./SectionInfo";
import TextButton from "./TextButton";
import "./AppBadgePanel.css";
import config from "../config";

const helpUrl = "https://seed.run/docs/deploy-badges";

export default function AppBadgePanel({ owner, app, stages }) {
  const textareaRef = useRef(null);
  const [copyStatus, setCopyStatus] = useState(false);

  // Select the stage to show the sample badge. An app should always have a
  // production stage. But in case it doesn't, use the follow selection order:
  // - select production stage
  // - select any stage
  // - use a fake "prod" stage
  let stageName;
  const prodStage = stages.find((stage) => stage.is_production);
  if (prodStage) {
    stageName = prodStage.name;
  } else if (stages.length > 0) {
    stageName = stages[0].name;
  } else {
    stageName = "prod";
  }

  // Build badge url
  const badgeUrl = `${config.v1ApiGateway.URL}/${owner}/${app}/stages/${stageName}/build_badge`;

  const badgeMarkdown = `[![Seed Status](${badgeUrl})](${window.location.origin}/${owner}/${app})`;

  function handleCopy() {
    textareaRef.current.select();
    document.execCommand("copy");
    textareaRef.current.focus();
    setCopyStatus(true);
  }

  function handleBlur() {
    setCopyStatus(false);
  }

  function renderSnippet() {
    return (
      <div className="snippet">
        <input
          readOnly
          ref={textareaRef}
          onBlur={handleBlur}
          value={badgeMarkdown}
        />
        <div>
          {document.queryCommandSupported("copy") && (
            <>
              {copyStatus && <span className="copied-status">Copied!</span>}
              <TextButton onClick={handleCopy}>Copy</TextButton>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="AppBadgePanel">
      <SectionInfo
        label="Deploy Badge"
        description={
          <span>
            Add a Seed deploy badge to your project README.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn about deploy badges.
            </a>
          </span>
        }
      >
        <img src={badgeUrl} alt={""} />
        {renderSnippet()}
      </SectionInfo>
    </div>
  );
}
