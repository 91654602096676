import React, { Fragment } from "react";
import AccessLogFieldsPanel from "./AccessLogFieldsPanel";
import RequestLogLine from "./RequestLogLine";
import SectionHeader from "./SectionHeader";
import CaretIcon from "./CaretIcon";
import FaIcon from "./FaIcon";
import {
  dateToFullTimeNoYearWithUTCTimeZone,
  dateToFullTimeNoYearWithLocalTimeZone,
} from "../lib/timeLib";
import { getIndicesOf } from "../lib/stringLib";
import "./RequestLogEntry.css";

function formatSummary(str) {
  return str.replace(/\n/, " ");
}

function HighlightedLogSummary({ filterTerms, summary }) {
  const formattedSummary = formatSummary(summary);

  if (filterTerms.length === 0) {
    return <>{formattedSummary}</>;
  }

  const termPositions = filterTerms
    // Find positions
    .reduce((acc, term) => {
      const indices = getIndicesOf(term, formattedSummary);

      if (indices.length === 0) {
        return acc;
      }

      indices.forEach((index, i) => {
        acc.push([index, index + term.length]);
      });

      return acc;
    }, [])
    // Sort in ascending order
    .sort(([start1], [start2]) => start1 - start2);

  if (termPositions.length === 0) {
    return <>{formattedSummary}</>;
  } else {
    const parts = [];
    let index = 0;

    for (let i = 0, l = termPositions.length; i < l; i++) {
      const [start, end] = termPositions[i];

      parts.push(
        React.createElement(
          "span",
          { key: `text-${i}` },
          index === 0 && start > 15
            ? `…${formattedSummary.substring(start - 7, start)}`
            : formattedSummary.substring(index, start)
        )
      );
      parts.push(
        React.createElement(
          "b",
          { key: `term-${i}` },
          formattedSummary.substring(start, end)
        )
      );

      index = end;
    }

    parts.push(
      React.createElement(
        "span",
        { key: "end" },
        formattedSummary.substring(index, formattedSummary.length)
      )
    );

    return <>{parts}</>;
  }
}

function compareProps(props, newProps) {
  return (
    props.level === newProps.level &&
    props.isUTC === newProps.isUTC &&
    props.expanded === newProps.expanded &&
    props.filterTerms === newProps.filterTerms &&
    props.logs.length === newProps.logs.length
  );
}

function RequestLogEntry({
  onClick,
  id = null,
  meta = {},
  logs = [],
  summary = "",
  buildShareUrl,
  isUTC = false,
  level = "info",
  expanded = false,
  xrayBaseLink = "",
  logStreamName = "",
  filterTerms = null,
  isAccessLog = false,
  isSingleRequest = false,
}) {
  const filtered = filterTerms !== null;

  const permLinkStartTime = isAccessLog
    ? logs[0].t - 1000
    : meta.startTime
    ? meta.startTime - 1000
    : logs[0].t - 900000;
  const permLinkEndTime = isAccessLog
    ? logs[logs.length - 1].t + 1000
    : meta.endTime
    ? meta.endTime + 1000
    : logs[logs.length - 1].t + 900000;

  const isMissingStart = !isAccessLog && !isSingleRequest && !meta.startTime;
  const isMissingEnd = !isAccessLog && !isSingleRequest && !meta.endTime;

  const expandedCs = expanded ? "expanded" : "";
  const singleRequestCs = isSingleRequest ? "single" : "";

  function renderEmptyLog() {
    return (
      <>
        <RequestLogLine />
        <div className="divider"></div>
      </>
    );
  }

  function renderSummary({ uid, level, fields, summary, startTime }) {
    const utcTime = dateToFullTimeNoYearWithUTCTimeZone(startTime);
    const localTime = dateToFullTimeNoYearWithLocalTimeZone(startTime);

    return (
      <div className={`summary ${level}`} onClick={() => onClick(uid)}>
        <CaretIcon direction={expanded ? "down" : "right"} />
        <div className="contents">
          <div title="Log level" className="level">
            {level}
          </div>
          <div className="time" title={isUTC ? localTime : utcTime}>
            {isUTC ? utcTime : localTime}
          </div>
          {fields}
          <div className="text">{summary ? summary : <>&nbsp;</>}</div>
        </div>
      </div>
    );
  }

  function renderAccessLogSummary() {
    let fields;

    const uid = logs[0].i;
    const startTime = logs[0].t;
    const firstMessage = logs[0].m;

    if (!meta.httpMethod && !meta.httpStatus && !meta.resourcePath) {
      fields = <div className="spacer"></div>;
    } else {
      fields = (
        <>
          {meta.httpMethod && (
            <div title="HTTP Method" className="method">
              {meta.httpMethod}
            </div>
          )}
          {meta.httpStatus && (
            <div title="HTTP Status" className="status">
              {meta.httpStatus}
            </div>
          )}
          {meta.responseLatency && (
            <div title="Request duration" className="duration access-logs">
              {meta.responseLatency} ms
            </div>
          )}
          {meta.sourceIp && (
            <div title="Source IP" className="ip">
              {meta.sourceIp}
            </div>
          )}
          {meta.resourcePath && <div className="path">{meta.resourcePath}</div>}
        </>
      );
    }

    return renderSummary({
      uid,
      level,
      fields,
      startTime,
      summary:
        filtered && !isSingleRequest ? (
          <HighlightedLogSummary
            summary={firstMessage}
            filterTerms={filterTerms}
          />
        ) : meta.requestId ? (
          `RequestId: ${meta.requestId}`
        ) : (
          formatSummary(firstMessage)
        ),
    });
  }

  function renderLambdaSummary() {
    const uid = logs[0].i;
    const firstMessage = logs[0].m;
    const startTime = meta.startTime || logs[0].t;

    const memPercent =
      meta.memUsed && meta.memSize ? meta.memUsed / meta.memSize : null;
    const memPercentStr = memPercent
      ? `${parseFloat(100 * memPercent).toFixed(2)}% memory used`
      : "Memory used";
    const isMemHigh = memPercent ? memPercent > 0.9 : false;

    const fields =
      filtered && !isSingleRequest ? (
        <div className="spacer"></div>
      ) : (
        <>
          <div title="Request duration" className="duration">
            {id && meta.duration ? meta.duration : "-"} ms
          </div>
          <div
            title={memPercentStr}
            className={`memory ${isMemHigh ? "high" : ""}`}
          >
            {id && meta.memUsed ? meta.memUsed : "-"} MB
          </div>
        </>
      );

    return renderSummary({
      uid,
      level,
      fields,
      startTime,
      summary:
        filtered && !isSingleRequest ? (
          <HighlightedLogSummary
            summary={firstMessage}
            filterTerms={filterTerms}
          />
        ) : meta.requestId ? (
          `RequestId: ${meta.requestId} ${formatSummary(summary)}`
        ) : (
          formatSummary(summary)
        ),
    });
  }

  function renderDetails() {
    return (
      <div className="details">
        <div className="controls">
          <SectionHeader>Details</SectionHeader>
          <div>
            {meta.xrayTraceId && (
              <>
                <a
                  target="_blank"
                  className="xray"
                  rel="noopener noreferrer"
                  href={`${xrayBaseLink}${meta.xrayTraceId}`}
                >
                  X-Ray Trace
                  <FaIcon name="external-link" />
                </a>
                <span className="separator">&#124;</span>
              </>
            )}
            {
              <a
                target="_blank"
                className="link"
                rel="noopener noreferrer"
                href={buildShareUrl({
                  logStreamName,
                  filter: meta.requestId ? `"${meta.requestId}"` : ``,
                  logTimestamp: logs[0].t,
                  span: `${permLinkStartTime} to ${permLinkEndTime}`,
                })}
              >
                {isMissingStart || isMissingEnd ? (
                  "View complete request"
                ) : (
                  <>
                    <FaIcon name="link" />
                    Permalink
                  </>
                )}
              </a>
            }
          </div>
        </div>
        {isAccessLog && <AccessLogFieldsPanel fields={meta.messageFields} />}
        <div className="logs">
          {isMissingStart && renderEmptyLog()}
          {logs.map((log, i) => (
            <Fragment key={i}>
              <RequestLogLine log={log} isUTC={isUTC} />
              <div className="divider"></div>
            </Fragment>
          ))}
          {isMissingEnd && renderEmptyLog()}
        </div>
      </div>
    );
  }

  return (
    <div className={`RequestLogEntry ${expandedCs} ${singleRequestCs}`}>
      {isAccessLog ? renderAccessLogSummary() : renderLambdaSummary()}
      {expanded && renderDetails()}
    </div>
  );
}

export { HighlightedLogSummary };

export default React.memo(RequestLogEntry, compareProps);
