import React from "react";
import { Row, Col, Grid } from "react-bootstrap";
import "./Footer.css";

export default (props) => (
  <footer className="Footer">
    <Grid fluid>
      <Row>
        <Col sm={6}>
          <a className="brand" href="/">
            Seed
          </a>
        </Col>
        <Col className="col-right" sm={6}>
          <a href="https://anoma.ly">&copy; 2024 Anomaly Innovations</a>
        </Col>
      </Row>
    </Grid>
  </footer>
);
