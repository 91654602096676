import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { stripProtocol, isRelativeUrl } from "../lib/urlLib";
import SectionHeader from "./SectionHeader";
import "./SectionInfo.css";

function FluidRow({ stacked, ...props }) {
  return stacked ? (
    <>{props.children}</>
  ) : (
    <Row {...props}>{props.children}</Row>
  );
}

function FluidCol({ stacked, ...props }) {
  return stacked ? (
    <div {...props}>{props.children}</div>
  ) : (
    <Col {...props}>{props.children}</Col>
  );
}

export default function SectionInfo({
  label,
  value,
  description,
  link = false,
  button = false,
  className = "",
  linkText = null,
  stacked = false,
  ...props
}) {
  return (
    <div className={`SectionInfo ${className} ${button ? "button" : ""}`}>
      <FluidRow stacked={stacked}>
        <FluidCol stacked={stacked} className="col-info" md={4}>
          <SectionHeader>{label}</SectionHeader>
          {description && <p className="desc">{description}</p>}
        </FluidCol>
        <FluidCol stacked={stacked} className="col-body" md={8}>
          {link && !button && isRelativeUrl(value) && (
            <Link to={value} className="info-link">
              {linkText ? linkText : value}
            </Link>
          )}
          {link && !button && !isRelativeUrl(value) && (
            <a
              target="_blank"
              className="info-link"
              rel="noopener noreferrer"
              href={value}
              {...props}
            >
              {linkText ? linkText : stripProtocol(value)}
            </a>
          )}
          {button && !link && button}
          {!link && !button && <span className="info-text">{value}</span>}
          {!link && !button && props.children && props.children}
        </FluidCol>
      </FluidRow>
    </div>
  );
}
