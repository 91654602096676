import React, { useState, useEffect, useReducer } from "react";
import { Auth } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import withCancel from "../components/ComponentWithCancel";
import TextButton from "../components/TextButton";
import SectionInfo from "../components/SectionInfo";
import ScreenHeader from "../components/ScreenHeader";
import RightChevron from "../components/RightChevron";
import LoaderButton from "../components/LoaderButton";
import UpdateEmailForm from "../components/UpdateEmailForm";
import VerifyEmailForm from "../components/VerifyEmailForm";
import DeleteUserPanel from "../components/DeleteUserPanel";
import UpdatePasswordForm from "../components/UpdatePasswordForm";
import TwoFactorValidateForm from "../components/TwoFactorValidateForm";
import title from "../lib/titleLib";
import { errorHandler, createError } from "../lib/errorLib";
import { updateUserPassword, getUserAttributes } from "../lib/awsLib";
import "./UserSettings.css";

const defaultEmailState = {
  isLoading: true,
  verifyEmail: false,
  isEmailVerified: true,
  isExternalUser: false,
  showUpdateEmail: false,
  isEmailUpdating: false,
  emailVerificationCodeSent: false,
};

const defaultMfaState = {
  mfaMode: null,
  mfaCode: null,
  mfaEnabled: false,
  showMfaForm: false,
  isDisablingMfa: false,
  isValidatingMfaCode: false,
};

const defaultDeleteUserState = {
  show: false,
  loading: false,
  deleting: false,
  disabled: false,
  checks: null,
};

function emailReducer(state, action) {
  switch (action.type) {
    case "loaded":
      return {
        ...state,
        isLoading: false,
        isExternalUser: action.isExternalUser,
        isEmailVerified: action.isEmailVerified,
      };
    case "error":
      return { ...state, isLoading: false };
    case "verified":
      return {
        ...state,
        verifyEmail: false,
        isEmailVerified: true,
        showUpdateEmail: false,
        emailVerificationCodeSent: false,
      };
    case "show":
      return { ...state, showUpdateEmail: true };
    case "hide":
      return { ...state, showUpdateEmail: false };
    case "updating":
      return { ...state, isEmailUpdating: true };
    case "updated":
      return { ...state, isEmailUpdating: false };
    case "verify-needed":
      return { ...state, verifyEmail: true, emailVerificationCodeSent: true };
    case "verify":
      return { ...state, verifyEmail: true, showUpdateEmail: true };
    default:
      return state;
  }
}

function mfaReducer(state, action) {
  switch (action.type) {
    case "loaded":
      return { ...state, mfaMode: action.mode };
    case "show":
      return { ...state, showMfaForm: true };
    case "loaded-code":
      return { ...state, mfaCode: action.code };
    case "hide":
      return { ...state, showMfaForm: false, mfaCode: null };
    case "disabling":
      return { ...state, isDisablingMfa: true, mfaEnabled: false };
    case "disabled":
      return { ...state, isDisablingMfa: false, mfaMode: action.mode };
    case "disable-error":
      return { ...state, isDisablingMfa: false };
    case "validating":
      return { ...state, isValidatingMfaCode: true };
    case "validated":
      return {
        ...state,
        mfaCode: null,
        mfaEnabled: true,
        mfaMode: action.mode,
        isValidatingMfaCode: false,
      };
    case "validate-error":
      return { ...state, isValidatingMfaCode: false };
    default:
      return state;
  }
}

function deleteUserReducer(state, action) {
  switch (action.type) {
    case "loading":
      return { ...state, loading: true };
    case "load-error":
      return { ...state, loading: false };
    case "loaded":
      return { ...state, loading: false, show: true, checks: action.checks };
    case "deleting":
      return { ...state, deleting: true };
    case "delete-error":
      return { ...state, deleting: false };
    case "hide":
      return { ...defaultDeleteUserState };
    default:
      return state;
  }
}

function UserSettings(props) {
  const [user, setUser] = useState(null);
  const [
    {
      isLoading,
      verifyEmail,
      isExternalUser,
      isEmailVerified,
      showUpdateEmail,
      isEmailUpdating,
      emailVerificationCodeSent,
    },
    emailDispatch,
  ] = useReducer(emailReducer, defaultEmailState);
  const [
    {
      mfaMode,
      mfaCode,
      mfaEnabled,
      showMfaForm,
      isDisablingMfa,
      isValidatingMfaCode,
    },
    mfaDispatch,
  ] = useReducer(mfaReducer, defaultMfaState);
  const [deleteUserState, deleteUserDispatch] = useReducer(
    deleteUserReducer,
    defaultDeleteUserState
  );

  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);

  useEffect(() => {
    document.title = title("Account Settings");

    handleLoad();
  }, []);

  useEffect(() => {
    if (!showMfaForm || mfaMode !== "NOMFA" || mfaCode !== null) {
      return;
    }

    async function handleLoadMfaCode() {
      try {
        const code = await Auth.setupTOTP(user);
        mfaDispatch({ type: "loaded-code", code });
      } catch (e) {
        errorHandler(e);
      }
    }

    handleLoadMfaCode();
  }, [user, showMfaForm, mfaMode, mfaCode]);

  async function handleLoad() {
    try {
      const attributes = await getUserAttributes([
        "email_verified",
        "identities",
      ]);
      const isEmailVerified = attributes["email_verified"] !== "false";
      const isExternalUser = !!attributes["identities"];

      emailDispatch({ type: "loaded", isEmailVerified, isExternalUser });
    } catch (e) {
      // If the user is not logged in trigger the session expired
      // error handler
      if (e === "not authenticated") {
        errorHandler(createError("user_session_expired"));
      } else {
        emailDispatch({ type: "error" });
        errorHandler(e);
      }
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);

      // Get current MFA setting
      const mode = await Auth.getPreferredMFA(user, { bypassCache: true });
      mfaDispatch({ type: "loaded", mode });
    } catch (e) {
      errorHandler(e);
    }
  }

  //////////
  // APIs //
  //////////

  function updateUserInfo(email) {
    return props.invokeApig({
      path: "",
      method: "PUT",
      body: { email },
    });
  }

  function getDeleteUserChecks(email) {
    return props.invokeApig({
      path: "/user/delete_checks",
      method: "GET",
    });
  }

  function deleteUser() {
    return props.invokeApig({
      path: "/user",
      method: "DELETE",
    });
  }

  async function sendEmailVerificationCode(event) {
    try {
      await Auth.verifyCurrentUserAttribute("email");
    } catch (e) {
      throw e;
    }
  }

  async function confirmEmailVerificationCode(event, code) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", code);
      emailDispatch({ type: "verified" });
    } catch (e) {
      throw e;
    }
  }

  //////////////
  // Handlers //
  //////////////

  function handleShowUpdatePassword() {
    setShowUpdatePassword(true);
  }

  function handleCancelPasswordUpdate() {
    setShowUpdatePassword(false);
  }

  async function handlePasswordUpdate(event, oldPassword, password) {
    setIsPasswordUpdating(true);

    try {
      await updateUserPassword(oldPassword, password);
      setShowUpdatePassword(false);
    } catch (e) {
      setIsPasswordUpdating(false);
      throw e;
    }

    setIsPasswordUpdating(false);
  }

  function handleShowUpdateEmail() {
    emailDispatch({ type: "show" });
  }

  function handleCancelEmailUpdate() {
    emailDispatch({ type: "hide" });
  }

  async function handleEmailUpdate(email) {
    emailDispatch({ type: "updating" });

    try {
      await Auth.updateUserAttributes(user, { email });
      props.setUserAsAuthenticated(email);
      emailDispatch({ type: "verify-needed" });
      await updateUserInfo(email);
    } catch (e) {
      emailDispatch({ type: "updated" });
      throw e;
    }

    emailDispatch({ type: "updated" });
  }

  function handleVerifyEmailClick() {
    emailDispatch({ type: "verify" });
  }

  function handleShowMfaForm() {
    mfaDispatch({ type: "show" });
  }

  async function handleMfaValidateClick(validationCode) {
    mfaDispatch({ type: "validating" });

    try {
      // Verify validation token
      const verifyRet = await Auth.verifyTotpToken(user, validationCode);

      // Update user's MFA settings
      if (verifyRet && verifyRet.Status === "SUCCESS") {
        await Auth.setPreferredMFA(user, "TOTP");
      }

      // Get current MFA setting
      const mode = await Auth.getPreferredMFA(user);
      mfaDispatch({ type: "validated", mode });

      // Update user mfa setting in App
      await props.loadUserMfaMode();
    } catch (e) {
      errorHandler(e, {
        EnableSoftwareTokenMFAException: true,
      });
      mfaDispatch({ type: "validate-error" });
    }
  }

  async function handleDisableMfa() {
    if (
      !window.confirm(
        "Are you sure you want to disable two-factor auth for your account?"
      )
    ) {
      return;
    }

    mfaDispatch({ type: "disabling" });

    try {
      // Update user's MFA settings
      await Auth.setPreferredMFA(user, "NOMFA");

      // Get current MFA setting
      const mode = await Auth.getPreferredMFA(user);
      mfaDispatch({ type: "disabled", mode });

      // Update user mfa setting in App
      await props.loadUserMfaMode();
    } catch (e) {
      errorHandler(e);
      mfaDispatch({ type: "disable-error" });
    }
  }

  async function handleDeleteUserShowClick() {
    deleteUserDispatch({ type: "loading" });

    try {
      const ret = await getDeleteUserChecks();
      deleteUserDispatch({ type: "loaded", checks: ret.checks });
    } catch (e) {
      errorHandler(e);
    }

    deleteUserDispatch({ type: "load-error" });
  }

  function handleDeleteUserHideClick() {
    deleteUserDispatch({ type: "hide" });
  }

  async function handleDeleteUserDeleteClick() {
    deleteUserDispatch({ type: "deleting" });

    try {
      await deleteUser();
      await props.onLogout();
    } catch (e) {
      deleteUserDispatch({ type: "delete-error" });
      errorHandler(e);
    }
  }

  /////////////
  // Renders //
  /////////////

  function renderMfaButton() {
    if (isExternalUser) {
      return (
        <LoaderButton disabled bsSize="large">
          Enable Two-Factor Auth
        </LoaderButton>
      );
    }

    if (mfaMode === null) {
      return (
        <LoaderButton disabled bsSize="large">
          Loading Two-Factor Settings
        </LoaderButton>
      );
    }

    if (mfaMode === "NOMFA" && (!showMfaForm || (showMfaForm && !mfaCode))) {
      return (
        <LoaderButton
          bsSize="large"
          onClick={handleShowMfaForm}
          loading={showMfaForm && !mfaCode}
        >
          Enable Two-Factor Auth
        </LoaderButton>
      );
    }

    if (mfaMode === "SOFTWARE_TOKEN_MFA") {
      return (
        <>
          {mfaEnabled && (
            <p className="current updated">Two-factor auth has been enabled.</p>
          )}
          <LoaderButton
            bsSize="large"
            loading={isDisablingMfa}
            onClick={handleDisableMfa}
          >
            Disable Two-Factor Auth
          </LoaderButton>
        </>
      );
    }

    return null;
  }

  return (
    <div className="UserSettings">
      <ScreenHeader border>Account Settings</ScreenHeader>

      <SectionInfo
        label="Email"
        button={
          !showUpdateEmail && (
            <>
              {isExternalUser && (
                <p className="current">
                  You are currently logged through a third-party provider.
                </p>
              )}
              {!isExternalUser && isEmailVerified && (
                <p className="current email">
                  You are currently logged in as {props.authenticatedUser}.
                </p>
              )}
              {!isExternalUser && !isEmailVerified && (
                <p className="current error">
                  Your email has not been verified.&nbsp;
                  <TextButton onClick={handleVerifyEmailClick}>
                    Click here to verify it
                  </TextButton>
                  .
                </p>
              )}
              <LoaderButton
                bsSize="large"
                text="Update Your Email"
                disabled={isLoading || isExternalUser}
                onClick={handleShowUpdateEmail}
              />
            </>
          )
        }
      >
        {showUpdateEmail && (
          <div className="update-email">
            {!verifyEmail && (
              <UpdateEmailForm
                email={props.authenticatedUser}
                isUpdating={isEmailUpdating}
                onEmailUpdate={handleEmailUpdate}
                onCancelClick={handleCancelEmailUpdate}
              />
            )}
            {verifyEmail && (
              <VerifyEmailForm
                email={props.authenticatedUser}
                codeSent={emailVerificationCodeSent}
                onCancelClick={handleCancelEmailUpdate}
                onSendCodeClick={sendEmailVerificationCode}
                onConfirmCodeClick={confirmEmailVerificationCode}
              />
            )}
          </div>
        )}
      </SectionInfo>
      <hr />
      <SectionInfo
        label="Username"
        button={
          <LinkContainer exact to="/settings/username">
            <LoaderButton bsSize="large">
              Update Your Username
              <RightChevron />
            </LoaderButton>
          </LinkContainer>
        }
      />
      <hr />
      <SectionInfo
        label="Password"
        button={
          !showUpdatePassword && (
            <LoaderButton
              bsSize="large"
              disabled={isLoading || isExternalUser}
              onClick={handleShowUpdatePassword}
            >
              Update Your Password
            </LoaderButton>
          )
        }
      >
        {showUpdatePassword && (
          <UpdatePasswordForm
            isUpdating={isPasswordUpdating}
            onPasswordUpdate={handlePasswordUpdate}
            onCancelClick={handleCancelPasswordUpdate}
          />
        )}
      </SectionInfo>
      <hr />
      <SectionInfo label="Two-Factor Auth" button={renderMfaButton()}>
        {showMfaForm && mfaCode && (
          <TwoFactorValidateForm
            mfaCode={mfaCode}
            email={user.attributes.email}
            isValidating={isValidatingMfaCode}
            onValidateClick={handleMfaValidateClick}
            onHideClick={() => mfaDispatch({ type: "hide" })}
          />
        )}
      </SectionInfo>
      <hr />
      <DeleteUserPanel
        show={deleteUserState.show}
        loading={deleteUserState.loading}
        username={props.userInfo.username}
        deleting={deleteUserState.deleting}
        disabled={!props.userInfo.username}
        deleteChecks={deleteUserState.checks}
        onShowClick={handleDeleteUserShowClick}
        onHideClick={handleDeleteUserHideClick}
        onDeleteClick={handleDeleteUserDeleteClick}
      />
    </div>
  );
}

export default withCancel(UserSettings);
