import React from "react";
import { Link, useParams } from "react-router-dom";
import Alert from "./Alert";
import { getAppIssuesSettingsUrl } from "../lib/urlLib";
import config from "../config";
import "./IssuesEnableErrorAlert.css";

function runtimesToString(runtimes) {
  const l = runtimes.length;
  return runtimes.reduce((acc, runtime, i) => {
    const copy = config.lambdaRuntimes[runtime] || runtime;

    // a
    if (l === 1) {
      return copy;
    }
    // a and b
    else if (l === 2) {
      if (i === 0) {
        return copy + " ";
      } else {
        return `${acc}and ${copy}`;
      }
    }
    // a, b, and c
    else {
      if (i !== l - 1) {
        return `${acc}${copy}, `;
      } else {
        return `${acc}and ${copy}`;
      }
    }
  }, "");
}

export default function IssuesEnableErrorAlert({
  status,
  unsupportedRuntimes = [],
}) {
  const { ownerId, appId } = useParams();
  const runtimesCopy = unsupportedRuntimes.length === 1 ? "one" : "some";

  return (
    <Alert className="IssuesEnableErrorAlert" bsStyle="danger">
      {status === "failure_all" && (
        <>
          We were unable to enable Issues for your account.&nbsp;
          <Link to={getAppIssuesSettingsUrl(ownerId, appId)}>
            Head over to the settings
          </Link>{" "}
          and try enabling it again.
        </>
      )}
      {status === "failure_some" && (
        <>
          Issues could not be enabled for some of your Lambda functions.&nbsp;
          <Link to={getAppIssuesSettingsUrl(ownerId, appId)}>
            Head over to the settings
          </Link>{" "}
          and try enabling them again.
        </>
      )}
      {status === "unsupported_runtime" && (
        <>
          Issues is not currently supported for {runtimesCopy} of your runtimes
          ({runtimesToString(unsupportedRuntimes)}).{" "}
          <a href={`mailto:${config.contactEmail}`}>Contact us</a> if you&apos;d
          like us to add support for them.
        </>
      )}
    </Alert>
  );
}
