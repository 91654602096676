import React from "react";
import { ListGroup } from "react-bootstrap";
import EmptyListPanel from "./EmptyListPanel";
import AppActivityPanel from "./AppActivityPanel";
import LoaderButton from "./LoaderButton";
import "./AppActivityList.css";

function renderEmpty() {
  return (
    <EmptyListPanel message="There are no recent activities. Trigger a deployment to get started!" />
  );
}

export default ({
  pathParams,
  activities,
  hasNextPage,
  isLoadMoreLoading,
  handleRollbackClick,
  handleLoadMoreClick,
  activeActivityIds = [],
  rollbackProcessingActivityIds = [],
}) => (
  <div className="AppActivityList">
    {activities.length === 0 && renderEmpty()}
    {activities.length > 0 && (
      <>
        <ListGroup>
          {activities.map((activity, i) => (
            <AppActivityPanel
              key={i}
              activity={activity}
              pathParams={pathParams}
              isActive={activeActivityIds.includes(activity.activity_id)}
              isRollingBack={rollbackProcessingActivityIds.includes(
                activity.activity_id
              )}
              handleRollbackClick={handleRollbackClick}
            />
          ))}
        </ListGroup>
        {hasNextPage && (
          <LoaderButton
            bsStyle="link"
            text="Load older builds"
            className="load-more"
            loading={isLoadMoreLoading}
            onClick={handleLoadMoreClick}
          />
        )}
      </>
    )}
  </div>
);
