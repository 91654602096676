import React, { Component } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import { testEnvVarName } from "../lib/regexLib";
import TextButton from "./TextButton";
import EnvVarPanel from "./EnvVarPanel";
import SectionInfo from "./SectionInfo";
import LoaderButton from "./LoaderButton";
import "./EnvVarsPanel.css";

const helpUrl = "https://seed.run/docs/storing-secrets";

const noop = () => {};

const defaultProps = {
  keys: [],
  values: {},
  showings: {},
  savings: {},
  deletings: {},
  onShowClick: noop,
  onHideClick: noop,
  onAddVarClick: noop,
  onShowVarClick: noop,
  onDeleteVarClick: noop,
  onSaveVarClick: noop,
};

const defaultState = {
  validationError: false,
  newVar: {
    key: "",
    value: "",
  },
};

class EnvVarsForm extends Component {
  inputEl = null;

  state = { ...defaultState };

  handleNewVarEdit = (event) => {
    const { id, value } = event.target;
    this.setState({
      newVar: {
        ...this.state.newVar,
        [id]: value,
      },
    });

    if (id === "key") {
      this.setState({
        validationError: false,
      });
    }
  };

  handleAddVarClick = async (event) => {
    event.preventDefault();

    let { key = "", value = "" } = this.state.newVar;

    key = key.trim();
    value = value.trim();

    if (key === "" || value === "") {
      return;
    }

    if (!testEnvVarName(key)) {
      this.setState({ validationError: "invalid" });
      return;
    }

    if (this.props.keys.indexOf(key) !== -1) {
      this.setState({ validationError: "duplicate" });
      return;
    }

    const result = await this.props.onAddVarClick(event, { key, value });

    // The component has been unmounted already
    // We need to handle this case because the `withCancel` HOC
    // only applies to top level containers that are being removed
    if (this.inputEl === null) {
      return;
    }

    if (result) {
      this.setState({
        newVar: defaultState.newVar,
      });
    }

    // focus on key field
    this.inputEl.focus();
  };

  renderNewVar() {
    const { newVar, validationError } = this.state;

    return (
      <div className="newVar">
        <Form onSubmit={this.handleAddVarClick}>
          <FormGroup
            controlId="key"
            validationState={validationError ? "error" : null}
          >
            <FormControl
              inputRef={(inputEl) => {
                this.inputEl = inputEl;
              }}
              autoFocus
              type="text"
              bsSize="large"
              placeholder="Key"
              value={newVar.key}
              onChange={this.handleNewVarEdit}
            />
          </FormGroup>
          <FormGroup controlId="value">
            <FormControl
              rows="1"
              bsSize="large"
              placeholder="Value"
              value={newVar.value}
              componentClass="textarea"
              onChange={this.handleNewVarEdit}
            />
          </FormGroup>
          <div className="controls">
            <LoaderButton
              text="Add"
              type="submit"
              bsStyle="link"
              loading={this.props.adding}
              onClick={this.handleAddVarClick}
            />
          </div>
        </Form>
        {validationError === "invalid" && (
          <div className="validation">
            Keys must start with a letter and can only contain letters, numbers,
            and underscores.
          </div>
        )}
        {validationError === "duplicate" && (
          <div className="validation">
            You already have an environment variable with the same key.
          </div>
        )}
      </div>
    );
  }

  render() {
    const { keys, values, savings, showings, deletings } = this.props;

    return (
      <>
        <p className="helperCopy">
          Environment variables are encrypted by default.
        </p>
        <div className="vars">
          {keys.map((key, i) => (
            <EnvVarPanel
              key={key}
              name={key}
              value={values[key]}
              saving={savings[key]}
              showing={showings[key]}
              deleting={deletings[key]}
              onShowVarClick={(e, key) => this.props.onShowVarClick(e, key, i)}
              onDeleteVarClick={(e, key) =>
                this.props.onDeleteVarClick(e, key, i)
              }
              onSaveVarClick={(e, key, value) =>
                this.props.onSaveVarClick(e, key, value, i)
              }
            />
          ))}
          {this.renderNewVar()}
        </div>
        <TextButton className="btnHide" onClick={this.props.onHideClick}>
          Hide Env Variables
        </TextButton>
      </>
    );
  }
}

function EnvVarsPanel({
  showing,
  loading,
  adding,
  showings,
  savings,
  deletings,
  keys,
  values,
  onShowClick,
  onHideClick,
  onAddVarClick,
  onShowVarClick,
  onSaveVarClick,
  onDeleteVarClick,
}) {
  return (
    <div className="EnvVarsPanel">
      <SectionInfo
        label="Env Variables"
        description={
          <span>
            Configure environment variables for this stage.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn about storing environment variables.
            </a>
          </span>
        }
        button={
          showing === false && (
            <LoaderButton
              bsSize="large"
              className="btnShow"
              onClick={onShowClick}
              loading={loading}
              text="Show Env Variables"
            />
          )
        }
      >
        {showing === true && (
          <EnvVarsForm
            adding={adding}
            showings={showings}
            savings={savings}
            deletings={deletings}
            keys={keys}
            values={values}
            onShowClick={onShowClick}
            onHideClick={onHideClick}
            onAddVarClick={onAddVarClick}
            onShowVarClick={onShowVarClick}
            onSaveVarClick={onSaveVarClick}
            onDeleteVarClick={onDeleteVarClick}
          />
        )}
      </SectionInfo>
    </div>
  );
}

EnvVarsPanel.defaultProps = defaultProps;

export default EnvVarsPanel;
