import React, { useState } from "react";
import { Form, HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import SectionInfo from "./SectionInfo";
import LoaderButton from "./LoaderButton";
import TextButton from "./TextButton";
import "./TransferAppPanel.css";

const defaultSelectValue = "select org";

function TransferAppForm({
  orgs,
  appName,
  showing,
  loading,
  onHideClick,
  transferring,
  onTransferClick,
}) {
  const noOrgs = orgs.length === 0;
  const [formData, setFormData] = useState({
    confirmText: "",
    orgId: defaultSelectValue,
  });

  const isReadyToConfirm =
    formData.confirmText === appName && formData.orgId !== defaultSelectValue;

  function handleFieldEdit(event) {
    const { id, value } = event.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  }

  function handleTransferClick(event) {
    event.preventDefault();

    if (!isReadyToConfirm) {
      return;
    }

    onTransferClick(formData.orgId);
  }

  return (
    <div>
      <Form onSubmit={handleTransferClick}>
        <FormGroup controlId="orgId">
          <FormControl
            bsSize="large"
            componentClass="select"
            disabled={noOrgs || transferring}
            value={formData.orgId}
            onChange={handleFieldEdit}
          >
            {noOrgs && (
              <option disabled>You have no organizations to transfer to</option>
            )}
            {!noOrgs && (
              <option disabled value={defaultSelectValue}>
                Select an organization to transfer to
              </option>
            )}
            {orgs.map((org, i) => (
              <option key={i} value={org.name}>
                {org.name}
              </option>
            ))}
          </FormControl>
        </FormGroup>
        <FormGroup bsSize="large" controlId="confirmText">
          <HelpBlock>
            This action cannot be undone. Enter <b>{appName}</b> to confirm.
          </HelpBlock>
          <FormControl
            type="text"
            onChange={handleFieldEdit}
            value={formData.confirmText}
            disabled={noOrgs || transferring}
          />
        </FormGroup>
        <div className="controls">
          <LoaderButton
            bsSize="large"
            bsStyle="danger"
            loading={transferring}
            className="btnConfirm"
            onClick={handleTransferClick}
            disabled={noOrgs || !isReadyToConfirm}
          >
            Transfer
          </LoaderButton>
          <TextButton onClick={onHideClick}>Hide Transfer Options</TextButton>
        </div>
      </Form>
    </div>
  );
}

export default function TransferAppPanel({
  orgs,
  appName,
  showing,
  loading,
  onShowClick,
  onHideClick,
  transferring,
  onTransferClick,
}) {
  const formProps = {
    orgs,
    appName,
    showing,
    loading,
    onHideClick,
    transferring,
    onTransferClick,
  };

  return (
    <div className="TransferAppPanel">
      <SectionInfo
        label="Transfer App"
        description="Transfer this app to an organization."
        button={
          !showing && (
            <LoaderButton
              bsSize="large"
              bsStyle="danger"
              loading={loading}
              className="btnShow"
              onClick={onShowClick}
            >
              Transfer App
            </LoaderButton>
          )
        }
      >
        {showing && <TransferAppForm {...formProps} />}
      </SectionInfo>
    </div>
  );
}
