import React from "react";
import FaIcon from "./FaIcon";
import RightChevron from "./RightChevron";
import BuildHelpVoteButton from "./BuildHelpVoteButton";
import "./BuildHelpPanel.css";

function MissingServerlessYml({ errorContext, appUrl }) {
  return (
    <>
      <p>
        We could not find the <b>serverless.yml</b> for the{" "}
        <b>{errorContext.serviceName}</b> service. To fix this:
      </p>
      <ol>
        <li>
          Make sure it exists in the <code>{errorContext.servicePath}</code>{" "}
          path in your repo.
        </li>
        <li>
          Head over to the{" "}
          <b>
            <a href={`${appUrl}/services/${errorContext.serviceName}`}>
              {errorContext.serviceName} service
            </a>
          </b>{" "}
          page.
        </li>
        <li>
          Make sure the <b>Service Path</b> is set correctly.
        </li>
      </ol>
    </>
  );
}

function MissingSstJson({ errorContext, appUrl }) {
  return (
    <>
      <p>
        We could not find the <b>sst.config.ts</b> or <b>sst.json</b> for the{" "}
        <b>{errorContext.serviceName}</b> service. To fix this:
      </p>
      <ol>
        <li>
          Make sure it exists in the <code>{errorContext.servicePath}</code>{" "}
          path in your repo.
        </li>
        <li>
          Head over to the{" "}
          <b>
            <a href={`${appUrl}/services/${errorContext.serviceName}`}>
              {errorContext.serviceName} service
            </a>
          </b>{" "}
          page.
        </li>
        <li>
          Make sure the <b>Service Path</b> is set correctly.
        </li>
      </ol>
    </>
  );
}

function ErrorYarnUnexpectedEndOfData({ errorContext, appUrl }) {
  return (
    <>
      <p>
        Yarn failed to install node dependencies for the{" "}
        <b>{errorContext.serviceName}</b> service. To fix this:
      </p>
      <ol>
        <li>
          First, if you have private npm modules defined,&nbsp;
          <b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://seed.run/docs/private-npm-modules"
            >
              ensure that the registry is configured
            </a>
          </b>
          .
        </li>
        {errorContext.hasRootYarnLock && !errorContext.hasServiceYarnLock && (
          <li>
            Remove the <code>yarn.lock</code> file and the{" "}
            <code>node_modules</code> directory in your repo's root directory.
          </li>
        )}
        {!errorContext.hasRootYarnLock && errorContext.hasServiceYarnLock && (
          <li>
            Remove the <code>yarn.lock</code> file and the{" "}
            <code>node_modules</code> directory in the{" "}
            <code>{errorContext.servicePath}</code> directory.
          </li>
        )}
        {errorContext.hasRootYarnLock && errorContext.hasServiceYarnLock && (
          <li>
            Remove the <code>yarn.lock</code> file and the{" "}
            <code>node_modules</code> directory in the{" "}
            <code>{errorContext.servicePath}</code> and root directory.
          </li>
        )}

        {errorContext.hasRootYarnLock && !errorContext.hasServiceYarnLock && (
          <li>
            Run <code>yarn</code> in the root directory to generate a new{" "}
            <code>yarn.lock</code> file.
          </li>
        )}
        {!errorContext.hasRootYarnLock && errorContext.hasServiceYarnLock && (
          <li>
            Run <code>yarn</code> in the <code>{errorContext.servicePath}</code>{" "}
            directory to generate a new <code>yarn.lock</code> file.
          </li>
        )}
        {errorContext.hasRootYarnLock && errorContext.hasServiceYarnLock && (
          <li>
            Run <code>yarn</code> in the <code>{errorContext.servicePath}</code>{" "}
            and root directory to generate a new <code>yarn.lock</code> file.
          </li>
        )}

        {errorContext.hasRootYarnLock && errorContext.hasServiceYarnLock && (
          <li>
            Push the new <code>yarn.lock</code> files to git and trigger a
            build.
          </li>
        )}
        {!(errorContext.hasRootYarnLock && errorContext.hasServiceYarnLock) && (
          <li>
            Push the new <code>yarn.lock</code> file to git and trigger a build.
          </li>
        )}
      </ol>
    </>
  );
}

function ErrorSlsMissingPlugin({ errorContext, appUrl }) {
  return (
    <>
      <p>
        Serverless was not able to find the <b>{errorContext.pluginName}</b>{" "}
        plugin. To fix this:
      </p>
      <ol>
        <li>
          Make sure the plugin is installed. Run{" "}
          <code>npm install --save-dev {errorContext.pluginName}</code> to add
          the plugin to the <code>devDependencies</code> part of your{" "}
          <code>package.json</code>.
        </li>
        <li>
          Push the new <code>package.json</code> and lock files to git and
          trigger a build.
        </li>
      </ol>
    </>
  );
}

function ErrorSlsDomainCreate({ errorContext, appUrl }) {
  return (
    <>
      <p>
        Serverless failed to deploy because the custom domain specified for the{" "}
        <b>{errorContext.serviceName}</b> service was not created. To fix this:
      </p>
      <ol>
        <li>
          Run{" "}
          <code>serverless create_domain --stage {errorContext.stageName}</code>{" "}
          locally.
        </li>
        <li>
          Or alternatively, you can&nbsp;
          <b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://seed.run/docs/using-serverless-plugins#serverless-domain-manager"
            >
              follow these instructions and add it to your build spec.
            </a>
          </b>
        </li>
        <li>And trigger a new build.</li>
      </ol>
    </>
  );
}

function ErrorNodeWebpackJSHeapOOM({ errorContext, appUrl }) {
  return (
    <>
      <p>
        Serverless failed to generate the deployment package because webpack ran
        out of memory. To fix this:
      </p>
      <ul>
        <li>
          Disable minimizing JS files in the webpack config by using the{" "}
          <code>minimize: false</code> flag. You can&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://webpack.js.org/configuration/optimization/#optimizationminimize"
          >
            read more here
          </a>
          .
        </li>
        <li>
          Or, split up the <b>{errorContext.serviceName}</b> service so that
          webpack isn't bundling a ton of large files.
        </li>
        <li>
          You can also use a larger build machine by heading over to&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://seed.run/docs/build-machine-types"
          >
            your service settings
          </a>
          .
        </li>
      </ul>
    </>
  );
}

function ErrorDockerCannotConnect({ errorContext, appUrl }) {
  return (
    <>
      <p>
        Docker is not enabled by default on Seed.&nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://seed.run/docs/docker-commands-in-your-builds"
        >
          Learn more about enabling Docker.
        </a>
      </p>
    </>
  );
}

function ResourceAlreadyExists({ errorContext, appUrl }) {
  return (
    <>
      {errorContext.existingStackName && (
        <p>
          Serverless failed to deploy because it tried to create a{" "}
          <b>{errorContext.resourceType}</b> resource named{" "}
          <code>{errorContext.resourceName}</code>, and a resource with that
          name already exists in the{" "}
          <code>{errorContext.existingStackName}</code> stack. To fix this:
        </p>
      )}
      {!errorContext.existingStackName && (
        <p>
          Serverless failed to deploy because it tried to create a{" "}
          <b>{errorContext.resourceType}</b> resource named{" "}
          <code>{errorContext.resourceName}</code>, and a resource with that
          name already exists. To fix this:
        </p>
      )}
      <ul>
        <li>Remove the existing resource, if it's no longer needed.</li>
        <li>Or, change the name of the new resource.</li>
      </ul>
      {!errorContext.resourceNameContainsStageName && (
        <p>
          Note: It's a good practice to parameterize the resource name with the
          name of the stage to ensure uniqueness when deploying the service to
          multiple stages, e.g.,{" "}
          <b>{`${errorContext.resourceName}-${errorContext.stageName}`}</b>.
        </p>
      )}
    </>
  );
}

export default function BuildHelpPanel({
  successContext,
  errorCode,
  pathParams,
  errorSlsDocLink,
  errorBuildDocLink,
  errorContext,
  skippedCode,
  skippedMessage,
  skippedContext,

  reportSent,
  onReportClick,
  reporting = {},
}) {
  const errorComponentMap = {
    "ERROR 001": MissingServerlessYml,
    1001: ErrorYarnUnexpectedEndOfData,
    1011: ErrorSlsMissingPlugin,
    1012: ErrorSlsDomainCreate,
    docker_cannot_connect: ErrorDockerCannotConnect,
    node_webpack_js_heap_oom: ErrorNodeWebpackJSHeapOOM,
    resource_already_exists: ResourceAlreadyExists,
  };

  const skippedComponentMap = {
    "ERROR 001": MissingServerlessYml,
    sst_json_not_found: MissingSstJson,
  };

  function getAppUrl() {
    const { ownerId, appId } = pathParams;
    return `/${ownerId}/${appId}`;
  }

  const ErrorComponent = errorComponentMap[errorCode];
  const SkippedComponent = skippedComponentMap[skippedCode];
  const isSkippedSuccess =
    successContext && successContext.successType === "git_diff_deploy_skip";

  return ErrorComponent ||
    SkippedComponent ||
    errorSlsDocLink ||
    errorBuildDocLink ||
    isSkippedSuccess ? (
    <div className="BuildHelpPanel">
      <div className="content">
        {isSkippedSuccess && (
          <p className="doc-link">
            <FaIcon name="lightbulb-o" />
            Seed did not deploy this service because we could not detect any
            code changes in it.&nbsp;
            <a
              target="_blank"
              href="https://seed.run/docs/deploying-monorepo-apps"
              rel="noopener noreferrer"
            >
              Learn more about how we check for code changes
              <RightChevron />
            </a>
          </p>
        )}
        {ErrorComponent && (
          <>
            <p className="title">Why did this build fail?</p>
            <div className="body">
              <ErrorComponent
                appUrl={getAppUrl()}
                errorContext={errorContext}
              />
            </div>
          </>
        )}
        {SkippedComponent && (
          <>
            <p className="title">Why was this build skipped?</p>
            <div className="body">
              <SkippedComponent
                appUrl={getAppUrl()}
                errorContext={skippedContext}
              />
            </div>
          </>
        )}
        {!ErrorComponent &&
          !SkippedComponent &&
          errorSlsDocLink &&
          !errorBuildDocLink && (
            <p className="doc-link">
              <FaIcon name="lightbulb-o" />
              This is a common Serverless build error.&nbsp;
              <a
                target="_blank"
                href={errorSlsDocLink}
                rel="noopener noreferrer"
              >
                Learn more about the error and how to fix it over on our docs
                <RightChevron />
              </a>
            </p>
          )}
        {!ErrorComponent &&
          !SkippedComponent &&
          !errorSlsDocLink &&
          errorBuildDocLink && (
            <p className="doc-link">
              <FaIcon name="lightbulb-o" />
              This is a common build error.&nbsp;
              <a
                target="_blank"
                href={errorBuildDocLink}
                rel="noopener noreferrer"
              >
                Learn more about the error and how to fix it over on our docs
                <RightChevron />
              </a>
            </p>
          )}
        <div className="footer">
          <BuildHelpVoteButton
            voted={reportSent}
            votingUp={reporting.up}
            votingDown={reporting.down}
            onVoteClick={onReportClick}
            copy={
              isSkippedSuccess || SkippedComponent
                ? "Did you find this helpful?"
                : "Did this help fix your build?"
            }
          />
        </div>
      </div>
    </div>
  ) : null;
}
