export function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function buildCanonicalQueryString(queryParams) {
  if (Object.keys(queryParams).length < 1) {
    return "";
  }

  let sortedQueryParams = [];
  for (let property in queryParams) {
    if (queryParams.hasOwnProperty(property) && queryParams[property]) {
      sortedQueryParams.push(property);
    }
  }
  sortedQueryParams.sort();

  let canonicalQueryString = "";
  for (let i = 0; i < sortedQueryParams.length; i++) {
    canonicalQueryString +=
      sortedQueryParams[i] + "=" + queryParams[sortedQueryParams[i]] + "&";
  }
  return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
}

function encodeQueryStringParams(params) {
  const encodedParams = {};

  Object.keys(params).forEach(
    (param) => (encodedParams[param] = encodeURIComponent(params[param]))
  );

  return encodedParams;
}

export function buildQueryStringUrl(url, queryParams) {
  let queryString = buildCanonicalQueryString(queryParams);
  if (queryString !== "") {
    url += "?" + queryString;
  }

  return url;
}

export function isRelativeUrl(url) {
  return url.search(/^https?:\/\//i) === -1;
}

export function stripProtocol(url) {
  return url.replace(/^https?:\/\//i, "");
}

export function getRootUrl(url) {
  return url.toString().replace(/^(.*\/\/[^/?#]*).*$/, "$1");
}

export function getHomeUrl() {
  return `/`;
}
export function getAppUrl(ownerName, appSlug) {
  return `/${ownerName}/${appSlug}`;
}
export function getAppPipelineUrl(ownerName, appSlug) {
  return `/${ownerName}/${appSlug}/pipeline`;
}
export function getAppSettingsUrl(ownerName, appSlug) {
  return `/${ownerName}/${appSlug}/settings`;
}
export function getStageSettingsUrl(ownerName, appSlug, stageName) {
  return `/${ownerName}/${appSlug}/settings/stages/${stageName}`;
}
export function getAppPipelineEditUrl(ownerName, appSlug) {
  return `/${ownerName}/${appSlug}/pipeline/edit`;
}
export function getAppRemoveUrl(ownerName, appSlug) {
  return `/${ownerName}/${appSlug}/status`;
}
export function getAppDeployPhaseSettingsUrl(ownerName, appSlug) {
  return `/${ownerName}/${appSlug}/pipeline/phases`;
}
export function getAppActivityUrl(ownerName, appSlug, params = {}) {
  return (
    `${getAppUrl(ownerName, appSlug)}/activity` +
    (params.service ? `/services/${params.service}` : "") +
    (params.stage ? `/stages/${params.stage}` : "")
  );
}
export function getAppReportUrl(ownerName, appSlug, reportSlug, params = {}) {
  return (
    `${getAppUrl(ownerName, appSlug)}/reports/${reportSlug}` +
    (params.stage ? `/stages/${params.stage}` : "")
  );
}
export function getAppStageIssuesUrl(
  ownerName,
  appSlug,
  stageName,
  params = {}
) {
  const url = stageName
    ? `/${ownerName}/${appSlug}/issues/stages/${stageName}`
    : `/${ownerName}/${appSlug}/issues`;

  // Handle mode param
  if (params.mode === "active") {
    delete params.mode;
  }

  return buildQueryStringUrl(url, params);
}
export function getAppStageIssueUrl(
  ownerName,
  appSlug,
  stageName,
  issueGroupShortId,
  issueShortId
) {
  return stageName
    ? `/${ownerName}/${appSlug}/issues/stages/${stageName}/${issueGroupShortId}/${issueShortId}`
    : `/${ownerName}/${appSlug}/issues/${issueGroupShortId}/${issueShortId}`;
}
export function getAppIssuesSettingsUrl(ownerName, appSlug) {
  return `/${ownerName}/${appSlug}/settings/issues`;
}
export function getAppStageUrl(ownerName, appSlug, stageName) {
  return `${getAppUrl(ownerName, appSlug)}/stages/${stageName}`;
}
export function getAppStageApiLogsUrl(
  ownerName,
  appSlug,
  stageName,
  region,
  stack,
  apiId,
  params = {}
) {
  params = { ...params, type: "api", region, stack, apiId };
  return buildQueryStringUrl(
    `${getAppStageUrl(ownerName, appSlug, stageName)}/logs`,
    encodeQueryStringParams(params)
  );
}
export function getAppStageApiLogsUrlOld(
  ownerName,
  appSlug,
  stageName,
  service,
  stack,
  params = {}
) {
  params = { ...params, type: "api", service, stack };
  return buildQueryStringUrl(
    `${getAppStageUrl(ownerName, appSlug, stageName)}/logs`,
    encodeQueryStringParams(params)
  );
}
export function getAppStageApiMetricsUrl(
  ownerName,
  appSlug,
  stageName,
  region,
  stack,
  apiId,
  params = {}
) {
  params = { ...params, type: "api", region, stack, apiId };
  return buildQueryStringUrl(
    `${getAppStageUrl(ownerName, appSlug, stageName)}/metrics`,
    encodeQueryStringParams(params)
  );
}
export function getAppStageApiMetricsUrlOld(
  ownerName,
  appSlug,
  stageName,
  service,
  stack,
  params = {}
) {
  params = { ...params, type: "api", service, stack };
  return buildQueryStringUrl(
    `${getAppStageUrl(ownerName, appSlug, stageName)}/metrics`,
    encodeQueryStringParams(params)
  );
}
export function getAppStageLambdaLogsUrl(
  ownerName,
  appSlug,
  stageName,
  region,
  lambda,
  params = {}
) {
  params = { ...params, region, lambda };
  return buildQueryStringUrl(
    `${getAppStageUrl(ownerName, appSlug, stageName)}/logs`,
    encodeQueryStringParams(params)
  );
}
export function getAppStageLambdaLogsUrlOld(
  ownerName,
  appSlug,
  stageName,
  service,
  lambda,
  params = {}
) {
  params = { ...params, service, lambda };
  return buildQueryStringUrl(
    `${getAppStageUrl(ownerName, appSlug, stageName)}/logs`,
    encodeQueryStringParams(params)
  );
}
export function getAppStageLambdaMetricsUrl(
  ownerName,
  appSlug,
  stageName,
  region,
  lambda,
  params = {}
) {
  params = { ...params, region, lambda };
  return buildQueryStringUrl(
    `${getAppStageUrl(ownerName, appSlug, stageName)}/metrics`,
    encodeQueryStringParams(params)
  );
}
export function getAppStageLambdaMetricsUrlOld(
  ownerName,
  appSlug,
  stageName,
  service,
  lambda,
  params = {}
) {
  params = { ...params, service, lambda };
  return buildQueryStringUrl(
    `${getAppStageUrl(ownerName, appSlug, stageName)}/metrics`,
    encodeQueryStringParams(params)
  );
}
export function getAppStageRemoveUrl(ownerName, appSlug, stageName) {
  return `${getStageSettingsUrl(ownerName, appSlug, stageName)}/status`;
}
export function getAppStageBuildUrl(ownerName, appSlug, stageName, buildId) {
  return `${getAppActivityUrl(ownerName, appSlug, {
    stage: stageName,
  })}/builds/${buildId}`;
}
export function getAppStageBuildServiceUrl(
  ownerName,
  appSlug,
  stageName,
  buildId,
  serviceName
) {
  return `${getAppStageBuildUrl(
    ownerName,
    appSlug,
    stageName,
    buildId
  )}/services/${serviceName}`;
}
export function getAppStageBuildTestUrl(
  ownerName,
  appSlug,
  stageName,
  buildId
) {
  return `${getAppStageBuildUrl(
    ownerName,
    appSlug,
    stageName,
    buildId
  )}/post-deploy`;
}
export function getServiceUrl(ownerName, appSlug, serviceName) {
  return `${getAppUrl(ownerName, appSlug)}/settings/services/${serviceName}`;
}
export function getServiceRemoveUrl(ownerName, appSlug, serviceName) {
  return `${getServiceUrl(ownerName, appSlug, serviceName)}/status`;
}
export function getStageUrl(ownerName, appSlug, serviceName, stageName) {
  return `${getServiceUrl(
    ownerName,
    appSlug,
    serviceName
  )}/stages/${stageName}`;
}
export function getStageServiceUrl(ownerName, appSlug, stageName, service) {
  return `${getAppStageUrl(ownerName, appSlug, stageName)}/services/${service}`;
}
export function getServiceStageRemoveLogUrl(
  ownerName,
  appSlug,
  serviceName,
  stageName
) {
  return `${getServiceRemoveUrl(
    ownerName,
    appSlug,
    serviceName
  )}/stages/${stageName}`;
}
export function getStageServiceRemoveLogUrl(
  ownerName,
  appSlug,
  stageName,
  serviceName
) {
  return `${getAppStageRemoveUrl(
    ownerName,
    appSlug,
    stageName
  )}/services/${serviceName}`;
}
export function getDeploymentUrl(ownerName, appSlug, stageName, service) {
  return `${getStageServiceUrl(
    ownerName,
    appSlug,
    stageName,
    service
  )}/resources`;
}
export function getOrgSettingsUrl(ownerName) {
  return `/orgs/${ownerName}/settings`;
}
export function getOrgMembersUrl(ownerName) {
  return `/orgs/${ownerName}/members`;
}
export function getOrgBillingUrl(ownerName) {
  return `/orgs/${ownerName}/settings/billing`;
}
export function getOrgAuditLogUrl(ownerName, page = null) {
  const url = `/orgs/${ownerName}/logs`;

  return page ? buildQueryStringUrl(url, { page }) : url;
}
