import React from "react";
import CustomDomainsList from "./CustomDomainsList";
import CustomDomainForm from "./CustomDomainForm";
import LoaderButton from "./LoaderButton";
import SectionInfo from "./SectionInfo";
import "./CustomDomainPanel.css";

const helpUrl = "https://seed.run/docs/configuring-custom-domains";

export default function CustomDomainPanel({
  adding,
  removing,
  retrying,
  onAddClick,
  showingForm,
  onShowClick,
  onHideClick,
  onRetryClick,
  onRemoveClick,
  loadingDomains,
  route53Domains,
  onShowFormClick,
  onHideFormClick,
  loading = false,
  showing = false,
  customEndpoints = [],
  loadDomainsErrorMessage,
  availableEndpoints = [],
  ...props
}) {
  const noEndpointsMessage = !showing
    ? null
    : availableEndpoints.length === 0 && customEndpoints.length === 0
    ? "There are no available API endpoints in your app. Please deploy a service with an API Gateway endpoint."
    : availableEndpoints.length === 0
    ? "All the API endpoints in your app have been configured with a custom domain."
    : null;

  function renderCollapsed() {
    return (
      <LoaderButton
        bsSize="large"
        className="btnShow"
        loading={loading}
        onClick={onShowClick}
      >
        Edit Custom Domains
      </LoaderButton>
    );
  }

  function renderExpanded() {
    return (
      <>
        <CustomDomainsList
          removing={removing}
          retrying={retrying}
          domains={customEndpoints}
          onRetryClick={onRetryClick}
          onRemoveClick={onRemoveClick}
        />
        <CustomDomainForm
          adding={adding}
          showing={showingForm}
          onAddClick={onAddClick}
          domains={route53Domains}
          loading={loadingDomains}
          onCancelClick={onHideClick}
          onShowClick={onShowFormClick}
          onHideClick={onHideFormClick}
          endpoints={availableEndpoints}
          noEndpointsMessage={noEndpointsMessage}
          loadErrorMessage={loadDomainsErrorMessage}
        />
      </>
    );
  }

  return (
    <div className="CustomDomainPanel">
      <SectionInfo
        label="Custom Domains"
        button={!showing && renderCollapsed()}
        description={
          <span>
            Configure custom domains for your app.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn more.
            </a>
          </span>
        }
      >
        {showing && renderExpanded()}
      </SectionInfo>
    </div>
  );
}
