import React, { Component } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import "./SignupConfirmForm.css";

const noop = () => {};

const defaultProps = {
  email: "",
  isConfirming: false,
  onConfirmClick: noop,
};

const defaultState = {
  formData: {
    code: "",
  },
};

export default class SignupConfirmForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
    };
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
    });
  };

  handleConfirmClick = async (event) => {
    event.preventDefault();

    const code = this.state.formData.code;

    if (code.trim() === "") {
      return;
    }

    await this.props.onConfirmClick(event, code);
  };

  render() {
    const { email, isConfirming } = this.props;
    const { formData } = this.state;

    return (
      <form className="SignupConfirmForm" onSubmit={this.handleConfirmClick}>
        <FormGroup bsSize="large" controlId="code">
          <StyledControlLabel>Confirmation Code</StyledControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={formData.code}
            onChange={this.handleFieldChange}
          />
          <HelpBlock>
            Please check your email ({email}) for the confirmation code.
          </HelpBlock>
        </FormGroup>
        <div className="controls">
          <LoaderButton
            type="submit"
            bsSize="large"
            bsStyle="warning"
            loading={isConfirming}
            onClick={this.handleConfirmClick}
          >
            Confirm
          </LoaderButton>
        </div>
      </form>
    );
  }
}

SignupConfirmForm.defaultProps = defaultProps;
