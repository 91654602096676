import React, { Component } from "react";
import StageIamPanel from "../../components/StageIamPanel";
import { errorHandler } from "../../lib/errorLib";

const noop = () => {};

const defaultProps = {
  iamRole: false,
  iamAccess: false,

  policyInfoLoading: false,
  onShowIamRoleClick: noop,

  onSave: noop,
};

const defaultState = {
  iamRole: false,
  iamAccess: false,
  editing: false,
  saving: false,
  removing: false,

  // Flag to trigger clearing the form
  clearForm: 0,
};

class StageIam extends Component {
  state = { ...defaultState };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.iamRole !== prevState.iamRole) {
      return { iamRole: nextProps.iamRole };
    } else if (nextProps.iamAccess !== prevState.iamAccess) {
      return { iamAccess: nextProps.iamAccess };
    }
    return null;
  }

  handleEditClick = (event) => {
    this.setState({ editing: true });
  };

  handleSaveClick = async (event, iamData) => {
    this.setState({ saving: true });

    try {
      await this.props.onSave(event, iamData);

      this.setState((prevState) => ({
        editing: false,
        saving: false,
        iamRole: iamData.iam_role,
        iamAccess: iamData.iam_access,
        clearForm: prevState.clearForm + 1,
      }));
    } catch (e) {
      this.setState({ saving: false });
      errorHandler(e);
    }
  };

  handleRemoveClick = async (event) => {
    this.setState({ removing: true });

    try {
      await this.props.onSave(event, {
        iam_role: null,
        iam_access: null,
        iam_secret: null,
      });

      this.setState((prevState) => ({
        removing: false,
        iamRole: false,
        iamAccess: false,
        clearForm: prevState.clearForm + 1,
      }));
    } catch (e) {
      this.setState({ removing: false });
      errorHandler(e);
    }
  };

  handleHideClick = (event) => {
    this.setState({ editing: false });
  };

  render() {
    const {
      iamRole,
      iamAccess,
      editing,
      saving,
      removing,
      clearForm,
    } = this.state;
    const { policyInfoLoading, onShowIamRoleClick } = this.props;

    return (
      <StageIamPanel
        editing={editing}
        saving={saving}
        removing={removing}
        iamRole={iamRole}
        iamAccess={iamAccess}
        clearForm={clearForm}
        onEditClick={this.handleEditClick}
        onSaveClick={this.handleSaveClick}
        onHideClick={this.handleHideClick}
        onRemoveClick={this.handleRemoveClick}
        policyInfoLoading={policyInfoLoading}
        onShowIamRoleClick={onShowIamRoleClick}
      />
    );
  }
}

StageIam.defaultProps = defaultProps;

export default StageIam;
