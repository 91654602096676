import React from "react";
import { Link } from "react-router-dom";
import FaIcon from "./FaIcon";
import { shortHash } from "../lib/gitLib";
import { dateToSinceTime } from "../lib/timeLib";
import { getAppStageBuildUrl } from "../lib/urlLib";
import "./PipelineTableStageStatusCell.css";

function statusToGlyphicon(statusState) {
  const textArray = {
    queued: "cog fa-spin",
    skipped: "minus-circle",
    success: "check-circle",
    failure: "times-circle",
    deploying: "cog fa-spin",
  };

  return textArray[statusState];
}

export default function PipelineTableStageStatusCell({
  appSlug,
  activity,
  ownerName,
  mini = false,
}) {
  const cs = "PipelineTableStageStatusCell";
  const miniCs = mini ? "mini" : "";

  if (!activity) {
    return (
      <div className={`${cs} ${miniCs}`}>
        <p className="empty">&#8212;</p>
      </div>
    );
  }

  const multipleCommits = activity.commitSHAs.length > 1;

  const buildId = activity.build_id;
  const stageName = activity.stage_id;
  const hasBranch = !multipleCommits && (activity.branch || activity.pr);
  const buildLink = getAppStageBuildUrl(ownerName, appSlug, stageName, buildId);

  return (
    <div className={`${cs} ${miniCs}`}>
      <Link to={buildLink}>
        <FaIcon name={statusToGlyphicon(activity.status)} />
      </Link>
      <div>
        <p className="line-1">
          <Link className="id" to={buildLink}>
            v{buildId}
          </Link>
          <span className="date">{dateToSinceTime(activity.created_at)}</span>
        </p>
        <p className="line-2">
          {hasBranch && (
            <a
              target="_blank"
              className="branch"
              rel="noopener noreferrer"
              href={activity.branch ? activity.branch_url : activity.pr_url}
            >
              <FaIcon name="code-fork" />
              {activity.branch ? activity.branch : `pr#${activity.pr}`}
            </a>
          )}
          {!multipleCommits && (
            <a
              target="_blank"
              className="commit"
              rel="noopener noreferrer"
              href={activity.commit.url}
            >
              {shortHash(activity.commit.id)}
            </a>
          )}
          {multipleCommits && (
            <span className="multiple">Multiple commits</span>
          )}
        </p>
      </div>
    </div>
  );
}
