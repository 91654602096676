import React from "react";
import { Link } from "react-router-dom";
import KeyMetricsGraph, {
  durations as graphDurations,
} from "./KeyMetricsGraph";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import RightChevron from "./RightChevron";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import { stripProtocol } from "../lib/urlLib";
import { formatKeyMetricsNumbers } from "../lib/stringLib";
import "./DashboardMetricsPanel.css";

const typeCopyMap = {
  api: "API",
  lambda: "Lambda",
};
export const durations = {
  week: "week",
  day: "day",
};

export default function DashboardMetricsPanel({
  onEditClick,
  metrics = null,
  loading = false,
  onDurationChange,
  isDeployed = false,
  metricsLinkBuilder,
  hasMultiProd = false,
  onPermissionsErrorClick,
  duration = durations.day,
  metricsPermissionsError = false,
  resourcesPermissionsError = false,
}) {
  const empty = metrics === null || metrics.length === 0;
  const emptyCs = empty ? "empty" : "";

  function isHistoryEmpty(history) {
    return history.every(({ v }) => v === null);
  }

  function renderEmpty() {
    return (
      <div className="empty">
        <TextButton onClick={onEditClick}>
          Pick a couple of metrics to get started
        </TextButton>
      </div>
    );
  }

  function renderNotDeployed() {
    return (
      <div className="empty">
        <span className="copy">
          Make your first deployment to production to view key metrics.
        </span>
      </div>
    );
  }

  function renderResourcePermissionError() {
    return (
      <div className="error">
        <div className="content">
          <FaIcon name="exclamation-triangle" />
          <p>Missing IAM permissions</p>
          <TextButton onClick={onPermissionsErrorClick}>
            Click here to add the permissions
          </TextButton>
        </div>
      </div>
    );
  }

  function renderMetricsPermissionError() {
    return (
      <div className="error">
        <LoaderButton bsStyle="red-link" onClick={onPermissionsErrorClick}>
          <FaIcon name="exclamation-triangle" />
          Add a couple of permissions to view key metrics
        </LoaderButton>
      </div>
    );
  }

  function renderEmptyMetric() {
    return (
      <div className="empty">
        <SectionHeader>No data</SectionHeader>
        <span className="unit">- ms</span>
      </div>
    );
  }

  function renderLoadingMetric() {
    return (
      <div className="empty loading">
        <span className="spinner">
          <span className="bounce1"></span>
          <span className="bounce2"></span>
          <span className="bounce3"></span>
        </span>
      </div>
    );
  }

  function renderSparkline(data, id) {
    let grade;
    const n = data.length - 1;
    const currValue = data[n].v || 0;
    const prevValues = data.slice(0, n).map((per) => per.v || 0);

    // get variance of previous data
    const mean = prevValues.reduce((a, b) => a + b) / n;
    const variance = Math.sqrt(
      prevValues.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );

    // grade is # of variance above mean (max 5, min 0)
    grade =
      variance === 0
        ? currValue > 0
          ? 5
          : 0
        : Math.round(Math.max(Math.min((currValue - mean) / variance, 5), 0));

    return (
      <KeyMetricsGraph
        id={id}
        data={data}
        colorGrade={grade}
        duration={
          duration === durations.day ? graphDurations.hour : graphDurations.day
        }
      />
    );
  }

  function renderMetricValue({ value, unit, history }, metricsUrl, fullName) {
    const hasHistory = history !== null && !isHistoryEmpty(history);

    const formattedValue = value && formatKeyMetricsNumbers(value);

    return (
      <div className="value">
        <div className="graph">
          {hasHistory ? (
            <Link to={metricsUrl}>{renderSparkline(history, fullName)}</Link>
          ) : (
            <SectionHeader>No data</SectionHeader>
          )}
        </div>
        <div className="number">
          {value === null ? (
            <span className="unit">- ms</span>
          ) : (
            <>
              <span>{formattedValue[0]}</span>
              <span className="unit">{formattedValue[1]}</span>
            </>
          )}
        </div>
      </div>
    );
  }

  function renderMetric(metric) {
    const { name, stage, fullName, description, latency, type } = metric;

    const noData = latency.value === null;

    const metricsUrl = metricsLinkBuilder({
      ...metric,
      span: duration === durations.day ? "24 hours" : "14 days",
    });

    return (
      <div key={fullName} className="metric">
        <span className="name">
          <Link to={metricsUrl}>
            {hasMultiProd && (
              <>
                <span>{stage}</span>
                <RightChevron />
              </>
            )}
            <span>{typeCopyMap[type]}</span>
            <RightChevron />
            {name}
          </Link>
          <span>{stripProtocol(description)}</span>
        </span>
        <div className="values">
          {loading
            ? metricsPermissionsError
              ? renderEmptyMetric()
              : renderLoadingMetric()
            : noData && latency.history === null
            ? renderEmptyMetric()
            : renderMetricValue(latency, metricsUrl, fullName)}
        </div>
      </div>
    );
  }

  function renderHeader() {
    return (
      <div className="header">
        <div className="col1">
          <SectionHeader>P95 Latency</SectionHeader>
        </div>
        <div className="col2">
          <SectionHeader>Past&nbsp;</SectionHeader>
          <div>
            <TextButton
              onClick={() => onDurationChange(durations.day)}
              className={duration === durations.day ? "selected" : ""}
            >
              24h
            </TextButton>
            <TextButton
              onClick={() => onDurationChange(durations.week)}
              className={duration === durations.week ? "selected" : ""}
            >
              14d
            </TextButton>
          </div>
        </div>
        <div className="col3">
          <SectionHeader>Current</SectionHeader>
        </div>
      </div>
    );
  }

  return (
    <div className={`DashboardMetricsPanel ${emptyCs}`}>
      {resourcesPermissionsError && renderResourcePermissionError()}
      {!resourcesPermissionsError && (
        <>
          <h4>
            <span>
              Key Metrics
              {!metricsPermissionsError && isDeployed && (
                <TextButton onClick={onEditClick}>Edit</TextButton>
              )}
            </span>
          </h4>
          {!isDeployed && renderNotDeployed()}
          {isDeployed && empty && renderEmpty()}
          {isDeployed && !empty && (
            <div className="body">
              {metricsPermissionsError && renderMetricsPermissionError()}
              {!metricsPermissionsError && renderHeader()}
              <div>{metrics.map(renderMetric)}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
