import React, { useEffect } from "react";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import withAppHeader from "../components/ComponentWithAppHeader";
import withCancel from "../components/ComponentWithCancel";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorAlert from "../components/ErrorAlert";
import AppList from "../components/AppList";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import { getLoadError } from "../lib/errorLib";
import "./Owner.css";

const loadErrorCodes = {
  OrgNotExist: "OWNER_NOT_FOUND",
  4006: "OWNER_NO_PERMISSION",
};

function Owner(props) {
  let apps = null;
  let owner = null;
  let loadError = null;
  let isLoading = false;

  const { ownerId } = props.match.params;

  useEffect(() => {
    document.title = title(ownerId);
  }, [ownerId]);

  const { data: info, error } = useAPILoad(`/${ownerId}?version=v2`, (path) =>
    props.invokeAppsApig({ path })
  );

  if (info) {
    isLoading = false;
    apps = info.apps;
    owner = info.owner;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
  }

  const isReady = !isLoading && !loadError && owner;

  return (
    <div className="Owner">
      {isLoading && <LoadingSpinner />}

      {error && !loadError && <ErrorAlert error={error} />}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="owner"
          code={loadError}
          context={{
            owner: ownerId,
          }}
        />
      )}

      {isReady && <AppList owner={ownerId} apps={apps} />}
    </div>
  );
}

export default withAppHeader(withCancel(Owner));
