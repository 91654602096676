import React, { Component } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import "./EnvVarPanel.css";

const noop = () => {};

const defaultProps = {
  showing: false,
  saving: false,
  deleting: false,
  name: null,
  value: null,
  onShowVarClick: noop,
  onDeleteVarClick: noop,
  onSaveVarClick: noop,
};

const defaultState = {
  value: null,
};

class EnvVarPanel extends Component {
  state = { ...defaultState };

  handleShowVarClick = async (event, name) => {
    const value = await this.props.onShowVarClick(event, name);
    this.setState({ value });
  };

  handleSaveVarClick = async (event, name) => {
    event.preventDefault();

    const value = this.state.value;

    if (!value || value.trim() === "") {
      return;
    }

    await this.props.onSaveVarClick(event, name, value);
  };

  handleDeleteVarClick = async (event, name) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this environment variable?"
    );

    if (!confirmed) {
      return;
    }

    await this.props.onDeleteVarClick(event, name);
  };

  handleVarEdit = (event, name) => {
    this.setState({
      value: event.target.value,
    });
  };

  render() {
    const { name, value, showing, saving, deleting } = this.props;
    const stateValue = this.state.value;

    return (
      <div className="EnvVarPanel">
        <Form onSubmit={(e) => this.handleSaveVarClick(e, name)}>
          <FormGroup controlId={`key_${name}`}>
            <FormControl readOnly type="text" bsSize="large" value={name} />
          </FormGroup>
          <FormGroup controlId={`value_${name}`}>
            <FormControl
              rows="1"
              bsSize="large"
              placeholder="Value"
              componentClass="textarea"
              readOnly={value === null}
              value={
                value
                  ? stateValue === null
                    ? value
                    : stateValue
                  : "●●●●●●●●●●●●"
              }
              onChange={(e) => this.handleVarEdit(e, name)}
            />
          </FormGroup>

          <div className="controls">
            {value && (
              <LoaderButton
                text="Save"
                type="submit"
                bsStyle="link"
                className="btnSave"
                disabled={
                  deleting ||
                  !stateValue ||
                  stateValue.trim() === "" ||
                  value === stateValue
                }
                loading={saving}
                onClick={(e) => this.handleSaveVarClick(e, name)}
              />
            )}

            {!value && (
              <LoaderButton
                text="Show"
                bsStyle="link"
                className="btnShow"
                disabled={deleting}
                loading={showing}
                onClick={(e) => this.handleShowVarClick(e, name)}
              />
            )}

            <span className="separator">&#124;</span>
            <LoaderButton
              text="Delete"
              bsStyle="red-link"
              className="btnDelete"
              disabled={showing}
              loading={deleting}
              onClick={(e) => this.handleDeleteVarClick(e, name)}
            />
          </div>
        </Form>
      </div>
    );
  }
}

EnvVarPanel.defaultProps = defaultProps;

export default EnvVarPanel;
