import React from "react";
import LoaderButton from "./LoaderButton";
import config from "../config";
import "./AccessLogSettingsPanel.css";

export default function AccessLogSettingsPanel({
  updating,
  status = "",
  onEnableClick,
  onDisableClick,
  ...props
}) {
  function renderButton() {
    return (
      <>
        <span>&nbsp;</span>
        {status !== "success" && status !== "disabling" && (
          <LoaderButton
            bsStyle="link"
            className="btnDisable"
            onClick={onEnableClick}
            loading={updating || status === "pending" || status === "enabling"}
          >
            Enable Access Logs
          </LoaderButton>
        )}
        {(status === "success" || status === "disabling") && (
          <LoaderButton
            bsStyle="link"
            className="btnDisable"
            onClick={onDisableClick}
            loading={updating || status === "disabling"}
          >
            Disable Access Logs
          </LoaderButton>
        )}
      </>
    );
  }

  return (
    <div className="AccessLogSettingsPanel">
      {status === "success" && (
        <span className="current">
          Access logs are enabled for this service.
        </span>
      )}
      {status === "" && (
        <span className="current">
          Access logs are not enabled for this service.
        </span>
      )}
      {status === "pending" && (
        <span className="current">Updating the policy for API Gateway.</span>
      )}
      {status === "error_unknown" && !updating && (
        <span className="current error">
          An error occured while trying to enable the access logs. Please
          refresh the page and try again. You can also{" "}
          <a href={`mailto:${config.contactEmail}`}>contact us</a> if you need
          any help.
        </span>
      )}
      {renderButton()}
    </div>
  );
}
