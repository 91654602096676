import React from "react";
import TextButton from "./TextButton";
import "./EmptyListPanel.css";

export default function EmptyListPanel({ message, onClick }) {
  return (
    <div className="EmptyListPanel">
      {!onClick && <p>{message}</p>}
      {onClick && <TextButton onClick={onClick}>{message}</TextButton>}
    </div>
  );
}
