import React from "react";
import sstIcon from "../images/sst-icon.svg";
import slsIcon from "../images/serverless-icon.svg";
import defaultIcon from "../images/service-icon.svg";
import "./ServiceIcon.css";

const serviceTypeMap = {
  sls: "sls",
  component: "sls",
  default: "default",
  sst: "sst",
  "sst/resources": "sst",
};
const iconMap = {
  sls: slsIcon,
  sst: sstIcon,
  default: defaultIcon,
};

const dimensionsMap = {
  large: [48, 40],
  medium: [24, 20],
  small: [18, 15],
};

export default function ServiceIcon({
  type = "sls",
  size = "large",
  className = "",
  ...props
}) {
  const [width, height] = dimensionsMap[size];

  type = serviceTypeMap[type] || "sls";

  return (
    <img
      width={width}
      height={height}
      src={iconMap[type]}
      className={`ServiceIcon ${size} ${className} ${type}`}
      alt={type === "sst" ? "Serverless Stack Icon" : "Serverless Icon"}
    />
  );
}
