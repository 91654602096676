import React from "react";
import { Alert as BsAlert } from "react-bootstrap";
import "./Alert.css";

export default function Alert({ className = "", ...props }) {
  return (
    <BsAlert className={`SeedAlert ${className}`} {...props}>
      {props.children}
    </BsAlert>
  );
}
