import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useReducer,
  useCallback,
  createContext,
} from "react";
import { invokeApig } from "./awsLib";
import { makeCancelable } from "./promiseLib";
import { cacheGet, cacheSet } from "./apiLoadLib";

const defaultPolicyState = {
  policyInfo: null,
  policyModalKey: 0,
  policyEditing: false,
  policyUpdating: false,
  showPolicyModal: false,
  policyUpdateError: null,
  hasPolicyUpdated: false,
  policyInfoLoading: false,
};

function policyReducer(state, action) {
  switch (action.type) {
    case "show-policy-editor":
      return {
        ...state,
        policyEditing: true,
      };
    case "hide-policy-editor":
      return {
        ...state,
        policyEditing: false,
        policyUpdateError: null,
      };
    case "updating-policy-editor":
      return {
        ...state,
        policyUpdateError: null,
        policyUpdating: true,
      };
    case "updated-policy-editor":
      return {
        ...state,
        hasPolicyUpdated: true,
        policyEditing: false,
        policyUpdating: false,
        policyInfo: action.policyInfo,
      };
    case "error-policy-editor":
      return {
        ...state,
        policyUpdating: false,
        policyUpdateError: action.policyUpdateError,
      };
    case "info-loading":
      return {
        ...state,
        policyInfoLoading: true,
      };
    case "info-loaded":
      return {
        ...state,
        showPolicyModal: true,
        policyInfo: action.policyInfo,
        policyModalKey: state.policyModalKey + 1,
      };
    case "hide-info":
      return {
        ...defaultPolicyState,
        policyModalKey: state.policyModalKey,
      };
    default:
      return state;
  }
}

export function usePolicyReducer() {
  return useReducer(policyReducer, defaultPolicyState);
}

function removeStatusReducer(state, action) {
  switch (action.type) {
    case "show-retry":
      return {
        ...state,
        retry: true,
      };
    case "hide-retry":
      return {
        ...state,
        retry: false,
      };
    case "removing":
      return {
        ...state,
        removing: true,
      };
    case "quick-removing":
      return {
        ...state,
        quickRemoving: true,
      };
    case "removed":
    case "quick-removed":
      return {
        ...state,
        retry: false,
        removing: false,
        quickRemoving: false,
      };
    default:
      return state;
  }
}

export function useRemoveStatusReducer() {
  return useReducer(removeStatusReducer, {
    retry: false,
    removing: false,
    quickRemoving: false,
  });
}

export function useFormReducer(fields) {
  const [state, dispatch] = useReducer(formReducer, {
    values: { ...fields },
    isDirty: {},
    validation: {},
  });

  function formReducer(state, action) {
    switch (action.type) {
      case "edit":
        return {
          ...state,
          values: {
            ...state.values,
            [action.id]: action.value,
          },
          isDirty: {
            ...state.isDirty,
            [action.id]: true,
          },
          validation: {
            ...state.validation,
            [action.id]: null,
          },
        };
      case "validate":
        return {
          ...state,
          validation: {
            ...state.validation,
            [action.id]: "error",
          },
        };
      case "reset":
        return {
          ...state,
          values: {
            ...(action.fields || fields),
          },
          isDirty: {},
          validation: {},
        };
      default:
        return state;
    }
  }

  function handleFieldChange(event) {
    const { id, value, type, checked } = event.target;

    dispatch({
      id,
      type: "edit",
      value: type === "checkbox" ? checked : value,
    });
  }

  return [state, dispatch, handleFieldChange];
}

export function useBlurDropdown({ toggleEl, selectEl, onClick }) {
  useEffect(() => {
    function handleDocumentClick(e) {
      const select = selectEl();
      const toggle = toggleEl();

      if (select === null || toggle === null) {
        return;
      }

      if (select && select.contains(e.target)) {
        return;
      } else if (toggle.contains(e.target)) {
        return;
      } else if (select) {
        onClick();
      }
    }

    document.addEventListener("mousedown", handleDocumentClick);

    return () => document.removeEventListener("mousedown", handleDocumentClick);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useLogQueries({ queries, cancelUrl }) {
  const requestIdCounter = useRef(0);
  const queryInfo = useRef({});
  const [queryData, setQueryData] = useState({});
  const [queryErrors, setQueryErrors] = useState({});
  const interval = 500;

  const fetchQuery = useCallback(async (query) => {
    const path = queryInfo.current[query].queryId
      ? `${query}&queryId=${queryInfo.current[query].queryId}`
      : query;

    const cancelablePromise = makeCancelable(invokeApig({ path }));
    queryInfo.current = {
      ...queryInfo.current,
      [query]: { ...queryInfo.current[query], promise: cancelablePromise },
    };

    try {
      // call api
      const result = await cancelablePromise.promise;
      queryInfo.current = {
        ...queryInfo.current,
        [query]: {
          ...queryInfo.current[query],
          queryId: result.queryId,
          isQueryCompleted: result.isQueryCompleted,
        },
      };

      // query completed => set data
      if (result.isQueryCompleted) {
        cacheSet(query, result);
        setQueryData((queryData) => ({ ...queryData, [query]: result }));
      }
      // query NOT completed => schedule
      else {
        queryInfo.current = {
          ...queryInfo.current,
          [query]: {
            ...queryInfo.current[query],
            timer: window.setTimeout(() => fetchQuery(query), interval),
          },
        };
      }
    } catch (error) {
      setQueryErrors((queryErrors) => ({ ...queryErrors, [query]: error }));
    }
  }, []);

  const cancelQuery = useCallback(
    (query) => {
      const info = queryInfo.current[query];

      // cancel promise and timer
      info.timer !== null && window.clearTimeout(info.timer);
      info.promise !== null && info.promise.cancel();
      info.timer = null;
      info.promise = null;

      // stop query
      if (info.queryId && !info.isQueryCompleted) {
        invokeApig({
          path: cancelUrl,
          method: "POST",
          body: { queryId: info.queryId },
        });
      }

      delete queryInfo.current[query];
      queryInfo.current = { ...queryInfo.current };

      setQueryData((queryData) => {
        delete queryData[query];
        return { ...queryData };
      });

      setQueryErrors((queryErrors) => {
        delete queryErrors[query];
        return { ...queryErrors };
      });
    },
    [cancelUrl]
  );

  useEffect(() => {
    // stop queries not exist anymore
    Object.keys(queryInfo.current)
      .filter((query) => !queries.includes(query))
      .forEach(cancelQuery);

    // run new queries
    queries
      .filter((query) => !queryInfo.current[query])
      .forEach((query) => {
        requestIdCounter.current++;
        const newQuery = { requestId: requestIdCounter.current };
        queryInfo.current = { ...queryInfo.current, [query]: newQuery };
        fetchQuery(query);
      });
  }, [queries, fetchQuery, cancelQuery]);

  useEffect(() => {
    return () => {
      // cancel all queries
      Object.keys(queryInfo.current).forEach(cancelQuery);
    };
  }, [cancelQuery]);

  return {
    data: queries.map((query) => {
      if (queryData[query]) {
        return {
          requestId: queryInfo.current[query].requestId,
          data: queryData[query],
        };
      } else if (cacheGet(query)) {
        return {
          // note: mock a request id, b/c currently request ids are only used to distinguish errors
          requestId: "cached",
          data: cacheGet(query),
        };
      }
      return null;
    }),
    error: queries.map((query) => {
      let error = queryErrors[query] || null;
      if (error) {
        error = {
          requestId: queryInfo.current[query].requestId,
          error,
        };
      }
      return error;
    }),
  };
}

export function useLogTailing(url = null) {
  const INTERVAL = 1000;
  // Increase linearly to this
  // ((1500(1500+1)/2)-(1000(1000+1)/2))/(60*1000) ~ 10mins
  // ie, increase linearly for 10mins
  const BACKOFF_LIMIT = 1500;
  // Then increase exponentially to this and stay capped there.
  const MAX_INTERVAL = INTERVAL * Math.pow(2, 8);
  const MAX_EVENTS = 3000;

  const promise = useRef(null);
  const timer = useRef(null);
  const nextToken = useRef(null);
  const [errors, setErrors] = useState({});
  const [, setCacheData] = useState(null);
  const currentInterval = useRef(INTERVAL);
  const eventIds = useRef({});

  const { onNetworkError } = useAppContext();

  useEffect(() => {
    function onBrowserFocus() {
      if (currentInterval.current > BACKOFF_LIMIT) {
        cancelQuery();
        fetchQuery();
      }
    }

    function browserHasFocus() {
      return !document.hasFocus || document.hasFocus();
    }

    function setTimer(interval = INTERVAL) {
      currentInterval.current = interval;
      timer.current = window.setTimeout(fetchQuery, interval);
    }

    function setTimerWithBackoff() {
      setTimer(
        currentInterval.current < BACKOFF_LIMIT
          ? currentInterval.current + 1
          : Math.min(2 * currentInterval.current, MAX_INTERVAL)
      );
    }

    async function fetchQuery() {
      const isInitialRequest = !nextToken.current;
      const path = nextToken.current
        ? `${url}&nextToken=${nextToken.current}`
        : url;

      promise.current = makeCancelable(invokeApig({ path }));

      try {
        // call api
        const result = await promise.current.promise;

        nextToken.current = result.nextToken;

        // filter duplicate events
        const resultEvents = result.events.filter((event) => {
          if (eventIds.current[event.i]) {
            return false;
          }
          eventIds.current[event.i] = true;
          return true;
        });

        let data = cacheGet(url);
        let events = (data ? data.response.events : []).concat(resultEvents);
        let startCutoffAt = data ? data.startCutoffAt : null;
        if (events.length > MAX_EVENTS) {
          events = events.slice(-(MAX_EVENTS / 2));
          startCutoffAt = events[0].t;
        }
        data = {
          response: {
            ...result,
            events,
            hasMissingLogs:
              (data && data.response.hasMissingLogs) || result.hasMissingLogs,
          },
          startCutoffAt,
        };
        cacheSet(url, data);
        // note: setCacheData is a dummy state. It is only responsible for triggering the
        //       component to reload after data is changed.
        setCacheData(data);
      } catch (error) {
        if (isInitialRequest) {
          setErrors((errors) => ({ ...errors, [url]: error }));
        } else if (
          error.code === "NETWORK_OFFLINE" ||
          error.code === "NETWORK_ERROR"
        ) {
          onNetworkError();
        }
      }

      browserHasFocus() ? setTimer() : setTimerWithBackoff();
    }

    function cancelQuery() {
      // cancel promise and timer
      timer.current !== null && window.clearTimeout(timer.current);
      promise.current !== null && promise.current.cancel();
      timer.current = null;
      promise.current = null;
      currentInterval.current = INTERVAL;

      nextToken.current = null;
    }

    function reset() {
      eventIds.current = {};
      setErrors({});
    }

    if (url === null) {
      return;
    }

    // url has changed
    fetchQuery();

    window.addEventListener("focus", onBrowserFocus);

    return () => {
      cancelQuery();
      reset();
      window.removeEventListener("focus", onBrowserFocus);
    };
  }, [MAX_INTERVAL, url, onNetworkError]);

  return {
    data: cacheGet(url) || null,
    error: errors[url],
  };
}

export const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

function urlPermissionErrorReducer(state, action) {
  switch (action.type) {
    case "set":
      return { ...state, error: action.error };
    case "dismiss":
      return { ...state, shown: true };
    case "clear":
      return { ...state, error: null, shown: false };
    default:
      return state;
  }
}

export function usePermissionErrorReducer() {
  return useReducer(urlPermissionErrorReducer, { error: null, shown: false });
}

function searchModalReducer(state, action) {
  switch (action.type) {
    case "show":
      return { ...state, show: true, key: state.key + 1 };
    case "hide":
      return { ...state, show: false };
    default:
      return state;
  }
}

export function useSearchModalReducer() {
  return useReducer(searchModalReducer, { key: 0, show: false });
}

/**
 * Always returns the initial value. Useful for cases where we don't want to update
 * the component if the prop updates.
 * @param currentValue The variable or prop that could change
 * @returns The initial value that was set
 */
export function useInitial(currentValue) {
  const value = useRef(currentValue);

  return value.current;
}

/**
 * Returns the previous value of the set value
 * @param value The value to keep track of
 * @returns The previous value
 */
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

/**
 * Only return the updated value based on the custom comaprison function provided.
 * @param value The variable or prop that could change
 * @param equal A function that returns true if the value hasn't changed
 * @returns The value based on the compare
 */
export function useCustomCompare(value, equal) {
  const ref = useRef(value);

  if (!equal(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

const compareInputs = (inputKeys, oldInputs, newInputs) => {
  inputKeys.forEach((key) => {
    const oldInput = oldInputs[key];
    const newInput = newInputs[key];
    if (oldInput !== newInput) {
      console.log("change detected", key, "old:", oldInput, "new:", newInput);
    }
  });
};

/**
 * Debug dependcy array for hooks. It'll print the dependencies that've changed
 * between calls.
 * Usage: useDependenciesDebugger({ dep1, dep2 })
 * @param inputs An array of dependencies
 */
export function useDependenciesDebugger(inputs) {
  const oldInputsRef = useRef(inputs);
  const inputValuesArray = Object.values(inputs);
  const inputKeysArray = Object.keys(inputs);

  useMemo(() => {
    const oldInputs = oldInputsRef.current;

    compareInputs(inputKeysArray, oldInputs, inputs);

    oldInputsRef.current = inputs;
  }, inputValuesArray); // eslint-disable-line react-hooks/exhaustive-deps
}

function createOrgReducer(state, action) {
  switch (action.type) {
    case "show":
      return { ...state, show: true, key: ++state.key };
    case "hide":
      return { ...state, show: false };
    case "creating":
      return { ...state, creating: true };
    case "create-error":
      return { ...state, creating: false };
    case "created":
      return { ...state, creating: false, show: false, key: ++state.key };
    default:
      return state;
  }
}

export function useCreateOrgReducer() {
  const [state, dispatch] = useReducer(createOrgReducer, {
    key: 0,
    show: false,
    creating: false,
  });

  function onShowClick(event) {
    event.preventDefault();
    dispatch({ type: "show" });
  }

  function onHideClick(event) {
    event.preventDefault();
    dispatch({ type: "hide" });
  }

  return [state, dispatch, onShowClick, onHideClick];
}
