import React from "react";
import { getFirstLine } from "../lib/stringLib";

export default function CommitMessageSpan({ commit }) {
  const commitMessage = commit.message.trim();
  const truncMessage = getFirstLine(commitMessage);
  const isMultiLine = truncMessage !== commitMessage;

  return (
    <span className="CommitMessageSpan" title={commitMessage}>
      {truncMessage}
      {isMultiLine && <>&nbsp;&hellip;</>}
    </span>
  );
}
