import { useRef, useMemo, useEffect, useReducer, useCallback } from "react";
import {
  useInitial,
  useAppContext,
  usePrevious,
  useCustomCompare,
} from "./hooksLib";
import { isAPINetworkError } from "./errorLib";
import { makeCancelable } from "./promiseLib";
import { invokeApig } from "./awsLib";

const __cache = new Map();

function generateDependenciesString(dependencies) {
  return dependencies.map((dep) => (dep ? dep : "")).join(",");
}

export function cacheGet(key) {
  return __cache.get(key) || undefined;
}

export function cacheSet(key, value) {
  return __cache.set(key, value);
}

export function cacheClear() {
  __cache.clear();
}

function defaultFetch(path) {
  return invokeApig({ path });
}

function formatUris(uris) {
  let argument;

  // Falsey value
  if (!uris) {
    argument = null;
  }
  // Array of URIs
  else if (Array.isArray(uris)) {
    argument = uris;
  }
  // Function that returns URIs
  else if (typeof uris === "function") {
    try {
      const runUris = uris();
      argument = runUris;
    } catch {
      argument = null;
    }
  }
  // URI
  else {
    argument = uris;
  }

  return argument;
}

function reducer(state, action) {
  switch (action.type) {
    case "clear":
    case "loaded":
      return { ...state, error: null };
    case "error":
      return { ...state, error: { [action.key]: action.error } };
    default:
      return state;
  }
}

/**
 * Poll loading an API or array of APIs. Usage examples:
 *
 * // Standard case
 * const { data, error } = useAPILoad(`/${ownerId}/${appId}`);
 *
 * // With a custom fetch function
 * const { data, error } = useAPILoad(
 *   `/${ownerId}/${appId}/stages/${appStageId}`,
 *   path => props.invokeAppsApig({ path })
 * );
 *
 * // With an array of URIs
 * const { data: [ api1, api2 ], error } = useAPILoad(
 *   [ `/${ownerId}`, `/${ownerId}/${appId}` ],
 *   ([url1, url2]) =>
 *     Promise.all([
 *       props.invokeAppsApig({ path: url1 }),
 *       props.invokeApig({ path: url2 })
 *     ])
 * );
 * const { data: [ api1, api2 ], error } = useAPILoad(
 *   [ `/${ownerId}`, `/${ownerId}/${appId}` ],
 *   [
 *      url1 => props.invokeAppsApig({ path: url1 }),
 *      url2 => props.invokeApig({ path: url2 })
 *   ]
 * );
 *
 * // Conditional loading. If `appId` is not defined then the request is skipped
 * const { data, error } = useAPILoad(appId && `/${ownerId}/${appId}`);
 *
 * // Dependent loading
 * const { data: user } = useAPILoad('/api/user')
 * const { data: projects } = useAPILoad(() => '/api/projects?uid=' + user.id)
 *
 * Uses the following scheme to decide what to return:
 *
 *   Initial load, no cache
 *    -- data: return data
 *    -- error: return error
 *
 *   Initial load, with cache
 *    -- data: return data
 *    -- error: return error
 *
 *   Subsequent load, with cache
 *    -- data: return data
 *    -- error: return error and data if browser is in focus
 *              or suppress error if browser is not in focus
 *
 *   Subsequent load, no cache
 *    -- data: return data
 *    -- error: return error
 *
 * @param uris    Takes an API path, array of paths, function, or falsey value.
 *                If passed in a falsey value, the request is skipped.
 *                If passed in a function, it is evaluated to get a path or array
 *                of paths. If it returns a falsey value, then the request is
 *                skipped. The paths are used as a key to cache.
 * @param fetch   A function that fetches the API path(s) and returns a promise.
 *                Defaults to fetching the main API. Is passed in the API paths from
 *                the uris param.
 * @param options Object of options. Turn off polling by passing in `polling: false`.
 *
 * @returns { data, error, reload }
 *          data  : API response. Null while loading.
 *          error : Error in case API request failed.
 *          reload: Function to cancel currently queued request
 *                  and make one right away.
 */
export default function useAPILoad(uris, fetch = defaultFetch, options = {}) {
  // If no custom fetch function is passed in
  if (typeof fetch !== "function" && !Array.isArray(fetch)) {
    options = fetch;
    fetch = defaultFetch;
  }

  // Don't allow changing the fetch function
  fetch = useInitial(fetch);

  const polling = options.hasOwnProperty("polling") ? options.polling : true;

  // Set options once and don't update for the rest of the lifecycle
  const pollingInterval = useInitial(options.pollingInterval || 4000);

  // Keep track of change in polling option
  const prevPolling = usePrevious(polling);

  // Compare using JSON.stringify to update to handle array of uris
  const argument = useCustomCompare(
    useMemo(() => formatUris(uris), [uris]),
    (curr, old) =>
      curr === old ? true : JSON.stringify(curr) === JSON.stringify(old)
  );
  const dependencies = useMemo(
    () => (Array.isArray(argument) ? argument : [argument]),
    [argument]
  );
  const isMultiFetch = Array.isArray(argument);
  const dependenciesString = generateDependenciesString(dependencies);

  const pollTimer = useRef(null);
  const initialLoad = useRef(true);
  const currentRequest = useRef(null);
  // Track timer interval for exponential backoff
  const currentInterval = useRef(pollingInterval);

  const { onNetworkError } = useAppContext();

  let [{ error }, dispatch] = useReducer(reducer, { error: null });

  const clear = useCallback(() => {
    pollTimer.current !== null && window.clearTimeout(pollTimer.current);
    currentRequest.current !== null && currentRequest.current.cancel();

    pollTimer.current = null;
    currentRequest.current = null;
    currentInterval.current = pollingInterval;
  }, [pollingInterval]);

  const load = useCallback(
    async (mode) => {
      // Max interval for exponential backoff
      const MAX_INTERVAL = Math.min(1024000, pollingInterval * Math.pow(2, 8));

      function setTimer(interval = pollingInterval) {
        if (!polling) {
          return;
        }

        currentInterval.current = interval;
        pollTimer.current = window.setTimeout(() => load("polling"), interval);
      }

      function setTimerWithBackoff() {
        setTimer(Math.min(2 * currentInterval.current, MAX_INTERVAL));
      }

      function saveToCache(data) {
        data = isMultiFetch ? data : [data];

        data.forEach((datum, i) => cacheSet(dependencies[i], datum));
      }

      function clearCache() {
        saveToCache(
          isMultiFetch ? new Array(argument.length).fill(null) : null
        );
      }

      function setError(error, silent = false) {
        if (!silent) {
          clearCache();
        }

        if (isAPINetworkError(error)) {
          onNetworkError();
        } else {
          dispatch({ error, key: dependenciesString, type: "error" });
        }
      }

      function prepareFetch(mode) {
        const createFetchPromise = (uri, i) => {
          const cachedValue = getFromCache(uri);
          // Simply return the cached value if:
          // 1. We are fetching because the uris are updated
          // 2. It's not the initial load,
          // 3. And it's available in cache.
          return mode === "update" &&
            !initialLoad.current &&
            cachedValue !== null
            ? Promise.resolve(cachedValue)
            : Array.isArray(fetch)
            ? // Use the given fetch function
              fetch[i](uri)
            : // Use default fetch function
              fetch(uri);
        };

        const fetchPromise = isMultiFetch
          ? // For a multi fetch wrap it all up in an Promise.all
            (uris) => Promise.all(uris.map(createFetchPromise))
          : createFetchPromise;

        const cancelablePromise = makeCancelable(fetchPromise(argument));
        currentRequest.current = cancelablePromise;

        return cancelablePromise.promise;
      }

      try {
        const data = await prepareFetch(mode);

        // Set timer for polling
        browserHasFocus()
          ? setTimer()
          : // Use backoff, if not in focus
            setTimerWithBackoff();

        saveToCache(data);
        dispatch({ type: "loaded" });
      } catch (error) {
        // Poll with exponential backoff
        setTimerWithBackoff();

        if (initialLoad.current) {
          setError(error);
        }
      }

      initialLoad.current = false;
    },
    [
      fetch,
      polling,
      argument,
      isMultiFetch,
      pollingInterval,
      dependencies,
      onNetworkError,
      dependenciesString,
    ]
  );

  const reload = useCallback(async () => {
    // Do not reload if url is null
    if (argument === null) {
      return;
    }

    clear();
    await load("reload");
  }, [clear, load, argument]);

  useEffect(() => {
    if (argument === null) {
      return;
    }

    function onBrowserFocus() {
      if (currentInterval.current > pollingInterval) {
        reload();
      }
    }

    // If we are not polling anymore but we were, don't make any new requests
    // if the polling option has changed.
    if (!polling && prevPolling) {
    } else {
      load("update");
    }

    if (polling) {
      window.addEventListener("focus", onBrowserFocus);
    }

    return () => {
      clear();

      dispatch({ type: "clear" });
      initialLoad.current = true;

      if (polling) {
        window.removeEventListener("focus", onBrowserFocus);
      }
    };
    // Ignore prevPolling as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clear,
    load,
    reload,
    polling,
    argument,
    pollingInterval,
    dependenciesString,
  ]);

  // Don't store the data in the state because when the request URI
  // changes, the data in the state will be invalid.
  const data = loadFromCache();

  function getFromCache(uri) {
    return uri === null ? null : cacheGet(uri) || null;
  }

  function loadFromCache() {
    const results = dependencies.map(getFromCache);

    return isMultiFetch ? results : results[0];
  }

  function browserHasFocus() {
    return !document.hasFocus || document.hasFocus();
  }

  return {
    data,
    reload: reload,
    // Handle case where the cache key changes,
    // don't return error from previous load
    error: (error && error[dependenciesString]) || null,
  };
}
