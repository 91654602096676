import React from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import { useFormReducer } from "../lib/hooksLib";
import "./GitBranchForm.css";

const noop = () => {};

export default function GitBranchForm({
  onChange = noop,
  onSubmit = noop,
  disabled = false,
  lastDeployedRefs = [],
}) {
  const [formData, formDispatch] = useFormReducer({ search: "" });

  function handleClick(value) {
    handleFieldChange({ target: { value } });
  }

  function handleFieldChange(event) {
    const { value } = event.target;

    formDispatch({
      value,
      id: "search",
      type: "edit",
    });

    onChange({ target: { id: "branch", value } });
  }

  return (
    <div className="GitBranchForm">
      <Form className="form" onSubmit={onSubmit}>
        <FormGroup bsSize="large" controlId="search">
          <FormControl
            autoFocus
            type="text"
            autoComplete="off"
            disabled={disabled}
            onChange={handleFieldChange}
            value={formData.values.search}
            placeholder="Enter a Git branch, tag, or commit"
          />
        </FormGroup>
      </Form>
      {lastDeployedRefs.length !== 0 && (
        <div className="recently-deployed">
          <div className="list">
            <SectionHeader>Recent</SectionHeader>
            {lastDeployedRefs.length > 0 &&
              lastDeployedRefs.map((ref, i) => (
                <LoaderButton
                  key={ref}
                  bsStyle="block"
                  onClick={(e) => handleClick(ref)}
                >
                  {ref}
                </LoaderButton>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
