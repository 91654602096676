import React from "react";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import StyledButtonGroup from "./StyledButtonGroup";
import "./IssueStatusControl.css";

export default function IssueStatusControl({
  muted = false,
  resolved = false,
  disabled = false,
  onMuteUpdate,
  muting = false,
  onResolveUpdate,
  resolving = false,
}) {
  return (
    <StyledButtonGroup className="IssueStatusControl">
      <LoaderButton
        active={muted}
        loading={muting}
        disabled={disabled}
        onClick={() => onMuteUpdate(!muted)}
      >
        {!muting && <FaIcon name="bell-slash-o" />}
        Ignore
      </LoaderButton>
      <LoaderButton
        active={resolved}
        loading={resolving}
        disabled={disabled}
        onClick={() => onResolveUpdate(!resolved)}
      >
        {!resolving && <FaIcon name="check" />}
        Resolve
      </LoaderButton>
    </StyledButtonGroup>
  );
}
