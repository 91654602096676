import React, { Fragment } from "react";
import { ListGroup } from "react-bootstrap";
import AppActivityWorkflowBuild from "./AppActivityWorkflowBuild";
import ScrollShadowContainer from "./ScrollShadowContainer";
import { shortHash } from "../lib/gitLib";
import "./AppActivityWorkflow.css";

function statusToCopy(statusState) {
  const textArray = {
    success: "Completed",
    failure: "Failed",
    deploying: "In Progress",
    queued: "Queued",
    skipped: "Skipped",
  };
  return textArray[statusState];
}

function buildAction(activity) {
  let title = "";

  switch (activity.action) {
    case "promote":
      title = `Promote`;
      break;
    case "rollback":
      title = "Rollback";
      break;
    case "deploy_branch":
    case "deploy_pr":
    case "deploy_cli":
    case "deploy_manual":
    default:
  }

  return title;
}

function renderWorkflow(pathParams, activity) {
  return activity.progress.map((phase, phaseId) =>
    renderWorkflowPhase(pathParams, activity, phaseId)
  );
}

function renderWorkflowPhase(pathParams, activity, phaseId) {
  return (
    <Fragment key={phaseId}>
      <div className="pointers">
        <hr />
      </div>
      <ListGroup className="list">
        {activity.progress[phaseId].map((step, stepId) =>
          renderWorkflowPhaseStep(pathParams, activity, phaseId, stepId)
        )}
      </ListGroup>
    </Fragment>
  );
}

function renderWorkflowPhaseStep(pathParams, activity, phaseId, stepId) {
  return (
    <AppActivityWorkflowBuild
      key={`${phaseId}-${stepId}`}
      pathParams={pathParams}
      activity={activity}
      phaseId={phaseId}
      stepId={stepId}
    />
  );
}

export default ({ pathParams, activity }) => (
  <div className="AppActivityWorkflow">
    <ScrollShadowContainer>
      <div className="graphic">
        <div className="tag start">
          <hr />
          <span className="separator">&nbsp;</span>
          {activity.commit && (
            <a
              target="_blank"
              className="commit-link"
              rel="noopener noreferrer"
              href={activity.commit.url}
            >
              {shortHash(activity.commit.id)}
            </a>
          )}
          {!activity.commit && (
            <span className="copy">{buildAction(activity)}</span>
          )}
        </div>
        {renderWorkflow(pathParams, activity)}
        <div className="pointers">
          <hr />
        </div>
        <div className="tag end">
          <hr />
          <span className="separator">&nbsp;</span>
          <span className="copy">{statusToCopy(activity.status)}</span>
        </div>
      </div>
    </ScrollShadowContainer>
  </div>
);
