import React from "react";
import StyledControlLabel from "./StyledControlLabel";
import "./GraphTooltip.css";

export default function GraphTooltip({ time, values }) {
  return (
    <div className="GraphTooltip">
      <div className="footer">
        <p>{time}</p>
        <p>Click to view logs</p>
      </div>
      <hr />
      {values.map(([key, value, unit, color]) => (
        <p className="values" key={key}>
          <StyledControlLabel style={color ? { color } : null}>
            {key}
          </StyledControlLabel>
          <span className="value">{value}</span>
          {unit && <span className="unit">{unit}</span>}
        </p>
      ))}
    </div>
  );
}
