import React from "react";
import { Route } from "react-router-dom";
import RenderContainerComponent from "./RenderContainerComponent";

export default function AppRoute({ component: C, props: cProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <RenderContainerComponent component={C} {...props} {...cProps} />
      )}
    />
  );
}
