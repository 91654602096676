import React from "react";
import {
  dateToFullTimeNoYearWithUTCTimeZone,
  dateToFullTimeNoYearWithLocalTimeZone,
} from "../lib/timeLib";
import { beautify } from "../lib/jsonLib";
import "./RequestLogLine.css";

export default function RequestLogLine({ log = null, isUTC = false }) {
  if (log === null) {
    return (
      <div className="RequestLogLine empty">
        <p>&nbsp;</p>
        <pre>...</pre>
      </div>
    );
  }

  const utcTime = dateToFullTimeNoYearWithUTCTimeZone(log.t);
  const localTime = dateToFullTimeNoYearWithLocalTimeZone(log.t);

  let extraLine;

  let message = beautify(log.m);

  let mParts = log.m.split("\t");
  let mPartial = mParts[mParts.length - 1];
  let mPartialJSON = beautify(mPartial);

  let firstParts = mParts.slice(0, mParts.length - 1).join("\t");

  // If the last part is JSON
  if (mPartial !== mPartialJSON) {
    message = firstParts;
    extraLine = mPartialJSON;
  }
  // If the last part has newline characters
  else if (mPartial.includes("\n") || mPartial.includes("\r")) {
    message = firstParts;
    extraLine = mPartial.replace(/\r/g, "\n");
  }

  return (
    <div className="RequestLogLine">
      <p title={isUTC ? localTime : utcTime}>{isUTC ? utcTime : localTime}</p>
      <div className="message">
        {message !== "" && <pre>{message}</pre>}
        {extraLine && <pre className="formatted">{extraLine}</pre>}
      </div>
    </div>
  );
}
