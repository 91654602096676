import React from "react";
import config from "../config";
import "./ErrorComponent.css";

export default (props) => (
  <div className="ErrorComponent">
    <h2>Sorry, there was an error!</h2>
    <p>You can refresh this page and try again.</p>
    <p>
      Or <a href={`mailto:${config.contactEmail}`}>contact us</a> if it happens
      again.
    </p>
  </div>
);
