import React, { Component } from "react";
import StageNotificationsPanel from "../../components/StageNotificationsPanel";
import { errorHandler } from "../../lib/errorLib";

const noop = () => {};

const defaultProps = {
  onAdd: noop,
  onLoad: noop,
  onRemove: noop,
};

const defaultState = {
  notifications: false,

  // Flag to trigger clearing the form
  clearForm: 0,

  adding: false,
  loading: false,
  removing: {},
};

class StageNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = { ...defaultState };
  }

  addNotificationToList(notification) {
    this.setState((prevState) => {
      const notifications = prevState.notifications;
      notifications.push(notification);

      return { notifications };
    });
  }

  removeNotificationFromList(id) {
    this.setState((prevState) => {
      const notifications = prevState.notifications.filter(
        (notification) => notification.id !== id
      );

      return { notifications };
    });
  }

  disableRemoveLoading(id) {
    this.setState((prevState) => {
      const removing = prevState.removing;
      delete removing[id];

      return { removing };
    });
  }

  handleShowClick = async (event) => {
    this.setState({ loading: true });

    try {
      const { notifications } = await this.props.onLoad();

      this.setState({
        loading: false,
        notifications,
      });
    } catch (e) {
      this.setState({ loading: false });
      errorHandler(e);
    }
  };

  handleAddClick = async (e, notification) => {
    this.setState({ adding: true });

    try {
      const result = await this.props.onAdd(notification);

      this.setState((prevState) => ({
        adding: false,
        clearForm: prevState.clearForm + 1,
      }));
      this.addNotificationToList(result.notification);
    } catch (e) {
      this.setState({ adding: false });
      errorHandler(e);
    }
  };

  handleRemoveClick = async (event, id) => {
    this.setState((prevState) => ({
      removing: { ...prevState.removing, [id]: true },
    }));

    try {
      await this.props.onRemove(id);

      this.disableRemoveLoading(id);
      this.removeNotificationFromList(id);
    } catch (e) {
      this.disableRemoveLoading(id);
      errorHandler(e);
    }
  };

  handleHideClick = (event) => {
    this.setState({ notifications: false });
  };

  render() {
    const { adding, loading, removing, clearForm, notifications } = this.state;

    return (
      <StageNotificationsPanel
        adding={adding}
        loading={loading}
        removing={removing}
        clearForm={clearForm}
        notifications={notifications}
        onAddClick={this.handleAddClick}
        onHideClick={this.handleHideClick}
        onShowClick={this.handleShowClick}
        onRemoveClick={this.handleRemoveClick}
      />
    );
  }
}

StageNotifications.defaultProps = defaultProps;

export default StageNotifications;
