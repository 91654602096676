import React from "react";
import "./SectionGroupPanel.css";

export default ({ title, className = "", important = false, ...props }) => {
  const importantCs = important ? "important" : "";

  return (
    <div className={`SectionGroupPanel ${className} ${importantCs}`}>
      {title && <h4>{title}</h4>}
      <div className="body">{props.children}</div>
    </div>
  );
};
