import React from "react";
import { Link } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import "./ReportFilterSelect.css";

export default function ReportFilterSelect({
  stages,
  selectedStage,
  buildReportUrl,
}) {
  return (
    <div className="ReportFilterSelect">
      <SectionHeader>Stages</SectionHeader>
      {stages.map((stage) => (
        <Link key={stage} to={buildReportUrl(stage)}>
          <LoaderButton
            bsSize="small"
            bsStyle="block"
            className="option"
            active={stage === selectedStage}
          >
            {stage}
          </LoaderButton>
        </Link>
      ))}
    </div>
  );
}
