import React, { Fragment } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import TextButton from "./TextButton";
import SectionInfo from "./SectionInfo";
import LoaderButton from "./LoaderButton";
import { useFormReducer } from "../lib/hooksLib";
import "./DeleteUserPanel.css";

function DeleteUserForm({
  username,
  deleting,
  deleteChecks,
  onHideClick,
  onDeleteClick,
}) {
  const [{ values: formData }, , handleFieldChange] = useFormReducer({
    confirmText: "",
  });

  const canDelete =
    deleteChecks.apps.length === 0 && deleteChecks.orgs.length === 0;

  function canSubmit() {
    return formData.confirmText === username;
  }

  function handleDeleteConfirmClick(event) {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    onDeleteClick();
  }

  function renderList(items) {
    if (items.length === 1) {
      return <b>{items[0]}</b>;
    } else if (items.length === 2) {
      return (
        <>
          <b>{items[0]}</b> and <b>{items[1]}</b>
        </>
      );
    } else {
      const s = [];
      for (let i = 0, l = items.length; i < l; i++) {
        const item = items[i];
        if (l - i > 1) {
          s.push(
            <Fragment key={item}>
              <b>{item}</b>,{" "}
            </Fragment>
          );
        } else if (l - i === 1) {
          s.push(
            <Fragment key={item}>
              and <b>{item}</b>
            </Fragment>
          );
        }
      }
      return s;
    }
  }

  function renderChecks() {
    const { orgs, apps } = deleteChecks;

    const hasOrgs = orgs.length > 0;
    const hasApps = apps.length > 0;

    const onlyAppsCopy = hasOrgs ? "And delete" : "Delete";

    const orgCopy = orgs.length === 1 ? "organization" : "organizations";
    const appsCopy = apps.length === 1 ? "app" : "apps";

    return (
      <div className="checks">
        <p>Before deleting your account, you&apos;ll need to:</p>
        <ul>
          {hasOrgs > 0 && (
            <li>
              Leave the {renderList(orgs)} {orgCopy}
            </li>
          )}
          {hasApps > 0 && (
            <li>
              {onlyAppsCopy} the {renderList(apps)} {appsCopy}
            </li>
          )}
        </ul>
      </div>
    );
  }

  return (
    <div className="DeleteUserForm">
      {!canDelete && renderChecks()}
      {canDelete && (
        <p className="helperCopy">
          To delete your account, enter <b>{username}</b> to confirm.
        </p>
      )}
      <Form onSubmit={handleDeleteConfirmClick}>
        {canDelete && (
          <FormGroup bsSize="large" controlId="confirmText">
            <FormControl
              type="text"
              value={formData.confirmText}
              onChange={handleFieldChange}
              disabled={deleting || !canDelete}
            />
          </FormGroup>
        )}
        <div className="controls">
          <LoaderButton
            bsSize="large"
            bsStyle="danger"
            loading={deleting}
            className="btnConfirm"
            onClick={handleDeleteConfirmClick}
            disabled={!canDelete || !canSubmit()}
          >
            Confirm Delete
          </LoaderButton>
          <TextButton onClick={onHideClick}>Cancel</TextButton>
        </div>
      </Form>
    </div>
  );
}

export default function DeleteUserPanel({
  show = false,
  username = "",
  loading = false,
  deleting = false,
  disabled = false,
  deleteChecks = null,

  onShowClick,
  onHideClick,
  onDeleteClick,
}) {
  return (
    <div className="DeleteUserPanel">
      <SectionInfo
        label="Delete Account"
        button={
          !show && (
            <LoaderButton
              bsSize="large"
              bsStyle="danger"
              loading={loading}
              disabled={disabled}
              className="btnDelete"
              onClick={onShowClick}
            >
              Delete Your Account
            </LoaderButton>
          )
        }
      >
        {show && (
          <DeleteUserForm
            deleting={deleting}
            username={username}
            onHideClick={onHideClick}
            deleteChecks={deleteChecks}
            onDeleteClick={onDeleteClick}
          />
        )}
      </SectionInfo>
    </div>
  );
}
