import React, { Fragment, useState } from "react";
import Ansi from "ansi-to-react";
import FaIcon from "./FaIcon";
import "./CollapsableLogEntry.css";

export default function CollapsableLogEntry({
  entry,
  secondary = false,
  ...props
}) {
  // Expand folds with error by default
  const defaultIsExpanded = entry.type === "fold" && entry.error;
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);

  const secondaryCs = secondary ? "secondary" : "";

  function getDuration(fold) {
    // Do not render duration if either start or end do not exist
    // ie. fold without timestamp
    if (!fold.start || !fold.end) {
      return undefined;
    }

    const duration = fold.end - fold.start;

    // Do not render duration for Seed INFO folds with less than 10ms
    if (fold.lines[0].message.startsWith("INFO: ") && duration < 10) {
      return undefined;
    }

    return `${(duration / 1000).toFixed(2)} s`;
  }

  function renderPlainMessage(line, duration) {
    const className = line.error
      ? "error"
      : line.message.startsWith("$ ")
      ? "command"
      : "info";

    return (
      <div className="log empty">
        <div className="pointer"></div>
        <pre className={className}>
          <Ansi useClasses>{line.message}</Ansi>
        </pre>
        {duration && <div className="duration">{duration}</div>}
      </div>
    );
  }

  function renderCollapsableMessage(line, duration) {
    return (
      <div
        className="log"
        onClick={(e) => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div className={`pointer ${isExpanded ? "expanded" : ""}`}>
          {isExpanded && <FaIcon name="caret-down" />}
          {!isExpanded && <FaIcon name="caret-right" />}
        </div>
        <pre>{line.message}</pre>
        {duration && <div className="duration">{duration}</div>}
      </div>
    );
  }

  function renderFold(fold) {
    if (fold.lines.length > 1) {
      return fold.lines.map((line, i) => (
        <Fragment key={i}>
          {i === 0 && renderCollapsableMessage(line, getDuration(fold))}
          {isExpanded && i > 0 && renderPlainMessage(line)}
        </Fragment>
      ));
    } else {
      return renderPlainMessage(fold.lines[0], getDuration(fold));
    }
  }

  return (
    <div className={`CollapsableLogEntry ${secondaryCs}`} {...props}>
      {entry.type === "line" && renderPlainMessage(entry)}
      {entry.type === "fold" && renderFold(entry)}
    </div>
  );
}
