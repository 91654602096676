import React, { useEffect, useState } from "react";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import ErrorAlert from "../components/ErrorAlert";
import ScreenHeader from "../components/ScreenHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import AppActivityListWidget from "./widgets/AppActivityListWidget";
import ActivityFilterSelect from "../components/ActivityFilterSelect";
import { buildQueryStringUrl, getAppActivityUrl } from "../lib/urlLib";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import { appActivityBreadcrumb } from "../lib/breadcrumbLib";
import { errorHandler, getLoadError } from "../lib/errorLib";
import "./AppRecentActivity.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
};

function AppRecentActivity(props) {
  let isLoading = true;
  let loadError = null;

  const { ownerId, appId, appStageId, serviceId } = props.match.params;

  const [rollbackProcessingActivityIds, setRollbackActivityIds] = useState([]);

  const titleCopy = appStageId
    ? appStageId
    : serviceId
    ? serviceId
    : "Activity";

  //////////
  // Load //
  //////////

  useEffect(() => {
    document.title = title(titleCopy);
  }, [titleCopy]);

  const {
    data: [appInfo, activitiesInfo],
    error,
    reload: reloadActivities,
  } = useAPILoad(
    [getAppAPI(), getAppActivitiesAPI()],
    [
      (path) => props.invokeAppsApig({ path }),
      (path) => props.invokeApig({ path }),
    ]
  );

  if (appInfo !== null && activitiesInfo !== null) {
    isLoading = false;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  const loaded = !isLoading && !loadError && appInfo && activitiesInfo;

  let activeActivityIds = [];
  if (loaded) {
    activeActivityIds = appInfo.stages
      .map((stage) => stage.latestBuild && stage.latestBuild.activity_id)
      .filter((activityId) => !!activityId);
  }

  /////////
  // API //
  /////////

  function getAppAPI() {
    return `/${ownerId}/${appId}`;
  }

  function getAppActivitiesAPI(nextToken) {
    const apiParams = {};
    if (appStageId) {
      apiParams.stageName = appStageId;
    } else if (serviceId) {
      apiParams.serviceName = serviceId;
    }

    if (nextToken) {
      apiParams.next_token = nextToken;
    }

    return buildQueryStringUrl(`${getAppAPI()}/activities`, apiParams);
  }

  function getAppActivities(nextToken) {
    return props.invokeApig({
      path: getAppActivitiesAPI(nextToken),
    });
  }

  function rollback(activity) {
    const stageId = activity.stage_id;
    const buildId = activity.build_id;
    return props.invokeApig({
      path: `${getAppAPI()}/stages/${stageId}/builds/${buildId}/rollback`,
      method: "POST",
    });
  }

  //////////////
  // Handlers //
  //////////////

  function handleFilterSelect(type, name) {
    const params = {};
    if (type === "stage") {
      params.stage = name;
    } else if (type === "service") {
      params.service = name;
    }
    const url = getAppActivityUrl(ownerId, appId, params);
    props.history.push(url);
  }

  function handleLoadNextPage(nextToken) {
    return getAppActivities(nextToken);
  }

  async function handleRollbackClick(activity) {
    const activityId = activity.activity_id;
    const buildId = activity.build_id;
    const stageId = activity.stage_id;

    const confirm = window.confirm(
      `Are you sure you want to rollback ${stageId} to Build v${buildId}?`
    );

    if (!confirm) {
      return;
    }

    setRollbackActivityIds([...rollbackProcessingActivityIds, activityId]);

    try {
      await rollback(activity);
      await reloadActivities();
    } catch (e) {
      errorHandler(e);
    }
    setRollbackActivityIds(
      rollbackProcessingActivityIds.filter((per) => per !== activityId)
    );
  }

  return (
    <div className="AppRecentActivity">
      {isLoading && <LoadingSpinner />}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="app"
          code={loadError}
          context={{
            name: appId,
          }}
        />
      )}
      {error && !loadError && <ErrorAlert error={error} />}

      {loaded && (
        <>
          <ScreenHeader
            breadcrumb={
              (appStageId || serviceId) && appActivityBreadcrumb(props)
            }
            action={
              <ActivityFilterSelect
                stages={appInfo.stages}
                onSelect={handleFilterSelect}
                pathParams={props.match.params}
                filter={appStageId || serviceId}
                services={appInfo.services.map(({ service }) => service)}
              />
            }
          >
            {appStageId ? appStageId : serviceId ? serviceId : "Activity"}
          </ScreenHeader>
          <AppActivityListWidget
            key={props.location.pathname}
            pathParams={props.match.params}
            activitiesInfo={activitiesInfo}
            activeActivityIds={activeActivityIds}
            rollbackProcessingActivityIds={rollbackProcessingActivityIds}
            onRollback={handleRollbackClick}
            onLoadNextPage={handleLoadNextPage}
          />
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(AppRecentActivity));
