import React from "react";
import { Link } from "react-router-dom";
import { Glyphicon } from "react-bootstrap";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import "./PipelineTableStageCell.css";

// Note: pass 'status' in explicitly b/c this component is used to render:
// - an AppStage in pipelines => in this case, it needs to render AppStage's status
// - a Stage in remove service table => in this case, it needs to render Stage's status
export default ({
  status,
  appStage,
  appStageLink,
  onManualDeploy,
  onCancelDeploy,
  activityStatus,
  appStageRemoveLink,
  cancelling = false,
  hideAction = false,
  settingsIcon = false,
}) => {
  const branch = appStage.branch;
  const branchUrl = appStage.branch_url;
  const pr = appStage.pull_request;
  const prUrl = appStage.pull_request_url;
  const isDeleting = status === "deleting";
  const isDeleteFailed = status === "delete_failed";
  const className = `PipelineTableStageCell ${status}`;

  const hasBranch = branch || pr;

  return (
    <div className={className}>
      {!isDeleting && !isDeleteFailed && (
        <>
          <div className="title">
            <div className="name">
              <Link to={appStageLink} title={appStage.name}>
                {settingsIcon && <FaIcon name="cog" />}
                {appStage.name}
              </Link>
            </div>
            <div className="desc">
              {hasBranch && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={branch ? branchUrl : prUrl}
                  title={branch ? branch : `pr#${pr}`}
                >
                  <FaIcon name="code-fork" />
                  {branch ? branch : `pr#${pr}`}
                </a>
              )}
              {!hasBranch && <span>Auto-deploy off</span>}
            </div>
          </div>
          {!hideAction && (
            <>
              {activityStatus === "deploying" || activityStatus === "queued" ? (
                <LoaderButton
                  bsSize="xsmall"
                  bsStyle="danger"
                  className="btnCancel"
                  loading={cancelling}
                  onClick={onCancelDeploy}
                >
                  Cancel
                </LoaderButton>
              ) : (
                <LoaderButton
                  bsSize="xsmall"
                  bsStyle="block"
                  className="btnDeploy"
                  onClick={onManualDeploy}
                >
                  Deploy
                </LoaderButton>
              )}
            </>
          )}
        </>
      )}
      {isDeleting && (
        <>
          <div className="title">
            <div className="name">
              <Link to={appStageRemoveLink} title={appStage.name}>
                <Glyphicon className="icon" glyph="refresh" />
                {appStage.name}
              </Link>
            </div>
            <div className="desc">
              <span>Removing&hellip;</span>
            </div>
          </div>
        </>
      )}
      {isDeleteFailed && (
        <>
          <div className="title">
            <div className="name">
              <Link
                title={appStage.name}
                className="btn-red-link"
                to={appStageRemoveLink}
              >
                <FaIcon name="exclamation-triangle" />
                {appStage.name}
              </Link>
            </div>
            <div className="desc">
              <span>Remove failed</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
