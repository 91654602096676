import React from "react";
import { Link } from "react-router-dom";
import { Glyphicon } from "react-bootstrap";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import ServiceIcon from "./ServiceIcon";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import ResourcesPanel, { TabEnum } from "./ResourcesPanel";
import { getServiceUrl } from "../lib/urlLib";
import { formatStackNames } from "../lib/stringLib";
import "./ServiceResourceList.css";

export default function ServiceResourceList({
  services,
  pathParams,
  onTabClick,
  resourcesState,
  onShowResourcesClick,
  onHideResourcesClick,
  onUpdateAccessLogClick,
}) {
  const { ownerId, appId } = pathParams;

  function renderCard({
    serviceName,
    serviceType,
    serviceStatus,
    activeDeployment,
    region,
    stackName,
    stackNames,
    apiEndpoint,
    slsComponent,
  }) {
    const resourceState = resourcesState && resourcesState[serviceName];
    const showResource = resourceState && resourceState.info !== null;
    const serviceUrl = getServiceUrl(ownerId, appId, serviceName);

    const isDeleting = serviceStatus === "deleting";
    const isDeleteFailed = serviceStatus === "delete_failed";

    const cannotShowResources =
      !activeDeployment || isDeleting || isDeleteFailed;

    return (
      <div key={serviceName} className={`resource ${serviceStatus}`}>
        <div className="card">
          <div className="col1">
            <Link to={serviceUrl}>
              <ServiceIcon size="large" type={serviceType} />
            </Link>
            {!isDeleting && !isDeleteFailed && (
              <div>
                <Link to={serviceUrl}>{serviceName}</Link>
                {activeDeployment && serviceType === "sls" && (
                  <p>{stackName}</p>
                )}
                {activeDeployment && serviceType === "sst/resources" && (
                  <p>{formatStackNames(stackNames)}</p>
                )}
                {activeDeployment && serviceType === "component" && (
                  <p>{slsComponent}</p>
                )}
                {!activeDeployment && <p className="disabled">Not deployed</p>}
              </div>
            )}
            {isDeleting && (
              <div>
                <Link to={serviceUrl}>
                  <Glyphicon glyph="refresh" />
                  {serviceName}
                </Link>
                <p className="disabled">Removing&hellip;</p>
              </div>
            )}
            {isDeleteFailed && (
              <div>
                <Link className="btn-red-link" to={serviceUrl}>
                  <FaIcon name="exclamation-triangle" />
                  {serviceName}
                </Link>
                <p className="disabled">Failed to remove</p>
              </div>
            )}
          </div>
          {cannotShowResources && (
            <div className="col2">
              <LoaderButton disabled bsSize="small" bsStyle="block">
                View Resources
              </LoaderButton>
            </div>
          )}
          {!cannotShowResources && !showResource && (
            <div className="col2">
              <div className="fields">
                <SectionHeader>{region}</SectionHeader>
              </div>
              <LoaderButton
                bsSize="small"
                bsStyle="block"
                loading={resourceState && resourceState.info === null}
                onClick={() =>
                  onShowResourcesClick(
                    serviceName,
                    serviceType === "component"
                      ? TabEnum.outputs
                      : TabEnum.lambdas
                  )
                }
              >
                View Resources
              </LoaderButton>
            </div>
          )}
          {!cannotShowResources && showResource && (
            <div className="col2">
              <TextButton onClick={() => onHideResourcesClick(serviceName)}>
                Hide Resources
              </TextButton>
            </div>
          )}
        </div>
        {showResource && (
          <ResourcesPanel
            serviceId={serviceName}
            serviceType={serviceType}
            pathParams={pathParams}
            onTabClick={onTabClick}
            activeTab={resourceState.tab}
            resources={resourceState.info}
            updatingLogs={resourceState.updatingLogs}
            onUpdateAccessLogClick={onUpdateAccessLogClick}
          />
        )}
      </div>
    );
  }

  return <div className="ServiceResourceList">{services.map(renderCard)}</div>;
}
