import React, { useRef, useEffect } from "react";
import {
  invokeApig,
  invokeV2Apig,
  invokeAppsApig,
  invokeStagesApig,
  invokePublicApig,
} from "../lib/awsLib";
import { makeCancelable, onFinally } from "../lib/promiseLib";

const wrapFn = (fn, wrapperFn) => (...args) => wrapperFn(fn(...args));
export default function withCancel(Comp) {
  return function CancelableComponent(props) {
    const register = useRef({});
    const registerId = useRef(0);

    useEffect(() => {
      // Run on unmount
      return () => {
        for (let id in register.current) {
          register.current[id].cancel();
        }
      };
    }, []);

    function registerFetch(promise) {
      const id = registerId.current++;
      const cancelablePromise = makeCancelable(promise);

      register.current = {
        ...register.current,
        [id]: cancelablePromise,
      };

      return onFinally(cancelablePromise.promise, () => {
        delete register.current[id];
        register.current = {
          ...register.current,
        };
      });
    }

    const _invokeApig = wrapFn(invokeApig, registerFetch);
    const _invokeV2Apig = wrapFn(invokeV2Apig, registerFetch);
    const _invokeAppsApig = wrapFn(invokeAppsApig, registerFetch);
    const _invokePublicApig = wrapFn(invokePublicApig, registerFetch);
    const _invokeStagesApig = wrapFn(invokeStagesApig, registerFetch);

    return (
      <Comp
        invokeApig={_invokeApig}
        invokeV2Apig={_invokeV2Apig}
        invokeAppsApig={_invokeAppsApig}
        invokePublicApig={_invokePublicApig}
        invokeStagesApig={_invokeStagesApig}
        {...props}
      />
    );
  };
}
