import React, { useEffect } from "react";
import TextButton from "./TextButton";
import SectionHeader from "./SectionHeader";
import GraphPanel, { keyCopyMap, keyColorMap } from "./GraphPanel";
import { usePrevious, useFormReducer } from "../lib/hooksLib";
import "./MetricsPanel.css";

const nameMap = {
  count: "Request Count",
  error: "Error Count",
  latency: "Latency",
  invocations: "Invocation Count",
  duration: "Duration",
};

const dataKeysMap = {
  duration: ["vP99", "vP95", "vP90", "vAvg"],
  latency: ["vP99", "vP95", "vP90", "vAvg"],
};

function getDefaultLegendState(metrics) {
  const keys = metrics.duration
    ? dataKeysMap.duration
    : metrics.latency
    ? dataKeysMap.latency
    : null;

  if (keys === null) {
    return {};
  }

  const defaultState = {};

  keys.forEach((key) => (defaultState[key] = true));

  return defaultState;
}

export default function MetricsPanel({
  metrics = {},
  onPointClick,
  loading = false,
}) {
  const previousLoading = usePrevious(loading);
  const [{ values: formData }, formDispatch] = useFormReducer({});

  useEffect(() => {
    if (!loading && loading !== previousLoading) {
      formDispatch({
        type: "reset",
        fields: getDefaultLegendState(metrics),
      });
    }
  }, [loading, metrics, formDispatch, previousLoading]);

  function canDisableKey() {
    let enabledCount = 0;

    for (let key in formData) {
      if (formData[key]) {
        enabledCount++;
      }
    }

    return enabledCount > 1;
  }

  function handleLegendClick(key, value) {
    if (value === false && !canDisableKey()) {
      return;
    }

    formDispatch({
      id: key,
      type: "edit",
      value: value,
    });
  }

  function getDataKeys(metric) {
    return dataKeysMap[metric]
      ? dataKeysMap[metric].filter((key) => formData[key])
      : ["v"];
  }

  function renderLegendKey(key) {
    const selected = formData[key];

    return (
      <TextButton
        key={key}
        className={selected ? "" : "deselected"}
        onClick={() => handleLegendClick(key, !selected)}
      >
        <span
          style={{
            borderColor: keyColorMap[key],
            backgroundColor: keyColorMap[key],
          }}
        ></span>
        {keyCopyMap[key]}
      </TextButton>
    );
  }

  function renderMetric(metric) {
    return (
      <>
        <div className="title">
          <SectionHeader>{nameMap[metric]}</SectionHeader>
          {!loading && dataKeysMap[metric] && (
            <div className="legend">
              {dataKeysMap[metric].map(renderLegendKey)}
            </div>
          )}
        </div>
        {loading && <GraphPanel loading />}
        {!loading && (
          <GraphPanel
            name={metric}
            data={metrics[metric].points}
            dataKeys={getDataKeys(metric)}
            onPointClick={(i) => onPointClick(i, metric)}
          />
        )}
      </>
    );
  }

  return (
    <div className="MetricsPanel">
      <div className="metrics">
        {Object.keys(metrics).map((key) => (
          <div key={key} className="metric">
            {renderMetric(key)}
          </div>
        ))}
      </div>
    </div>
  );
}
