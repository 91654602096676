import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import TextButton from "./TextButton";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import StyledControlLabel from "./StyledControlLabel";
import { testEmail } from "../lib/regexLib";
import { useFormReducer } from "../lib/hooksLib";
import "./LoginForm.css";

export default function LoginForm({
  onLoginClick,
  onCancelClick,
  onSsoLoginClick,
  isLoggingIn = false,
}) {
  const [isSsoMode, setSsoMode] = useState(false);

  const [
    { values: formData, validation },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ email: "", password: "" });

  function canSubmit() {
    if (formData.email.trim() === "") {
      return false;
    }

    if (!isSsoMode && formData.password.trim() === "") {
      return false;
    }

    return true;
  }

  function handleLoginClick(event) {
    event.preventDefault();

    let { email, password } = formData;

    if (!canSubmit()) {
      return;
    }

    email = email.trim();

    if (!testEmail(email)) {
      formDispatch({
        id: "email",
        type: "validate",
      });
      return;
    }

    isSsoMode ? onSsoLoginClick(email) : onLoginClick(email, password);
  }

  function handleCancelClick() {
    formDispatch({ type: "reset" });
    setSsoMode(false);
  }

  function handleSsoClick() {
    formDispatch({ type: "reset" });
    setSsoMode(true);
  }

  return (
    <div className="LoginForm">
      <form onSubmit={handleLoginClick}>
        <FormGroup
          controlId="email"
          bsSize="large"
          validationState={validation.email}
        >
          <StyledControlLabel>
            {isSsoMode ? "SSO Email" : "Email"}
          </StyledControlLabel>
          <FormControl
            autoFocus
            type="email"
            autoComplete="email"
            value={formData.email}
            placeholder={isSsoMode ? "Your SSO email" : "Email address"}
            onChange={handleFieldChange}
          />
          {validation.email && (
            <HelpBlock>Please enter a valid email address.</HelpBlock>
          )}
        </FormGroup>
        {!isSsoMode && (
          <FormGroup controlId="password" bsSize="large">
            <StyledControlLabel>Password</StyledControlLabel>
            <FormControl
              type="password"
              placeholder="Password"
              value={formData.password}
              autoComplete="current-password"
              onChange={handleFieldChange}
            />
          </FormGroup>
        )}
        <div className="controls">
          <LoaderButton
            type="submit"
            bsSize="large"
            bsStyle="warning"
            loading={isLoggingIn}
            disabled={!canSubmit()}
            onClick={handleLoginClick}
          >
            {isSsoMode ? "Submit" : "Log in"}
          </LoaderButton>
          {!isSsoMode && (
            <Link to="/login/reset">Don&apos;t remember your password?</Link>
          )}
          {isSsoMode && (
            <TextButton disabled={isLoggingIn} onClick={handleCancelClick}>
              Cancel
            </TextButton>
          )}
        </div>
      </form>
      {!isSsoMode && (
        <div className="show-sso">
          <SectionHeader>Single sign-on</SectionHeader>
          <LoaderButton bsSize="large" bsStyle="block" onClick={handleSsoClick}>
            Login with SSO
          </LoaderButton>
        </div>
      )}
    </div>
  );
}
