import React, { Component } from "react";
import OrgSSHPanel from "../../components/OrgSSHPanel";
import { errorHandler } from "../../lib/errorLib";

const noop = () => {};

const defaultProps = {
  sshData: undefined,

  onSave: noop,
};

const defaultState = {
  editing: false,
  saving: false,
  removing: false,
};

class OrgSSH extends Component {
  constructor(props) {
    super(props);

    this.state = { ...defaultState };
  }

  handleEditClick = (event) => {
    this.setState({ editing: true });
  };

  handleSaveClick = async (event, ssh_key) => {
    this.setState({ saving: true });

    try {
      await this.props.onSave(event, {
        ssh_key,
      });

      this.setState((prevState) => ({
        editing: false,
        saving: false,
      }));
    } catch (e) {
      this.setState({ saving: false });
      errorHandler(e);
    }
  };

  handleRemoveClick = async (event) => {
    this.setState({ removing: true });

    try {
      await this.props.onSave(event, {
        ssh_key: null,
      });

      this.setState((prevState) => ({
        removing: false,
      }));
    } catch (e) {
      this.setState({ removing: false });
      errorHandler(e);
    }
  };

  handleHideClick = (event) => {
    this.setState({ editing: false });
  };

  render() {
    const { editing, saving, removing } = this.state;
    const { sshData } = this.props;

    return (
      <OrgSSHPanel
        saving={saving}
        sshData={sshData}
        editing={editing}
        removing={removing}
        onEditClick={this.handleEditClick}
        onHideClick={this.handleHideClick}
        onSaveClick={this.handleSaveClick}
        onRemoveClick={this.handleRemoveClick}
      />
    );
  }
}

OrgSSH.defaultProps = defaultProps;

export default OrgSSH;
