import React, { useEffect, useReducer } from "react";
import { Redirect } from "react-router-dom";
import withAppHeader from "../components/ComponentWithAppHeader";
import withCancel from "../components/ComponentWithCancel";
import ManualDeploy from "./widgets/ManualDeploy";
import ErrorAlert from "../components/ErrorAlert";
import AppAddService from "./widgets/AppAddService";
import ConfirmPromote from "./widgets/ConfirmPromote";
import LoadingSpinner from "../components/LoadingSpinner";
import AppPipelineTable from "../components/AppPipelineTable";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import AppPipelineInfoPanel from "../components/AppPipelineInfoPanel";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import { getAppRemoveUrl } from "../lib/urlLib";
import { errorHandler, getLoadError } from "../lib/errorLib";
import "./AppPipeline.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
};

const defaultPromoteState = {
  show: false,
  tarType: null,
  serviceName: null,
  serviceType: null,
  tarAppStageId: null,
  srcAppStageId: null,
};

const defaultManualDeployState = {
  key: 0,
  show: false,
  stageName: null,
  serviceName: null,
  serviceType: null,
  defaultBranch: null,
  lastDeployedRefs: [],
};

function cancelBuildReducer(state, action) {
  switch (action.type) {
    case "cancelling":
      return {
        ...state,
        [action.stage]: true,
      };
    case "cancelled":
      return {
        ...state,
        [action.stage]: false,
      };
    default:
      return state;
  }
}

function modalReducer(state, action) {
  switch (action.type) {
    case "show":
      return {
        ...state,
        show: true,
        key: state.key + 1,
      };
    case "hide":
      return {
        ...state,
        show: false,
        key: state.key,
      };
    default:
      return state;
  }
}

function manualDeployReducer(state, action) {
  switch (action.type) {
    case "show":
      return {
        ...defaultManualDeployState,
        show: true,
        key: state.key + 1,
        stageName: action.stageName,
        serviceName: action.serviceName,
        serviceType: action.serviceType,
        defaultBranch: action.defaultBranch,
        lastDeployedRefs: action.lastDeployedRefs,
      };
    case "hide":
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}

function promoteReducer(state, action) {
  switch (action.type) {
    case "show":
      return {
        ...state,
        show: true,
        tarType: action.tarType,
        serviceName: action.serviceName,
        serviceType: action.serviceType,
        srcAppStageId: action.srcAppStageId,
        tarAppStageId: action.tarAppStageId,
      };
    case "hide":
      return { ...defaultPromoteState };
    default:
      return state;
  }
}

function AppPipeline(props) {
  let isLoading = true;
  let loadError = null;
  let isRemovingOrRemoveFailed = false;

  const { ownerId, appId } = props.match.params;

  const [addServiceState, addServiceDispatch] = useReducer(modalReducer, {
    key: 0,
    show: false,
  });
  const [promoteState, promoteDispatch] = useReducer(
    promoteReducer,
    defaultPromoteState
  );
  const [manualDeployState, manualDeployDispatch] = useReducer(
    manualDeployReducer,
    defaultManualDeployState
  );
  const [cancellingBuilds, cancelBuildDispatch] = useReducer(
    cancelBuildReducer,
    {}
  );

  //////////
  // Load //
  //////////

  useEffect(() => {
    document.title = title("Pipeline");
  }, []);

  const {
    data: appInfo,
    error,
    reload: reloadAppInfo,
  } = useAPILoad(`/${ownerId}/${appId}`, (path) =>
    props.invokeAppsApig({ path, queryStringParameters: { version: "v2" } })
  );

  if (appInfo) {
    isLoading = false;

    // Case 1: deleting or delete_failed => stop polling
    if (
      appInfo.app.status === "deleting" ||
      appInfo.app.status === "delete_failed"
    ) {
      isRemovingOrRemoveFailed = true;
    }
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  const loaded = !isLoading && !loadError && appInfo;

  /////////
  // API //
  /////////

  function getAppAPI() {
    return `/${ownerId}/${appId}`;
  }

  function getAppStageAPI(stageName) {
    return `/${ownerId}/${appId}/stages/${stageName}`;
  }

  function getAppStageServiecAPI(stageId, serviceId) {
    return `/${ownerId}/${appId}/stages/${stageId}/services/${serviceId}`;
  }

  function getPromoteAPI() {
    const { tarAppStageId, serviceName } = promoteState;

    return serviceName
      ? getAppStageServiecAPI(tarAppStageId, serviceName)
      : getAppStageAPI(tarAppStageId);
  }

  function getAppStageBuildAPI(stageId, buildId) {
    return `/${ownerId}/${appId}/stages/${stageId}/builds/${buildId}`;
  }

  function cancelBuild(stageId, buildId) {
    return props.invokeApig({
      path: `${getAppStageBuildAPI(stageId, buildId)}/cancel_build`,
      method: "POST",
    });
  }

  //////////////////
  // API: Promote //
  //////////////////

  function promote() {
    return props.invokeApig({
      path: `${getPromoteAPI()}/promote?version=v20200425`,
      method: "POST",
      body: {
        sourceStageName: promoteState.srcAppStageId,
      },
    });
  }

  function getChangeset() {
    return props.invokeApig({
      path: `${getPromoteAPI()}/promote_changeset?version=v20200425`,
      queryStringParameters: {
        sourceStageName: promoteState.srcAppStageId,
      },
    });
  }

  function reportChangeset(changeset) {
    return props.invokeApig({
      path: `${getAppStageAPI(promoteState.srcAppStageId)}/report_changeset`,
      method: "POST",
      body: changeset,
    });
  }

  //////////////////////
  // API: Add Service //
  //////////////////////

  function getSlsInfo(path) {
    return props.invokeAppsApig({
      path: `${getAppAPI()}/git_service_info`,
      queryStringParameters: {
        path,
      },
    });
  }

  function addService(name, path, serviceFramework) {
    return props.invokeApig({
      path: `${getAppAPI()}/services?version=v20200823`,
      method: "POST",
      body: { name, path, serviceFramework },
    });
  }

  ////////////////////////
  // API: Manual Deploy //
  ////////////////////////

  function deployStage({ appStageId, serviceId }, branch, force_deploy) {
    return props.invokeApig({
      path: serviceId
        ? `${getAppStageServiecAPI(appStageId, serviceId)}/deploy`
        : `${getAppStageAPI(appStageId)}/deploy`,
      method: "POST",
      body: { branch, force_deploy },
    });
  }

  ////////////////////////
  // Handlers - Promote //
  ////////////////////////

  async function handlePromote(appStage, downstreamStage, service) {
    promoteDispatch({
      type: "show",
      tarType: downstreamStage.is_production ? "Production" : "Staging",
      srcAppStageId: appStage.id,
      tarAppStageId: downstreamStage.id,
      serviceName: service && service.name,
      serviceType: service && service.serviceType,
    });
  }

  async function handleConfirmPromoteClick(event) {
    await promote();
    await reloadAppInfo();

    promoteDispatch({ type: "hide" });
  }

  function handleConfirmPromoteCloseClick(event) {
    promoteDispatch({ type: "hide" });
  }

  async function handleConfirmPromoteReportClick(event, changeset) {
    return await reportChangeset(changeset);
  }

  async function handleLoadChangeset(event) {
    return await getChangeset();
  }

  /////////////////////////////
  // Handlers: Manual Deploy //
  /////////////////////////////

  function handleManualDeployModalShow(stage, service) {
    manualDeployDispatch({
      type: "show",
      stageName: stage.name,
      defaultBranch: stage.branch,
      serviceName: service && service.name,
      serviceType: service && service.serviceType,
      lastDeployedRefs: stage.lastDeployedRefs,
    });
  }

  function handleManualDeployModalClose(event) {
    manualDeployDispatch({ type: "hide" });
  }

  async function handleManualDeploy(event, branch, deployTo, force_deploy) {
    await deployStage(deployTo, branch, force_deploy);
    await reloadAppInfo();

    manualDeployDispatch({ type: "hide" });
  }

  ////////////////////////////
  // Handlers - Add Service //
  ////////////////////////////

  function handleAddServiceModalShow() {
    addServiceDispatch({ type: "show" });
  }

  function handleAddServiceModalClose(event) {
    addServiceDispatch({ type: "hide" });
  }

  async function handleAddService(name, path, serviceFramework) {
    await addService(name, path, serviceFramework);
    await reloadAppInfo();
    addServiceDispatch({ type: "hide" });
  }

  async function handleSearchPath(path) {
    return await getSlsInfo(path);
  }

  //////////////
  // Handlers //
  //////////////

  //  function handleEditPipelineClick(event) {
  //  }
  //
  //  function handleEditPipelineDoneClick(event) {
  //  }

  async function handleCancelDeployClick(stage) {
    cancelBuildDispatch({ type: "cancelling", stage: stage.name });

    try {
      await cancelBuild(stage.name, stage.latestBuild.build_id);
      await reloadAppInfo();
    } catch (e) {
      errorHandler(e);
    }

    cancelBuildDispatch({ type: "cancelled", stage: stage.name });
  }

  return (
    <div className="AppPipeline">
      {isLoading && <LoadingSpinner />}

      {!isLoading && isRemovingOrRemoveFailed && (
        <Redirect to={getAppRemoveUrl(ownerId, appId)} />
      )}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="app"
          code={loadError}
          context={{
            name: appId,
          }}
        />
      )}
      {error && !loadError && <ErrorAlert error={error} />}

      {loaded && (
        <div>
          <AppPipelineInfoPanel
            appInfo={appInfo}
            onAddServiceClick={handleAddServiceModalShow}
          />

          <AppPipelineTable
            app={appInfo.app}
            onPromote={handlePromote}
            appStages={appInfo.stages}
            services={appInfo.services}
            cancellingBuilds={cancellingBuilds}
            onCancelDeploy={handleCancelDeployClick}
            onManualDeploy={handleManualDeployModalShow}
            onAddServiceClick={handleAddServiceModalShow}
          />

          <ConfirmPromote
            show={promoteState.show}
            onLoad={handleLoadChangeset}
            pathParams={props.match.params}
            onConfirm={handleConfirmPromoteClick}
            promoteTo={promoteState.tarType}
            onClose={handleConfirmPromoteCloseClick}
            onReport={handleConfirmPromoteReportClick}
            downstreamStageId={promoteState.tarAppStageId}
            source={{
              serviceId: promoteState.serviceName,
              serviceType: promoteState.serviceType,
              appStageId: promoteState.srcAppStageId,
            }}
          />

          <ManualDeploy
            onDeploy={handleManualDeploy}
            show={manualDeployState.show}
            onClose={handleManualDeployModalClose}
            key={`deploy-${manualDeployState.key}`}
            defaultBranch={manualDeployState.defaultBranch}
            lastDeployedRefs={manualDeployState.lastDeployedRefs}
            deployTo={{
              appStageId: manualDeployState.stageName,
              serviceId: manualDeployState.serviceName,
              serviceType: manualDeployState.serviceType,
            }}
          />

          <AppAddService
            onAdd={handleAddService}
            show={addServiceState.show}
            onSearch={handleSearchPath}
            onClose={handleAddServiceModalClose}
            key={`service-${addServiceState.key}`}
          />
        </div>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(AppPipeline));
