import React, { Component } from "react";
import { Auth } from "aws-amplify";
import {
  invokeApig,
  invokePublicApig,
  setIdentityIdAttribute,
} from "../lib/awsLib";
import { addBreadcrumb } from "../lib/sentryLib";
import title from "../lib/titleLib";
import UsernameUpdate from "./widgets/UsernameUpdate";
import BetaSignupForm from "../components/BetaSignupForm";
import ScreenHeader from "../components/ScreenHeader";
import SsoLoginPanel from "../components/SsoLoginPanel";
import SignupConfirmForm from "../components/SignupConfirmForm";
import { getDomainFromEmail } from "../lib/regexLib";
import { errorHandler, createError } from "../lib/errorLib";
import "./Signup.css";

const defaultState = {
  isSigningup: false,
  isConfirming: false,
  isConfirmed: false,
  showPassword: false,
  email: null,
  password: null,
  newUser: false,
  betaFields: null,
  identityProvider: null,
};

class Signup extends Component {
  constructor(props) {
    super(props);

    document.title = title("Sign up");

    this.state = {
      ...defaultState,
    };
  }

  /////////
  // API //
  /////////

  betaSignup(fields) {
    return invokePublicApig({
      path: "/beta_signup",
      method: "POST",
      body: fields,
    });
  }

  updateUsername(username) {
    return invokeApig({
      path: "",
      method: "PUT",
      body: { username },
    });
  }

  getIdentityProvider(domain) {
    return invokePublicApig({
      path: "/identity_providers",
      queryStringParameters: {
        domain,
      },
    });
  }

  //////////////
  // Handlers //
  //////////////

  async checkDomainForSso(domain) {
    try {
      const { identity_provider } = await this.getIdentityProvider(domain);

      if (identity_provider) {
        this.setState({ identityProvider: identity_provider });
        return;
      }
    } catch (e) {}

    this.setState({ showPassword: true });
  }

  handleSignupClick = async (fields) => {
    const { password, ...betaFields } = fields;
    const email = betaFields.email;

    // Log signup info
    addBreadcrumb({
      message: "Sign up",
      category: "requests",
      data: {
        email,
      },
    });

    this.setState({
      email,
      password,
      betaFields,
      isSigningup: true,
    });

    if (!password) {
      await this.checkDomainForSso(getDomainFromEmail(email));
      this.setState({ isSigningup: false });
      return;
    }

    try {
      await Auth.signUp({
        username: email,
        password,
      });
      this.setState({ newUser: true });
    } catch (e) {
      if (e.code === "UsernameExistsException") {
        await this.handleExistingUser(email, e);
      } else {
        errorHandler(e);
      }

      this.setState({ isSigningup: false });
    }
  };

  async handleExistingUser(email, signupError) {
    try {
      await Auth.resendSignUp(email);
      this.setState({ newUser: true });
    } catch (e) {
      errorHandler(
        e.code === "InvalidParameterException" &&
          e.message === "User is already confirmed."
          ? signupError
          : e
      );
    }
  }

  handleConfirmClick = async (event, code) => {
    this.setState({ isConfirming: true });

    try {
      await Auth.confirmSignUp(this.state.email, code);
      await Auth.signIn(this.state.email, this.state.password);

      await this.betaSignup(this.state.betaFields);

      await setIdentityIdAttribute();

      this.setState({ isConfirmed: true });
    } catch (e) {
      // https://sentry.io/organizations/anomaly-innovations
      // /issues/942898599/events/e46ec23e16d54718aa24f207489af661/
      if (e.code === "InvalidParameterException") {
        errorHandler(createError("INVALID_CODE_FORMAT"));
      }
      // https://sentry.io/organizations/anomaly-innovations
      // /issues/942898599/events/f47b6f91b8dd4375ac141bfc7f5c83ca/
      else if (e.code === "NotAuthorizedException") {
        errorHandler(createError("UNABLE_TO_AUTHENTICATE"));
        this.props.history.push("/login");
        return;
      } else {
        errorHandler(e, {
          CodeMismatchException: true,
        });
      }
      this.setState({ isConfirming: false });
    }
  };

  handleCancelClick = () => {
    this.setState({ showPassword: false });
  };

  handleSsoCancelClick = (event) => {
    this.setState({
      showPassword: false,
      identityProvider: defaultState.identityProvider,
    });
  };

  handleUsernameUpdate = async (username) => {
    await this.updateUsername(username);
    // Set app as authenticated to redirect to home
    this.props.setUserAsAuthenticated(this.state.email);
  };

  render() {
    const { newUser, isConfirmed, identityProvider } = this.state;

    return (
      <div className="Signup">
        <ScreenHeader border>Sign up</ScreenHeader>
        {identityProvider === null && (
          <>
            {!newUser && (
              <BetaSignupForm
                isSigningup={this.state.isSigningup}
                onSignupClick={this.handleSignupClick}
                onCancelClick={this.handleCancelClick}
                showPassword={this.state.showPassword}
              />
            )}
            {newUser && !isConfirmed && (
              <SignupConfirmForm
                email={this.state.email}
                isConfirming={this.state.isConfirming}
                onConfirmClick={this.handleConfirmClick}
              />
            )}
            {newUser && isConfirmed && (
              <div className="username-form">
                <p>
                  Finally, please select a username. We use your username to
                  namespace your apps.
                </p>
                <UsernameUpdate onUpdate={this.handleUsernameUpdate} />
              </div>
            )}
          </>
        )}
        {identityProvider && (
          <SsoLoginPanel
            name={identityProvider.name}
            domain={identityProvider.domain}
            source={identityProvider.source}
            provider={identityProvider.provider}
            onCancelClick={this.handleSsoCancelClick}
          />
        )}
      </div>
    );
  }
}
export default Signup;
