import React, { Component } from "react";
import UsernameForm from "../../components/UsernameForm";

const noop = () => {};

const defaultProps = {
  username: "",
  onUpdate: noop,
};

const defaultState = {
  saving: false,
};

class UsernameUpdate extends Component {
  state = {
    ...defaultState,
  };

  handleSaveClick = async (event, username) => {
    this.setState({ saving: true });

    try {
      await this.props.onUpdate(username);
    } catch (e) {
      this.setState({ saving: false });
      throw e;
    }
  };

  render() {
    const { saving } = this.state;
    return (
      <UsernameForm
        saving={saving}
        username={this.props.username}
        onSaveClick={this.handleSaveClick}
      />
    );
  }
}

UsernameUpdate.defaultProps = defaultProps;

export default UsernameUpdate;
