import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { action } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [pathname, action]);

  return null;
}
