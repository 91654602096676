import React, { Component } from "react";
import { Form, Checkbox, FormGroup, FormControl } from "react-bootstrap";
import SectionDescriptionLabel from "./SectionDescriptionLabel";
import LoaderButton from "./LoaderButton";
import SectionInfo from "./SectionInfo";
import TextButton from "./TextButton";
import "./ItemDeletePanel.css";

const helpUrl = "https://seed.run/docs/deleting-resources";

const noop = () => {};

const config = {
  app: {
    determiner: "an",
    descName: "app",
    loadButton: "Delete App",
    hasHelpUrl: true,
    hasFullRemove: true,
    confirmCopy: (name) => `Delete the ${name} app`,
  },
  appStage: {
    determiner: "a",
    descName: "stage",
    loadButton: "Delete Stage",
    hasHelpUrl: true,
    hasFullRemove: true,
    confirmCopy: (name) => `Delete the ${name} stage`,
  },
  service: {
    determiner: "a",
    descName: "service",
    loadButton: "Delete Service",
    hasHelpUrl: true,
    hasFullRemove: true,
    confirmCopy: (name) => `Delete the ${name} service`,
  },
  organization: {
    determiner: "an",
    descName: "organization",
    loadButton: "Delete Organization",
    hasHelpUrl: false,
    hasFullRemove: false,
    confirmCopy: (name) => `Delete the ${name} org`,
  },
};

const defaultProps = {
  type: "",
  itemName: "",
  showing: false,
  deleting: false,
  onShowClick: noop,
  onHideClick: noop,
  onDeleteClick: noop,
};

const defaultState = {
  confirmText: "",
  fullRemove: true,
};

class ItemDeleteForm extends Component {
  state = { ...defaultState };

  handleFieldEdit = (event) => {
    const { id, value, type, checked } = event.target;

    this.setState({ [id]: type === "checkbox" ? checked : value });
  };

  canConfirm() {
    const { type, itemName } = this.props;

    return this.state.confirmText === config[type].confirmCopy(itemName);
  }

  handleDeleteConfirmClick = async (event) => {
    event.preventDefault();

    if (!this.canConfirm()) {
      return;
    }

    await this.props.onDeleteClick(event, this.state.fullRemove);
  };

  render() {
    const { type, deleting, itemName, onHideClick } = this.props;

    const isReadyToConfirm = this.canConfirm();
    const confirmCopy = config[type].confirmCopy(itemName);

    return (
      <div>
        <p className="helperCopy">
          This action cannot be undone. Enter &ldquo;<b>{confirmCopy}</b>&rdquo;
          to confirm.
        </p>

        {config[type].hasFullRemove && (
          <Checkbox
            id="fullRemove"
            checked={this.state.fullRemove}
            disabled={deleting}
            onChange={this.handleFieldEdit}
          >
            Also, remove all the <b>resources</b> from <b>AWS</b>.
          </Checkbox>
        )}

        <Form onSubmit={this.handleDeleteConfirmClick}>
          <FormGroup bsSize="large" controlId="confirmText">
            <FormControl
              type="text"
              disabled={deleting}
              placeholder={confirmCopy}
              value={this.state.confirmText}
              onChange={this.handleFieldEdit}
            />
          </FormGroup>
          <div className="controls">
            <LoaderButton
              text="Delete"
              bsSize="large"
              bsStyle="danger"
              loading={deleting}
              className="btnConfirm"
              disabled={!isReadyToConfirm}
              onClick={this.handleDeleteConfirmClick}
            />
            <TextButton onClick={onHideClick}>Cancel</TextButton>
          </div>
        </Form>
      </div>
    );
  }
}

function ItemDeletePanel({
  type,
  showing,
  deleting,
  itemName,
  disabled,
  onShowClick,
  onHideClick,
  onDeleteClick,
  stageBusyError,
  disabledMessage,
}) {
  return (
    <div className="ItemDeletePanel">
      <SectionInfo
        label="Delete"
        description={
          <>
            {`Delete this ${config[type].descName}. `}
            {config[type].hasHelpUrl && (
              <a
                href={helpUrl}
                target="_blank"
                className="btn-red-link"
                rel="noopener noreferrer"
              >
                {`Learn more about deleting ${config[type].determiner}
                  ${config[type].descName}.`}
              </a>
            )}
          </>
        }
        button={
          !showing && (
            <>
              {disabled && disabledMessage && (
                <SectionDescriptionLabel>
                  {disabledMessage}
                </SectionDescriptionLabel>
              )}
              <LoaderButton
                bsSize="large"
                bsStyle="danger"
                loading={deleting}
                disabled={disabled}
                className="btnDelete"
                onClick={onShowClick}
              >
                {config[type].loadButton}
              </LoaderButton>
            </>
          )
        }
      >
        {showing && (
          <ItemDeleteForm
            type={type}
            showing={showing}
            itemName={itemName}
            deleting={deleting}
            onHideClick={onHideClick}
            onDeleteClick={onDeleteClick}
          />
        )}
        {stageBusyError && (
          <p className="stage-busy-error">
            The stage is currently busy. Please wait until the stage is
            available.
          </p>
        )}
      </SectionInfo>
    </div>
  );
}

ItemDeletePanel.defaultProps = defaultProps;

export default ItemDeletePanel;
