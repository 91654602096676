import React, { Component } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import SectionDescriptionLabel from "./SectionDescriptionLabel";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import { errorHandler } from "../lib/errorLib";
import "./VerifyEmailForm.css";

const noop = () => {};

const defaultProps = {
  email: "",
  codeSent: false,
  onCancelClick: noop,
  onSendCodeClick: noop,
  onConfirmCodeClick: noop,
};

const defaultState = {
  isSending: false,
  hasCodeSent: false,
  isConfirming: false,
  formData: {
    code: "",
  },
};

export default class VerifyEmailForm extends Component {
  state = { ...defaultState };

  canSubmit() {
    return this.state.formData.code.trim() !== "";
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
    });
  };

  handleConfirmClick = async (event) => {
    event.preventDefault();

    if (!this.canSubmit()) {
      return;
    }

    const code = this.state.formData.code.trim();

    this.setState({ isConfirming: true });

    try {
      await this.props.onConfirmCodeClick(event, code);
    } catch (e) {
      errorHandler(e);
      this.setState({ isConfirming: false });
    }
  };

  handleSendCodeClick = async (event) => {
    this.setState({ isSending: true });

    try {
      await this.props.onSendCodeClick(event);
      this.setState({ hasCodeSent: true });
    } catch (e) {
      errorHandler(e);
      this.setState({ isSending: false });
    }
  };

  handleCancelClick = (event) => {
    this.props.onCancelClick(event);
  };

  render() {
    const { email } = this.props;
    const { formData, isSending, isConfirming } = this.state;

    const hasCodeSent = this.props.codeSent || this.state.hasCodeSent;

    return (
      <div className="VerifyEmailForm">
        {!hasCodeSent && (
          <>
            <SectionDescriptionLabel className="send-code-copy">
              Send a new confirmation code to verify your email address.
            </SectionDescriptionLabel>
            <LoaderButton
              text="Send Confirmation Code"
              bsSize="large"
              loading={isSending}
              onClick={this.handleSendCodeClick}
            />
          </>
        )}
        {hasCodeSent && (
          <form onSubmit={this.handleConfirmClick}>
            <FormGroup bsSize="large" controlId="code">
              <StyledControlLabel>Confirmation Code</StyledControlLabel>
              <FormControl
                autoFocus
                type="tel"
                value={formData.code}
                onChange={this.handleFieldChange}
              />
              <HelpBlock>
                Please check your email ({email}) for the confirmation code.
              </HelpBlock>
            </FormGroup>
            <div className="controls">
              <LoaderButton
                type="submit"
                bsSize="large"
                loading={isConfirming}
                disabled={!this.canSubmit()}
                onClick={this.handleConfirmClick}
              >
                Confirm
              </LoaderButton>
              <LoaderButton
                bsStyle="link"
                disabled={isConfirming}
                onClick={this.handleCancelClick}
              >
                Hide Update Email
              </LoaderButton>
            </div>
          </form>
        )}
      </div>
    );
  }
}

VerifyEmailForm.defaultProps = defaultProps;
