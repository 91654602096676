import { Auth } from "aws-amplify";
import * as sentry from "./sentryLib";
import { safeParse } from "./jsonLib";
import { onFinally } from "./promiseLib";
import errors from "../errors";

const AWS_CLOCK_OLD_MESSAGE = /Signature expired: [A-Za-z0-9]+ is now earlier than [A-Za-z0-9]+ \([A-Za-z0-9]+ - 5 min\.\)/;
const AWS_CLOCK_NEW_MESSAGE = /Signature not yet current: [A-Za-z0-9]+ is still later than [A-Za-z0-9]+ \([A-Za-z0-9]+ \+ 5 min\.\)/;

// Gets set when the page is refreshing and we don't want to
// handle any errors that are thrown
let suppressAllErrors = false;

// Track if we are about to reload
let beforeunload = false;

// Set to true on `beforeunload` event
window.addEventListener("beforeunload", () => (beforeunload = true));

export function errorHandler(error, expectedErrors = {}) {
  if (process.env.REACT_APP_STAGE !== "production") {
    console.log(error);
  }

  // Cognito does not throw an error object.
  // So we need to convert it to an error for
  // Sentry to handle it.
  if (isCognitoError(error)) {
    error = createCognitoError(error);
  }

  let errorCode = null;
  let message = null;

  if (isStripeError(error)) {
    const code = `stripe_${error.stripe.type}`;

    if (error.stripe.type === "card_error") {
      message = error.stripe.message;
    } else if (errors[code]) {
      errorCode = code;
      message = errors[errorCode];
    }
  } else if (isCreatedError(error)) {
    errorCode = error.code;

    if (errors[errorCode]) {
      message = errors[errorCode];
    }
  } else if (getAPIError(error)) {
    const respObj = getAPIError(error);
    if (respObj) {
      if (respObj.hasOwnProperty("code") && errors[respObj.code]) {
        errorCode = respObj.code;
        message =
          errorCode === "stripe_card_error" && respObj.message
            ? respObj.message
            : errors[errorCode];
      }
      // Handle API Gateway errors
      else if (respObj.hasOwnProperty("message")) {
        if (isAwsClockError(respObj.message)) {
          message = errors["AWS_CLOCK_ERROR"];
        }
      }
    }
  }

  if (message === null) {
    message = errors.generic;
  }

  // Don't log if it's an expected error
  // like invalid username/password
  // AND if we are about to unload the page
  if (!expectedErrors[errorCode] && !beforeunload) {
    logErrorToSentry(error, message);
  }

  // If the error is a session expiry
  // then don't alert and refresh the page
  if (errorCode === "user_session_expired") {
    suppressAllErrors = true;

    // Refresh the page after signing the user out
    // Handles the case where Cognito might be partly down
    // In these cases the API calls fail because Cognito says the sessions has expired.
    // But the initial page loads fine only to fail again on the API call.
    // Causing a constant refresh loop.
    onFinally(Auth.signOut(), () => window.location.reload(true));
  } else if (!suppressAllErrors) {
    if (
      error.config &&
      error.config.method &&
      error.config.method.toLowerCase() === "get"
    ) {
      // Wait before showing alert
      // Handles the case where the page was reloaded,
      // causing requests to be cancelled.
      // The beforeunload flag isn't enough because all browsers don't support
      // the beforeunload event
      setTimeout(() => window.alert(message), 1000);
    } else {
      window.alert(message);
    }
  }
}

export function getLoadError(error, codes) {
  const response = error.response
    ? error.response
    : // For non-GET requests the error is not JSON parsed
    typeof error.message === "string"
    ? { data: safeParse(error.message) }
    : null;

  if (
    response &&
    response.data &&
    response.data.hasOwnProperty("code") &&
    codes[response.data.code]
  ) {
    return codes[response.data.code];
  }

  return null;
}

export function loadErrorHandler({ error, codes, setState }) {
  const loadError = getLoadError(error, codes);

  if (loadError) {
    setState({ loadError });

    return true;
  }

  return false;
}

export function createError(code, message = null) {
  const err = new Error(message || code);
  err.code = code;

  return err;
}

export function createCognitoError(e) {
  return e instanceof Error ? e : createError(e.code, e.message);
}

export function createGenericError(data) {
  if (typeof data !== "string") {
    data = JSON.stringify(data);
  }

  return new Error(data);
}

export function formatStripeError(error) {
  const err = new Error("Stripe error");
  err.stripe = error;

  return err;
}

export function isCognitoError(e) {
  return !(e instanceof Error) && e.hasOwnProperty("code");
}

export function isAPIErrorWithCode(e, code) {
  const error = getAPIError(e);

  return error && error.code && error.code === code ? true : false;
}

/**
 * Check if we want to show the network error sign for the given error
 */
export function isAPINetworkError(e) {
  const code = e.code;
  const message = e.message;

  // If the browser is offline
  if (window.navigator.onLine === false) {
    e.code = "NETWORK_OFFLINE";
    return true;
  }
  // If the request has been aborted
  // https://github.com/axios/axios/blob/dc4bc49673943e35280e5df831f5c3d0347a9393/lib/adapters/xhr.js#L73
  else if (code === "ECONNABORTED" && message === "Request aborted") {
    return true;
  }

  return false;
}

export function getAPIError(error) {
  // Non-200 error returned by the API where `data` is the
  // parsed JSON response
  return error && error.response && error.response.data;
}

function isCreatedError(error) {
  return error.message && error.code;
}

function isStripeError(error) {
  return error.stripe;
}

export function isAwsPermissionError(error) {
  const errorData = getAPIError(error);
  return errorData && errorData.code === "AWSPermissionGeneric" ? true : false;
}

export function isAwsThrottlingError(error) {
  const errorData = getAPIError(error);
  return errorData && errorData.code === "AWSThrottling" ? true : false;
}

export function isInvalidIamError(error) {
  const errorData = getAPIError(error);
  return errorData && errorData.code === 8105 ? true : false;
}

function logErrorToSentry(error, displayedMessage) {
  let fingerprint;

  // If it's an API error, then set the fingerprint
  // to tell Sentry to split up errors by url
  if (
    error.message &&
    error.message !== "user_session_expired" &&
    error.config &&
    error.config.url
  ) {
    fingerprint = ["{{default}}", error.config.url];
  }

  // Log error
  sentry.captureException(
    error,
    (scope) => {
      scope.setExtra("displayedMessage", displayedMessage);

      if (error.hasOwnProperty("code")) {
        scope.setExtra("errorCode", error.code);
      }

      if (error.hasOwnProperty("message")) {
        scope.setExtra("errorMessage", error.message);
      }

      // If API error log request info
      if (error.hasOwnProperty("config")) {
        error.config.url && scope.setExtra("requestUrl", error.config.url);
        error.config.body && scope.setExtra("requestBody", error.config.body);
        error.config.method &&
          scope.setExtra("requestMethod", error.config.method);
      }
    },
    fingerprint
  );
}

function isAwsClockError(message) {
  return AWS_CLOCK_NEW_MESSAGE.test(message) ||
    AWS_CLOCK_OLD_MESSAGE.test(message)
    ? true
    : false;
}
