import React, { useEffect } from "react";
import ErrorAlert from "../components/ErrorAlert";
import ScreenHeader from "../components/ScreenHeader";
import SectionHeader from "../components/SectionHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import BuildInfoPanel from "../components/BuildInfoPanel";
import withAppHeader from "../components/ComponentWithAppHeader";
import withFaviconChange from "../components/ComponentWithFaviconChange";
import CollapsableLogTextarea from "../components/CollapsableLogTextarea";
import { appBuildBreadcrumb } from "../lib/breadcrumbLib";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import "./AppActivityPostDeployTest.css";

function AppActivityPostDeployTest(props) {
  let isLoading = true;

  const { ownerId, appId, appStageId, buildId } = props.match.params;

  useEffect(() => {
    document.title = title(`Build v${buildId} Post-Deploy`);
  }, [buildId]);

  const { data: buildInfo, error } = useAPILoad(
    `/${ownerId}/${appId}/stages/${appStageId}/builds/${buildId}/post_deploy_test`
  );

  const logs = buildInfo && buildInfo.logs;
  const build = buildInfo && buildInfo.build;
  const postDeployStatus =
    build && build.progress[build.progress.length - 1][0].status;

  useEffect(() => {
    if (!postDeployStatus) {
      return;
    }

    props.favicon.setStatus(postDeployStatus);
  }, [props.favicon, postDeployStatus]);

  if (buildInfo) {
    isLoading = false;
  }

  return (
    <div className="AppActivityPostDeployTest">
      <ScreenHeader border breadcrumb={appBuildBreadcrumb(props)}>
        Post-Deploy
      </ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {error && <ErrorAlert error={error} />}

      {!isLoading && buildInfo && (
        <>
          <BuildInfoPanel activity={build} />
          <div>
            <SectionHeader>Log</SectionHeader>
            <CollapsableLogTextarea log={logs} loading={false} />
          </div>
        </>
      )}
    </div>
  );
}

export default withAppHeader(withFaviconChange(AppActivityPostDeployTest));
