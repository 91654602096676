import React, { useRef } from "react";
import { Modal as BaseModal } from "react-bootstrap";

const Modal = React.forwardRef(({ onExit, onEntering, ...props }, ref) => {
  const backdrop = useRef(null);

  function handleEntering() {
    const el = (backdrop.current = document.getElementsByClassName(
      "modal-backdrop"
    )[0]);

    if (el) {
      el.addEventListener(
        "transitionend",
        () => {
          el.classList.add("blur");
          onEntering && onEntering();
        },
        { once: true }
      );
    } else {
      onEntering && onEntering();
    }
  }

  function handleExit() {
    backdrop.current && backdrop.current.classList.remove("blur");

    onExit && onExit();
  }

  return (
    <BaseModal
      ref={ref}
      onExit={handleExit}
      onEntering={handleEntering}
      {...props}
    >
      {props.children}
    </BaseModal>
  );
});

Modal.Body = BaseModal.Body;
Modal.Header = BaseModal.Header;
Modal.Title = BaseModal.Title;
Modal.Footer = BaseModal.Footer;

Modal.Dialog = BaseModal.Dialog;

export default Modal;
