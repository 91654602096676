import React, { useState } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import TextButton from "./TextButton";
import SectionInfo from "./SectionInfo";
import LoaderButton from "./LoaderButton";
import { useFormReducer } from "../lib/hooksLib";
import "./IssuesStageSettingsPanel.css";

const helpUrl = "https://seed.run/docs/issues-and-alerts#default-stage";

export default function IssuesStageSettingsPanel({
  stages = [],
  defaultStage,
  onUpdateClick,
  updated = false,
  updating = false,
  disabled = false,
}) {
  const [showStatus, setShowStatus] = useState(updated);
  const [
    { values: formData, isDirty },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ defaultStage });

  const editing = isDirty.defaultStage;

  function handleCancelClick() {
    setShowStatus(false);
    formDispatch({ type: "reset" });
  }

  function handleUpdateClick(event) {
    event.preventDefault();

    onUpdateClick(formData.defaultStage);
  }

  function renderForm() {
    return (
      <Form className="form" onSubmit={handleUpdateClick}>
        <FormGroup controlId="defaultStage">
          <FormControl
            bsSize="large"
            componentClass="select"
            onChange={handleFieldChange}
            value={formData.defaultStage}
            disabled={updating || disabled}
          >
            {stages.map(({ name }) => (
              <option key={name} value={name}>
                {name === defaultStage ? `${name} (Default)` : name}
              </option>
            ))}
          </FormControl>
        </FormGroup>
        {!editing && showStatus && (
          <p className="status">Default stage updated!</p>
        )}
        {editing && (
          <div className="controls">
            <LoaderButton
              type="submit"
              bsSize="large"
              loading={updating}
              onClick={handleUpdateClick}
            >
              Update
            </LoaderButton>
            <TextButton onClick={handleCancelClick}>Cancel</TextButton>
          </div>
        )}
      </Form>
    );
  }

  return (
    <div className="IssuesStageSettingsPanel">
      <SectionInfo
        label="Default Stage"
        description={
          <span>
            Set the default stage for Issues.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn more.
            </a>
          </span>
        }
      >
        <p className="info">
          Seed defaults to using your production stage for Issues. Change this
          if you have multiple production stages or if you want to use another
          stage.
        </p>
        {renderForm()}
      </SectionInfo>
    </div>
  );
}
