import React from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import Modal from "./Modal";
import LoaderButton from "./LoaderButton";
import SectionDescriptionLabel from "./SectionDescriptionLabel";
import { useFormReducer } from "../lib/hooksLib";
import { trimFields } from "../lib/formLib";
import "./StagePostDeployModal.css";

export default function StagePostDeployModal({
  postDeployTest = [],

  show = false,
  saving = false,
  onSaveClick,
  onCloseClick,
}) {
  const [{ values: formData, isDirty }, , handleFieldChange] = useFormReducer({
    postDeployTest: postDeployTest ? postDeployTest.join("\n") : postDeployTest,
  });

  function canSubmit() {
    return isDirty.postDeployTest && formData.postDeployTest.trim() !== "";
  }

  function handleSaveClick(event) {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    let { postDeployTest } = trimFields(formData);
    postDeployTest =
      postDeployTest === ""
        ? null
        : JSON.stringify(
            postDeployTest
              .split("\n")
              .map((step) => step.trim())
              .filter((step) => step.length > 0)
          );

    onSaveClick(postDeployTest);
  }

  function renderForm() {
    return (
      <>
        <SectionDescriptionLabel>
          The following commands will be run after all the services in this
          stage have been deployed.
        </SectionDescriptionLabel>

        <div className="notifications">
          <Form onSubmit={handleSaveClick}>
            <FormGroup controlId="postDeployTest">
              <FormControl
                autoFocus
                rows={5}
                placeholder="npm test"
                componentClass="textarea"
                disabled={saving}
                value={formData.postDeployTest}
                onChange={handleFieldChange}
              />
            </FormGroup>
          </Form>
        </div>
      </>
    );
  }

  return (
    <Modal show={show} className="StagePostDeployModal" onHide={onCloseClick}>
      <Modal.Header closeButton>
        <Modal.Title>Post-Deploy Phase</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderForm()}</Modal.Body>
      <Modal.Footer>
        <LoaderButton bsStyle="link" onClick={onCloseClick}>
          Cancel
        </LoaderButton>
        <LoaderButton
          type="submit"
          bsSize="large"
          bsStyle="warning"
          disabled={!canSubmit()}
          loading={saving}
          onClick={handleSaveClick}
        >
          {postDeployTest ? "Update" : "Enable"}
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
