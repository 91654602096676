import React from "react";
import { Button, Glyphicon } from "react-bootstrap";
import "./LoaderButton.css";

function LoaderButton(
  {
    text,
    bsStyle,
    className = "",
    loading = false,
    disabled = false,
    importantLink = false,
    ...props
  },
  ref
) {
  const blockCs = bsStyle === "block" ? "block" : "";
  const redLink = bsStyle === "red-link";
  const redLinkCs = redLink ? "btn-red-link" : "";
  const loadingCs = loading ? "loading" : "";
  bsStyle = redLink ? "link" : blockCs ? "default" : bsStyle;

  return (
    <Button
      ref={ref}
      bsStyle={bsStyle}
      disabled={disabled || loading}
      className={`LoaderButton ${redLinkCs} ${loadingCs} ${blockCs} ${className}`}
      {...props}
    >
      {loading && <Glyphicon glyph="refresh" className="spinning" />}
      {text}
      {props.children}
    </Button>
  );
}

export default React.forwardRef(LoaderButton);
