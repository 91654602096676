import React, { Component } from "react";
import withCancel from "../components/ComponentWithCancel";
import ScreenHeader from "../components/ScreenHeader";
import RequestDemoForm from "../components/RequestDemoForm";
import FaIcon from "../components/FaIcon";
import { errorHandler } from "../lib/errorLib";
import title from "../lib/titleLib";
import "./RequestDemo.css";

class RequestDemo extends Component {
  constructor(props) {
    super(props);

    document.title = title("Request a Demo");

    this.state = {
      isSigningup: false,
      signupComplete: false,
    };
  }

  betaSignup(fields) {
    return this.props.invokePublicApig({
      path: "/beta_signup",
      method: "POST",
      body: {
        ...fields,
        source: "demo",
      },
    });
  }

  handleSignupClick = async (event, fields) => {
    this.setState({ isSigningup: true });
    try {
      await this.betaSignup(fields);
      this.setState({ signupComplete: true });
    } catch (e) {
      errorHandler(e);
      this.setState({ isSigningup: false });
    }
  };

  render() {
    return (
      <div className="RequestDemo">
        <ScreenHeader border>Request a Demo</ScreenHeader>
        {!this.state.signupComplete && (
          <RequestDemoForm
            isSigningup={this.state.isSigningup}
            onSignupClick={this.handleSignupClick}
          />
        )}
        {this.state.signupComplete && (
          <div className="complete">
            <p>
              <FaIcon name="check-circle" />
            </p>
            <p>Thank you for signing up for a demo.</p>
            <p>We&#8217;ll be sending you an email shortly!</p>
            <p>
              Click <a href="https://seed.run">here</a> to go back.
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default withCancel(RequestDemo);
