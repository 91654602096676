import React, { useEffect } from "react";
import SectionInfo from "./SectionInfo";
import LoaderToggle from "./LoaderToggle";
import { useFormReducer } from "../lib/hooksLib";
import "./AppCiPanel.css";

const helpUrl = "https://seed.run/docs/connect-your-own-ci";

export default function AppCiPanel({
  saving,
  enabled,
  resetKey,
  onSaveClick,
  gitProvider,
}) {
  const isSupported =
    gitProvider === "github" ||
    gitProvider === "github-enterprise" ||
    gitProvider === "bitbucket";

  const [
    { values: formData },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ enabled });

  useEffect(() => {
    formDispatch({ type: "reset" });
  }, [resetKey, formDispatch]);

  function handleChange(e) {
    handleFieldChange(e);

    onSaveClick(e.target.checked);
  }

  return (
    <div className="AppCiPanel">
      <SectionInfo
        label="External CI"
        description={
          <span>
            Connect a CI service like CircleCI or Travis CI to Seed.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn more.
            </a>
          </span>
        }
      >
        {!enabled && !isSupported && (
          <p className="current">
            {gitProvider &&
              "Connecting an external CI is not available for your Git provider."}
            {!gitProvider && "Not connected to a Git repository."}
          </p>
        )}
        <LoaderToggle
          id="enabled"
          loading={saving}
          onChange={handleChange}
          disabled={!isSupported}
          checked={formData.enabled}
        >
          Connect external CI
        </LoaderToggle>
      </SectionInfo>
    </div>
  );
}
