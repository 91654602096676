import React, { useState, useEffect } from "react";
import TextButton from "./TextButton";
import LoaderToggle from "./LoaderToggle";
import StagePostDeployModal from "./StagePostDeployModal";
import { useFormReducer } from "../lib/hooksLib";
import { sortStages } from "../lib/stagesLib";
import "./StagesPostDeployPanel.css";

export default function StagesPostDeployPanel({
  resetKey,

  stages = [],

  editing = null,
  enabling = null,
  disabling = null,

  onEditClick,
  onSaveClick,
  onCancelClick,
}) {
  stages = sortStages(stages, { filterDeleted: true });

  const [modalKey, setModalKey] = useState(0);
  const [
    { values: formData },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer(generateFormFields());

  useEffect(() => {
    formDispatch({ type: "reset" });
  }, [resetKey, formDispatch]);

  useEffect(() => {
    if (editing) {
      setModalKey((modalKey) => modalKey + 1);
    }
  }, [editing]);

  function generateFormFields() {
    const fields = {};

    stages.forEach(({ name, post_deploy_test }) => {
      fields[name] = post_deploy_test ? true : false;
    });

    return fields;
  }

  function handleChange(e) {
    handleFieldChange(e);

    const stage = e.target.id;

    if (e.target.checked) {
      const stageObject = stages.find(({ name }) => name === stage);
      onEditClick([stage, stageObject.post_deploy_test]);
    } else {
      onSaveClick(stage, null);
    }
  }

  return (
    <div className="StagesPostDeployPanel">
      {stages.map(({ name, post_deploy_test }) => (
        <div key={name} className="stage">
          <div className="name">{name}</div>
          <div>
            <LoaderToggle
              id={name}
              onChange={handleChange}
              checked={formData[name]}
              loading={disabling === name}
            >
              Run a post-deploy phase
            </LoaderToggle>
            {post_deploy_test && (
              <span className="edit">
                .&nbsp;
                <TextButton
                  onClick={() => onEditClick([name, post_deploy_test])}
                  disabled={
                    enabling === name || disabling === name ? true : false
                  }
                >
                  Edit commands
                </TextButton>
                .
              </span>
            )}
          </div>
        </div>
      ))}

      <StagePostDeployModal
        onCloseClick={onCancelClick}
        saving={enabling ? true : false}
        show={editing === null ? false : true}
        key={`StagePostDeployModal-${modalKey}`}
        postDeployTest={editing ? editing[1] : undefined}
        onSaveClick={
          editing &&
          ((postDeployTest) => onSaveClick(editing[0], postDeployTest))
        }
      />
    </div>
  );
}
