import React, { useState } from "react";
import { FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import SectionInfo from "./SectionInfo";
import TextButton from "./TextButton";
import { useFormReducer } from "../lib/hooksLib";
import "./ServicePathForm.css";

export default function ServicePathForm({
  path,
  type,
  onSaveClick,
  saving = false,
  editing = false,
  updated = false,
}) {
  const [showStatus, setShowStatus] = useState(updated);
  const [
    { values: formData, isDirty },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ path });

  editing = isDirty.path || editing;

  const displayCs = editing ? "" : "display";

  function canSubmit() {
    return isDirty.path === true && formData.path.trim() !== "";
  }

  function handleCancelClick() {
    setShowStatus(false);
    formDispatch({ type: "reset" });
  }

  function handleSaveClick(event) {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    onSaveClick(formData.path);
  }

  function renderForm() {
    return (
      <form className={displayCs} onSubmit={handleSaveClick}>
        <FormGroup controlId="path">
          <FormControl
            type="text"
            bsSize="large"
            disabled={saving}
            value={formData.path}
            onChange={handleFieldChange}
          />
        </FormGroup>
        {!editing && showStatus && (
          <p className="status">Service path updated!</p>
        )}
        {editing && (
          <div className="controls">
            <LoaderButton
              type="submit"
              bsSize="large"
              loading={saving}
              disabled={!canSubmit()}
              onClick={handleSaveClick}
            >
              Update
            </LoaderButton>
            <TextButton onClick={handleCancelClick}>Cancel</TextButton>
          </div>
        )}
      </form>
    );
  }

  return (
    <div className="ServicePathForm">
      <SectionInfo
        label="Service Path"
        description={
          type === "sst/resources"
            ? "Location of the sst.config.ts file for this service."
            : "Location of the serverless.yml for this service."
        }
      >
        {renderForm()}
      </SectionInfo>
    </div>
  );
}
