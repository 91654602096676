import React from "react";
import { Button } from "react-bootstrap";
import "./ButtonCheckbox.css";

function handleClick(id, checked, disabled, onChange) {
  onChange({ target: { id, checked: !checked } });
}

export default ({
  id,
  onChange,
  className = "",
  checked = false,
  disabled = false,
  ...props
}) => {
  const checkedCs = checked ? "checked" : "";
  return (
    <Button
      id={id}
      bsSize="xsmall"
      disabled={disabled}
      className={`ButtonCheckbox ${className} ${checkedCs}`}
      onClick={(event) => handleClick(id, checked, disabled, onChange)}
      {...props}
    >
      {props.children}
    </Button>
  );
};
