import React, { useMemo, useState, useEffect } from "react";
import { Glyphicon } from "react-bootstrap";
import Alert from "./Alert";
import FaIcon from "./FaIcon";
import ErrorAlert from "./ErrorAlert";
import TextButton from "./TextButton";
import LoaderButton from "./LoaderButton";
import LoadingSpinner from "./LoadingSpinner";
import RequestLogEntry from "./RequestLogEntry";
import {
  parseDateString,
  dateToFullTimeNoYearNoSecondWithUTCTimeZone,
  dateToFullTimeNoYearNoSecondWithLocalTimeZone,
} from "../lib/timeLib";
import { getAPIError } from "../lib/errorLib";
import { buildQueryStringUrl } from "../lib/urlLib";
import { useInitial } from "../lib/hooksLib";
import {
  LOG_LEVEL,
  parseLambdaLogMetadata,
  parseAccessLogMetaData,
  parseAccessLogFormatRegex,
  parseAccessLogFormatFieldNames,
} from "../lib/logLib";
import { getHighlightTermsFromFilterString } from "../lib/stringLib";
import "./RequestLogPanel.css";

const SPAN_DURATION = 300000; // 5 mins
const REQUEST_WITH_ID_END_CUSHION = 10;
const REQUEST_WITHOUT_ID_END_CUSHION = 100;
const LOAD_MODE = {
  TAIL: "tail",
  REQUEST: "request",
  RANGE: "range",
};

export default function RequestLogPanel({
  span = null,
  apiId = "",
  lambda = "",
  region = "",
  service = "",
  buildShareUrl,
  filter = null,
  urlError = null,
  isAccessLog = false,
  isErrorOnly = false,
  logTimestamp = null,
  logStreamName = null,
  isSingleRequest = false,
  appStageApiLink = "",
  urlResultsByUrl = {},
  tailingStartCutoffAt = null,
  onUrlsUpdate = null,
  onResetUrlError = null,
}) {
  // Tokenize filter string
  const filterTerms = useMemo(
    () => (filter === null ? null : getHighlightTermsFromFilterString(filter)),
    [filter]
  );

  //////////
  // Load //
  //////////
  const spanInfo = span && parseDateString(span);
  const [sections, setSections] = useState([buildInitialSection()]);
  const [isUTC, setIsUTC] = useState(!!(spanInfo && spanInfo.isUTC));

  // Using useInitial because the two callbacks are being used as dependencies
  onUrlsUpdate = useInitial(onUrlsUpdate);
  onResetUrlError = useInitial(onResetUrlError);

  useEffect(() => {
    return () => {
      onUrlsUpdate([]);
      onResetUrlError();
    };
  }, [onUrlsUpdate, onResetUrlError]);

  //resolve urls
  useEffect(() => {
    const urlRequests = [];
    sections.forEach(({ urls, loadMode }) =>
      urls.forEach((url) => urlRequests.push({ loadMode, url }))
    );

    onUrlsUpdate(urlRequests);
  }, [sections, onUrlsUpdate]);

  // build visible data
  const topSection = sections[0];
  const bottomSection = sections[sections.length - 1];
  const isInitialLoading =
    topSection.isMainSection && isLoadingSectionInitialPage(topSection);
  const isTailingBottomSection = bottomSection.loadMode === LOAD_MODE.TAIL;

  let xrayBaseLink;
  let eventsAndButtons = [];
  let requestsAndButtons = [];
  if (!isInitialLoading) {
    xrayBaseLink = getXrayBaseLink();
    mergeAllEvents();
    filterEvents();
    sortEvents();
    if (isAccessLog) {
      buildEventsMetaDataForAccessLog();
      buildRequestsWithoutGrouping();
      buildRequestsMetaData();
      isSingleRequest && filterSingleRequestForAccessLog();
    } else {
      buildEventsMetaDataForLambda();
      (filter && !isSingleRequest) || isErrorOnly
        ? buildRequestsWithoutGrouping()
        : buildRequestsWithGrouping();
      buildRequestsMetaData();
      isSingleRequest && filterSingleRequestForLambda();
    }
    isErrorOnly && !isErrorOnlyFailedToParse() && filterErrorOnlyRequests();
  }

  const isEmpty = requestsAndButtons.length === 0;

  const [expanded, setExpanded] = useState({});

  // auto expand single request after the initial request loads
  const singleRequestFirstLogId =
    isSingleRequest && !isEmpty && requestsAndButtons[0].logs[0].i;
  useEffect(() => {
    // log id can be 0
    if (singleRequestFirstLogId === false) {
      return;
    }

    setExpanded({ [singleRequestFirstLogId]: true });
  }, [singleRequestFirstLogId]);

  ////////////////////////////////
  // Functions - Error handling //
  ////////////////////////////////

  const urlErrorUrl = urlError && urlError.url;
  const urlErrorRequestId = urlError && urlError.requestId;
  useEffect(() => {
    if (!urlErrorUrl) {
      return;
    }

    setSections((sections) => {
      let sectionIndexToRemove = -1;
      sections.forEach((section, i) => {
        const index = section.urls.indexOf(urlErrorUrl);
        // case 1: initial url failed AND not main section => remove section
        if (index === 0) {
          if (!section.isMainSection) {
            sectionIndexToRemove = i;
          }
        }
        // case 2: load more url failed => remove url
        else if (index > -1) {
          section.urls.splice(index, 1);
        }
      });

      if (sectionIndexToRemove > -1) {
        sections.splice(sectionIndexToRemove, 1);
      }
      return [...sections];
    });
  }, [urlErrorUrl, urlErrorRequestId]);

  /////////////////////////
  // Functions - Tailing //
  /////////////////////////
  useEffect(() => {
    if (!tailingStartCutoffAt) {
      return;
    }

    setSections((sections) => {
      const bottomSection = sections[sections.length - 1];
      return [{ ...bottomSection, start: tailingStartCutoffAt }];
    });
    setExpanded({});
  }, [tailingStartCutoffAt]);

  // Start tailing if
  // - is not showing single request
  // - currently not tailing, AND
  // - bottom section is not loading, AND
  // - bottom section does not have more logs
  // - bottom section reached near current date (within 1 min), AND
  if (
    !isSingleRequest &&
    !isTailingBottomSection &&
    !isLoadingSectionInitialOrMorePage(bottomSection) &&
    !getNextToken(bottomSection) &&
    bottomSection.end > Date.now() - 180000
  ) {
    // start tailing from 3 mins ago b/c CW Insights is delayed by 2-3 minutes
    const start = Date.now() - 180000;
    const loadMode = LOAD_MODE.TAIL;
    const section = {
      start,
      loadMode,
      urls: [buildApiUrl({ loadMode, start })],
    };
    setSections([...sections, section]);
  }

  ///////////////
  // Functions //
  ///////////////

  function buildInitialSection() {
    let loadMode, start, end;

    // Case 1: Tailing
    // - url (tailing): /logs?lambda=X
    // - url (tailing after page up): /logs?lambda=X&start=11324568736
    if (!span) {
      loadMode = LOAD_MODE.TAIL;
      start = Date.now() - 180000;
    } else {
      // Case 2: Debug point-in-time
      // - url: /logs?lambda=X&span=2019/11/11 11:59:00 PM
      // - url: /logs?lambda=X&span=2019/11/11 11:59:00 PM&start=11324568736&end=11324568736
      if (spanInfo && spanInfo.type === "absolute") {
        loadMode = LOAD_MODE.RANGE;
        start = spanInfo.ts - 30000;
        end = spanInfo.ts + 60000;
      }
      // Case 3: Search with relative range
      // - url: /logs?lambda=X&span=3days
      else if (spanInfo && spanInfo.type === "relative") {
        loadMode = LOAD_MODE.RANGE;
        start = spanInfo.ts;
        end = Date.now() + 60000;
      }
      // Case 4: Metrics link OR Share link
      // - url (metric): /logs?lambda=X&span=2019/11/11 11:59:00 PM to 2019/11/12 11:59:00 PM&filter=ERROR
      // - url (share): /logs?lambda=X&span=2019/11/11 11:59:00 PM to 2019/11/12 11:59:00 PM&filter=request_id
      else if (spanInfo && spanInfo.type === "range") {
        loadMode = logStreamName ? LOAD_MODE.REQUEST : LOAD_MODE.RANGE;
        start = spanInfo.startTs;
        end = spanInfo.endTs;
      }
      // Case 5: Failed to parse
      else {
        return;
      }
    }

    return {
      isMainSection: true,
      loadMode,
      start,
      end,
      urls: [buildApiUrl({ loadMode, start, end })],
    };
  }

  function isLoadingSectionInitialOrMorePage(section) {
    const latestUrl = section.urls[section.urls.length - 1];
    return !urlResultsByUrl[latestUrl];
  }

  function isLoadingTopSpanUpSectionInitialPage() {
    if (topSection.isMainSection) {
      return false;
    }
    return isLoadingSectionInitialPage(topSection);
  }

  function isLoadingBottomSpanDownSectionInitialPage() {
    if (bottomSection.isMainSection) {
      return false;
    }
    return isLoadingSectionInitialPage(bottomSection);
  }

  function isLoadingSectionInitialPage(section) {
    return !urlResultsByUrl[section.urls[0]];
  }

  function getNextToken(section) {
    let nextToken;
    section.urls.forEach((url) => {
      const result = urlResultsByUrl[url];
      if (result) {
        nextToken = result.nextToken;
      }
    });
    return nextToken;
  }

  function getXrayBaseLink() {
    return Object.values(urlResultsByUrl).find(
      (result) => result && result.xrayBaseLink
    ).xrayBaseLink;
  }

  function getLambdaLogRuntime() {
    const response = Object.values(urlResultsByUrl).find(
      (result) => result && result.runtime
    );
    return response && response.runtime;
  }

  function getAccessLogFormat() {
    return Object.values(urlResultsByUrl).find(
      (result) => result && result.logFormat
    ).logFormat;
  }

  function isErrorOnlyFailedToParse() {
    const response = Object.values(urlResultsByUrl).find(
      (result) => result && result.isErrorOnlyFailedToParse
    );
    return response && response.isErrorOnlyFailedToParse;
  }

  function isErrorOnlyNotSupported() {
    if (isAccessLog) {
      return false;
    }

    const runtime = getLambdaLogRuntime();
    return !runtime.startsWith("nodejs") && !runtime.startsWith("python");
  }

  function isTopSpanUpSectionEmpty() {
    if (topSection.isMainSection) {
      return false;
    }
    return isSectionEmpty(topSection);
  }

  function isBottomSpanDownSectionEmpty() {
    if (bottomSection.isMainSection) {
      return false;
    }
    return isSectionEmpty(bottomSection);
  }

  function isSectionEmpty(section) {
    return section.urls.every((url) => {
      const results = urlResultsByUrl[url];
      return !results || results.events.length === 0;
    });
  }

  function mergeAllEvents() {
    // Merge each section
    sections.forEach((section) => {
      let lastNextToken;
      let isLoading = false;
      section.urls.forEach((url) => {
        const results = urlResultsByUrl[url];
        if (!results) {
          isLoading = true;
          return;
        }

        // append requests
        eventsAndButtons.push(...results.events);

        // store nextToken
        lastNextToken = results.nextToken;
      });

      // append nextToken
      if (lastNextToken && section.loadMode !== LOAD_MODE.TAIL) {
        eventsAndButtons.push({
          type: "moreButton",
          id: section.start,
          isLoading,
          nextToken: lastNextToken,
          section,
        });
      }
    });
  }

  function filterEvents() {
    const eventIds = {};
    const insightEventTimestampMessageKeys = {};
    const filteredEventTimestampMessageKeys = {};

    eventsAndButtons = eventsAndButtons.filter((item) => {
      if (item.type === "moreButton") {
        return true;
      }

      // filter:
      // - events with empty message
      if (item.m.length === 0) {
        return false;
      }

      // filter:
      // - events with duplicate id
      if (eventIds[item.i]) {
        return false;
      }

      // filter:
      // - events has insight id, and has the exact timestamp + message as a filtered one
      // - events has filtered id, and has the exact timestamp + message as a insight one
      const key = `${item.t}-${item.m}`;
      if (
        (item.s === "i" && filteredEventTimestampMessageKeys[key]) ||
        (item.s === "f" && insightEventTimestampMessageKeys[key])
      ) {
        return false;
      }

      eventIds[item.i] = true;
      item.s === "i" && (insightEventTimestampMessageKeys[key] = true);
      item.s === "f" && (filteredEventTimestampMessageKeys[key] = true);

      return true;
    });
  }

  function sortEvents() {
    eventsAndButtons.sort((a, b) => {
      // only sort logs returned from CloudWatch Insights call
      if (a.type === "moreButton" || b.type === "moreButton") {
        return 0;
      }
      if (a.s === "f" || b.s === "f") {
        return 0;
      }

      // sort by timestamp
      if (a.t !== b.t) {
        return a.t - b.t;
      }

      // sort by ingestion timestamp
      if (a.it !== b.it) {
        return a.it - b.it;
      }

      // sort by log id
      // ie. order is **X** **Y** **Z** **a** **b** ... **z** **0** **1** ... **9**
      const last8CharsA = a.i.slice(-8);
      const last8CharsB = b.i.slice(-8);
      for (let i = 0; i < 8; i++) {
        if (last8CharsA[i] === last8CharsB[i]) {
          continue;
        }

        let sortValueA = last8CharsA[i];
        if (last8CharsA[i] >= "A" && last8CharsA[i] <= "Z") {
          sortValueA = `0_${sortValueA}`;
        } else if (last8CharsA[i] >= "a" && last8CharsA[i] <= "z") {
          sortValueA = `1_${sortValueA}`;
        } else if (last8CharsA[i] >= "0" && last8CharsA[i] <= "9") {
          sortValueA = `2_${sortValueA}`;
        }

        let sortValueB = last8CharsB[i];
        if (last8CharsB[i] >= "A" && last8CharsB[i] <= "Z") {
          sortValueB = `0_${sortValueB}`;
        } else if (last8CharsB[i] >= "a" && last8CharsB[i] <= "z") {
          sortValueB = `1_${sortValueB}`;
        } else if (last8CharsB[i] >= "0" && last8CharsB[i] <= "9") {
          sortValueB = `2_${sortValueB}`;
        }

        if (sortValueA < sortValueB) {
          return -1;
        } else if (sortValueA > sortValueB) {
          return 1;
        }
      }

      return 0;
    });
  }

  function buildEventsMetaDataForAccessLog() {
    const logFormat = getAccessLogFormat();
    const fieldNames = parseAccessLogFormatFieldNames(logFormat);
    const regex = parseAccessLogFormatRegex(logFormat);

    eventsAndButtons.forEach((item) => {
      if (item.type === "moreButton") {
        return true;
      }
      item.meta = parseAccessLogMetaData(item, fieldNames, regex);
    });
  }

  function buildEventsMetaDataForLambda() {
    const runtime = getLambdaLogRuntime();

    eventsAndButtons.forEach((item) => {
      if (item.type === "moreButton") {
        return true;
      }
      item.meta = parseLambdaLogMetadata(item, runtime);
    });
  }

  function buildRequestsWithGrouping() {
    requestsAndButtons = [];
    let requestsById = {};
    const latestRequestWithoutIdByStream = {};
    const latestRequestWithIdByStream = {};

    eventsAndButtons.forEach((event) => {
      // do not merge across more buttons
      if (event.type === "moreButton") {
        requestsAndButtons.push(event);
        requestsById = {};
        return;
      }

      const logStreamName = event.ls;
      const requestId = event.meta.requestId;
      let request;

      // Case 1: group logs with request id => group by request id
      if (requestId) {
        request = requestsById[requestId];
        // Case 1a: request with id does not exist => create new request
        // Case 1b: request with id exists, AND existing request ended => create new request
        if (
          !request ||
          (request.meta.endTime &&
            event.meta.startTime &&
            event.meta.startTime > request.meta.endTime)
        ) {
          request = {
            id: requestId,
            logs: [],
            logStreamName,
            meta: {},
          };
          requestsAndButtons.push(request);
          requestsById[requestId] = request;
        }
        // Case 1c: request with id exists, AND existing request NOT ended => group with request
        else {
        }
        // update open groups
        latestRequestWithIdByStream[logStreamName] = request;
      }

      // Case 2: group logs without request id => group by stream name
      // note: group consecutive requests without request id within 10ms together
      else {
        // case 2a: group event into an open group with request id
        if (
          latestRequestWithIdByStream[logStreamName] &&
          (!latestRequestWithIdByStream[logStreamName].meta.endTime ||
            event.t - latestRequestWithIdByStream[logStreamName].meta.endTime <=
              REQUEST_WITH_ID_END_CUSHION ||
            event.m.startsWith("Unknown application error occurred"))
        ) {
          request = latestRequestWithIdByStream[logStreamName];
        }
        // case 2b: group event into an previous request, and is within 100ms timespan
        // note: longest observed was 70ms
        else if (
          latestRequestWithoutIdByStream[logStreamName] &&
          event.t - latestRequestWithoutIdByStream[logStreamName].logs[0].t <=
            REQUEST_WITHOUT_ID_END_CUSHION
        ) {
          request = latestRequestWithoutIdByStream[logStreamName];
        }
        // case 2c: create new group
        else {
          request = {
            id: event.i,
            logs: [],
            logStreamName,
            meta: {},
          };
          requestsAndButtons.push(request);
        }
        latestRequestWithoutIdByStream[logStreamName] = request;
      }

      // append request log
      request.meta = { ...request.meta, ...event.meta };
      request.logs.push(event);
    });
  }

  function buildRequestsWithoutGrouping() {
    requestsAndButtons = eventsAndButtons.map((event) => {
      if (event.type === "moreButton") {
        return event;
      }

      return {
        id: event.meta.requestId || event.id,
        logs: [event],
        logStreamName: event.ls,
        meta: event.meta,
      };
    });
  }

  function buildRequestsMetaData() {
    requestsAndButtons.forEach((request) => {
      if (request.type === "moreButton") {
        return true;
      }

      // Request format:
      // {
      //    id,
      //    logs: [...],
      //    logStreamName,
      //    level,
      //    summary,
      //    meta: { ... },
      // }
      request.level = LOG_LEVEL.INFO;
      request.summary = undefined;

      request.logs.forEach((event) => {
        if (!request.summary) {
          request.summary = event.meta.summary;
        }

        if (event.meta.level > request.level) {
          request.level = event.meta.level;
          request.summary = event.meta.summary;
        }
      });

      // normalize level
      request.level =
        request.level === LOG_LEVEL.INFO
          ? "info"
          : request.level === LOG_LEVEL.WARN
          ? "warn"
          : "error";

      // normalize summary
      request.summary = (request.summary || request.logs[0].m)
        .substring(0, 100)
        .trimRight();
    });
  }

  function filterSingleRequestForAccessLog() {
    return requestsAndButtons.find((request) => {
      if (request.type === "moreButton") {
        return false;
      }
      return request.logs[0].t === logTimestamp;
    });
  }

  function filterSingleRequestForLambda() {
    let found;

    found = requestsAndButtons.find((request) => {
      if (request.type === "moreButton") {
        return false;
      }
      const begin = request.logs[0].t;
      const end = request.logs[request.logs.length - 1].t;
      return begin <= logTimestamp && end >= logTimestamp;
    });

    requestsAndButtons = found ? [found] : [];
  }

  function filterErrorOnlyRequests() {
    // Note: tailing API cannot filter 4xx/5xx access log errors, need to rely on the frontend
    //       to filter it out. May as well filter for lambda log errors as well (not necessary).
    requestsAndButtons = requestsAndButtons.filter((request) => {
      if (request.type === "moreButton") {
        return true;
      }
      return request.level === "error";
    });
  }

  function hasMissingLogs() {
    return Object.values(urlResultsByUrl).some(
      (result) => result && result.hasMissingLogs
    );
  }

  function hasRandomOrder() {
    if (!isAccessLog || !isTailingBottomSection) {
      return false;
    }

    let refDate;
    let hasRandomOrder = null;
    requestsAndButtons.reverse().forEach((item) => {
      if (hasRandomOrder !== null) {
        return;
      }

      // Only check the last tailing section
      if (item.type === "moreButton") {
        hasRandomOrder = false;
        return;
      }

      if (refDate && item.logs[0].t > refDate + 100) {
        hasRandomOrder = true;
      }

      refDate = item.logs[0].t;
    });

    return hasRandomOrder || false;
  }

  function buildApiUrl({ loadMode, start, end, nextToken }) {
    const params =
      !region || region === ""
        ? {
            serviceName: service,
            mode: loadMode,
          }
        : {
            version: "v20200224",
            region,
            mode: loadMode,
          };
    if (apiId) {
      params.apiId = apiId;
    }
    if (lambda) {
      params.lambdaName = lambda;
    }
    if (start) {
      params.start = start;
    }
    if (end) {
      params.end = end;
    }
    if (nextToken) {
      params.nextToken = nextToken;
    }
    if (logStreamName) {
      params.logStreamName = logStreamName;
    }
    if (logTimestamp) {
      params.logTimestamp = logTimestamp;
    }
    if (filter) {
      params.searchFilter = filter;
    }
    if (isErrorOnly) {
      params.errorOnly = isErrorOnly;
    }

    return buildQueryStringUrl(
      isAccessLog
        ? `${appStageApiLink}/resources/api_logs`
        : `${appStageApiLink}/resources/lambda_logs`,
      params
    );
  }

  //////////////
  // Handlers //
  //////////////

  function handleSpanUpClick() {
    const start = topSection.start - SPAN_DURATION;
    const end = topSection.start;
    const loadMode = LOAD_MODE.RANGE;
    const section = {
      start,
      end,
      loadMode,
      urls: [buildApiUrl({ loadMode, start, end })],
    };
    setSections([section, ...sections]);
  }

  function handleSpanDownClick() {
    const start = bottomSection.end;
    const end = bottomSection.end + SPAN_DURATION;
    const loadMode = LOAD_MODE.RANGE;
    const section = {
      start,
      end,
      loadMode,
      urls: [buildApiUrl({ loadMode, start, end })],
    };
    setSections([...sections, section]);
  }

  function handleLoadMore(section) {
    const loadMode = section.loadMode;
    const nextToken = getNextToken(section);
    section.urls.push(buildApiUrl({ loadMode, nextToken }));
    setSections([...sections]);
  }

  function handleLogClick(id) {
    setExpanded((expanded) => ({
      ...expanded,
      [id]: !(expanded[id] ? true : false),
    }));
  }

  function handleTimezoneToggle() {
    setIsUTC(!isUTC);
  }

  function renderMissingLogsSign() {
    const analysis = hasRandomOrder()
      ? "random"
      : hasMissingLogs()
      ? "missing"
      : null;

    if (analysis === null) {
      return null;
    }

    return (
      <span className="missing">
        <FaIcon name="exclamation-triangle" />
        {analysis === "random"
          ? "Logs not in order due to high volume"
          : "Skipping logs due to high volume"}
      </span>
    );
  }

  function renderEmptySign() {
    // Note: show 'No requests' sign if span down triggered tailing
    const cs = isTailingBottomSection && !span ? "tailing" : "";
    return (
      <div className={`empty ${cs}`}>
        {isTailingBottomSection && !span ? (
          <span>
            <Glyphicon glyph="flash" />
            Listening for logs&hellip;
          </span>
        ) : (
          "No requests have been logged."
        )}
      </div>
    );
  }

  function renderLoadMoreButton(item) {
    return (
      <div key={`load-${item.id}`} className="load-more-control">
        <LoaderButton
          bsStyle="link"
          bsSize="xsmall"
          loading={item.isLoading}
          onClick={() => handleLoadMore(item.section)}
        >
          {!item.isLoading && <FaIcon name="ellipsis-v" />}
          Load more logs
        </LoaderButton>
      </div>
    );
  }

  function renderLogEntry(item) {
    const { id, logs, logStreamName, level, summary, meta } = item;
    const uid = logs[0].i;

    return (
      <RequestLogEntry
        id={id}
        key={uid}
        meta={meta}
        logs={logs}
        level={level}
        isUTC={isUTC}
        summary={summary}
        expanded={expanded[uid]}
        filterTerms={filterTerms}
        isAccessLog={isAccessLog}
        xrayBaseLink={xrayBaseLink}
        buildShareUrl={buildShareUrl}
        logStreamName={logStreamName}
        isSingleRequest={isSingleRequest}
        onClick={() => handleLogClick(uid)}
      />
    );
  }

  function renderSpanButton(type) {
    let copy;
    let time;
    let isEmpty;
    let onClick;
    let disabled;
    let isLoading;

    switch (type) {
      case "up":
        copy = "Show logs from 5 minutes ago";
        onClick = handleSpanUpClick;
        isLoading = isLoadingTopSpanUpSectionInitialPage();
        time = topSection.start;
        isEmpty = isTopSpanUpSectionEmpty();
        disabled = isInitialLoading;
        break;
      case "down":
        copy = "Show logs 5 minutes ahead";
        onClick = handleSpanDownClick;
        isLoading = isLoadingBottomSpanDownSectionInitialPage();
        time = bottomSection.end;
        isEmpty = isBottomSpanDownSectionEmpty();
        disabled = false;
        break;
      default:
    }

    const localTime = dateToFullTimeNoYearNoSecondWithLocalTimeZone(time);
    const utcTime = dateToFullTimeNoYearNoSecondWithUTCTimeZone(time);

    return (
      <div className={`span-control ${type}`}>
        <div>
          <TextButton
            className="time"
            onClick={handleTimezoneToggle}
            title={isUTC ? localTime : utcTime}
          >
            {isUTC ? utcTime : localTime}
          </TextButton>
          <LoaderButton
            bsStyle="link"
            bsSize="xsmall"
            onClick={onClick}
            loading={isLoading}
            disabled={disabled}
          >
            {copy}
            {isEmpty && !isLoading && (
              <span className="status">No logs found</span>
            )}
          </LoaderButton>
        </div>
      </div>
    );
  }

  function renderSingleRequestSign() {
    return (
      <div className="span-control single-request">
        <span>
          {filter
            ? `Viewing RequestId: ${filter.replace(/"/g, "")}`
            : "Viewing a single request"}
        </span>
      </div>
    );
  }

  function renderErrorOnlyFailedToParse() {
    return (
      <Alert bsStyle="danger">
        Failed to parse the API access log format. Showing all logs instead.
      </Alert>
    );
  }
  function renderErrorOnlyNotSupported() {
    return (
      <Alert bsStyle="warning">
        Inspecting error logs for metrics is not currently supported for the{" "}
        <b>{getLambdaLogRuntime()}</b> runtime. Showing all logs instead.
      </Alert>
    );
  }
  function renderErrorOnlySign() {
    return (
      <div className="span-control single-request">
        <span>Viewing errors for {span}</span>
      </div>
    );
  }

  function renderTailingSign() {
    return (
      <div className="span-control tailing">
        <span>
          <Glyphicon glyph="flash" />
          Listening for logs&hellip;
        </span>
        {renderMissingLogsSign()}
      </div>
    );
  }

  function renderInitialErrorSign() {
    let message;

    const errorData = getAPIError(urlError.error);
    const code = errorData && errorData.code;

    switch (code) {
      case 2011:
        message = "Access logs are disabled for this service.";
        break;
      case 8107:
        message = "There are no logs available for this Lambda function.";
        break;
      case "AWSLambdaFunctionNotExist":
        message =
          "It looks like the Lambda function has been removed. Please redeploy the stage and try again.";
        break;
      case "LogsTailInvalidFilter":
        message =
          "It looks like filter is invalid. Please check the filter syntax and try again.";
        break;
      default:
        message =
          "There was a problem loading the logs. Please refresh the page and try again.";
    }

    return (
      <div className="error-sign">
        <span>{message}</span>
      </div>
    );
  }

  function renderSingleRequestNotFoundSign() {
    return (
      <div className="error-sign">
        <span>Request {filter} could not be found.</span>
      </div>
    );
  }

  return (
    <div className="RequestLogPanel">
      {isInitialLoading && (
        <>
          {isSingleRequest && renderSingleRequestSign()}
          {isErrorOnly && renderErrorOnlySign()}
          {!isSingleRequest && !isErrorOnly && renderSpanButton("up")}

          {urlError && renderInitialErrorSign()}

          {!urlError && <LoadingSpinner />}
        </>
      )}

      {!isInitialLoading && (
        <>
          {urlError && (
            <ErrorAlert key={urlError.requestId} error={urlError.error} />
          )}

          {isErrorOnly &&
            isErrorOnlyFailedToParse() &&
            renderErrorOnlyFailedToParse()}
          {isErrorOnly &&
            isErrorOnlyNotSupported() &&
            renderErrorOnlyNotSupported()}
          {isSingleRequest && renderSingleRequestSign()}
          {isErrorOnly && renderErrorOnlySign()}
          {!isSingleRequest && !isErrorOnly && renderSpanButton("up")}

          {isSingleRequest && isEmpty
            ? renderSingleRequestNotFoundSign()
            : requestsAndButtons.map((item) =>
                item.type === "moreButton"
                  ? renderLoadMoreButton(item)
                  : renderLogEntry(item)
              )}

          {!isSingleRequest && isEmpty && renderEmptySign()}

          {!isSingleRequest &&
            !isErrorOnly &&
            !isTailingBottomSection &&
            !getNextToken(bottomSection) &&
            renderSpanButton("down")}

          {isTailingBottomSection && (
            <>
              {span && renderTailingSign()}
              {!span && !isEmpty && renderTailingSign()}
            </>
          )}
        </>
      )}
    </div>
  );
}
