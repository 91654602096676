import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import * as serviceWorker from "./serviceWorker";
import * as sentry from "./lib/sentryLib";
import config from "./config";
import "./index.css";

sentry.install();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "main",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: "stages",
        endpoint: config.stagesApiGateway.URL,
        region: config.stagesApiGateway.REGION,
      },
      {
        name: "apps",
        endpoint: config.appsApiGateway.URL,
        region: config.appsApiGateway.REGION,
      },
      {
        name: "v2",
        endpoint: config.v2ApiGateway.URL,
        region: config.v2ApiGateway.REGION,
      },
    ],
  },
  Analytics: {
    autoSessionRecord: false,
  },
});

ReactDOM.render(
  <Router>
    <ScrollToTop />
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
