import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import title from "../lib/titleLib";
import { captureException } from "../lib/sentryLib";
import { querystring } from "../lib/urlLib";
import LoadingSpinner from "../components/LoadingSpinner";
import ScreenHeader from "../components/ScreenHeader";
import TextButton from "../components/TextButton";
import config from "../config";
import "./GitHubInstallCallback.css";

class GitHubInstallCallback extends Component {
  constructor(props) {
    super(props);

    document.title = title("Connect GitHub");

    this.state = {
      loading: true,
      redirect: true,
      hasError: false,
      isBrowserError: false,
    };
  }

  componentDidMount() {
    const installationId = querystring("installation_id");

    // Validate installation id
    if (installationId === null || installationId === "") {
      this.setState({
        loading: false,
        hasError: true,
      });

      const error = querystring("error");
      const desc = querystring("error_description");

      captureException(new Error(error), (scope) => {
        scope.setExtra("error_description", desc);
      });
      return;
    }

    if (window.opener === null) {
      this.setState({
        loading: false,
        redirect: true,
      });
    }

    try {
      window.opener.postMessage(
        {
          type: "installation",
          source: config.postMessageSrc,
          provider: "github",
          installationId,
        },
        "*"
      );
      this.setState({ loading: false });

      window.close();
    } catch (e) {
      this.setState({
        loading: false,
        hasError: true,
        isBrowserError: true,
      });
      captureException(e);
    }
  }

  handleCloseClick = (event) => {
    window.close();
  };

  render() {
    const { loading, redirect, hasError, isBrowserError } = this.state;
    return (
      <div className="GitHubInstallCallback">
        <ScreenHeader border>Connect GitHub</ScreenHeader>
        {loading && <LoadingSpinner />}
        {!loading && (
          <>
            {redirect && <Redirect to="/" />}
            {!hasError && (
              <div className="content">
                <h2>Installed the Seed GitHub app successfully!</h2>
                <p>
                  Click{" "}
                  <TextButton onClick={this.handleCloseClick}>here</TextButton>{" "}
                  to close this window.
                </p>
              </div>
            )}
            {hasError && (
              <div className="content">
                <h2>
                  {isBrowserError
                    ? "There was a browser error while adding GitHub."
                    : "Sorry there was a problem adding GitHub."}
                  <br />
                  Please{" "}
                  <a href={`mailto:${config.contactEmail}`}>contact us</a> if
                  this problem persists.
                </h2>
                {isBrowserError ? (
                  <p>Or try adding GitHub through another browser.</p>
                ) : (
                  <p>
                    Click{" "}
                    <TextButton onClick={this.handleCloseClick}>
                      here to close this window
                    </TextButton>{" "}
                    and try again.
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default GitHubInstallCallback;
