import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import withCancel from "../components/ComponentWithCancel";
import ErrorAlert from "../components/ErrorAlert";
import MetricsPanel from "../components/MetricsPanel";
import ScreenHeader from "../components/ScreenHeader";
import LogDurationSelect from "../components/LogDurationSelect";
import withAppHeader from "../components/ComponentWithAppHeader";
import PermissionErrorPanel from "../components/PermissionErrorPanel";
import {
  parseDateString,
  dateToFullTimeWithUTCTimeZone,
  dateToFullTimeWithLocalTimeZone,
  dateToFullTimeNoYearWithUTCTimeZone as dateToUTC,
  dateToFullTimeNoYearWithLocalTimeZone as dateToLocal,
} from "../lib/timeLib";
import {
  querystring,
  getAppStageUrl,
  getAppStageApiLogsUrl,
  getAppStageApiLogsUrlOld,
  getAppStageApiMetricsUrl,
  getAppStageApiMetricsUrlOld,
  getAppStageLambdaLogsUrl,
  getAppStageLambdaLogsUrlOld,
  getAppStageLambdaMetricsUrl,
  getAppStageLambdaMetricsUrlOld,
  buildQueryStringUrl,
} from "../lib/urlLib";
import { appResourcesBreadcrumb } from "../lib/breadcrumbLib";
import { isAwsPermissionError } from "../lib/errorLib";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import "./Metrics.css";

const loadingDataMap = {
  api: {
    count: null,
    latency: null,
  },
  lambda: {
    invocations: null,
    duration: null,
  },
};

function Metrics(props) {
  let metrics = null;
  let isLoading = true;

  const { ownerId, appId, appStageId } = props.match.params;

  const type = querystring("type", props.location.search);
  const service = querystring("service", props.location.search);
  const stackName = querystring("stack", props.location.search);
  const lambda = querystring("lambda", props.location.search);
  const region = querystring("region", props.location.search);
  const apiId = querystring("apiId", props.location.search);
  const span = querystring("span", props.location.search) || "1 hour";

  const spanInfo = span && parseDateString(span);

  const invalidQueryParams = checkInvalidQueryParams();

  const isApi = type === "api";
  let error = null;

  const loadingData = isApi ? loadingDataMap.api : loadingDataMap.lambda;

  useEffect(() => {
    document.title = title(isApi ? "API Metrics" : "Lambda Metrics");
  }, [isApi]);

  const { data, error: metricsError } = useAPILoad(buildApiUrl());

  if (data) {
    isLoading = false;
    metrics = data.data;
  }

  if (metricsError && !isAwsPermissionError(metricsError)) {
    error = metricsError;
  }

  function getAppStageAPI() {
    return `/${ownerId}/${appId}/stages/${appStageId}`;
  }

  function checkInvalidQueryParams() {
    // Not able to parse span
    if (span && !spanInfo) {
      return true;
    }

    return false;
  }

  function buildApiUrl() {
    let end;
    let start;
    let duration;

    const hour = 60 * 60 * 1000;

    // Note: only support region version starting April 1, 2020
    const params = !region
      ? { serviceName: service }
      : { version: "v20200224", region };

    if (span === "" || !spanInfo) {
      duration = hour;
    } else {
      switch (spanInfo.type) {
        case "absolute":
          start = spanInfo.ts - hour / 2;
          end = spanInfo.ts + hour / 2;
          break;
        case "relative":
          duration = spanInfo.duration;
          break;
        case "range":
          start = spanInfo.startTs;
          end = spanInfo.endTs;
          break;
        default:
          duration = hour;
          break;
      }
    }

    if (duration) {
      params.duration = duration;
    }
    if (lambda) {
      params.lambdaName = lambda;
    }
    if (apiId) {
      params.apiId = apiId;
    }
    if (start) {
      params.start = start;
    }
    if (end) {
      params.end = end;
    }

    return buildQueryStringUrl(
      isApi
        ? `${getAppStageAPI()}/resources/api_metrics`
        : `${getAppStageAPI()}/resources/lambda_metrics`,
      params
    );
  }

  function buildUrl(span) {
    if (!region) {
      return isApi
        ? getAppStageApiMetricsUrlOld(
            ownerId,
            appId,
            appStageId,
            service,
            stackName,
            { span }
          )
        : getAppStageLambdaMetricsUrlOld(
            ownerId,
            appId,
            appStageId,
            service,
            lambda,
            { span }
          );
    }

    return isApi
      ? getAppStageApiMetricsUrl(
          ownerId,
          appId,
          appStageId,
          region,
          stackName,
          apiId,
          { span }
        )
      : getAppStageLambdaMetricsUrl(
          ownerId,
          appId,
          appStageId,
          region,
          lambda,
          { span }
        );
  }

  //////////////
  // Handlers //
  //////////////

  function handleDurationChange(newSpan) {
    // Normalize span
    if (newSpan !== "") {
      const parsed = parseDateString(newSpan);
      if (!parsed) {
        newSpan = "";
      } else if (parsed.type === "absolute") {
        newSpan = parsed.isUTC ? dateToUTC(parsed.ts) : dateToLocal(parsed.ts);
      } else if (parsed.type === "range") {
        newSpan = parsed.isUTC
          ? `${dateToUTC(parsed.startTs)} - ${dateToUTC(parsed.endTs)}`
          : `${dateToLocal(parsed.startTs)} - ${dateToLocal(parsed.endTs)}`;
      } else if (parsed.type === "relative") {
        newSpan = parsed.normalized;
      }
    }

    props.history.push(buildUrl(newSpan || undefined));
  }

  function handleOnPointClick(i, key) {
    const point = metrics[key].points[i];
    // Note: there is a delay in when a metric is logged. Display logs up to 1 minute before the
    //       the start time of the metric timestamp.
    const fullStartTime = spanInfo.isUTC
      ? dateToFullTimeWithUTCTimeZone(point.t - 60000)
      : dateToFullTimeWithLocalTimeZone(point.t - 60000);
    const fullEndTime = spanInfo.isUTC
      ? dateToFullTimeWithUTCTimeZone(point.t + data.period)
      : dateToFullTimeWithLocalTimeZone(point.t + data.period);

    const params = {
      span: `${fullStartTime} - ${fullEndTime}`,
      errorOnly: key === "error" ? "true" : "",
    };
    const url = !region
      ? isApi
        ? getAppStageApiLogsUrlOld(
            ownerId,
            appId,
            appStageId,
            service,
            stackName,
            params
          )
        : getAppStageLambdaLogsUrlOld(
            ownerId,
            appId,
            appStageId,
            service,
            lambda,
            params
          )
      : isApi
      ? getAppStageApiLogsUrl(
          ownerId,
          appId,
          appStageId,
          region,
          stackName,
          apiId,
          params
        )
      : getAppStageLambdaLogsUrl(
          ownerId,
          appId,
          appStageId,
          region,
          lambda,
          params
        );

    props.history.push(url);
  }

  return (
    <div className="Metrics">
      <ScreenHeader
        breadcrumb={appResourcesBreadcrumb(props)}
        action={
          !invalidQueryParams && (
            <LogDurationSelect
              value={span}
              key={`duration-${span}`}
              onChange={handleDurationChange}
            />
          )
        }
      >
        {isApi ? stackName : lambda}
      </ScreenHeader>

      {error && <ErrorAlert error={error} />}

      <PermissionErrorPanel
        type="metrics"
        error={
          metricsError && isAwsPermissionError(metricsError)
            ? metricsError
            : null
        }
      >
        Add a couple of permissions to view metrics
      </PermissionErrorPanel>

      {invalidQueryParams && (
        <div className="error-invalid">
          <div className="content">
            <h2>There was a problem loading this page.</h2>
            <p>
              Please check the URL and try again. Or{" "}
              <Link to={getAppStageUrl(ownerId, appId, appStageId)}>
                click here
              </Link>{" "}
              to view all the deployed resources.
            </p>
          </div>
        </div>
      )}

      {!invalidQueryParams && (
        <MetricsPanel
          loading={isLoading}
          onPointClick={handleOnPointClick}
          metrics={isLoading ? loadingData : metrics}
        />
      )}
    </div>
  );
}

export default withAppHeader(withCancel(Metrics));
