import React, { useState } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import TextButton from "./TextButton";
import SectionInfo from "./SectionInfo";
import LoaderButton from "./LoaderButton";
import { useFormReducer } from "../lib/hooksLib";
import "./BuildImageSettingsPanel.css";

const defaultImage = "auto-detect";

const helpUrl = "https://seed.run/docs/seed-build-images";

export default function BuildImageSettingsPanel({
  updated = false,
  updating = false,
  activeBuildImages = [],
  customBuildImages = [],
  buildImage = defaultImage,
  deprecatedBuildImages = [],

  onUpdateClick,
}) {
  const [showStatus, setShowStatus] = useState(updated);
  const [
    { values: formData, isDirty },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ buildImage });

  const editing = isDirty.buildImage;

  function handleCancelClick() {
    setShowStatus(false);
    formDispatch({ type: "reset" });
  }

  function handleUpdateClick(event) {
    event.preventDefault();

    onUpdateClick(
      formData.buildImage === defaultImage ? undefined : formData.buildImage
    );
  }

  function renderForm() {
    return (
      <Form className="form" onSubmit={handleUpdateClick}>
        <FormGroup controlId="buildImage">
          <FormControl
            bsSize="large"
            disabled={updating}
            componentClass="select"
            value={formData.buildImage}
            onChange={handleFieldChange}
          >
            {buildImage === defaultImage && (
              <>
                <option value={defaultImage}>Auto-Detect</option>
                <option disabled></option>
              </>
            )}
            <option disabled>Active Images</option>
            {activeBuildImages.map((per, i) => (
              <option key={i} value={per.image}>
                {per.name}
              </option>
            ))}
            {deprecatedBuildImages.length > 0 && (
              <>
                <option disabled></option>
                <option disabled>Deprecated Images</option>
                {deprecatedBuildImages.map((per, i) => (
                  <option key={i} value={per.image}>
                    {per.name}
                  </option>
                ))}
              </>
            )}
            {customBuildImages.length > 0 && (
              <>
                <option disabled></option>
                <option disabled>Custom Images</option>
                {customBuildImages.map((per, i) => (
                  <option key={i} value={per.image}>
                    {per.name}
                  </option>
                ))}
              </>
            )}
          </FormControl>
        </FormGroup>
        {!editing && showStatus && (
          <p className="status">Build image updated!</p>
        )}
        {editing && (
          <div className="controls">
            <LoaderButton
              type="submit"
              bsSize="large"
              loading={updating}
              onClick={handleUpdateClick}
            >
              Update
            </LoaderButton>
            <TextButton onClick={handleCancelClick}>Cancel</TextButton>
          </div>
        )}
      </Form>
    );
  }

  return (
    <div className="BuildImageSettingsPanel">
      <SectionInfo
        label="Build Image"
        description={
          <span>
            The build image used for this service.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn more about build images.
            </a>
          </span>
        }
      >
        {renderForm()}
      </SectionInfo>
    </div>
  );
}
