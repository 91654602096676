import React, { useReducer } from "react";
import NewAppDetectServicePanel from "../../components/NewAppDetectServicePanel";
import { getAPIError, errorHandler } from "../../lib/errorLib";
import config from "../../config";

const defaultState = {
  searchError: null,
  serviceName: null,
  addingPath: false,
  selectedPath: null,
  isManualAdd: false,
  isManuallyAdding: false,
  serviceFramework: undefined,
};

function reducer(state, action) {
  switch (action.type) {
    case "adding":
      return {
        ...state,
        addingPath: true,
        selectedPath: action.path,
      };
    case "added":
      return {
        ...state,
        addingPath: false,
        serviceName: action.serviceName,
        serviceFramework: action.serviceFramework,
      };
    case "add-error":
      return {
        ...state,
        addingPath: false,
        searchError: action.searchError,
        serviceFramework: action.serviceFramework,
      };
    case "confirming":
      return {
        ...state,
        isManuallyAdding: true,
      };
    case "confirmed":
      return {
        ...state,
        isManuallyAdding: false,
        serviceName: action.name,
        selectedPath: action.path,
        serviceFramework: action.framework,
      };
    case "change":
      return {
        ...state,
        searchError: null,
        selectedPath: null,
      };
    case "manual":
      return {
        ...state,
        isManualAdd: true,
        selectedPath: action.clear
          ? defaultState.selectedPath
          : state.selectedPath,
        serviceFramework: action.clear
          ? defaultState.serviceFramework
          : state.serviceFramework,
      };
    case "manual-cancel":
      return {
        ...state,
        isManualAdd: false,
      };
    default:
      return state;
  }
}

export default function NewAppDetectService({
  disabled = false,
  searching = true,
  confirmed = false,
  detectError = null,
  servicePaths = null,

  onEditClick,
  onGetSlsInfo,
  onConfirmClick,
  onCancelSearchClick,
}) {
  const [
    {
      addingPath,
      searchError,
      serviceName,
      isManualAdd,
      selectedPath,
      isManuallyAdding,
      serviceFramework,
    },
    dispatch,
  ] = useReducer(reducer, defaultState);

  function handleEditPathClick(event) {
    onEditClick();
  }

  async function handleAddServiceClick(event, path) {
    let slsInfo;

    dispatch({ type: "adding", path });

    try {
      slsInfo = await onGetSlsInfo(path);
    } catch (e) {
      const serviceSearchErrors = config.serviceSearchErrors;
      const errorObject = getAPIError(e);

      let serviceFramework;
      let searchError = serviceSearchErrors.UNKNOWN;

      if (errorObject) {
        const { code } = errorObject;

        switch (code) {
          case serviceSearchErrors.GENERIC_NOT_FOUND:
          case serviceSearchErrors.SLS_NOT_FOUND:
          case serviceSearchErrors.SLS_CANNOT_PARSE:
          case serviceSearchErrors.SLS_INVALID_SERVICE_NAME:
          case serviceSearchErrors.SLS_JS_NOT_SUPPORTED:
          case serviceSearchErrors.SST_NOT_FOUND:
          case serviceSearchErrors.SST_CANNOT_PARSE:
          case serviceSearchErrors.SST_INVALID_SERVICE_NAME:
            searchError = code;
            break;
          default:
            searchError = serviceSearchErrors.UNKNOWN;
        }

        serviceFramework =
          searchError === serviceSearchErrors.SLS_NOT_FOUND ||
          searchError === serviceSearchErrors.SLS_CANNOT_PARSE ||
          searchError === serviceSearchErrors.SLS_INVALID_SERVICE_NAME ||
          searchError === serviceSearchErrors.SLS_JS_NOT_SUPPORTED
            ? "sls"
            : "sst";
      }

      dispatch({ type: "add-error", searchError, serviceFramework });

      return;
    }

    const { service_name, serviceFramework } = slsInfo;

    try {
      await onConfirmClick(service_name, path, serviceFramework);

      dispatch({ type: "added", serviceName: service_name, serviceFramework });
    } catch (e) {
      errorHandler(e);
    }
  }

  async function handleConfirmPath(event, { name, path, framework }) {
    dispatch({ type: "confirming" });

    try {
      await onConfirmClick(name, path, framework);

      dispatch({ type: "confirmed", name, path, framework });
    } catch (e) {
      errorHandler(e);
    }
  }

  function handleChangeServiceClick(event) {
    dispatch({ type: "change" });
  }

  function handleCancelEditServiceClick(event) {
    dispatch({ type: "manual-cancel" });
  }

  function handleManualAddServiceClick(event, clear) {
    dispatch({ type: "manual", clear });
  }

  return (
    <NewAppDetectServicePanel
      disabled={disabled}
      adding={addingPath}
      searching={searching}
      confirmed={confirmed}
      manualAdd={isManualAdd}
      searchError={searchError}
      detectError={detectError}
      serviceName={serviceName}
      selectedPath={selectedPath}
      servicePaths={servicePaths}
      manuallyAdding={isManuallyAdding}
      serviceFramework={serviceFramework}
      onEditClick={handleEditPathClick}
      onAddClick={handleAddServiceClick}
      onConfirmClick={handleConfirmPath}
      onChangeClick={handleChangeServiceClick}
      onCancelSearchClick={onCancelSearchClick}
      onCancelClick={handleCancelEditServiceClick}
      onManualAddClick={handleManualAddServiceClick}
    />
  );
}
