import React from "react";
import { ButtonGroup } from "react-bootstrap";
import "./StyledButtonGroup.css";

export default function StyledButtonGroup({ className = "", ...props }) {
  return (
    <ButtonGroup className={`StyledButtonGroup ${className}`}>
      {props.children}
    </ButtonGroup>
  );
}
