import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import StageRemoveServiceTable from "../components/StageRemoveServiceTable";
import ItemRemoveStatusPanel from "../components/ItemRemoveStatusPanel";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import LoadingSpinner from "../components/LoadingSpinner";
import ScreenHeader from "../components/ScreenHeader";
import ErrorAlert from "../components/ErrorAlert";
import useAPILoad from "../lib/apiLoadLib";
import { useRemoveStatusReducer } from "../lib/hooksLib";
import { errorHandler, getLoadError } from "../lib/errorLib";
import { appSettingsBreadcrumb } from "../lib/breadcrumbLib";
import { getAppUrl, getStageServiceRemoveLogUrl } from "../lib/urlLib";
import title from "../lib/titleLib";
import "./StageRemove.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
  StageNotExist: "STAGE_NOT_FOUND",
};

function StageRemove(props) {
  let isLoading = true;
  let loadError = null;

  let appStatus;
  let isCleaningUp;

  const { ownerId, appId, appStageId } = props.match.params;

  const [isInitialRemoving, setIsInitialRemoving] = useState(false);
  const [removeStatus, statusDispatch] = useRemoveStatusReducer();

  useEffect(() => {
    document.title = title(appStageId);
  }, [appStageId]);

  const {
    error,
    data: removeInfo,
    reload: reloadRemoveInfo,
  } = useAPILoad(
    `/${ownerId}/${appId}/stages/${appStageId}/delete_status`,
    (path) => props.invokeAppsApig({ path })
  );

  // Tracks if this is in the removing state initially
  useEffect(() => {
    if (removeInfo) {
      setIsInitialRemoving(true);
    }
  }, [removeInfo]);

  if (removeInfo !== null) {
    isLoading = false;

    const servicesData = getServicesBeingRemoved(removeInfo.servicesData);

    appStatus = removeInfo.app.status;
    isCleaningUp = servicesData.length === 0;

    removeInfo.servicesData = servicesData;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  function getServicesBeingRemoved(servicesData) {
    return servicesData.filter((serviceDatum) => serviceDatum.stage);
  }

  /////////
  // API //
  /////////

  function removeAppStage(quickRemove) {
    return props.invokeAppsApig({
      path: `/${ownerId}/${appId}/stages/${appStageId}`,
      method: "DELETE",
      body: { mode: quickRemove ? "quick" : undefined },
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleAppStageRemove(quickRemove) {
    try {
      await removeAppStage(quickRemove);
      await reloadRemoveInfo();
      statusDispatch({ type: quickRemove ? "quick-removed" : "removed" });
    } catch (e) {
      errorHandler(e);
    }
  }

  function handleRemoveClick(e) {
    statusDispatch({ type: "removing" });
    handleAppStageRemove(false);
  }

  function handleShowRetryClick(e) {
    statusDispatch({ type: "show-retry" });
  }

  function handleHideRetryClick(e) {
    statusDispatch({ type: "hide-retry" });
  }

  function handleQuickRemoveClick(e) {
    statusDispatch({ type: "quick-removing" });
    handleAppStageRemove(true);
  }

  ////////////
  // Render //
  ////////////

  return (
    <div className="StageRemove">
      <ScreenHeader border breadcrumb={appSettingsBreadcrumb(props)}>
        {appStageId}
      </ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {!isLoading && loadError && isInitialRemoving && (
        <Redirect to={getAppUrl(ownerId, appId)} />
      )}

      {!isLoading && loadError && !isInitialRemoving && (
        <ContainerErrorPanel
          type="stageRemove"
          code={loadError}
          context={{
            appName: appId,
            stageName: appStageId,
          }}
        />
      )}

      {error && !loadError && <ErrorAlert error={error} />}

      {!isLoading && !loadError && removeInfo && (
        <>
          <ItemRemoveStatusPanel
            type="stage"
            appStatus={appStatus}
            retry={removeStatus.retry}
            isCleaningUp={isCleaningUp}
            status={removeInfo.overallStatus}
            removing={removeStatus.removing}
            onRemoveClick={handleRemoveClick}
            onShowRetryClick={handleShowRetryClick}
            onHideRetryClick={handleHideRetryClick}
            quickRemoving={removeStatus.quickRemoving}
            onQuickRemoveClick={handleQuickRemoveClick}
            errorMessage={removeInfo.overallErrorMessage}
          />
          <StageRemoveServiceTable
            services={removeInfo.servicesData.map((serviceDatum) => ({
              serviceType: serviceDatum.service.serviceType,
              service_name: serviceDatum.service.service_name,
              current_action: serviceDatum.stage.current_action,
              errorMessage: serviceDatum.stage.removeErrorMessage,
              link: getStageServiceRemoveLogUrl(
                ownerId,
                appId,
                appStageId,
                serviceDatum.service.service_name
              ),
            }))}
          />
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(StageRemove));
