import React, { Component } from "react";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import SectionInfo from "../components/SectionInfo";
import ScreenHeader from "../components/ScreenHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import NewAppRepoForm from "../components/NewAppRepoForm";
import NewIamRoleModal from "../components/NewIamRoleModal";
import NewAppConfigForm from "../components/NewAppConfigForm";
import NewAppDetectService from "./widgets/NewAppDetectService";
import { errorHandler, getAPIError, isAPIErrorWithCode } from "../lib/errorLib";
import { makeCancelable } from "../lib/promiseLib";
import { safeParse } from "../lib/jsonLib";
import title from "../lib/titleLib";
import config from "../config";
import "./NewApp.css";

const titleCopy = "Add a New App";

const defaultGitProviderProps = {
  nextToken: "",
  repoFormKey: 0,
  gitRepos: null,
  gitProvider: null,
  selectedRepo: null,
  installations: null,
  isLoadingMoreRepo: false,
  activeInstallation: null,
};

const defaultSearchPathProps = {
  serviceKey: 0,
  isSearching: true,
  serviceName: null,
  selectedPath: null,
  serviceFramework: null,
  servicePaths: null,
  isPathConfirmed: false,
  servicePathsError: null,
};
const defaultConfigProps = {
  configFormKey: 0,
  isCreating: false,
  createError: null,
};
const defaultPolicyState = {
  policyInfo: null,
  loadedPolicyInfo: null,
  hasPolicyUpdated: false,

  policyModalKey: 0,
  policyEditing: false,
  policyUpdating: false,
  showPolicyModal: false,
  policyUpdateError: null,
};

class NewApp extends Component {
  cancelableReq = null;

  state = {
    owner: null,
    apps: null,
    isLoading: true,

    ...defaultPolicyState,

    ...defaultGitProviderProps,
    ...defaultSearchPathProps,
    ...defaultConfigProps,
  };

  async componentDidMount() {
    window.addEventListener("message", this.handlePostMessage);

    document.title = title(titleCopy);

    try {
      const { owner, apps } = await this.getOrgApps();

      this.setState({
        owner,
        apps,
        isLoading: false,
      });
    } catch (e) {
      errorHandler(e);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handlePostMessage);
  }

  getDefaultSearchPathProps() {
    return {
      ...defaultSearchPathProps,
      serviceKey: this.state.serviceKey + 1,
    };
  }

  getDefaultConfigProps() {
    return {
      ...defaultConfigProps,
      configFormKey: this.state.configFormKey + 1,
    };
  }

  /////////
  // API //
  /////////

  getUserInfo() {
    return this.props.invokeApig({ path: "/" });
  }

  getOrgApps() {
    const { ownerId } = this.props.match.params;
    return this.props.invokeAppsApig({ path: `/${ownerId}` });
  }

  createApp(repo, formFields) {
    const ownerId = this.props.match.params.ownerId;

    return this.props.invokeAppsApig({
      path: `/${ownerId}?version=v20200823`,
      method: "POST",
      body: {
        ...formFields,
        installation_id: repo.installation_id,
        git_provider: repo.git_provider,
        git_owner: repo.git_owner,
        git_owner_id: repo.git_owner_id,
        git_repo: repo.git_repo,
        git_id: repo.git_id,
        name: this.state.serviceName,
        path: this.state.selectedPath,
        serviceFramework: this.state.serviceFramework,
      },
    });
  }

  getRepos(data) {
    return this.props.invokeApig({
      path: "/get_repositories",
      queryStringParameters: {
        git_provider: data.gitProvider,
        next_token: data.nextToken,
        installation_id: data.installationId,
      },
    });
  }

  getServicePaths(repo) {
    return this.props.invokeApig({
      path: "/get_service_paths",
      queryStringParameters: {
        installation_id: repo.installation_id,
        git_provider: repo.git_provider,
        git_id: repo.git_id,
        git_owner: repo.git_owner,
        git_owner_id: repo.git_owner_id,
        git_repo: repo.git_repo,
        git_branch: repo.default_branch,
      },
    });
  }

  getSlsInfo(repo, path) {
    return this.props.invokeApig({
      path: "/get_service_info",
      queryStringParameters: {
        installation_id: repo.installation_id,
        git_provider: repo.git_provider,
        git_id: repo.git_id,
        git_owner: repo.git_owner,
        git_owner_id: repo.git_owner_id,
        git_repo: repo.git_repo,
        git_branch: repo.default_branch,
        path,
      },
    });
  }

  getIamPolicyInfo(serviceFramework) {
    const { ownerId } = this.props.match.params;
    return this.props.invokeV2Apig({
      path: `/orgs/${ownerId}/iam_role`,
      queryStringParameters: {
        serviceFramework,
      },
    });
  }

  setIamPolicyInfo(iam_policy, serviceFramework) {
    const { ownerId } = this.props.match.params;
    return this.props.invokeV2Apig({
      path: `/orgs/${ownerId}/iam_role`,
      method: "POST",
      body: { iam_policy, serviceFramework },
    });
  }

  /////////////////////////////
  // Handlers - Git Provider //
  /////////////////////////////

  handlePostMessage = async (event) => {
    const { data, origin } = event;

    if (
      origin !== window.location.origin ||
      data.source !== config.postMessageSrc
    ) {
      return;
    }

    switch (data.type) {
      case "oauth_code":
        if (data.hasOwnProperty("provider") && data.hasOwnProperty("code")) {
          this.handlePostMessageGit(data);
        }
        return;
      case "installation":
        if (
          data.hasOwnProperty("provider") &&
          data.hasOwnProperty("installationId")
        ) {
          this.handlePostMessageGit(data);
        }
        return;
      default:
        return;
    }
  };

  handleLoadRepos = async (gitProvider, installationId) => {
    try {
      const {
        next_token,
        repositories,
        installations,
        activeInstallationId,
      } = await this.getRepos({
        gitProvider,
        ...(installationId && { installationId }),
      });

      const activeInstallation = installations
        ? installations.find(
            (install) => install.installationId === activeInstallationId
          )
        : null;

      this.setState({
        nextToken: next_token,
        gitRepos: repositories,
        ...(installations && { installations }),
        ...(activeInstallation && { activeInstallation }),
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  handlePostMessageGit = async ({ provider, installationId }) => {
    this.setState({
      ...defaultGitProviderProps,
      gitProvider: provider,
      repoFormKey: this.state.repoFormKey + 1,
    });

    await this.handleLoadRepos(provider, installationId);
  };

  handleGitOrgChange = async (installationId) => {
    this.setState({
      ...defaultGitProviderProps,
      gitProvider: this.state.gitProvider,
      repoFormKey: this.state.repoFormKey + 1,
    });

    await this.handleLoadRepos(this.state.gitProvider, installationId);
  };

  handleLoadMoreClick = async (event) => {
    this.setState({ isLoadingMoreRepo: true });

    try {
      const results = await this.getRepos({
        gitProvider: this.state.gitProvider,
        nextToken: this.state.nextToken,
      });
      this.setState({
        isLoadingMoreRepo: false,
        nextToken: results.next_token,
        gitRepos: this.state.gitRepos.concat(results.repositories),
      });
    } catch (e) {
      this.setState({ isLoadingMoreRepo: false });
      errorHandler(e);
    }
  };

  handleRepoConfirmClick = (selectedRepo) => {
    this.setState({ selectedRepo }, this.handleGetServicePaths);
  };

  handleChangeProviderClick = async () => {
    this.setState({
      ...defaultGitProviderProps,
      ...this.getDefaultSearchPathProps(),
      ...this.getDefaultConfigProps(),
    });
  };

  handleChangeRepoClick = async (event) => {
    this.setState({
      // partially reset step 1
      selectedRepo: null,

      ...this.getDefaultSearchPathProps(),
      ...this.getDefaultConfigProps(),
    });
  };

  ////////////////////////////
  // Handlers - Search Path //
  ////////////////////////////

  handleCancelGetServicePaths = (event) => {
    if (this.cancelableReq) {
      this.cancelableReq.cancel();
    }

    this.setState({
      isSearching: false,
      servicePathsError: "cancel",
    });
  };

  handleGetServicePaths = async () => {
    try {
      this.cancelableReq = makeCancelable(
        this.getServicePaths(this.state.selectedRepo)
      );
      const { paths } = await this.cancelableReq.promise;

      if (paths.length && paths.length > 0) {
        this.setState({
          isSearching: false,
          servicePaths: paths,
        });
        return;
      }
    } catch (e) {}

    this.setState({
      servicePaths: [],
      isSearching: false,
      servicePathsError: "error",
    });
  };

  handleConfirmPath = async (serviceName, selectedPath, serviceFramework) => {
    await this.loadPolicyInfo(serviceFramework);

    this.setState({
      serviceName,
      selectedPath,
      serviceFramework,
      isPathConfirmed: true,
    });
  };

  handleGetSlsInfo = async (path) => {
    const repo = this.state.selectedRepo;
    return await this.getSlsInfo(repo, path);
  };

  handleEditPathClick = (event) => {
    this.setState({
      isPathConfirmed: false,
      ...this.getDefaultConfigProps(),
    });
  };

  ///////////////////////
  // Handlers - Config //
  ///////////////////////

  handleCreateClick = async (event, formFields) => {
    this.setState({ isCreating: true });

    try {
      const repo = this.state.selectedRepo;
      const { app } = await this.createApp(repo, formFields);

      this.props.history.push(`/${app.owner_name}/${app.slug}`);
    } catch (e) {
      const createError = getAPIError(e);

      if (createError && createError.code === 5013) {
        createError.context = safeParse(createError.context);
        this.setState({ createError });
      } else {
        errorHandler(e);
      }

      this.setState({ isCreating: false });
    }
  };

  ///////////////////////
  // Handlers - Policy //
  ///////////////////////

  async loadPolicyInfo(serviceFramework) {
    const policyInfo = await this.getIamPolicyInfo(serviceFramework);

    this.setState({
      policyInfo,
      loadPolicyInfo: policyInfo,
    });
  }

  handleShowIamRoleClick = (event) => {
    this.setState((prevState) => ({
      showPolicyModal: true,
      policyModalKey: prevState.policyModalKey + 1,
    }));
  };

  handleCustomizePolicyClick = (event) => {
    this.setState((prevState) => ({
      policyEditing: true,
    }));
  };

  handleUpdateCancelPolicyClick = (event) => {
    this.setState({
      policyEditing: false,
      policyUpdateError: null,
    });
  };

  handlePolicyUpdateClick = async (event, policy) => {
    this.setState({
      policyUpdateError: null,
      policyUpdating: true,
    });

    try {
      const policyInfo = await this.setIamPolicyInfo(
        policy,
        this.state.serviceFramework
      );
      this.setState({
        policyInfo,
        hasPolicyUpdated: true,
        policyEditing: false,
        policyUpdating: false,
      });
    } catch (e) {
      if (isAPIErrorWithCode(e, 5014)) {
        this.setState({
          policyUpdating: false,
          policyUpdateError: "Please enter a valid IAM policy.",
        });
      } else {
        errorHandler(e);
      }
    }
  };

  handleHideIamRolePanelClick = (event) => {
    this.setState((prevState) => ({
      ...defaultPolicyState,
      policyInfo: prevState.loadPolicyInfo,
      policyModalKey: prevState.policyModalKey,
    }));
  };

  ////////////
  // Render //
  ////////////

  render() {
    const {
      isLoading,
      policyInfo,
      policyEditing,
      policyUpdating,
      policyModalKey,
      showPolicyModal,
      serviceFramework,
      hasPolicyUpdated,
      policyUpdateError,
    } = this.state;

    const isStep1Done = this.state.selectedRepo !== null;
    const isStep2Done = this.state.isPathConfirmed;

    return (
      <div className="NewApp">
        <ScreenHeader border>Add a New App</ScreenHeader>
        <h4>Let&apos;s automate your Serverless deployments.</h4>

        {isLoading && <LoadingSpinner />}

        {!isLoading && (
          <>
            <div className="forms-container">
              <SectionInfo
                className="repo"
                label="1. Connect a repo"
                description="Select a Git repo to connect to your app."
              >
                <NewAppRepoForm
                  isEnabled={!isStep1Done}
                  org={this.state.owner}
                  repos={this.state.gitRepos}
                  key={this.state.repoFormKey}
                  orgs={this.state.installations}
                  nextToken={this.state.nextToken}
                  gitProvider={this.state.gitProvider}
                  onOrgChange={this.handleGitOrgChange}
                  ownerId={this.props.match.params.ownerId}
                  onLoadMoreClick={this.handleLoadMoreClick}
                  currentOrg={this.state.activeInstallation}
                  isLoadingMore={this.state.isLoadingMoreRepo}
                  onConfirmClick={this.handleRepoConfirmClick}
                  onChangeRepoClick={this.handleChangeRepoClick}
                  onChangeProviderClick={this.handleChangeProviderClick}
                />
              </SectionInfo>

              <SectionInfo
                label="2. Add a service"
                className={`service ${!isStep1Done ? "inactive" : ""}`}
                description="Start by adding a service from your app. You can always add other services later."
              >
                <NewAppDetectService
                  disabled={!isStep1Done}
                  key={this.state.serviceKey}
                  searching={this.state.isSearching}
                  confirmed={this.state.isPathConfirmed}
                  servicePaths={this.state.servicePaths}
                  detectError={this.state.servicePathsError}
                  onGetSlsInfo={this.handleGetSlsInfo}
                  onEditClick={this.handleEditPathClick}
                  onConfirmClick={this.handleConfirmPath}
                  onCancelSearchClick={this.handleCancelGetServicePaths}
                />
              </SectionInfo>

              <SectionInfo
                label="3. Configure the stages"
                className={`configure ${!isStep2Done ? "inactive" : ""}`}
                description="Add a dev and prod stage for your app. You can add additional stages later."
              >
                <NewAppConfigForm
                  apps={this.state.apps.filter(
                    (app) => !["deleting", "delete_failed"].includes(app.status)
                  )}
                  disabled={!isStep2Done}
                  key={this.state.configFormKey}
                  isCreating={this.state.isCreating}
                  createError={this.state.createError}
                  onCreateClick={this.handleCreateClick}
                  onShowIamRoleClick={this.handleShowIamRoleClick}
                />
              </SectionInfo>
            </div>

            <NewIamRoleModal
              key={policyModalKey}
              show={showPolicyModal}
              editing={policyEditing}
              updating={policyUpdating}
              hasUpdated={hasPolicyUpdated}
              updateError={policyUpdateError}
              serviceFramework={serviceFramework}
              onUpdateClick={this.handlePolicyUpdateClick}
              onCloseClick={this.handleHideIamRolePanelClick}
              onCustomizeClick={this.handleCustomizePolicyClick}
              seedPolicy={policyInfo && policyInfo.seedTemplate}
              cloudFormationUrl={policyInfo && policyInfo.createUrl}
              onUpdateCancelClick={this.handleUpdateCancelPolicyClick}
              serverlessPolicy={policyInfo && policyInfo.deployTemplate}
            />
          </>
        )}
      </div>
    );
  }
}

export default withAppHeader(withCancel(NewApp));
