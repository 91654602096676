import React from "react";
import "./SectionDescriptionLabel.css";

const mainClassname = "SectionDescriptionLabel";

export default ({ className, ...props }) => (
  <p
    className={className ? `${mainClassname} ${className}` : mainClassname}
    {...props}
  >
    {props.children}
  </p>
);
