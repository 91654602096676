import React, { useEffect, useReducer } from "react";
import { LinkContainer } from "react-router-bootstrap";
import withCancel from "../components/ComponentWithCancel";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import withAppHeader from "../components/ComponentWithAppHeader";
import SectionGroupPanel from "../components/SectionGroupPanel";
import SettingsLinksList from "../components/SettingsLinksList";
import TransferAppPanel from "../components/TransferAppPanel";
import AppUnitTestPanel from "../components/AppUnitTestPanel";
import NewIamRoleModal from "../components/NewIamRoleModal";
import LoadingSpinner from "../components/LoadingSpinner";
import AppBadgePanel from "../components/AppBadgePanel";
import AppRepoPanel from "../components/AppRepoPanel";
import RightChevron from "../components/RightChevron";
import ScreenHeader from "../components/ScreenHeader";
import LoaderButton from "../components/LoaderButton";
import SectionInfo from "../components/SectionInfo";
import AppNameForm from "../components/AppNameForm";
import ErrorAlert from "../components/ErrorAlert";
import AppCiPanel from "../components/AppCiPanel";
import CustomDomain from "./widgets/CustomDomain";
import ItemDelete from "./widgets/ItemDelete";
import AppIam from "./widgets/AppIam";
import {
  errorHandler,
  getLoadError,
  isAPIErrorWithCode,
} from "../lib/errorLib";
import { getAppIssuesSettingsUrl } from "../lib/urlLib";
import { usePolicyReducer } from "../lib/hooksLib";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import config from "../config";
import "./AppSettings.css";

const phasesHelpUrl = "https://seed.run/docs/issues-and-alerts";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
};

const transferAppDefaultState = {
  orgs: null,
  appName: "",
  showing: false,
  loading: false,
  transferring: false,
};

function transferAppReducer(state, action) {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: true,
      };
    case "loading-failed":
      return {
        ...state,
        loading: false,
      };
    case "show":
      return {
        ...state,
        showing: true,
        loading: false,
        orgs: action.orgs,
        appName: action.appName,
      };
    case "hide":
      return {
        ...transferAppDefaultState,
      };
    case "transferring":
      return {
        ...state,
        transferring: true,
      };
    case "transferring-failed":
      return {
        ...state,
        transferring: false,
      };
    default:
      return state;
  }
}

const gitRepoDefaultState = {
  gitFormKey: 0,
  gitRepos: null,
  gitNextToken: "",
  gitProvider: null,
  gitRepoSaving: false,
  gitRepoShowing: false,
  gitInstallations: null,
  gitIsLoadingMore: false,
  gitActiveInstallationId: null,
};

function gitRepoReducer(state, action) {
  switch (action.type) {
    case "picked":
      return {
        ...gitRepoDefaultState,
        gitProvider: action.provider,
        gitFormKey: state.gitFormKey + 1,
        gitRepoSaving: state.gitRepoSaving,
        gitRepoShowing: state.gitRepoShowing,
      };
    case "loaded":
      return {
        ...state,
        gitRepos: action.repositories,
        gitNextToken: action.next_token,
        ...(action.installations && { gitInstallations: action.installations }),
        ...(action.activeInstallationId && {
          gitActiveInstallationId: action.activeInstallationId,
        }),
      };
    case "loading-more":
      return { ...state, gitIsLoadingMore: true };
    case "loaded-more":
      return {
        ...state,
        gitIsLoadingMore: false,
        gitRepos: action.repositories,
        gitNextToken: action.next_token,
      };
    case "loading-more-failed":
      return { ...state, gitIsLoadingMore: false };
    case "show":
      return { ...state, gitRepoShowing: true };
    case "hide":
      return { ...state, gitRepoShowing: false };
    case "saving":
      return { ...state, gitRepoSaving: true };
    case "saved":
      return { ...state, gitRepoSaving: false, gitRepoShowing: false };
    case "saving-failed":
      return { ...state, gitRepoSaving: false };
    case "reset-org":
      return {
        ...gitRepoDefaultState,
        gitProvider: state.gitProvider,
        gitFormKey: state.gitFormKey + 1,
        gitRepoSaving: state.gitRepoSaving,
        gitRepoShowing: state.gitRepoShowing,
      };
    default:
      return state;
  }
}

const settingDefaultState = { key: 0, updated: false, updating: false };

function settingReducer(state, action) {
  switch (action.type) {
    case "update":
      return { ...state, updating: true };
    case "update-done":
      return { ...state, updating: false, updated: true, key: state.key + 1 };
    case "update-failed":
      return { ...state, updating: false, key: state.key + 1 };
    default:
      return state;
  }
}

function AppSettings({
  match,
  history,
  invokeV2Apig,
  invokeApig,
  invokeAppsApig,
  ...props
}) {
  let isLoading = true;
  let error = null;
  let loadError = null;

  const { appId, ownerId } = match.params;

  const [ciState, ciDispatch] = useReducer(settingReducer, settingDefaultState);
  const [nameState, nameDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [unitTestState, unitTestDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [
    {
      policyInfo,
      policyEditing,
      policyUpdating,
      policyModalKey,
      showPolicyModal,
      policyUpdateError,
      hasPolicyUpdated,
      policyInfoLoading,
    },
    policyDispatch,
  ] = usePolicyReducer();
  const [
    {
      gitRepos,
      gitFormKey,
      gitProvider,
      gitNextToken,
      gitRepoSaving,
      gitRepoShowing,
      gitIsLoadingMore,
      gitInstallations,
      gitActiveInstallationId,
    },
    gitRepoDispatch,
  ] = useReducer(gitRepoReducer, gitRepoDefaultState);
  const [transferAppState, transferAppDispatch] = useReducer(
    transferAppReducer,
    transferAppDefaultState
  );

  const gitActiveInstallation = gitInstallations
    ? gitInstallations.find((i) => i.installationId === gitActiveInstallationId)
    : null;

  useEffect(() => {
    async function handlePostMessage(event) {
      const { data, origin } = event;

      if (
        origin !== window.location.origin ||
        data.source !== config.postMessageSrc
      ) {
        return;
      }

      switch (data.type) {
        case "oauth_code":
          if (data.hasOwnProperty("provider") && data.hasOwnProperty("code")) {
            handlePostMessageGit(data);
          }
          return;
        case "installation":
          if (
            data.hasOwnProperty("provider") &&
            data.hasOwnProperty("installationId")
          ) {
            handlePostMessageGit(data);
          }
          return;
        default:
          return;
      }
    }

    async function handlePostMessageGit({ provider, installationId }) {
      gitRepoDispatch({ type: "picked", provider });

      await handleLoadRepos(provider, installationId);
    }

    // Duplicate of the same function
    async function handleLoadRepos(gitProvider, installationId) {
      try {
        const {
          next_token,
          repositories,
          installations,
          activeInstallationId,
        } = await getGitRepos({
          provider: gitProvider,
          installationId: installationId || undefined,
        });

        gitRepoDispatch({
          type: "loaded",
          repositories,
          next_token,
          installations,
          activeInstallationId,
        });
      } catch (e) {
        errorHandler(e);
      }
    }

    // Duplicate of the same function
    function getGitRepos({ provider, installationId, nextToken = "" }) {
      return invokeApig({
        path: "/get_repositories",
        queryStringParameters: {
          git_provider: provider,
          installation_id: installationId,
          next_token: nextToken,
        },
      });
    }

    window.addEventListener("message", handlePostMessage);
    document.title = title("App Settings");

    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [invokeApig]);

  const {
    data: appInfo,
    error: appError,
    reload: reloadAppInfo,
  } = useAPILoad(`/${ownerId}/${appId}`, (path) => invokeAppsApig({ path }));
  const { data: orgInfo, error: orgError } = useAPILoad(`/orgs/${ownerId}`, {
    polling: false,
  });

  if (appInfo !== null && orgInfo !== null) {
    isLoading = false;
  }

  // handle errors
  error = appError || orgError;
  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  function getAppAPI() {
    return `/${ownerId}/${appId}`;
  }

  /////////
  // API //
  /////////

  function getOrgs() {
    return invokeApig({ path: "/orgs" });
  }

  function getGitRepos({ provider, installationId, nextToken = "" }) {
    return invokeApig({
      path: "/get_repositories",
      queryStringParameters: {
        git_provider: provider,
        installation_id: installationId,
        next_token: nextToken,
      },
    });
  }

  function updateAppInfo(data) {
    return invokeAppsApig({
      path: getAppAPI(),
      method: "PUT",
      body: data,
    });
  }

  function removeApp(fullRemove) {
    return invokeAppsApig({
      path: getAppAPI(),
      method: "DELETE",
      body: { mode: fullRemove ? undefined : "quick" },
    });
  }

  ///////////////////////////
  // API - IAM Policy Info //
  ///////////////////////////

  function getIamPolicyInfo() {
    return invokeV2Apig({ path: `/orgs/${ownerId}/iam_role` });
  }

  function setIamPolicyInfo(iam_policy) {
    return invokeV2Apig({
      path: `/orgs/${ownerId}/iam_role`,
      method: "POST",
      body: { iam_policy },
    });
  }

  /////////////////////////
  // API - Custom Domain //
  /////////////////////////

  function getDomainInfo() {
    return invokeApig({ path: `${getAppAPI()}/domains_info` });
  }

  function getRoute53Domains(stage) {
    return invokeApig({ path: `${getAppAPI()}/stages/${stage}/domains_list` });
  }

  function addDomain(args) {
    return invokeApig({
      path: `${getAppAPI()}/domains_create`,
      method: "POST",
      body: {
        base_path: args.path,
        stage_name: args.stage,
        base_domain: args.domain,
        sub_domain: args.subDomain,
        service_name: args.service,
      },
    });
  }

  function removeDomain(stage_name, service_name) {
    return invokeApig({
      path: `${getAppAPI()}/domains_delete`,
      method: "DELETE",
      body: {
        stage_name,
        service_name,
      },
    });
  }

  function retryDomain(stage_name, service_name) {
    return invokeApig({
      path: `${getAppAPI()}/domains_retry`,
      method: "POST",
      body: {
        stage_name,
        service_name,
      },
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleNameSave(slug) {
    nameDispatch({ type: "update" });
    try {
      await updateAppInfo({ slug });
      // Reloads the page in place with new route
      history.replace(`/${ownerId}/${slug}/settings`);
      nameDispatch({ type: "update-done" });
    } catch (e) {
      nameDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleIamSave(event, iamData) {
    await updateAppInfo(iamData);
    await reloadAppInfo();
  }

  async function handleUnitTestEnabledSave(enabled) {
    unitTestDispatch({ type: "update" });
    try {
      await updateAppInfo({ build_test_enabled: enabled });
      await reloadAppInfo();
      unitTestDispatch({ type: "update-done" });
    } catch (e) {
      unitTestDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleAppRemove(event, fullRemove) {
    await removeApp(fullRemove);

    // Redirect
    history.push(`/${ownerId}`);
  }

  async function handleCiEnabledSave(enabled) {
    ciDispatch({ type: "update" });
    try {
      await updateAppInfo({ ci_enabled: enabled });
      await reloadAppInfo();
      ciDispatch({ type: "update-done" });
    } catch (e) {
      ciDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  /////////////////////////
  // Handlers - Git Repo //
  /////////////////////////

  async function handleLoadRepos(gitProvider, installationId) {
    try {
      const {
        next_token,
        repositories,
        installations,
        activeInstallationId,
      } = await getGitRepos({
        provider: gitProvider,
        installationId: installationId || undefined,
      });

      gitRepoDispatch({
        type: "loaded",
        repositories,
        next_token,
        installations,
        activeInstallationId,
      });
    } catch (e) {
      errorHandler(e);
    }
  }

  async function handleGitOrgChange(installationId) {
    gitRepoDispatch({ type: "reset-org" });

    await handleLoadRepos(gitProvider, installationId);
  }

  async function handleRepoLoadMoreClick(event) {
    gitRepoDispatch({ type: "loading-more" });

    try {
      const { next_token, repositories } = await getGitRepos({
        provider: gitProvider,
        installationId: gitActiveInstallationId || undefined,
        nextToken: gitNextToken,
      });
      gitRepoDispatch({
        type: "loaded-more",
        next_token,
        repositories: gitRepos.concat(repositories),
      });
    } catch (e) {
      gitRepoDispatch({ type: "loading-more-failed" });
      errorHandler(e);
    }
  }

  async function handleRepoSave(event, repoData) {
    await updateAppInfo(repoData);
    await reloadAppInfo();
  }

  function handleRepoShowClick(event) {
    gitRepoDispatch({ type: "show" });
  }

  function handleRepoHideClick(event) {
    gitRepoDispatch({ type: "hide" });
  }

  async function handleRepoConnectClick(event, { repo }) {
    gitRepoDispatch({ type: "saving" });

    try {
      await handleRepoSave(event, {
        installation_id: repo.installation_id,
        git_provider: repo.git_provider,
        git_owner: repo.git_owner,
        git_owner_id: repo.git_owner_id,
        git_repo: repo.git_repo,
        git_id: repo.git_id,
      });

      gitRepoDispatch({ type: "saved" });
    } catch (e) {
      gitRepoDispatch({ type: "saving-failed" });
      errorHandler(e);
    }
  }

  async function handleRepoDisconnectClick(event) {
    gitRepoDispatch({ type: "saving" });

    try {
      await handleRepoSave(event, {
        git_id: null,
        git_provider: null,
      });

      gitRepoDispatch({ type: "saved" });
    } catch (e) {
      gitRepoDispatch({ type: "saving-failed" });
      errorHandler(e);
    }
  }

  ///////////////////////
  // Handlers - Policy //
  ///////////////////////

  function handleCustomizePolicyClick(event) {
    policyDispatch({ type: "show-policy-editor" });
  }

  function handleUpdateCancelPolicyClick(event) {
    policyDispatch({ type: "hide-policy-editor" });
  }

  async function handlePolicyUpdateClick(event, policy) {
    policyDispatch({ type: "updating-policy-editor" });

    try {
      const policyInfo = await setIamPolicyInfo(policy);
      policyDispatch({ policyInfo, type: "updated-policy-editor" });
    } catch (e) {
      if (isAPIErrorWithCode(e, 5014)) {
        policyDispatch({
          type: "error-policy-editor",
          policyUpdateError: "Please enter a valid IAM policy.",
        });
      } else {
        errorHandler(e);
      }
    }
  }

  async function handleShowIamRolePanelClick(event) {
    policyDispatch({ type: "info-loading" });

    try {
      const policyInfo = await getIamPolicyInfo();
      policyDispatch({ policyInfo, type: "info-loaded" });
    } catch (e) {
      errorHandler(e);
    }
  }

  function handleHideIamRolePanelClick(event) {
    policyDispatch({ type: "hide-info" });
  }

  /////////////////////////////
  // Handlers - Transfer App //
  /////////////////////////////

  async function handleTransferPanelShowClick() {
    transferAppDispatch({ type: "loading" });

    try {
      const { orgs } = await getOrgs();
      transferAppDispatch({
        orgs,
        type: "show",
        appName: appId,
      });
    } catch (e) {
      errorHandler(e);
      transferAppDispatch({ type: "loading-failed" });
    }
  }

  function handleTransferPanelHideClick() {
    transferAppDispatch({ type: "hide" });
  }

  async function handleTransferPanelTransferClick(org_name) {
    transferAppDispatch({ type: "transferring" });

    try {
      await updateAppInfo({ org_name });
      history.push(`/${org_name}`);
    } catch (e) {
      errorHandler(e);
      transferAppDispatch({ type: "transferring-failed" });
    }
  }

  return (
    <div className="AppSettings">
      <ScreenHeader border>App Settings</ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {error && !loadError && <ErrorAlert error={error} />}
      {error && loadError && (
        <ContainerErrorPanel
          type="app"
          code={loadError}
          context={{
            name: appId,
          }}
        />
      )}

      {!isLoading && appInfo && (
        <>
          <div className="cols">
            <div className="col-1">
              <SectionGroupPanel>
                <AppNameForm
                  saving={nameState.updating}
                  updated={nameState.updated}
                  key={`AppNameForm-${nameState.key}`}
                  slug={appInfo.app.slug}
                  onSaveClick={handleNameSave}
                />
                <hr />
                <AppIam
                  onSave={handleIamSave}
                  iamRole={appInfo.app.iamRole}
                  iamAccess={appInfo.app.iamAccess}
                  policyInfoLoading={policyInfoLoading}
                  onShowIamRoleClick={handleShowIamRolePanelClick}
                />
                <hr />
                <AppRepoPanel
                  key={`AppRepoPanel-${gitFormKey}`}
                  org={orgInfo.org}
                  gitRepos={gitRepos}
                  saving={gitRepoSaving}
                  showing={gitRepoShowing}
                  gitProvider={gitProvider}
                  repoUrl={appInfo.app.repo}
                  gitOrgs={gitInstallations}
                  gitNextToken={gitNextToken}
                  onShowClick={handleRepoShowClick}
                  onHideClick={handleRepoHideClick}
                  onGitOrgChange={handleGitOrgChange}
                  gitIsLoadingMore={gitIsLoadingMore}
                  currentGitOrg={gitActiveInstallation}
                  onConnectClick={handleRepoConnectClick}
                  onLoadMoreClick={handleRepoLoadMoreClick}
                  onDisconnectClick={handleRepoDisconnectClick}
                />
                <hr />
                <CustomDomain
                  onAdd={addDomain}
                  onRetry={retryDomain}
                  onLoad={getDomainInfo}
                  onRemove={removeDomain}
                  onLoadDomains={getRoute53Domains}
                />
              </SectionGroupPanel>
              <SectionGroupPanel title="Workflow">
                {["palomma", "botnot", "foyyay", "rebelpay", "frank"].includes(
                  ownerId
                ) && (
                  <>
                    <SectionInfo
                      label="Issues"
                      description={
                        <span>
                          Get notified in real-time for any issues in your
                          Lambda functions.&nbsp;
                          <a
                            target="_blank"
                            href={phasesHelpUrl}
                            rel="noopener noreferrer"
                          >
                            Learn more.
                          </a>
                        </span>
                      }
                    >
                      <LinkContainer
                        exact
                        to={getAppIssuesSettingsUrl(ownerId, appId)}
                      >
                        <LoaderButton bsSize="large">
                          Manage Issues
                          <RightChevron />
                        </LoaderButton>
                      </LinkContainer>
                    </SectionInfo>
                    <hr />
                  </>
                )}
                <AppUnitTestPanel
                  saving={unitTestState.updating}
                  enabled={appInfo.app.build_test_enabled}
                  onSaveClick={handleUnitTestEnabledSave}
                  resetKey={`AppUnitTestPanel-${unitTestState.key}`}
                />
                <hr />
                <AppCiPanel
                  saving={ciState.updating}
                  enabled={appInfo.app.ci_enabled}
                  onSaveClick={handleCiEnabledSave}
                  gitProvider={appInfo.app.git_provider}
                  resetKey={`AppCiPanel-${ciState.key}`}
                />
                <hr />
                <AppBadgePanel
                  owner={ownerId}
                  app={appId}
                  stages={appInfo.stages}
                />
              </SectionGroupPanel>
              <SectionGroupPanel important title="Admin">
                {appInfo.app.canTransfer && (
                  <>
                    <TransferAppPanel
                      orgs={transferAppState.orgs}
                      appName={transferAppState.appName}
                      showing={transferAppState.showing}
                      loading={transferAppState.loading}
                      onShowClick={handleTransferPanelShowClick}
                      onHideClick={handleTransferPanelHideClick}
                      transferring={transferAppState.transferring}
                      onTransferClick={handleTransferPanelTransferClick}
                    />
                    <hr />
                  </>
                )}
                <ItemDelete
                  type="app"
                  itemName={appInfo.app.slug}
                  onDelete={handleAppRemove}
                />
              </SectionGroupPanel>
            </div>
            <div className="col-2">
              <SettingsLinksList type="stages" items={appInfo.stages} />
              <SettingsLinksList type="services" items={appInfo.services} />
            </div>
          </div>

          <NewIamRoleModal
            key={`NewIamRoleModal-${policyModalKey}`}
            show={showPolicyModal}
            editing={policyEditing}
            updating={policyUpdating}
            hasUpdated={hasPolicyUpdated}
            updateError={policyUpdateError}
            onUpdateClick={handlePolicyUpdateClick}
            onCloseClick={handleHideIamRolePanelClick}
            onCustomizeClick={handleCustomizePolicyClick}
            onUpdateCancelClick={handleUpdateCancelPolicyClick}
            seedPolicy={policyInfo && policyInfo.seedTemplate}
            cloudFormationUrl={policyInfo && policyInfo.createUrl}
            serverlessPolicy={policyInfo && policyInfo.deployTemplate}
          />
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(AppSettings));
