import React, { Component } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { testEmail } from "../lib/regexLib";
import LoaderButton from "./LoaderButton";
import StyledControlLabel from "./StyledControlLabel";
import "./ResetPwForm.css";

const noop = () => {};

const defaultProps = {
  isSendingCode: false,
  onCancelClick: noop,
  onSendCodeClick: noop,
};

const defaultState = {
  formData: {
    email: "",
  },
  validation: {},
};

export default class ResetPwForm extends Component {
  state = { ...defaultState };

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      validation: {
        ...this.state.validation,
        [id]: null,
      },
    });
  };

  handleSendCodeClick = async (event) => {
    event.preventDefault();

    const email = this.state.formData.email.trim();

    if (email === "") {
      return;
    }

    const isEmailValid = testEmail(email);

    if (!isEmailValid) {
      this.setState({
        validation: {
          ...this.state.validation,
          email: "error",
        },
      });
      return;
    }

    await this.props.onSendCodeClick(email);
  };

  handleCancelClick = () => {
    this.props.onCancelClick();
  };

  render() {
    const { formData, validation } = this.state;

    return (
      <form className="ResetPwForm" onSubmit={this.handleSendCodeClick}>
        <p className="desc">
          To reset your password, enter the email address on your account. And
          we&apos;ll email you a confirmation code to verify your email.
        </p>
        <hr />
        <FormGroup
          bsSize="large"
          controlId="email"
          validationState={validation.email}
        >
          <StyledControlLabel>Email</StyledControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={formData.email}
            placeholder="Email address"
            onChange={this.handleFieldChange}
          />
          {validation.email && (
            <HelpBlock>Please enter a valid email address.</HelpBlock>
          )}
        </FormGroup>
        <div className="controls">
          <LoaderButton
            type="submit"
            bsSize="large"
            bsStyle="warning"
            loading={this.props.isSendingCode}
            onClick={this.handleSendCodeClick}
          >
            Send Confirmation Code
          </LoaderButton>
          {/**
          <TextButton onClick={ this.handleCancelClick }>
            Cancel
          </TextButton>
          **/}
        </div>
      </form>
    );
  }
}

ResetPwForm.defaultProps = defaultProps;
