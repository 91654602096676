import React from "react";
import { Link } from "react-router-dom";
import { Glyphicon } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import AppListPanel from "./AppListPanel";
import { getOrgMembersUrl, getOrgSettingsUrl } from "../lib/urlLib";
import { getOrgName, sortByNumApps } from "../lib/orgsLib";
import "./OrgList.css";

function getAddAppLink(name) {
  return `/orgs/${name}/new`;
}

export default function OrgList({ groupedApps = [] }) {
  function renderNewApp(addAppLink) {
    return (
      <li key={0} className="newProject">
        <Link to={addAppLink}>
          <Glyphicon className="plus" glyph="plus" />
          <span className="copy">Add a New App</span>
        </Link>
      </li>
    );
  }

  function renderOrg(org) {
    const slug = org.org.name;
    const name = getOrgName(org.org);

    const members = org.org.seatsUsed;
    const membersCopy = members > 1 ? "members" : "member";

    const showBigAdd = org.apps.length === 0;

    return (
      <div key={slug} className="org">
        <div className="header">
          <div className="info">
            <h4>
              <Link to={`/${slug}`}>{name}</Link>
            </h4>
            <div>
              <Link className="members" to={getOrgMembersUrl(slug)}>
                <FaIcon name="user" /> {members} {membersCopy}
              </Link>
              <Link className="settings" to={getOrgSettingsUrl(slug)}>
                <FaIcon name="cog" /> Org Settings
              </Link>
            </div>
          </div>
          {!showBigAdd && (
            <LinkContainer to={getAddAppLink(slug)}>
              <LoaderButton bsStyle="warning">
                <FaIcon name="plus" />
                New App
              </LoaderButton>
            </LinkContainer>
          )}
        </div>
        <ul>
          {showBigAdd && renderNewApp(getAddAppLink(slug))}
          {org.apps.map((app) => (
            <li key={app.slug} className="project">
              <AppListPanel app={app} />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  groupedApps = sortByNumApps(groupedApps);

  return <div className="OrgList">{groupedApps.map(renderOrg)}</div>;
}
