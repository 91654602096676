import React, { Component, Fragment } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { testEmail } from "../lib/regexLib";
import { isFieldEmpty, isPasswordValid } from "../lib/formLib";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import "./BetaSignupForm.css";

const noop = () => {};

const defaultProps = {
  isSigningup: false,
  showPassword: false,
  onSignupClick: noop,
  onCancelClick: noop,
};

const defaultState = {
  formData: {
    name: "",
    email: "",
    company: "",
    team_size: "select team size",
    password: "",
    confirmPassword: "",
  },
  validation: {},
};

class BetaSignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
    };
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      validation: {
        ...this.state.validation,
        [id]: null,
      },
    });
  };

  handleSignupClick = async (event) => {
    event.preventDefault();

    const formData = { ...this.state.formData };

    formData.email = formData.email.trim().toLowerCase();

    if (!this.props.showPassword) {
      delete formData.password;
      delete formData.confirmPassword;
    }

    const hasError = Object.keys(formData).reduce((hasError, field) => {
      if (
        isFieldEmpty(formData[field]) ||
        (field === "team_size" &&
          formData[field] === defaultState.formData.team_size) ||
        (field === "password" && !isPasswordValid(formData[field])) ||
        (field === "confirmPassword" &&
          formData.password !== formData.confirmPassword) ||
        (field === "email" && !testEmail(formData[field]))
      ) {
        hasError = true;
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            [field]: "error",
          },
        }));
      }
      return hasError;
    }, false);

    if (hasError) {
      return;
    }

    delete formData.confirmPassword;

    await this.props.onSignupClick(formData);
  };

  handleCancelClick = () => {
    this.setState({
      formData: {
        ...defaultState.formData,
      },
      validation: {
        ...defaultState.validation,
      },
    });
    this.props.onCancelClick();
  };

  render() {
    const { formData, validation } = this.state;
    const { isSigningup, showPassword } = this.props;

    return (
      <form className="BetaSignupForm" onSubmit={this.handleSignupClick}>
        {!showPassword && (
          <Fragment>
            <FormGroup
              bsSize="large"
              controlId="name"
              validationState={validation.name}
            >
              <StyledControlLabel>Name</StyledControlLabel>
              <FormControl
                autoFocus
                type="text"
                value={formData.name}
                placeholder="Joe Smith"
                onChange={this.handleFieldChange}
              />
              {validation.name && (
                <HelpBlock>Please enter your name.</HelpBlock>
              )}
            </FormGroup>
            <FormGroup
              bsSize="large"
              controlId="email"
              validationState={validation.email}
            >
              <StyledControlLabel>Email</StyledControlLabel>
              <FormControl
                type="email"
                value={formData.email}
                placeholder="me@example.com"
                onChange={this.handleFieldChange}
              />
              {validation.email && (
                <HelpBlock>Please enter a valid email address.</HelpBlock>
              )}
            </FormGroup>
            <FormGroup
              bsSize="large"
              controlId="company"
              validationState={validation.company}
            >
              <StyledControlLabel>Company</StyledControlLabel>
              <FormControl
                type="text"
                value={formData.company}
                placeholder="Watermelon Inc"
                onChange={this.handleFieldChange}
              />
              {validation.company && (
                <HelpBlock>
                  Please add the name of your company or set it as
                  self-employed.
                </HelpBlock>
              )}
            </FormGroup>
            <FormGroup
              bsSize="large"
              controlId="team_size"
              validationState={validation.team_size}
            >
              <StyledControlLabel>Size of your team</StyledControlLabel>
              <FormControl
                componentClass="select"
                value={formData.team_size}
                onChange={this.handleFieldChange}
              >
                <option disabled value="select team size">
                  Select the size of your team
                </option>
                <option value={1}>1</option>
                <option value={5}>2 - 5</option>
                <option value={10}>6 - 10</option>
                <option value={20}>11 - 20</option>
                <option value={50}>21 - 50</option>
                <option value={100}>50+</option>
              </FormControl>
              {validation.team_size && (
                <HelpBlock>Please select a team size.</HelpBlock>
              )}
            </FormGroup>
          </Fragment>
        )}
        {showPassword && (
          <Fragment>
            <FormGroup
              bsSize="large"
              controlId="password"
              validationState={validation.password}
            >
              <StyledControlLabel>Password</StyledControlLabel>
              <FormControl
                type="password"
                autoComplete="new-password"
                value={formData.password}
                placeholder="Use a strong password"
                onChange={this.handleFieldChange}
              />
              {validation.password && (
                <HelpBlock>
                  Please use a password longer than 8 characters.
                </HelpBlock>
              )}
            </FormGroup>
            <FormGroup
              bsSize="large"
              controlId="confirmPassword"
              validationState={validation.confirmPassword}
            >
              <StyledControlLabel>Confirm Password</StyledControlLabel>
              <FormControl
                type="password"
                autoComplete="new-password"
                value={formData.confirmPassword}
                placeholder="Confirm your password"
                onChange={this.handleFieldChange}
              />
              {validation.confirmPassword && (
                <HelpBlock>Please confirm your password.</HelpBlock>
              )}
            </FormGroup>
          </Fragment>
        )}
        <div className="controls">
          {!showPassword && (
            <p className="legal">
              By creating an account you are agreeing to our&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://seed.run/legal/terms-of-service"
              >
                Terms of Service
              </a>
              &nbsp;and&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://seed.run/legal/privacy-policy"
              >
                Privacy Policy
              </a>
              .
            </p>
          )}
          <LoaderButton
            type="submit"
            bsSize="large"
            bsStyle="warning"
            loading={isSigningup}
            onClick={this.handleSignupClick}
          >
            {showPassword ? "Submit" : "Sign up"}
          </LoaderButton>
          {showPassword && (
            <LoaderButton
              bsStyle="link"
              className="btn-cancel"
              disabled={isSigningup}
              onClick={this.handleCancelClick}
            >
              Cancel
            </LoaderButton>
          )}
        </div>
      </form>
    );
  }
}

BetaSignupForm.defaultProps = defaultProps;

export default BetaSignupForm;
