import React from "react";
import "./NewAppErrorPanel.css";

export default function NewAppErrorPanel({ error, className = "", ...props }) {
  return (
    <div className={`NewAppErrorPanel ${className}`} {...props}>
      <div className="icon">
        <i className="fa fa-exclamation" aria-hidden="true"></i>
      </div>
      <div className="body">
        {error.code === 5013 && (
          <>
            <p>
              Please ensure that the IAM user <b>{error.context.iamUser}</b> has
              the following KMS permissions:
            </p>
            <ul>
              <li>
                <code>kms:ListAliases</code>
              </li>
              <li>
                <code>kms:CreateAlias</code>
              </li>
              <li>
                <code>kms:CreateKey</code>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
}
