import React, { useRef, useState, useReducer } from "react";
import CustomDomainPanel from "../../components/CustomDomainPanel";
import withPolling from "../../components/ComponentWithPolling";
import { errorHandler, isAPIErrorWithCode } from "../../lib/errorLib";
import { makeCancelable } from "../../lib/promiseLib";

const initialState = {
  info: {},
  removing: {},
  retrying: {},
  adding: false,
  showing: false,
  showingForm: null,
  route53Domains: [],
  loadingDomains: null,
  loadDomainsErrorMessage: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "show":
      return { ...state, showing: true };
    case "loaded":
      return { ...state, info: action.results };
    case "hide":
      return { ...initialState };
    case "adding":
      return { ...state, adding: true };
    case "added":
      return { ...state, adding: false, showingForm: null };
    case "add_failed":
      return { ...state, adding: false };
    case "loading_domains":
      return { ...state, loadingDomains: action.endpoint };
    case "load_domains_failed":
      return { ...state, loadingDomains: null };
    case "hide_form":
      return { ...state, showingForm: null };
    case "loaded_domains":
      return {
        ...state,
        loadingDomains: null,
        showingForm: action.endpoint,
        route53Domains: action.domains,
        loadDomainsErrorMessage: action.errorMessage,
      };
    case "retrying":
      return {
        ...state,
        retrying: { ...state.retrying, [action.id]: true },
      };
    case "retried":
      return {
        ...state,
        retrying: { ...state.retrying, [action.id]: false },
      };
    case "removing":
      return {
        ...state,
        removing: { ...state.removing, [action.id]: true },
      };
    case "removed":
      return {
        ...state,
        removing: { ...state.removing, [action.id]: false },
      };
    default:
      return state;
  }
}

function CustomDomain({
  onAdd,
  onLoad,
  onRetry,
  onRemove,
  onLoadDomains,
  ...props
}) {
  const initialLoad = useRef(true);
  const cancelableLoadDomains = useRef(null);

  const [
    {
      info,
      adding,
      showing,
      removing,
      retrying,
      showingForm,
      loadingDomains,
      route53Domains,
      loadDomainsErrorMessage,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);

  async function handleLoad() {
    try {
      const results = await props.poll.register(onLoad());
      dispatch({ type: "loaded", results });

      if (initialLoad.current) {
        initialLoad.current = false;
        dispatch({ type: "show" });
      }
    } catch (e) {
      if (initialLoad.current) {
        errorHandler(e);
        return false;
      }
    }
  }

  async function handleReload() {
    props.poll.clear();
    await props.poll.start(handleLoad);
  }

  async function handleShowClick() {
    setIsLoading(true);

    await props.poll.start(handleLoad);

    setIsLoading(false);
  }

  function handleHideClick() {
    props.poll.clear();
    initialLoad.current = true;
    dispatch({ type: "hide" });
  }

  async function handleAddClick(args) {
    dispatch({ type: "adding" });

    try {
      await onAdd(args);
      await handleReload();
      dispatch({ type: "added" });
    } catch (e) {
      errorHandler(e);
      dispatch({ type: "add_failed" });
    }
  }

  async function handleRetryClick({ domainEndpoint, stageName, serviceName }) {
    dispatch({ type: "retrying", id: domainEndpoint });

    try {
      await onRetry(stageName, serviceName);
      await handleReload();
    } catch (e) {
      errorHandler(e);
    }

    dispatch({ type: "retried", id: domainEndpoint });
  }

  async function handleRemoveClick({ domainEndpoint, stageName, serviceName }) {
    dispatch({ type: "removing", id: domainEndpoint });

    try {
      await onRemove(stageName, serviceName);
      await handleReload();
    } catch (e) {
      errorHandler(e);
    }

    dispatch({ type: "removed", id: domainEndpoint });
  }

  async function handleShowFormClick({ endpoint, stageName }) {
    handleHideFormClick();

    dispatch({ type: "loading_domains", endpoint });

    try {
      cancelableLoadDomains.current = makeCancelable(onLoadDomains(stageName));

      const { domains, isInheritedFromApp } = await cancelableLoadDomains
        .current.promise;
      const errorMessage =
        domains.length === 0
          ? isInheritedFromApp
            ? "No domains were detected in Route 53 for this stage. Please add a domain and try again."
            : "No domains were detected in Route 53. Please add a domain and try again."
          : null;

      dispatch({ type: "loaded_domains", endpoint, domains, errorMessage });
    } catch (e) {
      if (isAPIErrorWithCode(e, 8108)) {
        dispatch({
          type: "loaded_domains",
          endpoint,
          domains: [],
          errorMessage:
            "The IAM credentials don't have permission to Route 53. Please check the premissions and try again.",
        });
      } else {
        errorHandler(e);
        dispatch({ type: "load_domains_failed" });
      }
    }
  }

  function handleHideFormClick() {
    if (cancelableLoadDomains.current) {
      cancelableLoadDomains.current.cancel();
      cancelableLoadDomains.current = null;
    }

    dispatch({ type: "hide_form" });
  }

  return (
    <CustomDomainPanel
      adding={adding}
      showing={showing}
      removing={removing}
      retrying={retrying}
      loading={isLoading}
      showingForm={showingForm}
      onAddClick={handleAddClick}
      onShowClick={handleShowClick}
      onHideClick={handleHideClick}
      route53Domains={route53Domains}
      loadingDomains={loadingDomains}
      onRetryClick={handleRetryClick}
      onRemoveClick={handleRemoveClick}
      onShowFormClick={handleShowFormClick}
      onHideFormClick={handleHideFormClick}
      customEndpoints={info.customEndpoints}
      availableEndpoints={info.availableEndpoints}
      loadDomainsErrorMessage={loadDomainsErrorMessage}
    />
  );
}

export default withPolling(CustomDomain);
