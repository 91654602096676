import React from "react";
import { Glyphicon } from "react-bootstrap";
import SectionHeader from "./SectionHeader";
import LoaderButton from "./LoaderButton";
import TextButton from "./TextButton";
import config from "../config";
import "./ItemRemoveStatusPanel.css";

export default function ItemRemoveStatusPanel({
  type,
  status,
  appStatus,
  errorMessage,
  retry = false,
  onRemoveClick,
  removing = false,
  onShowRetryClick,
  onHideRetryClick,
  onQuickRemoveClick,
  isCleaningUp = false,
  quickRemoving = false,
}) {
  const isDeleting = status === "deleting";
  const isDeleteFailed =
    status === "delete_failed" || (status === "idle" && errorMessage);
  const isDeletingApp =
    appStatus === "deleting" || appStatus === "delete_failed";
  const isDeletingStageDueToDeletingApp = type === "stage" && isDeletingApp;
  const cs = `ItemRemoveStatusPanel ${status}`;

  function handleQuickRemoveClick(e) {
    if (
      window.confirm(
        `Are you sure you want to remove this ${type} only from Seed? This will not remove the resources created by the ${type}.`
      )
    ) {
      onQuickRemoveClick(e);
    }
  }

  return (
    <div className={cs}>
      <SectionHeader>Status</SectionHeader>
      {isDeleting && (
        <>
          <div className="status">
            <Glyphicon glyph="refresh" />
            Removing
          </div>
          {isCleaningUp && (
            <p className="copy">
              Items removed successfully. Doing a final cleanup&hellip;
            </p>
          )}
        </>
      )}
      {isDeleteFailed && (
        <>
          <div className="status">
            {errorMessage}
            {!retry && status !== "idle" && !isDeletingStageDueToDeletingApp && (
              <LoaderButton
                bsSize="small"
                bsStyle="danger"
                onClick={onShowRetryClick}
              >
                Retry
              </LoaderButton>
            )}
          </div>
          {retry && (
            <div className="retry">
              <p>
                You can try removing this {type} and its resources again. Or you
                can simply remove it from Seed.
                <br />
                If you are having problems removing this {type},&nbsp;
                <a href={`mailto:${config.contactEmail}`}>
                  feel free to contact us.
                </a>
              </p>
              <div className="controls">
                <LoaderButton
                  bsStyle="primary"
                  loading={removing}
                  onClick={onRemoveClick}
                  disabled={quickRemoving}
                >
                  Try removing again
                </LoaderButton>
                <LoaderButton
                  disabled={removing}
                  loading={quickRemoving}
                  onClick={handleQuickRemoveClick}
                >
                  Remove from Seed
                </LoaderButton>
                <TextButton onClick={onHideRetryClick}>
                  Hide Retry Controls
                </TextButton>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
