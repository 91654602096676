import React, { useState, useEffect } from "react";
import withFaviconChange from "../components/ComponentWithFaviconChange";
import withAppHeader from "../components/ComponentWithAppHeader";
import withCancel from "../components/ComponentWithCancel";
import ErrorAlert from "../components/ErrorAlert";
import ScreenHeader from "../components/ScreenHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import BuildReportPanel from "../components/BuildReportPanel";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import { errorHandler, getLoadError } from "../lib/errorLib";
import { appActivityTypeBreadcrumb } from "../lib/breadcrumbLib";
import { getAppStageBuildUrl } from "../lib/urlLib";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import "./AppActivities.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
  5103: "BUILD_NOT_FOUND",
  StageNotExist: "STAGE_NOT_FOUND",
};

function AppActivities(props) {
  let isLoading = true;
  let loadError = null;

  const { ownerId, appId, appStageId, buildId } = props.match.params;
  const appStageUri = `/${ownerId}/${appId}/stages/${appStageId}`;
  const buildUri = `/${ownerId}/${appId}/stages/${appStageId}/builds/${buildId}`;

  const [isRetrying, setRetrying] = useState(false);
  const [isCanceling, setCanceling] = useState(false);

  useEffect(() => {
    document.title = title(`Build v${buildId}`);
  }, [buildId]);

  const {
    data: [appStageInfo, buildInfo],
    error,
    reload,
  } = useAPILoad(
    [appStageUri, buildUri],
    [
      (path) => props.invokeAppsApig({ path }),
      (path) => props.invokeApig({ path }),
    ]
  );

  const build = buildInfo && buildInfo.build;
  const buildStatus = build && build.status;

  useEffect(() => {
    if (!buildStatus) {
      return;
    }

    props.favicon.setStatus(buildStatus);
  }, [props.favicon, buildStatus]);

  if (buildInfo && appStageInfo) {
    isLoading = false;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  /////////
  // API //
  /////////

  function cancelBuild() {
    return props.invokeApig({
      path: `${buildUri}/cancel_build`,
      method: "POST",
    });
  }

  function retryBuild(force_deploy) {
    return props.invokeApig({
      path: `${buildUri}/redeploy`,
      method: "POST",
      body: { force_deploy },
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleCancelClick(event) {
    const confirm = window.confirm(
      "Are you sure you want to cancel this build?"
    );
    if (!confirm) {
      return;
    }

    setCanceling(true);

    try {
      await cancelBuild();
      await reload();
    } catch (e) {
      errorHandler(e);
    }

    setCanceling(false);
  }

  async function handleRetryClick({ force }) {
    setRetrying(true);

    try {
      const { build } = await retryBuild(force);
      const retryBuildUrl = getAppStageBuildUrl(
        ownerId,
        appId,
        appStageId,
        build.build_id
      );

      // Reloads the page in place with new route
      props.history.replace(retryBuildUrl);
    } catch (e) {
      errorHandler(e);
    }

    setRetrying(false);
  }

  return (
    <div className="AppActivities">
      <ScreenHeader border breadcrumb={appActivityTypeBreadcrumb(props)}>
        Build v{buildId}
      </ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {error && !loadError && <ErrorAlert error={error} />}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="build"
          code={loadError}
          context={{
            id: buildId,
            appName: appId,
            stageName: appStageId,
          }}
        />
      )}

      {!isLoading && !loadError && appStageInfo && buildInfo && (
        <BuildReportPanel
          appId={appId}
          activity={build}
          ownerId={ownerId}
          isRetrying={isRetrying}
          isCanceling={isCanceling}
          onRetry={handleRetryClick}
          onCancel={handleCancelClick}
          pathParams={props.match.params}
          canRetry={appStageInfo.stage.latestBuildId === parseInt(buildId)}
        />
      )}
    </div>
  );
}

export default withAppHeader(withCancel(withFaviconChange(AppActivities)));
