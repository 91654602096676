import React, { Component } from "react";
import { trimFields, areFieldsNotEmpty } from "../lib/formLib";
import GitProviderSelect from "./GitProviderSelect";
import GitRepoSelect from "./GitRepoSelect";
import LoaderButton from "./LoaderButton";
import SectionInfo from "./SectionInfo";
import "./AppRepoPanel.css";

const noop = () => {};

const defaultProps = {
  repoUrl: false,
  saving: false,
  onShowClick: noop,
  onHideClick: noop,
  onConnectClick: noop,
  onDisconnectClick: noop,

  // repo picker
  org: null,
  gitOrgs: null,
  gitRepos: null,
  gitNextToken: "",
  gitProvider: null,
  currentGitOrg: null,
  onGitOrgChange: noop,
  onLoadMoreClick: noop,
  gitIsLoadingMore: false,
};

class AppRepoForm extends Component {
  state = {
    formData: {
      repo: null,
    },
  };

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
    });
  };

  handleConnectClick = async (event) => {
    event.preventDefault();

    const formData = trimFields(this.state.formData);

    // validate form
    if (!areFieldsNotEmpty(formData)) {
      return;
    }

    await this.props.onConnectClick(event, formData);
  };

  render() {
    const { formData } = this.state;
    const {
      org,
      saving,
      gitOrgs,
      gitRepos,
      gitProvider,
      onHideClick,
      gitNextToken,
      currentGitOrg,
      onGitOrgChange,
      onLoadMoreClick,
      gitIsLoadingMore,
    } = this.props;

    return (
      <>
        <p className="current">Connect your Git repository.</p>
        {gitProvider === null && <GitProviderSelect org={org} />}
        {gitProvider !== null && (
          <GitRepoSelect
            orgs={gitOrgs}
            repos={gitRepos}
            value={formData.repo}
            currentOrg={currentGitOrg}
            hasMore={gitNextToken !== ""}
            isLoadingMore={gitIsLoadingMore}
            onChange={this.handleFieldChange}
            onOrgChange={onGitOrgChange}
            onLoadMoreClick={onLoadMoreClick}
          />
        )}
        <div className="controls">
          <LoaderButton
            text="Connect"
            type="submit"
            bsSize="large"
            bsStyle="warning"
            loading={saving}
            disabled={gitProvider === null || formData.repo === null}
            onClick={this.handleConnectClick}
          />
          <LoaderButton
            bsStyle="link"
            text="Hide Git Repo"
            onClick={onHideClick}
          />
        </div>
      </>
    );
  }
}

function handleDisconnectClick(callback) {
  if (
    window.confirm(
      "Are you sure you want to disconnect the repository from your applicaiton?"
    )
  ) {
    callback();
  }
}

function AppRepoPanel({
  repoUrl,
  showing,
  saving,
  onShowClick,
  onHideClick,
  onConnectClick,
  onDisconnectClick,

  org,
  gitOrgs,
  gitRepos,
  gitProvider,
  gitNextToken,
  currentGitOrg,
  onGitOrgChange,
  onLoadMoreClick,
  gitIsLoadingMore,
}) {
  return (
    <div className="AppRepoPanel">
      <SectionInfo label="Git Repo">
        {repoUrl && (
          <>
            <p className="current">
              Currently connected to:&nbsp;
              <a target="_blank" href={repoUrl} rel="noopener noreferrer">
                {repoUrl}
              </a>
            </p>
            <LoaderButton
              bsSize="large"
              loading={saving}
              className="btnDisconnect"
              onClick={(e) => handleDisconnectClick(onDisconnectClick)}
            >
              Disconnect Repo
            </LoaderButton>
          </>
        )}
        {!repoUrl && !showing && (
          <>
            <p className="current highlight">
              This app is not connected to a Git repository.
            </p>
            <LoaderButton
              bsSize="large"
              className="btnShow"
              onClick={onShowClick}
              loading={false}
              text="Connect Repo"
            />
          </>
        )}
        {!repoUrl && showing && (
          <AppRepoForm
            org={org}
            saving={saving}
            gitOrgs={gitOrgs}
            gitRepos={gitRepos}
            gitProvider={gitProvider}
            onHideClick={onHideClick}
            gitNextToken={gitNextToken}
            currentGitOrg={currentGitOrg}
            onGitOrgChange={onGitOrgChange}
            onConnectClick={onConnectClick}
            onLoadMoreClick={onLoadMoreClick}
            gitIsLoadingMore={gitIsLoadingMore}
          />
        )}
      </SectionInfo>
    </div>
  );
}

AppRepoPanel.defaultProps = defaultProps;

export default AppRepoPanel;
