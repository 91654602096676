import React from "react";
import { Link } from "react-router-dom";
import PipelineTableServiceCell from "./PipelineTableServiceCell";
import RightChevron from "./RightChevron";
import "./StageRemoveServiceTable.css";

const statusMap = {
  remove_pending: "deleting",
  remove: "deleting",
  remove_failed: "delete_failed",
};

export default function StageRemoveServiceTable({ services }) {
  const servicesC = [];
  const errorsC = [];

  services.forEach((service, i) => {
    servicesC.push(
      <div key={i} className="service">
        <PipelineTableServiceCell
          removeLink={service.link}
          serviceType={service.serviceType}
          serviceName={service.service_name}
          status={statusMap[service.current_action]}
        />
      </div>
    );
    errorsC.push(
      <div key={i} className="error">
        <div>
          {service.errorMessage && (
            <p className="copy">{service.errorMessage}</p>
          )}
          <Link to={service.link}>
            View logs
            <RightChevron />
          </Link>
        </div>
      </div>
    );
  });

  return (
    <div className="StageRemoveServiceTable">
      <div className="content">
        <div className="services">{servicesC}</div>
        <div className="errors">{errorsC}</div>
      </div>
    </div>
  );
}
