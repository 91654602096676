import React, { useRef, useState } from "react";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import "./PolicyDisplayPanel.css";

const minLines = 15;

export default function PolicyDisplayPanel({
  policy,
  className = "",
  onCustomizeClick,
  customize = false,
  hasUpdated = false,
}) {
  const policyStr = JSON.stringify(policy, null, "  ");
  const numLines = policyStr.split(/\r\n|\r|\n/).length;

  const showExpand = numLines > minLines;

  const textareaRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const collapsedCs = collapsed ? "collapsed" : "";
  const icon = collapsed ? "expand" : "compress";

  function handleCopy() {
    textareaRef.current.select();
    document.execCommand("copy");
    textareaRef.current.focus();
    setCopied(true);
  }

  function handleBlur() {
    setCopied(false);
  }

  function handleExpandClick() {
    if (collapsed) {
      setCollapsed(false);
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    } else {
      setCollapsed(true);
      textareaRef.current.removeAttribute("style");
    }
  }

  return (
    <div className={`PolicyDisplayPanel ${className} ${collapsedCs}`}>
      <div className="code">
        <textarea
          readOnly
          ref={textareaRef}
          value={policyStr}
          onBlur={handleBlur}
        />
      </div>
      <div className="controls">
        <div className="left">
          {showExpand && (
            <>
              <TextButton onClick={handleExpandClick}>
                <FaIcon name={icon} />
                {collapsed ? "Expand" : "Collapse"}
              </TextButton>
              {customize && <span className="separator">&bull;</span>}
            </>
          )}
          {customize && (
            <>
              <TextButton onClick={onCustomizeClick}>
                <FaIcon name="pencil" />
                Edit Policy
              </TextButton>
              {hasUpdated && <span className="status">Updated!</span>}
            </>
          )}
        </div>
        <div className="right">
          {document.queryCommandSupported("copy") && (
            <>
              {copied && <span className="status copied">Copied!</span>}
              <TextButton onClick={handleCopy}>
                <FaIcon name="clone" />
                Copy
              </TextButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
