import React, { useState } from "react";
import { Glyphicon } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import RightChevron from "./RightChevron";
import { sortStages } from "../lib/stagesLib";
import {
  getServiceUrl,
  getStageSettingsUrl,
  getServiceRemoveUrl,
  getAppStageRemoveUrl,
} from "../lib/urlLib";
import "./SettingsLinksList.css";

const MAX_ITEMS = 10;

export default function SettingsLinksList({ type, items = [] }) {
  const [capped, setCapped] = useState(true);

  const { ownerId, appId } = useParams();

  // Sort by top stages
  if (type === "stages") {
    items = sortStages(items);
  }

  const shownItems = capped ? items.slice(0, MAX_ITEMS) : items;

  return (
    <div className={`SettingsLinksList ${type}`}>
      <h5>
        {type === "stages" && "Stage Settings"}
        {type === "services" && "Service Settings"}
      </h5>
      <div className="list">
        {type === "stages" &&
          shownItems.map(({ name, status }) => (
            <Link
              key={name}
              to={
                status === "deleting" || status === "delete_failed"
                  ? getAppStageRemoveUrl(ownerId, appId, name)
                  : getStageSettingsUrl(ownerId, appId, name)
              }
            >
              <span>
                {status === "deleting" && <Glyphicon glyph="refresh" />}
                {status === "delete_failed" && (
                  <FaIcon name="exclamation-triangle" />
                )}
                <span>{name}</span>
              </span>
              <RightChevron />
            </Link>
          ))}
        {type === "services" &&
          shownItems.map(({ service: { name, status } }) => (
            <Link
              key={name}
              to={
                status === "deleting" || status === "delete_failed"
                  ? getServiceRemoveUrl(ownerId, appId, name)
                  : getServiceUrl(ownerId, appId, name)
              }
            >
              <span>
                {status === "deleting" && <Glyphicon glyph="refresh" />}
                {status === "delete_failed" && (
                  <FaIcon name="exclamation-triangle" />
                )}
                <span>{name}</span>
              </span>
              <RightChevron />
            </Link>
          ))}
        {items.length > MAX_ITEMS && (
          <TextButton onClick={() => setCapped(!capped)}>
            Show&nbsp;
            {capped ? "more" : "less"}
            <FaIcon name={capped ? "caret-down" : "caret-up"} />
          </TextButton>
        )}
      </div>
    </div>
  );
}
