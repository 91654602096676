export function isDeprecationWarning(type, message) {
  // New format: type is [DEP0005] DeprecationWarning
  if (type.match(/^(\[.*\] )?DeprecationWarning$/)) {
    return true;
  }

  // Old format: message is
  // (node:8) [DEP0005] DeprecationWarning: Buffer() is deprecated due to security and usability issues. Please use the Buffer.alloc(), Buffer.allocUnsafe(), or Buffer.from() methods instead.
  // (node:7) DeprecationWarning: current URL string parser is deprecated, and will be removed in a future version. To use the new parser, pass option { useNewUrlParser: true } to MongoClient.connect.
  if (message.match(/^\(node:\d+\)(?: \[.*\])? DeprecationWarning:/)) {
    return true;
  }

  return false;
}
