import React, { Component } from "react";
import AppSettingDeployWorkflowPanel from "../../components/AppSettingDeployWorkflowPanel";
import { errorHandler } from "../../lib/errorLib";

const noop = () => {};

const defaultProps = {
  services: null,
  onUpdate: noop,
  hasUpdated: false,
};

const defaultState = {
  phases: null,
  isDirty: false,
  updating: false,
  hasUpdated: false,
};

function generatePhases(services) {
  const phases = [];
  services.forEach((service) => {
    const phaseId = service.deploy_phase;
    phases[phaseId] = phases[phaseId] || [];
    phases[phaseId].push(service);
  });

  return phases;
}

class AppSettingDeployWorkflow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      hasUpdated: props.hasUpdated,
      phases: generatePhases(props.services),
    };
  }

  handlePhaseChange = async (serviceId, tarPhaseId) => {
    let phases = this.state.phases;

    // Remove from current phase
    let service;
    phases = phases.map((phase) =>
      phase.filter((per) => {
        if (per.projectId === serviceId) {
          service = per;
          return false;
        }
        return true;
      })
    );

    // Add to new phase
    phases[tarPhaseId].push(service);

    this.setState({
      phases,
      isDirty: true,
      hasUpdated: false,
    });
  };

  handleAddPhaseClick = async (event) => {
    const phases = this.state.phases;
    phases.push([]);
    this.setState({ phases });
  };

  handleRemovePhaseClick = async (phaseId) => {
    const phases = this.state.phases;
    phases.splice(phaseId, 1);
    this.setState({ phases });
  };

  handleClearClick = (event) => {
    this.setState({
      ...defaultState,
      phases: generatePhases(this.props.services),
    });
  };

  handleUpdateClick = async (event) => {
    this.setState({ updating: true });

    try {
      const phases = this.state.phases
        .filter((phase) => phase.length > 0)
        .map((phase) => phase.map((service) => service.projectId));
      await this.props.onUpdate(JSON.stringify(phases));
    } catch (e) {
      this.setState({ updating: false });
      errorHandler(e);
    }
  };

  render() {
    const { phases, isDirty, updating, hasUpdated } = this.state;

    return (
      <AppSettingDeployWorkflowPanel
        phases={phases}
        isDirty={isDirty}
        updating={updating}
        services={this.props.services}
        onClearClick={this.handleClearClick}
        onPhaseChange={this.handlePhaseChange}
        onUpdateClick={this.handleUpdateClick}
        onAddPhaseClick={this.handleAddPhaseClick}
        onRemovePhaseClick={this.handleRemovePhaseClick}
        hasUpdated={hasUpdated && this.props.hasUpdated}
      />
    );
  }
}

AppSettingDeployWorkflow.defaultProps = defaultProps;

export default AppSettingDeployWorkflow;
