import React, { useState, useRef, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import "./ScrollShadowContainer.css";

function measureElement(element) {
  const DOMNode = ReactDOM.findDOMNode(element);
  return {
    width: DOMNode.offsetWidth,
    contentWidth: DOMNode.scrollWidth,
    scrollLeft: DOMNode.scrollLeft,
  };
}

function calcRightShadow(m) {
  // Scroll left can be negative on Safari
  return Math.max(m.scrollLeft, 0) < m.contentWidth - m.width ? 1 : 0;
}

function calcLeftShadow(m) {
  return m.scrollLeft > 0 ? 1 : 0;
}

export default function ScrollShadowContainer({
  className = "",
  scrollToEnd = false,
  ...props
}) {
  const contentEl = useRef(null);

  const [leftOpacity, setLeftOpacity] = useState(0);
  const [rightOpacity, setRightOpacity] = useState(0);

  const setScrollShadow = () => {
    const m = measureElement(contentEl.current);

    const _leftOpacity = calcLeftShadow(m);
    const _rightOpacity = calcRightShadow(m);

    if (_leftOpacity !== leftOpacity) {
      setLeftOpacity(_leftOpacity);
    }
    if (_rightOpacity !== rightOpacity) {
      setRightOpacity(_rightOpacity);
    }
  };

  // Scroll to end on mount
  useLayoutEffect(() => {
    if (!scrollToEnd) {
      return;
    }

    const m = measureElement(contentEl.current);
    contentEl.current.scrollLeft = m.contentWidth;
  }, [scrollToEnd]);
  useLayoutEffect(setScrollShadow);

  return (
    <div className={`ScrollShadowContainer ${className}`}>
      <div
        ref={contentEl}
        className="scroll-content"
        onScroll={setScrollShadow}
      >
        {props.children}
        <div
          className="scroll-shadow left"
          style={{ opacity: leftOpacity }}
        ></div>
        <div
          className="scroll-shadow right"
          style={{ opacity: rightOpacity }}
        ></div>
      </div>
    </div>
  );
}
