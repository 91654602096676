import React from "react";
import { Link } from "react-router-dom";
import NewMemberForm from "./NewMemberForm";
import MemberPanel from "./MemberPanel";
import { getOrgBillingUrl } from "../lib/urlLib";
import config from "../config";
import "./OrgMembersPanel.css";

const helpUrl = "https://seed.run/docs/adding-organization-members";

const noop = () => {};

function totalMembersCount(members) {
  return members.users.length + (members.invites ? members.invites.length : 0);
}

export default function OrgMembersPanel({
  apps = [],
  owner = null,
  members = false,
  seatsLimit = null,
  isRbacAvailable = false,

  adding = false,
  removing = {},
  updating = {},
  showingNewForm = false,

  onAddClick = noop,
  onShowClick = noop,
  onCancelClick = noop,
  onRemoveClick = noop,
  onUpdateClick = noop,
}) {
  let atLimit, usersCopy;

  // Check to see if there is more than 1 admin
  let isRemovable;
  if (members) {
    const admins = members.users.filter((user) =>
      user.permissions.includes(config.userPermissions.admin.value)
    );
    isRemovable = admins.length > 1;

    atLimit = totalMembersCount(members) >= seatsLimit;

    usersCopy = seatsLimit === 1 ? "user" : "users";
  }

  return (
    <div className="OrgMembersPanel">
      <div key="members" className="members">
        {members.users && members.users.length !== 0 && (
          <div className="members-table added">
            <div className="header">Added</div>
            {members.users.map((member, i) => (
              <MemberPanel
                key={i}
                apps={apps}
                owner={owner}
                user={member}
                removing={removing}
                updating={updating}
                isRemovable={isRemovable}
                isRbacAvailable={isRbacAvailable}
                onRemoveClick={onRemoveClick}
                onUpdateClick={onUpdateClick}
              />
            ))}
          </div>
        )}
        {members.invites && members.invites.length !== 0 && (
          <div className="members-table invitees">
            <div className="header">Invited</div>
            {members.invites.map((invitee, i) => (
              <MemberPanel
                key={i}
                isRemovable
                apps={apps}
                owner={owner}
                user={invitee}
                removing={removing}
                updating={updating}
                isRbacAvailable={isRbacAvailable}
                onRemoveClick={onRemoveClick}
                onUpdateClick={onUpdateClick}
              />
            ))}
          </div>
        )}
      </div>
      {atLimit && (
        <p className="info-copy warning">
          Your organization is limited to {seatsLimit} {usersCopy}. To upgrade,{" "}
          <Link to={getOrgBillingUrl(owner)} className="btn-red-link">
            head over to the billing page
          </Link>
          .
        </p>
      )}
      {!atLimit && (
        <p className="info-copy">
          Add members to this organization.&nbsp;
          <a target="_blank" href={helpUrl} rel="noopener noreferrer">
            Learn more about organization members.
          </a>
        </p>
      )}
      <NewMemberForm
        apps={apps}
        owner={owner}
        adding={adding}
        disabled={atLimit}
        onAddClick={onAddClick}
        showing={showingNewForm}
        isRbacAvailable={isRbacAvailable}
        onShowClick={onShowClick}
        onCancelClick={onCancelClick}
      />
    </div>
  );
}
