import * as Sentry from "@sentry/browser";
import config from "../config";
import { version } from "../../package.json";

const logSentry = process.env.NODE_ENV !== "development";

export function install() {
  if (!logSentry) {
    return;
  }

  Sentry.init({
    dsn: config.sentryDsn,
    release: version,
  });
}

export function setUser(context) {
  if (!logSentry) {
    return;
  }

  Sentry.configureScope((scope) => {
    scope.setUser(context);
  });
}

export function captureException(error, extraCb = null, fingerprint = null) {
  if (!logSentry) {
    return;
  }

  Sentry.withScope((scope) => {
    fingerprint && scope.setFingerprint(fingerprint);

    extraCb && extraCb(scope);

    Sentry.captureException(error);
  });
}

export function addBreadcrumb(crumb) {
  if (!logSentry) {
    return;
  }

  Sentry.addBreadcrumb(crumb);
}
