import React, { Component } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import { trimFields } from "../lib/formLib";
import LoaderButton from "./LoaderButton";
import SectionInfo from "./SectionInfo";
import "./OrgSSHPanel.css";

const helpUrl = "https://seed.run/docs/adding-an-organization-ssh-key";

const noop = () => {};

const defaultProps = {
  sshData: undefined,

  saving: false,

  onSaveClick: noop,
  onHideClick: noop,
};

const defaultState = {
  isFormDirty: false,
  formData: {},
};

class OrgSSHForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...defaultState, formData: { ...defaultState.formData } };
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      isFormDirty: true,
    });
  };

  handleSaveClick = (event) => {
    event.preventDefault();

    const { ssh_key } = trimFields(this.state.formData);

    // Remove empty lines
    const cleanedKey = ssh_key
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0)
      .join("\n");

    // Call api
    this.props.onSaveClick(event, cleanedKey);
  };

  render() {
    const { formData, isFormDirty } = this.state;

    return (
      <Form onSubmit={this.handleAddClick}>
        <FormGroup controlId="ssh_key">
          <FormControl
            rows={10}
            placeholder="-----BEGIN RSA PRIVATE KEY-----"
            componentClass="textarea"
            value={formData.ssh_key}
            onChange={this.handleFieldChange}
          />
        </FormGroup>
        <div className="controls">
          <LoaderButton
            text="Add"
            type="submit"
            bsSize="large"
            disabled={!isFormDirty}
            loading={this.props.saving}
            onClick={this.handleSaveClick}
          />
          <LoaderButton
            bsStyle="link"
            text="Hide SSH Key Info"
            onClick={this.props.onHideClick}
          />
        </div>
      </Form>
    );
  }
}

function OrgSSHPanel({
  sshData,
  editing,
  saving,
  removing,
  onEditClick,
  onSaveClick,
  onHideClick,
  onRemoveClick,
}) {
  return (
    <div className="OrgSSHPanel">
      <SectionInfo
        label="Organization SSH Key"
        description={
          <span>
            Add an SSH key to use in your organization's build processes.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn about adding an SSH Key.
            </a>
          </span>
        }
        button={
          !editing && (
            <>
              {sshData && (
                <>
                  <p className="current">Fingerprint: {sshData.fingerprint}</p>
                  <LoaderButton
                    bsSize="large"
                    loading={removing}
                    className="btnUpdate"
                    onClick={onRemoveClick}
                    text="Remove Org SSH Key"
                  />
                </>
              )}
              {!sshData && (
                <LoaderButton
                  bsSize="large"
                  loading={false}
                  className="btnUpdate"
                  onClick={onEditClick}
                  text="Add Org SSH Key"
                />
              )}
            </>
          )
        }
      >
        {editing && (
          <OrgSSHForm
            sshData={sshData}
            saving={saving}
            onSaveClick={onSaveClick}
            onHideClick={onHideClick}
          />
        )}
      </SectionInfo>
    </div>
  );
}

OrgSSHPanel.defaultProps = defaultProps;

export default OrgSSHPanel;
