import React from "react";
import { Glyphicon } from "react-bootstrap";
import RightChevron from "./RightChevron";
import LoaderButton from "./LoaderButton";
import { stripProtocol } from "../lib/urlLib";
import "./CustomDomainsList.css";

function stepCopyMap(step) {
  switch (step) {
    case "route53_domain_validating":
      return "Step 1 of 6: Validating the given Route 53 domain…";
    case "acm_cert_creating":
      return "Step 2 of 6: Creating the SSL certificate…";
    case "acm_cert_validating":
      return "Step 3 of 6: Verifying the SSL certificate. This can take a few minutes…";
    case "apig_domain_creating":
      return "Step 4 of 6: Creating the API Gateway custom domain…";
    case "apig_base_path_creating":
      return "Step 5 of 6: Creating the API Gateway custom domain base path mapping…";
    case "apig_domain_cloudfront_validating":
      return "Step 6 of 6: Updating the API Gateway CloudFront Distribution. This can take around 40 minutes…";
    case "pre_configured":
    default:
      return "Getting ready to configure the custom domain…";
  }
}

export default function CustomDomainsList({
  domains = [],
  removing = {},
  retrying = {},
  onRetryClick,
  onRemoveClick,
  ...props
}) {
  function handleRemoveClick(endpoint) {
    if (
      window.confirm(
        "Are you sure you want to remove the custom domain for this endpoint?"
      )
    ) {
      onRemoveClick(endpoint);
    }
  }

  function renderDomainStatus(domain) {
    const status = domain.domainStatus;

    return (
      <div className={`status ${status}`}>
        {status === "pending" && (
          <div className="copy">
            <Glyphicon glyph="refresh" />
            <span>{stepCopyMap(domain.domainStep)}</span>
          </div>
        )}
        {status === "failed" && (
          <>
            <div className="copy">
              <span>
                {domain.domainErrorMessage ||
                  "There was an error setting up the custom domain."}
              </span>
            </div>
            <div className="controls">
              <LoaderButton
                onClick={(e) => onRetryClick(domain)}
                loading={retrying[domain.domainEndpoint]}
              >
                Retry
              </LoaderButton>
            </div>
          </>
        )}
      </div>
    );
  }

  function renderDomain(domain) {
    return (
      <div key={domain.domainEndpoint} className="domain">
        <div className="info">
          <div className="col1">
            <h4>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={domain.domainEndpoint}
                title={domain.domainEndpoint}
              >
                {stripProtocol(domain.domainEndpoint)}
              </a>
            </h4>
            <h6>
              <span>
                {domain.stageName}
                <RightChevron />
              </span>
              <span>
                {domain.serviceName}
                <RightChevron />
              </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={domain.endpoint}
                title={domain.endpoint}
              >
                {domain.endpoint}
              </a>
            </h6>
          </div>
          <div className="col2">
            <LoaderButton
              bsStyle="red-link"
              onClick={(e) => handleRemoveClick(domain)}
              loading={removing[domain.domainEndpoint]}
            >
              Remove
            </LoaderButton>
          </div>
        </div>
        {domain.domainStatus !== "success" && renderDomainStatus(domain)}
      </div>
    );
  }

  return <div className="CustomDomainsList">{domains.map(renderDomain)}</div>;
}
