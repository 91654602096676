import React, { useState } from "react";
import { Glyphicon } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import RightChevron from "./RightChevron";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import ServiceButtonGroup from "./ServiceButtonGroup";
import ScrollShadowContainer from "./ScrollShadowContainer";
import ConfirmPromoteChangesPanel from "./ConfirmPromoteChangesPanel";
import { getAppStageBuildServiceUrl } from "../lib/urlLib";
import { shortHash } from "../lib/gitLib";
import "./ConfirmPromoteModal.css";

const noop = () => {};

function statusToIcon(status) {
  const textArray = {
    changeset_success: "check",
    changeset_creating: "cog fa-spin",
    artifact_building: "cog fa-spin",
    artifact_failure: "times",
  };

  return textArray[status];
}

function statusToClass(status) {
  const textArray = {
    changeset_success: "",
    changeset_creating: "",
    artifact_building: "",
    artifact_failure: "failure",
  };

  return textArray[status];
}

function sortStatues(statuses) {
  return statuses.sort((f, s) => {
    if (f.status === "artifact_failure" && s.status === "artifact_failure") {
      return 0;
    } else if (
      f.status === "artifact_failure" &&
      s.status !== "artifact_failure"
    ) {
      return -1;
    } else if (
      f.status !== "artifact_failure" &&
      s.status === "artifact_failure"
    ) {
      return 1;
    } else if (
      f.status !== "artifact_failure" &&
      s.status !== "artifact_failure"
    ) {
      return 0;
    }

    return 0;
  });
}

function compareCommitDiffs(commitDiffs) {
  return commitDiffs.some(({ from, to }) => from !== to);
}

function renderCommitDiffLink({ from, to, link }) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <span>{shortHash(from)}</span>
      &hellip;
      <span>{shortHash(to)}</span>
      <FaIcon name="external-link" />
    </a>
  );
}

export default function ConfirmPromoteModal({
  show = false,
  error = null,
  source = null,
  status = null,
  promoteTo = "",
  changes = null,
  pathParams = null,
  reporting = false,
  commitDiffs = null,
  reportSent = false,
  confirming = false,
  errorContext = null,
  onCloseClick = noop,
  onReportClick = noop,
  onConfirmClick = noop,
  serviceStatuses = null,
  downstreamStageId = null,
}) {
  const [showStatus, setShowStatus] = useState(false);
  const [showErrorStatus, setShowErrorStatus] = useState(false);
  const [expandMinorChanges, setExpandMinorChanges] = useState(false);
  const [showMinorChanges, setShowMinorChanges] = useState(false);
  const [serviceItemChanges, setServiceItemsChanges] = useState({});
  const [minorServiceChanges, setMinorServiceChanges] = useState({});

  const showMinorServiceChanges = (i) =>
    setMinorServiceChanges((status) => ({
      ...status,
      [i]: true,
    }));
  const hideMinorServiceChanges = (i) =>
    setMinorServiceChanges((status) => ({
      ...status,
      [i]: false,
    }));

  const showServiceItemChanges = (i) =>
    setServiceItemsChanges((status) => ({
      ...status,
      [i]: true,
    }));
  const hideServiceItemChanges = (i) =>
    setServiceItemsChanges((status) => ({
      ...status,
      [i]: false,
    }));

  const disablePromote =
    (error === null && serviceStatuses === null) || // Status request is in progress
    (error !== null &&
      (error === 5106 || // Stage is busy
        error === 5107 || // No build
        error === 5108 || // Failed build
        error === 5111 || // In progress
        error === 5113)); // No active services (removing or remove failed)

  function buildServiceBuildLink(serviceId, buildId) {
    return getAppStageBuildServiceUrl(
      pathParams.ownerId,
      pathParams.appId,
      source.appStageId,
      buildId,
      serviceId
    );
  }

  function renderChanges() {
    const hasMajorChanges = changes.major.length > 0;
    const minorCopy = changes.minor.length === 1 ? "service" : "services";

    return (
      <div className="changeset">
        <SectionHeader>Infrastructure Changes</SectionHeader>
        {(hasMajorChanges || expandMinorChanges) && (
          <ConfirmPromoteChangesPanel
            changes={changes}
            reporting={reporting}
            reportSent={reportSent}
            onReportClick={onReportClick}
            showMinorChanges={showMinorChanges || expandMinorChanges}
            setShowMinorChanges={setShowMinorChanges}
            serviceItemChanges={serviceItemChanges}
            showServiceItemChanges={showServiceItemChanges}
            hideServiceItemChanges={hideServiceItemChanges}
            minorServiceChanges={minorServiceChanges}
            showMinorServiceChanges={showMinorServiceChanges}
            hideMinorServiceChanges={hideMinorServiceChanges}
            disableCollapseMinorChanges={expandMinorChanges}
          />
        )}
        {!hasMajorChanges && !expandMinorChanges && (
          <div className="empty">
            <FaIcon name="check" className="icon" />
            {changes.minor.length > 0 && (
              <>
                <p>There are no major changes to review</p>
                <p className="details">
                  <TextButton onClick={(e) => setExpandMinorChanges(true)}>
                    View minor changes in {changes.minor.length} {minorCopy}
                    <RightChevron />
                  </TextButton>
                </p>
              </>
            )}
            {changes.minor.length === 0 && (
              <p>There are no changes to review in this build.</p>
            )}
          </div>
        )}
      </div>
    );
  }

  const renderError = () => (
    <>
      <SectionHeader>Infrastructure Changes</SectionHeader>
      {status === "error" && error === 3006 && (
        <div className="error">
          <FaIcon name="check" className="icon" />
          <p>
            There are no changes to review,
            <br /> since there are no {promoteTo} artifacts for this build.
          </p>
        </div>
      )}
      {status === "error" && error === 5106 && (
        <div className="error">
          <FaIcon name="exclamation-triangle" className="icon" />
          <p>
            The {downstreamStageId} stage is currently busy.
            <br /> Please try again once it's available.
          </p>
        </div>
      )}
      {status === "error" && error === 5107 && (
        <div className="error">
          <FaIcon name="exclamation-triangle" className="icon" />
          <p>
            The {source.appStageId} stage does not have a build to promote.
            Please deploy a build to {source.appStageId}.
          </p>
        </div>
      )}
      {status === "error" && error === 5108 && (
        <div className="error">
          <FaIcon name="exclamation-triangle" className="icon" />
          <p>
            You are trying to promote an unsuccessful build from the{" "}
            {source.appStageId} stage. Please fix the build and try again.
          </p>
        </div>
      )}
      {status === "error" && error === 5111 && (
        <div className="error">
          <FaIcon name="exclamation-triangle" className="icon" />
          <p>
            The build you are trying to promote is in progress.
            <br /> Please try again once it's complete.
          </p>
        </div>
      )}
      {status === "error" && error === 5113 && (
        <div className="error">
          <FaIcon name="exclamation-triangle" className="icon" />
          <p>
            The {downstreamStageId} stage has services that are being removed.
            <br /> Please try again once they have been removed.
          </p>
        </div>
      )}
      {status === "error" && error === "AWSPermissionGeneric" && (
        <div className="error">
          <FaIcon name="exclamation-triangle" className="icon" />
          <p>
            Seed needs the <code>{errorContext.iamAction}</code> IAM permission
            to generate a change set. Add this permission and try again. Or go
            ahead and promote anyway.
          </p>
        </div>
      )}
      {status === "error" && error === 9999 && (
        <div className="error">
          <FaIcon name="exclamation-triangle" className="icon" />
          <p>
            There was a problem generating the change set.
            <br />
            You can refresh the page and try again,
            <br />
            or go ahead and promote anyway.
          </p>
        </div>
      )}
      {status === "failure" && (
        <div className="error">
          <FaIcon name="exclamation-triangle" className="icon" />
          <p>
            Failed to generate the change set for {renderErrorStatusSummary()}.
            <br />
            You can go ahead and promote anyway.
            <br />
            <TextButton onClick={(e) => setShowErrorStatus(true)}>
              Or view the details here
              <RightChevron />
            </TextButton>
          </p>
        </div>
      )}
    </>
  );

  function renderStatus(status) {
    const serviceId = status.project.service_name;

    return (
      <>
        <div>
          <FaIcon name={statusToIcon(status.status)} />
        </div>
        <div>
          <p>{serviceId}</p>
          <p>
            {status.status === "artifact_building" && (
              <>
                <span>Generating artifacts&hellip;&nbsp;</span>
                <Link
                  to={buildServiceBuildLink(serviceId, status.artifact.buildId)}
                >
                  View logs
                  <RightChevron />
                </Link>
              </>
            )}
            {status.status === "artifact_failure" && (
              <>
                <span>Failed to generate artifact.</span>&nbsp;
                <Link
                  to={buildServiceBuildLink(serviceId, status.artifact.buildId)}
                >
                  View logs
                  <RightChevron />
                </Link>
              </>
            )}
            {status.status === "changeset_creating" && (
              <span>Generating change set&hellip;</span>
            )}
            {status.status === "changeset_success" && (
              <span>Change set generated</span>
            )}
          </p>
        </div>
      </>
    );
  }

  function renderStatuses(failed) {
    const sortedStatuses = failed
      ? sortStatues(serviceStatuses)
      : serviceStatuses;

    return (
      <>
        <SectionHeader>
          {failed ? "Change Set Status" : "Generating Change Set"}
        </SectionHeader>
        <div className="loading generate">
          <ul className="statuses">
            {sortedStatuses.map((status, i) => (
              <li key={i} className={statusToClass(status.status)}>
                {renderStatus(status)}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }

  function renderStatusSummary() {
    const total = serviceStatuses.length;
    const servicesCopy = total > 1 ? "services" : "service";
    const completed = serviceStatuses.filter(
      (status) => status.status === "changeset_success"
    ).length;

    return (
      <p className="status">
        <TextButton onClick={(e) => setShowStatus(true)}>
          Completed {completed} out of {total} {servicesCopy}. View progress
          <RightChevron />
        </TextButton>
      </p>
    );
  }

  function renderErrorStatusSummary() {
    const total = serviceStatuses.filter(
      (status) => status.status === "artifact_failure"
    ).length;
    const servicesCopy = total > 1 ? "services" : "service";

    return (
      <>
        {total} {servicesCopy}
      </>
    );
  }

  function renderCommitDiffs() {
    const hasChanges = compareCommitDiffs(commitDiffs);

    return (
      <>
        <SectionHeader>Commits</SectionHeader>
        {hasChanges && <p className="copy">Compare code changes</p>}
        {!hasChanges && (
          <p className="copy">
            <FaIcon name="check" />
            No code changes
          </p>
        )}
        {commitDiffs.map((diff, i) => (
          <p key={i} className="commit">
            {renderCommitDiffLink(diff)}
          </p>
        ))}
      </>
    );
  }

  const renderPending = () => (
    <>
      <SectionHeader>Review Changes</SectionHeader>
      <div className="loading request">
        <p className="header">
          <Glyphicon glyph="refresh" />
          Generating a change set for this build&hellip;
        </p>
        {serviceStatuses && renderStatusSummary()}
      </div>
    </>
  );

  return (
    <Modal show={show} onHide={onCloseClick} className="ConfirmPromoteModal">
      <Modal.Header closeButton>
        <Modal.Title>
          {source.serviceId && (
            <>
              <ServiceButtonGroup
                serviceName={source.serviceId}
                serviceType={source.serviceType}
              />
              <span className="separator">/</span>
              Promote
            </>
          )}
          {!source.serviceId && "Promote"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="summary">
          <ScrollShadowContainer>
            <div className="graphic">
              <div className="locations">
                <SectionHeader>From</SectionHeader>
                <span>{source.appStageId}</span>
              </div>
              <Glyphicon glyph="arrow-right" />
              <div className="locations">
                <SectionHeader>To</SectionHeader>
                <span>{downstreamStageId}</span>
              </div>
            </div>
          </ScrollShadowContainer>
          {commitDiffs && (
            <div className="commit-diff">{renderCommitDiffs()}</div>
          )}
        </div>
        <div className="changes">
          {status === "success" && renderChanges()}
          {(status === "failure" || status === "error") &&
            !showErrorStatus &&
            renderError()}
          {(status === "failure" || status === "error") &&
            showErrorStatus &&
            renderStatuses(true)}
          {status === "pending" && showStatus && renderStatuses()}
          {status === "pending" && !showStatus && renderPending()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <LoaderButton bsStyle="link" onClick={onCloseClick}>
          Cancel
        </LoaderButton>
        <LoaderButton
          bsSize="large"
          bsStyle="warning"
          loading={confirming}
          onClick={onConfirmClick}
          disabled={disablePromote}
        >
          Promote to {promoteTo}
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
