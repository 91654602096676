import React from "react";
import {
  Dot,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import GraphTooltip from "./GraphTooltip";
import LoadingSpinner from "./LoadingSpinner";
import { formatDecimals } from "../lib/stringLib";
import "./GraphPanel.css";

export const keyCopyMap = {
  vP99: "P99",
  vP95: "P95",
  vP90: "P90",
  vAvg: "Avg",
};

export const keyColorMap = {
  vP99: "#BE7A2B",
  vP95: "#8D6150",
  vP90: "#53437D",
  vAvg: "#68C77E",
};

const height = 200;
const margins = { top: 5, right: 5, left: 0, bottom: 5 };

function generateEmptyPoints(dataKeys) {
  const points = {};

  dataKeys.forEach((dataKey) => (points[dataKey] = 0));

  return points;
}

function compareProps(props, newProps) {
  const data = props.data;
  const dataKeys = props.dataKeys;
  const newData = newProps.data;
  const newDataKeys = newProps.dataKeys;

  if (!data || data.length === 0) {
    return false;
  }

  function lastValues(data, lastIndex, dataKeys) {
    const lastDatum = data[data.length - (1 + lastIndex)];

    if (!lastDatum) {
      return true;
    }

    return dataKeys.map((dataKey) => lastDatum[dataKey]).join("-");
  }

  return (
    data.length === newData.length &&
    data[0].t === newData[0].t &&
    data[data.length - 1].t === newData[newData.length - 1].t &&
    lastValues(data, 0, dataKeys) === lastValues(newData, 0, newDataKeys) &&
    lastValues(data, 1, dataKeys) === lastValues(newData, 1, newDataKeys) &&
    lastValues(data, 2, dataKeys) === lastValues(newData, 2, newDataKeys) &&
    lastValues(data, 3, dataKeys) === lastValues(newData, 3, newDataKeys) &&
    lastValues(data, 4, dataKeys) === lastValues(newData, 4, newDataKeys)
  );
}

function GraphPanel({
  name,
  dataKeys,
  data = [],
  onPointClick,
  loading = false,
}) {
  const loadingData = [{ t: 1518552317 }];

  let span;
  let Chart;
  let isRedColor;
  let emptyPoints;
  let isTimeValues;
  let isEmpty = true;
  let formattedData = [];

  if (!loading) {
    isRedColor = name === "error";
    emptyPoints = generateEmptyPoints(dataKeys);
    isTimeValues = name === "latency" || name === "duration";
    span = Math.floor((data[data.length - 1].t - data[0].t) / (60 * 1000));

    data.forEach((element) => {
      const notEmpty = dataKeys.some((dataKey) =>
        element.hasOwnProperty(dataKey)
      );

      isEmpty = isEmpty && !notEmpty;
      formattedData.push(
        notEmpty
          ? element
          : {
              ...element,
              isNull: true,
              ...emptyPoints,
            }
      );
    });

    Chart = isTimeValues ? LineChart : BarChart;
  }

  function formatDate(timestamp) {
    let opts;

    if (span <= 60) {
      opts = {
        hour: "numeric",
        minute: "numeric",
      };
    } else if (span <= 24 * 60) {
      opts = {
        weekday: "short",
        hour: "numeric",
        minute: "numeric",
      };
    } else if (span <= 7 * 24 * 60) {
      opts = {
        minute: "numeric",
        hour: "numeric",
        day: "numeric",
        month: "short",
      };
    } else if (span <= 30 * 24 * 60) {
      opts = {
        day: "numeric",
        month: "short",
        hour: "numeric",
      };
    } else {
      opts = {
        hour: "numeric",
        month: "short",
        day: "numeric",
      };
    }

    return new Date(timestamp).toLocaleString("en-us", opts);
  }

  function formatLoadingXTip() {
    return "";
  }

  function formatXTick(tick) {
    return formatDate(tick);
  }

  function formatYTick(tick) {
    return tick === 0 ? "" : tick;
  }

  function formatValue(value) {
    return isTimeValues ? formatDecimals(value) : value;
  }

  function formatTooltipLabel(dataKey) {
    switch (dataKey) {
      case "v":
        switch (name) {
          case "count":
            return "Requests";
          case "error":
            return "Errors";
          case "invocations":
            return "Invocations";
          default:
            return name;
        }
      case "vAvg":
      case "vP90":
      case "vP95":
      case "vP99":
        return keyCopyMap[dataKey];
      default:
        return dataKey;
    }
  }

  function handleDotClick({ index }) {
    onPointClick(index);
  }

  function handleBarClick(data, index) {
    onPointClick(index);
  }

  function renderTooltip({ active, label, payload }) {
    if (active) {
      if (payload.length === 0 || payload[0].payload.isNull) {
        return null;
      }

      label = formatDate(label);
      const values = payload[0].payload;

      return (
        <GraphTooltip
          time={label}
          values={dataKeys.map((dataKey) => [
            formatTooltipLabel(dataKey),
            formatValue(values[dataKey]),
            isTimeValues ? "ms" : null,
            isTimeValues ? keyColorMap[dataKey] : null,
          ])}
        />
      );
    }

    return null;
  }

  function renderActiveDot(dotProps) {
    const payload = dotProps.payload;
    const isNull = payload ? payload.isNull : false;

    return !isNull ? (
      <Dot onClick={handleDotClick} cursor="pointer" {...dotProps} />
    ) : null;
  }

  function renderDataKey(dataKey) {
    if (isTimeValues) {
      return (
        <Line
          connectNulls
          dot={false}
          key={dataKey}
          type="monotone"
          dataKey={dataKey}
          isAnimationActive={false}
          activeDot={renderActiveDot}
          stroke={keyColorMap[dataKey]}
        />
      );
    } else {
      return (
        <Bar
          key={dataKey}
          cursor="pointer"
          dataKey={dataKey}
          onClick={handleBarClick}
          isAnimationActive={false}
          fill={isRedColor ? "url(#colorRed)" : "url(#colorDefault)"}
        />
      );
    }
  }

  return (
    <div className="GraphPanel">
      {loading && (
        <>
          <ResponsiveContainer width="100%" height={height}>
            <LineChart margin={margins} data={loadingData}>
              <XAxis
                dataKey="t"
                tickLine={false}
                tickFormatter={formatLoadingXTip}
              />
            </LineChart>
          </ResponsiveContainer>
          <LoadingSpinner />
        </>
      )}
      {!loading && (
        <>
          <ResponsiveContainer width="100%" height={height}>
            <Chart margin={margins} barCategoryGap="4%" data={formattedData}>
              <defs>
                <linearGradient id="colorDefault" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#53437D" stopOpacity={0.9} />
                  <stop offset="95%" stopColor="#53437D" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#BE2F2B" stopOpacity={0.9} />
                  <stop offset="95%" stopColor="#BE2F2B" stopOpacity={0.3} />
                </linearGradient>
              </defs>
              <Tooltip
                cursor={false}
                content={renderTooltip}
                isAnimationActive={false}
              />
              {!isEmpty && (
                <YAxis
                  tickLine={false}
                  allowDecimals={false}
                  tickFormatter={formatYTick}
                />
              )}
              <XAxis
                dataKey="t"
                tickLine={false}
                interval="preserveEnd"
                tickFormatter={formatXTick}
              />
              {dataKeys.map(renderDataKey)}
            </Chart>
          </ResponsiveContainer>
          {isEmpty && <div className="empty">No metrics collected</div>}
        </>
      )}
    </div>
  );
}

export default React.memo(GraphPanel, compareProps);
