import React from "react";
import { MenuItem } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import PipelineDropdownButton from "./PipelineDropdownButton";
import "./PipelineTablePromoteCell.css";

function statusToCopy(status) {
  const textArray = {
    queued: "Queued",
    skipped: "Skipped",
    success: "Success",
    failure: "Failure",
    deploying: "In Progress",
  };

  return textArray[status];
}

function actionToCopy(action) {
  const textArray = {
    deploy_branch: "Deploy",
    deploy_pr: "Deploy",
    deploy_cli: "Deploy",
    deploy_manual: "Deploy",
    promote: "Promote",
    rollback: "Rollback",
  };

  return textArray[action];
}

export default function PipelineTablePromoteCell({
  appStage,
  srcStage,
  onPromote,
  latestBuild,
  dropup = true,
  isDeleting = false,
  isProduction = false,
  downstreamStages = [],
}) {
  function renderProd() {
    return (
      <div className="prod">
        {!latestBuild && <span>Promoted from: &#8212;</span>}
        {latestBuild && (
          <>
            {!srcStage && (
              <span>
                {actionToCopy(latestBuild.action)}{" "}
                {statusToCopy(latestBuild.status)}
              </span>
            )}
            {srcStage && (
              <>
                <span>Promoted from:&nbsp;</span>
                <span>{srcStage}</span>
              </>
            )}
          </>
        )}
      </div>
    );
  }

  function renderNotProd() {
    return downstreamStages.length > 1 ? (
      <PipelineDropdownButton
        pullRight
        id="btnPromote"
        title="Promote"
        dropup={dropup}
        disabled={isDeleting || !latestBuild}
      >
        {downstreamStages.map((stage) => (
          <MenuItem
            key={stage.name}
            eventKey={stage.name}
            onSelect={() => onPromote(appStage, stage)}
          >
            Promote to {stage.name}
          </MenuItem>
        ))}
      </PipelineDropdownButton>
    ) : (
      <LoaderButton
        onClick={() => onPromote(appStage, downstreamStages[0])}
        disabled={isDeleting || !latestBuild}
      >
        Promote
      </LoaderButton>
    );
  }

  return (
    <div className="PipelineTablePromoteCell">
      {isProduction ? renderProd() : renderNotProd()}
    </div>
  );
}
