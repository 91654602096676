import React, { useRef, useEffect } from "react";

const stateMap = {
  "": "",

  completed: "-success",
  success: "-success",

  in_progress: "-build",
  deploying: "-build",
  building: "-build",

  failed: "-fail",
  failure: "-fail",

  queued: "-skip",
  skipped: "-skip",
};

function replaceFavicon16(str, favicon) {
  return str.replace(/favicon(-success|-fail|-build|-skip)?-16x16/, favicon);
}

function replaceFavicon32(str, favicon) {
  return str.replace(/favicon(-success|-fail|-build|-skip)?-32x32/, favicon);
}

export default function withFaviconChange(Comp) {
  return function ComponentWithFaviconChange(props) {
    const currentState = useRef("");

    useEffect(() => {
      // Run on unmount
      return () => {
        setStatus("");
      };
    }, []);

    function setStatus(state) {
      if (currentState.current === state || !stateMap.hasOwnProperty(state)) {
        return;
      }

      const stateStr = stateMap[state];

      try {
        const favicon16 = document.getElementById("favicon-16");
        const favicon32 = document.getElementById("favicon-32");

        favicon16.href = replaceFavicon16(
          favicon16.href,
          `favicon${stateStr}-16x16`
        );
        favicon32.href = replaceFavicon32(
          favicon32.href,
          `favicon${stateStr}-32x32`
        );
      } catch (e) {
        // Ignore errors if for some reason the browser cannot do this
      }

      currentState.current = state;
    }

    function setSuccess() {
      setStatus("success");
    }

    function setFailure() {
      setStatus("fail");
    }

    return (
      <Comp
        favicon={{
          setStatus,
          setSuccess,
          setFailure,
        }}
        {...props}
      />
    );
  };
}
