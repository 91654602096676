import React, { useState } from "react";
import { FormGroup, HelpBlock, FormControl } from "react-bootstrap";
import Modal from "./Modal";
import TextButton from "./TextButton";
import LoaderButton from "./LoaderButton";
import RightChevron from "./RightChevron";
import PolicyDisplayPanel from "./PolicyDisplayPanel";
import { useFormReducer } from "../lib/hooksLib";
import "./NewIamRoleModal.css";

const noop = () => {};

const iamHelpUrl = "https://seed.run/docs/customizing-your-iam-policy";

function stringifyPolicy(policy) {
  return JSON.stringify(policy, null, "  ");
}

function EditPolicyComponent({
  errorMessage,
  loading = false,
  defaultPolicyObject,
  onCancelClick = noop,
  onSubmitClick = noop,
}) {
  const defaultPolicy = stringifyPolicy(defaultPolicyObject);

  let [formData, formDispatch] = useFormReducer({
    policy: defaultPolicy,
  });
  let [showErrorMessage, setShowErrorMessage] = useState(true);

  const errorCs = showErrorMessage && errorMessage ? "error" : "";

  function canSubmit() {
    return formData.values.policy !== defaultPolicy;
  }

  function handleFieldChange(event) {
    const { value } = event.target;

    formDispatch({
      value,
      id: "policy",
      type: "edit",
    });
    setShowErrorMessage(false);
  }

  function handleSubmitClick(event) {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    onSubmitClick(event, formData.values.policy);

    setShowErrorMessage(true);
  }

  return (
    <form
      onSubmit={handleSubmitClick}
      className={`EditPolicyComponent ${errorCs}`}
    >
      <FormGroup controlId="policy">
        <FormControl
          componentClass="textarea"
          onChange={handleFieldChange}
          value={formData.values.policy}
        />
      </FormGroup>
      <div className="controls">
        {showErrorMessage && errorMessage && (
          <HelpBlock>{errorMessage}</HelpBlock>
        )}
        <LoaderButton
          loading={loading}
          disabled={!canSubmit()}
          onClick={handleSubmitClick}
        >
          Update
        </LoaderButton>
        <TextButton disabled={loading} onClick={onCancelClick}>
          Cancel
        </TextButton>
      </div>
    </form>
  );
}

export default function NewIamRoleModal({
  show,
  editing,
  updating,
  hasUpdated,
  seedPolicy,
  updateError,
  onCloseClick,
  onUpdateClick,
  onCustomizeClick,
  serverlessPolicy,
  cloudFormationUrl,
  onUpdateCancelClick,
}) {
  return (
    <Modal show={show} onHide={onCloseClick} className="NewIamRoleModal">
      <Modal.Header closeButton>
        <Modal.Title>Create an IAM role</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ol className="steps">
          <li className="step">
            <p>Review the IAM permissions Seed needs.</p>
            <PolicyDisplayPanel policy={seedPolicy} />
          </li>
          <li className="step">
            <p>
              Since Seed deploys your app to your AWS account, optionally
              customize the permissions your app needs.&nbsp;
              <a target="_blank" href={iamHelpUrl} rel="noopener noreferrer">
                Learn more about customizing your deploy IAM policy.
              </a>
            </p>
            {!editing && (
              <PolicyDisplayPanel
                customize
                hasUpdated={hasUpdated}
                policy={serverlessPolicy}
                className="serverless-policy"
                onCustomizeClick={onCustomizeClick}
              />
            )}
            {editing && (
              <EditPolicyComponent
                loading={updating}
                errorMessage={updateError}
                onSubmitClick={onUpdateClick}
                onCancelClick={onUpdateCancelClick}
                defaultPolicyObject={serverlessPolicy}
              />
            )}
          </li>
          <li className="step">
            <p>
              Create an IAM Role with the above permissions through
              CloudFormation.
            </p>
            <LoaderButton
              target="_blank"
              bsSize="large"
              bsStyle="warning"
              href={cloudFormationUrl}
            >
              Create an IAM Role using CloudFormation
              <RightChevron />
            </LoaderButton>
            <p>
              Select the{" "}
              <b>
                I acknowledge that AWS CloudFormation might create IAM resources
              </b>{" "}
              checkbox and hit the <b>Create</b> button at the bottom of the
              page.
            </p>
          </li>
          <li className="step">
            <p>Wait for the stack to complete creating&hellip;</p>
          </li>
          <li className="step">
            <p>
              Expand the <b>Output</b> section, copy the <b>RoleArn</b>, and
              paste it!
            </p>
          </li>
        </ol>
      </Modal.Body>

      <Modal.Footer>
        <LoaderButton bsSize="large" onClick={onCloseClick}>
          Done
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
