export function pathToCamelCase(str) {
  let ret = "";

  // Get last part that is not empty
  str
    .split("/")
    .reverse()
    .find((part) => {
      ret = part.replace(/[^a-z0-9-]/gi, "").toLowerCase();
      return ret !== "";
    });
  return ret;
}

export function truncate(fullStr, strLen, separator = "…") {
  if (fullStr.length <= strLen) {
    return fullStr;
  }

  const sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
}

export function obfuscate(key) {
  const obfuscateStr = Array(15).join("\u2022");
  const start = key.slice(0, 2);
  const end = key.slice(key.length - 2);

  return `${start}${obfuscateStr}${end}`;
}

export function formatDollars(amount) {
  const dollars = Math.floor(amount / 100);
  const dollarsStr = String(dollars);
  const centsStr = String(amount - dollars * 100);
  const formatCents = centsStr.length === 1 ? `0${centsStr}` : centsStr;

  return `${dollarsStr}.${formatCents}`;
}

export function formatDecimals(number) {
  return parseFloat(Math.round(number * 100) / 100).toFixed(2);
}

export function formatLargeNumbers(num) {
  return num > 999
    ? num > 999999
      ? num > 999999999
        ? `${(num / 1000000000).toFixed(1)}b`
        : `${(num / 1000000).toFixed(1)}m`
      : `${(num / 1000).toFixed(1)}k`
    : num;
}

export function formatKeyMetricsNumbers(value) {
  return value < 10000
    ? [value.toFixed(0), "ms"]
    : [(value / 1000).toFixed(2), "s"];
}

export function formatMetricsNumbers(value, unit, source) {
  if (unit === "ms") {
    if (source === "api") {
      return `${value.toFixed(0)} ms`;
    } else {
      return value < 30000
        ? `${value.toFixed(0)} ms`
        : `${(value / 1000).toFixed(2)} s`;
    }
  }

  return formatLargeNumbers(value);
}

export function normalizeSearchPath(filePath) {
  // Add leading /
  return filePath.startsWith("/") ? filePath : `/${filePath}`;
}

export function getServiceNameFromPath(path) {
  return path
    .replace(/\/$/, "")
    .replace(/[/]?(serverless.(yml|yaml|js)|sst.json|sst.config.ts)$/, "")
    .split("/")
    .pop();
}

export function getFirstLine(str) {
  return str.split(/\n/)[0];
}

export function getIndicesOf(searchStr, str) {
  const searchStrLen = searchStr.length;

  let index;
  let indices = [];
  let startIndex = 0;

  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }

  return indices;
}

export function getHighlightTermsFromFilterString(filter) {
  const noExcludes = filter
    .replace(/-\w+/g, "") // -hello
    .replace(/-['"][^'"]*['"]/g, "") // -"hello world"
    .trim()
    .replace(/\s+/g, " ");

  if (noExcludes === "") {
    return [];
  }

  let phrases = [];

  const noPhrases = noExcludes
    // Remove phrases
    .replace(/('[^']*')|("[^"]*")/g, (phrase) => {
      phrases.push(phrase.replace(/^['"]|['"]$/g, ""));
      return "";
    })
    .replace(/\s+/g, " ");

  const noNested = noPhrases
    .split(" ")
    // Remove terms that are a part of bigger terms
    // Ex: los loss
    .filter((term) => noPhrases.replace(term, "").indexOf(term) === -1);

  return phrases.concat(noNested);
}

export function formatReportDateSlug(slug, withYear = true) {
  // Handle both slugs
  // daily/jan-28-2020
  // jan-28-2020
  slug = slug.replace(/^\w+\//, "");

  slug = withYear ? slug : slug.replace(/-\d+$/, "");

  return (slug.charAt(0).toUpperCase() + slug.substring(1))
    .replace(/-/, " ")
    .replace(/-/, ", ");
}

export function formatStackNames(stackNames) {
  const length = stackNames.length;
  switch (length) {
    case 0:
      return "No stacks";
    case 1:
      return stackNames[0];
    case 2:
      return `${stackNames[0]} and ${stackNames[1]}`;
    case 3:
      return `${stackNames[0]}, ${stackNames[1]}, and ${stackNames[2]}`;
    default:
      return `${stackNames[0]}, ${stackNames[1]}, and ${
        length - 2
      } more stacks`;
  }
}

export function searchResources(resourceLinks, stageNames, searchText, limit) {
  searchText = searchText.toLowerCase();

  // Format input
  // - tokenize input
  const words = searchText.split(" ").filter((word) => word.trim().length > 0);
  const wordLen = words.length;
  // - check if still editing the last word, if so store the index of the word
  const wordIndexInEdit = searchText.slice(-1) === " " ? -1 : wordLen - 1;

  // Parse search text for keywords
  stageNames = stageNames.map((name) => name.toLowerCase());
  let stageWord;
  let stageWordInEdit;
  let typeWord;
  let typeWordInEdit;
  let matchedStages = [];
  let matchedTypes = [];
  if (wordLen >= 1) {
    // check if first word matches any stage
    matchedStages =
      wordIndexInEdit === 0
        ? stageNames.filter((name) => name.startsWith(words[0]))
        : stageNames.filter((name) => name === words[0]);
    if (matchedStages.length > 0) {
      stageWord = words.shift();
      stageWordInEdit = wordIndexInEdit === 0;
    }

    // if first word matched any stage, check if second word matches any type
    if (matchedStages.length > 0) {
      if (wordLen >= 2) {
        matchedTypes =
          wordIndexInEdit === 1
            ? ["api", "lambda"].filter((type) => type.startsWith(words[0]))
            : ["api", "lambda"].filter((type) => type === words[0]);
        if (matchedTypes.length > 0) {
          typeWord = words.shift();
          typeWordInEdit = wordIndexInEdit === 1;
        }
      }
    }
    // if first word did NOT match any stage, check if first word matches any type
    else {
      matchedTypes =
        wordIndexInEdit === 0
          ? ["api", "lambda"].filter((type) => type.startsWith(words[0]))
          : ["api", "lambda"].filter((type) => type === words[0]);
      if (matchedTypes.length > 0) {
        typeWord = words.shift();
        typeWordInEdit = wordIndexInEdit === 0;
      }
    }
  }

  // Parse search text for url
  const url = words.join(" ").split("?")[0].split("#")[0];

  //console.log('search', { matchedStages, matchedTypes, url });

  let results = [];
  resourceLinks.forEach((resourceLink) => {
    const {
      fullName,
      stage,
      type,
      customDomain,
      apigDomain,
      apiMethods,
      apiPaths,
    } = resourceLink;

    const nameOrUrlWords = [...words];
    let matchedStageText;
    let matchedTypeText;
    let matchedName;
    let matchedNamePositions;
    let matchedUrlText;
    let matchedUrlApiPath;
    let matchedUrlApiMethod;
    let matchedUrlPartsLen;
    let matchedUrlMatchAllPartsIndices;

    // type match
    // note: if typeWord is not matched, then typeWord becomes a name or url word
    if (typeWord) {
      if (matchedTypes.includes(type)) {
        matchedTypeText = typeWord;
      } else {
        nameOrUrlWords.unshift(typeWord);
      }
    }

    // stage match
    // note: if stageWord is not matched, then stageWord becomes a name or url word
    if (stageWord) {
      if (matchedStages.includes(stage)) {
        matchedStageText = stageWord;
      } else {
        nameOrUrlWords.unshift(stageWord);
      }
    }

    // fullName match
    const fullNameLower = fullName.toLowerCase();
    const matchedPositions = [];
    let matchesName = false;
    if (nameOrUrlWords.length > 0) {
      matchesName = nameOrUrlWords.every((word) => {
        const indices = getIndicesOf(word, fullNameLower);
        indices.forEach((index) =>
          matchedPositions.push([index, index + word.length])
        );
        return indices.length > 0;
      });
      if (matchesName) {
        matchedName = matchesName;
      }
    }
    // note: also try matching stageWord and typeWord if they are in edit,
    //       but do not alter the matched result
    if (stageWord && stageWordInEdit) {
      const indices = getIndicesOf(stageWord, fullNameLower);
      indices.forEach((index) =>
        matchedPositions.push([index, index + stageWord.length])
      );
    }
    if (typeWord && typeWordInEdit) {
      const indices = getIndicesOf(typeWord, fullNameLower);
      indices.forEach((index) =>
        matchedPositions.push([index, index + typeWord.length])
      );
    }
    matchedNamePositions = matchedPositions;

    ///////////////
    // url match
    ///////////////
    if (nameOrUrlWords.length === 1 && apiPaths && apiPaths.length > 0) {
      // strip out api domain
      let urlPath = url;
      if (url.startsWith(customDomain)) {
        urlPath = url.substring(customDomain.length);
        urlPath = urlPath === "" ? "/" : urlPath;
      } else if (url.startsWith(apigDomain)) {
        urlPath = url.substring(apigDomain.length);
        urlPath = urlPath === "" ? "/" : urlPath;
      }

      // searched url path has to begin with '/'
      if (urlPath.startsWith("/")) {
        apiPaths.some((apiPath, pathInd) => {
          // ie. urlPath: /path/to/resource
          // ie. apiPath: /path/{var}/resource
          const urlParts = urlPath.split("/");
          const urlPartsLen = urlParts.length;

          // validate: searched urlPath has more parts than apiPath
          const apiParts = apiPath.split("/");
          const apiPartsLen = apiParts.length;

          let matchedUrl = false;
          if (apiPath === "/{proxy+}") {
            matchedUrl = true;
            matchedUrlApiPath = apiPath;
            matchedUrlApiMethod = apiMethods[pathInd];
            matchedUrlText = apiPath;
            matchedUrlPartsLen = apiPartsLen;
            matchedUrlMatchAllPartsIndices = apiPartsLen - 1;
          } else if (urlPartsLen <= apiPartsLen) {
            matchedUrl = urlParts.every(
              (urlPart, partInd) =>
                // algorithm
                // - the part is exact match, OR
                // - the part in api path is match all, OR
                // - the part is the last part in searched url and matches partial api path
                urlPart === apiParts[partInd] ||
                apiParts[partInd].startsWith("{") ||
                (partInd === urlParts.length - 1 &&
                  apiParts[partInd].startsWith(urlPart))
            );
            if (matchedUrl) {
              matchedUrlApiPath = apiPath;
              matchedUrlApiMethod = apiMethods[pathInd];
              matchedUrlText = apiParts
                .slice(0, urlPartsLen - 1)
                .concat(
                  apiParts[urlPartsLen - 1].startsWith("{")
                    ? apiParts[urlPartsLen - 1]
                    : urlParts[urlPartsLen - 1]
                )
                .join("/");
              matchedUrlPartsLen = apiPartsLen;
              matchedUrlMatchAllPartsIndices = apiParts
                .map((part, index) => ({
                  isMatchAll: part.startsWith("{"),
                  index,
                }))
                .filter(({ isMatchAll }) => isMatchAll)
                .map(({ index }) => index);
            }
          }
          return matchedUrl;
        });
      }
    }

    // If has name or url words => count if either name or url matches
    // If no name or url words (ie. only stage and api words) => could if any criteria matches
    const isMatched =
      nameOrUrlWords.length > 0
        ? matchedName || matchedUrlText
        : matchedStageText || matchedTypeText || matchedName || matchedUrlText;
    if (isMatched) {
      results.push({
        ...resourceLink,
        matchedStageText,
        matchedTypeText,
        matchedName,
        matchedNamePositions,
        matchedUrlApiPath,
        matchedUrlApiMethod,
        matchedUrlText,
        matchedUrlPartsLen,
        matchedUrlMatchAllPartsIndices,
      });
    }
  });

  const methodSortOrder = [
    "GET",
    "POST",
    "PUT",
    "PATCH",
    "DELETE",
    "ANY",
    "HEAD",
    "OPTIONS",
  ];
  const defaultSortOrder = methodSortOrder.length;

  results = results.sort((a, b) => {
    if (!a.matchedUrlText || !b.matchedUrlText) {
      return 0;
    }

    // sort by longestest exact match by checking when first match all comes in
    for (let i = 0, l = a.matchedUrlMatchAllPartsIndices.length; i < l; i++) {
      // case: b has no more { => better match
      // case: b has further { position => better match
      if (
        !b.matchedUrlMatchAllPartsIndices[i] ||
        b.matchedUrlMatchAllPartsIndices[i] >
          a.matchedUrlMatchAllPartsIndices[i]
      ) {
        return 1;
      }
      // case: new match has ealier { position => worse match
      else if (
        b.matchedUrlMatchAllPartsIndices[i] <
        a.matchedUrlMatchAllPartsIndices[i]
      ) {
        return -1;
      }
      // case: both matches have same { position => check next { position
      else {
      }
    }
    if (
      a.matchedUrlMatchAllPartsIndices.length <
      b.matchedUrlMatchAllPartsIndices.length
    ) {
      return -1;
    }

    // sort lesser parts first, ie.
    //  - come first: /path/{any}
    //  - come after: /path/{any}/later
    if (a.matchedUrlPartsLen !== b.matchedUrlPartsLen) {
      return a.matchedUrlPartsLen - b.matchedUrlPartsLen;
    }

    // sort by http method
    let methodA = methodSortOrder.indexOf(a.matchedUrlApiMethod);
    let methodB = methodSortOrder.indexOf(b.matchedUrlApiMethod);
    methodA = methodA === -1 ? defaultSortOrder : methodA;
    methodB = methodB === -1 ? defaultSortOrder : methodB;
    if (methodA !== methodB) {
      return methodA - methodB;
    }

    return 0;
  });

  /*
  // If has multiple url matches:
  //  1. matches with the same parts => only one can be best match
  //  2. matches with more same parts => can only be children of best match
  let urlBestMatchApiPath;
  let urlBestMatchPartsLen;
  let urlBestMatchMatchAllPartsIndices;
  results.forEach(result => {
    if ( ! result.matchedUrlText) { return; }

    const isBetterMatch = () => {
      if (result.matchedUrlPartsLen < urlBestMatchPartsLen) { return true; }
      if (result.matchedUrlPartsLen > urlBestMatchPartsLen) { return false; }
      for (let i=0, l=urlBestMatchMatchAllPartsIndices.length; i<l; i++) {
        // case: new match has no more { => better match
        // case: new match has further { position => better match
        if ( ! result.matchedUrlMatchAllPartsIndices[i]
          || result.matchedUrlMatchAllPartsIndices[i] > urlBestMatchMatchAllPartsIndices[i]) {
          return true;
        }
        // case: new match has ealier { position => worse match
        else if (result.matchedUrlMatchAllPartsIndices[i] < urlBestMatchMatchAllPartsIndices[i]) {
          return false;
        }
        // case: both matches have same { position => check next { position
        else {
        }
      }
    }

    if ( ! urlBestMatchApiPath || isBetterMatch()) {
      urlBestMatchApiPath = result.matchedUrlApiPath;
      urlBestMatchPartsLen = result.matchedUrlPartsLen;
      urlBestMatchMatchAllPartsIndices = result.matchedUrlMatchAllPartsIndices;
    }
  });

  if (urlBestMatchApiPath) {
    results = results.filter(result => {
      if ( ! result.matchedUrlText) { return true; }
      return result.matchedUrlApiPath.startsWith(urlBestMatchApiPath);
    });
  }
  */

  return results.slice(0, limit);
}
