import React, { useRef, useState, useEffect, Fragment } from "react";
import { Glyphicon } from "react-bootstrap";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import SectionHeader from "./SectionHeader";
import LoadingSpinner from "./LoadingSpinner";
import RequestLogLine from "./RequestLogLine";
import PermissionErrorModal from "./PermissionErrorModal";
import {
  getAPIError,
  isAwsPermissionError,
  isAwsThrottlingError,
} from "../lib/errorLib";
import {
  buildRequestsWithGrouping,
  findSingleRequestForLambda,
  parseLambdaLogMetadata,
} from "../lib/logLib";
import { useInitial } from "../lib/hooksLib";
import "./IssueLogPanel.css";

export default function IssueLogPanel({
  logData,
  logError,
  logTimestamp,
  logStreamName,
  onFullRequestLoaded = null,
}) {
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  //////////
  // Load //
  //////////
  let xrayBaseLink;
  const request = useRef(null);

  // Using useInitial because the callback is being used as dependencies
  onFullRequestLoaded = useInitial(onFullRequestLoaded);

  if (logData) {
    xrayBaseLink = logData.xrayBaseLink;

    const runtime = logData.runtime;
    const events = logData.events.map((event) => ({
      ...event,
      meta: parseLambdaLogMetadata(event, runtime),
    }));
    const requests = buildRequestsWithGrouping(events);
    request.current = findSingleRequestForLambda(requests, logTimestamp);
  }

  // request is fully loaded if:
  // - no startTime
  // - has startTime && endTime
  const isFullRequestLoaded =
    request.current &&
    request.current.meta &&
    (!request.current.meta.startTime ||
      (request.current.meta.startTime && request.current.meta.endTime));

  useEffect(() => {
    if (isFullRequestLoaded) {
      onFullRequestLoaded();
    }
  }, [isFullRequestLoaded, onFullRequestLoaded]);

  ////////////
  // Render //
  ////////////

  function renderHeader() {
    const { meta } = request.current || {};
    return (
      <div className="controls">
        <SectionHeader>Logs</SectionHeader>
        {request.current && meta.xrayTraceId && (
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${xrayBaseLink}${meta.xrayTraceId}`}
            >
              X-Ray Trace
              <FaIcon name="external-link" />
            </a>
          </div>
        )}
      </div>
    );
  }

  function renderLogs() {
    const { logs } = request.current;
    return (
      <div className="logs">
        {logs.map((log, i) => (
          <Fragment key={i}>
            <RequestLogLine isUTC key={i} log={log} />
            <div className="divider"></div>
          </Fragment>
        ))}
      </div>
    );
  }

  function renderInitialErrorSign() {
    let message;

    const errorData = getAPIError(logError);
    const code = errorData && errorData.code;

    if (isAwsPermissionError(logError)) {
      message = (
        <>
          Seed doesn&apos;t have the right permissions to display Lambda logs.{" "}
          <TextButton onClick={() => setShowPermissionsModal(true)}>
            Click here to view the IAM permissions
          </TextButton>{" "}
          we need.
        </>
      );
    } else {
      switch (code) {
        case 8107:
          message = "There are no logs available for this Lambda function.";
          break;
        case "AWSLambdaFunctionNotExist":
          message =
            "There are no logs available, since the the Lambda function has been removed.";
          break;
        case "AWSCloudWatchLogsLogStreamNotExist":
          message =
            "Logs have been removed by CloudWatch. Please pick a longer log retention period.";
          break;
        default:
          message =
            "There was a problem loading the logs. Please refresh the page and try again.";
      }
    }

    return (
      <div className="error-sign">
        <span>{message}</span>
      </div>
    );
  }

  function renderRequestNotFoundSign() {
    return (
      <div className="error-sign">
        <span>The Lambda log for this request could not be found.</span>
      </div>
    );
  }

  function renderTailingSign() {
    return (
      <div className="tailing">
        <span>
          <Glyphicon glyph="flash" />
          Waiting for the request to complete&hellip;
        </span>
      </div>
    );
  }

  return (
    <div className="IssueLogPanel">
      {renderHeader()}

      <div className="content">
        {!request.current && (
          <>
            {logError &&
              !isAwsThrottlingError(logError) &&
              renderInitialErrorSign()}
            {logError && isAwsThrottlingError(logError) && <LoadingSpinner />}
            {!logError && !logData && <LoadingSpinner />}
            {!logError && logData && renderRequestNotFoundSign()}
          </>
        )}

        {request.current && (
          <>
            {renderLogs()}
            {!isFullRequestLoaded && renderTailingSign()}
          </>
        )}
      </div>

      <PermissionErrorModal
        type="logs"
        show={showPermissionsModal}
        onCloseClick={() => setShowPermissionsModal(false)}
      />
    </div>
  );
}
