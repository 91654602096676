import React, { useState } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import TextButton from "./TextButton";
import SectionInfo from "./SectionInfo";
import LoaderButton from "./LoaderButton";
import { useFormReducer } from "../lib/hooksLib";
import "./MachineSettingsPanel.css";

const defaultMachine = "1x";

const helpUrl = "https://seed.run/docs/build-machine-types";

export default function MachineSettingsPanel({
  buildMachine = defaultMachine,
  updated = false,
  updating = false,
  showV2Only = false,
  onUpdateClick,
}) {
  const [showStatus, setShowStatus] = useState(updated);
  const [
    { values: formData, isDirty },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ buildMachine });

  const editing = isDirty.buildMachine;

  function handleCancelClick() {
    setShowStatus(false);
    formDispatch({ type: "reset" });
  }

  function handleUpdateClick(event) {
    event.preventDefault();

    onUpdateClick(formData.buildMachine);
  }

  function renderForm() {
    return (
      <Form className="form" onSubmit={handleUpdateClick}>
        <FormGroup controlId="buildMachine">
          <FormControl
            bsSize="large"
            disabled={updating}
            componentClass="select"
            value={formData.buildMachine}
            onChange={handleFieldChange}
          >
            {!showV2Only && (
              <>
                <option disabled>First Generation</option>
                <option value="1x">Standard</option>
                <option value="2x">Medium</option>
                <option value="4x">Large</option>
                <option disabled></option>
              </>
            )}
            <option disabled>Second Generation</option>
            <option value="1x-v2">Standard SG</option>
            <option value="2x-v2">Medium SG</option>
            <option value="4x-v2">Large SG</option>
          </FormControl>
        </FormGroup>
        {!editing && showStatus && (
          <p className="status">Build machine updated!</p>
        )}
        {editing && (
          <div className="controls">
            <LoaderButton
              type="submit"
              bsSize="large"
              loading={updating}
              onClick={handleUpdateClick}
            >
              Update
            </LoaderButton>
            <TextButton onClick={handleCancelClick}>Cancel</TextButton>
          </div>
        )}
      </Form>
    );
  }

  return (
    <div className="MachineSettingsPanel">
      <SectionInfo
        label="Build Machine"
        description={
          <span>
            The build machine used for this service.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn more about build machines.
            </a>
          </span>
        }
      >
        {renderForm()}
      </SectionInfo>
    </div>
  );
}
