import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import title from "../lib/titleLib";
import "./NotFound.css";

function NotFound() {
  useEffect(() => {
    document.title = title("Page not found");
  }, []);

  return (
    <div className="NotFound">
      <h2>Sorry, page not found!</h2>
      <p>
        Click <Link to="/">here</Link> to return to home.
      </p>
    </div>
  );
}

export default NotFound;
