import React, {
  useRef,
  useMemo,
  useState,
  Fragment,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import Modal from "./Modal";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import RightChevron from "./RightChevron";
import SectionHeader from "./SectionHeader";
import DropdownSearchItem from "./DropdownSearchItem";
import { searchResources } from "../lib/stringLib";
import { useFormReducer } from "../lib/hooksLib";
import "./ResourceSearchModal.css";

const MAX_ITEMS = 10;

const KEY_UP = 38;
const KEY_DOWN = 40;

export default function ResourceSearchModal({
  show,
  buildUrl,
  onSelect,
  value = "",
  onCloseClick,
  resourceLinks = [],
  recentSearches = [],
}) {
  const isLoading = resourceLinks === null;

  const searchEl = useRef(null);
  const cursorEl = useRef(null);

  const [cursor, _setCursor] = useState({ index: -1, focus: false });
  const [
    {
      values: { search },
      isDirty: { search: isDirty },
    },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ search: value });

  // Get stage related metadata
  const { stageNames, hasMultipleEndpointsPerStage } = useMemo(() => {
    let stageNames;
    let hasMultipleEndpointsPerStage;

    if (!isLoading) {
      const objByStageName = {};
      const endpointsByStageName = {};
      resourceLinks.forEach(({ type, stage }) => {
        objByStageName[stage] = true;
        if (type === "api") {
          endpointsByStageName[stage] = endpointsByStageName[stage] || 0;
          endpointsByStageName[stage]++;
        }
      });
      stageNames = Object.keys(objByStageName);
      hasMultipleEndpointsPerStage = Object.values(endpointsByStageName).some(
        (per) => per > 1
      );
    }

    return { stageNames, hasMultipleEndpointsPerStage };
  }, [isLoading, resourceLinks]);

  const displayPlaceholder =
    (value !== "" && !isDirty) ||
    (search === "" &&
      ((!isLoading && resourceLinks.length > MAX_ITEMS) || isLoading));

  const results = useMemo(
    () =>
      isLoading
        ? []
        : displayPlaceholder
        ? []
        : search === ""
        ? resourceLinks
        : searchResources(resourceLinks, stageNames, search, MAX_ITEMS),
    [search, isLoading, stageNames, resourceLinks, displayPlaceholder]
  );

  useEffect(resetCursor, [search]);

  useLayoutEffect(() => {
    if (cursorEl.current) {
      cursorEl.current.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "start",
      });
    }
  }, []);

  function clearFormData() {
    resetCursor();

    formDispatch({
      value: "",
      id: "search",
      type: "edit",
    });
  }

  function setCursor(index, focus = false) {
    _setCursor({ index, focus });
  }
  function resetCursor() {
    setCursor(-1);
  }
  function isFocusRef(i) {
    return i === cursor.index && cursor.focus;
  }
  function isOnCursorCs(i) {
    return i === cursor.index ? "cursor" : "";
  }

  //////////////
  // Handlers //
  //////////////

  function handleEntered() {
    searchEl.current.focus();
  }

  function handleFieldKeyDown(e) {
    const keyCode = e.keyCode;
    const count = results.length;

    if (keyCode === KEY_UP || keyCode === KEY_DOWN) {
      e.preventDefault();
    }

    if (keyCode === KEY_UP && cursor.index >= 0) {
      // Limit to the first item of the list
      setCursor(Math.max(0, cursor.index - 1), true);
    } else if (keyCode === KEY_DOWN && cursor.index <= count - 1) {
      // Limit to the last item of the list
      setCursor(Math.min(count - 1, cursor.index + 1), true);
    }
  }

  function handleClearClick() {
    clearFormData();
    searchEl.current.focus();
  }

  function handleSearch(e) {
    e.preventDefault();

    // Select item on enter key
    if (cursor.index !== -1 && cursor.focus) {
      handleSelect(results[cursor.index]);
    }
  }

  function handleSelect(value) {
    onSelect(value, buildUrl(value));
  }

  function renderRecentSearch(item) {
    return (
      <Link key={item.fullName} className="item" to={buildUrl(item)}>
        <span>{item.type === "api" ? "API" : "Lambda"}</span>
        <RightChevron />
        <span>{item.fullName}</span>
      </Link>
    );
  }

  function renderItemKeyLine({
    stage,
    service,
    fullName,
    type,
    matchedStageText,
    matchedTypeText,
    matchedUrlApiPath,
    matchedUrlApiMethod,
    matchedUrlText,
    matchedNamePositions,
  }) {
    // Render stage
    let matchedStageTerms;
    if (matchedStageText) {
      matchedStageTerms = [];
      matchedStageTerms.push({
        style: "bold",
        text: stage.substring(0, matchedStageText.length),
      });
      matchedStageTerms.push({
        style: "plain",
        text: stage.substring(matchedStageText.length),
      });
    }

    // Render type
    const typeText = type === "api" ? "API" : "Lambda";
    let matchedTypeTerms;
    if (matchedTypeText) {
      matchedTypeTerms = [];
      matchedTypeTerms.push({
        style: "bold",
        text: typeText.substring(0, matchedTypeText.length),
      });
      matchedTypeTerms.push({
        style: "plain",
        text: typeText.substring(matchedTypeText.length),
      });
    }

    // Render url
    let matchedUrlTerms;
    if (matchedUrlText) {
      matchedUrlTerms = [];
      matchedUrlTerms.push({
        style: "bold",
        text: matchedUrlApiPath.substring(0, matchedUrlText.length),
      });
      matchedUrlTerms.push({
        style: "plain",
        text: matchedUrlApiPath.substring(matchedUrlText.length),
      });
    }

    // Render fullName
    let matchedNameTerms;
    if (
      !matchedUrlText &&
      matchedNamePositions &&
      matchedNamePositions.length > 0
    ) {
      // merge overlap positions
      const positions = [];
      matchedNamePositions
        .sort((posA, posB) => {
          return posA[0] === posB[0] ? posA[1] - posB[1] : posA[0] - posB[0];
        })
        .forEach(([startNew, endNew]) => {
          const overlapPosition = positions.find(
            ([start, end]) => startNew <= end && endNew >= end
          );
          if (overlapPosition) {
            overlapPosition[1] = endNew;
          } else {
            positions.push([startNew, endNew]);
          }
        });

      // build matched terms
      matchedNameTerms = [];
      matchedNameTerms.push({
        style: "plain",
        text: fullName.substring(0, positions[0][0]),
      });
      positions.forEach(([start, end], i) => {
        matchedNameTerms.push({
          style: "bold",
          text: fullName.substring(start, end),
        });
        matchedNameTerms.push(
          positions[i + 1]
            ? {
                style: "plain",
                text: fullName.substring(end, positions[i + 1][0]),
              }
            : { style: "plain", text: fullName.substring(end) }
        );
      });
    }

    return (
      <>
        {/* Stage name */}
        {!matchedStageTerms && <span>{stage}</span>}
        {matchedStageTerms &&
          matchedStageTerms.map(({ style, text }, i) =>
            style === "plain" ? (
              <span key={i}>{text}</span>
            ) : (
              <b key={i}>{text}</b>
            )
          )}

        {/* Resource type */}
        <RightChevron />
        {!matchedTypeTerms && <span>{typeText}</span>}
        {matchedTypeTerms &&
          matchedTypeTerms.map(({ style, text }, i) =>
            style === "plain" ? (
              <span key={i}>{text}</span>
            ) : (
              <b key={i}>{text}</b>
            )
          )}

        {/* If matched by url AND a stage has multiple endpoint => show Service name */}
        {matchedUrlTerms && hasMultipleEndpointsPerStage && (
          <>
            <RightChevron />
            <span>{service}</span>
          </>
        )}

        {matchedUrlTerms && (
          <>
            <RightChevron />
            <span>{matchedUrlApiMethod}</span>
          </>
        )}

        {/* Name or Url */}
        <RightChevron />
        <span className="full-name">
          {!matchedNameTerms && !matchedUrlTerms && <>{fullName}</>}
          {matchedNameTerms &&
            matchedNameTerms.map(({ style, text }, i) =>
              style === "plain" ? (
                <Fragment key={i}>{text}</Fragment>
              ) : (
                <b key={i}>{text}</b>
              )
            )}
          {matchedUrlTerms &&
            matchedUrlTerms.map(({ style, text }, i) =>
              style === "plain" ? (
                <Fragment key={i}>{text}</Fragment>
              ) : (
                <b key={i}>{text}</b>
              )
            )}
        </span>
      </>
    );
  }

  function renderItem(item) {
    return <p>{renderItemKeyLine(item)}</p>;
  }

  function renderListItems(items) {
    return (
      <>
        {items.length > 0 &&
          items.map((item, i) => (
            <DropdownSearchItem
              onHover={() => setCursor(i)}
              key={`${item.fullName}-${item.type}`}
              className={`item ${isOnCursorCs(i)}`}
              onMouseDown={() => handleSelect(item)}
              elRef={(el) => isFocusRef(i) && (cursorEl.current = el)}
            >
              {renderItem(item)}
            </DropdownSearchItem>
          ))}
        {items.length === 0 && (
          <span className="empty">
            {isLoading
              ? "Searching…"
              : resourceLinks.length === 0
              ? "No Lambdas or APIs found"
              : "No matching resources found"}
          </span>
        )}
      </>
    );
  }

  function renderResults(items) {
    return <div className="search-results">{renderListItems(items)}</div>;
  }

  function renderSearchTips() {
    const stage = "dev";

    return (
      <div className="search-tips">
        <SectionHeader>
          <FaIcon name="lightbulb-o" /> Search Tips
        </SectionHeader>
        <ul>
          <li>
            Filter results by stage: <span>{stage} my-function</span>
          </li>
          <li>
            Filter further by APIs or Lambdas:{" "}
            <span>{stage} api my-function</span>
          </li>
          <li>
            Search Lambdas that respond to an API path:{" "}
            <span>/users/123/notes/456/info</span>
          </li>
          <li>
            Use full URLs to search:{" "}
            <span>https://api.example.com/prod/v1/users/123/friends</span>
          </li>
        </ul>
      </div>
    );
  }

  function renderRecentSearches() {
    return (
      recentSearches.length > 0 && (
        <div className="recent-searches">
          <SectionHeader>
            <FaIcon name="history" /> Recent Searches
          </SectionHeader>
          {recentSearches.map(renderRecentSearch)}
        </div>
      )
    );
  }

  return (
    <Modal
      show={show}
      onHide={onCloseClick}
      onEntered={handleEntered}
      className="ResourceSearchModal"
    >
      <Modal.Body>
        <Form onSubmit={handleSearch}>
          <FormGroup bsSize="large" controlId="search">
            <FormControl
              type="text"
              value={search}
              autoComplete="off"
              onChange={handleFieldChange}
              onKeyDown={handleFieldKeyDown}
              inputRef={(el) => (searchEl.current = el)}
              placeholder="View Lambda logs or API logs"
            />
            <label htmlFor="search">
              <FaIcon name="search" />
            </label>
            {search !== "" && (
              <TextButton onMouseDown={handleClearClick}>
                <FaIcon name="times-circle" />
              </TextButton>
            )}
          </FormGroup>
        </Form>
        {!displayPlaceholder && renderResults(results)}
        {displayPlaceholder && (
          <div className="placeholder-info">
            {renderRecentSearches()}
            {renderSearchTips()}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
