import React from "react";
import ResourceItem from "./ResourceItem";
import "./WebsiteResourceList.css";

export default function WebsiteResourceList({ urls }) {
  function renderUrl(url) {
    return (
      <div key={url.stage.cfEndpoint} className="website">
        <div className="header">
          <h4>{url.serviceName}</h4>
        </div>
        <ul className="endpoint">
          <ResourceItem type="website" resource={url.stage} />
        </ul>
      </div>
    );
  }

  return <div className="WebsiteResourceList">{urls.map(renderUrl)}</div>;
}
