import React from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import TextButton from "./TextButton";
import { useFormReducer } from "../lib/hooksLib";
import "./DomainPathControl.css";

const selectCopy = "select domain";

export default function DomainPathControl({
  onAddClick,
  domains = [],
  onCancelClick,
  adding = false,
  errorMessage = null,
}) {
  const [
    {
      values: { path, domain, subDomain },
    },
    ,
    handleChange,
  ] = useFormReducer({
    path: "",
    subDomain: "",
    domain: selectCopy,
  });

  const disabled = domains.length === 0;

  function validateForm() {
    return domain !== selectCopy;
  }

  function handleAddClick(event) {
    event.preventDefault();
    onAddClick(event, { path, domain, subDomain });
  }

  return (
    <Form inline onSubmit={handleAddClick} className="DomainPathControl">
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="field">
        <FormGroup controlId="subDomain" bsSize="large">
          <FormControl
            type="text"
            value={subDomain}
            onChange={handleChange}
            placeholder="sub-domain"
            disabled={disabled}
          />
        </FormGroup>
        <FormGroup className="separator period" bsSize="large">
          <span>.</span>
        </FormGroup>
        <FormGroup className="domain" controlId="domain" bsSize="large">
          <FormControl
            value={domain}
            disabled={disabled}
            componentClass="select"
            onChange={handleChange}
          >
            {domains.length === 0 && <option>No domains available</option>}
            {domains.length !== 0 && (
              <option disabled value={selectCopy}>
                Select a domain
              </option>
            )}
            {domains.length !== 0 &&
              domains.map((domain, i) => (
                <option key={i} value={domain}>
                  {domain}
                </option>
              ))}
          </FormControl>
        </FormGroup>
        <FormGroup className="separator" bsSize="large">
          <span>/</span>
        </FormGroup>
        <FormGroup controlId="path" bsSize="large">
          <FormControl
            type="text"
            value={path}
            onChange={handleChange}
            placeholder="base-path"
            disabled={disabled}
          />
        </FormGroup>
      </div>
      <div className="controls">
        <LoaderButton
          type="submit"
          bsSize="large"
          loading={adding}
          className="btnUpdate"
          onClick={handleAddClick}
          disabled={!validateForm()}
          text="Add Custom Domain"
        />
        <TextButton key="btnHide" className="btnHide" onClick={onCancelClick}>
          Cancel
        </TextButton>
      </div>
    </Form>
  );
}
