import { API, Auth } from "aws-amplify";
import { CognitoAuth } from "amazon-cognito-auth-js";
import { addBreadcrumb } from "./sentryLib";
import { createError } from "./errorLib";
import { getRootUrl, buildQueryStringUrl } from "./urlLib";
import config from "../config";

const AWS_RETRY_MESSAGE =
  "The request signature we calculated does not match the signature you provided.";
const AWS_NO_SESSION_MESSAGE = "Missing Authentication Token";

export async function invokeApig(
  { path, method = "GET", headers = {}, queryStringParameters = {}, body },
  apiName = "main"
) {
  addBreadcrumb({
    message: "Send Request",
    category: "requests",
    data: {
      path,
      body,
      method,
      //headers,
      queryStringParameters,
    },
  });

  let retry = false;

  do {
    try {
      return await API[methodToAmplifyFn(method)](apiName, path, {
        body,
        headers,
        queryStringParameters,
      });
    } catch (e) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (e.response) {
        // Catch API Gateway errors
        if (e.response.data && e.response.data.message) {
          const responseText = e.response.data.message;

          // In case the request signature is invalid
          // ignore the error and
          // try the request again once more
          if (isAwsRetryError(responseText) && !retry) {
            retry = true;
            continue;
          }

          // If it is the "Missing Authentication Token" error
          // check if the session is still valid and log it
          // Else throw a custom error that gets handled in the
          // errorLib
          if (isAwsNoSessionError(responseText)) {
            try {
              const session = await Auth.currentSession();
              addBreadcrumb({
                message: "Session Valid",
                category: "requests",
                data: {
                  session,
                },
              });
            } catch (error) {
              addBreadcrumb({
                message: "Session Expired",
                category: "requests",
                data: {
                  error,
                },
              });
            }
            throw createError("user_session_expired");
          }
        }

        // Set the error message as the response text
        if (e.response.data) {
          e.message = JSON.stringify(e.response.data);
        }
      } else if (e.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // Can happen if it's a CORS error
        addBreadcrumb({
          message: "Network Error",
          category: "requests",
          data: {
            error: e,
            request: e.request,
          },
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        addBreadcrumb({
          message: "Request Error",
          category: "requests",
          data: {
            error: e,
            message: e.message,
          },
        });
      }

      throw e;
    }
  } while (retry);
}

export async function invokeAppsApig(request) {
  return invokeApig(request, "apps");
}

export async function invokeStagesApig(request) {
  return invokeApig(request, "stages");
}

export async function invokeV2Apig(request) {
  return invokeApig(request, "v2");
}

export function invokePublicApig({
  path,
  method = "GET",
  headers = {},
  queryStringParameters = {},
  body,
}) {
  path = buildQueryStringUrl(
    `${config.apiGateway.URL}${path}`,
    queryStringParameters
  );

  return fetchApi({
    path,
    method,
    headers,
    body,
  });
}

export async function setIdentityIdAttribute() {
  const credentials = await Auth.currentCredentials();
  const currentUser = await Auth.currentAuthenticatedUser();
  const { identityId } = Auth.essentialCredentials(credentials);

  return Auth.updateUserAttributes(currentUser, {
    "custom:identityId": identityId,
  });
}

export async function updateUserPassword(oldPassword, newPassword) {
  const currentUser = await Auth.currentAuthenticatedUser();

  return Auth.changePassword(currentUser, oldPassword, newPassword);
}

export async function getUserAttributes(names) {
  const currentUser = await Auth.currentAuthenticatedUser();

  const attributes = await Auth.userAttributes(currentUser);

  const ret = {};
  attributes.forEach((attribute) => {
    const name = attribute.getName();
    if (names.includes(name)) {
      ret[name] = attribute.getValue();
    }
  });

  return ret;
}

export function initCognitoSDK(provider, userhandler = null) {
  const rootUrl = getRootUrl(window.location.href);

  const authData = {
    IdentityProvider: provider,
    ClientId: config.cognito.APP_CLIENT_ID,
    UserPoolId: config.cognito.USER_POOL_ID,
    AppWebDomain: config.cognito.APP_WEB_DOMAIN,
    TokenScopesArray: config.cognito.TOKEN_SCOPES_ARRAY,
    RedirectUriSignIn: `${rootUrl}${config.cognito.REDIRECT_URI_SIGNIN}`,
    RedirectUriSignOut: `${rootUrl}${config.cognito.REDIRECT_URI_SIGNOUT}`,
  };
  const auth = new CognitoAuth(authData);

  if (userhandler) {
    auth.userhandler = userhandler;
  }

  if (provider) {
    auth.setState(provider);
  }

  auth.useCodeGrantFlow();

  return auth;
}

function methodToAmplifyFn(method) {
  const mapping = {
    get: "get",
    post: "post",
    put: "put",
    head: "head",
    delete: "del",
  };

  return mapping[method.toLowerCase()];
}

async function fetchApi({ path, method, headers, body }) {
  body = body ? JSON.stringify(body) : body;

  const results = await fetch(path, {
    method,
    headers,
    body,
  });

  if (results.status !== 200) {
    throw new Error(await results.text());
  }

  return results.json();
}

function isAwsRetryError(message) {
  return message.indexOf(AWS_RETRY_MESSAGE) !== -1 ? true : false;
}

function isAwsNoSessionError(message) {
  return message.indexOf(AWS_NO_SESSION_MESSAGE) !== -1 ? true : false;
}
