import React from "react";
import { Link } from "react-router-dom";
import { MenuItem, DropdownButton } from "react-bootstrap";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import EmptyListPanel from "./EmptyListPanel";
import { stripProtocol } from "../lib/urlLib";
import { formatMetricsNumbers } from "../lib/stringLib";
import { dateToFullTimeWithUTCTimeZone } from "../lib/timeLib";
import "./StageReportPanel.css";

const previousCopy = {
  weekly: "Previous week's value",
  daily: "Previous day's value",
};

function formatNullCount(value) {
  return value === null ? 0 : value;
}

export default function StageReportPanel({
  data,
  hiding = {},
  onHideToggle,
  buildMetricsUrl,
}) {
  const stageId = data.stageId;
  const noApis = data.apiData.length === 0;
  const noLambdas = data.lambdaData.length === 0;
  const fullStartTime = dateToFullTimeWithUTCTimeZone(data.startTime);
  const fullEndTime = dateToFullTimeWithUTCTimeZone(data.endTime);

  function sortApis() {
    return data.apiData.sort((first, second) => {
      const firstValue = formatNullCount(first.metrics["count"].value);
      const secondValue = formatNullCount(second.metrics["count"].value);
      if (firstValue === secondValue) {
        const firstDisplayName = first.stack ? first.stack : first.apiName;
        const secondDisplayName = second.stack ? second.stack : second.apiName;
        return firstDisplayName.toLowerCase() < secondDisplayName.toLowerCase()
          ? -1
          : 1;
      }
      return secondValue - firstValue;
    });
  }

  function getRatio(num, denom) {
    return denom === 0 ? 0 : num / denom;
  }

  function ratioChange(value, prevValue) {
    return (value - prevValue) / prevValue;
  }

  function setLambdaSortData(data, metric) {
    const value = formatNullCount(data.metrics[metric].value);
    const prevValue = formatNullCount(data.metrics[metric].prevValue);

    if (metric === "error") {
      const invokeValue = formatNullCount(data.metrics.invocations.value);
      const invokePrevValue = formatNullCount(
        data.metrics.invocations.prevValue
      );

      // Note: default prevValue to 0.1 instead of 1 if it is 0
      // This is to handle the errors metrics case:
      // - curr: 1/2.0k
      // - prev: 0/1.9k
      // If 1 is used as default value, it appears the error rate is dropped, and ranks
      // higher than those error rate has not changed.
      const errorRatio = getRatio(value, invokeValue);
      let errorRatioPrev;
      let errorRatioPrevNonZero;
      // Case: new Lambda: errorPrevValue = 0; invokePrevValue = 0
      if (prevValue === 0 && invokePrevValue === 0) {
        errorRatioPrev = 0;
        errorRatioPrevNonZero = getRatio(0.1, invokeValue);
      }
      // Case: new error: errorPrevValue = 0; invokePrevValue > 0
      else if (prevValue === 0) {
        errorRatioPrev = 0;
        errorRatioPrevNonZero = getRatio(0.1, invokePrevValue);
      } else {
        errorRatioPrev = getRatio(prevValue, invokePrevValue);
        errorRatioPrevNonZero = getRatio(prevValue, invokePrevValue);
      }

      const errorRatioDiff = errorRatio - errorRatioPrev;
      let trendDirection;
      if (errorRatioDiff > 0) {
        trendDirection = "up";
      } else if (errorRatioDiff < 0) {
        trendDirection = "down";
      } else {
        trendDirection = "unchanged";
      }

      data.metrics[metric].sortData = {
        errorRatio: (errorRatio * 100).toFixed(5),
        errorRatioPrev: (errorRatioPrev * 100).toFixed(5),
        errorRatioPrevNonZero: (errorRatioPrevNonZero * 100).toFixed(3),
        trendDirection,
        value: ratioChange(errorRatio, errorRatioPrevNonZero) * value,
      };
    } else {
      const errorRatioDiff = value - prevValue;
      let trendDirection;
      if (errorRatioDiff > 0) {
        trendDirection = "up";
      } else if (errorRatioDiff < 0) {
        trendDirection = "down";
      } else {
        trendDirection = "unchanged";
      }

      data.metrics[metric].sortData = {
        trendDirection,
        value: ratioChange(value, prevValue),
      };
    }
  }

  function sortLambdas(metric) {
    const lambdaData =
      metric === "error"
        ? // For errors, filter out lambdas that have a current and old value of 0
          data.lambdaData.filter((lambda) => {
            const value = formatNullCount(lambda.metrics[metric].value);
            const prevValue = formatNullCount(lambda.metrics[metric].prevValue);

            return !(value === 0 && prevValue === 0);
          })
        : data.lambdaData;

    lambdaData.forEach((lambda) => setLambdaSortData(lambda, metric));

    return lambdaData.sort((first, second) => {
      const firstData = first.metrics[metric];
      const secondData = second.metrics[metric];

      // Sort not muted first
      if (firstData.isMuted && !secondData.isMuted) {
        return 1;
      } else if (!firstData.isMuted && secondData.isMuted) {
        return -1;
      }

      // Sort current value = 0 or null last
      if (
        formatNullCount(firstData.value) === 0 ||
        formatNullCount(secondData.value) === 0
      ) {
        return (
          formatNullCount(secondData.value) - formatNullCount(firstData.value)
        );
      }

      // [latency] Sort prev value = null first
      if (firstData.prevValue === null && secondData.prevValue === null) {
        return secondData.value - firstData.value;
      } else if (firstData.prevValue === null) {
        return -1;
      } else if (secondData.prevValue === null) {
        return 1;
      }

      // Sort by sortData
      const firstSortData = first.metrics[metric].sortData;
      const secondSortData = second.metrics[metric].sortData;
      const firstValue = firstSortData.value;
      const secondValue = secondSortData.value;

      // For errors, break all Lambdas into 3 sections
      // - error rate increased: sort by rate increase the most
      // - error rate decreased: sort by rate decrease the most
      // - error rate unchanged
      if (metric === "error") {
        if (firstSortData.trendDirection === "up") {
          if (secondSortData.trendDirection === "up") {
            return firstValue === secondValue
              ? first.fullName.toLowerCase() < second.fullName.toLowerCase()
                ? -1
                : 1
              : secondValue - firstValue;
          } else {
            return -1;
          }
        } else if (firstSortData.trendDirection === "down") {
          if (secondSortData.trendDirection === "up") {
            return 1;
          } else if (secondSortData.trendDirection === "down") {
            return firstValue === secondValue
              ? first.fullName.toLowerCase() < second.fullName.toLowerCase()
                ? -1
                : 1
              : firstValue - secondValue;
          } else if (secondSortData.trendDirection === "unchanged") {
            return -1;
          }
        } else if (firstSortData.trendDirection === "unchanged") {
          if (secondSortData.trendDirection === "unchanged") {
            return first.fullName.toLowerCase() < second.fullName.toLowerCase()
              ? -1
              : 1;
          } else {
            return 1;
          }
        }
      } else {
        return firstValue === secondValue
          ? first.fullName.toLowerCase() < second.fullName.toLowerCase()
            ? -1
            : 1
          : secondValue - firstValue;
      }

      return 0;
    });
  }

  function renderNoLambdasSign() {
    return (
      <EmptyListPanel
        message={
          noApis
            ? "There are no metrics to report."
            : "There are no Lambdas deployed to this stage."
        }
      />
    );
  }

  function renderLambdaTrendArrow(prevValue, sortData) {
    if (prevValue === null) {
      return <FaIcon className="empty" name="minus" />;
    }

    // Get trend direction
    let trendName;
    if (sortData.trendDirection === "up") {
      trendName = "arrow-up";
    } else if (sortData.trendDirection === "down") {
      trendName = "arrow-down";
    } else {
      trendName = "minus";
    }

    return (
      <FaIcon className={`level-${sortData.colorGrade}`} name={trendName} />
    );
  }

  function renderApiTrendArrow(value, prevValue) {
    if (prevValue === null) {
      return <FaIcon className="empty" name="minus" />;
    }

    // Get trend direction
    let trendName;
    if (value > prevValue) {
      trendName = "arrow-up";
    } else if (value < prevValue) {
      trendName = "arrow-down";
    } else {
      trendName = "minus";
    }

    return <FaIcon name={trendName} />;
  }

  function renderApiMetric({ name, value, prevValue, unit }, count, prevCount) {
    if (name === "error") {
      value = formatNullCount(value);
      prevValue = formatNullCount(prevValue);
      count = formatNullCount(count);
      prevCount = formatNullCount(prevCount);
    }

    return (
      <span className={`metric ${name}`}>
        {value !== null && (
          <>
            <span className="current">
              {formatMetricsNumbers(value, unit, "api")}
              {name === "error" && (
                <>
                  <span className="separator">/</span>
                  {formatMetricsNumbers(count, unit, "api")}
                </>
              )}
            </span>
            {renderApiTrendArrow(value, prevValue)}
            {prevValue !== null && (
              <span title={previousCopy[data.type]} className="previous">
                {formatMetricsNumbers(prevValue, unit, "api")}
                {name === "error" && (
                  <>
                    <span className="separator">/</span>
                    {formatMetricsNumbers(prevCount, unit, "api")}
                  </>
                )}
              </span>
            )}
            {prevValue === null && (
              <span title={previousCopy[data.type]} className="previous empty">
                &mdash;
              </span>
            )}
          </>
        )}
        {value === null && <span className="current empty">&mdash;</span>}
      </span>
    );
  }

  function renderApi(api) {
    const { value: count, prevValue: prevCount } = api.metrics.count;
    let metricsUrl;
    if (api.region) {
      metricsUrl = buildMetricsUrl({
        stageId,
        type: "api",
        region: api.region,
        source: api.stack,
        apiId: api.apiId,
        params: {
          span: `${fullStartTime} to ${fullEndTime}`,
        },
      });
    }
    const endpoint = api.customEndpoint || api.endpoint;

    return (
      <div key={api.apiName} className="api">
        <div className="col1">
          <p className="name">
            {metricsUrl ? (
              <Link to={metricsUrl}>{api.stack}</Link>
            ) : (
              <span>{api.apiName}</span>
            )}
          </p>
          {endpoint && <p className="endpoint">{stripProtocol(endpoint)}</p>}
        </div>
        <div className="col2">
          {renderApiMetric(api.metrics.latency)}
          {renderApiMetric(api.metrics.error, count, prevCount)}
        </div>
      </div>
    );
  }

  function renderApis() {
    const apis = sortApis();
    return (
      <>
        <div className="header-apis">
          <h4>APIs</h4>
          <div>
            <SectionHeader>P95 Latency</SectionHeader>
            <SectionHeader>
              Errors
              <span className="separator">/</span>
              Requests
            </SectionHeader>
          </div>
        </div>
        <div className="apis">{apis.map(renderApi)}</div>
      </>
    );
  }

  function renderLambdaMetric(
    value,
    prevValue,
    unit,
    type,
    inv,
    prevInv,
    sortData
  ) {
    if (type === "error") {
      value = formatNullCount(value);
      prevValue = formatNullCount(prevValue);
      inv = formatNullCount(inv);
      prevInv = formatNullCount(prevInv);
    }

    return value !== null ? (
      <>
        <span className="current">
          {formatMetricsNumbers(value, unit, "lambda")}
          {type === "error" && (
            <>
              <span className="separator">/</span>
              {formatMetricsNumbers(inv, unit, "lambda")}
            </>
          )}
        </span>
        {renderLambdaTrendArrow(prevValue, sortData)}
        {prevValue !== null && (
          <span title={previousCopy[data.type]} className="previous">
            {formatMetricsNumbers(prevValue, unit, "lambda")}
            {type === "error" && (
              <>
                <span className="separator">/</span>
                {formatMetricsNumbers(prevInv, unit, "lambda")}
              </>
            )}
          </span>
        )}
        {prevValue === null && (
          <span title={previousCopy[data.type]} className="previous empty">
            &mdash;
          </span>
        )}
      </>
    ) : (
      <span className="current empty">&mdash;</span>
    );
  }

  function renderLambda(lambda, type) {
    const fullName = lambda.fullName;
    const { value, prevValue, unit, isMuted, sortData } = lambda.metrics[type];
    const { value: inv, prevValue: prevInv } = lambda.metrics.invocations;
    const loading = hiding[type] && hiding[type][fullName];

    let metricsUrl;

    if (lambda.region) {
      metricsUrl = buildMetricsUrl({
        stageId,
        region: lambda.region,
        source: fullName,
        type: "lambda",
        params: {
          span: `${fullStartTime} to ${fullEndTime}`,
        },
      });
    }

    return (
      <div key={fullName} className="lambda">
        <div className="col1">
          {metricsUrl ? (
            <Link to={metricsUrl}>{fullName}</Link>
          ) : (
            <span>{fullName}</span>
          )}
        </div>
        <div className="col2">
          {renderLambdaMetric(
            value,
            prevValue,
            unit,
            type,
            inv,
            prevInv,
            sortData
          )}
          {!loading && (
            <DropdownButton
              noCaret
              pullRight
              id={`lambda-${type}-${fullName}`}
              title={<FaIcon name="ellipsis-h" />}
            >
              <MenuItem
                eventKey="1"
                onClick={() => onHideToggle(stageId, !isMuted, type, fullName)}
              >
                {isMuted && "Show in reports"}
                {!isMuted && "Hide from reports"}
              </MenuItem>
            </DropdownButton>
          )}
          {loading && <LoaderButton loading bsSize="xsmall" />}
        </div>
      </div>
    );
  }

  function renderLambdas(metric) {
    const headerCopyMap = {
      duration: "P95 Duration",
      error: "Errors",
    };
    const lambdas = sortLambdas(metric);
    const firstMutedIndex = lambdas.findIndex(
      (lambda) => lambda.metrics[metric].isMuted
    );

    // get variance for color grade
    const values = lambdas
      .map((lambda) => lambda.metrics[metric].sortData.value)
      .filter((value) => isFinite(value) && value > 0);
    const n = values.length;
    let mean = 0;
    let variance = 0;
    if (n > 0) {
      mean = values.reduce((a, value) => a + value) / n;
      variance = Math.sqrt(
        values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
      );
    }
    lambdas.forEach((lambda) => {
      const value = lambda.metrics[metric].sortData.value;
      // grade is # of variance above mean (max 5, min 0)
      lambda.metrics[metric].sortData.colorGrade =
        variance === 0 || mean === 0
          ? value > 0
            ? 5
            : 0
          : Math.round(Math.max(Math.min((value - mean) / variance, 5), 0));
    });

    let shown = lambdas;
    let hidden;

    if (firstMutedIndex !== -1) {
      shown = lambdas.slice(0, firstMutedIndex);
      hidden = lambdas.slice(firstMutedIndex);
    }

    return (
      <div className={`lambdas ${metric}`}>
        <div className="header">
          <SectionHeader>
            {headerCopyMap[metric]}
            {metric === "error" && (
              <>
                <span className="separator">/</span>
                Invocations
              </>
            )}
          </SectionHeader>
        </div>
        <div>{shown.map((lambda) => renderLambda(lambda, metric))}</div>
        {(firstMutedIndex === 0 || lambdas.length === 0) && (
          <div className="no-errors">
            {metric === "error" && (
              <>
                <FaIcon name="thumbs-o-up" />
                There are no errors to report!
              </>
            )}
            {metric === "duration" && (
              <>There are no major metrics to report!</>
            )}
          </div>
        )}
        {hidden && (
          <div className="muted">
            <SectionHeader>
              <span>Hidden</span>
              <hr />
            </SectionHeader>
            {hidden.map((lambda) => renderLambda(lambda, metric))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="StageReportPanel">
      {!noApis && renderApis()}

      {noLambdas && renderNoLambdasSign()}
      {!noLambdas && (
        <>
          <h4 className="header-lambdas">Lambdas</h4>
          <div className="lambda-reports">
            {renderLambdas("duration")}
            {renderLambdas("error")}
          </div>
        </>
      )}
    </div>
  );
}
