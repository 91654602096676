import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, NavItem } from "react-bootstrap";
import {
  getAppUrl,
  getOrgMembersUrl,
  getAppPipelineUrl,
  getAppActivityUrl,
  getOrgSettingsUrl,
  getOrgAuditLogUrl,
  getAppStageIssuesUrl,
} from "../lib/urlLib";
import "./AppTabNav.css";

export default function AppTabNav({ type, match }) {
  const { ownerId, appId } = match.params;
  const path = match.path;

  const appUrl = getAppUrl(ownerId, appId);

  const isOrgAppsActive =
    path.indexOf("/orgs/:ownerId/new") === 0 || path.indexOf("/:ownerId") === 0;
  const isAppsActive =
    path.indexOf("/:ownerId/:appId/status") === 0 ||
    path.indexOf("/:ownerId/:appId/reports") === 0;

  return (
    <Nav activeKey={null} className="AppTabNav" bsStyle="pills">
      {type === "org" && (
        <>
          <LinkContainer exact to={`/${ownerId}`}>
            <NavItem active={isOrgAppsActive}>Apps</NavItem>
          </LinkContainer>
          <LinkContainer to={getOrgMembersUrl(ownerId)}>
            <NavItem>Members</NavItem>
          </LinkContainer>
          <LinkContainer to={getOrgAuditLogUrl(ownerId)}>
            <NavItem>Audit Log</NavItem>
          </LinkContainer>
          <LinkContainer to={getOrgSettingsUrl(ownerId)}>
            <NavItem>Settings</NavItem>
          </LinkContainer>
        </>
      )}
      {type === "app" && (
        <>
          <LinkContainer exact to={appUrl}>
            <NavItem active={isAppsActive}>Overview</NavItem>
          </LinkContainer>
          <LinkContainer to={getAppPipelineUrl(ownerId, appId)}>
            <NavItem>Pipeline</NavItem>
          </LinkContainer>
          <LinkContainer to={getAppActivityUrl(ownerId, appId)}>
            <NavItem>Activity</NavItem>
          </LinkContainer>
          {["palomma", "botnot", "foyyay", "rebelpay", "frank"].includes(
            ownerId
          ) && (
            <LinkContainer to={getAppStageIssuesUrl(ownerId, appId)}>
              <NavItem>Issues</NavItem>
            </LinkContainer>
          )}
          <LinkContainer to={`/${ownerId}/${appId}/stages`}>
            <NavItem>Resources</NavItem>
          </LinkContainer>
          <LinkContainer to={`/${ownerId}/${appId}/settings`}>
            <NavItem>Settings</NavItem>
          </LinkContainer>
        </>
      )}
    </Nav>
  );
}
