export function trimFields(fields) {
  return Object.keys(fields).reduce((acc, field) => {
    acc[field] =
      typeof fields[field] === "string" ? fields[field].trim() : fields[field];
    return acc;
  }, {});
}

export function areFieldsNotEmpty(fields) {
  return (
    Object.keys(fields).find((field) => isFieldEmpty(fields[field])) ===
    undefined
  );
}

export function isFieldEmpty(field) {
  return typeof field === "string" ? field.trim() === "" : false;
}

export function isPasswordValid(password) {
  return password.length >= 8;
}
