import React, { useRef, useState } from "react";
import Modal from "./Modal";
import FaIcon from "./FaIcon";
import RightChevron from "./RightChevron";
import LoaderButton from "./LoaderButton";
import PolicyDisplayPanel from "./PolicyDisplayPanel";
import StyledControlLabel from "./StyledControlLabel";
import { safeParse } from "../lib/jsonLib";
import "./PermissionErrorModal.css";

const copyMap = {
  logs:
    "Seed needs the following permission to be able to display the requested logs.",
  issues: "Seed needs the following permission to be able to enable Issues.",
  metrics:
    "Seed needs the following permission to be able to display the requested metrics.",
  reports:
    "Seed needs the following permission to be able to generate the requested report.",
  dashboard:
    "Seed needs the following permission to be able to display key metrics.",
  resources:
    "Seed needs the following permission to be able to display the resources.",
  accessLog:
    "Seed needs the following permission to be able to manage access logs.",
};

const logsStatement = {
  Sid: "MonitorLogs",
  Effect: "Allow",
  Action: [
    "apigateway:GET",
    "lambda:GetFunction",
    "logs:DescribeLogStreams",
    "logs:FilterLogEvents",
    "logs:GetLogEvents",
    "logs:GetQueryResults",
    "logs:StartQuery",
    "logs:StopQuery",
  ],
  Resource: "*",
};

const metricsStatement = {
  Sid: "MonitorMetrics",
  Effect: "Allow",
  Action: [
    "apigateway:GET",
    "cloudwatch:GetMetricData",
    "cloudformation:ListStackResources",
  ],
  Resource: "*",
};

const issuesStatement = {
  Sid: "ManageIssues",
  Effect: "Allow",
  Action: [
    "cloudformation:ListStackResources",
    "cloudformation:DescribeStacks",
    "logs:CreateLogGroup",
    "logs:DescribeSubscriptionFilters",
    "logs:PutSubscriptionFilter",
    "logs:DeleteSubscriptionFilter",
    "lambda:GetFunction",
  ],
  Resource: "*",
};

const resourcesStatement = {
  Sid: "ManageDeployedResources",
  Effect: "Allow",
  Action: [
    "cloudformation:GetTemplate",
    "cloudformation:ListStacks",
    "cloudformation:ListStackResources",
    "cloudformation:DescribeStacks",
    "apigateway:GET",
  ],
  Resource: "*",
};

const accessLogStatement = {
  Sid: "ManageAPIAccessLog",
  Effect: "Allow",
  Action: [
    "iam:CreateRole",
    "apigateway:GET",
    "apigateway:PATCH",
    "logs:CreateLogGroup",
    "logs:DescribeLogGroups",
  ],
  Resource: "*",
};

const policyMap = {
  logs: {
    Version: "2012-10-17",
    Statement: [logsStatement],
  },
  metrics: {
    Version: "2012-10-17",
    Statement: [metricsStatement],
  },
  issues: {
    Version: "2012-10-17",
    Statement: [issuesStatement],
  },
  reports: {
    Version: "2012-10-17",
    Statement: [metricsStatement],
  },
  resources: {
    Version: "2012-10-17",
    Statement: [resourcesStatement],
  },
  dashboard: {
    Version: "2012-10-17",
    Statement: [metricsStatement, resourcesStatement],
  },
  accessLog: {
    Version: "2012-10-17",
    Statement: [accessLogStatement],
  },
};

export default function PermissionErrorModal({
  show,
  type,
  error,
  onCloseClick,
}) {
  const resourceRef = useRef(null);
  const permissionRef = useRef(null);

  const [showStatus, setShowStatus] = useState(null);

  const errorContext =
    error &&
    error.response &&
    error.response.data &&
    error.response.data.context
      ? safeParse(error.response.data.context)
      : null;
  const errorInfo =
    errorContext &&
    errorContext.iamUser &&
    errorContext.iamAction &&
    errorContext.iamResource
      ? errorContext
      : null;

  function handleCopy(type) {
    const ref = type === "permission" ? permissionRef : resourceRef;

    ref.current.select();
    document.execCommand("copy");
    ref.current.focus();
    setShowStatus(type);
  }

  function handleBlur() {
    setShowStatus(null);
  }

  function renderErrorInfoField(type) {
    const isPermission = type === "permission";

    return (
      <div className="field">
        <div className="header">
          <StyledControlLabel>
            {isPermission ? "Permission" : "Resource"}
          </StyledControlLabel>
          {showStatus === type && <p className="status">Copied!</p>}
        </div>
        <input
          readOnly
          ref={isPermission ? permissionRef : resourceRef}
          onBlur={() =>
            handleBlur(
              isPermission ? permissionRef.current : resourceRef.current
            )
          }
          value={isPermission ? errorInfo.iamAction : errorInfo.iamResource}
        />
        <LoaderButton
          title="Copy"
          bsStyle="block"
          onClick={() => handleCopy(type)}
          disabled={!document.queryCommandSupported("copy")}
        >
          <FaIcon name="clone" />
        </LoaderButton>
      </div>
    );
  }

  function renderDefault() {
    return (
      <>
        <p>{copyMap[type]}</p>
        <PolicyDisplayPanel policy={policyMap[type]} />
        <p>Please update the IAM Role associated with Seed and try again.</p>
      </>
    );
  }

  function renderWithError() {
    // iamUser ie. 112245769880:user/no-resources-permission
    const iamUserName = errorInfo.iamUser.split("/")[1];
    return (
      <>
        <div className="error-info">
          <p>{copyMap[type]}</p>
          <div className="fields">
            {renderErrorInfoField("permission")}
            {renderErrorInfoField("resource")}
          </div>
          <a
            className="iam"
            target="_blank"
            href={`https://console.aws.amazon.com/iam/home#/users/${iamUserName}`}
            rel="noopener noreferrer"
          >
            Head over to the IAM Console to update the permissions&nbsp;
            <RightChevron />
          </a>
        </div>
        <hr />
        <p className="reference">
          For reference, here are all the permissions Seed needs for this.
        </p>
        <PolicyDisplayPanel policy={policyMap[type]} />
      </>
    );
  }

  return (
    <Modal show={show} onHide={onCloseClick} className="PermissionErrorModal">
      <Modal.Header closeButton>
        <Modal.Title>IAM Permissions Needed</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!errorInfo && renderDefault()}
        {errorInfo && renderWithError()}
      </Modal.Body>

      <Modal.Footer>
        <LoaderButton bsSize="large" onClick={onCloseClick}>
          Done
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
