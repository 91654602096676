import React, { useEffect, useState, useReducer } from "react";
import { Redirect } from "react-router-dom";
import withAppHeader from "../components/ComponentWithAppHeader";
import withCancel from "../components/ComponentWithCancel";
import AppAddStage from "./widgets/AppAddStage";
import AppPrPanel from "../components/AppPrPanel";
import ErrorAlert from "../components/ErrorAlert";
import SectionInfo from "../components/SectionInfo";
import ScreenHeader from "../components/ScreenHeader";
import AppBranchPanel from "../components/AppBranchPanel";
import LoadingSpinner from "../components/LoadingSpinner";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import AppPipelineEditPanel from "../components/AppPipelineEditPanel";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import { getAppRemoveUrl } from "../lib/urlLib";
import { filterDeletedStages } from "../lib/stagesLib";
import { appPipelineBreadcrumb } from "../lib/breadcrumbLib";
import { errorHandler, getLoadError } from "../lib/errorLib";
import "./AppEditPipeline.css";

const prHelpUrl = "https://seed.run/docs/working-with-pull-requests";
const branchHelpUrl = "https://seed.run/docs/working-with-branches";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
};

function addStageReducer(state, action) {
  switch (action.type) {
    case "show":
      return {
        ...state,
        show: true,
        key: state.key + 1,
        stageType: action.stageType,
      };
    case "hide":
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}

const modalSettingDefaultState = {
  key: 0,
  editing: false,
  updated: false,
  enabling: false,
  disabling: false,
};

function modalSettingReducer(state, action) {
  switch (action.type) {
    case "edit":
      return { ...state, editing: true };
    case "edit-done":
      return { ...state, editing: false, key: state.key + 1 };
    case "enable":
      return { ...state, enabling: true };
    case "disable":
      return { ...state, disabling: true };
    case "update-done":
      return {
        ...state,
        enabling: false,
        disabling: false,
        updated: true,
        editing: false,
        key: state.key + 1,
      };
    case "update-failed":
      return {
        ...state,
        enabling: false,
        disabling: false,
        key: state.key + 1,
      };
    default:
      return state;
  }
}

function AppEditPipeline(props) {
  let isLoading = true;
  let loadError = null;
  let isRemovingOrRemoveFailed = false;
  let activeStages = [];

  const { ownerId, appId } = props.match.params;

  // Update stage type
  const [updatingStageTypes, setUpdatingStageTypes] = useState([]);
  const [addStageState, addStageDispatch] = useReducer(addStageReducer, {
    key: 0,
    show: false,
  });
  const [prState, prDispatch] = useReducer(
    modalSettingReducer,
    modalSettingDefaultState
  );
  const [branchState, branchDispatch] = useReducer(
    modalSettingReducer,
    modalSettingDefaultState
  );

  //////////
  // Load //
  //////////

  useEffect(() => {
    document.title = title("Edit Pipeline");
  }, []);

  const {
    data: appInfo,
    error,
    reload: reloadAppInfo,
  } = useAPILoad(
    `/${ownerId}/${appId}`,
    (path) =>
      props.invokeAppsApig({ path, queryStringParameters: { version: "v2" } }),
    { polling: false }
  );

  if (appInfo) {
    isLoading = false;

    // Case: deleting or delete_failed => stop polling
    if (
      appInfo.app.status === "deleting" ||
      appInfo.app.status === "delete_failed"
    ) {
      isRemovingOrRemoveFailed = true;
    }
    // Case: NOT deleting or delete_failed
    else {
      activeStages = appInfo.stages.filter(
        (stage) => !["deleting", "delete_failed"].includes(stage.status)
      );
    }
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  const loaded = !isLoading && !loadError && appInfo;

  /////////
  // API //
  /////////

  function getAppAPI() {
    return `/${ownerId}/${appId}`;
  }

  function getAppStageAPI(stageName) {
    return `/${ownerId}/${appId}/stages/${stageName}`;
  }

  function updateStage(stageName, data) {
    return props.invokeAppsApig({
      path: `${getAppStageAPI(stageName)}?version=v20200317`,
      method: "PUT",
      body: data,
    });
  }

  function addStage(stage) {
    return props.invokeAppsApig({
      path: `${getAppAPI()}/stages`,
      method: "POST",
      body: stage,
    });
  }

  function getGitBranches(nextToken) {
    return props.invokeAppsApig({
      path: `${getAppAPI()}/git_branches`,
      queryStringParameters: { nextToken },
    });
  }

  function updateAppInfo(data) {
    return props.invokeAppsApig({
      path: `${getAppAPI()}?version=v20210611`,
      method: "PUT",
      body: data,
    });
  }

  ////////////////////////////
  // Handlers Edit Pipeline //
  ////////////////////////////

  async function handleSetStageType(event, stage, type) {
    setUpdatingStageTypes([stage, type]);
    await updateStage(stage, { type });
    await reloadAppInfo();
    setUpdatingStageTypes([]);
  }

  ////////////////////////
  // Handlers Add Stage //
  ////////////////////////

  function handleLoadBranches(nextToken) {
    return getGitBranches(nextToken);
  }

  function handleAddStageModalShow(e, stageType = "dev") {
    addStageDispatch({ type: "show", stageType });
  }

  function handleAddStageModalClose() {
    addStageDispatch({ type: "hide" });
  }

  async function handleAddStage(event, stage) {
    await addStage(stage);
    await reloadAppInfo();
    addStageDispatch({ type: "hide" });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleBranchEdit() {
    branchDispatch({ type: "edit" });
  }
  async function handleBranchCancel() {
    branchDispatch({ type: "edit-done" });
  }

  async function handleBranchSave({
    branchEnabled,
    branchCleanupEnabled,
    branchInheritStageId,
  }) {
    branchDispatch({ type: branchEnabled ? "enable" : "disable" });

    try {
      await updateAppInfo({
        branch_enabled: branchEnabled,
        branch_cleanup_enabled: branchCleanupEnabled,
        branch_inherit_stage_id: branchInheritStageId,
      });
      await reloadAppInfo();
      branchDispatch({ type: "update-done" });
    } catch (e) {
      branchDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handlePrEdit() {
    prDispatch({ type: "edit" });
  }
  async function handlePrCancel() {
    prDispatch({ type: "edit-done" });
  }
  async function handlePrSave({
    prEnabled,
    prCleanupEnabled,
    prCommentEnabled,
    prInheritStageId,
  }) {
    prDispatch({ type: prEnabled ? "enable" : "disable" });

    try {
      await updateAppInfo({
        pr_enabled: prEnabled,
        pr_cleanup_enabled: prCleanupEnabled,
        pr_comment_enabled: prCommentEnabled,
        pr_inherit_stage_id: prInheritStageId,
      });
      await reloadAppInfo();
      prDispatch({ type: "update-done" });
    } catch (e) {
      prDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  ////////////
  // Render //
  ////////////

  return (
    <div className="AppEditPipeline">
      <ScreenHeader border breadcrumb={appPipelineBreadcrumb(props)}>
        Edit Pipeline
      </ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {!isLoading && isRemovingOrRemoveFailed && (
        <Redirect to={getAppRemoveUrl(ownerId, appId)} />
      )}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="app"
          code={loadError}
          context={{
            name: appId,
          }}
        />
      )}
      {error && !loadError && <ErrorAlert error={error} />}

      {loaded && (
        <div>
          <div className="options">
            <SectionInfo
              label="Auto-deploy Options"
              description={
                <span>
                  Add a new stage when a branch or PR is created. Learn more
                  about auto-deploying&nbsp;
                  <a
                    target="_blank"
                    href={branchHelpUrl}
                    rel="noopener noreferrer"
                  >
                    branches
                  </a>
                  &nbsp;and&nbsp;
                  <a target="_blank" href={prHelpUrl} rel="noopener noreferrer">
                    pull requests
                  </a>
                  .
                </span>
              }
            >
              <AppBranchPanel
                resetKey={`AppBranchPanel-${branchState.key}`}
                stages={activeStages}
                branchEnabled={appInfo.app.branch_enabled}
                branchCleanupEnabled={appInfo.app.branch_cleanup_enabled}
                branchInheritStageId={appInfo.app.branch_inherit_stage_id}
                gitProvider={appInfo.app.git_provider}
                editing={branchState.editing}
                enabling={branchState.enabling}
                disabling={branchState.disabling}
                onEditClick={handleBranchEdit}
                onSaveClick={handleBranchSave}
                onCancelClick={handleBranchCancel}
              />
              <AppPrPanel
                resetKey={`AppPrPanel-${prState.key}`}
                stages={activeStages}
                prEnabled={appInfo.app.pr_enabled}
                prCleanupEnabled={appInfo.app.pr_cleanup_enabled}
                prCommentEnabled={appInfo.app.prCommentEnabled}
                prInheritStageId={appInfo.app.prInheritStageId}
                gitProvider={appInfo.app.git_provider}
                editing={prState.editing}
                enabling={prState.enabling}
                disabling={prState.disabling}
                onEditClick={handlePrEdit}
                onSaveClick={handlePrSave}
                onCancelClick={handlePrCancel}
              />
            </SectionInfo>
          </div>

          <AppPipelineEditPanel
            app={appInfo.app}
            loading={updatingStageTypes}
            onSetStageTypeClick={handleSetStageType}
            onAddStageClick={handleAddStageModalShow}
            appStages={filterDeletedStages(appInfo.stages)}
          />

          <AppAddStage
            onAdd={handleAddStage}
            stages={activeStages}
            show={addStageState.show}
            type={addStageState.stageType}
            key={`stage-${addStageState.key}`}
            onClose={handleAddStageModalClose}
            onLoadBranches={handleLoadBranches}
          />
        </div>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(AppEditPipeline));
