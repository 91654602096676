import React from "react";
import FaIcon from "./FaIcon";
import CommitMessageSpan from "./CommitMessageSpan";
import { shortHash } from "../lib/gitLib";
import "./CommitInfoGroup.css";

export default function CommitInfoGroup({ activity }) {
  const commit = activity.commit;
  const branch = activity.branch;
  const branchUrl = activity.branch_url;
  const pr = activity.pr;
  const prUrl = activity.pr_url;
  const commitHash = shortHash(commit.id);
  const commitUrl = commit.url;

  const hasCommitMessage =
    commit && commit.message && commit.message.trim() !== "";
  const hasMessageCs = hasCommitMessage ? "with-message" : "no-message";

  return (
    <div className={`CommitInfoGroup ${hasMessageCs}`}>
      {hasCommitMessage && (
        <p className="message">
          <CommitMessageSpan commit={commit} />
        </p>
      )}

      <div className="info">
        {commit.author.profileLink && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={commit.author.profileLink}
          >
            {commit.author.name}
          </a>
        )}
        {!commit.author.profileLink && (
          <span className="author">{commit.author.name}</span>
        )}

        {branch && (
          <>
            <span className="separator">&bull;</span>

            <a target="_blank" href={branchUrl} rel="noopener noreferrer">
              <FaIcon name="code-fork" />
              {branch}
            </a>
          </>
        )}

        {pr && (
          <>
            <span className="separator">&bull;</span>

            <a target="_blank" rel="noopener noreferrer" href={prUrl}>
              <FaIcon name="code-fork" />
              pr#{pr}
            </a>
          </>
        )}

        <span className="separator">&bull;</span>

        <a target="_blank" rel="noopener noreferrer" href={commitUrl}>
          {commitHash}
        </a>
      </div>
    </div>
  );
}
