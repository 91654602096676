import React, { useState, useEffect } from "react";
import LoaderButton from "./LoaderButton";
import LoaderToggle from "./LoaderToggle";
import SectionInfo from "./SectionInfo";
import StageBranchModal from "./StageBranchModal";
import { useFormReducer } from "../lib/hooksLib";
import "./StageBranchPanel.css";

const helpUrl = "https://seed.run/docs/updating-the-stage-source";

export default function StageBranchPanel({
  branch,
  branches,
  gitRepo,

  resetKey,
  loading,
  editing,
  enabling,
  disabling,
  hasMoreBranches,
  loadingMoreBranches,

  onEditClick,
  onSaveClick,
  onCancelClick,
  onLoadMoreBranchesClick,
}) {
  const [modalKey, setModalKey] = useState(0);

  const [
    { values: formData },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ branch });

  useEffect(() => {
    formDispatch({ type: "reset" });
  }, [resetKey, formDispatch]);

  useEffect(() => {
    if (editing) {
      setModalKey((modalKey) => modalKey + 1);
    }
  }, [editing]);

  function handleChange(e) {
    handleFieldChange(e);

    if (e.target.checked) {
      onEditClick();
    } else {
      onSaveClick(null);
    }
  }

  return (
    <div className="StageBranchPanel">
      <SectionInfo
        label="Auto-Deploy"
        description={
          <span>
            {branch
              ? "Update the branch that is connected to this stage."
              : "Connect a branch to this stage."}
            &nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              {branch ? "Learn more." : "Learn about auto-deploying a branch."}
            </a>
          </span>
        }
      >
        {!gitRepo && (
          <p className="current">
            This app is not connected to a Git repository.
          </p>
        )}
        <LoaderToggle
          id="branch"
          loading={(loading && !branch) || disabling}
          onChange={handleChange}
          checked={formData.branch}
          disabled={!gitRepo}
        >
          {branch && (
            <>
              Auto-deploying <b>{branch}</b>
            </>
          )}
          {!branch && <>Auto-deploy a branch</>}
        </LoaderToggle>
        {branch && gitRepo && (
          <span className="edit">
            .&nbsp;
            <LoaderButton
              bsStyle="link"
              onClick={onEditClick}
              loading={loading && branch}
              disabled={!gitRepo || enabling || disabling}
            >
              Edit branch.
            </LoaderButton>
          </span>
        )}
      </SectionInfo>

      {branches && (
        <StageBranchModal
          key={`StageBranchModal-${modalKey}`}
          branch={branch}
          branches={branches}
          show={editing}
          saving={enabling}
          hasMoreBranches={hasMoreBranches}
          loadingMoreBranches={loadingMoreBranches}
          onCloseClick={onCancelClick}
          onSaveClick={onSaveClick}
          onLoadMoreBranchesClick={onLoadMoreBranchesClick}
        />
      )}
    </div>
  );
}
