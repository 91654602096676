const dateOpts = {
  hour: "numeric",
  minute: "numeric",
};
const oldDateOpts = {
  hour: "numeric",
  minute: "numeric",
  month: "short",
  day: "numeric",
};
const lastYearDateOpts = {
  month: "short",
  day: "numeric",
  year: "numeric",
};

function milisecondsToSeconds(ms) {
  return Math.round(ms / 1000);
}
function milisecondsToMinutes(ms) {
  return Math.round(ms / (60 * 1000));
}
function milisecondsToHours(ms) {
  return Math.round(ms / (3600 * 1000));
}
function milisecondsToDays(ms) {
  return Math.round(ms / (86400 * 1000));
}
function milisecondsToMonths(ms) {
  return Math.round(ms / (2592000 * 1000));
}
function milisecondsToYears(ms) {
  return Math.round(ms / (31536000 * 1000));
}

function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

function getDaysInMonth(year, month) {
  return [
    31,
    isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ][month];
}

export function dateToSinceTime(timestamp) {
  const date = new Date(timestamp);
  const currentDate = new Date();
  const current = currentDate.getTime();
  const diff = current - timestamp;

  return diff <= 59 * 1000
    ? diff <= 1.5 * 1000
      ? "1 second ago"
      : `${milisecondsToSeconds(diff)} seconds ago`
    : diff <= 3569 * 1000
    ? diff <= 90 * 1000
      ? "1 minute ago"
      : `${milisecondsToMinutes(diff)} minutes ago`
    : diff <= 43199 * 1000
    ? // 3:21 PM
      date.toLocaleString("en-US", dateOpts)
    : date.getFullYear() === currentDate.getFullYear()
    ? // Jul 21, 3:21 PM
      date.toLocaleString("en-US", oldDateOpts)
    : // Jul 21, 2016
      date.toLocaleString("en-US", lastYearDateOpts);
}
export function dateToSinceTimeIssues(timestamp) {
  const currentDate = new Date();
  const current = currentDate.getTime();
  const diff = current - timestamp;

  if (diff <= 1.5 * 1000) {
    return "1 sec";
  } else if (diff <= 59 * 1000) {
    return `${milisecondsToSeconds(diff)} secs`;
  } else if (diff <= 60 * 1.5 * 1000) {
    return "1 min";
  } else if (diff <= 3569 * 1000) {
    return `${milisecondsToMinutes(diff)} mins`;
  } else if (diff <= 3600 * 1.5 * 1000) {
    return "1 hour";
  } else if (diff <= 86399 * 1000) {
    return `${milisecondsToHours(diff)} hours`;
  } else if (diff <= 86400 * 1.5 * 1000) {
    return "1 day";
  } else if (diff <= 2591999 * 1000) {
    return `${milisecondsToDays(diff)} days`;
  } else if (diff <= 2592000 * 1.5 * 1000) {
    return "1 month";
  } else if (diff <= 31535999 * 1000) {
    return `${milisecondsToMonths(diff)} months`;
  } else if (diff <= 31536000 * 1.5 * 1000) {
    return "1 year";
  }
  return `${milisecondsToYears(diff)} years`;
}
export function dateToSinceTimeIssuesDashboard(timestamp) {
  const currentDate = new Date();
  const current = currentDate.getTime();
  const diff = current - timestamp;

  if (diff <= 59 * 1000) {
    return `${milisecondsToSeconds(diff)}s`;
  } else if (diff <= 3569 * 1000) {
    return `${milisecondsToMinutes(diff)}m`;
  } else if (diff <= 86399 * 1000) {
    return `${milisecondsToHours(diff)}h`;
  }
  return `${milisecondsToDays(diff)}d`;
}

export function dateToFullTime(timestamp) {
  return new Date(timestamp).toLocaleString("en-us", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

export function dateToFullTimeWithLocalTimeZone(timestamp) {
  return new Date(timestamp).toLocaleString("en-us", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZoneName: "short",
  });
}

export function dateToFullTimeWithUTCTimeZone(timestamp) {
  return new Date(timestamp).toLocaleString("en-us", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
    timeZoneName: "short",
  });
}

export function dateToFullTimeNoYear(timestamp) {
  return new Date(timestamp).toLocaleString("en-us", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function dateToFullTimeNoYearWithUTCTimeZone(timestamp) {
  return new Date(timestamp).toLocaleString("en-us", {
    hour: "2-digit",
    hour12: false,
    minute: "2-digit",
    second: "2-digit",
    month: "short",
    day: "2-digit",
    timeZone: "UTC",
    timeZoneName: "short",
  });
}

export function dateToFullTimeNoYearWithLocalTimeZone(timestamp) {
  return new Date(timestamp).toLocaleString("en-us", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    month: "short",
    day: "2-digit",
    hour12: false,
    timeZoneName: "short",
  });
}

export function dateToFullTimeNoYearNoSecondWithUTCTimeZone(timestamp) {
  return new Date(timestamp).toLocaleString("en-us", {
    hour: "2-digit",
    minute: "2-digit",
    month: "short",
    day: "2-digit",
    timeZone: "UTC",
    hour12: false,
    weekday: "short",
    timeZoneName: "short",
  });
}

export function dateToFullTimeNoYearNoSecondWithLocalTimeZone(timestamp) {
  return new Date(timestamp).toLocaleString("en-us", {
    hour: "2-digit",
    minute: "2-digit",
    month: "short",
    day: "2-digit",
    hour12: false,
    weekday: "short",
    timeZoneName: "short",
  });
}

export function dateToFullDate(timestamp) {
  return new Date(timestamp).toLocaleString("en-us", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

export function dateToFullDateNoYear(timestamp) {
  return new Date(timestamp).toLocaleString("en-us", {
    month: "short",
    day: "numeric",
  });
}

export function formatDuration(startedAt, endedAt) {
  if (!endedAt || !startedAt || endedAt <= startedAt) {
    return;
  }

  const duration = Math.ceil((endedAt - startedAt) / 1000);
  const min = Math.floor(duration / 60);
  const sec = duration - min * 60;

  return min > 0 ? `${min}m ${sec}s` : `${sec}s`;
}

export function dateNextMonth(date) {
  const d = new Date(date);
  const n = date.getDate();

  d.setDate(1);
  d.setMonth(d.getMonth() + 1);
  d.setDate(Math.min(n, getDaysInMonth(d.getFullYear(), d.getMonth())));

  return d;
}

export function parseDateString(str) {
  str = str.trim();
  const date = parseDate(str);
  if (date) {
    return date;
  }

  const range = parseRange(str);
  if (range) {
    return range;
  }
}
function parseDate(date, refTimestamp = null) {
  // Sanitization: change 12:34pm to 12:34 pm
  date = date.replace(/(:\d{1,2})([ap])/gi, "$1 $2");

  const ret =
    parseFullDate(date) ||
    parseFullDateInDashFormat(date) ||
    parseTimestamp(date) ||
    parseRelative(date) ||
    parseTime(date, refTimestamp) ||
    parseSentryEmailDate(date) ||
    parseAWSStepFunctionDate(date) ||
    parseAWSAccessLogDate(date);

  if (ret) {
    const dateUp = date.toUpperCase();
    ret.isUTC =
      dateUp.endsWith("UTC") ||
      dateUp.endsWith("Z") ||
      dateUp.endsWith("+00:00") ||
      dateUp.endsWith("-00:00") ||
      dateUp.endsWith("GMT") ||
      dateUp.endsWith("GMT+0000") ||
      dateUp.endsWith("GMT-0000");
  }

  return ret;
}
function parseRange(date) {
  const ret = date.match(/(.*)\s+(to|-)\s+(.*)/);
  if (ret && ret.length >= 4) {
    const start = parseDate(ret[1]);
    const end = start && parseDate(ret[3], start.ts);
    if (start && end) {
      return {
        type: "range",
        startTs: start.ts,
        endTs: end.ts,
        isUTC: start.isUTC || end.isUTC,
      };
    }
  }
}
function parseFullDate(date) {
  const ret = Date.parse(date);
  if (!isNaN(ret)) {
    const dateObj = new Date(ret);
    if (dateObj.getUTCFullYear() <= 2001) {
      const currentDate = new Date();
      const currentYear = currentDate.getUTCFullYear();
      const currentMonth = currentDate.getUTCMonth();
      dateObj.setUTCFullYear(
        dateObj.getUTCMonth() > currentMonth ? currentYear - 1 : currentYear
      );
    }

    return {
      type: "absolute",
      ts: dateObj.getTime(),
    };
  }
}
function parseFullDateInDashFormat(date) {
  // ie. '2019-11-13 11:57:58',
  const dateFmt = date.replace(/(\d{4})-(\d{1,2})-(\d{1,2})/g, "$1/$2/$3");
  const ret = Date.parse(dateFmt);
  if (!isNaN(ret)) {
    return {
      type: "absolute",
      ts: ret,
    };
  }
}
function parseTimestamp(date) {
  if (isNaN(date)) {
    return;
  }

  let ret = parseInt(date);

  // handle timestamp without ms
  ret = date.length === 10 ? ret * 1000 : ret;
  return {
    type: "absolute",
    ts: ret,
  };
}
function parseRelative(date) {
  const unitSec = ["s", "sec", "secs", "second", "seconds"];
  const unitMin = ["m", "min", "mins", "minute", "minutes"];
  const unitHr = ["h", "hr", "hrs", "hour", "hours"];
  const unitDay = ["d", "day", "days"];
  const unitWk = ["w", "wk", "wks", "week", "weeks"];

  const regex = new RegExp(
    `^\\s*(\\d+|last( \\d+)?)\\s*(${unitSec.join("|")}|${unitMin.join(
      "|"
    )}|${unitHr.join("|")}|${unitDay.join("|")}|${unitWk.join("|")})\\s*$`,
    "i"
  );
  const ret = date.match(regex);
  if (ret && ret.length >= 4) {
    const num = parseInt(ret[1]);
    const unit = ret[3].toLowerCase();
    let timestamp;
    let unitNorm;
    let duration;
    if (unitSec.includes(unit)) {
      duration = num * 1000;
      timestamp = Date.now() - duration;
      unitNorm = num === 1 ? "second" : "seconds";
    } else if (unitMin.includes(unit)) {
      duration = num * 60000;
      timestamp = Date.now() - duration;
      unitNorm = num === 1 ? "minute" : "minutes";
    } else if (unitHr.includes(unit)) {
      duration = num * 3600000;
      timestamp = Date.now() - duration;
      unitNorm = num === 1 ? "hour" : "hours";
    } else if (unitDay.includes(unit)) {
      duration = num * 86400000;
      timestamp = Date.now() - duration;
      unitNorm = num === 1 ? "day" : "days";
    } else if (unitWk.includes(unit)) {
      duration = num * 604800000;
      timestamp = Date.now() - duration;
      unitNorm = num === 1 ? "week" : "weeks";
    }

    if (timestamp) {
      return {
        duration,
        type: "relative",
        ts: timestamp,
        normalized: `${num} ${unitNorm}`,
        isUTC: false,
      };
    }
  }
}
function parseTime(time, refTimestamp) {
  let timestamp;

  // Handle time format: 7am
  time = time.replace(/^(\d{1,2})\s?([ap]m)$/i, "$1:00 $2");

  // Case 1: time is standalone
  if (refTimestamp === null) {
    // ie. '2012-11-04T14:51:06.157Z'
    const refDate = new Date().toISOString();
    // ie. '2012/11/04 ..time..'
    timestamp = Date.parse(
      `${refDate.split("T")[0].replace(/-/g, "/")} ${time}`
    );
    if (!timestamp) {
      return;
    }

    while (timestamp > Date.now()) {
      timestamp = timestamp - 86400000;
    }
  }
  // Case 2: time is part of a range
  else {
    const refDate = new Date(refTimestamp).toISOString();
    timestamp = Date.parse(
      `${refDate.split("T")[0].replace(/-/g, "/")} ${time}`
    );
    if (!timestamp) {
      return;
    }

    if (timestamp < refTimestamp) {
      timestamp = timestamp + 86400000;
    } else if (timestamp > refTimestamp + 86400000) {
      timestamp = timestamp - 86400000;
    }
  }

  return {
    type: "absolute",
    ts: timestamp,
  };
}
function parseSentryEmailDate(date) {
  // ie. 'Nov. 18, 2019, 11:05:59 a.m. UTC'
  const dateSanitized = date.replace(/\./gi, "");
  return parseFullDate(dateSanitized);
}
export function parseAWSStepFunctionDate(date) {
  // ie. 'Jan 7, 2020 11:15:28.814 AM'
  // Sanitization: change 11:15:28.814 to 11:15:28
  const dateFmt = date.replace(/(\d{2}:\d{2}:\d{2})\.\d{3}/g, "$1");
  const ret = Date.parse(dateFmt);
  if (!isNaN(ret)) {
    return {
      type: "absolute",
      ts: ret,
    };
  }
}
export function parseAWSAccessLogDate(date) {
  // ie. '08/Jan/2020:05:00:12 +0000'
  // Sanitization: change 08/Jan/2020: to Jan 08, 2020
  const dateFmt = date.replace(/(\d{2})\/(\w{3})\/(\d{4}):/g, "$2 $1, $3 ");
  const ret = Date.parse(dateFmt);
  if (!isNaN(ret)) {
    return {
      type: "absolute",
      ts: ret,
    };
  }
}
