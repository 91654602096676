import React from "react";
import {
  Form,
  Checkbox,
  HelpBlock,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import Modal from "./Modal";
import TextButton from "./TextButton";
import LoaderButton from "./LoaderButton";
import StyledControlLabel from "./StyledControlLabel";
import { useFormReducer } from "../lib/hooksLib";
import "./AppBranchModal.css";

const defaultStageValue = "select stage";

export default function AppBranchModal({
  show = false,
  saving = false,

  stages = [],
  branchEnabled = false,
  branchCleanupEnabled = true,
  branchInheritStageId = false,

  onEnableClick,
  onCloseClick,
}) {
  const inheritStageIdExists = stages.some(
    (stage) => stage.name === branchInheritStageId
  );
  const [{ values: formData, isDirty }, , handleFieldChange] = useFormReducer({
    branchInheritStageId: inheritStageIdExists
      ? branchInheritStageId
      : defaultStageValue,
    branchCleanupEnabled,
  });

  function canSubmit() {
    const { branchInheritStageId } = formData;

    if (branchInheritStageId === defaultStageValue) {
      return false;
    }

    if (!branchEnabled) {
      return true;
    }

    return branchEnabled && isDirty;
  }

  function handleEnableClick(event) {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    const { branchCleanupEnabled, branchInheritStageId } = formData;

    onEnableClick({
      branchEnabled: true,
      branchCleanupEnabled,
      branchInheritStageId,
    });
  }

  function renderForm() {
    return (
      <Form className="form">
        <div className="fields">
          <FormGroup controlId="branchInheritStageId">
            <StyledControlLabel>
              Select a stage to copy the settings from
            </StyledControlLabel>
            <FormControl
              bsSize="large"
              disabled={saving}
              componentClass="select"
              value={formData.branchInheritStageId}
              onChange={handleFieldChange}
            >
              {!inheritStageIdExists && (
                <option disabled value={defaultStageValue}>
                  Select a stage
                </option>
              )}
              {stages.map((stage, i) => (
                <option key={i} value={stage.id}>
                  {stage.name}
                </option>
              ))}
            </FormControl>
          </FormGroup>
          <FormGroup>
            <Checkbox
              id="branchCleanupEnabled"
              validationState="error"
              onChange={handleFieldChange}
              checked={formData.branchCleanupEnabled}
              disabled={saving}
            >
              Remove the stage when the branch is deleted
            </Checkbox>
            <HelpBlock>
              Enable this if you want to automatically remove the stage and all
              the resources when the branch is deleted.
            </HelpBlock>
          </FormGroup>
        </div>
      </Form>
    );
  }

  return (
    <Modal show={show} className="AppBranchModal" onHide={onCloseClick}>
      <Modal.Header closeButton>
        <Modal.Title>Auto-Deploy Branches</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderForm()}</Modal.Body>
      <Modal.Footer>
        <TextButton onClick={onCloseClick}>Cancel</TextButton>
        <LoaderButton
          type="submit"
          bsSize="large"
          bsStyle="warning"
          disabled={!canSubmit()}
          loading={saving}
          onClick={handleEnableClick}
        >
          {branchEnabled ? "Update" : "Enable"}
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
