import React, { useState } from "react";
import RolesPanel from "./RolesPanel";
import LoaderButton from "./LoaderButton";
import MemberRoleForm, { MemberRoleFormModes } from "./MemberRoleForm";
import config from "../config";
import "./MemberPanel.css";

const noop = () => {};

export default function MemberPanel({
  apps = [],
  user = {},
  owner = null,
  removing = {},
  updating = {},

  isRemovable = true,
  isRbacAvailable = false,

  onRemoveClick = noop,
  onUpdateClick = noop,
}) {
  const [editing, setEditing] = useState(false);

  const email = user.email;
  const disabled =
    user.username === owner ||
    (!isRemovable &&
      user.permissions.includes(config.userPermissions.admin.value));

  function resetState() {
    setEditing(false);
  }

  function handleEditClick() {
    setEditing(true);
  }

  async function handleUpdateClick(permissions, appPermissions) {
    const email = user.email;

    await onUpdateClick(email, permissions, appPermissions);

    resetState();
  }

  function handleCancelClick() {
    resetState();
  }

  function renderPermissions() {
    return (
      <RolesPanel
        permissions={user.permissions}
        appPermissions={user.appPermissions}
      />
    );
  }

  return (
    <div className="MemberPanel">
      <div className="info">
        <p>{email}</p>
        {!editing && renderPermissions()}
        {editing && (
          <MemberRoleForm
            apps={apps}
            owner={owner}
            updating={updating[user.email]}
            mode={MemberRoleFormModes.update}
            isRbacAvailable={isRbacAvailable}
            initialPermissions={user.permissions}
            initialAppPermissions={user.appPermissions}
            onUpdateMemberClick={handleUpdateClick}
            onUpdateMemberCancelClick={handleCancelClick}
          />
        )}
      </div>
      {!editing && (
        <div className="controls">
          <LoaderButton
            disabled={disabled || removing[user.email]}
            bsStyle="link"
            onClick={handleEditClick}
          >
            Manage
          </LoaderButton>
          <span className="separator">&#124;</span>
          <LoaderButton
            disabled={disabled}
            bsStyle="red-link"
            loading={removing[user.email]}
            onClick={() => onRemoveClick(email)}
          >
            Remove
          </LoaderButton>
        </div>
      )}
    </div>
  );
}
