import React, { useState } from "react";
import { Nav, Navbar, NavItem, MenuItem, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import FaIcon from "./FaIcon";
import UserImage from "./UserImage";
import "./BrandNavbar.css";

export default function BrandNavbar({
  appId,
  email,
  ownerId,
  userInfo = {},
  onLogoutClick,
  isAuthenticated = false,
  isAuthenticating = false,
}) {
  const [key, setKey] = useState(0);

  let { username, imageLink: userImage, nameInitials: userInitials } = userInfo;

  username = username || email;

  // Use it to force a rerender and
  // close the dropdown on click
  function handleClick() {
    setKey(key + 1);
  }

  function renderOrg() {
    const orgName = ownerId === username ? `${ownerId}’s team` : ownerId;

    return (
      <>
        <LinkContainer className="org" to={`/orgs/${ownerId}/settings`}>
          <MenuItem onClick={handleClick}>
            <UserImage size="small" initials={ownerId[0]} />
            <span>
              <span>{orgName}</span>
              <span>Organization Settings</span>
            </span>
          </MenuItem>
        </LinkContainer>
        <MenuItem divider />
      </>
    );
  }

  return (
    <Navbar key={key} className="BrandNavbar" fluid inverse collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <Link className="navbar-brand" to="/">
            Seed
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav className="app-links">
          {ownerId && (
            <>
              <Navbar.Text>
                <FaIcon name="angle-right" />
              </Navbar.Text>
              <LinkContainer to={`/${ownerId}`}>
                <NavItem>{ownerId}</NavItem>
              </LinkContainer>
            </>
          )}
          {ownerId && appId && (
            <>
              <Navbar.Text>/</Navbar.Text>
              <LinkContainer to={`/${ownerId}/${appId}`}>
                <NavItem>{appId}</NavItem>
              </LinkContainer>
            </>
          )}
        </Nav>
        {!isAuthenticating && isAuthenticated && (
          <>
            <Nav pullRight>
              <NavDropdown
                noCaret
                id="menu-dropdown"
                title={
                  <UserImage
                    size="xsmall"
                    src={userImage}
                    initials={userInitials}
                  />
                }
              >
                <MenuItem header bsClass="main">
                  Logged in as&nbsp;
                  <b>{username}</b>
                </MenuItem>
                <MenuItem divider />
                {ownerId && renderOrg()}
                <LinkContainer to="/settings">
                  <MenuItem onClick={handleClick}>Account Settings</MenuItem>
                </LinkContainer>
                <LinkContainer to="/upgrade">
                  <MenuItem onClick={handleClick}>Upgrade Plan</MenuItem>
                </LinkContainer>
                <MenuItem onSelect={onLogoutClick}>Logout</MenuItem>
              </NavDropdown>
            </Nav>
            <Navbar.Text pullRight>
              <Navbar.Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://seed.run/docs"
              >
                Docs
              </Navbar.Link>
            </Navbar.Text>
          </>
        )}
        {!isAuthenticating && !isAuthenticated && (
          <Nav pullRight>
            <LinkContainer to="/signup">
              <NavItem>Sign up</NavItem>
            </LinkContainer>
            <LinkContainer to="/login">
              <NavItem>Login</NavItem>
            </LinkContainer>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}
