import React from "react";
import { Glyphicon } from "react-bootstrap";
import { getStageSettingsUrl, getAppStageRemoveUrl } from "../lib/urlLib";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import PipelineTableStageCell from "./PipelineTableStageCell";
import "./AppPipelineEditPanel.css";

const PropsContext = React.createContext(null);

const stageCopy = {
  dev: "Development",
  staging: "Staging",
  prod: "Production",
};

function Controls({
  type,
  appStage,
  loading,
  disableControls,
  onSetStageTypeClick,
}) {
  const disabled = disableControls || loading.length !== 0;
  const [loadingStage, loadingAction] = loading;
  const isLoading = appStage.name === loadingStage;

  return (
    <div className="controls">
      {(type === "dev" || type === "prod") && (
        <>
          <LoaderButton
            bsSize="small"
            bsStyle="link"
            disabled={disabled}
            loading={isLoading && loadingAction === "staging"}
            onClick={(e) => onSetStageTypeClick(e, appStage.name, "staging")}
          >
            Set as Staging
          </LoaderButton>
          <hr />
        </>
      )}
      {(type === "staging" || type === "prod") && (
        <>
          <LoaderButton
            bsSize="small"
            bsStyle="link"
            disabled={disabled}
            loading={isLoading && loadingAction === "dev"}
            onClick={(e) => onSetStageTypeClick(e, appStage.name, "dev")}
          >
            Set as Development
          </LoaderButton>
          {type === "staging" && <hr />}
        </>
      )}
      {(type === "dev" || type === "staging") && (
        <LoaderButton
          bsSize="small"
          bsStyle="link"
          disabled={disabled}
          loading={isLoading && loadingAction === "prod"}
          onClick={(e) => onSetStageTypeClick(e, appStage.name, "prod")}
        >
          Set as Production
        </LoaderButton>
      )}
    </div>
  );
}

function StageBody({ type, appStage, disableControls }) {
  const pr = appStage.pull_request;

  return (
    <div className="body">
      {!pr && (
        <PropsContext.Consumer>
          {({ loading, onSetStageTypeClick }) => (
            <Controls
              type={type}
              appStage={appStage}
              loading={loading}
              disableControls={disableControls}
              onSetStageTypeClick={onSetStageTypeClick}
            />
          )}
        </PropsContext.Consumer>
      )}
      {pr && <p>PR stages cannot be set as Staging or Production</p>}
    </div>
  );
}

function HeadRowCell({ appStage }) {
  return (
    <PropsContext.Consumer>
      {({ app }) => (
        <div className="header">
          <PipelineTableStageCell
            hideAction
            settingsIcon
            appStage={appStage}
            status={appStage.status}
            appStageLink={getStageSettingsUrl(
              app.owner_name,
              app.slug,
              appStage.name
            )}
            appStageRemoveLink={getAppStageRemoveUrl(
              app.owner_name,
              app.slug,
              appStage.name
            )}
          />
        </div>
      )}
    </PropsContext.Consumer>
  );
}

function EmptyStage({ type }) {
  const copyMap = {
    dev: "Dev",
    staging: "Staging",
    prod: "Prod",
  };

  return (
    <PropsContext.Consumer>
      {({ onAddStageClick }) => (
        <div className="placeholder" onClick={(e) => onAddStageClick(e, type)}>
          <div className="body">
            <div className="copy">
              <FaIcon name="plus" />
              <span>New {copyMap[type]} Stage</span>
            </div>
          </div>
        </div>
      )}
    </PropsContext.Consumer>
  );
}

function Stage({ type, appStage, disableControls }) {
  return (
    <div className="stage">
      <HeadRowCell appStage={appStage} />
      <StageBody
        type={type}
        appStage={appStage}
        disableControls={disableControls}
      />
    </div>
  );
}

function Stages({ type, appStages, hasDownstream = false }) {
  // do not allow setting prod as dev/staging if only 1 prod left
  const disableControls = type === "prod" && appStages.length === 1;

  // Add empty placeholder stage
  appStages.push(null);

  return (
    <div className="stage-group">
      {appStages.map((appStage, i) => {
        const hasMoreCs = hasDownstream ? "more" : "";
        const emptyCs = appStage === null ? "empty" : "";
        const label = i === 0 ? stageCopy[type] : <>&nbsp;</>;

        return (
          <div key={i} className={`stage-container ${hasMoreCs} ${emptyCs}`}>
            {i === 0 && (
              <div className="header">
                <SectionHeader>{label}</SectionHeader>
                {hasDownstream && <Glyphicon glyph="arrow-right" />}
              </div>
            )}
            {appStage && (
              <Stage
                type={type}
                appStage={appStage}
                disableControls={disableControls}
              />
            )}
            {!appStage && <EmptyStage type={type} />}
          </div>
        );
      })}
    </div>
  );
}

export default ({
  app,
  onDoneClick,
  loading = [],
  appStages = [],
  onAddStageClick,
  onSetStageTypeClick,
}) => {
  // Sort stages by dev/staging/prod
  const { dev, staging, prod } = appStages.reduce(
    (sortedStages, stage) => {
      if (stage.is_production) {
        sortedStages.prod.push(stage);
      } else if (stage.is_staging) {
        sortedStages.staging.push(stage);
      } else {
        sortedStages.dev.push(stage);
      }

      return sortedStages;
    },
    { dev: [], staging: [], prod: [] }
  );

  return (
    <div className="AppPipelineEditPanel">
      <div className="pipelineedit-content">
        <div className="col1">
          <div className="header">
            <SectionHeader>Editing Pipeline</SectionHeader>
          </div>
          <div className="body">
            <LoaderButton bsSize="large" onClick={onAddStageClick}>
              <FaIcon name="plus" />
              New Dev Stage
            </LoaderButton>
          </div>
        </div>
        <div className="col2">
          <PropsContext.Provider
            value={{
              app,
              loading,
              onAddStageClick,
              onSetStageTypeClick,
            }}
          >
            <Stages type="dev" appStages={dev} hasDownstream />
            <Stages type="staging" appStages={staging} hasDownstream />
            <Stages type="prod" appStages={prod} />
            <div className="spacer-col"></div>
          </PropsContext.Provider>
        </div>
      </div>
    </div>
  );
};
