import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import withCancel from "../components/ComponentWithCancel";
import LoadingSpinner from "../components/LoadingSpinner";
import ScreenHeader from "../components/ScreenHeader";
import ErrorAlert from "../components/ErrorAlert";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import "./UserBilling.css";

function UserBilling(props) {
  let isLoading = true;

  useEffect(() => {
    document.title = title("Usage");
  }, []);

  const { data: userInfo, error } = useAPILoad("/user/info", {
    polling: false,
  });

  if (userInfo) {
    isLoading = false;
  }

  /////////
  // API //
  /////////

  return (
    <div className="UserBilling">
      <ScreenHeader broder>Usage</ScreenHeader>
      {isLoading && <LoadingSpinner />}

      {error && <ErrorAlert error={error} />}

      {!isLoading && userInfo && (
        <Redirect to={`/orgs/${userInfo.user.username}/settings/billing`} />
      )}
    </div>
  );
}

export default withCancel(UserBilling);
