import React from "react";
import QRCode from "qrcode.react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import TextButton from "./TextButton";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import { useFormReducer } from "../lib/hooksLib";
import { testTwoFactorCode } from "../lib/regexLib";
import "./TwoFactorValidateForm.css";

export default function TwoFactorValidateForm({
  email,
  mfaCode,
  onHideClick,
  onValidateClick,
  isValidating = false,
}) {
  const issuer =
    process.env.REACT_APP_STAGE === "production" ? "Seed" : "SeedLocal";

  const [
    {
      values: { code },
      validation,
    },
    formDispatch,
    handleChange,
  ] = useFormReducer({ code: "" });

  async function handleValidateClick(event) {
    event.preventDefault();

    const formattedCode = code.trim();

    if (!testTwoFactorCode(formattedCode)) {
      formDispatch({ type: "validate", id: "code" });
      return;
    }

    onValidateClick(formattedCode);
  }

  return (
    <form className="TwoFactorValidateForm" onSubmit={handleValidateClick}>
      <div className="format">
        <div className="qr">
          <QRCode
            value={`otpauth://totp/${email}?secret=${mfaCode}&issuer=${issuer}`}
          />
          <p className="mfa-code">{mfaCode}</p>
        </div>
        <div className="code">
          <SectionHeader>Step 1</SectionHeader>
          <p className="copy">Scan the QR code in your authenticator app.</p>
          <SectionHeader>Step 2</SectionHeader>
          <p className="copy">Enter the six-digit code from your app.</p>
          <FormGroup
            bsSize="large"
            controlId="code"
            validationState={validation.code}
          >
            <FormControl
              autoFocus
              type="text"
              value={code}
              pattern="[0-9]*"
              inputMode="numeric"
              onChange={handleChange}
              placeholder="6-digit code"
              autoComplete="one-time-code"
            />
            {validation.code && (
              <HelpBlock>Please enter a valid six-digit code.</HelpBlock>
            )}
          </FormGroup>
        </div>
      </div>
      <div className="controls">
        <LoaderButton
          type="submit"
          bsSize="large"
          bsStyle="warning"
          loading={isValidating}
          disabled={code.trim() === ""}
          onClick={handleValidateClick}
        >
          Enable
        </LoaderButton>
        <TextButton disabled={isValidating} onClick={onHideClick}>
          Hide Two-Factor Settings
        </TextButton>
      </div>
    </form>
  );
}
