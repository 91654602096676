import React from "react";
import config from "../config";
import "./ContainerErrorPanel.css";

function errorCopy(type, code, context) {
  const copy = {
    owner: {
      OWNER_NOT_FOUND: () => ({
        title: `Sorry "${context.owner}" doesn't exist.`,
        description: "Please check the url and try again.",
      }),
      OWNER_NO_PERMISSION: () => ({
        title: `Sorry "${context.owner}" doesn't exist.`,
        description: "Please check the url and try again.",
      }),
    },
    app: {
      APP_NOT_FOUND: () => ({
        title: `We couldn't find the "${context.name}" app.`,
        description: "Please check the url and try again.",
      }),
    },
    appRemove: {
      APP_NOT_FOUND: () => ({
        title: "Sorry this app doesn't exist.",
        description: `The "${context.appName}" app might have been removed.`,
      }),
    },
    stage: {
      APP_NOT_FOUND: () => ({
        title: "Sorry this stage doesn't exist.",
        description: `The "${context.appName}" app might have been removed.`,
      }),
      STAGE_NOT_FOUND: () => ({
        title: "Sorry this stage doesn't exist.",
        description: `The "${context.name}" stage might have been removed.`,
      }),
    },
    stageRemove: {
      APP_NOT_FOUND: () => ({
        title: "Sorry this service doesn't exist.",
        description: `The "${context.appName}" app might have been removed.`,
      }),
      STAGE_NOT_FOUND: () => ({
        title: "Sorry this stage doesn't exist.",
        description: `The "${context.stageName}" stage might have been removed.`,
      }),
    },
    service: {
      APP_NOT_FOUND: () => ({
        title: "Sorry this service doesn't exist.",
        description: `The "${context.appName}" app might have been removed.`,
      }),
      SERVICE_NOT_FOUND: () => ({
        title: "Sorry this service doesn't exist.",
        description: `The "${context.name}" service might have been removed.`,
      }),
    },
    serviceStageRemove: {
      APP_NOT_FOUND: () => ({
        title: "Sorry this service doesn't exist.",
        description: `The "${context.appName}" app might have been removed.`,
      }),
      STAGE_NOT_FOUND: () => ({
        title: "Sorry this stage doesn't exist.",
        description: `The "${context.stageName}" stage might have been removed.`,
      }),
      SERVICE_NOT_FOUND: () => ({
        title: "Sorry this service doesn't exist.",
        description: `The "${context.name}" service might have been removed.`,
      }),
      SERVICE_STAGE_NOT_FOUND: () => ({
        title: "Sorry this service doesn't exist.",
        description: `The "${context.stageName}" stage might have been removed for the "${context.name}" service.`,
      }),
    },
    build: {
      APP_NOT_FOUND: () => ({
        title: "Sorry this build doesn't exist.",
        description: `The "${context.appName}" app might have been removed.`,
      }),
      STAGE_NOT_FOUND: () => ({
        title: "Sorry this build doesn't exist.",
        description: `The "${context.stageName}" stage might have been removed.`,
      }),
      BUILD_NOT_FOUND: () => ({
        title: "Sorry this build doesn't exist.",
        description: `Build v${context.id} might belong to a stage that has been removed.`,
      }),
    },
    buildService: {
      APP_NOT_FOUND: () => ({
        title: "Sorry this build doesn't exist.",
        description: `The "${context.appName}" app might have been removed.`,
      }),
      STAGE_NOT_FOUND: () => ({
        title: "Sorry this build doesn't exist.",
        description: `The "${context.stageName}" stage might have been removed.`,
      }),
      BUILD_NOT_FOUND: () => ({
        title: "Sorry this build doesn't exist.",
        description: `Build v${context.id} might belong to a stage that has been removed.`,
      }),
      SERVICE_NOT_FOUND: () => ({
        title: "Sorry this service doesn't exist.",
        description: `The "${context.serviceName}" service might have been removed.`,
      }),
      DEPLOYMENT_NOT_FOUND: () => ({
        title: "Sorry this build doesn't exist.",
        description: `The "${context.serviceName}" service was not deployed in build v${context.id}.`,
      }),
    },
    issues: {
      APP_NOT_FOUND: () => ({
        title: "Sorry this app doesn't exist.",
        description: `The "${context.appName}" app might have been removed.`,
      }),
      STAGE_NOT_FOUND: () => ({
        title: "Sorry this stage doesn't exist.",
        description: `The "${context.stageName}" stage might have been removed.`,
      }),
      ISSUE_GROUP_NOT_EXIST: () => ({
        title: "Sorry this issue doesn't exist.",
        description: "Please check the url and try again.",
      }),
      ISSUE_NOT_EXIST: () => ({
        title: "Sorry this issue doesn't exist.",
        description: "Please check the url and try again.",
      }),
    },
    reports: {
      REPORT_NOT_FOUND: () => ({
        title: "Sorry this report doesn't exist.",
        description: "Please check the report URL and try again.",
      }),
      REPORT_FAILED: () => ({
        title: "This report failed to generate.",
        description:
          "There seems to have been a problem generating this report.",
      }),
    },
    unsubscribe: {
      APP_NOT_FOUND: () => ({
        title: "Sorry this link is invalid.",
        description: `The "${context.name}" org might have been removed.`,
      }),
      TOKEN_NOT_FOUND: () => ({
        title: "Sorry this link is invalid.",
        description:
          "There seems to be a problem with the email that was sent to you.",
      }),
    },
  };

  return copy[type][code]();
}

export default function ContainerErrorPanel({ type, code, context }) {
  const { title, description } = errorCopy(type, code, context);

  return (
    <div className="ContainerErrorPanel">
      <h2>{title}</h2>
      <p>{description}</p>
      <p>
        You can <a href={`mailto:${config.contactEmail}`}>contact us</a> if you
        need any help.
      </p>
    </div>
  );
}
