import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import FaIcon from "./FaIcon";
import UserImage from "./UserImage";
import RightChevron from "./RightChevron";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import LoadingSpinner from "./LoadingSpinner";
import {
  dateToFullTimeNoYear,
  dateToFullTimeWithUTCTimeZone,
} from "../lib/timeLib";
import { safeParse } from "../lib/jsonLib";
import seedIcon from "../images/seed-icon.png";
import "./AuditLogList.css";

const buttonCopyMap = {
  next: "Older",
  prev: "Newer",
};
const iconCopyMap = {
  next: "angle-right",
  prev: "angle-left",
};
const gitIconMap = {
  github: "github",
  gitlab: "gitlab",
  bitbucket: "bitbucket",
  "github-enterprise": "github",
  "gitlab-enterprise": "gitlab",
};
const gitCopyMap = {
  github: "GitHub",
  gitlab: "GitLab",
  bitbucket: "Bitbucket",
  "github-enterprise": "GitHub Enterprise",
  "gitlab-enterprise": "GitLab Self-Managed",
};

function convertParamsToString(params) {
  params = safeParse(params);

  if (params === null) {
    return null;
  }

  let str = "";

  Object.keys(params)
    .filter((key) => key !== "gitActor")
    .map((key) => (str += `${key}: ${params[key]}, `));

  return str.replace(/, $/, "");
}

const dummyRecordsLength = 15;

function getDummyRecords(startDate) {
  // Default to current time but at the start of the hour
  startDate = startDate || new Date().setHours(0, 0, 0, 0);

  return [
    {
      createdAt: new Date(startDate - 10000).getTime(),
      action: "build.create_succeeded",
      actorType: "system",
      description:
        "Build 20 completed successfully in stage dev of the hello-world app",
    },
    {
      createdAt: new Date(startDate - 30000).getTime(),
      action: "build.created",
      actorType: "git",
      params: "",
      description:
        "Git webhook triggered a build in stage dev of the hello-world app",
    },
    {
      createdAt: new Date(startDate - 60000).getTime(),
      action: "build.create_succeeded",
      actorType: "system",
      description:
        "Build 19 completed successfully in stage dev of the hello-world app",
    },
    {
      createdAt: new Date(startDate - 90000).getTime(),
      action: "build.created",
      actorType: "git",
      params: "",
      description:
        "Git webhook triggered a build in stage dev of the hello-world app",
    },
    {
      createdAt: new Date(startDate - 10000).getTime(),
      action: "build.create_succeeded",
      actorType: "system",
      description:
        "Build 18 completed successfully in stage dev of the hello-world app",
    },
  ];
}

export default function AuditLogList({
  records = [],
  nextPageLink,
  prevPageLink,
  loading = false,
  billingLink = "",
  unavailable = false,
}) {
  if (unavailable && !loading) {
    if (records.length < dummyRecordsLength) {
      const lastRecord = records[records.length - 1];
      const startDate = lastRecord && lastRecord.createdAt;
      const dummyRecords = getDummyRecords(startDate);

      records = records.concat(
        dummyRecords.slice(0, dummyRecordsLength - records.length)
      );
    } else {
      records = records.slice(0, dummyRecordsLength);
    }

    nextPageLink = null;
    prevPageLink = null;
  }

  function renderEmpty() {
    return (
      <div className="empty">
        <p>No events have been recorded.</p>
      </div>
    );
  }

  function renderUpgradeSign() {
    return (
      <div className="upgrade-sign">
        <div>
          <p>Upgrade to the Enterprise plan to access Audit Logs.</p>
          <LinkContainer exact to={billingLink}>
            <LoaderButton bsSize="large" bsStyle="warning">
              Upgrade Plan
              <RightChevron />
            </LoaderButton>
          </LinkContainer>
        </div>
      </div>
    );
  }

  function renderLink(type, link) {
    const icon = iconCopyMap[type];
    const copy = buttonCopyMap[type];

    return link ? (
      // Since `exact` still matches if the querystring is different
      // use isActive to always set false
      <LinkContainer to={link} isActive={() => false}>
        <LoaderButton>
          {type === "prev" && <FaIcon name={icon} />}
          {copy}
          {type === "next" && <FaIcon name={icon} />}
        </LoaderButton>
      </LinkContainer>
    ) : (
      <LoaderButton disabled>
        {type === "prev" && <FaIcon name={icon} />}
        {copy}
        {type === "next" && <FaIcon name={icon} />}
      </LoaderButton>
    );
  }

  function renderDescription({ description, actorEmail, params }) {
    const strParams = convertParamsToString(params);

    return (
      <>
        {actorEmail && <p className="email">{actorEmail}</p>}
        <p className="copy">{description}</p>
        {strParams && <p className="params">{strParams}</p>}
      </>
    );
  }

  function renderActor({
    params,
    actorType,
    actorEmail,
    actorInitials,
    actorImageLink,
  }) {
    switch (actorType) {
      case "user":
        return (
          <UserImage
            size="small"
            name={actorEmail}
            src={actorImageLink}
            initials={actorInitials}
          />
        );
      case "system":
        return <UserImage name="Seed" size="small" src={seedIcon} />;
      case "cli":
        return (
          <div className="git-icon" title="CLI">
            <FaIcon name="terminal" />
          </div>
        );
      case "git":
        params = safeParse(params);

        let icon = "git";
        let title = "Git";

        if (params && params.gitActor) {
          const type = params.gitActor;
          icon = gitIconMap[type] || icon;
          title = gitCopyMap[type] || title;
        }

        return (
          <div className="git-icon" title={title}>
            <FaIcon name={icon} />
          </div>
        );
      default:
        return null;
    }
  }

  function renderRecords() {
    return records.map((record) => (
      <div key={record.id} className="item">
        <div className="avatar">{renderActor(record)}</div>
        <div className="description">{renderDescription(record)}</div>
        <div className="action">{record.action}</div>
        {record.sourceIp ? (
          <div className="ip">{record.sourceIp}</div>
        ) : (
          <div className="ip empty">—</div>
        )}
        <div
          className="time"
          title={dateToFullTimeWithUTCTimeZone(record.createdAt)}
        >
          {dateToFullTimeNoYear(record.createdAt)}
        </div>
      </div>
    ));
  }

  return (
    <div className="AuditLogList">
      <div className="flex-table">
        <div className="header">
          <SectionHeader>Event</SectionHeader>
          <SectionHeader>Action</SectionHeader>
          <SectionHeader>IP</SectionHeader>
          <SectionHeader>Time</SectionHeader>
        </div>
        <div className="body">{renderRecords()}</div>
      </div>

      {!loading && records.length === 0 && renderEmpty()}
      {loading && (
        <div className="loading">
          <LoadingSpinner />
        </div>
      )}

      <div className="footer">
        {renderLink("prev", prevPageLink)}
        {renderLink("next", nextPageLink)}
      </div>

      {unavailable && !loading && renderUpgradeSign()}
    </div>
  );
}
