import React, { useState } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { CardElement, injectStripe } from "react-stripe-elements";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import TextButton from "./TextButton";
import { useFormReducer } from "../lib/hooksLib";
import config from "../config";
import "./StripeCardForm.css";

const noop = () => {};

function StripeCardForm({
  isFree = false,
  loading = false,
  disabled = false,

  invoiceBilling = false,
  invoiceBillingEmail = "",

  freeValidateError = true,

  onCancel = noop,
  onSubmitFree = noop,
  onSubmitPaid = noop,

  ...props
}) {
  const freeCs = isFree ? "free" : "paid";

  const [isProcessing, setIsProcessing] = useState(false);
  const [{ values: formData }, , handleFieldChange] = useFormReducer({
    name: "",
  });

  const showLoading = isProcessing || loading;
  const showFields = !isFree && !invoiceBilling;
  const showValidateError = isFree && freeValidateError;

  async function handleSubmitClick(event) {
    event.preventDefault();

    // Handle free plan
    if (isFree) {
      onSubmitFree();
      return;
    }

    if (invoiceBilling) {
      onSubmitPaid({});
      return;
    }

    // Handle paid plan
    const { name } = formData;

    if (name.trim() === "") {
      return;
    }

    setIsProcessing(true);

    const { token, error } = await props.stripe.createToken({ name });

    setIsProcessing(false);

    onSubmitPaid({ token, error });
  }

  function renderInvoiceBillingDetails() {
    return (
      <div className="invoice-details">
        <h5>Invoice Billing Enabled</h5>
        <p>
          Billing email: <b>{invoiceBillingEmail}</b>
        </p>
        <a href={`mailto:${config.contactEmail}`}>
          Contact us if you need any help.
        </a>
      </div>
    );
  }

  function renderFields() {
    return (
      <div className="fields">
        <FormGroup bsSize="large" controlId="name">
          <StyledControlLabel>Cardholder&apos;s name</StyledControlLabel>
          <FormControl
            type="text"
            value={formData.name}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <StyledControlLabel>Credit Card Info</StyledControlLabel>
        <CardElement
          className="card-field"
          style={{
            base: { fontSize: "18px", fontFamily: "Rubik, sans-serif" },
          }}
        />
      </div>
    );
  }

  return (
    <div className={`StripeCardForm ${freeCs}`}>
      {showFields && <h4>Payment Details</h4>}
      <form onSubmit={handleSubmitClick}>
        {showFields && renderFields()}

        {invoiceBilling && renderInvoiceBillingDetails()}

        <div className="controls">
          {showValidateError && (
            <HelpBlock>
              Individual plans are limited to organizations with a single user.
            </HelpBlock>
          )}
          <LoaderButton
            type="submit"
            bsSize="large"
            bsStyle="warning"
            loading={showLoading}
            onClick={handleSubmitClick}
            disabled={disabled || showValidateError}
          >
            Update
          </LoaderButton>
          <TextButton className="btnHide" onClick={onCancel}>
            Hide Update Plan Info
          </TextButton>
        </div>
      </form>
    </div>
  );
}

export default injectStripe(StripeCardForm);
