import React from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import FaIcon from "./FaIcon";
import RightChevron from "./RightChevron";
import SectionHeader from "./SectionHeader";
import StyledButtonGroup from "./StyledButtonGroup";
import IssuesBarGraph, { STYLES } from "./IssuesBarGraph";
import { getAppStageIssueUrl, getAppStageBuildUrl } from "../lib/urlLib";
import {
  dateToFullTime,
  dateToSinceTimeIssues,
  dateToFullTimeWithUTCTimeZone,
} from "../lib/timeLib";
import { formatLargeNumbers } from "../lib/stringLib";
import "./IssueContextPanel.css";

const softLambdaCountMax = 10;
const hardLambdaCountMax = 15;

export default function IssueContextPanel({
  error,
  trends,
  createdAt,
  serviceName,
  lastErrorAt,
  lastBuildId,
  firstErrorAt,
  firstBuildId,
  errorShortId,
  lambdaCounts,
  lastErrorShortId,
}) {
  const { ownerId, appId, appStageId, errorGroupId } = useParams();

  function getErrorUrl(id) {
    return getAppStageIssueUrl(ownerId, appId, appStageId, errorGroupId, id);
  }

  function renderEvents() {
    const formatted = formatLargeNumbers(lastErrorShortId);
    const eventCopy = lastErrorShortId > 1 ? "events" : "event";

    return (
      <>
        <StyledButtonGroup className="events-nav">
          <LinkContainer isActive={() => false} to={getErrorUrl(1)}>
            <Button disabled={errorShortId === 1}>
              <FaIcon name="step-backward" />
            </Button>
          </LinkContainer>
          <LinkContainer
            isActive={() => false}
            to={getErrorUrl(errorShortId - 1)}
          >
            <Button disabled={errorShortId === 1}>Older</Button>
          </LinkContainer>
          <LinkContainer
            isActive={() => false}
            to={getErrorUrl(errorShortId + 1)}
          >
            <Button disabled={errorShortId === lastErrorShortId}>Newer</Button>
          </LinkContainer>
          <LinkContainer
            isActive={() => false}
            to={getErrorUrl(lastErrorShortId)}
          >
            <Button disabled={errorShortId === lastErrorShortId}>
              <FaIcon name="step-forward" />
            </Button>
          </LinkContainer>
        </StyledButtonGroup>
        <span
          title={lastErrorShortId !== formatted ? lastErrorShortId : undefined}
        >
          <span>{formatLargeNumbers(lastErrorShortId)}</span> {eventCopy}
        </span>
      </>
    );
  }

  function renderLambdaCount(count) {
    const formatted = formatLargeNumbers(count);

    return (
      <span title={formatted !== count ? count : undefined}>{formatted}</span>
    );
  }

  function renderLambdaCounts() {
    const sorted = lambdaCounts.sort((a, b) => b.count - a.count);
    const limited =
      sorted.length > hardLambdaCountMax
        ? sorted.slice(0, softLambdaCountMax)
        : sorted;

    return (
      <>
        {limited.map(({ lambdaName, count }) => (
          <div key={lambdaName} className="lambda">
            <span>{lambdaName}</span>
            <span>{renderLambdaCount(count)}</span>
          </div>
        ))}
        {sorted.length !== limited.length && (
          <p className="more">
            + {sorted.length - limited.length} other Lambda functions
          </p>
        )}
      </>
    );
  }

  return (
    <div className="IssueContextPanel">
      <div className="date" title={dateToFullTime(createdAt)}>
        {dateToFullTimeWithUTCTimeZone(createdAt)}
      </div>

      <div className="events">{renderEvents()}</div>

      <div className="trends trend-24h">
        <SectionHeader>Last 24 Hours</SectionHeader>
        <IssuesBarGraph
          duration="hour"
          style={STYLES.WIDE}
          data={trends["24hTrend"]}
        />
      </div>

      <div className="trends trend-30d">
        <SectionHeader>Last 30 Days</SectionHeader>
        <IssuesBarGraph
          duration="day"
          style={STYLES.WIDE}
          data={trends["30dTrend"]}
        />
      </div>

      <div className="seen first">
        <SectionHeader>First Seen</SectionHeader>
        <div className="times">
          <span>{dateToSinceTimeIssues(firstErrorAt)} ago</span>
          <span title={dateToFullTime(firstErrorAt)}>
            {dateToFullTimeWithUTCTimeZone(firstErrorAt)}
          </span>
        </div>
        <div className="build">
          <Link
            to={getAppStageBuildUrl(ownerId, appId, appStageId, firstBuildId)}
          >
            Build v{firstBuildId}
          </Link>
          <Link
            to={getAppStageBuildUrl(
              ownerId,
              appId,
              appStageId,
              firstBuildId,
              serviceName
            )}
          >
            View build info
            <RightChevron />
          </Link>
        </div>
      </div>

      <div className="seen last">
        <SectionHeader>Last Seen</SectionHeader>
        <div className="times">
          <span>{dateToSinceTimeIssues(lastErrorAt)} ago</span>
          <span title={dateToFullTime(lastErrorAt)}>
            {dateToFullTimeWithUTCTimeZone(lastErrorAt)}
          </span>
        </div>
        <div className="build">
          <Link
            to={getAppStageBuildUrl(ownerId, appId, appStageId, lastBuildId)}
          >
            Build v{lastBuildId}
          </Link>
          <Link
            to={getAppStageBuildUrl(
              ownerId,
              appId,
              appStageId,
              lastBuildId,
              serviceName
            )}
          >
            View build info
            <RightChevron />
          </Link>
        </div>
      </div>

      <div className="lambdas">
        <SectionHeader>Lambda Functions</SectionHeader>
        {renderLambdaCounts()}
      </div>
    </div>
  );
}
