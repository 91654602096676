import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import ServiceRemoveStageTable from "../components/ServiceRemoveStageTable";
import ItemRemoveStatusPanel from "../components/ItemRemoveStatusPanel";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import LoadingSpinner from "../components/LoadingSpinner";
import ScreenHeader from "../components/ScreenHeader";
import ErrorAlert from "../components/ErrorAlert";
import useAPILoad from "../lib/apiLoadLib";
import { useRemoveStatusReducer } from "../lib/hooksLib";
import { appSettingsBreadcrumb } from "../lib/breadcrumbLib";
import { errorHandler, getLoadError } from "../lib/errorLib";
import { getAppUrl, getServiceStageRemoveLogUrl } from "../lib/urlLib";
import title from "../lib/titleLib";
import "./ServiceRemove.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
  1001: "SERVICE_NOT_FOUND",
};

function ServiceRemove(props) {
  let isLoading = true;
  let loadError = null;

  let isCleaningUp;

  const { ownerId, appId, serviceId } = props.match.params;

  const [isInitialRemoving, setIsInitialRemoving] = useState(false);
  const [removeStatus, statusDispatch] = useRemoveStatusReducer();

  useEffect(() => {
    document.title = title(serviceId);
  }, [serviceId]);

  const { error, data: removeInfo, reload: reloadRemoveInfo } = useAPILoad(
    `/${ownerId}/${appId}/services/${serviceId}/delete_status`
  );

  // Tracks if this is in the removing state initially
  useEffect(() => {
    if (removeInfo) {
      setIsInitialRemoving(true);
    }
  }, [removeInfo]);

  if (removeInfo !== null) {
    isLoading = false;

    const appStagesData = getStagesBeingRemoved(removeInfo.appStagesData);

    isCleaningUp = appStagesData.length === 0;

    removeInfo.appStagesData = appStagesData;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  function getStagesBeingRemoved(appStagesData) {
    return appStagesData.filter((appStageDatum) => appStageDatum.stage);
  }

  /////////
  // API //
  /////////

  function removeAppStage(quickRemove) {
    return props.invokeApig({
      path: `/${ownerId}/${appId}/services/${serviceId}`,
      method: "DELETE",
      body: { mode: quickRemove ? "quick" : undefined },
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleAppStageRemove(quickRemove) {
    try {
      await removeAppStage(quickRemove);
      await reloadRemoveInfo();
      statusDispatch({ type: quickRemove ? "quick-removed" : "removed" });
    } catch (e) {
      errorHandler(e);
    }
  }

  function handleRemoveClick(e) {
    statusDispatch({ type: "removing" });
    handleAppStageRemove(false);
  }

  function handleShowRetryClick(e) {
    statusDispatch({ type: "show-retry" });
  }

  function handleHideRetryClick(e) {
    statusDispatch({ type: "hide-retry" });
  }

  function handleQuickRemoveClick(e) {
    statusDispatch({ type: "quick-removing" });
    handleAppStageRemove(true);
  }

  ////////////
  // Render //
  ////////////

  return (
    <div className="ServiceRemove">
      <ScreenHeader border breadcrumb={appSettingsBreadcrumb(props)}>
        {serviceId}
      </ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {!isLoading && loadError && isInitialRemoving && (
        <Redirect to={getAppUrl(ownerId, appId)} />
      )}

      {!isLoading && loadError && !isInitialRemoving && (
        <ContainerErrorPanel
          type="service"
          code={loadError}
          context={{
            appName: appId,
            name: serviceId,
          }}
        />
      )}

      {error && !loadError && <ErrorAlert error={error} />}

      {!isLoading && !loadError && removeInfo && (
        <>
          <ItemRemoveStatusPanel
            type="service"
            retry={removeStatus.retry}
            isCleaningUp={isCleaningUp}
            removing={removeStatus.removing}
            status={removeInfo.overallStatus}
            onRemoveClick={handleRemoveClick}
            onShowRetryClick={handleShowRetryClick}
            onHideRetryClick={handleHideRetryClick}
            quickRemoving={removeStatus.quickRemoving}
            onQuickRemoveClick={handleQuickRemoveClick}
            errorMessage={removeInfo.overallErrorMessage}
          />
          <ServiceRemoveStageTable
            stagesData={removeInfo.appStagesData.map((appStageDatum) => ({
              stage: appStageDatum.stage,
              appStage: appStageDatum.appStage,
              errorMessage: appStageDatum.stage.removeErrorMessage,
              removeLogLink: getServiceStageRemoveLogUrl(
                ownerId,
                appId,
                serviceId,
                appStageDatum.stage.name
              ),
            }))}
          />
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(ServiceRemove));
