import React, { Component } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { testEmail } from "../lib/regexLib";
import { isFieldEmpty } from "../lib/formLib";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import "./RequestDemoForm.css";

const noop = () => {};

const defaultProps = {
  isSigningup: false,
  onSignupClick: noop,
};

const defaultState = {
  formData: {
    name: "",
    email: "",
    company: "",
    team_size: "select team size",
  },
  validation: {},
};

class RequestDemoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
    };
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      validation: {
        ...this.state.validation,
        [id]: null,
      },
    });
  };

  handleSignupClick = async (event) => {
    event.preventDefault();

    const formData = { ...this.state.formData };

    formData.email = formData.email.trim();

    const hasError = Object.keys(formData).reduce((hasError, field) => {
      if (
        isFieldEmpty(formData[field]) ||
        (field === "team_size" &&
          formData[field] === defaultState.formData.team_size) ||
        (field === "email" && !testEmail(formData[field]))
      ) {
        hasError = true;
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            [field]: "error",
          },
        }));
      }
      return hasError;
    }, false);

    if (hasError) {
      return;
    }

    await this.props.onSignupClick(event, formData);
  };

  render() {
    const { formData, validation } = this.state;
    const signingup = this.props.isSigningup;

    return (
      <form className="RequestDemoForm" onSubmit={this.handleSignupClick}>
        <FormGroup
          bsSize="large"
          controlId="name"
          validationState={validation.name}
        >
          <StyledControlLabel>Name</StyledControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={formData.name}
            placeholder="Joe Smith"
            onChange={this.handleFieldChange}
          />
          {validation.name && <HelpBlock>Please enter your name.</HelpBlock>}
        </FormGroup>
        <FormGroup
          bsSize="large"
          controlId="email"
          validationState={validation.email}
        >
          <StyledControlLabel>Email</StyledControlLabel>
          <FormControl
            type="email"
            value={formData.email}
            placeholder="me@example.com"
            onChange={this.handleFieldChange}
          />
          {validation.email && (
            <HelpBlock>Please enter a valid email address.</HelpBlock>
          )}
        </FormGroup>
        <FormGroup
          bsSize="large"
          controlId="company"
          validationState={validation.company}
        >
          <StyledControlLabel>Company</StyledControlLabel>
          <FormControl
            type="text"
            value={formData.company}
            placeholder="Watermelon Inc"
            onChange={this.handleFieldChange}
          />
          {validation.company && (
            <HelpBlock>
              Please add the name of your company or set it as self-employed.
            </HelpBlock>
          )}
        </FormGroup>
        <FormGroup
          bsSize="large"
          controlId="team_size"
          validationState={validation.team_size}
        >
          <StyledControlLabel>Size of your team</StyledControlLabel>
          <FormControl
            componentClass="select"
            value={formData.team_size}
            onChange={this.handleFieldChange}
          >
            <option disabled value="select team size">
              Select the size of your team
            </option>
            <option value={1}>1</option>
            <option value={5}>2 - 5</option>
            <option value={10}>6 - 10</option>
            <option value={20}>11 - 20</option>
            <option value={50}>21 - 50</option>
            <option value={100}>50+</option>
          </FormControl>
          {validation.team_size && (
            <HelpBlock>Please select a team size.</HelpBlock>
          )}
        </FormGroup>
        <div className="controls">
          <LoaderButton
            type="submit"
            bsSize="large"
            bsStyle="warning"
            loading={signingup}
            onClick={this.handleSignupClick}
          >
            Request Demo
          </LoaderButton>
        </div>
      </form>
    );
  }
}

RequestDemoForm.defaultProps = defaultProps;

export default RequestDemoForm;
