import React, { Component } from "react";
import { Link } from "react-router-dom";
import withCancel from "../components/ComponentWithCancel";
import ScreenHeader from "../components/ScreenHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import UsernameUpdate from "./widgets/UsernameUpdate";
import title from "../lib/titleLib";
import { initCognitoSDK } from "../lib/awsLib";
import { querystring } from "../lib/urlLib";
import "./LoginSsoCallback.css";

class LoginSsoCallback extends Component {
  constructor(props) {
    super(props);

    document.title = title("Sign in");

    this.state = {
      user: null,
      email: null,
      loading: true,
      hasError: false,
    };
  }

  componentDidMount() {
    const code = querystring("code");
    const error = querystring("error");
    const state = querystring("state");

    // Case 1: login success => parse code
    if (code) {
      const auth = initCognitoSDK(null, {
        onSuccess: this.handleAuthSuccess,
        onFailure: this.handleAuthFailure,
      });
      auth.parseCognitoWebResponse(window.location.href);
    }

    // Case 2: login failure => retry login
    else if (error === "server_error" && state) {
      const auth = initCognitoSDK(state);
      auth.getSession();
    }
  }

  getUserInfo() {
    return this.props.invokeApig({ path: "/" });
  }

  updateUserInfo(username) {
    return this.props.invokeApig({
      path: "",
      method: "PUT",
      body: { username },
    });
  }

  getEmailFromPayload(result) {
    const idToken = result.getIdToken().getJwtToken();
    const payload = idToken.split(".")[1];
    const user = JSON.parse(atob(payload));

    return user.email;
  }

  handleUsernameUpdate = async (username) => {
    await this.updateUserInfo(username);
    this.completeLogin();
  };

  async loadUserInfo() {
    try {
      const { user } = await this.getUserInfo();

      if (user.username) {
        this.completeLogin();
        return;
      }

      this.setState({ user });
    } catch (e) {
      this.setState({ hasError: true });
    }
  }

  completeLogin() {
    this.props.setUserAsAuthenticated(this.state.email);
  }

  handleAuthSuccess = (result) => {
    const email = this.getEmailFromPayload(result);
    this.setState({ email });

    this.loadUserInfo();
  };

  handleAuthFailure = (error) => {
    this.setState({ hasError: true });
  };

  render() {
    const { user, loading, hasError } = this.state;

    return (
      <div className="LoginSsoCallback">
        <ScreenHeader border>Login</ScreenHeader>
        {loading && !hasError && user === null && <LoadingSpinner />}
        {hasError && (
          <div className="error">
            <h2>Sorry there was a problem logging you in.</h2>
            <p>
              Click <Link to="/login">here to login</Link> and try again.
            </p>
          </div>
        )}
        {user && !user.username && (
          <>
            <h4>Pick a username to get started.</h4>
            <div className="username-form">
              <p className="current">
                Please pick a username.&nbsp; Your username is used to namespace
                your apps.
              </p>
              <UsernameUpdate onUpdate={this.handleUsernameUpdate} />
            </div>
          </>
        )}
      </div>
    );
  }
}
export default withCancel(LoginSsoCallback);
