import React, { useReducer } from "react";
import { Link } from "react-router-dom";
import { Form, HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import RolesPanel from "./RolesPanel";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import MemberRoleModal, { MemberRoleModalModes } from "./MemberRoleModal";
import { getOrgBillingUrl } from "../lib/urlLib";
import { useFormReducer } from "../lib/hooksLib";
import { testEmail } from "../lib/regexLib";
import config from "../config";
import "./MemberRoleForm.css";

const noop = () => {};

const userPermissions = config.userPermissions;

const defaultSelectOption = "select team size";

const helpUrl =
  "https://seed.run/docs/adding-organization-members#member-roles";

const defaultFormState = {
  email: "",
  appSelect: defaultSelectOption,
};

function hasPermissionsChanged(newP, oldP) {
  return newP.length === 0 ||
    (oldP && oldP.length === newP.length && oldP.every((p) => newP.includes(p)))
    ? false
    : true;
}

function filterBillingPermission(permissions) {
  return permissions.filter(
    (permission) => permission !== config.userPermissions.billing.value
  );
}

function reducer(state, action) {
  switch (action.type) {
    case "edit-org":
      return {
        ...state,
        modalShow: true,
        modalKey: state.modalKey + 1,
        modalMode: MemberRoleModalModes.org,
        modalInitialPermissions: state.permissions,
      };
    case "edit-app":
      return {
        ...state,
        modalShow: true,
        modalEditAppId: action.app,
        modalKey: state.modalKey + 1,
        modalMode: MemberRoleModalModes.app,
        // Default to org level permission, but remove billing
        modalInitialPermissions:
          state.appPermissions[action.app] ||
          filterBillingPermission(state.permissions),
      };
    case "remove-app":
      delete state.appPermissions[action.app];
      return { ...state, isPermissionsDirty: true };
    case "modal-close":
      return {
        ...state,
        modalShow: false,
      };
    case "update":
      let newPermissions;
      let isPermissionsDirty;

      if (state.modalMode === MemberRoleModalModes.org) {
        isPermissionsDirty =
          state.isPermissionsDirty ||
          hasPermissionsChanged(action.newPermissions, state.permissions);
        newPermissions = { permissions: action.newPermissions };
      } else {
        isPermissionsDirty =
          state.isPermissionsDirty ||
          hasPermissionsChanged(
            action.newPermissions,
            state.appPermissions[state.modalEditAppId]
          );
        newPermissions = {
          appPermissions: {
            ...state.appPermissions,
            [state.modalEditAppId]: action.newPermissions,
          },
        };
      }

      return {
        ...state,
        ...newPermissions,
        modalShow: false,
        isPermissionsDirty,
      };
    default:
      return state;
  }
}

export const MemberRoleFormModes = {
  add: "add",
  update: "update",
};

export default function MemberRoleForm({
  onUpdate,
  apps = [],
  owner = null,
  adding = false,
  updating = false,
  isRbacAvailable = true,
  initialAppPermissions = {},
  mode = MemberRoleFormModes.add,
  initialPermissions = [userPermissions.admin.value],

  onAddMemberClick = noop,
  onUpdateMemberClick = noop,
  onAddMemberCancelClick = noop,
  onUpdateMemberCancelClick = noop,
}) {
  const [
    {
      modalKey,
      modalShow,
      modalMode,
      permissions,
      appPermissions,
      isPermissionsDirty,
      modalInitialPermissions,
    },
    dispatch,
  ] = useReducer(reducer, {
    modalKey: 0,
    modalMode: null,
    modalShow: false,
    isPermissionsDirty: false,
    modalInitialPermissions: [],
    permissions: initialPermissions,
    appPermissions: initialAppPermissions,
  });
  const [
    { values: formData, validation },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer(defaultFormState);

  const appListCs = isRbacAvailable ? "app-list" : "app-list disabled";
  const appsWithPermissions = Object.keys(appPermissions);

  const disabled = adding || updating;

  function canSubmitAddMemberForm() {
    return formData.email.trim() !== "";
  }

  function resetAppDropdown() {
    formDispatch({
      type: "edit",
      id: "appSelect",
      value: defaultFormState.appSelect,
    });
  }

  function handleOrgRoleEditClick() {
    dispatch({ type: "edit-org" });
  }

  function handleModalDoneClick(newPermissions) {
    resetAppDropdown();
    dispatch({ type: "update", newPermissions });
  }

  function handleModalCloseClick() {
    resetAppDropdown();
    dispatch({ type: "modal-close" });
  }

  function handleAppRoleAddClick() {
    dispatch({ type: "edit-app", app: formData.appSelect });
  }

  function handleAppRoleEditClick(app) {
    dispatch({ type: "edit-app", app });
  }

  function handleAppRoleRemoveClick(app) {
    dispatch({ type: "remove-app", app });
  }

  function handleAddMemberClick(event) {
    event.preventDefault();

    const email = formData.email.trim();

    if (!canSubmitAddMemberForm()) {
      return;
    }

    if (!testEmail(email)) {
      formDispatch({
        id: "email",
        type: "validate",
      });
      return;
    }

    onAddMemberClick(email, permissions, appPermissions);
  }

  function handleUpdateMemberClick() {
    onUpdateMemberClick(permissions, appPermissions);
  }

  function renderPermissions(permissions) {
    return <RolesPanel permissions={permissions} />;
  }

  function renderAppRole(app, permissions) {
    return (
      <div key={app} className="app-role">
        <div className="info">
          <p>{app}</p>
          {renderPermissions(permissions)}
        </div>
        <div className="controls">
          <LoaderButton
            bsStyle="link"
            disabled={disabled}
            onClick={() => handleAppRoleEditClick(app)}
          >
            Edit
          </LoaderButton>
          <span className="separator">&#124;</span>
          <LoaderButton
            bsStyle="red-link"
            disabled={disabled}
            onClick={() => handleAppRoleRemoveClick(app)}
          >
            Remove
          </LoaderButton>
        </div>
      </div>
    );
  }

  function renderAddMemberForm() {
    return (
      <Form inline className="add-member" onSubmit={handleAddMemberClick}>
        <FormGroup
          bsSize="large"
          controlId="email"
          validationState={validation.email}
        >
          <FormControl
            type="text"
            placeholder="Invite via Email"
            value={formData.email}
            onChange={handleFieldChange}
          />
          {validation.email && (
            <HelpBlock>Please enter a valid email address.</HelpBlock>
          )}
        </FormGroup>
        <LoaderButton
          text="Add"
          type="submit"
          bsSize="large"
          bsStyle="primary"
          loading={adding}
          onClick={handleAddMemberClick}
          disabled={!canSubmitAddMemberForm()}
        />
        <LoaderButton
          text="Cancel"
          bsSize="large"
          disabled={disabled}
          onClick={onAddMemberCancelClick}
        />
      </Form>
    );
  }

  function renderUpdateMemberControls() {
    return (
      <div className="update-controls">
        <LoaderButton
          bsSize="large"
          bsStyle="primary"
          loading={updating}
          onClick={handleUpdateMemberClick}
          disabled={disabled || !isPermissionsDirty}
        >
          Update
        </LoaderButton>
        <LoaderButton
          bsSize="large"
          disabled={disabled}
          onClick={onUpdateMemberCancelClick}
        >
          Cancel
        </LoaderButton>
      </div>
    );
  }

  return (
    <div className="MemberRoleForm">
      {mode === MemberRoleFormModes.add && renderAddMemberForm()}

      <div className="header">
        <SectionHeader>Roles</SectionHeader>
        <a target="_blank" href={helpUrl} rel="noopener noreferrer">
          Learn about member roles
        </a>
      </div>

      <div className="roles">
        <div className="org-roles">
          <div className="info">
            <SectionHeader className="org-header">Org Role</SectionHeader>
            {renderPermissions(permissions)}
          </div>
          <div className="controls">
            <LoaderButton
              bsStyle="link"
              disabled={disabled}
              onClick={handleOrgRoleEditClick}
            >
              Edit
            </LoaderButton>
          </div>
        </div>

        <div className="app-roles">
          {!isRbacAvailable && (
            <>
              <p className="restricted-copy">
                Please{" "}
                <Link to={getOrgBillingUrl(owner)}>
                  upgrade to the Enterprise plan
                </Link>{" "}
                to enable role-based access.
              </p>
            </>
          )}
          {appsWithPermissions.length > 0 && (
            <SectionHeader className="app-header">
              App Specific Roles
            </SectionHeader>
          )}
          <div className="app-roles-list">
            {appsWithPermissions.map((app) =>
              renderAppRole(app, appPermissions[app])
            )}
          </div>
          <div className={appListCs}>
            <FormControl
              id="appSelect"
              componentClass="select"
              value={formData.appSelect}
              onChange={handleFieldChange}
              disabled={disabled || !isRbacAvailable}
            >
              <option disabled value={defaultSelectOption}>
                Add a specific role for an app
              </option>
              {apps.map((app) => (
                <option key={app} value={app}>
                  {app}
                </option>
              ))}
            </FormControl>
            <LoaderButton
              onClick={handleAppRoleAddClick}
              disabled={disabled || formData.appSelect === defaultSelectOption}
            >
              Add
            </LoaderButton>
          </div>
        </div>
      </div>

      {mode === MemberRoleFormModes.update && renderUpdateMemberControls()}

      <MemberRoleModal
        owner={owner}
        key={modalKey}
        show={modalShow}
        mode={modalMode}
        isRbacAvailable={isRbacAvailable}
        onDoneClick={handleModalDoneClick}
        onCloseClick={handleModalCloseClick}
        initialPermissions={modalInitialPermissions}
      />
    </div>
  );
}
