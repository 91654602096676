import React, { Component } from "react";
import AppActivityList from "../../components/AppActivityList";
import { errorHandler } from "../../lib/errorLib";

const noop = () => {};

const defaultProps = {
  activitiesInfo: {
    activities: [],
  },
  pathParams: {},
  onLoadNextPage: noop,
  onRollback: noop,
  activeActivityIds: [],
  rollbackProcessingActivityIds: [],
};

const defaultState = {
  activities: [],
  isLoadMoreLoading: false,
};

class AppActivityListWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      activities: this.props.activitiesInfo.activities,
      nextToken: this.props.activitiesInfo.next_token,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newActivities = nextProps.activitiesInfo.activities;
    const oldActivities = prevState.activities;

    // Case 1: newActivities is EMPTY => use newActivities []
    // Case 2: oldActivities is EMPTY => use newActivities
    if (newActivities.length === 0 || oldActivities.length === 0) {
    }

    // Case 3: User has pushed new activity => top activity id changed
    // Case 4: User previously pressed load more => activity lengths are different
    else {
      const newTopActivity = newActivities[0];
      const oldTopActivity = oldActivities[0];

      if (
        newTopActivity.activity_id !== oldTopActivity.activity_id ||
        newActivities.length !== oldActivities.length
      ) {
        // Merge newActivities and oldActivities
        const newBottomActivity = newActivities[newActivities.length - 1];
        const lastIndex = oldActivities.findIndex(
          (oldActivity) =>
            oldActivity.activity_id === newBottomActivity.activity_id
        );
        const firstPageRemoved = oldActivities.slice(lastIndex + 1);
        newActivities = newActivities.concat(firstPageRemoved);
      }
    }

    return {
      activities: newActivities,
    };
  }

  handleLoadMore = async () => {
    try {
      this.setState({
        isLoadMoreLoading: true,
      });

      const results = await this.props.onLoadNextPage(this.state.nextToken);

      // Merge newActivities and oldActivities
      this.setState({
        activities: this.state.activities.concat(results.activities),
        nextToken: results.next_token,
        isLoadMoreLoading: false,
      });
    } catch (e) {
      this.setState({
        isLoadMoreLoading: false,
      });
      errorHandler(e);
    }
  };

  render() {
    const {
      pathParams,
      onRollback,
      activeActivityIds,
      rollbackProcessingActivityIds,
    } = this.props;
    return (
      <AppActivityList
        pathParams={pathParams}
        activities={this.state.activities}
        hasNextPage={this.state.nextToken !== undefined}
        isLoadMoreLoading={this.state.isLoadMoreLoading}
        activeActivityIds={activeActivityIds}
        rollbackProcessingActivityIds={rollbackProcessingActivityIds}
        handleRollbackClick={onRollback}
        handleLoadMoreClick={this.handleLoadMore}
      />
    );
  }
}

AppActivityListWidget.defaultProps = defaultProps;

export default AppActivityListWidget;
