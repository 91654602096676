import React, { Component } from "react";
import LoaderButton from "./LoaderButton";
import StyledControlLabel from "./StyledControlLabel";
import GitProviderSelect from "./GitProviderSelect";
import GitRepoSelect from "./GitRepoSelect";
import TextButton from "./TextButton";
import "./NewAppRepoForm.css";

const noop = () => {};

const defaultProps = {
  org: null,
  orgs: null,
  repos: null,
  nextToken: "",
  isEnabled: false,
  currentOrg: null,
  gitProvider: null,
  onOrgChange: noop,
  onConfirmClick: noop,
  isLoadingMore: false,
  onLoadMoreClick: noop,
  onChangeRepoClick: noop,
  onChangeProviderClick: noop,
};

const defaultState = {
  formData: {
    selectedRepo: null,
  },
};

class NewAppRepoForm extends Component {
  state = { ...defaultState };

  handleFieldChange = (event) => {
    const { value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        selectedRepo: value,
      },
    });

    this.props.onConfirmClick(value);
  };

  handleConfirmClick = () => {
    this.props.onConfirmClick(this.state.formData.selectedRepo);
  };

  handleChangeProviderClick = async (event) => {
    this.setState({ ...defaultState });
    await this.props.onChangeProviderClick();
  };

  render() {
    const { selectedRepo } = this.state.formData;
    const {
      org,
      orgs,
      repos,
      nextToken,
      isEnabled,
      currentOrg,
      gitProvider,
      isLoadingMore,
    } = this.props;

    const enabledCs = isEnabled ? "enabled" : "";

    return (
      <div className={`NewAppRepoForm ${enabledCs}`}>
        {gitProvider === null && (
          <>
            <StyledControlLabel>Select Your Git Provider</StyledControlLabel>
            <GitProviderSelect org={org} />
          </>
        )}
        {gitProvider !== null && (
          <GitRepoSelect
            orgs={orgs}
            repos={repos}
            value={selectedRepo}
            readOnly={!isEnabled}
            currentOrg={currentOrg}
            hasMore={nextToken !== ""}
            isLoadingMore={isLoadingMore}
            onChange={this.handleFieldChange}
            onOrgChange={this.props.onOrgChange}
            onLoadMoreClick={this.props.onLoadMoreClick}
          />
        )}
        {gitProvider !== null && (
          <div className="controls">
            {isEnabled && (
              <>
                <LoaderButton
                  type="submit"
                  bsSize="large"
                  bsStyle="warning"
                  disabled={selectedRepo === null}
                  onClick={this.handleConfirmClick}
                >
                  Select Repo
                </LoaderButton>
                <LoaderButton
                  bsStyle="link"
                  onClick={this.handleChangeProviderClick}
                >
                  Change providers
                </LoaderButton>
              </>
            )}
            {!isEnabled && (
              <TextButton onClick={this.props.onChangeRepoClick}>
                Pick a different repo
              </TextButton>
            )}
          </div>
        )}
      </div>
    );
  }
}

NewAppRepoForm.defaultProps = defaultProps;

export default NewAppRepoForm;
