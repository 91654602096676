import React from "react";
import { useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import { getAppIssuesSettingsUrl } from "../lib/urlLib";
import "./IssuesSplashPanel.css";

const tweetUrl = "https://twitter.com/SEED_run/status/1278671941759574016";

export default function IssuesSplashPanel() {
  const { ownerId, appId } = useParams();

  return (
    <div className="IssuesSplashPanel">
      <div className="col1">
        <h1>Issues</h1>
        <p className="desc">
          Native, real-time Lambda monitoring and alerting in Seed.
        </p>
        <ul className="fa-ul">
          <li>
            <i className="fa-li fa fa-star"></i>
            Works out-of-the-box. No code changes or external SDKs needed.
          </li>
          <li>
            <i className="fa-li fa fa-star"></i>
            Sends Slack or email alerts with a complete log of the failed
            request.
          </li>
          <li>
            <i className="fa-li fa fa-star"></i>
            Autodetects all Lambda failures. Including out of memory and
            timeouts.
          </li>
          <li>
            <i className="fa-li fa fa-star"></i>
            Supports native error reporting. Use `<code>console.error</code>` to
            report any exceptions.
          </li>
        </ul>
      </div>
      <div className="col2">
        <LinkContainer exact to={getAppIssuesSettingsUrl(ownerId, appId)}>
          <LoaderButton bsSize="large" bsStyle="warning" className="enable">
            Enable Issues
          </LoaderButton>
        </LinkContainer>
        <p className="waitlist-copy">
          Enable Issues to receive real-time error alerts for your Lambda
          functions.
        </p>
        <hr />
        <LoaderButton
          bsStyle="block"
          target="_blank"
          className="tweet"
          href={tweetUrl}
          rel="noopener noreferrer"
        >
          <FaIcon name="twitter" /> Share the love
        </LoaderButton>
        <p className="tweet-copy">
          Make sure to like and retweet our announcement!
        </p>
      </div>
    </div>
  );
}
