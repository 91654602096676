import React, { Component } from "react";
import withCancel from "../components/ComponentWithCancel";
import title from "../lib/titleLib";
import { captureException } from "../lib/sentryLib";
import { querystring } from "../lib/urlLib";
import LoadingSpinner from "../components/LoadingSpinner";
import ScreenHeader from "../components/ScreenHeader";
import TextButton from "../components/TextButton";
import config from "../config";
import "./OAuthCallback.css";

const providerCopyMap = {
  github: "GitHub",
  "github-enterprise": "GitHub Enterprise",
  bitbucket: "Bitbucket",
  gitlab: "GitLab",
  "gitlab-enterprise": "GitLab Enterprise",
};

class OAuthCallback extends Component {
  constructor(props) {
    super(props);

    this.providerCopy = providerCopyMap[this.props.match.params.provider];

    document.title = title(`Connect ${this.providerCopy}`);

    this.state = {
      loading: true,
      hasError: false,
      isBrowserError: false,
    };
  }

  async componentDidMount() {
    const code = querystring("code");

    // Validate gitProvider and redirectUri
    let gitProvider = this.props.match.params.provider;
    let redirectUri = "";
    if (gitProvider === "github-enterprise") {
      gitProvider = `ghe:${querystring("state")}`;
    } else if (gitProvider === "gitlab") {
      redirectUri = config.gitlab.redirect_uri;
    } else if (gitProvider === "gitlab-enterprise") {
      gitProvider = `gle:${querystring("state")}`;
      redirectUri = config.gitlabEE.redirect_uri;
    }

    // Validate OAuth code
    if (code === null || code === "") {
      const error = querystring("error");
      const desc = querystring("error_description");

      captureException(new Error(error), (scope) => {
        scope.setExtra("error_description", desc);
      });

      this.setState({
        loading: false,
        hasError: true,
      });
      return;
    }

    let bindRet;

    // Bind Git Token
    try {
      bindRet = await this.bindGitToken({
        git_provider: gitProvider,
        oauth_code: code,
        oauth_redirect_uri: redirectUri,
      });
    } catch (e) {
      captureException(e);
      this.setState({
        loading: false,
        hasError: true,
      });
      return;
    }

    this.setState({ loading: false });

    // Case 1: GitHub and no installations => install apps
    if (gitProvider === "github" && bindRet.installations.length === 0) {
      window.location = config.github.install_url;
      return;
    }
    // Case 2: Direct back to console
    else {
      try {
        window.opener.postMessage(
          {
            type: "oauth_code",
            source: config.postMessageSrc,
            provider: gitProvider,
            code,
          },
          "*"
        );
        window.close();
      } catch (e) {
        captureException(e);
        this.setState({
          hasError: true,
          isBrowserError: true,
        });
      }
    }
  }

  /////////
  // API //
  /////////

  bindGitToken(data) {
    return this.props.invokeApig({
      path: "/bind_git_token",
      method: "POST",
      body: data,
    });
  }

  //////////////
  // Handlers //
  //////////////

  handleCloseClick = (event) => {
    window.close();
  };

  render() {
    const { loading, hasError, isBrowserError } = this.state;
    return (
      <div className="OAuthCallback">
        <ScreenHeader border>Connect {this.providerCopy}</ScreenHeader>
        {loading && <LoadingSpinner />}
        {!loading && !hasError && (
          <div className="content">
            <h2>{`Added ${this.providerCopy} successfully!`}</h2>
            <p>
              Click{" "}
              <TextButton onClick={this.handleCloseClick}>here</TextButton> to
              close this window.
            </p>
          </div>
        )}
        {!loading && hasError && (
          <div className="content">
            <h2>
              {isBrowserError
                ? `There was a browser error while adding ${this.providerCopy}.`
                : `Sorry there was a problem adding ${this.providerCopy}.`}
              <br />
              Please <a href={`mailto:${config.contactEmail}`}>contact us</a> if
              this problem persists.
            </h2>
            {isBrowserError ? (
              <p>{`Or try adding ${this.providerCopy} through another browser.`}</p>
            ) : (
              <p>
                Click{" "}
                <TextButton onClick={this.handleCloseClick}>
                  here to close this window
                </TextButton>{" "}
                and try again.
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withCancel(OAuthCallback);
