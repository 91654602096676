import React, { useState } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import SectionInfo from "./SectionInfo";
import TextButton from "./TextButton";
import { testAppSlug } from "../lib/regexLib";
import { useFormReducer } from "../lib/hooksLib";
import "./AppNameForm.css";

export default function AppNameForm({
  slug,
  onSaveClick,
  saving = false,
  updated = false,
}) {
  const [showStatus, setShowStatus] = useState(updated);
  const [
    { values: formData, isDirty, validation },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ slug });

  const editing = isDirty.slug;

  const displayCs = editing ? "" : "display";

  function canSubmit() {
    return isDirty.slug === true && formData.slug.trim() !== "";
  }

  function handleCancelClick() {
    setShowStatus(false);
    formDispatch({ type: "reset" });
  }

  function handleSaveClick(event) {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    const slug = formData.slug;

    if (!testAppSlug(slug)) {
      formDispatch({
        id: "slug",
        type: "validate",
      });
      return;
    }

    onSaveClick(slug);
  }

  function renderForm() {
    return (
      <form className={displayCs} onSubmit={handleSaveClick}>
        <FormGroup controlId="slug" validationState={validation.slug}>
          <FormControl
            type="text"
            bsSize="large"
            disabled={saving}
            value={formData.slug}
            onChange={handleFieldChange}
          />
          {editing && validation.slug && (
            <HelpBlock>
              App names must start with a letter or number and can only contain
              uppercase or lowercase letters, numbers, and hyphens.
            </HelpBlock>
          )}
        </FormGroup>
        {!editing && showStatus && <p className="status">App name updated!</p>}
        {editing && (
          <div className="controls">
            <LoaderButton
              type="submit"
              bsSize="large"
              loading={saving}
              disabled={!canSubmit()}
              onClick={handleSaveClick}
            >
              Update
            </LoaderButton>
            <TextButton onClick={handleCancelClick}>Cancel</TextButton>
          </div>
        )}
      </form>
    );
  }

  return (
    <div className="AppNameForm">
      <SectionInfo label="App Name">{renderForm()}</SectionInfo>
    </div>
  );
}
