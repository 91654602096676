import React, { useEffect } from "react";
import SectionInfo from "./SectionInfo";
import LoaderToggle from "./LoaderToggle";
import { useFormReducer } from "../lib/hooksLib";
import "./SettingsTogglePanel.css";

export default function SettingsTogglePanel({
  enabled,
  updating,
  resetKey,
  available,
  controlLabel,
  unavailableLabel,
  sectionLabel,
  sectionDescription,
  helperLink,
  helperDescription,
  onUpdateClick,
}) {
  const [
    { values: formData },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ enabled });

  useEffect(() => {
    formDispatch({ type: "reset" });
  }, [resetKey, formDispatch]);

  function handleChange(e) {
    handleFieldChange(e);

    onUpdateClick(e.target.checked);
  }

  return (
    <div className="SettingsTogglePanel">
      <SectionInfo
        label={sectionLabel}
        description={
          <span>
            {sectionDescription}&nbsp;
            <a target="_blank" href={helperLink} rel="noopener noreferrer">
              {helperDescription}
            </a>
          </span>
        }
      >
        {!available && <p className="unavailable">{unavailableLabel}</p>}

        <LoaderToggle
          id="enabled"
          loading={updating}
          onChange={handleChange}
          checked={formData.enabled}
          disabled={!available}
        >
          {controlLabel}
        </LoaderToggle>
      </SectionInfo>
    </div>
  );
}
