import React, { useEffect, useReducer, useState } from "react";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import ErrorAlert from "../components/ErrorAlert";
import ScreenHeader from "../components/ScreenHeader";
import IssueLogPanel from "../components/IssueLogPanel";
import IssueInfoPanel from "../components/IssueInfoPanel";
import LoadingSpinner from "../components/LoadingSpinner";
import IssueActivityList from "../components/IssueActivityList";
import IssueContextPanel from "../components/IssueContextPanel";
import IssueStatusControl from "../components/IssueStatusControl";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import { appIssuesBreadcrumb } from "../lib/breadcrumbLib";
import { buildQueryStringUrl } from "../lib/urlLib";
import { getLoadError, errorHandler } from "../lib/errorLib";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import "./AppIssueDetails.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
  StageNotExist: "STAGE_NOT_FOUND",
  IssueGroupNotExist: "ISSUE_GROUP_NOT_EXIST",
  IssueNotExist: "ISSUE_NOT_EXIST",
};

const MODES = {
  ACTIVE: "active",
  MUTED: "muted",
  RESOLVED: "resolved",
};

const settingDefaultState = { key: 0, updated: false, updating: false };

function settingReducer(state, action) {
  switch (action.type) {
    case "update":
      return { ...state, updating: true };
    case "update-done":
      return { ...state, updating: false, updated: true, key: state.key + 1 };
    case "update-failed":
      return { ...state, updating: false, key: state.key + 1 };
    default:
      return state;
  }
}

function AppIssueDetails(props) {
  let isLoading = true;
  let loadError = null;
  let isMuted;
  let isResolved;

  const {
    ownerId,
    appId,
    appStageId,
    errorGroupId,
    errorId,
  } = props.match.params;

  const [muteState, muteDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [resolveState, resolveDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [reportState, reportDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [reportMessageState, reportMessageDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [pollingLogs, setPollingLogs] = useState(true);

  //////////
  // Load //
  //////////

  // Load error data
  const {
    data: [issueInfo, activitiesInfo],
    error: issueError,
    reload: reloadErrorInfo,
  } = useAPILoad([getAppErrorGETAPI(), getErrorActivitiesAPI()], {
    polling: false,
  });

  // load error log
  const { data: logInfo, error: logError } = useAPILoad(
    issueInfo && getErrorLogAPI(),
    { polling: pollingLogs }
  );

  if (issueInfo !== null) {
    isLoading = false;
  }

  if (issueError) {
    loadError = getLoadError(issueError, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  const loaded = !isLoading && !loadError && issueInfo;

  if (issueInfo) {
    isMuted = issueInfo.errorGroup.status === MODES.MUTED;
    isResolved = issueInfo.errorGroup.status === MODES.RESOLVED;
  }

  useEffect(() => {
    document.title = title(issueInfo ? issueInfo.error.errorType : "Issues");
  }, [appId, issueInfo]);

  /////////
  // API //
  /////////

  function getAppErrorGETAPI() {
    return appStageId
      ? `/${ownerId}/${appId}/stages/${appStageId}/errors/${errorGroupId}/${errorId}`
      : `/${ownerId}/${appId}/errors/default_stage/${errorGroupId}/${errorId}`;
  }

  function getAppErrorPUTAPI() {
    const { stageId } = issueInfo.error;
    return `/${ownerId}/${appId}/stages/${stageId}/errors/${errorGroupId}/${errorId}`;
  }

  function getErrorActivitiesAPI() {
    return appStageId
      ? `/${ownerId}/${appId}/stages/${appStageId}/errors/${errorGroupId}/activities`
      : `/${ownerId}/${appId}/errors/default_stage/${errorGroupId}/activities`;
  }

  function getErrorLogAPI() {
    const {
      stageId,
      createdAt,
      lambdaName,
      requestId,
      logStream,
    } = issueInfo.error;

    return buildQueryStringUrl(
      `/${ownerId}/${appId}/stages/${stageId}/resources/lambda_logs`,
      {
        version: "v20200224",
        mode: "request",
        region: issueInfo.region,
        start: createdAt - 900000,
        end: createdAt + 900000,
        lambdaName,
        filter: requestId ? `"${requestId}"` : "",
        logStreamName: logStream,
        logTimestamp: createdAt,
      }
    );
  }

  function updateError(data) {
    return props.invokeApig({
      path: getAppErrorPUTAPI(),
      method: "PUT",
      body: data,
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleResolveSave(resolved) {
    resolveDispatch({ type: "update" });
    try {
      await updateError({ resolved });
      await reloadErrorInfo();
      resolveDispatch({ type: "update-done" });
    } catch (e) {
      resolveDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleMuteSave(muted) {
    muteDispatch({ type: "update" });
    try {
      await updateError({ muted });
      await reloadErrorInfo();
      muteDispatch({ type: "update-done" });
    } catch (e) {
      muteDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleReport() {
    reportDispatch({ type: "update" });
    try {
      await updateError({ reported: true });
      reportDispatch({ type: "update-done" });
    } catch (e) {
      reportDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleReportMessage() {
    reportMessageDispatch({ type: "update" });
    try {
      await updateError({ reportedMessage: true });
      reportMessageDispatch({ type: "update-done" });
    } catch (e) {
      reportMessageDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleFullRequestLoaded() {
    setPollingLogs(false);
  }

  return (
    <div className="AppIssueDetails">
      {isLoading && <LoadingSpinner />}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="issues"
          code={loadError}
          context={{
            appName: appId,
            stageName: appStageId,
            errorGroupId,
            errorId,
          }}
        />
      )}

      {issueError && !loadError && <ErrorAlert error={issueError} />}

      {loaded && issueInfo && (
        <>
          <ScreenHeader
            border
            action={
              <IssueStatusControl
                muted={isMuted}
                resolved={isResolved}
                muting={muteState.updating}
                onMuteUpdate={handleMuteSave}
                resolving={resolveState.updating}
                onResolveUpdate={handleResolveSave}
              />
            }
            breadcrumb={appIssuesBreadcrumb(
              props,
              appStageId ? null : issueInfo.error.stageId
            )}
          >
            {issueInfo.error.errorType}
          </ScreenHeader>

          <div className="cols">
            <div className="col-1">
              <IssueInfoPanel
                muted={isMuted}
                resolved={isResolved}
                error={issueInfo.error}
                onReportClick={handleReport}
                onReportMessageClick={handleReportMessage}
                reported={reportState.updated}
                reportedMessage={reportMessageState.updated}
                reporting={reportState.updating}
                reportingMessage={reportMessageState.updating}
                serviceName={issueInfo.serviceName}
                muteType={issueInfo.errorGroup.muteType}
              />
              <br />
              <IssueLogPanel
                key={`${errorGroupId}-${errorId}`}
                logData={logInfo}
                logError={logError}
                logTimestamp={issueInfo.error.createdAt}
                logStreamName={issueInfo.error.logStream}
                onFullRequestLoaded={handleFullRequestLoaded}
              />
            </div>

            <div className="col-2">
              <IssueContextPanel
                trends={{
                  "24hTrend": issueInfo["24hTrend"],
                  "30dTrend": issueInfo["30dTrend"],
                }}
                serviceName={issueInfo.serviceName}
                lambdaCounts={issueInfo.lambdaCounts}
                createdAt={issueInfo.error.createdAt}
                errorShortId={issueInfo.error.errorShortId}
                lastBuildId={issueInfo.errorGroup.lastBuildId}
                lastErrorAt={issueInfo.errorGroup.lastErrorAt}
                firstBuildId={issueInfo.errorGroup.firstBuildId}
                firstErrorAt={issueInfo.errorGroup.firstErrorAt}
                lastErrorShortId={issueInfo.errorGroup.lastErrorShortId}
              />
              {activitiesInfo.recentActivities.length > 0 && (
                <IssueActivityList
                  recentHasMore={activitiesInfo.recentHasMore}
                  firstErrorAt={issueInfo.errorGroup.firstErrorAt}
                  recentActivities={activitiesInfo.recentActivities}
                  oldestActivities={activitiesInfo.oldestActivities}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(AppIssueDetails));
