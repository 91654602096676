import React from "react";
import { Link, useParams } from "react-router-dom";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import RightChevron from "./RightChevron";
import { stripProtocol, getAppDeployPhaseSettingsUrl } from "../lib/urlLib";
import "./AppPipelineInfoPanel.css";

function countPhases(services) {
  let count = 0;

  services.forEach(({ service }) => {
    count = Math.max(count, service.deploy_phase);
  });

  return count + 1;
}

const iconMap = {
  github: "github",
  github_enterprise: "github",
  gitlab: "gitlab",
  gitlab_enterprise: "gitlab",
  bitbucket: "bitbucket",
};

export default function AppPipelineInfoPanel({ appInfo, onAddServiceClick }) {
  const { ownerId, appId } = useParams();

  const app = appInfo.app;

  const servicesCount = appInfo.services.length;
  const servicesCopy =
    servicesCount === 1 ? <b>one service</b> : <b>{servicesCount} services</b>;

  const phasesCount = countPhases(appInfo.services);
  const phasesCopy =
    phasesCount === 1 ? <b>one phase</b> : <b>{phasesCount} phases</b>;

  return (
    <div className="AppPipelineInfoPanel">
      <div>
        <p>
          {app.git_provider && (
            <a target="_blank" href={app.repo} rel="noopener noreferrer">
              <FaIcon name={iconMap[app.git_provider] || "git"} />
              {stripProtocol(app.repo)}
            </a>
          )}
          {!app.git_provider && (
            <span className="empty">
              <FaIcon name="exclamation-triangle" />
              Not connected to a Git repository
            </span>
          )}
        </p>
      </div>
      <div>
        <p>
          Deploying {servicesCopy} in {phasesCopy}.
        </p>
        <Link
          className="settings"
          to={getAppDeployPhaseSettingsUrl(ownerId, appId)}
        >
          Manage Deploy Phases
          <RightChevron />
        </Link>
      </div>
      <LoaderButton bsStyle="warning" onClick={onAddServiceClick}>
        <FaIcon name="plus" />
        New Service
      </LoaderButton>
    </div>
  );
}
