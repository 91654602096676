import React, { useState } from "react";
import AppAddStageModal from "../../components/AppAddStageModal";
import { errorHandler } from "../../lib/errorLib";

export default function AppAddStage({
  type = null,
  show = false,
  stages = null,

  onAdd,
  onClose,
  onLoadBranches,
}) {
  const [branches, setBranches] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [isBranchChecked, setIsBranchChecked] = useState(false);
  const [isInheritChecked, setIsInheritChecked] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [adding, setAdding] = useState(false);

  async function handleBranchCheck(event) {
    setIsBranchChecked(true);

    if (branches) {
      return;
    }

    try {
      const { branches, nextToken } = await onLoadBranches(event);
      setBranches(branches);
      setNextToken(nextToken);
    } catch (e) {
      errorHandler(e);
    }
  }

  async function handleLoadMoreClick(event) {
    setIsLoadingMore(true);

    try {
      const {
        branches: newBranches,
        nextToken: newToken,
      } = await onLoadBranches(nextToken);

      setBranches((branches) => branches.concat(newBranches));
      setNextToken(newToken);
      setIsLoadingMore(false);
    } catch (e) {
      setIsLoadingMore(false);
      errorHandler(e);
    }
  }

  function handleBranchUncheck(event) {
    setIsBranchChecked(false);
  }

  function handleInheritCheck(event) {
    setIsInheritChecked(true);
  }

  function handleInheritUncheck(event) {
    setIsInheritChecked(false);
  }

  async function handleAddClick(event, stage) {
    setAdding(true);

    try {
      await onAdd(event, stage);
    } catch (e) {
      errorHandler(e);
    }

    setAdding(false);
  }

  function handleHideClick(event) {
    onClose();
  }

  return (
    <AppAddStageModal
      show={show}
      type={type}
      stages={stages}
      adding={adding}
      hasMore={nextToken}
      branches={branches}
      isLoadingMore={isLoadingMore}
      isBranchChecked={isBranchChecked}
      isInheritChecked={isInheritChecked}
      onAddClick={handleAddClick}
      onCloseClick={handleHideClick}
      onLoadMoreClick={handleLoadMoreClick}
      onBranchCheck={handleBranchCheck}
      onInheritCheck={handleInheritCheck}
      onBranchUncheck={handleBranchUncheck}
      onInheritUncheck={handleInheritUncheck}
    />
  );
}
