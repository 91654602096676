export default {
  0: "Sorry there was a problem making this request. Please refresh the page and try again.",

  // Project errors
  1001: "The requested project does not exist. Please check the URL and try again.",
  1002: "Project slugs must start with a letter or number and can only contain lowercase letters, numbers, and hyphens.",
  1003: "The GitHub Personal Access Token is invalid. Please check the token and try again.",
  1004: "The IAM Access Key is invalid. Please check the key and try again.",
  1005: "The IAM Secret Key is invalid. Please check the key and try again.",
  1006: "The KMS key we are using to encrypt your information is pending deletion. Please check the key and try again.",
  1007: "The KMS key we are using to encrypt your information is missing. Please check the key and try again.",
  1008: "The KMS key we are using to encrypt your information is currently disabled. Please check the key and try again.",
  1009: "The IAM credentials for this project does not have access to your AWS KMS. We need this to encrypt your information. Please give the appropriate permissions to the IAM user.",
  1010: "This GitHub repository is already in use for another project under your account.",
  1011: "This user has already been added to the project.",
  1015: "It looks like Seed does not have access to the given GitHub repository. Please refresh the page and try adding this repository again.",
  1020: "This service is currently busy building. Please wait for it to complete and try again",
  1022: "This service slug is already taken. Please pick another one and try again.",
  ProjectInvalidIamRole:
    "The IAM Role is invalid. Please check the key and try again.",

  // Service errors
  1201: "The given service name is invalid. Please pick a valid one and try again.",
  1202: "The given service path is invalid. Please pick a valid one and try again.",
  1203: "A service with the name already exists. Please pick another one and try again.",
  1204: "The service's slug cannot be updated during the beta.",
  1205: "The service's name cannot be updated during the beta.",

  // Stage errors
  2002: "This branch is already in use. Please pick another and try again.",
  2003: "This stage is currently busy. Please check the status and try again.",
  2006: "The given stage name is invalid. Please pick a valid one and try again.",
  2007: "The given stage name is already taken. Please try again.",
  2008: "The branch that you’ve specified is invalid. Please check the branch and try again.",
  2014: "This stage cannot be renamed because the project has already been deployed to.",
  2101: "The requested environment variable does not exist. Please the refresh the page and try again.",
  2102: "Environment variables must start with a letter and can only contain letters, numbers, and underscores.",
  2103: "The value for the given environment variable is invalid. Please try again.",
  StageNotExist:
    "The requested stage does not exist. Please check the URL and try again.",
  StageCFStackNotExist:
    "It looks like the requested resources have been removed. Please redeploy the stage and try again.",

  // Build errors
  3002: "This build is already in production and cannot be promoted.",
  3003: "This build is not in production and cannot be rolled back to.",
  3005: "Sorry this build cannot be canceled currently.",
  3101: "The requested build does not exist. Please check the URL and try again.",

  // User errors
  4008: "You don't have an active enterprise subscription. Please contact us for more information.",
  UserRemoveUnremovedMembers:
    "It looks like there are other members in your team. Please remove them from the team and try again.",
  UserRemoveUnremovedOrgs:
    "It looks like you are a member of another organization. Please leave the organization and try again.",
  UserRemoveUnremovedApps:
    "It looks like you have some apps in your organization. Please remove them and try again.",

  // App errors
  5004: "App slugs must start with a letter or number and can only contain lowercase letters, numbers, and hyphens.",
  5005: "This app slug is already taken. Please pick another one and try again.",
  5017: "This app is currently busy. Please try again once it's available.",
  AppNotConnectedToGit:
    "The app is not connected to a git repo. Please head over to your app settings and connect it.",
  AppStageDiffRegionExist:
    "Sorry, one or more services in this stage have already been deployed to a different region.",

  // App Stage errors
  5105: "The stage you are trying to deploy to is currently busy. Please try again once it's available.",
  5106: "The stage you are trying to promote to is currently busy. Please try again once it's available.",
  5107: "The current stage does not have a build to promote. Please deploy a build to the current stage.",
  5108: "You are trying to promote an unsuccessful build. Please fix the build and try again.",
  5109: "The stage you are trying to rollback is currently busy. Please try again once it's available.",
  5110: "This stage is currently on the build you are trying to rollback to. Please pick another build and try again.",
  5111: "The build you are trying to promote is in-progress. Please try again once it's complete.",
  5112: "The stage you are trying to deploy to has services that are being removed. Please try again once they have been removed.",
  5113: "The stage you are trying to promote to has services that are being removed. Please try again once they have been removed.",
  5114: "The stage you are trying to rollback has services that are being removed. Please try again once they have been removed.",
  5115: "This build cannot be redeployed since some of the services are no longer available.",

  // Org errors
  6002: "The organization name is already taken. Please pick another one and try again.",
  6003: "Please remove all the apps in the organization before proceeding.",
  6004: "Just a heads up, the person you are trying to invite is already in your organization!",
  6005: "Cannot remove the last admin user from the organization.",
  6007: "You don't have permission to perform this action.",
  6008: "Your organization does not have an active custom plan. Please contact us for more information.",
  6009: "It looks like you've hit the limit for the number of users in your organization. Head over to your billing page to upgrade.",
  6010: "You cannot select fewer seats than the ones set in your custom plan.",
  6018: "The given key is not a private key. Please check the SSH key and try again.",
  OrgNotExist:
    "The requested org does not exist. Please check the URL and try again.",
  OrgBillingSeatsBelowUsage:
    "Your organization currently has more members than the seats you’ve selected.",
  OrgBillingChangePlanRBAC:
    'Your org currently has members with specific roles. Please reset them to "Admin" and try again.',
  OrgBillingInvalidInvoicePlan:
    "The invoice billing option is not available on the Team plan. Contact us if you'd like make this change.",

  // Git errors
  8002: "We are unable to connect to your GitHub repo. Please head over to the app settings and disconnect your repo and reconnect it.",
  8003: "Your GitHub access token does not have access to the given repository. Please ensure that you are the owner of the repository.",
  8004: "It looks like Seed does not have access to the given GitHub repository. Please enable repository access in your Installed GitHub App settings.",
  8005: "The given Git repository is empty. Please add a Serverless project and try again.",
  8007: "Sorry there was a problem talking to GitLab. Please head over to your app settings and disconnect your repo and reconnect it.",
  8009: "Sorry there was a problem talking to BitBucket. Please head over to your app settings and disconnect your repo and reconnect it.",
  GitHubApiError:
    "Sorry there was a problem talking to GitHub. Please refresh the page and try again.",
  GitLabApiError:
    "Sorry there was a problem talking to GitLab. Please refresh the page and try again.",
  GitLabApiHooksPermission:
    "It looks like your GitLab account does not have the right permissions to configure hooks for this repository. Please ensure that you are a maintainer or owner of the repository.",
  BitbucketApiError:
    "Sorry there was a problem talking to BitBucket. Please refresh the page and try again.",
  GitBranchNotFound:
    "A matching branch, tag, or commit could not be found. Please enter a valid ref and try again.",

  // Billing errors
  8013: "The promo code you are using is invalid. Please try again.",
  stripe_card_error:
    "Sorry there was a problem talking to Stripe. Please try again or use another card.", // error.message override this errror message
  stripe_unknown_error:
    "Sorry there was a problem talking to Stripe. Please try again or use another card.",
  FeatureUnavailable:
    "Sorry, this feature is not available in your current plan.",

  // AWS errors
  8101: "The given IAM credentials are invalid. Please check your credentials and try again.",
  8105: "The IAM credentials for this app are invalid. Please update your credentials and try again.",
  8106: "It looks like this AWS account has not been verified. Please ensure your account has been properly configured.",
  8107: "It looks like the log group for this service has been removed. Please redeploy this stage and try again.",
  8151: "It looks like the resources attached to this stage have been removed. Please redeploy the stage and try again.",
  8161: "It looks like the API Gateway project for this stage has been removed. Please redeploy the stage and try again.",
  AWSPermissionGeneric:
    "It looks like the IAM credentials you are using does not have the right permissions to make this request.",
  AWSThrottling:
    "It looks like the API requests to your AWS services are being throttled. Please try again after a few seconds.",
  AWSLambdaFunctionNotExist:
    "It looks like the Lambda function has been removed. Please redeploy the stage and try again.",
  LogsInsightQueryTimeout:
    "CloudWatch was unable to process this request due to a large number of logs. Please reduce the time range being searched.",
  LogsInsightQueryFail:
    "Sorry there was a problem making this request to CloudWatch. Please try again.",

  // Cognito errors
  UserNotFoundException:
    "An account with that email was not found. Please check your credentials and try again.",
  NotAuthorizedException:
    "The email or password is incorrect. Please check your credentials and try again.",
  NotAuthorizedException_SESSION:
    "The session to check the confirmation code has expired. Please enter your username and password again.",
  UsernameExistsException:
    "This email is tied to an existing account. If you are having problems logging in to your account, please contact us.",
  LimitExceededException:
    "We’ve reached the limit on making this request. Please try again after some time.",
  CodeMismatchException:
    "The confirmation code you have entered is invalid. Please check your email and try again.",
  CodeMismatchException_LOGIN:
    "The six-digit code you have entered is invalid. Please check the code and try again.",
  ExpiredCodeException:
    "The confirmation code you have entered is invalid. Please request a new code and try again.",
  AliasExistsException:
    "An account with that email already exists. Please use another email address and try again.",
  UserNotConfirmedException:
    "It looks like an account with this email was created but not confirmed. Please try signing up again.",
  EnableSoftwareTokenMFAException:
    "The six-digit code you have entered is invalid. Please check the code and try again.",

  // Stripe errors
  stripe_validation_error: "The credit card info is incomplete.",

  // Frontend errors
  NO_PROMOTE_WHILE_ROLLBACK:
    "Sorry you cannot promote a build while a rollback is in progress.",
  NO_PROMOTE_WHILE_PROMOTE:
    "Sorry you cannot promote a build while another build is being promoted.",
  NO_ROLLBACK_WHILE_PROMOTE:
    "Sorry you cannot rollback while a build is being promoted.",
  INVALID_CURRENT_PASSWORD: "Sorry your current password is incorrect.",
  AWS_CLOCK_ERROR:
    "There was a security error while making this request. This can happen if your system clock is incorrect. Please try again or contact us if the problem persists.",
  INVALID_CODE_FORMAT:
    "The confirmation code format you have entered is invalid. Please check the code and try again.",
  UNABLE_TO_AUTHENTICATE:
    "Sorry there was a problem logging into your new account. Please refresh the page and try logging in.",

  // Internal errors
  user_is_not_signed_in: "user_is_not_signed_in",
  user_session_expired: "user_session_expired",

  // Misc errors
  generic:
    "Sorry there was a problem making this request. Please refresh the page and try again.",
};
