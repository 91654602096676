import React from "react";
import { Link } from "react-router-dom";
import { Glyphicon } from "react-bootstrap";
import AppListPanel from "./AppListPanel";
import "./AppList.css";

function getAddAppLink(name) {
  return `/orgs/${name}/new`;
}

export default function AppList({ owner, apps = [] }) {
  const isEmpty = apps.length === 0;

  function renderNoApps(addAppLink) {
    return (
      <li key={0} className="noProjects">
        <h2>Let&#8217;s get started!</h2>
        <Link to={addAppLink}>
          <Glyphicon className="plus" glyph="plus" />
          <span className="copy">Add your first app</span>
        </Link>
      </li>
    );
  }

  function renderNewApp(addAppLink) {
    return (
      <li key={0} className="newProject">
        <Link to={addAppLink}>
          <Glyphicon className="plus" glyph="plus" />
          <span className="copy">Add a New App</span>
        </Link>
      </li>
    );
  }

  function renderApps() {
    return (
      <div className="org">
        <ul>
          {renderNewApp(getAddAppLink(owner))}
          {apps.map((app) => (
            <li key={app.slug} className="project">
              <AppListPanel app={app} />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="AppList">
      {isEmpty ? renderNoApps(getAddAppLink(owner)) : renderApps()}
    </div>
  );
}
