import React from "react";
import { Area, Tooltip, AreaChart, ResponsiveContainer } from "recharts";
import { formatKeyMetricsNumbers } from "../lib/stringLib";
import "./KeyMetricsGraph.css";

const colorGrades = [
  "#C7C6CC",
  "#CBAFB3",
  "#CE989A",
  "#D28181",
  "#D56A68",
  "#D9534F",
];
export const durations = {
  hour: "hour",
  day: "day",
};
const height = 26;
const margins = { top: 0, right: 0, left: 0, bottom: 1 };

function areEqual(props, newProps) {
  const data = props.data;
  const newData = newProps.data;

  return (
    props.colorGrade === newProps.colorGrade &&
    data.length === newData.length &&
    data[0].t === newData[0].t &&
    data[0].v === newData[0].v &&
    data[data.length - 1].t === newData[newData.length - 1].t &&
    data[data.length - 1].v === newData[newData.length - 1].v
  );
}

function KeyMetricsGraph({
  id = "",
  data = [],
  colorGrade = 0,
  duration = durations.day,
}) {
  const color = colorGrades[colorGrade];

  data.forEach((datum) => {
    if (datum.v === null) {
      datum.v = 0;
      datum.isNull = true;
    }
  });

  function getGradientId(index) {
    // We need the id to make the gradient def unique
    // Since there are multiple graphs on the page
    // And if you remove one of the key metrics then
    // all the gradients will go black.
    return `gradient-${id}-${index}`;
  }

  function renderTooltip({ active, label, payload }) {
    if (!active || payload.length === 0) {
      return null;
    }

    const { t, v, isNull } = payload[0].payload;

    const date = new Date(t);
    const dayCopy = date.toLocaleString(
      "en-us",
      duration === durations.day
        ? { day: "numeric", month: "short", year: "numeric", timeZone: "UTC" }
        : { day: "numeric", month: "short", timeZone: "UTC" }
    );
    const hourCopy =
      duration === durations.hour
        ? date.toLocaleString("en-us", {
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
          }) +
          " — " +
          new Date(t + 59 * 60 * 1000).toLocaleString("en-us", {
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
          })
        : null;

    const formattedValue = formatKeyMetricsNumbers(v);

    return (
      <div className="tip">
        <div className="duration">
          <span className="day">{dayCopy}</span>
          {hourCopy && <span className="hour">{hourCopy}</span>}
        </div>
        <p className="value">
          <span>{isNull ? "-" : formattedValue[0]}</span>
          {!isNull && <span className="unit">{formattedValue[1]}</span>}
        </p>
      </div>
    );
  }

  function renderGradients() {
    return (
      <defs>
        {colorGrades.map((color, index) => (
          <linearGradient
            x1="0"
            y1="0"
            x2="0"
            y2="1"
            key={index}
            id={getGradientId(index)}
          >
            <stop offset="0%" stopColor={color} stopOpacity={0.8} />
            <stop offset="100%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        ))}
      </defs>
    );
  }

  return (
    <div className="KeyMetricsGraph">
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart data={data} margin={margins}>
          {renderGradients()}
          <Tooltip
            cursor={false}
            content={renderTooltip}
            isAnimationActive={false}
            position={{ x: 0, y: height + 5 }}
            wrapperStyle={{ left: 0, zIndex: 1, width: "100%" }}
          />
          <Area
            dataKey="v"
            connectNulls
            dot={false}
            type="linear"
            stroke={color}
            activeDot={false}
            isAnimationActive={false}
            fill={`url(#${getGradientId(colorGrade)})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default React.memo(KeyMetricsGraph, areEqual);
