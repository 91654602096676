import React from "react";
import { ControlLabel } from "react-bootstrap";
import "./StyledControlLabel.css";

const mainClassname = "StyledControlLabel";

export default ({ className, ...props }) => (
  <ControlLabel
    className={className ? `${mainClassname} ${className}` : mainClassname}
    {...props}
  >
    {props.children}
  </ControlLabel>
);
