import React from "react";
import { Link } from "react-router-dom";
import ServiceIcon from "./ServiceIcon";
import RightChevron from "./RightChevron";
import SectionHeader from "./SectionHeader";
import { formatStackNames } from "../lib/stringLib";
import { getAppStageUrl, getServiceUrl } from "../lib/urlLib";
import "./DashboardServicesPanel.css";

export default function DashboardServicesPanel({ services, pathParams }) {
  const { ownerId, appId, appStageId } = pathParams;

  function renderService({
    name,
    serviceType,
    stack,
    stacks,
    region,
    isDeployed,
  }) {
    const emptyCs = isDeployed === false ? "empty" : "";
    const serviceUrl = getServiceUrl(ownerId, appId, name);

    return (
      <div key={name} className={`service ${emptyCs}`}>
        {isDeployed !== false && (
          <>
            <div className="info">
              <Link to={serviceUrl}>
                <ServiceIcon type={serviceType} />
              </Link>
              <div>
                <h5>
                  <Link to={serviceUrl}>{name}</Link>
                </h5>
                {serviceType === "sls" && <p>{stack}</p>}
                {serviceType === "sst/resources" && (
                  <p>
                    {stacks ? formatStackNames(stacks) : "No stacks deployed"}
                  </p>
                )}
              </div>
            </div>
            <SectionHeader>{region}</SectionHeader>
          </>
        )}
        {isDeployed === false && (
          <>
            <div className="info">
              <Link to={serviceUrl}>
                <ServiceIcon type={serviceType} />
              </Link>
              <div>
                <h5>
                  <Link to={serviceUrl}>{name}</Link>
                </h5>
                <p>Not deployed</p>
              </div>
            </div>
            <SectionHeader>&mdash;</SectionHeader>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="DashboardServicesPanel">
      <h4>
        <span>Services</span>
        <Link to={getAppStageUrl(ownerId, appId, appStageId)}>
          View Resources <RightChevron />
        </Link>
      </h4>
      <div className="services">{services.map(renderService)}</div>
    </div>
  );
}
