import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Form, HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import Modal from "./Modal";
import FaIcon from "./FaIcon";
import ServiceIcon from "./ServiceIcon";
import LoaderButton from "./LoaderButton";
import StyledControlLabel from "./StyledControlLabel";
import SectionDescriptionLabel from "./SectionDescriptionLabel";
import { normalizeSearchPath } from "../lib/stringLib";
import { testServiceName } from "../lib/regexLib";
import "./AppAddServiceModal.css";
import config from "../config";

const helpUrl = "https://seed.run/docs/adding-a-service";

const noop = () => {};

const defaultProps = {
  show: false,
  adding: false,
  editing: false,
  searching: false,
  searchPath: null,
  serviceName: null,
  serviceFramework: undefined,
  searchError: null,

  onAddClick: noop,
  onCloseClick: noop,
  onSearchClick: noop,
  onChangeClick: noop,
};

const defaultFramework = "sst";
const defaultState = {
  path: "",
  name: "",
  framework: null,
  validation: null,
};

class AppAddServiceModal extends Component {
  state = { ...defaultState };

  constructor(props) {
    super(props);
    this.focusRef = React.createRef();
  }

  canAdd() {
    return this.state.name.trim() !== "";
  }

  handleFieldEdit = (event) => {
    const { id, value } = event.target;

    this.setState({
      [id]: value,
      validation: null,
    });
  };

  handleAddSubmit = (event) => {
    event.preventDefault();

    this.handleAddClick();
  };

  handleAddClick = () => {
    if (this.props.searchError) {
      if (!this.canAdd()) {
        return;
      }

      const name = this.state.name.trim();

      if (!testServiceName(name)) {
        this.setState({ validation: "error" });
        return;
      }

      this.props.onAddClick(name, this.state.framework);
    } else {
      this.props.onAddClick();
    }
  };

  handleSearchClick = async (event) => {
    event.preventDefault();

    this.props.onSearchClick(event, this.state.path);
    // Hack to prevent focus being set to body element after
    // hitting enter on the form. Closing the dialog after hitting
    // enter causes page to jump to the bottom.
    ReactDOM.findDOMNode(this.focusRef.current).focus();
  };

  renderForm() {
    const { path } = this.state;
    const {
      adding,
      editing,
      searching,
      searchPath,
      serviceName,
      serviceFramework,
      searchError,
    } = this.props;

    const status = searching
      ? "searching"
      : editing
      ? "search"
      : searchError
      ? "not-found"
      : "detected";

    const slsYmlPath = normalizeSearchPath(searchPath);
    const newSearchButton = (
      <LoaderButton
        bsStyle="link"
        disabled={adding}
        className="btnNewSearch"
        onClick={this.props.onChangeClick}
      >
        Try a different path
      </LoaderButton>
    );

    const noTypeOnError =
      status === "not-found" &&
      (searchError === config.serviceSearchErrors.GENERIC_NOT_FOUND ||
        searchError === config.serviceSearchErrors.UNKNOWN);

    return (
      <div tabIndex="-1" className="form-container" ref={this.focusRef}>
        <SectionDescriptionLabel>
          Add a service by pointing to its <b>directory</b> in your repo.&nbsp;
          <a target="_blank" href={helpUrl} rel="noopener noreferrer">
            Learn about adding a service to your app.
          </a>
        </SectionDescriptionLabel>
        {status === "detected" && (
          <>
            <div className="status detected">
              <div className="icon">
                <ServiceIcon type={serviceFramework} />
              </div>
              <div className="body">
                <div className="service">
                  <p className="name">
                    {serviceName ? serviceName : "Service"}
                  </p>
                  <p className="path">{slsYmlPath}</p>
                </div>
              </div>
            </div>
            {newSearchButton}
          </>
        )}
        {status === "not-found" && (
          <>
            <div className="status not-found">
              <div className="icon">
                <FaIcon name="exclamation-triangle" />
              </div>
              <div className="body">
                <p>
                  {(searchError === config.serviceSearchErrors.UNKNOWN ||
                    searchError ===
                      config.serviceSearchErrors.GENERIC_NOT_FOUND ||
                    searchError === config.serviceSearchErrors.SST_NOT_FOUND ||
                    searchError ===
                      config.serviceSearchErrors.SLS_NOT_FOUND) && (
                    <>
                      We could not find a service in the{" "}
                      <code>{slsYmlPath}</code> path in the <b>master</b>{" "}
                      branch.
                    </>
                  )}
                  {searchError ===
                    config.serviceSearchErrors.SST_CANNOT_PARSE && (
                    <>
                      We could not parse the <code>sst.config.ts</code> file in{" "}
                      <code>{slsYmlPath}</code>.
                    </>
                  )}
                  {searchError ===
                    config.serviceSearchErrors.SLS_CANNOT_PARSE && (
                    <>
                      We could not parse the <code>serverless.yml</code> file in{" "}
                      <code>{slsYmlPath}</code>.
                    </>
                  )}
                  {(searchError ===
                    config.serviceSearchErrors.SST_INVALID_SERVICE_NAME ||
                    searchError ===
                      config.serviceSearchErrors.SLS_INVALID_SERVICE_NAME) && (
                    <>
                      We could not parse the service name in{" "}
                      <code>{slsYmlPath}</code>.
                    </>
                  )}
                  {searchError ===
                    config.serviceSearchErrors.SLS_JS_NOT_SUPPORTED && (
                    <>
                      We found a <code>serverless.js</code> in{" "}
                      <code>{slsYmlPath}</code> instead of a YAML file.
                    </>
                  )}
                  <br />
                  Add it by entering the name of the service. Or try a different
                  path.
                </p>
                <Form
                  inline
                  onSubmit={this.handleAddSubmit}
                  className={!noTypeOnError ? "name single" : "name"}
                >
                  {noTypeOnError && (
                    <FormGroup className="service-type" controlId="framework">
                      <StyledControlLabel>Service Type</StyledControlLabel>
                      <FormControl
                        bsSize="large"
                        componentClass="select"
                        value={this.state.framework || defaultFramework}
                        onChange={this.handleFieldEdit}
                      >
                        <option value="sst">SST</option>
                        <option value="sls">Serverless Framework</option>
                      </FormControl>
                    </FormGroup>
                  )}
                  <FormGroup
                    controlId="name"
                    validationState={this.state.validation}
                  >
                    <StyledControlLabel>Service Name</StyledControlLabel>
                    <FormControl
                      type="text"
                      bsSize="large"
                      disabled={adding}
                      value={this.state.name}
                      placeholder="my-service"
                      onChange={this.handleFieldEdit}
                    />
                  </FormGroup>
                </Form>
                {this.state.validation && (
                  <HelpBlock className="invalid-name">
                    Service names must start with a letter or number and can
                    only contain uppercase or lowercase letters, numbers, and
                    hyphens.
                  </HelpBlock>
                )}
              </div>
            </div>
            {newSearchButton}
          </>
        )}
        {(status === "searching" || status === "search") && (
          <Form inline className="search" onSubmit={this.handleSearchClick}>
            <FormControl
              id="path"
              type="text"
              value={path}
              bsSize="large"
              disabled={searching}
              onChange={this.handleFieldEdit}
              placeholder="/path/to/service"
            />
            <LoaderButton
              bsSize="large"
              loading={searching}
              disabled={path.trim() === ""}
              onClick={this.handleSearchClick}
            >
              Search
            </LoaderButton>
          </Form>
        )}
      </div>
    );
  }

  render() {
    const { show, adding, editing, searching, searchError } = this.props;

    const status = searching
      ? "searching"
      : editing
      ? "search"
      : searchError
      ? "not-found"
      : "detected";
    const disableAdd =
      status === "searching" ||
      status === "search" ||
      (status === "not-found" && !this.canAdd());

    return (
      <Modal
        show={show}
        className="AppAddServiceModal"
        onHide={this.props.onCloseClick}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderForm()}</Modal.Body>
        <Modal.Footer>
          <LoaderButton bsStyle="link" onClick={this.props.onCloseClick}>
            Cancel
          </LoaderButton>
          <LoaderButton
            bsSize="large"
            bsStyle="warning"
            loading={adding}
            disabled={disableAdd}
            onClick={this.handleAddClick}
          >
            Add Service
          </LoaderButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

AppAddServiceModal.defaultProps = defaultProps;

export default AppAddServiceModal;
