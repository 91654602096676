import React from "react";
import { Route, Redirect } from "react-router-dom";
import RenderContainerComponent from "./RenderContainerComponent";
import { querystring } from "../lib/urlLib";

export default ({ component: C, props: cProps, redirect, ...rest }) => {
  const qsRedirect = querystring("redirect");
  redirect =
    qsRedirect !== "" && qsRedirect !== null
      ? qsRedirect
      : redirect
      ? redirect
      : "/";

  return (
    <Route
      {...rest}
      render={(props) =>
        !cProps.isAuthenticated ? (
          <RenderContainerComponent component={C} {...props} {...cProps} />
        ) : (
          <Redirect to={redirect} />
        )
      }
    />
  );
};
