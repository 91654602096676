import React, { useEffect, useReducer } from "react";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import ErrorAlert from "../components/ErrorAlert";
import ScreenHeader from "../components/ScreenHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import EnableIssuesPanel from "../components/EnableIssuesPanel";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import IssuesNotificationSettings from "./widgets/IssuesNotificationSettings";
import IssuesStageSettingsPanel from "../components/IssuesStageSettingsPanel";
import { appSettingsBreadcrumb } from "../lib/breadcrumbLib";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import { errorHandler, getLoadError } from "../lib/errorLib";
import "./AppSettingIssues.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
};

const settingDefaultState = { key: 0, updated: false, updating: false };

function settingReducer(state, action) {
  switch (action.type) {
    case "update":
      return { ...state, updating: true };
    case "update-done":
      return { ...state, updating: false, updated: true, key: state.key + 1 };
    case "update-failed":
      return { ...state, updating: false, key: state.key + 1 };
    default:
      return state;
  }
}

function AppSettingIssues(props) {
  const { ownerId, appId } = props.match.params;

  let isLoading = true;
  let loadError = null;
  let errorMonitorStatus = null;

  const [enableState, enableDispath] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [defaultStageState, defaultStageDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );

  useEffect(() => {
    document.title = title("Issues");
  }, [appId]);

  //////////
  // Load //
  //////////

  const {
    data: [appInfo, statusInfo],
    error,
    reload: reloadInfo,
  } = useAPILoad(
    [getAppAPI(), `${getAppAPI()}/error_status`],
    [
      (path) => props.invokeAppsApig({ path }),
      (path) => props.invokeApig({ path }),
    ]
  );

  if (appInfo !== null && statusInfo !== null) {
    isLoading = false;
    errorMonitorStatus = appInfo.app.errorMonitorStatus;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  const loaded = !isLoading && !loadError && appInfo;

  /////////
  // API //
  /////////

  function getAppAPI() {
    return `/${ownerId}/${appId}`;
  }

  function updateAppInfo(data) {
    return props.invokeAppsApig({
      path: getAppAPI(),
      method: "PUT",
      body: data,
    });
  }

  /////////////////////////
  // API - Notifications //
  /////////////////////////

  function loadNotifications() {
    return props.invokeApig({
      path: `${getAppAPI()}/error_notifications?version=v20200626`,
    });
  }

  function addNotification(notification) {
    return props.invokeApig({
      path: `${getAppAPI()}/error_notifications?version=v20200626`,
      method: "POST",
      body: notification,
    });
  }

  function removeNotification(notificationId) {
    return props.invokeApig({
      path: `${getAppAPI()}/error_notifications?version=v20200626`,
      method: "DELETE",
      body: { notificationId },
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleToggle({ enabled, force, forceStageName }) {
    enableDispath({ type: "update" });

    try {
      await updateAppInfo({
        errorMonitorEnabled: enabled,
        errorMonitorForce: force,
        errorMonitorForceStageName: forceStageName,
      });
      await reloadInfo();
      enableDispath({ type: "update-done" });
    } catch (e) {
      enableDispath({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleDefaultStageUpdateClick(stageName) {
    defaultStageDispatch({ type: "update", stageName });
    try {
      await updateAppInfo({ errorDefaultStageName: stageName });
      await reloadInfo();
      defaultStageDispatch({ type: "update-done" });
    } catch (e) {
      defaultStageDispatch({ type: "update-done" });
      errorHandler(e);
    }
  }

  ////////////
  // Render //
  ////////////

  return (
    <div className="AppSettingIssues">
      <ScreenHeader border breadcrumb={appSettingsBreadcrumb(props)}>
        Issues
      </ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="app"
          code={loadError}
          context={{ name: appId }}
        />
      )}

      {error && !loadError && <ErrorAlert error={error} />}

      {loaded && (
        <>
          <EnableIssuesPanel
            stages={appInfo.stages}
            onSaveClick={handleToggle}
            status={errorMonitorStatus}
            saving={enableState.updating}
            enableErrors={statusInfo.statuses}
            resetKey={`EnableIssuesPanel-${enableState.key}`}
          />
          <hr />
          <IssuesNotificationSettings
            stages={appInfo.stages}
            onAdd={addNotification}
            onLoad={loadNotifications}
            onRemove={removeNotification}
            disabled={errorMonitorStatus !== "enabled"}
          />
          <hr />
          <IssuesStageSettingsPanel
            stages={appInfo.stages}
            updated={defaultStageState.updated}
            updating={defaultStageState.updating}
            disabled={errorMonitorStatus !== "enabled"}
            key={`defaultStage-${defaultStageState.key}`}
            onUpdateClick={handleDefaultStageUpdateClick}
            defaultStage={statusInfo.defaultStageName}
          />
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(AppSettingIssues));
