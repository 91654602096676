import React, { useState, useEffect, useReducer, useCallback } from "react";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import EnforceTwoFactorModal from "./components/EnforceTwoFactorModal";
import NetworkStatusAlert from "./components/NetworkStatusAlert";
import ErrorBoundary from "./components/ErrorBoundary";
import Footer from "./components/Footer";
import Routes from "./Routes";
import * as sentry from "./lib/sentryLib";
import { AppContext } from "./lib/hooksLib";
import { cacheClear } from "./lib/apiLoadLib";
import { invokeApig, getUserAttributes } from "./lib/awsLib";
import { isCognitoError, createCognitoError } from "./lib/errorLib";
import "./App.css";

const defaultAuthState = {
  userInfo: {},
  mfaMode: null,
  isAuthenticated: false,
  isAuthenticating: true,
  authenticatedUser: null,
  orgNamesEnforced2fa: [],
};

function authReducer(state, action) {
  switch (action.type) {
    case "authenticated":
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        authenticatedUser: action.user,
      };
    case "unauthenticated":
      return {
        ...defaultAuthState,
        isAuthenticating: false,
      };
    case "load-info":
      return {
        ...state,
        userInfo: action.userInfo,
        orgNamesEnforced2fa: action.orgNamesEnforced2fa,
      };
    case "load-mfa":
      return {
        ...state,
        mfaMode: action.mfaMode,
      };
    default:
      return state;
  }
}

export default function App(props) {
  const history = useHistory();
  const location = useLocation();

  const [org, setOrg] = useState(null);
  const [showNetworkAlertKey, setShowNetworkAlertKey] = useState(0);
  const [state, dispatch] = useReducer(authReducer, defaultAuthState);

  const setUserAsAuthenticated = useCallback((email) => {
    if (email !== null) {
      dispatch({ type: "authenticated", user: email });

      loadUserInfo();
      loadUserMfaMode();

      setSentryUserContext(email);
    } else {
      dispatch({ type: "unauthenticated" });
      unsetSentryUserContext();
    }
  }, []);

  const onNetworkError = useCallback(() => {
    // Use functional updates since this is used inside a context
    setShowNetworkAlertKey((showNetworkAlertKey) => showNetworkAlertKey + 1);
  }, []);

  const dismissNetworkAlert = useCallback(() => setShowNetworkAlertKey(0), []);

  useEffect(() => {
    async function onLoad() {
      try {
        // Getting the user attributes checks for the session using
        // Auth.currentAuthenticatedUser()
        // so we don't have to do it twice
        // We also load the email attribute from the server in case
        // the user has just changed his email address through the
        // settings page.
        const { email } = await getUserAttributes(["email"]);
        setUserAsAuthenticated(email);
      } catch (e) {
        dispatch({ type: "unauthenticated" });
        sentry.captureException(isCognitoError(e) ? createCognitoError(e) : e);
        //      TODO Don't throw any errors if fail to get session
        //      if (
        //        e.message !== errors.user_is_not_signed_in &&
        //        ! (
        //          e.code === 'NotAuthorizedException' &&
        //          e.message === 'Access Token has expired'
        //        )
        //      ) {
        //        errorHandler(e);
        //      }
      }
    }

    onLoad();
  }, [setUserAsAuthenticated]);

  useEffect(() => {
    if (state.isAuthenticated) {
      trackPageView(location.pathname);
    }
  }, [location.pathname, state.isAuthenticated]);

  const childProps = {
    setOrg,
    loadUserInfo,
    loadUserMfaMode,
    onLogout: handleLogout,
    userInfo: state.userInfo,
    isAuthenticated: state.isAuthenticated,
    isAuthenticating: state.isAuthenticating,
    authenticatedUser: state.authenticatedUser,
    setUserAsAuthenticated: setUserAsAuthenticated,
  };

  const needToEnable2fa =
    state.orgNamesEnforced2fa.includes(org) && state.mfaMode === "NOMFA";

  async function loadUserInfo() {
    try {
      const { user: userInfo, orgNamesEnforced2fa } = await invokeApig({
        path: "/user/info",
      });

      dispatch({ type: "load-info", userInfo, orgNamesEnforced2fa });
    } catch (e) {
      sentry.captureException(e);
    }
  }

  async function loadUserMfaMode() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const mfaMode = await Auth.getPreferredMFA(user, { bypassCache: true });
      dispatch({ type: "load-mfa", mfaMode });
    } catch (e) {
      sentry.captureException(isCognitoError(e) ? createCognitoError(e) : e);
    }
  }

  function setSentryUserContext(email) {
    sentry.setUser({ email });
  }

  function unsetSentryUserContext() {
    sentry.setUser({});
  }

  //////////////
  // Handlers //
  //////////////

  async function handleLogout(event) {
    // Clear API cache
    cacheClear();

    await Auth.signOut();

    setUserAsAuthenticated(null);

    history.push("/login");
  }

  /////////
  // API //
  /////////

  async function trackPageView(path) {
    try {
      await invokeApig({
        method: "POST",
        body: { path },
        path: "/view_page",
      });
    } catch (e) {
      // Ignore any errors
    }
  }

  return (
    <div className="App">
      <div className="container-fluid">
        {!state.isAuthenticating && (
          <ErrorBoundary>
            <AppContext.Provider value={{ onNetworkError }}>
              <Routes childProps={childProps} />
            </AppContext.Provider>
          </ErrorBoundary>
        )}
        <NetworkStatusAlert
          show={showNetworkAlertKey > 0}
          onDismiss={dismissNetworkAlert}
        />
        <EnforceTwoFactorModal
          org={org}
          show={needToEnable2fa}
          onCloseClick={() => history.push("/settings")}
        />
      </div>
      <Footer />
    </div>
  );
}
