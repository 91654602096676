import React from "react";
import { Link } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import { getOrgBillingUrl } from "../lib/urlLib";
import "./EnforceTwoFactorSettingPanel.css";

const helpUrl = "https://seed.run/docs/enabling-mandatory-two-factor-auth";

export default function EnforceTwoFactorSettingPanel({
  owner,
  isEnabled,
  onEnableClick,
  onDisableClick,
  updating = false,
  isFeatureUnavailable,
}) {
  return (
    <div className="EnforceTwoFactorSettingPanel">
      <SectionHeader>Mandatory Two-Factor Auth</SectionHeader>
      <p className="info">
        <>
          {isEnabled
            ? "Disable mandatory two-factor authentication for all team members."
            : "Enable this if you want all team members to use two-factor authentication."}
        </>
        &nbsp;
        <a target="_blank" href={helpUrl} rel="noopener noreferrer">
          Learn more.
        </a>
      </p>
      {isFeatureUnavailable && (
        <p className="unavailable">
          Please{" "}
          <Link to={getOrgBillingUrl(owner)}>
            upgrade to the Enterprise plan
          </Link>{" "}
          to make two-factor auth mandatory for your team.
        </p>
      )}
      <LoaderButton
        bsSize="large"
        loading={updating}
        disabled={isFeatureUnavailable}
        onClick={isEnabled ? onDisableClick : onEnableClick}
      >
        {isEnabled ? "Disable Mandatory 2FA" : "Enable Mandatory 2FA"}
      </LoaderButton>
    </div>
  );
}
