import React from "react";
import "./LoaderToggle.css";

export default function LoaderToggle({
  id,
  onChange,
  checked = false,
  loading = false,
  disabled = false,
  ...props
}) {
  const loadingCs = loading ? "loading" : "";
  const disabledCs = disabled ? "disabled" : "";

  return (
    <span className={`LoaderToggle ${loadingCs} ${disabledCs}`}>
      <label>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled || loading}
        />
        <span className="slider"></span>
        {loading && (
          <span className="loading">
            <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
          </span>
        )}
      </label>
      <span className="copy">{props.children}</span>
    </span>
  );
}
