import React, {
  useRef,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import {
  Form,
  MenuItem,
  FormGroup,
  FormControl,
  DropdownButton,
} from "react-bootstrap";
import { useFormReducer } from "../lib/hooksLib";
import { truncate } from "../lib/stringLib";
import DropdownSearchItem from "./DropdownSearchItem";
import LoaderButton from "./LoaderButton";
import TextButton from "./TextButton";
import CaretIcon from "./CaretIcon";
import FaIcon from "./FaIcon";
import config from "../config";
import "./GitRepoSelect.css";

const noop = () => {};

const helpUrl = "https://seed.run/docs/cannot-find-my-repo";

const defaultFields = {
  search: "",
};

function find(items, text) {
  text = text.split(" ");

  return items.filter(({ git_path }) => {
    const gitPathNormalised = git_path.toLowerCase();
    return text.every(
      (part) => gitPathNormalised.indexOf(part.toLowerCase()) > -1
    );
  });
}

export default function GitRepoSelect({
  orgs = null,
  repos = null,
  value = null,
  onChange = noop,
  hasMore = false,
  disabled = false,
  readOnly = false,
  currentOrg = null,
  onOrgChange = noop,
  isLoadingMore = false,
  onLoadMoreClick = noop,
}) {
  const searchEl = useRef(null);
  const cursorEl = useRef(null);
  let [open, setOpen] = useState(true);
  const [cursor, _setCursor] = useState({ index: -1, focus: false });
  const [formData, formDispatch] = useFormReducer(defaultFields);
  const filteredRepos = useMemo(
    () =>
      formData.values.search === ""
        ? repos
        : find(repos, formData.values.search),
    [formData.values.search, repos]
  );
  const resetCursor = useCallback(() => setCursor(-1), []);

  useEffect(() => {
    resetCursor();
  }, [resetCursor, formData.values.search]);

  useLayoutEffect(() => {
    if (cursorEl.current) {
      cursorEl.current.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "start",
      });
    }
  }, []);

  if (readOnly || repos === null || disabled) {
    open = false;
  }

  function setCursor(index, focus = false) {
    _setCursor({ index, focus });
  }

  function isFocusRef(i) {
    return i === cursor.index && cursor.focus;
  }

  function isCurrentCs(repo) {
    return value && value.git_path === repo.git_path ? "current" : "";
  }

  function isOnCursorCs(i) {
    return i === cursor.index ? "cursor" : "";
  }

  function clearFormData() {
    resetCursor();

    formDispatch({
      value: "",
      id: "search",
      type: "edit",
    });
  }

  function handleFieldChange(event) {
    const { id, value } = event.target;

    formDispatch({
      id,
      value,
      type: "edit",
    });
  }

  function handleFieldKeyDown(e) {
    const keyCode = e.keyCode;
    const count = filteredRepos.length;

    if (keyCode === 38 || keyCode === 40) {
      e.preventDefault();
    }

    if (keyCode === 38 && cursor.index > 0) {
      setCursor(cursor.index - 1, true);
    } else if (keyCode === 40 && cursor.index < count - 1) {
      setCursor(cursor.index + 1, true);
    }
  }

  function handleSearch(e) {
    e.preventDefault();

    // Select item on enter key
    if (cursor.index !== -1 && cursor.focus) {
      handleChange(filteredRepos[cursor.index]);
    }
  }

  function handleClick() {
    if (readOnly) {
      return;
    }

    clearFormData();
    setOpen(!open);
  }

  function handleChange(value) {
    clearFormData();
    setOpen(false);

    onChange({ target: { id: "repo", value } });
  }

  function handleClearClick() {
    clearFormData();
    searchEl.current.focus();
  }

  function handleOrgChange(orgId) {
    // If new selection is the same as the currently displayed
    if (orgId === currentOrg.installationId) {
      return;
    }

    if (orgId === "add") {
      window.open(config.github.install_url);
      return;
    }

    onOrgChange(orgId);
  }

  function renderOrgsDropdown() {
    return (
      <>
        <span className="dropdown-copy">Displaying repos in </span>
        <DropdownButton
          bsStyle="link"
          id="orgs-dropdown"
          onSelect={handleOrgChange}
          title={truncate(currentOrg.accountName, 24)}
        >
          <MenuItem className="add" eventKey="add">
            Add an organization&hellip;
          </MenuItem>
          <MenuItem divider />
          <MenuItem header>Organizations</MenuItem>
          {orgs.map((org) => (
            <MenuItem
              key={org.installationId}
              eventKey={org.installationId}
              active={org.installationId === currentOrg.installationId}
            >
              {org.accountName}
            </MenuItem>
          ))}
        </DropdownButton>
      </>
    );
  }

  return (
    <div className="GitRepoSelect">
      {repos === null && (
        <LoaderButton loading bsSize="large" className="loading">
          <span className="text">Loading repos</span>
        </LoaderButton>
      )}
      {repos && repos.length === 0 && (
        <LoaderButton disabled bsSize="large">
          <span className="text">No repos found</span>
        </LoaderButton>
      )}
      {repos && repos.length !== 0 && (
        <>
          <LoaderButton
            bsSize="large"
            active={open}
            disabled={disabled}
            onClick={handleClick}
            className={readOnly ? "read-only" : ""}
          >
            {value && <span className="text">{value.git_path}</span>}
            {!value && <span className="text">Select a repo</span>}
            <CaretIcon direction={open ? "up" : "down"} />
          </LoaderButton>
          {open && (
            <div className="list-container">
              <Form onSubmit={handleSearch}>
                <FormGroup bsSize="large" controlId="search">
                  <label htmlFor="search">
                    <FaIcon name="search" />
                  </label>
                  <FormControl
                    autoFocus
                    type="text"
                    autoComplete="off"
                    onChange={handleFieldChange}
                    value={formData.values.search}
                    onKeyDown={handleFieldKeyDown}
                    inputRef={(el) => {
                      searchEl.current = el;
                    }}
                  />
                  {formData.values.search !== "" && (
                    <TextButton onClick={handleClearClick}>
                      <FaIcon name="times-circle" />
                    </TextButton>
                  )}
                </FormGroup>
              </Form>
              <div className="list">
                {filteredRepos.length > 0 &&
                  filteredRepos.map((repo, i) => (
                    <DropdownSearchItem
                      key={repo.git_path}
                      onHover={() => setCursor(i)}
                      onClick={(e) => handleChange(repo)}
                      elRef={(el) => {
                        isFocusRef(i) && (cursorEl.current = el);
                      }}
                      className={`item ${isOnCursorCs(i)}  ${isCurrentCs(
                        repo
                      )}`}
                    >
                      {repo.git_path}
                    </DropdownSearchItem>
                  ))}
                {filteredRepos.length === 0 && (
                  <span className="empty">No matching repos found</span>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {(open || (repos && repos.length === 0)) && (
        <div className="controls">
          {orgs && (
            <>
              {renderOrgsDropdown()}
              <span className="separator">&bull;</span>
            </>
          )}
          {hasMore && open && (
            <>
              <LoaderButton
                bsStyle="link"
                loading={isLoadingMore}
                disabled={isLoadingMore}
                onClick={onLoadMoreClick}
              >
                Searching {repos.length} repos, load more
              </LoaderButton>
              <span className="separator">&bull;</span>
            </>
          )}
          <a
            target="_blank"
            href={orgs ? currentOrg.installationUrl : helpUrl}
            className="help-link"
            rel="noopener noreferrer"
          >
            Cannot find your repo?
          </a>
        </div>
      )}
    </div>
  );
}
