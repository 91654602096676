import React, { Component } from "react";
import * as sentry from "../lib/sentryLib";
import ErrorComponent from "./ErrorComponent";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    sentry.captureException(error, (scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
  }

  render() {
    return this.state.error ? <ErrorComponent /> : this.props.children;
  }
}
