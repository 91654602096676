import React from "react";
import "./BuildInfoPanel.css";

const changeSlsVersionUrl =
  "https://seed.run/docs/pinning-the-serverless-version";

function isCompletedSkipped(deployment) {
  return (
    deployment.successContext &&
    deployment.successContext.successType === "git_diff_deploy_skip"
  );
}

function deploymentStatusStateToText(statusState, completeSkipped) {
  const textArray = {
    skipped: "Skipped",
    completed: "Completed",
    in_progress: "In progress",
    failed: "Failed",
  };

  return completeSkipped ? "No changes to deploy" : textArray[statusState];
}
function deploymentStatusStateToIcon(statusState) {
  const iconArray = {
    skipped: "minus-circle",
    completed: "check-circle",
    in_progress: "cog fa-spin",
    failed: "times-circle",
  };

  return iconArray[statusState];
}

function deploymentStatusStateToClassName(statusState, completeSkipped) {
  return completeSkipped ? "completed_skipped" : statusState;
}

function stageStatusStateToText(status) {
  const textArray = {
    remove_pending: "Pending",
    remove: "In progress",
    remove_failed: "Failed",
  };

  return textArray[status];
}
function stageStatusStateToIcon(status) {
  const iconArray = {
    remove_pending: "cog fa-spin",
    remove: "cog fa-spin",
    remove_failed: "times-circle",
  };

  return iconArray[status];
}
function stageStatusStateToClassName(status) {
  return status === "remove_failed" ? "failed" : status;
}

function activityStatusStateToIcon(activity) {
  const step = activity.progress[activity.progress.length - 1][0];
  if (step.status) {
    const iconArray = {
      queued: "cog fa-spin",
      success: "check-circle",
      failure: "times-circle",
      skipped: "minus-circle",
      deploying: "cog fa-spin",
    };
    return iconArray[step.status];
  } else {
    const iconArray = {
      deploying: "cog fa-spin",
      queued: "cog fa-spin",
    };
    return iconArray[activity.status] || "minus-circle";
  }
}
function activityStatusStateToText(activity) {
  const step = activity.progress[activity.progress.length - 1][0];
  const textArray = {
    queued: "Queued",
    failure: "Failed",
    skipped: "Skipped",
    success: "Completed",
    deploying: "In progress",
  };

  return textArray[step.status];
}
function activityStatusStateToClassName(activity) {
  const step = activity.progress[activity.progress.length - 1][0];
  return step.status === "failure" ? "failed" : step.status;
}

function artifactStatusToText(status) {
  const textArray = {
    building: "In progress",
    success: "Completed",
    failure: "Failed",
  };

  return textArray[status];
}
function artifactStatusToIcon(status) {
  const iconArray = {
    success: "check-circle",
    failure: "times-circle",
    building: "cog fa-spin",
  };

  return iconArray[status];
}
function artifactStatusToClassName(status) {
  return status === "failure" ? "failed" : status;
}

export default ({ activity, artifacts, deployment, stage }) => {
  let errorMessage,
    skippedMessage,
    slsVersion,
    statusText,
    className,
    icon,
    completeSkipped;
  if (deployment) {
    completeSkipped = isCompletedSkipped(deployment);
    errorMessage =
      deployment.message ||
      (deployment.skippedDueToMinutes &&
        "Build minutes limit exceeded. Please upgrade your account.");
    skippedMessage = deployment.skippedMessage;
    slsVersion = deployment.sls_version;
    statusText = deploymentStatusStateToText(
      deployment.status,
      completeSkipped
    );
    className = deploymentStatusStateToClassName(
      deployment.status,
      completeSkipped
    );
    icon = deploymentStatusStateToIcon(deployment.status);
  } else if (stage) {
    errorMessage = stage.removeErrorMessage;
    statusText = stageStatusStateToText(stage.current_action);
    className = stageStatusStateToClassName(stage.current_action);
    icon = stageStatusStateToIcon(stage.current_action);
  } else if (artifacts) {
    statusText = artifactStatusToText(artifacts.status);
    errorMessage = artifacts.errorMessage;
    className = artifactStatusToClassName(artifacts.status);
    icon = artifactStatusToIcon(artifacts.status);
  } else {
    errorMessage =
      activity.progress[activity.progress.length - 1][0].error_message;
    statusText = activityStatusStateToText(activity);
    className = activityStatusStateToClassName(activity);
    icon = activityStatusStateToIcon(activity);
  }

  return (
    <div className={`BuildInfoPanel ${className}`}>
      <div className="icon">
        <i className={`fa fa-${icon}`}></i>
      </div>
      <div className="content">
        <p className="status">{statusText} </p>

        {slsVersion && (
          <div className="sls-version">
            <p>Using Serverless Framework v{slsVersion}</p>
            <a
              target="_blank"
              href={changeSlsVersionUrl}
              rel="noopener noreferrer"
            >
              Learn about how to use a different Serverless Framework version.
            </a>
          </div>
        )}
        {errorMessage && <p className="message">{errorMessage}</p>}
        {skippedMessage && <p className="message">{skippedMessage}</p>}
      </div>
    </div>
  );
};
