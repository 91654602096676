import React from "react";
import { Link, useParams } from "react-router-dom";
import FaIcon from "./FaIcon";
import RightChevron from "./RightChevron";
import { dateToSinceTimeIssuesDashboard } from "../lib/timeLib";
import {
  getAppStageUrl,
  getAppStageIssueUrl,
  getAppStageIssuesUrl,
  getAppIssuesSettingsUrl,
} from "../lib/urlLib";
import "./DashboardIssuesPanel.css";

export default function DashboardIssuesPanel({
  issues = [],
  stageName = false,
  isDeployed = false,
  isIssuesDisabled = false,
}) {
  const { ownerId, appId } = useParams();

  function renderDisabled() {
    return (
      <div className="empty">
        <p>Issues has not been enabled.</p>
        <p>
          <Link to={getAppIssuesSettingsUrl(ownerId, appId)}>
            Enable it in the settings
            <RightChevron />
          </Link>
        </p>
      </div>
    );
  }

  function renderNotDeployed() {
    return (
      <div className="empty">
        Make your first deployment to{" "}
        <Link to={getAppStageUrl(ownerId, appId, stageName)}>
          <b>{stageName}</b>
        </Link>{" "}
        to view Lambda errors.
      </div>
    );
  }

  function renderEmpty() {
    return (
      <div className="empty">
        <FaIcon name="thumbs-o-up" />
        There are no new issues!
      </div>
    );
  }

  function renderIssue({
    stageId,
    errorGroupShortId,
    errorType,
    errorMessage,
    lastErrorShortId,
    firstErrorAt,
  }) {
    const url = getAppStageIssueUrl(
      ownerId,
      appId,
      stageId,
      errorGroupShortId,
      lastErrorShortId
    );

    return (
      <div key={errorGroupShortId} className="issue">
        <div className="col1">
          <Link to={url}>{errorType}</Link>
          <p>{errorMessage}</p>
        </div>
        <Link to={url}>
          <span className="count">
            {dateToSinceTimeIssuesDashboard(firstErrorAt)} ago
          </span>
        </Link>
      </div>
    );
  }

  return (
    <div className="DashboardIssuesPanel">
      <div className="title">
        <span>
          New Issues<span>Last 24 hrs</span>
        </span>
        <Link to={getAppStageIssuesUrl(ownerId, appId)}>
          View All <RightChevron />
        </Link>
      </div>
      <div className="list">
        {isIssuesDisabled && renderDisabled()}
        {!isIssuesDisabled && !isDeployed && renderNotDeployed()}
        {!isIssuesDisabled && isDeployed && (
          <>
            {issues.map(renderIssue)}
            {issues.length === 0 && renderEmpty()}
          </>
        )}
      </div>
    </div>
  );
}
