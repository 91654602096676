import React, { useState } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import SectionInfo from "./SectionInfo";
import ServiceIcon from "./ServiceIcon";
import TextButton from "./TextButton";
import { testServiceName } from "../lib/regexLib";
import { useFormReducer } from "../lib/hooksLib";
import "./ServiceNameForm.css";

export default function ServiceNameForm({
  name,
  type,
  onSaveClick,
  saving = false,
  updated = false,
}) {
  const [showStatus, setShowStatus] = useState(updated);
  const [
    { values: formData, isDirty, validation },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ name });

  const editing = isDirty.name;

  const displayCs = editing ? "" : "display";

  function canSubmit() {
    return isDirty.name === true && formData.name.trim() !== "";
  }

  function handleCancelClick() {
    setShowStatus(false);
    formDispatch({ type: "reset" });
  }

  function handleSaveClick(event) {
    event.preventDefault();

    if (!canSubmit()) {
      return;
    }

    const name = formData.name;

    if (!testServiceName(name)) {
      formDispatch({
        id: "name",
        type: "validate",
      });
      return;
    }

    onSaveClick(name);
  }

  function renderForm() {
    return (
      <form className={displayCs} onSubmit={handleSaveClick}>
        <FormGroup controlId="name" validationState={validation.name}>
          <FormControl
            type="text"
            bsSize="large"
            disabled={saving}
            value={formData.name}
            onChange={handleFieldChange}
          />
          {editing && (
            <>
              {!validation.name && (
                <HelpBlock>
                  Service names need to be unique within an app.
                </HelpBlock>
              )}
              {validation.name && (
                <HelpBlock>
                  Service names must start with a letter or number and can only
                  contain uppercase or lowercase letters, numbers, and hyphens.
                </HelpBlock>
              )}
            </>
          )}
        </FormGroup>
        {!editing && showStatus && <p className="status">Service updated!</p>}
        {editing && (
          <div className="controls">
            <LoaderButton
              type="submit"
              bsSize="large"
              loading={saving}
              disabled={!canSubmit()}
              onClick={handleSaveClick}
            >
              Update
            </LoaderButton>
            <TextButton onClick={handleCancelClick}>Cancel</TextButton>
          </div>
        )}
      </form>
    );
  }

  return (
    <div className="ServiceNameForm">
      <SectionInfo label="Service">
        <div className="form-container">
          <ServiceIcon type={type} size="large" />
          {renderForm()}
        </div>
      </SectionInfo>
    </div>
  );
}
