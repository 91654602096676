import React from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { MenuItem, Glyphicon } from "react-bootstrap";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import RightChevron from "./RightChevron";
import PipelineDropdownButton from "./PipelineDropdownButton";
import PipelineTableStageStatusCell from "./PipelineTableStageStatusCell";
import {
  getAppStageUrl,
  getAppActivityUrl,
  getAppStageRemoveUrl,
} from "../lib/urlLib";
import "./MiniPipelineStageCell.css";

export default function MiniPipelineStageCell({
  status,
  appStage,
  onPromote,
  pathParams,
  onManualDeploy,
  onCancelDeploy,
  downstreamStages,
  cancelling = false,
  emptyPipeline = false,
}) {
  const appStageId = appStage.name;
  const { ownerId, appId } = pathParams;

  const activity = appStage.latestBuild;

  const isProd = appStage.is_production;
  const isStaging = appStage.is_staging;
  const prodCs = isProd ? "prod" : "";

  const resourcesLink = getAppStageUrl(ownerId, appId, appStageId);
  const appStageRemoveLink = getAppStageRemoveUrl(ownerId, appId, appStageId);
  const appStageLink = getAppActivityUrl(ownerId, appId, { stage: appStageId });

  const activityStatus = activity && activity.status;
  const isDeleting = status === "deleting";
  const isDeleteFailed = status === "delete_failed";

  const statusCs = status ? status : "";

  const emptyCs = emptyPipeline ? "empty" : "";

  const className = `MiniPipelineStageCell ${statusCs} ${prodCs} ${emptyCs}`;

  return (
    <div className={className}>
      {!isDeleting && !isDeleteFailed && (
        <>
          <div className="title">
            <Link className="name" to={appStageLink} title={appStageId}>
              {appStageId}
            </Link>
            <PipelineDropdownButton
              noCaret
              pullRight
              id={`build-dropdown-${appStageId}`}
              title={<FaIcon name="chevron-down" />}
            >
              {activityStatus === "deploying" || activityStatus === "queued" ? (
                <MenuItem
                  eventKey="1"
                  className="cancel"
                  disabled={cancelling}
                  onClick={onCancelDeploy}
                >
                  {cancelling && <Glyphicon glyph="refresh" />}
                  Cancel
                </MenuItem>
              ) : (
                <MenuItem eventKey="1" onClick={onManualDeploy}>
                  Deploy
                </MenuItem>
              )}
              {downstreamStages.length > 1 && <MenuItem divider />}
              {!isProd &&
                downstreamStages.map((stage) => (
                  <MenuItem
                    key={stage.name}
                    eventKey={stage.name}
                    disabled={activity === null}
                    onSelect={() => onPromote(appStage, stage)}
                  >
                    Promote to {stage.name}
                  </MenuItem>
                ))}
              <MenuItem divider />
              <LinkContainer to={resourcesLink}>
                <MenuItem eventKey="3">
                  View Resources <RightChevron />
                </MenuItem>
              </LinkContainer>
            </PipelineDropdownButton>
          </div>
          {!emptyPipeline && (
            <PipelineTableStageStatusCell
              mini
              appSlug={appId}
              ownerName={ownerId}
              activity={activity}
            />
          )}
          {emptyPipeline && (
            <div className="empty">
              {appStage.branch && (
                <p className="linked">
                  <span>Git push to&nbsp;</span>
                  <a
                    target="_blank"
                    className="branch"
                    rel="noopener noreferrer"
                    href={appStage.branch_url}
                  >
                    <FaIcon name="code-fork" />
                    {appStage.branch}
                  </a>
                </p>
              )}
              {!appStage.branch && (
                <p>
                  {isProd || isStaging
                    ? "Promote a build"
                    : "Enable auto-deploy"}
                </p>
              )}
              <div className="separator">
                <span>or</span>
              </div>
              <LoaderButton
                bsSize="small"
                bsStyle="block"
                onClick={onManualDeploy}
              >
                Trigger Deployment
              </LoaderButton>
            </div>
          )}
        </>
      )}
      {isDeleting && (
        <>
          <div className="title">
            <Link className="name" to={appStageRemoveLink} title={appStageId}>
              <Glyphicon className="icon" glyph="refresh" />
              {appStageId}
            </Link>
          </div>
          <PipelineTableStageStatusCell mini />
        </>
      )}
      {isDeleteFailed && (
        <>
          <div className="title">
            <Link
              title={appStageId}
              className="btn-red-link"
              to={appStageRemoveLink}
            >
              <FaIcon name="exclamation-triangle" />
              {appStageId}
            </Link>
          </div>
          <PipelineTableStageStatusCell mini />
        </>
      )}
    </div>
  );
}
