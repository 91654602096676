import ReactDOM from "react-dom";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import FaIcon from "./FaIcon";
import { sortStages } from "../lib/stagesLib";
import { useBlurDropdown } from "../lib/hooksLib";
import "./StageSelectDropdown.css";

export default function StageSelectDropdown({
  getUrl,
  selected,
  stages = null,
}) {
  let displayedStages = [];

  const isLoading = !stages;

  const selectEl = useRef(null);
  const buttonEl = useRef(null);

  const [open, setOpen] = useState(false);

  useBlurDropdown({
    toggleEl: () => ReactDOM.findDOMNode(buttonEl.current),
    selectEl: () => selectEl.current,
    onClick: () => setOpen(false),
  });

  if (!isLoading) {
    displayedStages = sortStages(
      stages.filter(({ name }) => name !== selected),
      { filterDeleted: true }
    );
  }

  const loadingCs = isLoading || displayedStages.length === 0 ? "loading" : "";

  return (
    <div className={`StageSelectDropdown ${loadingCs}`}>
      <div
        className="selected"
        onClick={() => setOpen(!open)}
        ref={(el) => (buttonEl.current = el)}
      >
        <p>{selected}</p>
        <FaIcon name={open ? "chevron-up" : "chevron-down"} />
      </div>
      {displayedStages.length > 0 && open && (
        <ul className="stages" ref={(el) => (selectEl.current = el)}>
          {displayedStages.map(({ name }) => (
            <li key={name}>
              <Link to={getUrl(name)}>{name}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
