import React, { useEffect, useState } from "react";
import { Checkbox } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import LoadingSpinner from "./LoadingSpinner";
import { MODES } from "./IssuesFilterSelect";
import IssuesBarGraph from "./IssuesBarGraph";
import IssueStatusControl from "../components/IssueStatusControl";
import { getAppStageIssueUrl } from "../lib/urlLib";
import { formatLargeNumbers } from "../lib/stringLib";
import { dateToSinceTimeIssues } from "../lib/timeLib";
import "./IssuesList.css";

const buttonCopyMap = {
  next: "Older",
  prev: "Newer",
};
const iconCopyMap = {
  next: "angle-right",
  prev: "angle-left",
};

export default function IssuesList({
  mode,
  issues = [],
  emptyCopy,
  isDeployed,
  settingsLink,
  nextPageLink,
  prevPageLink,
  loading = false,
  featureDisabled = false,

  // mute/ignore props
  muting = false,
  resolving = false,

  onMuteUpdate,
  onResolveUpdate,
}) {
  const { ownerId, appId, appStageId } = useParams();
  const [selected, setSelected] = useState({});

  const hasSelected = Object.keys(selected).length > 0;
  const selectedCs = hasSelected ? "" : "not-selected";

  // Reset selected on mode change
  useEffect(() => {
    setSelected({});
  }, [mode]);

  // Reset selected on finish muting/resolving
  useEffect(() => {
    if (muting || resolving) {
      return;
    }
    setSelected({});
  }, [muting, resolving]);

  function handleSelectToggled(event, errorGroupShortId) {
    const { checked } = event.target;
    if (checked) {
      selected[errorGroupShortId] = true;
    } else {
      delete selected[errorGroupShortId];
    }
    setSelected({ ...selected });
  }

  function handleSelectClickToggled(errorGroupShortId) {
    if (selected[errorGroupShortId]) {
      delete selected[errorGroupShortId];
    } else {
      selected[errorGroupShortId] = true;
    }

    setSelected({ ...selected });
  }

  function handleSelectAllToggled(event) {
    const { checked } = event.target;
    if (checked) {
      issues.forEach((issue) => {
        selected[issue.errorGroupShortId] = true;
      });
    } else {
      issues.forEach((issue) => delete selected[issue.errorGroupShortId]);
    }
    setSelected({ ...selected });
  }

  function handleMuteUpdate(event) {
    if (issues.length === 0) {
      return;
    }

    const errorGroupShortIds = Object.keys(selected);

    if (errorGroupShortIds.length === 0) {
      return;
    } else if (errorGroupShortIds.length === 1) {
    } else if (
      !window.confirm(
        "Are you sure you want to update multiple issues at once?"
      )
    ) {
      return;
    }

    const stageId = issues[0].stageId;
    const muted = mode !== MODES.MUTED;
    onMuteUpdate(stageId, errorGroupShortIds, muted);
  }

  function handleResolveUpdate(event) {
    if (issues.length === 0) {
      return;
    }

    const errorGroupShortIds = Object.keys(selected);
    if (errorGroupShortIds.length === 0) {
      return;
    } else if (errorGroupShortIds.length === 1) {
    } else if (
      !window.confirm(
        "Are you sure you want to update multiple issues at once?"
      )
    ) {
      return;
    }

    const stageId = issues[0].stageId;
    const resolved = mode !== MODES.RESOLVED;
    onResolveUpdate(stageId, errorGroupShortIds, resolved);
  }

  function renderEmpty() {
    return (
      <div className="empty">
        <p>{emptyCopy}</p>
      </div>
    );
  }

  function renderNotDeployed() {
    return (
      <div className="empty">
        <p>Trigger a deployment to view Lambda errors!</p>
      </div>
    );
  }

  function renderNotEnabled() {
    return (
      <div className="empty">
        <p>
          Issues has not been enabled for this app.&nbsp;
          <Link to={settingsLink}>Head over to the settings</Link> to enable it.
        </p>
      </div>
    );
  }

  function renderSelect(errorGroupShortId) {
    return (
      <Checkbox
        disabled={muting || resolving || issues.length === 0}
        checked={selected[errorGroupShortId] || false}
        onChange={(e) => handleSelectToggled(e, errorGroupShortId)}
      />
    );
  }

  function renderSelectAll() {
    return (
      <Checkbox
        disabled={muting || resolving || issues.length === 0}
        checked={
          issues.length > 0 &&
          issues.every((issue) => selected[issue.errorGroupShortId])
        }
        onChange={handleSelectAllToggled}
      />
    );
  }

  function renderLink(type, link) {
    const icon = iconCopyMap[type];
    const copy = buttonCopyMap[type];

    return link ? (
      // Since `exact` still matches if the querystring is different
      // use isActive to always set false
      <LinkContainer to={link} isActive={() => false}>
        <LoaderButton>
          {type === "prev" && <FaIcon name={icon} />}
          {copy}
          {type === "next" && <FaIcon name={icon} />}
        </LoaderButton>
      </LinkContainer>
    ) : (
      <LoaderButton disabled>
        {type === "prev" && <FaIcon name={icon} />}
        {copy}
        {type === "next" && <FaIcon name={icon} />}
      </LoaderButton>
    );
  }

  function renderError(issue) {
    const {
      status,
      errorType,
      lambdaNames,
      errorMessage,
      lastLambdaName,
      lastErrorShortId,
      errorGroupShortId,
    } = issue;

    const lambdasCopy = lambdaNames.length === 2 ? "other" : "others";
    const functionsCopy = lambdaNames.length === 1 ? "Lambda" : "Functions";

    return (
      <>
        <p className="name">
          {status === MODES.RESOLVED && <FaIcon name="check" />}
          {status === MODES.MUTED && <FaIcon name="bell-slash-o" />}
          <Link
            to={getAppStageIssueUrl(
              ownerId,
              appId,
              appStageId,
              errorGroupShortId,
              lastErrorShortId
            )}
          >
            {errorType}
          </Link>
        </p>
        <p className="message">{errorMessage}</p>
        <p className="lambda">
          <span>{functionsCopy}: </span>
          <span>{lastLambdaName}</span>&nbsp;
          {lambdaNames.length > 1 && (
            <span>
              and {lambdaNames.length - 1} {lambdasCopy}
            </span>
          )}
        </p>
      </>
    );
  }

  function renderHistory(trend) {
    return <IssuesBarGraph duration="hour" data={trend} />;
  }

  function renderCount(count) {
    const formatted = formatLargeNumbers(count);

    return (
      <span title={count !== formatted ? count : undefined}>{formatted}</span>
    );
  }

  function renderTime(issue) {
    return (
      <>
        <span>{dateToSinceTimeIssues(issue.lastErrorAt)} ago</span>
        <span className="separator"> &mdash; </span>
        <span>{dateToSinceTimeIssues(issue.firstErrorAt)} old</span>
      </>
    );
  }

  function renderIssues() {
    return issues.map((issue) => (
      <div
        className="item"
        key={issue.errorGroupShortId}
        onClick={() => handleSelectClickToggled(issue.errorGroupShortId)}
      >
        <div className="select">{renderSelect(issue.errorGroupShortId)}</div>
        <div className="error">{renderError(issue)}</div>
        <div className="history">{renderHistory(issue["24hTrend"])}</div>
        <div className="count">{renderCount(issue.lastErrorShortId)}</div>
        <div className="time">{renderTime(issue)}</div>
      </div>
    ));
  }

  return (
    <div className="IssuesList">
      <div className="flex-table">
        <div className="header">
          <div className="issue-select">{renderSelectAll()}</div>
          <div className={`issue-controls ${selectedCs}`}>
            <SectionHeader>Error</SectionHeader>
            <IssueStatusControl
              muted={mode === MODES.MUTED}
              resolved={mode === MODES.RESOLVED}
              muting={muting}
              resolving={resolving}
              disabled={muting || resolving || !hasSelected}
              onMuteUpdate={handleMuteUpdate}
              onResolveUpdate={handleResolveUpdate}
            />
          </div>
          <SectionHeader>Last 24hrs</SectionHeader>
          <SectionHeader>Events</SectionHeader>
          <SectionHeader>Time</SectionHeader>
        </div>
        {!loading && !featureDisabled && isDeployed && (
          <div className="body">{renderIssues()}</div>
        )}
      </div>

      {!loading && featureDisabled && renderNotEnabled()}
      {!loading && !featureDisabled && !isDeployed && renderNotDeployed()}
      {!loading &&
        !featureDisabled &&
        isDeployed &&
        issues.length === 0 &&
        renderEmpty()}

      {loading && (
        <div className="loading">
          <LoadingSpinner />
        </div>
      )}

      {(nextPageLink || prevPageLink) && (
        <div className="footer">
          {renderLink("prev", prevPageLink)}
          {renderLink("next", nextPageLink)}
        </div>
      )}
    </div>
  );
}
