import React, { useState, useEffect, useReducer } from "react";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import ErrorAlert from "../components/ErrorAlert";
import ScreenHeader from "../components/ScreenHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import StagesPostDeployPanel from "../components/StagesPostDeployPanel";
import AppSettingDeployWorkflow from "./widgets/AppSettingDeployWorkflow";
import { appPipelineBreadcrumb } from "../lib/breadcrumbLib";
import { errorHandler } from "../lib/errorLib";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import "./AppSettingDeployPhases.css";

const helpUrl = "https://seed.run/docs/configuring-deploy-phases";
const postHelpUrl = "https://seed.run/docs/adding-a-post-deploy-phase";

const modalDefaultState = {
  key: 0,
  loading: null,
  editing: null,
  updated: null,
  enabling: null,
  disabling: null,
};

function modalReducer(state, action) {
  switch (action.type) {
    case "load":
      return { ...state, loading: action.stage };
    case "load-done":
      return { ...state, loading: null };
    case "load-failed":
      return { ...state, loading: null, key: state.key + 1 };
    case "edit":
      return { ...state, editing: action.editObject };
    case "edit-done":
      return { ...state, editing: null, key: state.key + 1 };
    case "enable":
      return { ...state, enabling: action.stage };
    case "disable":
      return { ...state, disabling: action.stage };
    case "update-done":
      return {
        ...state,
        enabling: null,
        disabling: null,
        updated: action.stage,
        editing: null,
        key: state.key + 1,
      };
    case "update-failed":
      return { ...state, enabling: null, disabling: null, key: state.key + 1 };
    default:
      return state;
  }
}

function AppSettingDeployPhases(props) {
  let services = null;
  let isLoading = true;

  const { ownerId, appId } = props.match.params;
  const appApi = `/${ownerId}/${appId}`;

  const [state, setState] = useState({ clearKey: 0, hasUpdatedPhases: false });
  const [postDeployState, postDeployDispatch] = useReducer(
    modalReducer,
    modalDefaultState
  );

  useEffect(() => {
    document.title = title("Deploy Phases");
  }, []);

  const { data: appInfo, error, reload } = useAPILoad(
    appApi,
    (path) => props.invokeAppsApig({ path }),
    { polling: false }
  );

  if (appInfo) {
    isLoading = false;

    services = appInfo.services.map(({ service }) => service);
  }

  /////////
  // API //
  /////////

  function updateServicePhases(phases) {
    return props.invokeAppsApig({
      path: appApi,
      method: "PUT",
      body: { deploy_phases: phases },
    });
  }

  function updateStageInfo(appStageId, data) {
    return props.invokeAppsApig({
      path: `${appApi}/stages/${appStageId}?version=v20200317`,
      method: "PUT",
      body: data,
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleUpdate(phases) {
    await updateServicePhases(phases);
    await reload();

    setState({
      hasUpdatedPhases: true,
      clearKey: state.clearKey + 1,
    });
  }

  function handlePostDeployEdit(editObject) {
    postDeployDispatch({ type: "edit", editObject });
  }
  function handlePostDeployCancel() {
    postDeployDispatch({ type: "edit-done" });
  }

  async function handlePostDeploySave(stage, postDeployTestSteps) {
    postDeployDispatch({
      type: postDeployTestSteps ? "enable" : "disable",
      stage,
    });
    try {
      await updateStageInfo(stage, {
        post_deploy_test_steps: postDeployTestSteps,
      });
      await reload();
      postDeployDispatch({ type: "update-done", stage });
    } catch (e) {
      postDeployDispatch({ type: "update-failed", stage });
      errorHandler(e);
    }
  }

  ////////////
  // Render //
  ////////////

  return (
    <div className="AppSettingDeployPhases">
      <ScreenHeader border breadcrumb={appPipelineBreadcrumb(props)}>
        Deploy Phases
      </ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {error && <ErrorAlert error={error} />}

      {!isLoading && appInfo && (
        <>
          <p className="copy">
            Deploy phases allow you to deploy your services in a preset
            order.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn more about deploy phases
            </a>
            .
          </p>
          <AppSettingDeployWorkflow
            services={services}
            key={state.clearKey}
            onUpdate={handleUpdate}
            hasUpdated={state.hasUpdatedPhases}
          />
          <h4>Post-Deploy Phase</h4>
          <p className="copy">
            To run some commands after all your services have been deployed, add
            a post-deploy phase.&nbsp;
            <a target="_blank" href={postHelpUrl} rel="noopener noreferrer">
              Learn about post-deploy phases
            </a>
            .
          </p>
          <StagesPostDeployPanel
            stages={appInfo.stages}
            editing={postDeployState.editing}
            onEditClick={handlePostDeployEdit}
            onSaveClick={handlePostDeploySave}
            enabling={postDeployState.enabling}
            disabling={postDeployState.disabling}
            onCancelClick={handlePostDeployCancel}
            resetKey={`StagesPostDeployPanel-${postDeployState.key}`}
          />
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(AppSettingDeployPhases));
