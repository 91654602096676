import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppRoute from "./components/AppRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

// AuthenticatedRoutes
import Apps from "./containers/Apps";
import Home from "./containers/Home";
import Logs from "./containers/Logs";
import Owner from "./containers/Owner";
import Stages from "./containers/Stages";
import NewApp from "./containers/NewApp";
import Metrics from "./containers/Metrics";
import Services from "./containers/Services";
import AppIssues from "./containers/AppIssues";
import AppRemove from "./containers/AppRemove";
import AppReports from "./containers/AppReports";
import OrgBilling from "./containers/OrgBilling";
import OrgMembers from "./containers/OrgMembers";
import AppPipeline from "./containers/AppPipeline";
import OrgSettings from "./containers/OrgSettings";
import OrgAuditLog from "./containers/OrgAuditLog";
import OrgsUpgrade from "./containers/OrgsUpgrade";
import AppSettings from "./containers/AppSettings";
import UserBilling from "./containers/UserBilling";
import StageRemove from "./containers/StageRemove";
import UserSettings from "./containers/UserSettings";
import AppActivities from "./containers/AppActivities";
import OAuthCallback from "./containers/OAuthCallback";
import StageSettings from "./containers/StageSettings";
import ServiceRemove from "./containers/ServiceRemove";
import OrgUnsubscribe from "./containers/OrgUnsubscribe";
import AppIssueDetails from "./containers/AppIssueDetails";
import AppEditPipeline from "./containers/AppEditPipeline";
import AppSettingIssues from "./containers/AppSettingIssues";
import UsernameSettings from "./containers/UsernameSettings";
import AppRecentActivity from "./containers/AppRecentActivity";
import ServiceStageRemove from "./containers/ServiceStageRemove";
import AppActivityServices from "./containers/AppActivityServices";
import GitHubInstallCallback from "./containers/GitHubInstallCallback";
import AppSettingDeployPhases from "./containers/AppSettingDeployPhases";
import AppActivityPostDeployTest from "./containers/AppActivityPostDeployTest";

// UnauthenticatedRoutes
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import RequestDemo from "./containers/RequestDemo";
import ResetPassword from "./containers/ResetPassword";
import LoginSsoCallback from "./containers/LoginSsoCallback";

import NotFound from "./containers/NotFound";

export default ({ childProps }) => (
  <Switch>
    <UnauthenticatedRoute
      exact
      path="/login/sso-callback"
      component={LoginSsoCallback}
      props={childProps}
    />
    <UnauthenticatedRoute
      exact
      path="/login"
      component={Login}
      props={childProps}
    />
    <UnauthenticatedRoute
      exact
      path="/login/reset"
      component={ResetPassword}
      props={childProps}
    />
    <UnauthenticatedRoute
      exact
      path="/signup"
      component={Signup}
      props={childProps}
    />
    <Redirect exact from="/signup-account" to="/signup" />
    <UnauthenticatedRoute
      exact
      path="/request-demo"
      component={RequestDemo}
      props={childProps}
    />

    {/* User */}
    <AuthenticatedRoute exact path="/" component={Home} props={childProps} />
    <AuthenticatedRoute
      exact
      path="/settings"
      component={UserSettings}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/settings/usage"
      component={UserBilling}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/settings/username"
      component={UsernameSettings}
      props={childProps}
    />
    {/* Catch any unmatched settings routes */}
    <AppRoute path="/settings/:any" component={NotFound} props={childProps} />

    {/* Orgs */}
    <AuthenticatedRoute
      exact
      path="/upgrade"
      component={OrgsUpgrade}
      props={childProps}
    />
    <Route
      exact
      path="/orgs/:ownerId"
      render={({ match }) => <Redirect to={`/${match.params.ownerId}`} />}
    />
    <AuthenticatedRoute
      exact
      path="/orgs/:ownerId/settings"
      component={OrgSettings}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/orgs/:ownerId/members"
      component={OrgMembers}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/orgs/:ownerId/settings/billing"
      component={OrgBilling}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/orgs/:ownerId/logs"
      component={OrgAuditLog}
      props={childProps}
    />
    <AppRoute
      exact
      path="/orgs/:ownerId/settings/unsubscribe-reports"
      component={OrgUnsubscribe}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/orgs/:ownerId/new"
      component={NewApp}
      props={childProps}
    />

    {/* Apps Redirects */}
    <Redirect exact from="/apps/:id" to="/" />
    <Redirect exact from="/apps/:id/services/:sv" to="/" />
    <Redirect exact from="/apps/:id/services/:sv/stages/:st" to="/" />
    <Redirect
      exact
      from="/apps/:id/services/:sv/stages/:st/deployment"
      to="/"
    />
    <Redirect exact from="/apps/:id/services/:sv/stages/:st/logs/:so" to="/" />
    <Redirect
      exact
      from="/apps/:id/services/:sv/stages/:st/metrics/:so"
      to="/"
    />
    <Redirect
      exact
      from="/apps/:id/services/:sv/stages/:st/builds/:bu"
      to="/"
    />
    <Redirect
      exact
      from="/:ownerId/:appId/services/:serviceId/stages/:stageId/builds/:buildId"
      to="/:ownerId/:appId/services/:serviceId/stages/:stageId"
    />
    <Redirect exact from="/:ownerId/:appId/builds/:activityId" to="/" />
    <Redirect
      exact
      from="/:ownerId/:appId/builds/:activityId/services/:serviceId"
      to="/"
    />
    <Redirect
      exact
      from="/:ownerId/:appId/builds/:activityId/post-deploy"
      to="/"
    />
    <Redirect
      exact
      from="/:ownerId/:appId/services/:serviceId/stages/:stageId"
      to="/"
    />
    <Redirect
      exact
      from="/:ow/:app/stages/:st/services/:sv/logs/:so"
      to="/:ow/:app/stages/:st"
    />
    <Redirect
      exact
      from="/:ow/:app/stages/:st/services/:sv/metrics/:so"
      to="/:ow/:app/stages/:st"
    />
    <Redirect
      exact
      from="/:ow/:app/stages/:st/reports/:ry/:rd"
      to="/:ow/:app/reports/:ry/:rd"
    />

    {/* Deployment Resource Redirects */}
    <Redirect
      exact
      from="/:ownerId/:appId/stages/:appStageId/services/:serviceId/resources"
      to="/:ownerId/:appId/stages/:appStageId"
    />

    {/* Old Artifacts Redirects */}
    <Redirect
      exact
      from="/:ownerId/:appId/stages/:appStageId/builds/:buildId/artifacts"
      to="/:ownerId/:appId/stages/:appStageId/builds/:buildId"
    />

    {/* New Project */}
    <AuthenticatedRoute
      exact
      path="/new/callback/github-install"
      component={GitHubInstallCallback}
      props={childProps}
    />

    <AuthenticatedRoute
      exact
      path="/new/callback/:provider"
      component={OAuthCallback}
      props={childProps}
    />

    {/* Owner */}
    <AuthenticatedRoute
      exact
      path="/:ownerId"
      component={Owner}
      props={childProps}
    />

    {/* App */}
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId"
      component={Apps}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/pipeline"
      component={AppPipeline}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/pipeline/edit"
      component={AppEditPipeline}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/pipeline/phases"
      component={AppSettingDeployPhases}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/status"
      component={AppRemove}
      props={childProps}
    />

    {/* Recent Activity */}
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/activity"
      component={AppRecentActivity}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/activity/stages/:appStageId"
      component={AppRecentActivity}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/activity/services/:serviceId"
      component={AppRecentActivity}
      props={childProps}
    />

    {/* Reports */}
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/reports/:reportType/:reportSlug"
      component={AppReports}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/reports/:reportType/:reportSlug/stages/:appStageId"
      component={AppReports}
      props={childProps}
    />

    {/* App Settings */}
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/settings"
      component={AppSettings}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/settings/issues"
      component={AppSettingIssues}
      props={childProps}
    />
    <Redirect
      exact
      from="/:o/:a/services/:s"
      to="/:o/:a/settings/services/:s"
    />
    <Redirect
      exact
      from="/:o/:a/services/:s/status"
      to="/:o/:a/settings/services/:s/status"
    />
    <Redirect
      exact
      from="/:o/:a/services/:s/status/stages/:st"
      to="/:o/:a/settings/services/:s/status/stages/:st"
    />
    <Redirect
      exact
      from="/:o/:a/stages/:s/status"
      to="/:o/:a/settings/stages/:s/status"
    />
    <Redirect
      exact
      from="/:o/:a/stages/:st/status/services/:s"
      to="/:o/:a/settings/stages/:st/status/services/:s"
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/settings/services/:serviceId"
      component={Services}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/settings/services/:serviceId/status"
      component={ServiceRemove}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/settings/services/:serviceId/status/stages/:appStageId"
      component={ServiceStageRemove}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/settings/stages/:appStageId"
      component={StageSettings}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/settings/stages/:appStageId/status"
      component={StageRemove}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/settings/stages/:appStageId/status/services/:serviceId"
      component={ServiceStageRemove}
      props={childProps}
    />

    {/* Resournces */}
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/stages"
      component={Stages}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/stages/:appStageId"
      component={Stages}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/stages/:appStageId/logs"
      component={Logs}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/stages/:appStageId/metrics"
      component={Metrics}
      props={childProps}
    />

    {/* Resournces */}
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/issues"
      component={AppIssues}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/issues/stages/:appStageId"
      component={AppIssues}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/issues/:errorGroupId/:errorId"
      component={AppIssueDetails}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/issues/stages/:appStageId/:errorGroupId/:errorId"
      component={AppIssueDetails}
      props={childProps}
    />

    {/* Builds */}
    <Redirect
      exact
      from="/:o/:a/stages/:s/builds/:b"
      to="/:o/:a/activity/stages/:s/builds/:b"
    />
    <Redirect
      exact
      from="/:o/:a/stages/:s/builds/:b/services/:sr"
      to="/:o/:a/activity/stages/:s/builds/:b/services/:sr"
    />
    <Redirect
      exact
      from="/:o/:a/stages/:s/builds/:b/post-deploy"
      to="/:o/:a/activity/stages/:s/builds/:b/post-deploy"
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/activity/stages/:appStageId/builds/:buildId"
      component={AppActivities}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/activity/stages/:appStageId/builds/:buildId/services/:serviceId"
      component={AppActivityServices}
      props={childProps}
    />
    <AuthenticatedRoute
      exact
      path="/:ownerId/:appId/activity/stages/:appStageId/builds/:buildId/post-deploy"
      component={AppActivityPostDeployTest}
      props={childProps}
    />

    {/* Finally, catch all unmatched routes */}
    <AppRoute component={NotFound} props={childProps} />
  </Switch>
);
