import React, { Component, Fragment } from "react";
import {
  Form,
  Table,
  HelpBlock,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { trimFields } from "../lib/formLib";
import { testEmail, testUrl } from "../lib/regexLib";
import SectionInfo from "./SectionInfo";
import LoaderButton from "./LoaderButton";
import "./StageNotificationsPanel.css";

const helpUrl = "https://seed.run/docs/adding-build-notifications";

const defaultTypeValue = "select a type";

const NOTIF_TYPE_SLACK = "slack";
const NOTIF_TYPE_EMAIL = "email";
const NOTIF_TYPE_WEBHOOK = "webhook";

const NOTIF_EVENT_ALL = "all";
const NOTIF_EVENT_FAILURE_ONLY = "failure_only";

const noop = () => {};

const defaultProps = {
  notifications: false,

  // Flag to trigger clearing the form
  clearForm: 0,

  adding: false,
  loading: false,
  removing: {},

  onAddClick: noop,
  onShowClick: noop,
  onHideClick: noop,
  onRemoveClick: noop,
};

const defaultState = {
  formData: {
    type: defaultTypeValue,
    event: NOTIF_EVENT_ALL,
    email: "",
    channel: "",
    slackUrl: "",
    webhookUrl: "",
    jwsToken: "",
  },
  validation: {},
  clearForm: defaultProps.clearForm,
};

class StageNotificationsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.clearForm !== prevState.clearForm) {
      return {
        formData: {
          ...defaultState.formData,
          type: prevState.formData.type,
          event: prevState.formData.event,
        },
        clearForm: nextProps.clearForm,
      };
    }

    return null;
  }

  handleFieldChange = (e) => {
    const { id, value } = e.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      validation: {
        ...this.state.validation,
        [id]: null,
      },
    });
  };

  handleAddClick = (e) => {
    e.preventDefault();

    const {
      slackUrl,
      type,
      event,
      email,
      channel,
      webhookUrl,
      jwsToken,
    } = trimFields(this.state.formData);
    if (type === defaultTypeValue) {
      return;
    }

    if (type === NOTIF_TYPE_SLACK) {
      if (!testUrl(slackUrl)) {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            slackUrl: "error",
          },
        }));
        return false;
      }

      this.props.onAddClick(
        e,
        channel === ""
          ? { type, event, url: slackUrl }
          : { type, event, url: slackUrl, channel }
      );
    } else if (type === NOTIF_TYPE_EMAIL) {
      if (!testEmail(email)) {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            email: "error",
          },
        }));
        return false;
      }
      this.props.onAddClick(e, { type, event, email });
    } else if (type === NOTIF_TYPE_WEBHOOK) {
      if (!testUrl(webhookUrl)) {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            webhookUrl: "error",
          },
        }));
        return false;
      }

      this.props.onAddClick(
        e,
        jwsToken === ""
          ? { type, event, webhookUrl }
          : { type, event, webhookUrl, jwsToken }
      );
    }
  };

  renderNotification(
    key,
    { id, type, event, email, slackUrl, channel, webhookUrl, jwsToken }
  ) {
    return (
      <tr key={key}>
        <td>
          {type === NOTIF_TYPE_EMAIL
            ? email
            : type === NOTIF_TYPE_SLACK
            ? channel
              ? `#${channel}`
              : "(default channel)"
            : webhookUrl}
        </td>
        <td>{event === NOTIF_EVENT_ALL ? "All events" : "Build failures"}</td>
        <td>
          <LoaderButton
            text="Remove"
            bsStyle="red-link"
            loading={this.props.removing[id]}
            onClick={(e) => this.props.onRemoveClick(e, id)}
          />
        </td>
      </tr>
    );
  }

  renderNewNotification() {
    const { formData, validation } = this.state;

    return (
      <Form onSubmit={this.handleAddClick}>
        <FormGroup controlId="event">
          <HelpBlock>Notification event</HelpBlock>
          <FormControl
            bsSize="large"
            componentClass="select"
            value={formData.event}
            onChange={this.handleFieldChange}
          >
            <option value={NOTIF_EVENT_ALL}>All events</option>
            <option value={NOTIF_EVENT_FAILURE_ONLY}>Build failures</option>
          </FormControl>
        </FormGroup>
        <FormGroup controlId="type">
          <HelpBlock>Notification type</HelpBlock>
          <FormControl
            bsSize="large"
            componentClass="select"
            value={formData.type}
            onChange={this.handleFieldChange}
          >
            <option disabled value={defaultTypeValue}>
              Select a notification type...
            </option>
            <option value={NOTIF_TYPE_EMAIL}>Email</option>
            <option value={NOTIF_TYPE_SLACK}>Slack</option>
            <option value={NOTIF_TYPE_WEBHOOK}> Webhook</option>
          </FormControl>
        </FormGroup>
        {formData.type === NOTIF_TYPE_EMAIL && (
          <FormGroup controlId="email" validationState={validation.email}>
            <FormControl
              type="email"
              bsSize="large"
              value={formData.email}
              onChange={this.handleFieldChange}
              placeholder="Email to be notified"
            />
            {validation.email && (
              <HelpBlock>Please enter a valid email address.</HelpBlock>
            )}
          </FormGroup>
        )}
        {formData.type === NOTIF_TYPE_SLACK && (
          <Fragment>
            <FormGroup
              controlId="slackUrl"
              validationState={validation.slackUrl}
            >
              <FormControl
                type="text"
                bsSize="large"
                value={formData.slackUrl}
                onChange={this.handleFieldChange}
                placeholder="Webhook URL"
              />
              {validation.slackUrl && (
                <HelpBlock>Please enter a valid webhook URL.</HelpBlock>
              )}
            </FormGroup>
            <FormGroup controlId="channel">
              <FormControl
                type="text"
                bsSize="large"
                value={formData.channel}
                onChange={this.handleFieldChange}
                placeholder="Slack Channel (Optional)"
              />
            </FormGroup>
          </Fragment>
        )}
        {formData.type === NOTIF_TYPE_WEBHOOK && (
          <Fragment>
            <FormGroup
              controlId="webhookUrl"
              validationState={validation.webhookUrl}
            >
              <FormControl
                type="text"
                bsSize="large"
                value={formData.webhookUrl}
                onChange={this.handleFieldChange}
                placeholder="Webhook URL"
              />
              {validation.webhookUrl && (
                <HelpBlock>Please enter a valid webhook URL.</HelpBlock>
              )}
            </FormGroup>
            <FormGroup controlId="jwsToken">
              <FormControl
                type="text"
                bsSize="large"
                value={formData.jwsToken}
                onChange={this.handleFieldChange}
                placeholder="JWS Secret Token (Optional)"
              />
            </FormGroup>
          </Fragment>
        )}
        <div className="controls">
          <LoaderButton
            text="Add Notification"
            type="submit"
            bsSize="large"
            loading={this.props.adding}
            onClick={this.handleAddClick}
          />
          <LoaderButton
            bsStyle="link"
            text="Hide Notification Options"
            onClick={this.props.onHideClick}
          />
        </div>
      </Form>
    );
  }

  render() {
    const { notifications, loading, onShowClick } = this.props;

    return (
      <div className="StageNotificationsPanel">
        <SectionInfo
          label="Notifications"
          description={
            <>
              Get notifications for the builds in this stage.&nbsp;
              <a target="_blank" href={helpUrl} rel="noopener noreferrer">
                Learn about the notification options.
              </a>
            </>
          }
          button={
            notifications === false && (
              <LoaderButton
                bsSize="large"
                className="btnShow"
                onClick={onShowClick}
                loading={loading}
                text="Show Notification Options"
              />
            )
          }
        >
          {notifications !== false && (
            <div className="notifications">
              {notifications.length !== 0 && (
                <Table>
                  <thead>
                    <tr>
                      <th colSpan="3">Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.map((notification, i) =>
                      this.renderNotification(i, notification)
                    )}
                  </tbody>
                </Table>
              )}
              {this.renderNewNotification()}
            </div>
          )}
        </SectionInfo>
      </div>
    );
  }
}

StageNotificationsPanel.defaultProps = defaultProps;

export default StageNotificationsPanel;
