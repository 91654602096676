import React, { useEffect, useReducer } from "react";
import { Redirect } from "react-router-dom";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import SectionGroupPanel from "../components/SectionGroupPanel";
import StageRegionPanel from "../components/StageRegionPanel";
import StageNotifications from "./widgets/StageNotifications";
import StageBranchPanel from "../components/StageBranchPanel";
import NewIamRoleModal from "../components/NewIamRoleModal";
import LoadingSpinner from "../components/LoadingSpinner";
import StageNameForm from "../components/StageNameForm";
import ScreenHeader from "../components/ScreenHeader";
import ErrorAlert from "../components/ErrorAlert";
import ItemDelete from "./widgets/ItemDelete";
import StageIam from "./widgets/StageIam";
import EnvVars from "./widgets/EnvVars";
import { errorHandler, isAPIErrorWithCode } from "../lib/errorLib";
import { appSettingsBreadcrumb } from "../lib/breadcrumbLib";
import { usePolicyReducer } from "../lib/hooksLib";
import useAPILoad from "../lib/apiLoadLib";
import {
  getAppPipelineUrl,
  getStageSettingsUrl,
  getAppStageRemoveUrl,
} from "../lib/urlLib";
import title from "../lib/titleLib";
import "./StageSettings.css";

const settingDefaultState = { key: 0, updated: false, updating: false };

function settingReducer(state, action) {
  switch (action.type) {
    case "update":
      return { ...state, updating: true };
    case "update-done":
      return { ...state, updating: false, updated: true, key: state.key + 1 };
    case "update-failed":
      return { ...state, updating: false, key: state.key + 1 };
    default:
      return state;
  }
}

const modalDefaultState = {
  key: 0,
  loading: false,
  editing: false,
  updated: false,
  enabling: false,
  disabling: false,
};

function modalReducer(state, action) {
  switch (action.type) {
    case "load":
      return { ...state, loading: true };
    case "load-done":
      return { ...state, loading: false };
    case "load-failed":
      return { ...state, loading: false, key: state.key + 1 };
    case "edit":
      return { ...state, editing: true };
    case "edit-done":
      return { ...state, editing: false, key: state.key + 1 };
    case "enable":
      return { ...state, enabling: true };
    case "disable":
      return { ...state, disabling: true };
    case "update-done":
      return {
        ...state,
        enabling: false,
        disabling: false,
        updated: true,
        editing: false,
        key: state.key + 1,
      };
    case "update-failed":
      return {
        ...state,
        enabling: false,
        disabling: false,
        key: state.key + 1,
      };
    default:
      return state;
  }
}

const pagingDefaultState = {
  loading: false,
  loadingMore: false,
  items: null,
  nextToken: null,
  hasMore: false,
};

function pagingReducer(state, action) {
  switch (action.type) {
    case "init":
      return {
        ...state,
        items: action.items,
        nextToken: action.nextToken,
        hasMore: !!action.nextToken,
      };
    case "load-more":
      return { ...state, loading: true };
    case "load-more-done":
      return {
        ...state,
        loading: false,
        items: [...state.items, ...action.items],
        nextToken: action.nextToken,
        hasMore: !!action.nextToken,
      };
    case "load-more-failed":
      return { ...state, loading: false };
    default:
      return state;
  }
}

function StageSettings(props) {
  let isLoading = true;
  let isRemovingOrRemoveFailed = false;

  const { ownerId, appId, appStageId } = props.match.params;

  const [nameState, nameDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [regionState, regionDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [autoDeployState, autoDeployDispatch] = useReducer(
    modalReducer,
    modalDefaultState
  );
  const [branchesState, branchesDispatch] = useReducer(
    pagingReducer,
    pagingDefaultState
  );

  const [
    {
      policyInfo,
      policyEditing,
      policyUpdating,
      policyModalKey,
      showPolicyModal,
      hasPolicyUpdated,
      policyUpdateError,
      policyInfoLoading,
    },
    policyDispatch,
  ] = usePolicyReducer();

  useEffect(() => {
    document.title = title(appStageId);
  }, [appStageId]);

  const {
    error,
    data: stageInfo,
    reload: reloadStageInfo,
  } = useAPILoad(`/${ownerId}/${appId}/stages/${appStageId}`, (path) =>
    props.invokeAppsApig({ path })
  );

  if (stageInfo !== null) {
    isLoading = false;

    if (
      stageInfo.stage.status === "deleting" ||
      stageInfo.stage.status === "delete_failed"
    ) {
      isRemovingOrRemoveFailed = true;
    }
  }

  function getAppAPI() {
    return `/${ownerId}/${appId}`;
  }

  function getAppStageAPI() {
    return `/${ownerId}/${appId}/stages/${appStageId}`;
  }

  /////////
  // API //
  /////////

  function updateStageInfo(data) {
    return props.invokeAppsApig({
      path: `${getAppStageAPI()}?version=v20200317`,
      method: "PUT",
      body: data,
    });
  }

  function removeAppStage(fullRemove) {
    return props.invokeAppsApig({
      path: getAppStageAPI(),
      method: "DELETE",
      body: { mode: fullRemove ? undefined : "quick" },
    });
  }

  function getGitBranches(nextToken) {
    return props.invokeAppsApig({
      path: `${getAppAPI()}/git_branches`,
      queryStringParameters: { nextToken },
    });
  }

  ////////////////////
  // API - Env Vars //
  ////////////////////

  function listEnvVars() {
    return props.invokeApig({
      path: `${getAppStageAPI()}/envs`,
    });
  }

  function getEnvVar(name) {
    return props.invokeApig({
      path: `${getAppStageAPI()}/envs/${name}`,
    });
  }

  function addEnvVar(name, value) {
    return props.invokeApig({
      path: `${getAppStageAPI()}/envs`,
      method: "POST",
      body: { name, value },
    });
  }

  function updateEnvVar(name, value) {
    return props.invokeApig({
      path: `${getAppStageAPI()}/envs/${name}`,
      method: "PUT",
      body: { value },
    });
  }

  function deleteEnvVar(name) {
    return props.invokeApig({
      path: `${getAppStageAPI()}/envs/${name}`,
      method: "DELETE",
    });
  }

  /////////////////////////
  // API - Notifications //
  /////////////////////////

  function loadNotifications() {
    return props.invokeApig({ path: `${getAppStageAPI()}/notifications` });
  }

  function addNotification(notification) {
    return props.invokeApig({
      path: `${getAppStageAPI()}/notifications`,
      method: "POST",
      body: notification,
    });
  }

  function removeNotification(notification_id) {
    return props.invokeApig({
      path: `${getAppStageAPI()}/notifications`,
      method: "DELETE",
      body: { notification_id },
    });
  }

  ///////////////////////////
  // API - IAM Policy Info //
  ///////////////////////////

  function getIamPolicyInfo() {
    return props.invokeV2Apig({ path: `/orgs/${ownerId}/iam_role` });
  }

  function setIamPolicyInfo(iam_policy) {
    return props.invokeV2Apig({
      path: `/orgs/${ownerId}/iam_role`,
      method: "POST",
      body: { iam_policy },
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleNameSave(name) {
    nameDispatch({ type: "update" });
    try {
      await updateStageInfo({ name });
      // Reloads the page in place with new route
      props.history.replace(getStageSettingsUrl(ownerId, appId, name));
      nameDispatch({ type: "update-done" });
    } catch (e) {
      nameDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleRegionSave(region) {
    regionDispatch({ type: "update" });
    try {
      await updateStageInfo({ region });
      await reloadStageInfo();
      regionDispatch({ type: "update-done" });
    } catch (e) {
      regionDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleIamSave(event, iamData) {
    await updateStageInfo(iamData);
    await reloadStageInfo();
  }

  async function handleAppStageRemove(event, fullRemove) {
    await removeAppStage(fullRemove);

    // redirect
    const { ownerId, appId } = props.match.params;
    const url = getAppPipelineUrl(ownerId, appId);
    props.history.push(url);
  }

  ////////////////////////////
  // Handlers - Auto Deploy //
  ////////////////////////////

  async function handleAutoDeployEdit() {
    autoDeployDispatch({ type: "load" });
    try {
      const { branches, nextToken } = await getGitBranches();
      branchesDispatch({ type: "init", items: branches, nextToken });
      autoDeployDispatch({ type: "load-done" });
      autoDeployDispatch({ type: "edit" });
    } catch (e) {
      autoDeployDispatch({ type: "load-failed" });
      errorHandler(e);
    }
  }
  async function handleAutoDeployLoadMore() {
    branchesDispatch({ type: "load-more" });
    try {
      const { branches, nextToken } = await getGitBranches(
        branchesState.nextToken
      );
      branchesDispatch({ type: "load-more-done", items: branches, nextToken });
    } catch (e) {
      branchesDispatch({ type: "load-more-failed" });
      errorHandler(e);
    }
  }
  function handleAutoDeployCancel() {
    autoDeployDispatch({ type: "edit-done" });
  }
  async function handleAutoDeploySave(branch) {
    autoDeployDispatch({ type: branch ? "enable" : "disable" });
    try {
      await updateStageInfo({ branch });
      await reloadStageInfo();
      autoDeployDispatch({ type: "update-done" });
    } catch (e) {
      autoDeployDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  ///////////////////////
  // Handlers - Policy //
  ///////////////////////

  function handleCustomizePolicyClick(event) {
    policyDispatch({ type: "show-policy-editor" });
  }

  function handleUpdateCancelPolicyClick(event) {
    policyDispatch({ type: "hide-policy-editor" });
  }

  async function handlePolicyUpdateClick(event, policy) {
    policyDispatch({ type: "updating-policy-editor" });

    try {
      const policyInfo = await setIamPolicyInfo(policy);
      policyDispatch({ policyInfo, type: "updated-policy-editor" });
    } catch (e) {
      if (isAPIErrorWithCode(e, 5014)) {
        policyDispatch({
          type: "error-policy-editor",
          policyModalError: "Please enter a valid IAM policy.",
        });
      } else {
        errorHandler(e);
      }
    }
  }

  async function handleShowIamRolePanelClick(event) {
    policyDispatch({ type: "info-loading" });

    try {
      const policyInfo = await getIamPolicyInfo();
      policyDispatch({ policyInfo, type: "info-loaded" });
    } catch (e) {
      errorHandler(e);
    }
  }

  function handleHideIamRolePanelClick(event) {
    policyDispatch({ type: "hide-info" });
  }

  return (
    <div className="StageSettings">
      <ScreenHeader border breadcrumb={appSettingsBreadcrumb(props)}>
        {appStageId}
      </ScreenHeader>

      {!isLoading && isRemovingOrRemoveFailed && (
        <Redirect to={getAppStageRemoveUrl(ownerId, appId, appStageId)} />
      )}

      {isLoading && <LoadingSpinner />}

      {error && <ErrorAlert error={error} />}

      {!isLoading && stageInfo && (
        <div>
          <div className="settings-group">
            <StageNameForm
              key={`name-${nameState.key}`}
              name={appStageId}
              canChangeName={stageInfo.can_change_name}
              updated={nameState.updated}
              saving={nameState.updating}
              onSaveClick={handleNameSave}
            />
            <hr />
            {!stageInfo.stage.hasOwnProperty("pull_request") && (
              <>
                <StageBranchPanel
                  owner={ownerId}
                  branch={stageInfo.stage.branch}
                  branches={branchesState.items}
                  gitRepo={stageInfo.repo}
                  resetKey={`StageBranchPanel-${autoDeployState.key}`}
                  loading={autoDeployState.loading}
                  editing={autoDeployState.editing}
                  enabling={autoDeployState.enabling}
                  disabling={autoDeployState.disabling}
                  loadingMoreBranches={branchesState.loading}
                  hasMoreBranches={branchesState.hasMore}
                  onEditClick={handleAutoDeployEdit}
                  onSaveClick={handleAutoDeploySave}
                  onCancelClick={handleAutoDeployCancel}
                  onLoadMoreBranchesClick={handleAutoDeployLoadMore}
                />
                <hr />
              </>
            )}
            <StageRegionPanel
              key={`region-${regionState.key}`}
              region={stageInfo.stage.region}
              updated={regionState.updated}
              saving={regionState.updating}
              onSaveClick={handleRegionSave}
            />
            <hr />
            <StageIam
              onSave={handleIamSave}
              iamRole={stageInfo.stage.iamRole}
              iamAccess={stageInfo.stage.iamAccess}
              policyInfoLoading={policyInfoLoading}
              onShowIamRoleClick={handleShowIamRolePanelClick}
            />
            <hr />
            <EnvVars
              onAdd={addEnvVar}
              onGetOne={getEnvVar}
              onSave={updateEnvVar}
              onLoadAll={listEnvVars}
              onRemove={deleteEnvVar}
            />
            <hr />
            <StageNotifications
              onAdd={addNotification}
              onLoad={loadNotifications}
              onRemove={removeNotification}
            />
          </div>
          <SectionGroupPanel important title="Admin">
            <ItemDelete
              type="appStage"
              itemName={stageInfo.stage.name}
              onDelete={handleAppStageRemove}
              disabled={stageInfo.stage.is_production}
              disabledMessage="You cannot delete the production stage of an app."
            />
          </SectionGroupPanel>

          <NewIamRoleModal
            key={policyModalKey}
            show={showPolicyModal}
            editing={policyEditing}
            updating={policyUpdating}
            hasUpdated={hasPolicyUpdated}
            updateError={policyUpdateError}
            onUpdateClick={handlePolicyUpdateClick}
            onCloseClick={handleHideIamRolePanelClick}
            onCustomizeClick={handleCustomizePolicyClick}
            seedPolicy={policyInfo && policyInfo.seedTemplate}
            onUpdateCancelClick={handleUpdateCancelPolicyClick}
            cloudFormationUrl={policyInfo && policyInfo.createUrl}
            serverlessPolicy={policyInfo && policyInfo.deployTemplate}
          />
        </div>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(StageSettings));
