import React from "react";
import LoaderButton from "./LoaderButton";
import { initCognitoSDK } from "../lib/awsLib";
import "./SsoLoginPanel.css";

const noop = () => {};

function handleLoginClick(provider) {
  const auth = initCognitoSDK(provider);
  auth.getSession();
}

export default function SsoLoginPanel({
  name = "",
  domain = "",
  source = "",
  provider = "",
  onCancelClick = noop,
  ...props
}) {
  return (
    <div className="SsoLoginPanel">
      <h4>Sign in to the {name} Org on Seed</h4>
      <LoaderButton
        type="submit"
        bsSize="large"
        bsStyle="warning"
        onClick={() => handleLoginClick(provider)}
      >
        Sign in with {source}
      </LoaderButton>
      <br />
      <LoaderButton bsStyle="link" onClick={onCancelClick}>
        Cancel
      </LoaderButton>
    </div>
  );
}
