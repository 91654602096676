import React from "react";
import { Link } from "react-router-dom";
import { getServiceUrl } from "../lib/urlLib";
import ServiceIcon from "./ServiceIcon";
import "./ServiceButtonGroup.css";

export default ({
  appName,
  ownerName,
  serviceType,
  serviceName,
  className = "",
}) => (
  <div className={`ServiceButtonGroup ${className}`}>
    <span className="service">
      {ownerName && appName && (
        <Link to={getServiceUrl(ownerName, appName, serviceName)}>
          <ServiceIcon size="medium" type={serviceType} />
          {serviceName}
        </Link>
      )}
      {(!ownerName || !appName) && (
        <>
          <ServiceIcon size="medium" type={serviceType} />
          {serviceName}
        </>
      )}
    </span>
  </div>
);
