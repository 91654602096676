import React, { Fragment } from "react";
import { Breadcrumb } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ScrollShadowContainer from "./ScrollShadowContainer";
import "./ScreenHeader.css";

function renderItem({ text, style = "", ...props }) {
  return (
    <Breadcrumb.Item className={style} {...props}>
      {text}
    </Breadcrumb.Item>
  );
}

function renderWrappedItem(props, i) {
  return props.href && !props.active ? (
    <LinkContainer exact key={i} to={props.href}>
      {renderItem(props)}
    </LinkContainer>
  ) : (
    <Fragment key={i}>{renderItem(props)}</Fragment>
  );
}

export default function ScreenHeader({
  action = null,
  border = false,
  breadcrumb = null,
  ...props
}) {
  const borderCs = border ? "border" : "";
  const breadcrumbCs = breadcrumb ? "with-breadcrumb" : "";

  return (
    <div className={`ScreenHeader ${borderCs} ${breadcrumbCs}`}>
      {breadcrumb && (
        <ScrollShadowContainer scrollToEnd>
          <Breadcrumb>
            {breadcrumb.map(renderWrappedItem)}
            <span className="spacer"></span>
          </Breadcrumb>
        </ScrollShadowContainer>
      )}
      <div className="cols">
        <h1 className="column copy">{props.children}</h1>
        {action && <div className="column action">{action}</div>}
      </div>
    </div>
  );
}
