import React, { useState } from "react";
import ManualDeployModal from "../../components/ManualDeployModal";
import { errorHandler } from "../../lib/errorLib";

export default function ManualDeploy({
  show,
  deployTo,
  defaultBranch = null,
  lastDeployedRefs = [],

  onClose,
  onDeploy,
}) {
  const [deploying, setDeploying] = useState(false);

  async function handleDeployClick(...args) {
    setDeploying(true);

    try {
      await onDeploy(...args);
    } catch (e) {
      errorHandler(e);
    }

    setDeploying(false);
  }

  function handleCloseClick(event) {
    onClose();
  }

  return (
    <ManualDeployModal
      show={show}
      deployTo={deployTo}
      deploying={deploying}
      defaultBranch={defaultBranch}
      onCloseClick={handleCloseClick}
      onDeployClick={handleDeployClick}
      lastDeployedRefs={lastDeployedRefs}
    />
  );
}
