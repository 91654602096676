export function getOrgName({ name, isDefaultOrg }) {
  return isDefaultOrg ? `${name}’s team` : name;
}

export function sortByNumApps(groupedApps) {
  return groupedApps.sort((one, two) =>
    one.org.isMyDefaultOrg
      ? -1
      : two.org.isMyDefaultOrg
      ? 1
      : one.apps.length < two.apps.length
      ? 1
      : one.apps.length === two.apps.length
      ? 0
      : -1
  );
}
