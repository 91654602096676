import React, { Component } from "react";
import AppAddServiceModal from "../../components/AppAddServiceModal";
import { errorHandler, getAPIError } from "../../lib/errorLib";
import { testAppSlug } from "../../lib/regexLib";
import { safeParse } from "../../lib/jsonLib";
import config from "../../config";

const noop = () => {};

const defaultServiceName = "new-service";
const defaultProps = {
  show: false,
  onAdd: noop,
  onClose: noop,
  onSearch: noop,
};

const defaultState = {
  adding: false,
  editing: true,
  searching: false,

  searchPath: "",
  serviceName: null,
  serviceFramework: undefined,

  searchError: null,
  searchErrorContext: null,
};

class AppAddService extends Component {
  state = { ...defaultState };

  handleAddClick = async (name, framework) => {
    this.setState({ adding: true });

    try {
      const serviceName = name || this.state.serviceName || defaultServiceName;
      const serviceFramework = framework || this.state.serviceFramework;

      await this.props.onAdd(
        serviceName,
        this.state.searchPath,
        serviceFramework
      );
    } catch (e) {
      this.setState({ adding: false });
      errorHandler(e);
    }
  };

  handleChangeClick = async (event) => {
    this.setState({ editing: true });
  };

  handleSearchClick = async (event, path) => {
    this.setState({
      editing: false,
      searching: true,
      searchPath: path,
    });

    try {
      const slsInfo = await this.props.onSearch(path);
      const serviceName = testAppSlug(slsInfo.service_name)
        ? slsInfo.service_name
        : null;
      this.setState({
        searching: false,
        serviceName,
        searchPath: slsInfo.servicePath,
        serviceFramework: slsInfo.serviceFramework,
        searchError: null,
        searchErrorContext: null,
      });
    } catch (e) {
      const serviceSearchErrors = config.serviceSearchErrors;
      const errorObject = getAPIError(e);

      let serviceFramework;
      let searchError = serviceSearchErrors.UNKNOWN;
      let searchErrorContext = null;

      if (errorObject) {
        const { code, context } = errorObject;

        switch (code) {
          case serviceSearchErrors.GENERIC_NOT_FOUND:
          case serviceSearchErrors.SLS_NOT_FOUND:
          case serviceSearchErrors.SST_NOT_FOUND:
            searchError = code;
            searchErrorContext = safeParse(context);
            break;
          case serviceSearchErrors.SLS_CANNOT_PARSE:
          case serviceSearchErrors.SLS_INVALID_SERVICE_NAME:
          case serviceSearchErrors.SLS_JS_NOT_SUPPORTED:
          case serviceSearchErrors.SST_CANNOT_PARSE:
          case serviceSearchErrors.SST_INVALID_SERVICE_NAME:
            searchError = code;
            break;
          default:
            searchError = serviceSearchErrors.UNKNOWN;
        }

        serviceFramework =
          searchError === serviceSearchErrors.SLS_NOT_FOUND ||
          searchError === serviceSearchErrors.SLS_CANNOT_PARSE ||
          searchError === serviceSearchErrors.SLS_INVALID_SERVICE_NAME ||
          searchError === serviceSearchErrors.SLS_JS_NOT_SUPPORTED
            ? "sls"
            : "sst";
      }

      this.setState({
        searchError,
        searching: false,
        searchErrorContext,
        serviceName: defaultServiceName,
        serviceFramework,
      });
    }
  };

  render() {
    const { show } = this.props;
    const {
      adding,
      editing,
      searching,
      searchPath,
      serviceName,
      serviceFramework,
      searchError,
      searchErrorContext,
    } = this.state;

    return (
      <AppAddServiceModal
        show={show}
        adding={adding}
        editing={editing}
        searching={searching}
        searchPath={searchPath}
        serviceName={serviceName}
        serviceFramework={serviceFramework}
        searchError={searchError}
        onAddClick={this.handleAddClick}
        onCloseClick={this.props.onClose}
        onShowClick={this.handleShowClick}
        onSearchClick={this.handleSearchClick}
        onChangeClick={this.handleChangeClick}
        searchErrorContext={searchErrorContext}
      />
    );
  }
}

AppAddService.defaultProps = defaultProps;

export default AppAddService;
