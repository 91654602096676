import React from "react";
import MemberRoleForm, { MemberRoleFormModes } from "./MemberRoleForm";
import LoaderButton from "./LoaderButton";
import config from "../config";
import "./NewMemberForm.css";

const noop = () => {};

export default function NewMemberForm({
  apps = [],
  owner = null,
  adding = false,
  showing = false,
  disabled = false,
  onAddClick = noop,
  onShowClick = noop,
  onCancelClick = noop,
  isRbacAvailable = true,
}) {
  async function handleAddClick(email, permissions, appPermissions) {
    await onAddClick(email, permissions, appPermissions);
  }

  return (
    <div className="NewMemberForm">
      {!showing && (
        <LoaderButton
          bsSize="large"
          bsStyle="warning"
          text="Add Member"
          disabled={disabled}
          onClick={onShowClick}
        />
      )}
      {showing && (
        <MemberRoleForm
          apps={apps}
          owner={owner}
          adding={adding}
          mode={MemberRoleFormModes.add}
          onAddMemberClick={handleAddClick}
          isRbacAvailable={isRbacAvailable}
          onAddMemberCancelClick={onCancelClick}
          initialPermissions={[config.userPermissions.admin.value]}
        />
      )}
    </div>
  );
}
