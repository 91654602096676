import React from "react";
import Alert from "./Alert";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import PermissionErrorModal from "./PermissionErrorModal";
import { usePermissionErrorReducer } from "../lib/hooksLib";
import "./PermissionErrorPanel.css";

export default function PermissionErrorPanel({ type, error, ...props }) {
  const [
    { error: permissionError },
    permissionErrorDispatch,
  ] = usePermissionErrorReducer();

  function handleErrorButtonClick() {
    permissionErrorDispatch({
      error,
      type: "set",
    });
  }

  function handleDismissPermissionError() {
    permissionErrorDispatch({ type: "clear" });
  }

  return (
    <>
      {error && (
        <Alert className="PermissionErrorPanel" bsStyle="danger">
          <LoaderButton bsStyle="red-link" onClick={handleErrorButtonClick}>
            <FaIcon name="exclamation-triangle" />
            {props.children}
          </LoaderButton>
        </Alert>
      )}
      <PermissionErrorModal
        type={type}
        error={error}
        show={permissionError ? true : false}
        onCloseClick={handleDismissPermissionError}
      />
    </>
  );
}
