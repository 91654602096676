import React, { useRef, useEffect } from "react";
import { Alert } from "react-bootstrap";
import "./NetworkStatusAlert.css";

const SHOW_AGAIN_DELAY = 30 * 1000;
const AUTO_DISMISS_DELAY = 10 * 1000;

export default function NetworkStatusAlert({
  onDismiss,
  show = false,
  ...props
}) {
  const timer = useRef(null);
  const dismissTime = useRef(null);

  const shouldShow = show && canShowAgain();

  useEffect(() => {
    function handleTimer() {
      timer.current = null;
      onDismiss();
    }

    function setTimer() {
      timer.current = window.setTimeout(handleTimer, AUTO_DISMISS_DELAY);
      dismissTime.current = null;
    }

    if (shouldShow && timer.current === null) {
      setTimer();
    } else if (!shouldShow && timer.current !== null) {
      clearTimer();
    }

    return clearTimer;
  }, [shouldShow, onDismiss]);

  function clearTimer() {
    if (timer.current) {
      window.clearTimeout(timer.current);
      timer.current = null;
    }
  }

  function canShowAgain() {
    return dismissTime.current === null
      ? true
      : Date.now() - dismissTime.current > SHOW_AGAIN_DELAY
      ? true
      : false;
  }

  function handleDismiss() {
    dismissTime.current = Date.now();
    onDismiss();
  }

  return shouldShow ? (
    <Alert className="NetworkStatusAlert" onDismiss={handleDismiss}>
      Network connection error
    </Alert>
  ) : null;
}
