import React, { Component } from "react";
import {
  Form,
  Checkbox,
  FormGroup,
  HelpBlock,
  FormControl,
} from "react-bootstrap";
import { testStageName } from "../lib/regexLib";
import Modal from "./Modal";
import LoaderButton from "./LoaderButton";
import GitBranchSelect from "./GitBranchSelect";
import SectionDescriptionLabel from "./SectionDescriptionLabel";
import "./AppAddStageModal.css";

const helpUrl = "https://seed.run/docs/adding-a-stage";

const noop = () => {};

const defaultStageId = "select stage";

const defaultProps = {
  type: "dev",
  show: false,
  stages: null,
  branches: null,
  adding: false,
  hasMore: false,
  isLoadingMore: false,
  isBranchChecked: false,
  isInheritChecked: false,
  onAddClick: noop,
  onCloseClick: noop,
  onLoadMoreClick: noop,
  onBranchCheck: noop,
  onInheritCheck: noop,
  onBranchUncheck: noop,
  onInheritUncheck: noop,
};

const defaultState = {
  name: "",
  dirty: false,
  validationError: null,
  branch: null,
  inheritStageId: null,
};

const typeCopyMap = {
  staging: "Staging",
  prod: "Production",
  dev: "Development",
};

class AppAddStageModal extends Component {
  state = { ...defaultState };

  handleFieldEdit = (event) => {
    const { id, value } = event.target;

    if (id === "name") {
      this.setState({
        dirty: true,
        validationError: null,
      });
    }

    if (id === "branch" && (!this.state.dirty || this.state.name === "")) {
      this.setState({
        dirty: false,
        name: value,
        validationError: null,
      });
    }

    this.setState({ [id]: value });
  };

  handleAddClick = async (event) => {
    event.preventDefault();

    const { name, branch, inheritStageId } = this.state;

    if (
      name.trim() === "" ||
      (this.props.isBranchChecked && !branch) ||
      (this.props.isInheritChecked && !inheritStageId)
    ) {
      return;
    }

    if (!testStageName(name)) {
      this.setState({ validationError: "error" });
      return;
    }

    await this.props.onAddClick(event, {
      name,
      type: this.props.type,
      branch: this.props.isBranchChecked ? branch : undefined,
      inherit_stage_id: this.props.isInheritChecked
        ? inheritStageId
        : undefined,
    });
  };

  handleBranchToggled = (event) => {
    const { checked } = event.target;
    checked
      ? this.props.onBranchCheck(event)
      : this.props.onBranchUncheck(event);
  };

  handleInheritToggled = (event) => {
    const { checked } = event.target;
    checked
      ? this.props.onInheritCheck(event)
      : this.props.onInheritUncheck(event);
  };

  handleCloseClick = (event) => {
    this.props.onCloseClick(event);
  };

  sortStages = (stages) => {
    return stages.sort((a, b) => {
      if (a.is_production) {
        return 1;
      } else if (b.is_production) {
        return -1;
      } else if (a.is_staging) {
        return 1;
      } else if (b.is_staging) {
        return -1;
      }
      return 0;
    });
  };

  renderForm() {
    const { name, branch, inheritStageId, validationError } = this.state;
    const {
      adding,
      stages,
      branches,
      isBranchChecked,
      isInheritChecked,
    } = this.props;
    const loadingBranches = branches === null;
    const noBranches = !loadingBranches && branches.length === 0;

    return (
      <Form onSubmit={this.handleAddClick}>
        <div>
          {/* Field: Stage Name */}
          <FormGroup
            className="name"
            controlId="name"
            validationState={validationError}
          >
            <FormControl
              type="text"
              value={name}
              bsSize="large"
              onChange={this.handleFieldEdit}
              placeholder={name === "" ? "Stage name" : undefined}
              disabled={adding}
            />
            {validationError && (
              <HelpBlock>
                Stage names must start with a letter or number and can only
                contain letters, numbers, and hyphens.
              </HelpBlock>
            )}
          </FormGroup>

          {/* Field: Branch Option */}
          <FormGroup controlId="branch" className="branch-option">
            <Checkbox
              checked={isBranchChecked}
              disabled={adding}
              onChange={this.handleBranchToggled}
            >
              Connect this stage to a branch
            </Checkbox>
            <HelpBlock>
              Enable this if you want to auto-deploy the stage when you push to
              a branch. You can always set this later.
            </HelpBlock>
          </FormGroup>
          {isBranchChecked && (
            <GitBranchSelect
              startOpened
              selectedBranch={branch}
              hasMore={this.props.hasMore}
              onChange={this.handleFieldEdit}
              isLoadingMore={this.props.isLoadingMore}
              onLoadMoreClick={this.props.onLoadMoreClick}
              branches={loadingBranches ? null : branches}
              disabled={noBranches || adding || loadingBranches}
            />
          )}

          <hr />

          {/* Field: Inherit Option */}
          <FormGroup controlId="inherit" className="inherit-option">
            <Checkbox
              checked={isInheritChecked}
              disabled={adding}
              onChange={this.handleInheritToggled}
            >
              Copy settings from an existing stage
            </Checkbox>
            <HelpBlock>
              Enable this if you want to configure this stage with the settings
              from another stage. You can configure them manually at any time.
            </HelpBlock>
          </FormGroup>
          {isInheritChecked && (
            <div className="inherit-select">
              <FormGroup controlId="inheritStageId">
                <FormControl
                  disabled={adding}
                  componentClass="select"
                  onChange={this.handleFieldEdit}
                  value={inheritStageId || defaultStageId}
                >
                  <option disabled value={defaultStageId}>
                    Select a stage to copy the settings from
                  </option>
                  {this.sortStages(stages).map((stage, i) => (
                    <option key={i} value={stage.stageId}>
                      {stage.name}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
              {inheritStageId && (
                <div className="inherit-copy">
                  <p>
                    The following settings will be copied from{" "}
                    <b>{inheritStageId}</b>:
                  </p>
                  <ul className="fa-ul">
                    <li>
                      <i className="fa-li fa fa-check"></i>
                      IAM settings
                    </li>
                    <li>
                      <i className="fa-li fa fa-check"></i>
                      Post-deploy phase
                    </li>
                    <li>
                      <i className="fa-li fa fa-check"></i>
                      Notification settings
                    </li>
                    <li>
                      <i className="fa-li fa fa-check"></i>
                      Environment variables
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </Form>
    );
  }

  render() {
    const { type, adding, branches } = this.props;
    const loadingBranches = branches === null;
    const noBranches = !loadingBranches && branches.length === 0;

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleCloseClick}
        className="AppAddStageModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a {typeCopyMap[type]} Stage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SectionDescriptionLabel>
            Add a new stage to your pipeline.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn about adding a stage to your app.
            </a>
          </SectionDescriptionLabel>
          {this.renderForm()}
        </Modal.Body>
        <Modal.Footer>
          <LoaderButton bsStyle="link" onClick={this.handleCloseClick}>
            Cancel
          </LoaderButton>
          <LoaderButton
            type="submit"
            bsSize="large"
            bsStyle="warning"
            className="btnAdd"
            loading={adding}
            disabled={noBranches}
            onClick={this.handleAddClick}
          >
            Add Stage
          </LoaderButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

AppAddStageModal.defaultProps = defaultProps;

export default AppAddStageModal;
