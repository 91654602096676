import React from "react";
import { Table } from "react-bootstrap";
import { formatDollars } from "../lib/stringLib";
import { dateToFullDate, dateToFullDateNoYear } from "../lib/timeLib";
import "./BillingHistoryTable.css";

export default function BillingHistoryTable({ invoices = [] }) {
  return (
    <Table className="BillingHistoryTable">
      <thead>
        <tr>
          <th>Billing Period</th>
          <th>Build Minutes</th>
          <th>Billed Amount</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice) => (
          <tr key={invoice.number}>
            <td>
              {dateToFullDateNoYear(invoice.start_at)}
              <span> &ndash; </span>
              {dateToFullDate(invoice.end_at)}
            </td>
            <td>{invoice.minutesUsed}</td>
            <td>
              <span>$</span>
              {formatDollars(invoice.amount)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
