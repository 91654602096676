import React, { useRef, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import ErrorAlert from "../components/ErrorAlert";
import { TabEnum } from "../components/ResourcesPanel";
import SectionHeader from "../components/SectionHeader";
import EmptyListPanel from "../components/EmptyListPanel";
import LoadingSpinner from "../components/LoadingSpinner";
import ApiResourceList from "../components/ApiResourceList";
import StagesSelectList from "../components/StagesSelectList";
import WebsiteResourceList from "../components/WebsiteResourceList";
import ServiceResourceList from "../components/ServiceResourceList";
import PermissionErrorPanel from "../components/PermissionErrorPanel";
import {
  querystring,
  getAppStageUrl,
  getAppStageRemoveUrl,
  buildQueryStringUrl,
} from "../lib/urlLib";
import title from "../lib/titleLib";
import useAPILoad, { cacheGet } from "../lib/apiLoadLib";
import {
  isAwsPermissionError,
  errorHandler,
  getLoadError,
} from "../lib/errorLib";
import "./Stages.css";

const defaultTab = TabEnum.lambdas;

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
  StageNotExist: "STAGE_NOT_FOUND",
};

function getResourcesQsFromState(state) {
  return Object.keys(state).join(",");
}

function expandedResourceStateToQs(state) {
  const ids = Object.keys(state);

  if (ids.length === 0) {
    return {};
  }

  return {
    resources: ids.map((id) => `${id}:${state[id].tab}`).join(","),
  };
}

function getExpandedResourceState(qsResources, updatingLogs) {
  if (qsResources) {
    let state = {};

    let resources = qsResources.split(",");

    resources.forEach((resource) => {
      const [serviceId, tab = defaultTab] = resource.split(":");

      state[serviceId] = {
        tab,
        info: null,
        updatingLogs: updatingLogs[serviceId] || false,
      };
    });

    return state;
  }

  return null;
}

function Stages(props) {
  const { ownerId, appId, appStageId } = props.match.params;
  const qsResources = querystring("resources", props.location.search);

  let isLoading = true;
  let loadError = null;
  let loadPermissionError = null;
  let apiError = null;

  let serviceResourcesInfo = null;
  let isStageRemovingOrRemoveFailed = false;
  let isDeployed = false;
  let noServices = false;

  let firstProdStage = null;

  // Used to cache previously loaded info, see below for optimization
  const prevResourceInfoUri = useRef(null);

  const [updatingLogs, setUpdatingLogs] = useState({});
  const [updatingLogsPermissionError, setUpdatingLogsError] = useState(null);

  // State for which service's resources are being shown and in which tab
  // Format:
  // {
  //    serviceId: {
  //      tab: < tab name >
  //      info: < API data >
  //      updatingLogs: < boolean >
  //    }
  // }
  let expandedResourceState = expandedResourceReducer({
    qsResources,
    updatingLogs,
    type: "init",
  });

  const resourceInfoUri =
    qsResources &&
    `/${ownerId}/${appId}/stages/${appStageId}/resources?serviceNames=${getResourcesQsFromState(
      expandedResourceState
    )}&version=v20200805`;

  //////////
  // Load //
  //////////

  useEffect(() => {
    document.title = appStageId ? title(appStageId) : title("Resources");
  }, [appStageId]);

  const { data: appInfo, error: appError } = useAPILoad(
    `/${ownerId}/${appId}`,
    (path) => props.invokeAppsApig({ path }),
    { polling: false }
  );

  const { data: appStageInfo, error: appStageError } = useAPILoad(
    appStageId && `/${ownerId}/${appId}/stages/${appStageId}`,
    (path) => props.invokeAppsApig({ path })
  );

  const {
    data: apisInfo,
    error: apisError,
    reload: reloadApiInfo,
  } = useAPILoad(
    appStageId && `/${ownerId}/${appId}/stages/${appStageId}/resources/apis`,
    { polling: false }
  );

  const {
    data: resourceInfo,
    error: resourcesError,
    reload: reloadResourcesInfo,
  } = useAPILoad(resourceInfoUri, { polling: false });

  if (appStageId) {
    isLoading = !(
      (appStageInfo !== null || appStageError) &&
      (appInfo !== null || appError) &&
      (apisInfo !== null || apisError)
    );
  } else {
    isLoading = appError === null;
  }

  if (appStageInfo !== null) {
    isDeployed = !!appStageInfo.stage.latestBuildId;
    noServices = appStageInfo.services.length === 0;

    // Case 1: deleting or delete_failed => redirect
    if (
      appStageInfo.stage.status === "deleting" ||
      appStageInfo.stage.status === "delete_failed"
    ) {
      isStageRemovingOrRemoveFailed = true;
    }
    // Case 2: normal case
    else {
      serviceResourcesInfo = formatServiceResources(appStageInfo);
    }
  }

  if (appInfo) {
    const first = appInfo.stages.find((stage) => stage.is_production);
    firstProdStage = first && first.name;
  }

  // If there are:
  // - no services is specified in query string
  // - no API endpoint, and
  // - only 1 service, and
  // - the service's stage has stack name
  // then auto expand the service
  if (
    !resourceInfoUri &&
    apisInfo &&
    apisInfo.apis.length === 0 &&
    appStageInfo &&
    appStageInfo.services.length === 1 &&
    appStageInfo.services[0].stage.active_deployment
  ) {
    props.history.replace(
      buildUrl("show", appStageInfo.services[0].service.name)
    );
  }

  // Optimization:
  // Handle case where showing different resources will reload all the data
  //
  // Check if something was previously loaded,
  // then load the cached response.
  // And filter the service resources we currently need.
  if (
    resourceInfoUri &&
    resourceInfo === null &&
    prevResourceInfoUri.current !== null &&
    cacheGet(prevResourceInfoUri.current)
  ) {
    let cachedData = cacheGet(prevResourceInfoUri.current);

    for (let serviceId in expandedResourceState) {
      if (cachedData.resources[serviceId]) {
        expandedResourceState = expandedResourceReducer({
          serviceId,
          type: "load",
          info: cachedData.resources[serviceId],
        });
      }
    }
  }
  // When the real response arrives
  // load it just as usual.
  else if (resourceInfo !== null) {
    for (let serviceId in resourceInfo.resources) {
      expandedResourceState = expandedResourceReducer({
        serviceId,
        type: "load",
        info: resourceInfo.resources[serviceId],
      });
    }

    // Save loaded uri
    prevResourceInfoUri.current = resourceInfoUri;
  }

  // handle errors
  if (appError) {
    loadError = getLoadError(appError, loadErrorCodes);
  } else if (appStageError) {
    loadError = getLoadError(appStageError, loadErrorCodes);
  }

  if (apisError && isAwsPermissionError(apisError)) {
    loadPermissionError = apisError;
  } else if (resourcesError && isAwsPermissionError(resourcesError)) {
    loadPermissionError = resourcesError;
  }

  apiError = loadError
    ? null
    : appError ||
      appStageError ||
      apisError ||
      resourcesError ||
      loadPermissionError;

  ///////////////
  // Functions //
  ///////////////

  function expandedResourceReducer(action) {
    switch (action.type) {
      case "init":
        return getExpandedResourceState(
          action.qsResources,
          action.updatingLogs
        );
      case "load":
        return {
          ...expandedResourceState,
          [action.serviceId]: {
            ...expandedResourceState[action.serviceId],
            info: action.info,
          },
        };
      case "show":
        return {
          ...expandedResourceState,
          [action.serviceId]: {
            info: null,
            tab: action.tab,
            updatingLogs: false,
          },
        };
      case "hide":
        let newState = { ...expandedResourceState };
        delete newState[action.serviceId];
        return newState;
      default:
        return expandedResourceState;
    }
  }

  function buildUrl(action, serviceId, tab = defaultTab) {
    if (action === "show") {
      expandedResourceState = expandedResourceReducer({
        tab,
        serviceId,
        type: "show",
      });
    } else {
      expandedResourceState = expandedResourceReducer({
        serviceId,
        type: "hide",
      });
    }

    return buildQueryStringUrl(
      getAppStageUrl(ownerId, appId, appStageId),
      expandedResourceStateToQs(expandedResourceState)
    );
  }

  /////////
  // API //
  /////////

  function updateStageLogs(serviceId, access_log_enabled) {
    return props.invokeStagesApig({
      path: `/${ownerId}/${appId}/services/${serviceId}/stages/${appStageId}`,
      method: "PUT",
      body: { access_log_enabled },
    });
  }

  function formatServiceResources(appStageInfo) {
    return appStageInfo.services.map(({ service, stage }) => ({
      serviceName: service.name,
      serviceType: service.serviceType,
      serviceStatus: service.status,
      activeDeployment: stage.active_deployment,
      region: stage.region,
      // Sls services
      stackName: stage.stack,
      apiEndpoint: stage.domain_endpoint || stage.endpoint,
      // Sst services
      stackNames: stage.stacks,
      // Component services
      slsComponent: service.slsComponent,
    }));
  }

  //////////////
  // Handlers //
  //////////////

  function handleServiceResourceClick(serviceId, action, tab) {
    props.history.replace(buildUrl(action, serviceId, tab));
  }

  async function handleUpdateAccessLogClick(serviceId, enabled) {
    setUpdatingLogs({
      ...updatingLogs,
      [serviceId]: true,
    });

    try {
      await updateStageLogs(serviceId, enabled);
      await reloadApiInfo();
      await reloadResourcesInfo();
    } catch (e) {
      errorHandler(e);

      if (isAwsPermissionError(e)) {
        setUpdatingLogsError(e);
      }
    }

    setUpdatingLogs((prevUpdatingLogs) => ({
      ...prevUpdatingLogs,
      [serviceId]: false,
    }));
  }

  function handleServiceResourceTabClick(serviceId, view) {
    props.history.replace(buildUrl("show", serviceId, view));
  }

  ////////////
  // Render //
  ////////////

  function renderBody() {
    return (
      <>
        {apisInfo.apis.length > 0 && (
          <ApiResourceList
            apis={apisInfo.apis}
            updatingLogs={updatingLogs}
            pathParams={{ appId, ownerId, appStageId }}
            onUpdateAccessLogClick={handleUpdateAccessLogClick}
          />
        )}
        {apisInfo.websiteUrls.length > 0 && (
          <WebsiteResourceList urls={apisInfo.websiteUrls} />
        )}
        {(apisInfo.apis.length > 0 || apisInfo.websiteUrls.length > 0) && (
          <div className="services-separator">
            <hr />
            <SectionHeader>All Deployed Services</SectionHeader>
          </div>
        )}
        <ServiceResourceList
          services={serviceResourcesInfo}
          resourcesState={expandedResourceState}
          pathParams={{ appId, ownerId, appStageId }}
          onTabClick={handleServiceResourceTabClick}
          onUpdateAccessLogClick={handleUpdateAccessLogClick}
          onHideResourcesClick={(id) => handleServiceResourceClick(id, "hide")}
          onShowResourcesClick={(id, tab) =>
            handleServiceResourceClick(id, "show", tab)
          }
        />
      </>
    );
  }

  return (
    <div className="Stages">
      <PermissionErrorPanel
        type={
          loadPermissionError
            ? "resources"
            : updatingLogsPermissionError
            ? "accessLog"
            : ""
        }
        error={updatingLogsPermissionError || loadPermissionError}
      >
        {updatingLogsPermissionError
          ? "Add a couple of permissions to enable access logs"
          : loadPermissionError
          ? "Add a couple of permissions to view resources"
          : ""}
      </PermissionErrorPanel>

      {apiError && !loadError && <ErrorAlert error={apiError} />}

      {!appStageId && (
        <>
          {!isLoading && loadError && (
            <ContainerErrorPanel
              type="app"
              code={loadError}
              context={{
                name: appId,
              }}
            />
          )}
          {firstProdStage && (
            <Redirect to={getAppStageUrl(ownerId, appId, firstProdStage)} />
          )}
        </>
      )}

      {!isLoading && isStageRemovingOrRemoveFailed && (
        <Redirect to={getAppStageRemoveUrl(ownerId, appId, appStageId)} />
      )}

      {!isStageRemovingOrRemoveFailed && (
        <div className="cols">
          <div className="col1">
            <StagesSelectList stages={appInfo && appInfo.stages} />
          </div>
          <div className="col2">
            {(isLoading || apiError) && <LoadingSpinner />}

            {!isLoading && loadError && (
              <ContainerErrorPanel
                type="stage"
                code={loadError}
                context={{
                  appName: appId,
                  name: appStageId,
                }}
              />
            )}

            {!apiError && !isLoading && !loadError && (
              <>
                {noServices && (
                  <EmptyListPanel message="There are no services in this app. Add a service to get started!" />
                )}

                {!noServices && !isDeployed && (
                  <EmptyListPanel message="There are no resources in this stage. Trigger a deployment to get started!" />
                )}

                {!noServices &&
                  isDeployed &&
                  appStageInfo &&
                  apisInfo &&
                  renderBody()}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(Stages));
