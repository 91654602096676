import React, { useState, useEffect } from "react";
import EnforceTwoFactorSettingPanel from "../components/EnforceTwoFactorSettingPanel";
import withAppHeader from "../components/ComponentWithAppHeader";
import OrgMembersPanel from "../components/OrgMembersPanel";
import withCancel from "../components/ComponentWithCancel";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorAlert from "../components/ErrorAlert";
import { errorHandler } from "../lib/errorLib";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import "./OrgMembers.css";

function OrgMembers(props) {
  let error = null;
  let isLoading = true;

  const { ownerId } = props.match.params;

  const [updatingEnforce2fa, setUpdatingEnforce2fa] = useState(false);

  const [addingMember, setAddingMember] = useState(false);
  const [removingMember, setRemovingMember] = useState({});
  const [updatingMember, setUpdatingMember] = useState({});
  const [showingNewForm, setShowingNewForm] = useState(false);

  useEffect(() => {
    document.title = title("Settings");
  }, []);

  const {
    data: ownerInfo,
    error: ownerError,
    reload: reloadOwner,
  } = useAPILoad(getOwnerAPI(), (path) => props.invokeAppsApig({ path }));
  const {
    data: orgMembers,
    error: orgError,
    reload: reloadOrgMembers,
  } = useAPILoad(getMembersAPI());

  isLoading = ownerInfo && orgMembers ? false : true;

  error = orgError || ownerError;

  function getOwnerAPI() {
    return `/${ownerId}`;
  }

  function getMembersAPI() {
    return `/orgs/${ownerId}/members`;
  }

  /////////
  // API //
  /////////

  function addMember(email, permissions, appPermissions) {
    return props.invokeApig({
      path: `${getMembersAPI()}?version=v20200829`,
      method: "POST",
      body: {
        email,
        permissions: JSON.stringify(permissions),
        appPermissions: JSON.stringify(appPermissions),
      },
    });
  }

  function editMember(email, permissions, appPermissions) {
    return props.invokeApig({
      path: `${getMembersAPI()}?version=v20200829`,
      method: "PUT",
      body: {
        email,
        permissions: JSON.stringify(permissions),
        appPermissions: JSON.stringify(appPermissions),
      },
    });
  }

  function removeMember(email) {
    return props.invokeApig({
      path: getMembersAPI(),
      method: "DELETE",
      body: { email },
    });
  }

  function updateOrg(data) {
    return props.invokeApig({
      method: "PUT",
      body: data,
      path: `/orgs/${ownerId}`,
    });
  }

  //////////////
  // Handlers //
  //////////////

  function handleAddMemberFormClick() {
    setShowingNewForm(true);
  }

  function handleAddMemberFormCancelClick() {
    setShowingNewForm(false);
  }

  async function handleAddMemberClick(email, permissions, appPermissions) {
    setAddingMember(true);

    try {
      await addMember(email, permissions, appPermissions);
      await reloadOrgMembers();

      setAddingMember(false);
      setShowingNewForm(false);
    } catch (e) {
      errorHandler(e);
      setAddingMember(false);
    }
  }

  async function handleEditMemberClick(email, permissions, appPermissions) {
    setUpdatingMember({ ...updatingMember, [email]: true });

    try {
      await editMember(email, permissions, appPermissions);
      await reloadOrgMembers();
    } catch (e) {
      errorHandler(e);
    }

    setUpdatingMember({ ...updatingMember, [email]: false });
  }

  async function handleRemoveMemberClick(email) {
    setRemovingMember({ ...removingMember, [email]: true });

    try {
      const ret = await removeMember(email);

      // redirect to home if user removed itself
      if (ret.isSessionUser) {
        props.history.push("/");
        return;
      }

      await reloadOrgMembers();
    } catch (e) {
      errorHandler(e);
    }

    setRemovingMember({ ...removingMember, [email]: false });
  }

  async function handleUpdateEnforce2fa(enforce2fa) {
    if (
      !enforce2fa &&
      !window.confirm(
        "Are you sure you want to disable mandatory two-factor auth for your team?"
      )
    ) {
      return;
    }

    setUpdatingEnforce2fa(true);

    try {
      await updateOrg({ enforce2fa });
      await reloadOwner();

      setUpdatingEnforce2fa(false);

      // Update user info setting in App to update orgs with enforced 2fa
      await props.loadUserInfo();
    } catch (e) {
      errorHandler(e);
      setUpdatingEnforce2fa(false);
    }
  }

  ////////////
  // Render //
  ////////////

  return (
    <div className="OrgMembers">
      {isLoading && <LoadingSpinner />}

      {error && <ErrorAlert error={error} />}

      {!isLoading && (
        <div className="columns">
          <OrgMembersPanel
            owner={ownerId}
            members={orgMembers}
            adding={addingMember}
            removing={removingMember}
            updating={updatingMember}
            showingNewForm={showingNewForm}
            seatsLimit={orgMembers.org.seatsLimit}
            apps={ownerInfo.apps.map(({ slug }) => slug)}
            isRbacAvailable={orgMembers.org.isRbacAvailable}
            onAddClick={handleAddMemberClick}
            onUpdateClick={handleEditMemberClick}
            onRemoveClick={handleRemoveMemberClick}
            onShowClick={handleAddMemberFormClick}
            onCancelClick={handleAddMemberFormCancelClick}
          />
          <EnforceTwoFactorSettingPanel
            owner={ownerId}
            updating={updatingEnforce2fa}
            isEnabled={orgMembers.org.enforce2fa}
            onEnableClick={() => handleUpdateEnforce2fa(true)}
            onDisableClick={() => handleUpdateEnforce2fa(false)}
            isFeatureUnavailable={!orgMembers.org.isEnforce2faAvailable}
          />
        </div>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(OrgMembers));
