import React, { Component } from "react";
import title from "../lib/titleLib";
import withCancel from "../components/ComponentWithCancel";
import UsernameUpdate from "./widgets/UsernameUpdate";
import ScreenHeader from "../components/ScreenHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import { errorHandler } from "../lib/errorLib";
import { userSettingsBreadcrumb } from "../lib/breadcrumbLib";
import "./UsernameSettings.css";

class UsernameSettings extends Component {
  state = { user: null };

  async componentDidMount() {
    document.title = title("Username");

    try {
      const { user } = await this.getUserInfo();

      this.setState({ user });
    } catch (e) {
      errorHandler(e);
    }
  }

  getUserInfo() {
    return this.props.invokeApig({ path: "/" });
  }

  updateUserInfo(username) {
    return this.props.invokeApig({
      path: "",
      method: "PUT",
      body: { username },
    });
  }

  handleUsernameUpdate = async (username) => {
    await this.updateUserInfo(username);

    this.props.history.push("/settings");
  };

  render() {
    const { user } = this.state;

    return (
      <div className="UsernameSettings">
        <ScreenHeader border breadcrumb={userSettingsBreadcrumb(this.props)}>
          Update Your Username
        </ScreenHeader>

        {!this.state.user && <LoadingSpinner />}

        {user && (
          <>
            {user.username && (
              <p className="current">
                We use your username to namespace your apps.&nbsp; Your username
                is currently set to:&nbsp;
                <b>{`${user.username}`}</b>
              </p>
            )}
            {!user.username && (
              <p className="current">
                Please pick a username.&nbsp; Your username is used to namespace
                your apps.
              </p>
            )}
            <UsernameUpdate
              username={user.username}
              onUpdate={this.handleUsernameUpdate}
            />
          </>
        )}
      </div>
    );
  }
}

export default withCancel(UsernameSettings);
