import React, { Component } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { testEmail } from "../lib/regexLib";
import { errorHandler } from "../lib/errorLib";
import LoaderButton from "./LoaderButton";
import "./UpdateEmailForm.css";

const noop = () => {};

const defaultProps = {
  email: "",
  isUpdating: false,
  onCancelClick: noop,
  onEmailUpdate: noop,
};

const defaultState = {
  formData: {
    email: "",
  },
  validation: {},
};

export default class UpdateEmailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      formData: {
        ...defaultState.formData,
        email: props.email,
      },
    };
  }

  canSubmit() {
    return (
      this.props.email.toLowerCase() !==
      this.state.formData.email.trim().toLowerCase()
    );
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      validation: {
        ...this.state.validation,
        [id]: null,
      },
    });
  };

  validateFields(formData) {
    formData.email = formData.email.trim();

    const isEmailValid = testEmail(formData.email);

    if (!isEmailValid) {
      const validation = {};
      if (!isEmailValid) {
        validation.email = "error";
      }

      this.setState({
        validation: {
          ...this.state.validation,
          ...validation,
        },
      });

      return false;
    }

    return formData;
  }

  handleUpdateClick = async (event) => {
    event.preventDefault();

    if (!this.canSubmit()) {
      return;
    }

    const validatedFields = this.validateFields(this.state.formData);

    if (validatedFields === false) {
      return;
    }

    const { email } = validatedFields;

    if (email) {
      try {
        await this.props.onEmailUpdate(email.toLowerCase());
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  handleCancelClick = (event) => {
    this.props.onCancelClick();
  };

  render() {
    const { formData, validation } = this.state;
    const { isUpdating } = this.props;

    return (
      <form className="UpdateEmailForm" onSubmit={this.handleUpdateClick}>
        <FormGroup
          bsSize="large"
          controlId="email"
          validationState={validation.email}
        >
          <FormControl
            type="email"
            value={formData.email}
            placeholder="me@example.com"
            onChange={this.handleFieldChange}
          />
          {validation.email && (
            <HelpBlock>Please enter a valid email address.</HelpBlock>
          )}
        </FormGroup>
        <div className="controls">
          <LoaderButton
            type="submit"
            bsSize="large"
            loading={isUpdating}
            disabled={!this.canSubmit()}
            onClick={this.handleUpdateClick}
          >
            Update Email
          </LoaderButton>
          <LoaderButton
            bsStyle="link"
            disabled={isUpdating}
            onClick={this.handleCancelClick}
          >
            Hide Update Email
          </LoaderButton>
        </div>
      </form>
    );
  }
}

UpdateEmailForm.defaultProps = defaultProps;
