import React, { Component, Fragment } from "react";
import { Form, HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import SectionDescriptionLabel from "./SectionDescriptionLabel";
import NewIamRoleHelpButton from "./NewIamRoleHelpButton";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import SectionInfo from "./SectionInfo";
import { trimFields } from "../lib/formLib";
import "./StageIamPanel.css";

const helpUrl = "https://seed.run/docs/iam-credentials-per-stage";

const noop = () => {};

const defaultProps = {
  iamRole: false,
  iamAccess: false,

  policyInfoLoading: false,
  onShowIamRoleClick: noop,

  editing: false,
  saving: false,
  removing: false,

  onEditClick: noop,
  onSaveClick: noop,
  onHideClick: noop,
  onRemoveClick: noop,

  // Flag to trigger clearing the form
  clearForm: 0,
};

const defaultState = {
  isFormDirty: false,
  formData: {},
  validation: {},
  clearForm: 0,
};

class StageIamPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      formData: {
        iam_role: props.iamRole === false ? "" : props.iamRole,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.clearForm !== prevState.clearForm) {
      return {
        clearForm: nextProps.clearForm,
        formData: {
          iam_role: nextProps.iamRole === false ? "" : nextProps.iamRole,
        },
      };
    }
    return null;
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      isFormDirty: true,
      validation: {
        ...this.state.validation,
        [id]: null,
      },
    });
  };

  handleSaveClick = async (event) => {
    event.preventDefault();

    if (this.state.isFormDirty === false) {
      return;
    }

    const { iam_role } = trimFields(this.state.formData);

    if (iam_role === "") {
      this.setState({
        validation: {
          ...this.state.validation,
          iam_role: "error",
        },
      });
      return;
    }

    await this.props.onSaveClick(event, { iam_role });
  };

  renderCollapsed() {
    const { iamRole, iamAccess } = this.props;

    return (
      <Fragment>
        <LoaderButton
          bsSize="large"
          className="btnUpdate"
          onClick={this.props.onEditClick}
          loading={false}
          text={
            iamAccess === false && iamRole === false
              ? "Use Custom IAM"
              : "Edit Custom IAM"
          }
        />

        {(iamAccess !== false || iamRole !== false) && (
          <LoaderButton
            bsSize="large"
            className="btnDefault"
            onClick={this.props.onRemoveClick}
            loading={this.props.removing}
            text="Use Default IAM"
          />
        )}
      </Fragment>
    );
  }

  renderForm() {
    const { formData, validation } = this.state;
    const { policyInfoLoading, onShowIamRoleClick } = this.props;

    return (
      <div>
        <Form onSubmit={this.handleAddClick}>
          <FormGroup controlId="iam_role" validationState={validation.iam_role}>
            <div className="label-container">
              <StyledControlLabel>IAM Role</StyledControlLabel>
              <NewIamRoleHelpButton
                loading={policyInfoLoading}
                onClick={onShowIamRoleClick}
                className="btnShowIamRolePanel"
              />
            </div>
            <FormControl
              type="text"
              bsSize="large"
              value={formData.iam_role}
              onChange={this.handleFieldChange}
            />
            {validation.iam_role && (
              <HelpBlock>Please enter a valid IAM Role.</HelpBlock>
            )}
          </FormGroup>
          <div className="controls">
            <LoaderButton
              text="Update"
              type="submit"
              bsSize="large"
              loading={this.props.saving}
              onClick={this.handleSaveClick}
            />
            <LoaderButton
              bsStyle="link"
              text="Hide IAM Info"
              onClick={this.props.onHideClick}
            />
          </div>
        </Form>
      </div>
    );
  }

  render() {
    const { editing, iamRole, iamAccess } = this.props;
    const disabledCs = editing
      ? ""
      : iamAccess === false && iamRole === false
      ? "disabled"
      : "";

    return (
      <div className="StageIamPanel">
        <SectionInfo
          label="AWS IAM Info"
          description={
            <span>
              Configure this stage to use its own IAM credentials.&nbsp;
              <a target="_blank" href={helpUrl} rel="noopener noreferrer">
                Learn more.
              </a>
            </span>
          }
        >
          <SectionDescriptionLabel
            className={`${editing ? "editing" : ""} ${disabledCs}`}
          >
            {editing === false
              ? iamAccess === false && iamRole === false
                ? "Using the default app IAM credentials."
                : "Using custom IAM credentials for this stage."
              : iamAccess === false && iamRole === false
              ? "Set custom IAM credentials for this stage."
              : "Edit custom IAM credentials for this stage."}
          </SectionDescriptionLabel>
          {iamAccess && !iamRole && (
            <SectionDescriptionLabel>
              Deploying through an IAM user has been <b>deprecated</b>. Set an
              IAM role to replace the current IAM user: {iamAccess}
            </SectionDescriptionLabel>
          )}
          {editing === false && this.renderCollapsed()}
          {editing === true && this.renderForm()}
        </SectionInfo>
      </div>
    );
  }
}

StageIamPanel.defaultProps = defaultProps;

export default StageIamPanel;
