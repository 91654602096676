import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import TextButton from "./TextButton";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import ButtonCheckbox from "./ButtonCheckbox";
import { getOrgBillingUrl } from "../lib/urlLib";
import config from "../config";
import "./MemberRoleModal.css";

const userPermissions = config.userPermissions;

const permissionList = {
  [userPermissions.admin.value]: false,
  [userPermissions.billing.value]: false,
  [userPermissions.dev_manage.value]: false,
  [userPermissions.dev_deploy.value]: false,
  [userPermissions.staging_manage.value]: false,
  [userPermissions.staging_deploy.value]: false,
  [userPermissions.staging_promote.value]: false,
  [userPermissions.prod_manage.value]: false,
  [userPermissions.prod_deploy.value]: false,
  [userPermissions.prod_promote.value]: false,
};

function readPermissions(permissions) {
  const list = { ...permissionList };

  permissions.forEach((permission) => (list[permission] = true));

  return Object.keys(list).map((permission) => list[permission]);
}

export const MemberRoleModalModes = {
  org: "org",
  app: "app",
};

export default function MemberRoleModal({
  show,
  onDoneClick,
  onCloseClick,
  owner = null,
  isRbacAvailable = true,
  mode = MemberRoleModalModes.org,
  initialPermissions = [userPermissions.admin.value],
}) {
  const [permissions, setPermissions] = useState(initialPermissions);

  const restrictedCs = !isRbacAvailable ? "restricted" : "";

  const [
    isAdmin,
    isBilling,
    isDevManage,
    isDevDeploy,
    isStagingManage,
    isStagingDeploy,
    isStagingPromote,
    isProdManage,
    isProdDeploy,
    isProdPromote,
  ] = readPermissions(permissions);

  const devSummary = summarize("dev", {
    deploy: isDevDeploy,
    manage: isDevManage,
  });
  const stagingSummary = summarize("staging", {
    deploy: isStagingDeploy,
    promote: isStagingPromote,
    manage: isStagingManage,
  });
  const prodSummary = summarize("prod", {
    deploy: isProdDeploy,
    promote: isProdPromote,
    manage: isProdManage,
  });

  function summarize(type, permissions) {
    const isDev = type === "dev";

    const permissionsKeys = Object.keys(permissions);
    const selected = permissionsKeys.filter(
      (permission) => permissions[permission]
    );

    if (selected.length === 0) {
      return isDev ? [false, type] : [false, type];
    } else if (selected.length === permissionsKeys.length) {
      return [true, `${type}:*`];
    }

    let permissionCopy = `${type}:`;

    for (let i = 0, l = selected.length; i < l; i++) {
      permissionCopy += (i === 0 ? "" : ",") + selected[i];
    }

    return [true, permissionCopy];
  }

  function checkedCs(flag) {
    return flag ? "checked" : "";
  }

  function canSubmit() {
    return permissions.length > 0;
  }

  function handleCheckboxChange(event) {
    const { id, checked } = event.target;

    const callback = checked
      ? handlePermissionsSelected
      : handlePermissionsDeselected;

    switch (id) {
      case userPermissions.admin.value:
        handlePermissionsDeselected(userPermissions.dev_deploy.value);
        handlePermissionsDeselected(userPermissions.dev_manage.value);
        handlePermissionsDeselected(userPermissions.staging_deploy.value);
        handlePermissionsDeselected(userPermissions.staging_promote.value);
        handlePermissionsDeselected(userPermissions.staging_manage.value);
        handlePermissionsDeselected(userPermissions.prod_deploy.value);
        handlePermissionsDeselected(userPermissions.prod_promote.value);
        handlePermissionsDeselected(userPermissions.prod_manage.value);
        handlePermissionsDeselected(userPermissions.billing.value);
        callback(id);
        break;
      case "dev_all":
        handlePermissionsDeselected(userPermissions.admin.value);
        callback(userPermissions.dev_deploy.value);
        callback(userPermissions.dev_manage.value);
        break;
      case "staging_all":
        handlePermissionsDeselected(userPermissions.admin.value);
        callback(userPermissions.staging_deploy.value);
        callback(userPermissions.staging_promote.value);
        callback(userPermissions.staging_manage.value);
        break;
      case "prod_all":
        handlePermissionsDeselected(userPermissions.admin.value);
        callback(userPermissions.prod_deploy.value);
        callback(userPermissions.prod_promote.value);
        callback(userPermissions.prod_manage.value);
        break;
      default:
        handlePermissionsDeselected(userPermissions.admin.value);
        callback(id);
        break;
    }
  }

  function handlePermissionsSelected(permission) {
    setPermissions((prevPermissions) => {
      const newPermissions = [...prevPermissions];
      newPermissions.push(permission);
      return newPermissions;
    });
  }

  function handlePermissionsDeselected(permission) {
    setPermissions((prevPermissions) => {
      let newPermissions = [...prevPermissions];
      newPermissions = newPermissions.filter((p) => p !== permission);
      return newPermissions;
    });
  }

  function handleDoneClick() {
    onDoneClick(permissions);
  }

  function renderRolePanel() {
    return (
      <div className="role-panel">
        <div className="roles">
          <SectionHeader>Complete Access</SectionHeader>
          <div className={`role admin ${checkedCs(isAdmin)}`}>
            <div className="check">
              <ButtonCheckbox
                id="admin"
                checked={isAdmin}
                onChange={handleCheckboxChange}
              >
                {userPermissions.admin.name}
              </ButtonCheckbox>
            </div>
            <p>
              {mode === MemberRoleModalModes.org ? (
                <>
                  Complete access to <b>billing</b>, <b>creating</b>, and{" "}
                  <b>removing</b> apps.
                </>
              ) : (
                <>
                  Complete access to the <b>entire</b> app.
                </>
              )}
            </p>
          </div>
          {!isRbacAvailable && (
            <>
              <p className="restricted-copy">
                Please{" "}
                <Link to={getOrgBillingUrl(owner)}>
                  upgrade to the Enterprise plan
                </Link>{" "}
                to enable role-based access.
              </p>
            </>
          )}
          <div className={restrictedCs}>
            <SectionHeader>Restricted Access</SectionHeader>
            <div className={`role member dev ${checkedCs(devSummary[0])}`}>
              <div className="summary">
                <div className="check">
                  <ButtonCheckbox
                    id="dev_all"
                    checked={devSummary[0]}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    {devSummary[1]}
                  </ButtonCheckbox>
                </div>
                <p>
                  Allow them to <b>manage</b>, and <b>deploy</b> to the
                  development stages.
                </p>
              </div>
              <div className="details">
                <div className="check">
                  <ButtonCheckbox
                    id="dev_deploy"
                    checked={isDevDeploy}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    Deploy
                  </ButtonCheckbox>
                </div>
                <div className="check">
                  <ButtonCheckbox
                    id="dev_manage"
                    checked={isDevManage}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    Manage
                  </ButtonCheckbox>
                </div>
              </div>
            </div>
            <div
              className={`role member staging ${checkedCs(stagingSummary[0])}`}
            >
              <div className="summary">
                <div className="check">
                  <ButtonCheckbox
                    id="staging_all"
                    checked={stagingSummary[0]}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    {stagingSummary[1]}
                  </ButtonCheckbox>
                </div>
                <p>
                  Allow them to <b>manage</b>, <b>deploy</b>, and <b>promote</b>{" "}
                  to the staging stages.
                </p>
              </div>
              <div className="details">
                <div className="check">
                  <ButtonCheckbox
                    id="staging_deploy"
                    checked={isStagingDeploy}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    Deploy
                  </ButtonCheckbox>
                </div>
                <div className="check">
                  <ButtonCheckbox
                    id="staging_promote"
                    checked={isStagingPromote}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    Promote
                  </ButtonCheckbox>
                </div>
                <div className="check">
                  <ButtonCheckbox
                    id="staging_manage"
                    checked={isStagingManage}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    Manage
                  </ButtonCheckbox>
                </div>
              </div>
            </div>
            <div className={`role member prod ${checkedCs(prodSummary[0])}`}>
              <div className="summary">
                <div className="check">
                  <ButtonCheckbox
                    id="prod_all"
                    checked={prodSummary[0]}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    {prodSummary[1]}
                  </ButtonCheckbox>
                </div>
                <p>
                  Allow them to <b>manage</b>, <b>deploy</b>, and <b>promote</b>{" "}
                  to the production stages.
                </p>
              </div>
              <div className="details">
                <div className="check">
                  <ButtonCheckbox
                    id="prod_deploy"
                    checked={isProdDeploy}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    Deploy
                  </ButtonCheckbox>
                </div>
                <div className="check">
                  <ButtonCheckbox
                    id="prod_promote"
                    checked={isProdPromote}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    Promote
                  </ButtonCheckbox>
                </div>
                <div className="check">
                  <ButtonCheckbox
                    id="prod_manage"
                    checked={isProdManage}
                    disabled={!isRbacAvailable}
                    onChange={handleCheckboxChange}
                  >
                    Manage
                  </ButtonCheckbox>
                </div>
              </div>
            </div>
            {mode === MemberRoleModalModes.org && (
              <>
                <SectionHeader>Billing Access</SectionHeader>
                <div className={`role billing ${checkedCs(isBilling)}`}>
                  <div className="check">
                    <ButtonCheckbox
                      id="billing"
                      checked={isBilling}
                      disabled={!isRbacAvailable}
                      onChange={handleCheckboxChange}
                    >
                      {userPermissions.billing.name}
                    </ButtonCheckbox>
                  </div>
                  <p>
                    Allow them to access <b>billing</b>.
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal show={show} onHide={onCloseClick} className="MemberRoleModal">
      <Modal.Header closeButton>
        <Modal.Title>Select a Member Role</Modal.Title>
      </Modal.Header>

      <Modal.Body>{renderRolePanel()}</Modal.Body>

      <Modal.Footer>
        <TextButton onClick={onCloseClick}>Cancel</TextButton>
        <LoaderButton
          bsSize="large"
          disabled={!canSubmit()}
          onClick={handleDoneClick}
        >
          Done
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
