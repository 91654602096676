import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import withAppHeader from "../components/ComponentWithAppHeader";
import withCancel from "../components/ComponentWithCancel";
import LoadingSpinner from "../components/LoadingSpinner";
import ScreenHeader from "../components/ScreenHeader";
import ErrorAlert from "../components/ErrorAlert";
import FaIcon from "../components/FaIcon";
import useAPILoad from "../lib/apiLoadLib";
import { querystring, getOrgSettingsUrl } from "../lib/urlLib";
import { orgSettingsBreadcrumb } from "../lib/breadcrumbLib";
import { getLoadError } from "../lib/errorLib";
import title from "../lib/titleLib";
import "./OrgUnsubscribe.css";

const loadErrorCodes = {
  OrgNotExist: "APP_NOT_FOUND",
  UserInvalidAuthToken: "TOKEN_NOT_FOUND",
};

function OrgUnsubscribe(props) {
  let isLoading = true;
  let loadError = null;

  const token = querystring("token", props.location.search);
  const { ownerId } = props.match.params;

  useEffect(() => {
    document.title = title("Unsubscribe");
  }, []);

  /////////////
  // Loading //
  /////////////

  const { data, error } = useAPILoad(
    `/orgs/${ownerId}/unsubscribe_report`,
    (path) =>
      props.invokePublicApig({
        path,
        method: "POST",
        body: { authToken: token },
      }),
    { polling: false }
  );

  if (data !== null) {
    isLoading = false;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);

    if (loadError) {
      isLoading = false;
    }
  }

  return (
    <div className="OrgUnsubscribe">
      <ScreenHeader border breadcrumb={orgSettingsBreadcrumb(props)}>
        Unsubscribe
      </ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {error && !loadError && <ErrorAlert error={error} />}

      {error && loadError && (
        <ContainerErrorPanel
          type="unsubscribe"
          context={{
            name: ownerId,
          }}
          code={loadError}
        />
      )}

      {data && (
        <div className="complete">
          <p>
            <FaIcon name="check-circle" />
          </p>
          <p>
            You&apos;ve been unsubscribed from {ownerId}&apos;s App Reports.
          </p>
          <p>
            To change your notification settings, head over to{" "}
            <Link to={getOrgSettingsUrl(ownerId)}>your organization</Link>.
          </p>
        </div>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(OrgUnsubscribe));
