import React, { Fragment } from "react";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import LoaderButton from "./LoaderButton";
import "./ConfirmPromoteChangesPanel.css";

const actionCopy = {
  add: "Add",
  remove: "Remove",
  replace: "Replace",
  modify: "Modify",
};
const actionVerb = {
  add: "added",
  remove: "removed",
  replace: "replaced",
  modify: "modified",
};

function changePlural(count) {
  return count === 1 ? "change" : "changes";
}

function servicePlural(count) {
  return count === 1 ? "service" : "services";
}

export default function ConfirmPromoteChangesPanel({
  changes,
  reporting,
  reportSent,
  onReportClick,
  showMinorChanges,
  setShowMinorChanges,
  serviceItemChanges,
  showServiceItemChanges,
  hideServiceItemChanges,
  minorServiceChanges,
  showMinorServiceChanges,
  hideMinorServiceChanges,
  disableCollapseMinorChanges,
  ...props
}) {
  function renderServiceChangeItem(service, key) {
    return (
      <Fragment key={key}>
        {service.ids.length > 1 && (
          <li className={service.action}>
            <div>
              <span>{actionCopy[service.action]}</span>
            </div>
            <div>
              <span>
                {service.type}
                {service.detail && <>&nbsp;{service.detail}</>}
              </span>
              <span>
                {service.ids.length}
                &nbsp;
                {actionVerb[service.action]}
              </span>
              {!serviceItemChanges[key] && (
                <TextButton onClick={(e) => showServiceItemChanges(key)}>
                  <FaIcon name="plus-square-o" />
                  Show all
                </TextButton>
              )}
              {serviceItemChanges[key] && (
                <TextButton onClick={(e) => hideServiceItemChanges(key)}>
                  <FaIcon name="minus-square-o" />
                  Hide
                </TextButton>
              )}
            </div>
          </li>
        )}
        {(service.ids.length === 1 || serviceItemChanges[key]) &&
          service.ids.map((item, i) => (
            <li key={i} className={service.action}>
              <div>
                <span>{actionCopy[service.action]}</span>
              </div>
              <div>
                <span>
                  {service.type}
                  {service.detail && <>&nbsp;{service.detail}</>}
                </span>
                <span title={item}>{item}</span>
              </div>
            </li>
          ))}
      </Fragment>
    );
  }

  function renderMinorServiceChanges(change) {
    const _showMinorServiceChanges = minorServiceChanges[change.category];

    const clipMinor = 2;
    let minorChanges = change.minor;
    let numMinor = 0;

    if (change.minor.length >= clipMinor) {
      minorChanges = _showMinorServiceChanges
        ? change.minor
        : change.minor.slice(0, clipMinor);
      numMinor = change.minor.length - clipMinor;
    }

    const _renderServiceChangeItem = (item, i) =>
      renderServiceChangeItem(item, `${change.category}-minor-${i}`);

    return (
      <div key={change.category} className="service">
        <h5>{change.category}</h5>
        <ul>{minorChanges.map(_renderServiceChangeItem)}</ul>
        {numMinor > 0 && (
          <>
            {_showMinorServiceChanges && (
              <TextButton
                onClick={(e) => hideMinorServiceChanges(change.category)}
              >
                Hide minor {change.category} changes
              </TextButton>
            )}
            {!_showMinorServiceChanges && (
              <TextButton
                onClick={(e) => showMinorServiceChanges(change.category)}
              >
                Show {numMinor} other minor {change.category}&nbsp;
                {changePlural(numMinor)}
              </TextButton>
            )}
          </>
        )}
      </div>
    );
  }

  function renderMajorServiceChanges(change) {
    const _showMinorServiceChanges = minorServiceChanges[change.category];

    const clipMajor = 10;
    let collapseMajor = false;
    let majorChanges = change.major;
    let numMinor = change.minor.length;

    if (change.major.length > clipMajor) {
      collapseMajor = true;
      majorChanges = _showMinorServiceChanges
        ? change.major
        : change.major.slice(0, clipMajor);
      numMinor += change.major.length - clipMajor;
    }

    const _renderMajorServiceChangeItem = (item, i) =>
      renderServiceChangeItem(item, `${change.category}-major-${i}`);
    const _renderMinorServiceChangeItem = (item, i) =>
      renderServiceChangeItem(item, `${change.category}-minor-${i}`);

    return (
      <div key={change.category} className="service">
        <h5>{change.category}</h5>
        <ul>
          {majorChanges.map(_renderMajorServiceChangeItem)}
          {_showMinorServiceChanges &&
            change.minor.map(_renderMinorServiceChangeItem)}
        </ul>
        {numMinor > 0 && (
          <>
            {_showMinorServiceChanges && (
              <TextButton
                onClick={(e) => hideMinorServiceChanges(change.category)}
              >
                {collapseMajor && <>Hide other {change.category} changes</>}
                {!collapseMajor && <>Hide minor {change.category} changes</>}
              </TextButton>
            )}
            {!_showMinorServiceChanges && (
              <TextButton
                onClick={(e) => showMinorServiceChanges(change.category)}
              >
                {collapseMajor && (
                  <>
                    Show {numMinor} other {change.category}&nbsp;
                    {changePlural(numMinor)}
                  </>
                )}
                {!collapseMajor && (
                  <>
                    Show {numMinor} minor {change.category}&nbsp;
                    {changePlural(numMinor)}
                  </>
                )}
              </TextButton>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div className="ConfirmPromoteChangesPanel">
      {changes.major.map(renderMajorServiceChanges)}
      {changes.minor.length > 0 && showMinorChanges && (
        <>
          {changes.minor.map(renderMinorServiceChanges)}
          <div className="controls">
            {!disableCollapseMinorChanges && (
              <>
                <TextButton onClick={(e) => setShowMinorChanges(false)}>
                  Hide minor changes
                </TextButton>
                <span className="separator">&bull;</span>
              </>
            )}
            {!reportSent && (
              <LoaderButton
                bsStyle="link"
                className="btn-report"
                loading={reporting}
                onClick={onReportClick}
              >
                Did we miscategorize something? Report it.
              </LoaderButton>
            )}
            {reportSent && (
              <span className="report-status">Report sent. Thanks!</span>
            )}
          </div>
        </>
      )}
      {changes.minor.length > 0 && !showMinorChanges && (
        <div className="controls">
          <TextButton onClick={(e) => setShowMinorChanges(true)}>
            Show minor changes in {changes.minor.length}&nbsp;
            {servicePlural(changes.minor.length)}
          </TextButton>
        </div>
      )}
    </div>
  );
}
