import React, { useEffect, useReducer } from "react";
import { Redirect, useHistory } from "react-router-dom";
import FaIcon from "../components/FaIcon";
import ErrorAlert from "../components/ErrorAlert";
import IssuesList from "../components/IssuesList";
import LoaderButton from "../components/LoaderButton";
import LoadingSpinner from "../components/LoadingSpinner";
import withCancel from "../components/ComponentWithCancel";
import IssuesSplashPanel from "../components/IssuesSplashPanel";
import withAppHeader from "../components/ComponentWithAppHeader";
import StageSelectDropdown from "../components/StageSelectDropdown";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import IssuesEnableErrorAlert from "../components/IssuesEnableErrorAlert";
import IssuesExceededLimitAlert from "../components/IssuesExceededLimitAlert";
import IssuesFilterSelect, { MODES } from "../components/IssuesFilterSelect";
import {
  querystring,
  getAppRemoveUrl,
  buildQueryStringUrl,
  getAppStageIssuesUrl,
  getAppIssuesSettingsUrl,
} from "../lib/urlLib";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import { getLoadError, errorHandler } from "../lib/errorLib";
import "./AppIssues.css";

const helpUrl = "https://seed.run/docs/native-error-reporting";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
  StageNotExist: "STAGE_NOT_FOUND",
};

const emptyCopyMap = {
  [MODES.ACTIVE]: "No active issues found.",
  [MODES.MUTED]: "No ignored issues found.",
  [MODES.RESOLVED]: "No resolved issues found.",
};

const noOlderCopyMap = {
  [MODES.ACTIVE]: "No older issues found.",
  [MODES.MUTED]: "No older issues found.",
  [MODES.RESOLVED]: "No older issues found.",
};

const settingDefaultState = { key: 0, updated: false, updating: false };

function settingReducer(state, action) {
  switch (action.type) {
    case "reset":
      return settingDefaultState;
    case "update":
      return { ...state, updating: true };
    case "update-done":
      return { ...state, updating: false, updated: true, key: state.key + 1 };
    case "update-failed":
      return { ...state, updating: false, key: state.key + 1 };
    default:
      return state;
  }
}

function AppIssues({ match, history, invokeApig, invokeAppsApig, ...props }) {
  const { ownerId, appId, appStageId } = match.params;
  const mode = querystring("mode") || MODES.ACTIVE;
  const page = querystring("page");

  const { action } = useHistory();

  const [muteState, muteDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [resolveState, resolveDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );

  let error = null;
  let isLoading = true;
  let loadError = null;

  useEffect(() => {
    document.title = title("Issues");
  }, [appId]);

  useEffect(() => {
    if (action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [action, page]);

  useEffect(() => {
    muteDispatch({ type: "reset" });
    resolveDispatch({ type: "reset" });
  }, [mode, page]);

  //////////
  // Load //
  //////////

  const { data: appInfo, error: appError } = useAPILoad(getAppAPI(), (path) =>
    invokeAppsApig({ path })
  );

  const {
    data: errorsInfo,
    error: errorsInfoError,
    reload: reloadErrorsInfo,
  } = useAPILoad(getStageErrorsAPI());

  const {
    data: statusInfo,
    error: statusInfoError,
  } = useAPILoad(getStageErrorStatusAPI(), { polling: false });

  // Note: set errorsInfoError and statusInfoError as error only after appInfo has loaded or
  //       failed. B/c the app can be in the removing/removed state and the page will redirect
  //       and not display error alert.
  error = appError || (appInfo && (errorsInfoError || statusInfoError));

  if (appInfo !== null && errorsInfo !== null && statusInfo !== null) {
    isLoading = false;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  const showFeed =
    appInfo &&
    (appInfo.app.errorMonitorStatus === "enabled" ||
      appInfo.app.errorMonitorStatusContext === "exceeded_limit");
  const showExceededLimitWarning =
    appInfo && appInfo.app.errorMonitorStatusContext === "exceeded_limit";
  const showUnsupportedRuntimeWarning =
    !showExceededLimitWarning && statusInfo && statusInfo.status !== "success";
  const isRemovingOrRemoveFailed =
    appInfo &&
    (appInfo.app.status === "deleting" ||
      appInfo.app.status === "delete_failed");

  /////////
  // API //
  /////////

  function getAppAPI() {
    return `/${ownerId}/${appId}`;
  }

  function getStageErrorsAPI() {
    const url = appStageId
      ? `/${ownerId}/${appId}/stages/${appStageId}/errors`
      : `/${ownerId}/${appId}/errors/default_stage`;
    return buildQueryStringUrl(url, {
      mode,
      nextToken: page,
    });
  }

  function getStageErrorsPUTAPI(appStageId) {
    return `/${ownerId}/${appId}/stages/${appStageId}/errors`;
  }

  function getStageErrorStatusAPI() {
    return appStageId
      ? `/${ownerId}/${appId}/stages/${appStageId}/error_status`
      : `/${ownerId}/${appId}/errors/default_stage/error_status`;
  }

  function updateErrorGroups(stageId, errorGroupIds, data) {
    return invokeApig({
      path: getStageErrorsPUTAPI(stageId),
      method: "PUT",
      body: { ...data, errorGroupShortIds: JSON.stringify(errorGroupIds) },
    });
  }

  /////////////
  // Handler //
  /////////////

  async function handleResolveSave(stageId, errorGroupIds, resolved) {
    resolveDispatch({ type: "update" });
    try {
      await updateErrorGroups(stageId, errorGroupIds, { resolved });
      await reloadErrorsInfo();
      resolveDispatch({ type: "update-done" });
    } catch (e) {
      resolveDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleMuteSave(stageId, errorGroupIds, muted) {
    muteDispatch({ type: "update" });
    try {
      await updateErrorGroups(stageId, errorGroupIds, { muted });
      await reloadErrorsInfo();
      muteDispatch({ type: "update-done" });
    } catch (e) {
      muteDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  ////////////
  // Render //
  ////////////

  return (
    <div className="AppIssues">
      {error && !loadError && <ErrorAlert error={error} />}

      {isLoading && <LoadingSpinner />}

      {isRemovingOrRemoveFailed && (
        <Redirect to={getAppRemoveUrl(ownerId, appId)} />
      )}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="stage"
          code={loadError}
          context={{
            appName: appId,
            name: appStageId || (errorsInfo && errorsInfo.stageName),
          }}
        />
      )}

      {!isLoading && !loadError && !showFeed && <IssuesSplashPanel />}

      {!isLoading && !loadError && showFeed && (
        <>
          <div className="title">
            <StageSelectDropdown
              stages={appInfo && appInfo.stages}
              key={appStageId || errorsInfo.stageName}
              selected={appStageId || errorsInfo.stageName}
              getUrl={(stage) => getAppStageIssuesUrl(ownerId, appId, stage)}
            />
            <div>
              <LoaderButton
                bsStyle="link"
                target="_blank"
                href={helpUrl}
                className="help-link"
                rel="noopener noreferrer"
              >
                <FaIcon name="question-circle" />
                <span>How to report issues</span>
              </LoaderButton>
              <IssuesFilterSelect
                currentMode={mode}
                getUrl={(mode) =>
                  getAppStageIssuesUrl(
                    ownerId,
                    appId,
                    appStageId,
                    mode !== MODES.ACTIVE && { mode }
                  )
                }
              />
            </div>
          </div>

          {showExceededLimitWarning && <IssuesExceededLimitAlert />}

          {showUnsupportedRuntimeWarning && (
            <IssuesEnableErrorAlert
              status={statusInfo.status}
              unsupportedRuntimes={statusInfo.unsupportedRuntimes}
            />
          )}

          <IssuesList
            mode={mode}
            loading={isLoading}
            emptyCopy={page ? noOlderCopyMap[mode] : emptyCopyMap[mode]}
            isDeployed={errorsInfo.isDeployed}
            issues={errorsInfo.errorGroups}
            settingsLink={getAppIssuesSettingsUrl(ownerId, appId)}
            nextPageLink={
              errorsInfo.nextPageToken &&
              getAppStageIssuesUrl(ownerId, appId, appStageId, {
                mode,
                page: errorsInfo.nextPageToken,
              })
            }
            prevPageLink={
              page &&
              (errorsInfo.prevPageToken
                ? getAppStageIssuesUrl(ownerId, appId, appStageId, {
                    mode,
                    page: errorsInfo.prevPageToken,
                  })
                : getAppStageIssuesUrl(ownerId, appId, appStageId, { mode }))
            }
            muting={muteState.updating}
            onMuteUpdate={handleMuteSave}
            resolving={resolveState.updating}
            onResolveUpdate={handleResolveSave}
          />
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(AppIssues));
