import React, { useEffect } from "react";
import BrandNavbar from "./BrandNavbar";

export default function RenderContainerComponent({ component: C, ...props }) {
  const { setOrg } = props;
  const { ownerId, appId } = props.match.params;

  // Set the current org in the app
  useEffect(() => setOrg(ownerId || null), [ownerId, setOrg]);

  return (
    <>
      <BrandNavbar
        appId={appId}
        ownerId={ownerId}
        userInfo={props.userInfo}
        onLogoutClick={props.onLogout}
        email={props.authenticatedUser}
        isAuthenticated={props.isAuthenticated}
        isAuthenticating={props.isAuthenticating}
      />
      <C {...props} />
    </>
  );
}
