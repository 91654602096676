import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { FormControl } from "react-bootstrap";
import ErrorAlert from "../components/ErrorAlert";
import ScreenHeader from "../components/ScreenHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import withCancel from "../components/ComponentWithCancel";
import StageReportPanel from "../components/StageReportPanel";
import withAppHeader from "../components/ComponentWithAppHeader";
import ReportFilterSelect from "../components/ReportFilterSelect";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import PermissionErrorPanel from "../components/PermissionErrorPanel";
import {
  isAwsPermissionError,
  errorHandler,
  getLoadError,
} from "../lib/errorLib";
import { appBreadcrumb } from "../lib/breadcrumbLib";
import { formatReportDateSlug } from "../lib/stringLib";
import {
  getAppReportUrl,
  getAppStageApiMetricsUrl,
  getAppStageLambdaMetricsUrl,
} from "../lib/urlLib";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import "./AppReports.css";

const loadErrorCodes = {
  AppStageReportNotExist: "REPORT_NOT_FOUND",
  AppStageReportGenerateFailed: "REPORT_FAILED",
};

const defaultSelectReportValue = "pick a report";

const titleCopyMap = {
  daily: "Daily Report for",
  weekly: "Weekly Report for",
};

function AppReports(props) {
  let isLoading = true;
  let loadError = null;
  let permissionError = null;
  let apiError = null;

  const {
    ownerId,
    appId,
    reportType,
    reportSlug,
    appStageId,
  } = props.match.params;

  const [hidingLambdas, setHidingLambdas] = useState({
    error: {},
    duration: {},
  });

  const reportTitle = `${titleCopyMap[reportType]} ${formatReportDateSlug(
    reportSlug
  )}`;

  //////////
  // Load //
  //////////

  useEffect(() => {
    document.title = title(reportTitle);
  }, [reportTitle]);

  const { data: reportInfo, error: reportInfoError } = useAPILoad(
    `${getAppAPI()}/reports/${reportType}/${reportSlug}/info`,
    {
      polling: false,
    }
  );

  const {
    data: reportsList,
    error: reportsListError,
  } = useAPILoad(`${getAppAPI()}/reports`, { polling: false });

  const {
    data: reportData,
    error: reportDataError,
    reload: reloadReportData,
  } = useAPILoad(
    appStageId &&
      `${getAppAPI()}/reports/${reportType}/${reportSlug}/data?stageName=${appStageId}`,
    { polling: false }
  );

  isLoading = !(
    (reportInfo !== null || reportInfoError) &&
    (reportsList !== null || reportsListError) &&
    reportData !== null
  );

  // Handle error
  if (reportDataError) {
    if (isAwsPermissionError(reportDataError)) {
      permissionError = reportDataError;
    }
    loadError = getLoadError(reportDataError, loadErrorCodes);
  }
  apiError = loadError
    ? null
    : reportInfoError || reportsListError || reportDataError || permissionError;

  const hasMultipleStages = reportInfo && reportInfo.stages.length > 1;
  const multiStageCs = hasMultipleStages ? "multiple" : "";

  //////////
  // APIs //
  //////////

  function getAppAPI() {
    return `/${ownerId}/${appId}`;
  }

  function setHidingLambda(lambdaName, metricType, hiding) {
    setHidingLambdas({
      ...hidingLambdas,
      [metricType]: {
        ...hidingLambdas[metricType],
        [lambdaName]: hiding,
      },
    });
  }

  function muteLambda(stageId, isMuted, metricType, lambdaName) {
    return props.invokeApig({
      path: `${getAppAPI()}/stages/${stageId}/reports/update_lambda`,
      method: "POST",
      body: { lambdaName, metricType, isMuted },
    });
  }

  function buildMetricsUrl({ stageId, type, region, source, apiId, params }) {
    return type === "api"
      ? getAppStageApiMetricsUrl(
          ownerId,
          appId,
          stageId,
          region,
          source,
          apiId,
          params
        )
      : getAppStageLambdaMetricsUrl(
          ownerId,
          appId,
          stageId,
          region,
          source,
          params
        );
  }

  function buildStageReportUrl(stageId) {
    return getAppReportUrl(ownerId, appId, `${reportType}/${reportSlug}`, {
      stage: stageId,
    });
  }

  //////////////
  // Handlers //
  //////////////

  function handleSelectReportChange(event) {
    const slug = event.target.value;

    props.history.push(getAppReportUrl(ownerId, appId, slug));
  }

  async function handleHideLambdaToggle(
    stageId,
    isMuted,
    metricType,
    lambdaName
  ) {
    setHidingLambda(lambdaName, metricType, true);

    try {
      await muteLambda(stageId, isMuted, metricType, lambdaName);
      await reloadReportData();
    } catch (e) {
      errorHandler(e);
    }

    setHidingLambda(lambdaName, metricType, false);
  }

  ////////////
  // Render //
  ////////////

  function renderReportsPicker() {
    return (
      <FormControl
        id="select"
        componentClass="select"
        disabled={!reportsList}
        value={defaultSelectReportValue}
        onChange={handleSelectReportChange}
      >
        <option disabled value={defaultSelectReportValue}>
          View past reports
        </option>
        {reportsList &&
          reportsList.reports.map(({ reportId, type }) => (
            <option key={reportId} value={reportId}>
              {`${titleCopyMap[type]} ${formatReportDateSlug(reportId, false)}`}
            </option>
          ))}
      </FormControl>
    );
  }

  return (
    <div className={`AppReports ${multiStageCs}`}>
      <ScreenHeader
        border
        action={renderReportsPicker()}
        breadcrumb={appBreadcrumb(props)}
      >
        {reportTitle}
      </ScreenHeader>

      {!appStageId && reportInfo && (
        <Redirect
          to={getAppReportUrl(ownerId, appId, `${reportType}/${reportSlug}`, {
            stage: reportInfo.stages[0],
          })}
        />
      )}

      {reportInfo && (
        <>
          {hasMultipleStages && (
            <div className="title">
              <h4>{appStageId}</h4>
              <ReportFilterSelect
                selectedStage={appStageId}
                stages={reportInfo.stages}
                buildReportUrl={buildStageReportUrl}
              />
            </div>
          )}
        </>
      )}

      <PermissionErrorPanel type="reports" error={permissionError}>
        Add a couple of permissions to view reports
      </PermissionErrorPanel>

      {isLoading && <LoadingSpinner />}

      {!isLoading && loadError && (
        <ContainerErrorPanel type="reports" code={loadError} />
      )}
      {apiError && <ErrorAlert error={apiError} />}

      {!isLoading && !loadError && (
        <StageReportPanel
          hiding={hidingLambdas}
          data={reportData.reportData}
          buildMetricsUrl={buildMetricsUrl}
          onHideToggle={handleHideLambdaToggle}
        />
      )}
    </div>
  );
}

export default withAppHeader(withCancel(AppReports));
