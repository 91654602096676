import React from "react";
import { Link } from "react-router-dom";
import PipelineTableStageCell from "./PipelineTableStageCell";
import RightChevron from "./RightChevron";
import "./ServiceRemoveStageTable.css";

const statusMap = {
  remove_pending: "deleting",
  remove: "deleting",
  remove_failed: "delete_failed",
};

export default function ServiceRemoveStageTable({ stagesData }) {
  const stagesC = [];
  const errorsC = [];

  stagesData.forEach((datum, i) => {
    stagesC.push(
      <div key={i} className="stage">
        <PipelineTableStageCell
          hideAction
          appStage={datum.appStage}
          appStageRemoveLink={datum.removeLogLink}
          status={statusMap[datum.stage.current_action]}
        />
      </div>
    );
    errorsC.push(
      <div key={i} className="error">
        <div>
          {datum.errorMessage && <p className="copy">{datum.errorMessage}</p>}
          <Link to={datum.removeLogLink}>
            View logs
            <RightChevron />
          </Link>
        </div>
      </div>
    );
  });

  return (
    <div className="ServiceRemoveStageTable">
      <div className="content">
        <div className="stages">{stagesC}</div>
        <div className="errors">{errorsC}</div>
      </div>
    </div>
  );
}
