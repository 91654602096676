import React from "react";
import { Link } from "react-router-dom";
import RightChevron from "./RightChevron";
import MiniActivityItem from "./MiniActivityItem";
import { getAppUrl } from "../lib/urlLib";
import "./MiniActivityList.css";

function renderEmpty() {
  return <div className="empty">There are no recent activities.</div>;
}

export default function MiniActivityList({ pathParams, activities }) {
  const { ownerId, appId } = pathParams;

  const empty = activities.length === 0;

  return (
    <div className="MiniActivityList">
      <h4>
        <span>Activity</span>
        {!empty && (
          <Link to={`${getAppUrl(ownerId, appId)}/activity`}>
            View All <RightChevron />
          </Link>
        )}
      </h4>
      <div className="list">
        {empty && renderEmpty()}
        {!empty &&
          activities.map((activity, i) => (
            <MiniActivityItem
              key={i}
              activity={activity}
              pathParams={pathParams}
            />
          ))}
      </div>
    </div>
  );
}
