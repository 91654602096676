import React, { useEffect } from "react";
import withCancel from "../components/ComponentWithCancel";
import LoadingSpinner from "../components/LoadingSpinner";
import ScreenHeader from "../components/ScreenHeader";
import OrgAddPanel from "../components/OrgAddPanel";
import ErrorAlert from "../components/ErrorAlert";
import OrgList from "../components/OrgList";
import { useCreateOrgReducer } from "../lib/hooksLib";
import { errorHandler } from "../lib/errorLib";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import "./Home.css";

function Home(props) {
  let apps = null;
  let isLoading = true;

  const [
    { key: orgAddKey, show: showOrgAddForm, creating: isCreating },
    createOrgDispatch,
    handleOrgAddShowClick,
    handleOrgAddHideClick,
  ] = useCreateOrgReducer();

  useEffect(() => {
    document.title = title("Home");
  }, []);

  const { data: info, error } = useAPILoad("/?version=v2");

  if (info) {
    isLoading = false;
    apps = info.orgs;
  }

  function createOrg(name) {
    return props.invokeApig({
      path: "/orgs",
      method: "POST",
      body: { name },
    });
  }

  async function handleOrgAddClick(event, name) {
    createOrgDispatch({ type: "creating" });

    try {
      await createOrg(name);
      props.history.push(`/${name}`);
    } catch (e) {
      errorHandler(e);
      createOrgDispatch({ type: "create-error" });
    }
  }

  return (
    <div className="Home">
      {isLoading && <LoadingSpinner />}

      {error && <ErrorAlert error={error} />}

      {!isLoading && apps && (
        <>
          <ScreenHeader
            action={
              <OrgAddPanel
                key={orgAddKey}
                adding={isCreating}
                showForm={showOrgAddForm}
                onAddClick={handleOrgAddClick}
                onShowClick={handleOrgAddShowClick}
                onHideClick={handleOrgAddHideClick}
              />
            }
          >
            Welcome!
          </ScreenHeader>
          <OrgList groupedApps={apps} />
        </>
      )}
    </div>
  );
}

export default withCancel(Home);
