const stageNameRe = new RegExp("^[a-zA-Z0-9][a-zA-Z0-9-]*$");
const envVarNameRe = new RegExp("^[a-zA-Z]+[a-zA-Z0-9_]*$");
const appSlugRe = new RegExp("^[a-zA-Z0-9]+[-a-zA-Z0-9]+$");
const serviceNameRe = new RegExp("^[a-zA-Z0-9]+[-a-zA-Z0-9]*$");
const urlRe = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const usernameRe = /^[a-z0-9]+(?:[-][a-z0-9]+)*$/;
const twoFactorCodeRe = /^\d{6}$/;

export function testStageName(name) {
  return stageNameRe.test(name);
}

export function testAppSlug(slug) {
  return appSlugRe.test(slug);
}

export function testServiceName(name) {
  return serviceNameRe.test(name);
}

export function testEnvVarName(name) {
  return envVarNameRe.test(name);
}

export function testEmail(str) {
  return emailRe.test(str);
}

export function testTwoFactorCode(str) {
  return twoFactorCodeRe.test(str);
}

export function getDomainFromEmail(email) {
  return email.substring(email.lastIndexOf("@") + 1);
}

export function testUrl(str) {
  return urlRe.test(str);
}

export function testUsername(str) {
  return usernameRe.test(str);
}

export function testOrgName(str) {
  return usernameRe.test(str);
}
