import React from "react";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import RightChevron from "./RightChevron";
import SectionHeader from "./SectionHeader";
import { isDeprecationWarning } from "../lib/issueLib";
import "./IssueInfoPanel.css";

const helperUrl = "https://seed.run/docs/serverless-errors/deprecationwarning";
const flagHelpUrl =
  "https://seed.run/docs/issues-and-alerts#flag-ignored-issues";
const ignoreHelpUrl =
  "https://seed.run/docs/issues-and-alerts#automatically-ignored-issues";

function formatStackTrace(stackTrace) {
  try {
    return JSON.parse(stackTrace)
      .map((line) => line)
      .join("\n");
  } catch (e) {}

  return stackTrace;
}

export default function IssueInfoPanel({
  muted,
  muteType,
  resolved,
  error = {},
  serviceName,
  onReportClick,
  reported = false,
  reporting = false,
  onReportMessageClick,
  reportedMessage = false,
  reportingMessage = false,
}) {
  const {
    stageId,
    lambdaName,
    errorType,
    errorMessage,
    errorStackTrace,
  } = error;

  const statusCs = resolved ? "resolved" : muted ? "muted" : "";
  const messageCs = errorMessage ? "" : "empty";
  const stackCs = errorStackTrace ? "" : "empty";

  return (
    <div className="IssueInfoPanel">
      {(resolved || muted) && (
        <div className={`status ${statusCs}`}>
          {resolved && (
            <div>
              <FaIcon name="check-square" />
              <span className="col2">
                This issue has been marked as resolved.
              </span>
            </div>
          )}
          {muted && (
            <div>
              <FaIcon name="bell-slash-o" />
              {muteType === "auto" && (
                <span className="col2">
                  This issue has been automatically ignored.&nbsp;
                  <a
                    target="_blank"
                    href={ignoreHelpUrl}
                    rel="noopener noreferrer"
                  >
                    Learn more about this.
                  </a>
                </span>
              )}
              {muteType !== "auto" && (
                <div className="col2">
                  <span>
                    This issue has been ignored. You will not be notified of any
                    future events.
                  </span>
                  <p className="report">
                    <LoaderButton
                      bsSize="xsmall"
                      bsStyle="danger"
                      loading={reporting}
                      disabled={reported}
                      onClick={onReportClick}
                    >
                      {reported ? (
                        <FaIcon name="check" />
                      ) : reporting ? null : (
                        <FaIcon name="flag" />
                      )}
                      Flag Issue
                    </LoaderButton>
                    <span>
                      Ignoring the same issue too many times? Flag it and
                      we&apos;ll have it looked at.{" "}
                      <a
                        target="_blank"
                        href={flagHelpUrl}
                        rel="noopener noreferrer"
                      >
                        Learn more.
                      </a>
                    </span>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div className={`message ${messageCs}`}>
        <SectionHeader>Error Message</SectionHeader>
        <p>{errorMessage ? errorMessage : "No error message available."}</p>
        <LoaderButton
          bsStyle="link"
          bsSize="xsmall"
          loading={reportingMessage}
          disabled={reportedMessage}
          onClick={onReportMessageClick}
        >
          {reportedMessage && (
            <>
              <FaIcon name="check" />
              <span>Thank you for letting us know</span>
            </>
          )}
          {!reportedMessage &&
            "Does the error message not look right? Let us know."}
        </LoaderButton>
      </div>
      {errorType &&
        errorMessage &&
        isDeprecationWarning(errorType, errorMessage) && (
          <div className="helper">
            <SectionHeader>
              <FaIcon name="lightbulb-o" />
              Common Serverless Error
            </SectionHeader>
            <p>
              This is a common Lambda Node.js warning that&apos;s logged as an
              error. This warning might be either printed out by a dependency,
              or by the Node.js runtime. Make sure you are not calling a
              deprecated Node.js API, and keep your dependencies up to date. You
              can{" "}
              <a href={helperUrl} target="_blank" rel="noopener noreferrer">
                learn more about this error over on our docs
                <RightChevron />
              </a>
            </p>
          </div>
        )}
      <div className="tags">
        <SectionHeader>Tags</SectionHeader>
        <div className="list">
          <div className="tag">
            <span className="key">lambda</span>
            <span className="value">{lambdaName}</span>
          </div>
          <div className="tag">
            <span className="key">service</span>
            <span className="value">{serviceName}</span>
          </div>
          <div className="tag">
            <span className="key">stage</span>
            <span className="value">{stageId}</span>
          </div>
        </div>
      </div>
      <div className={`stack-trace ${stackCs}`}>
        <SectionHeader>Stack Trace</SectionHeader>
        {errorStackTrace && <pre>{formatStackTrace(errorStackTrace)}</pre>}
        {!errorStackTrace && <pre>No stack trace available.</pre>}
      </div>
    </div>
  );
}
