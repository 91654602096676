import React, { useRef, useEffect, useReducer } from "react";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import withFaviconChange from "../components/ComponentWithFaviconChange";
import ErrorAlert from "../components/ErrorAlert";
import ScreenHeader from "../components/ScreenHeader";
import SectionHeader from "../components/SectionHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import BuildInfoPanel from "../components/BuildInfoPanel";
import BuildHelpPanel from "../components/BuildHelpPanel";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import CollapsableLogTextarea from "../components/CollapsableLogTextarea";
import { errorHandler, getLoadError } from "../lib/errorLib";
import { appBuildBreadcrumb } from "../lib/breadcrumbLib";
import useAPILoad from "../lib/apiLoadLib";
import title from "../lib/titleLib";
import "./AppActivityServices.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
  StageNotExist: "STAGE_NOT_FOUND",
  5103: "BUILD_NOT_FOUND",
  1001: "SERVICE_NOT_FOUND",
  DeploymentNotExist: "DEPLOYMENT_NOT_FOUND",
};

function reportReducer(state, action) {
  switch (action.type) {
    case "reporting":
      return {
        ...state,
        isReporting: { ...state.isReporting, [action.vote]: true },
      };
    case "reported":
      return {
        ...state,
        reportSent: true,
        isReporting: { ...state.isReporting, [action.vote]: false },
      };
    default:
      return state;
  }
}

function AppActivityServices(props) {
  let buildLog = null;
  let cleanupLog = null;
  let loadError = null;
  let isLoading = true;
  let isLoadingLog = true;

  // Keep track of if the build logs have completed
  let hasMoreLogs = useRef(true);

  const { ownerId, appId, appStageId, buildId, serviceId } = props.match.params;

  const buildUri = `/${ownerId}/${appId}/stages/${appStageId}/builds/${buildId}/services/${serviceId}`;

  const [{ reportSent, isReporting }, reportDispatch] = useReducer(
    reportReducer,
    {
      reportSent: false,
      isReporting: { up: false, down: false },
    }
  );

  useEffect(() => {
    document.title = title(`Build v${buildId} ${serviceId}`);
  }, [buildId, serviceId]);

  const { data: buildInfo, error: buildInfoError } = useAPILoad(buildUri);
  const {
    data: buildLogInfo,
    error: buildLogError,
  } = useAPILoad(`${buildUri}/logs`, { polling: hasMoreLogs.current });

  const build = buildInfo && buildInfo.build;
  const deployment = buildInfo && buildInfo.deployment;
  const deploymentStatus = deployment && deployment.status;

  const error = buildInfoError || buildLogError;

  useEffect(() => {
    if (!deploymentStatus) {
      return;
    }

    props.favicon.setStatus(deploymentStatus);
  }, [props.favicon, deploymentStatus]);

  if (buildInfo) {
    isLoading = false;
  }

  if (buildLogInfo) {
    isLoadingLog = false;
    buildLog = buildLogInfo.logs;
    cleanupLog = buildLogInfo.cleanupLogs;

    hasMoreLogs.current = buildLogInfo.hasMore;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  /////////
  // API //
  /////////

  function reportErrorContext(data) {
    return props.invokeApig({
      path: `/report_error_context`,
      method: "POST",
      body: data,
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleReportClick(event, vote) {
    let comments;

    if (vote === "down") {
      comments = window.prompt(
        "A report with this build will be sent back to us. Feel free to add any additional comments."
      );

      if (comments === null) {
        return;
      }
    }

    reportDispatch({ type: "reporting", vote });

    try {
      const deployment = buildInfo.deployment;
      await reportErrorContext({
        vote,
        comments,
        appId: deployment.appId,
        stageId: deployment.stageId,
        projectId: deployment.projectId,
        deploymentId: deployment.deploymentId,
      });
    } catch (e) {
      errorHandler(e);
    }

    reportDispatch({ type: "reported", vote });
  }

  return (
    <div className="AppActivityServices">
      <ScreenHeader border breadcrumb={appBuildBreadcrumb(props)}>
        {serviceId}
      </ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {error && !loadError && <ErrorAlert error={error} />}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="buildService"
          code={loadError}
          context={{
            id: buildId,
            appName: appId,
            stageName: appStageId,
            serviceName: serviceId,
          }}
        />
      )}

      {!isLoading && !loadError && buildInfo && (
        <div>
          <BuildInfoPanel
            deployment={{
              ...deployment,
              skippedDueToMinutes: build && build.skippedDueToMinutes,
            }}
          />

          {(deployment.successContext ||
            deployment.error_context ||
            deployment.errorSlsDocLink ||
            deployment.errorBuildDocLink ||
            deployment.skippedCode) && (
            <BuildHelpPanel
              reporting={isReporting}
              reportSent={reportSent}
              pathParams={props.match.params}
              onReportClick={handleReportClick}
              successContext={deployment.successContext}
              errorCode={deployment.error_code}
              errorContext={deployment.error_context}
              errorSlsDocLink={deployment.errorSlsDocLink}
              errorBuildDocLink={deployment.errorBuildDocLink}
              skippedCode={deployment.skippedCode}
              skippedMessage={deployment.skippedMessage}
              skippedContext={deployment.skippedContext}
            />
          )}

          <div className="build-log">
            <SectionHeader>Build Log</SectionHeader>
            <CollapsableLogTextarea
              log={buildLog}
              cleanupLog={cleanupLog}
              loading={isLoadingLog}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default withAppHeader(
  withCancel(withFaviconChange(AppActivityServices))
);
