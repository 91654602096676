import React from "react";
import { DropdownButton } from "react-bootstrap";
import "./PipelineDropdownButton.css";

function PipelineDropdownButton({ className = "", ...props }, ref) {
  return (
    <div className={`PipelineDropdownButton ${className}`}>
      <DropdownButton ref={ref} {...props}>
        {props.children}
      </DropdownButton>
    </div>
  );
}

export default React.forwardRef(PipelineDropdownButton);
