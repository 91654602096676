import React from "react";
import TextButton from "./TextButton";
import RightChevron from "./RightChevron";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import DomainPathControl from "./DomainPathControl";
import "./CustomDomainForm.css";

export default function CustomDomainForm({
  onAddClick,
  onShowClick,
  onHideClick,
  domains = [],
  onCancelClick,
  loading = null,
  showing = null,
  adding = false,
  endpoints = [],
  loadErrorMessage,
  noEndpointsMessage,
  ...props
}) {
  function handleAddClick(
    { path, domain, subDomain },
    { stageName, serviceName }
  ) {
    onAddClick({
      stage: stageName,
      service: serviceName,
      path,
      domain,
      subDomain,
    });
  }

  function renderAddForm(endpoint) {
    return (
      <DomainPathControl
        adding={adding}
        domains={domains}
        onCancelClick={onHideClick}
        errorMessage={loadErrorMessage}
        onAddClick={(e, fields) => handleAddClick(fields, endpoint)}
      />
    );
  }

  function renderEndpoint(endpoint) {
    return (
      <div key={endpoint.endpoint} className="endpoint">
        <div className="info">
          <div className="col1">
            <h4>{endpoint.stageName}</h4>
            <h6>
              <span>
                {endpoint.serviceName}
                <RightChevron />
              </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={endpoint.endpoint}
                title={endpoint.endpoint}
              >
                {endpoint.endpoint}
              </a>
            </h6>
          </div>
          {showing !== endpoint.endpoint && (
            <div className="col2">
              <LoaderButton
                bsStyle="link"
                onClick={() => onShowClick(endpoint)}
                loading={loading === endpoint.endpoint}
              >
                Add
              </LoaderButton>
            </div>
          )}
        </div>
        {showing === endpoint.endpoint && (
          <div className="form">{renderAddForm(endpoint)}</div>
        )}
      </div>
    );
  }

  return (
    <div className="CustomDomainForm">
      <div className="endpoints">
        <SectionHeader>Available Endpoints</SectionHeader>
        <div className="list">
          {endpoints.length !== 0 && endpoints.map(renderEndpoint)}
          {endpoints.length === 0 && (
            <div className="no-endpoints">{noEndpointsMessage}</div>
          )}
        </div>
      </div>
      <div className="controls">
        <TextButton onClick={onCancelClick}>Hide Custom Domains</TextButton>
      </div>
    </div>
  );
}
