import React from "react";
import { Link } from "react-router-dom";
import { Glyphicon } from "react-bootstrap";
import FaIcon from "./FaIcon";
import { stripProtocol } from "../lib/urlLib";
import { dateToSinceTime } from "../lib/timeLib";
import { getAppUrl, getAppRemoveUrl } from "../lib/urlLib";
import slsIcon from "../images/serverless-icon.svg";
import "./AppListPanel.css";

export default function AppListPanel({ app }) {
  function renderContentCol2(app) {
    return (
      <div className="col2">
        <p>Last deployed</p>
        {app.latestBuiltAt && <p>{dateToSinceTime(app.latestBuiltAt)}</p>}
        {!app.latestBuiltAt && <p className="empty">&#8212;</p>}
      </div>
    );
  }

  function renderDeletingApp(app) {
    return (
      <div className="AppListPanel deleting">
        <div className="icon">
          <Link to={getAppRemoveUrl(app.owner_name, app.slug)}>
            <img alt="Serverless Icon" src={slsIcon} width={48} height={40} />
          </Link>
        </div>
        <div className="content">
          <div className="col1">
            <Link to={getAppRemoveUrl(app.owner_name, app.slug)}>
              {app.slug}
            </Link>
            <Link to={getAppRemoveUrl(app.owner_name, app.slug)}>
              <Glyphicon className="icon" glyph="refresh" />
              Removing app&hellip;
            </Link>
          </div>
          {renderContentCol2(app)}
        </div>
      </div>
    );
  }

  function renderDeleteFailedApp(app) {
    return (
      <div className="AppListPanel delete_failed">
        <div className="icon">
          <Link to={getAppRemoveUrl(app.owner_name, app.slug)}>
            <img alt="Serverless Icon" src={slsIcon} width={48} height={40} />
          </Link>
        </div>
        <div className="content">
          <div className="col1">
            <Link to={getAppRemoveUrl(app.owner_name, app.slug)}>
              {app.slug}
            </Link>
            <Link to={getAppRemoveUrl(app.owner_name, app.slug)}>
              <FaIcon name="exclamation-triangle" />
              Remove failed
            </Link>
          </div>
          {renderContentCol2(app)}
        </div>
      </div>
    );
  }

  function renderApp(app) {
    return (
      <div className="AppListPanel">
        <div className="icon">
          <Link to={getAppUrl(app.owner_name, app.slug)}>
            <img alt="Serverless Icon" src={slsIcon} width={48} height={40} />
          </Link>
        </div>
        <div className="content">
          <div className="col1">
            <Link to={getAppUrl(app.owner_name, app.slug)}>{app.slug}</Link>
            {app.repo && (
              <a target="_blank" href={app.repo} rel="noopener noreferrer">
                {stripProtocol(app.repo)}
              </a>
            )}
            {!app.repo && <span>Not connected to a Git repo</span>}
          </div>
          {renderContentCol2(app)}
        </div>
      </div>
    );
  }

  return app.status === "deleting"
    ? renderDeletingApp(app)
    : app.status === "delete_failed"
    ? renderDeleteFailedApp(app)
    : renderApp(app);
}
