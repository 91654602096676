export function safeParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

export function beautify(str) {
  const json = safeParse(str);
  return json ? JSON.stringify(json, null, 4) : str;
}
