const repoRegex = new RegExp(
  "^(https?://)?github.com/([A-Za-z0-9-]+)/([A-Za-z0-9-]+)/?$"
);

export function shortHash(hash) {
  return hash.substring(0, 7);
}

export function matchRepoURL(url) {
  return url.match(repoRegex);
}
