import {
  getAppStageUrl,
  getOrgSettingsUrl,
  getAppSettingsUrl,
  getAppActivityUrl,
  getAppPipelineUrl,
  getServiceRemoveUrl,
  getAppStageBuildUrl,
  getAppStageIssuesUrl,
  getAppStageRemoveUrl,
} from "./urlLib";

export function loginBreadcrumb(props) {
  const items = [];

  items.push({
    text: "Login",
    href: "/login",
  });

  return items;
}

export function userSettingsBreadcrumb(props) {
  const items = [];

  items.push({
    text: "Account Settings",
    href: "/settings",
  });

  return items;
}

export function orgSettingsBreadcrumb(props) {
  const items = [];
  const { ownerId } = props.match.params;

  items.push({
    text: "Org Settings",
    href: getOrgSettingsUrl(ownerId),
  });

  return items;
}

export function appBreadcrumb(props) {
  const items = [];
  const { ownerId, appId } = props.match.params;

  items.push({
    text: "Overview",
    href: `/${ownerId}/${appId}`,
  });

  return items;
}

export function appActivityBreadcrumb(props) {
  const { ownerId, appId } = props.match.params;
  const items = [];

  items.push({
    text: "Activity",
    href: getAppActivityUrl(ownerId, appId),
  });

  return items;
}

export function appActivityTypeBreadcrumb(props) {
  const items = appActivityBreadcrumb(props);
  const { ownerId, appId, appStageId } = props.match.params;

  items.push({
    text: appStageId,
    href: getAppActivityUrl(ownerId, appId, { stage: appStageId }),
  });

  return items;
}

export function appBuildBreadcrumb(props) {
  const items = appActivityTypeBreadcrumb(props);
  const { ownerId, appId, appStageId, buildId } = props.match.params;

  items.push({
    text: `Build v${buildId}`,
    href: getAppStageBuildUrl(ownerId, appId, appStageId, buildId),
  });

  return items;
}

export function appSettingsBreadcrumb(props) {
  const items = [];
  const { ownerId, appId } = props.match.params;

  items.push({
    text: "App Settings",
    href: getAppSettingsUrl(ownerId, appId),
  });

  return items;
}

export function appPipelineBreadcrumb(props) {
  const items = [];
  const { ownerId, appId } = props.match.params;

  items.push({
    text: "Pipeline",
    href: getAppPipelineUrl(ownerId, appId),
  });

  return items;
}

export function appIssuesBreadcrumb(props, defaultStage = null) {
  const items = [];
  const { ownerId, appId, appStageId } = props.match.params;

  if (defaultStage) {
    items.push({
      text: defaultStage,
      href: getAppStageIssuesUrl(ownerId, appId),
    });
  } else {
    items.push({
      text: appStageId,
      href: getAppStageIssuesUrl(ownerId, appId, appStageId),
    });
  }

  return items;
}

export function appResourcesBreadcrumb(props) {
  const items = [];
  const { ownerId, appId, appStageId } = props.match.params;

  items.push({
    text: appStageId,
    href: getAppStageUrl(ownerId, appId, appStageId),
  });

  return items;
}

export function appServiceRemoveBreadcrumb(props) {
  const items = appSettingsBreadcrumb(props);
  const { ownerId, appId, serviceId } = props.match.params;

  items.push({
    text: serviceId,
    href: getServiceRemoveUrl(ownerId, appId, serviceId),
  });

  return items;
}

export function appStageRemoveBreadcrumb(props) {
  const items = appSettingsBreadcrumb(props);
  const { ownerId, appId, appStageId } = props.match.params;

  items.push({
    text: appStageId,
    href: getAppStageRemoveUrl(ownerId, appId, appStageId),
  });

  return items;
}
