export function sortStages(stages, { filterDeleted = false } = {}) {
  if (filterDeleted) {
    stages = filterDeletedStages(stages);
  }

  return stages
    .map((stage) => {
      if (stage.is_production) {
        stage.sortKey = `1-${stage.name.toLowerCase()}`;
      } else if (stage.is_staging) {
        stage.sortKey = `2-${stage.name.toLowerCase()}`;
      } else if (
        stage.status === "delete_failed" ||
        stage.status === "deleting"
      ) {
        stage.sortKey = `6-${stage.name.toLowerCase()}`;
      } else if (stage.branch === "master") {
        stage.sortKey = `3-${stage.name.toLowerCase()}`;
      } else if (!stage.pull_request) {
        stage.sortKey = `4-${stage.name.toLowerCase()}`;
      } else {
        stage.sortKey = `5-${stage.name.toLowerCase()}`;
      }
      return stage;
    })
    .sort((stageA, stageB) => {
      if (stageA.sortKey < stageB.sortKey) {
        return -1;
      }
      if (stageA.sortKey > stageB.sortKey) {
        return 1;
      }
      return 0;
    });
}

export function filterDeletedStages(stages) {
  return stages.filter(
    ({ status }) => status !== "deleting" && status !== "delete_failed"
  );
}
