import React, { Component } from "react";
import { Form, HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { trimFields } from "../lib/formLib";
import NewIamRoleHelpButton from "./NewIamRoleHelpButton";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import SectionInfo from "./SectionInfo";
import SectionDescriptionLabel from "./SectionDescriptionLabel";
import "./AppIamPanel.css";

const noop = () => {};

const defaultProps = {
  iamRole: false,
  iamAccess: false,

  policyInfoLoading: false,
  onShowIamRoleClick: noop,

  showing: false,
  saving: false,

  onShowClick: noop,
  onSaveClick: noop,
  onHideClick: noop,
};

const defaultState = {
  dirty: false,
  formData: {},
  validation: {},
};

class AppIamForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      formData: {
        iam_role: props.iamRole === false ? "" : props.iamRole,
      },
    };
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      dirty: true,
      validation: {
        ...this.state.validation,
        [id]: null,
      },
    });
  };

  handleSaveClick = async (event) => {
    event.preventDefault();

    if (this.state.dirty === false) {
      return;
    }

    const { iam_role } = trimFields(this.state.formData);

    if (iam_role === "") {
      this.setState({
        validation: {
          ...this.state.validation,
          iam_role: "error",
        },
      });
      return;
    }

    await this.props.onSaveClick(event, { iam_role });
  };

  render() {
    const { formData, validation } = this.state;
    const { policyInfoLoading, onShowIamRoleClick } = this.props;

    return (
      <div>
        <Form onSubmit={this.handleAddClick}>
          <FormGroup controlId="iam_role" validationState={validation.iam_role}>
            <div className="label-container">
              <StyledControlLabel>IAM Role</StyledControlLabel>
              <NewIamRoleHelpButton
                loading={policyInfoLoading}
                onClick={onShowIamRoleClick}
                className="btnShowIamRolePanel"
              />
            </div>
            <FormControl
              type="text"
              bsSize="large"
              value={formData.iam_role}
              onChange={this.handleFieldChange}
              disabled={this.props.saving}
            />
            {validation.iam_role && (
              <HelpBlock>Please enter a valid IAM Role.</HelpBlock>
            )}
          </FormGroup>
          <div className="controls">
            <LoaderButton
              text="Update"
              type="submit"
              bsSize="large"
              loading={this.props.saving}
              onClick={this.handleSaveClick}
            />
            <LoaderButton
              bsStyle="link"
              text="Hide IAM Info"
              onClick={this.props.onHideClick}
            />
          </div>
        </Form>
      </div>
    );
  }
}

function AppIamPanel({
  showing,
  saving,
  iamRole,
  iamAccess,
  onShowClick,
  onSaveClick,
  onHideClick,
  policyInfoLoading,
  onShowIamRoleClick,
}) {
  return (
    <div className="AppIamPanel">
      <SectionInfo
        label="AWS IAM Info"
        description="Edit the IAM credentials for your app."
        button={
          showing === false && (
            <>
              {iamAccess && !iamRole && (
                <SectionDescriptionLabel>
                  Deploying through an IAM user has been <b>deprecated</b>. Set
                  an IAM role to replace the current IAM user: {iamAccess}
                </SectionDescriptionLabel>
              )}
              <LoaderButton
                bsSize="large"
                className="btnShow"
                onClick={onShowClick}
                loading={false}
                text="Edit AWS IAM Info"
              />
            </>
          )
        }
      >
        {showing === true && (
          <AppIamForm
            saving={saving}
            showing={showing}
            iamRole={iamRole}
            iamAccess={iamAccess}
            onSaveClick={onSaveClick}
            onHideClick={onHideClick}
            policyInfoLoading={policyInfoLoading}
            onShowIamRoleClick={onShowIamRoleClick}
          />
        )}
      </SectionInfo>
    </div>
  );
}

AppIamPanel.defaultProps = defaultProps;

export default AppIamPanel;
