import ReactDOM from "react-dom";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FormGroup, FormControl } from "react-bootstrap";
import CaretIcon from "./CaretIcon";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import { sortStages } from "../lib/stagesLib";
import { useBlurDropdown } from "../lib/hooksLib";
import { getAppActivityUrl } from "../lib/urlLib";
import "./ActivityFilterSelect.css";

const defaultStage = "select a stage";
const defaultService = "select a service";

export default function ActivityFilterSelect({
  stages,
  onClear,
  services,
  onSelect,
  pathParams,
  filter = null,
}) {
  const { ownerId, appId } = pathParams;

  const filtered = filter !== null;

  const buttonEl = useRef(null);
  const selectEl = useRef(null);

  const [open, setOpen] = useState(false);

  useBlurDropdown({
    toggleEl: () => ReactDOM.findDOMNode(buttonEl.current),
    selectEl: () => selectEl.current,
    onClick: () => setOpen(false),
  });

  // Get top stages
  stages = stages && sortStages(stages, { filterDeleted: true });

  function handleFieldEdit(e) {
    const { id: type, value } = e.target;
    setOpen(false);
    onSelect(type, value);
  }

  //  TODO
  //  function renderViewAllButton() {
  //    return (
  //      <Link to={ getAppActivityUrl(ownerId, appId) }>
  //        <LoaderButton className="all" bsStyle="block">
  //          View all activity
  //        </LoaderButton>
  //      </Link>
  //    );
  //  }

  function renderFilterOptions() {
    return (
      <>
        <SectionHeader>View</SectionHeader>
        {stages.slice(0, 3).map(({ name }) => (
          <Link
            key={name}
            to={getAppActivityUrl(ownerId, appId, { stage: name })}
          >
            <LoaderButton className="option" bsSize="small" bsStyle="block">
              {name}
            </LoaderButton>
          </Link>
        ))}
        <LoaderButton
          bsSize="small"
          bsStyle="link"
          className="other"
          onClick={() => setOpen(!open)}
          ref={(el) => (buttonEl.current = el)}
        >
          Other
          <CaretIcon direction="down" />
        </LoaderButton>
        {open && (
          <div className="select" ref={(el) => (selectEl.current = el)}>
            <FormGroup controlId="stage">
              <FormControl
                componentClass="select"
                value={defaultStage}
                onChange={handleFieldEdit}
              >
                <option disabled value={defaultStage}>
                  Select a stage
                </option>
                {stages.map(({ name }) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </FormControl>
            </FormGroup>
            <div className="or">
              <hr />
              <span>or</span>
            </div>
            <FormGroup controlId="service">
              <FormControl
                componentClass="select"
                value={defaultService}
                onChange={handleFieldEdit}
              >
                <option disabled value={defaultService}>
                  Select a service
                </option>
                {services.map(({ service_name }) => (
                  <option key={service_name} value={service_name}>
                    {service_name}
                  </option>
                ))}
              </FormControl>
            </FormGroup>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="ActivityFilterSelect">
      {!filtered && renderFilterOptions()}
    </div>
  );
}
