import React, { Component } from "react";
import {
  Button,
  Checkbox,
  HelpBlock,
  FormGroup,
  FormControl,
  ButtonGroup,
} from "react-bootstrap";
import { truncate } from "../lib/stringLib";
import { testStageName } from "../lib/regexLib";
import { trimFields, areFieldsNotEmpty } from "../lib/formLib";
import LoaderButton from "./LoaderButton";
import SectionHeader from "./SectionHeader";
import NewAppErrorPanel from "./NewAppErrorPanel";
import StyledControlLabel from "./StyledControlLabel";
import NewIamRoleHelpButton from "./NewIamRoleHelpButton";
import "./NewAppConfigForm.css";

const configOptionManual = "manual";
const configOptionInherit = "inherit";

const headerCopyMap = {
  dev: "Add a development stage",
  prod: "Add a production stage",
};

const noop = () => {};

const defaultProps = {
  apps: null,
  disabled: false,
  createError: null,
  isCreating: false,
  onCreateClick: noop,
  onAddStageClick: noop,
  onEditStageClick: noop,
  onShowIamRoleClick: noop,
};

const defaultInheritApp = "select app";

const defaultState = {
  configOption: configOptionManual,
  formData: {
    dev_stage_name: "dev",
    dev_iam_role: "",
    prod_stage_name: "prod",
    prod_iam_role: "",
    same_as_dev: true,
    inherit_app_id: "",
  },
  dirty: false,
  validationError_dev: null,
  validationError_prod: null,
};

export default class NewAppConfigForm extends Component {
  state = { ...defaultState };

  formatFormData() {
    const formData = this.state.formData;

    if (this.state.configOption === configOptionManual) {
      const fields = { ...formData };
      delete fields.inherit_app_id;

      if (fields.same_as_dev) {
        delete fields.prod_iam_role;
      }

      delete fields.same_as_dev;

      return trimFields(fields);
    } else {
      return { inherit_app_id: formData.inherit_app_id };
    }
  }

  canSubmit() {
    const formData = this.formatFormData();

    return this.state.configOption === configOptionManual
      ? areFieldsNotEmpty(formData)
      : formData.inherit_app_id !== "";
  }

  validateFields(fields) {
    let valid = true;

    if (this.state.configOption === configOptionManual) {
      if (!testStageName(fields.dev_stage_name)) {
        this.setState({ validationError_dev: "error" });
        valid = false;
      }

      if (!testStageName(fields.prod_stage_name)) {
        this.setState({ validationError_prod: "error" });
        valid = false;
      } else if (fields.prod_stage_name === fields.dev_stage_name) {
        this.setState({ validationError_prod: "error_same" });
        valid = false;
      }
    } else {
      if (fields.inherit_app_id === "") {
        valid = false;
      }
    }

    return valid;
  }

  handleFieldChange = (event) => {
    const { id, value, type, checked } = event.target;

    const validation =
      id === "dev_stage_name"
        ? { validationError_dev: null }
        : id === "prod_stage_name"
        ? { validationError_prod: null }
        : {};

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: type === "checkbox" ? checked : value,
      },
      ...validation,
    });
  };

  handleInheritClick = (event) => {
    this.setState({ configOption: configOptionInherit });
  };

  handleManualClick = (event) => {
    this.setState({ configOption: configOptionManual });
  };

  handleCreateClick = async (event) => {
    event.preventDefault();

    if (!this.canSubmit()) {
      return;
    }

    const formData = this.formatFormData();

    if (!this.validateFields(formData)) {
      return;
    }

    await this.props.onCreateClick(event, formData);
  };

  renderConfigOptions() {
    const { disabled } = this.props;
    const { configOption } = this.state;

    return (
      <ButtonGroup>
        <Button
          disabled={disabled}
          onClick={this.handleManualClick}
          active={configOption === configOptionManual}
        >
          Configure Manually
        </Button>
        <Button
          disabled={disabled}
          onClick={this.handleInheritClick}
          active={configOption === configOptionInherit}
        >
          Copy Settings
        </Button>
      </ButtonGroup>
    );
  }

  renderIamForm(type, disabled = false) {
    const { formData } = this.state;
    const { createError, onShowIamRoleClick } = this.props;

    const validationError = this.state[`validationError_${type}`];
    const formattedValidateError = validationError !== null ? "error" : null;

    const disabledCs = disabled ? "disabled" : "";
    const fields = {
      stage_name: `${type}_stage_name`,
      iam_role: `${type}_iam_role`,
      same_as_dev: "same_as_dev",
    };
    const codeCopy = formData[fields.stage_name]
      ? truncate(formData[fields.stage_name], 30)
      : "$STAGE_NAME";

    const errorMessage = validationError
      ? validationError === "error_same"
        ? "Stage names cannot be the same."
        : "Stage names must start with a letter or number and can only contain letters, numbers, and hyphens."
      : null;

    return (
      <div className={`stage-iam-container ${type} ${disabledCs}`}>
        <SectionHeader className="copy">{headerCopyMap[type]}</SectionHeader>
        <div className="stage-iam">
          <FormGroup
            bsSize="large"
            controlId={fields.stage_name}
            validationState={formattedValidateError}
          >
            <div className="label-container">
              <StyledControlLabel>Stage Name</StyledControlLabel>
              <HelpBlock>
                Deployed to&nbsp;
                <code>`--stage {codeCopy}`</code>
              </HelpBlock>
            </div>
            <FormControl
              disabled={disabled}
              onChange={this.handleFieldChange}
              value={formData[fields.stage_name]}
            />
            {formattedValidateError && <HelpBlock>{errorMessage}</HelpBlock>}
          </FormGroup>
          {type === "prod" && (
            <>
              <FormGroup>
                <Checkbox
                  disabled={disabled}
                  id={fields.same_as_dev}
                  onChange={this.handleFieldChange}
                  checked={formData[fields.same_as_dev]}
                >
                  Use the same IAM credentials as the dev stage
                </Checkbox>
              </FormGroup>
              {!formData.same_as_dev && <hr />}
            </>
          )}
          {(type === "dev" || !formData.same_as_dev) && (
            <>
              <FormGroup bsSize="large" controlId={fields.iam_role}>
                <div className="label-container">
                  <StyledControlLabel>AWS IAM Role</StyledControlLabel>
                  <NewIamRoleHelpButton
                    disabled={disabled}
                    onClick={onShowIamRoleClick}
                    className="btnShowIamRolePanel"
                  />
                </div>
                <FormControl
                  type="text"
                  disabled={disabled}
                  onChange={this.handleFieldChange}
                  value={formData[fields.iam_role]}
                />
              </FormGroup>
            </>
          )}
          {createError && type === "dev" && (
            <NewAppErrorPanel error={createError} />
          )}
        </div>
      </div>
    );
  }

  renderIamForms() {
    const { disabled } = this.props;

    return (
      <div className="tab-manual">
        {this.renderIamForm("dev", disabled)}
        {this.renderIamForm("prod", disabled)}
      </div>
    );
  }

  renderAppInheritForm() {
    const { formData } = this.state;

    let selectedSlug;
    if (formData.inherit_app_id) {
      const app = this.props.apps.find(
        (app) => app.appId === formData.inherit_app_id
      );
      selectedSlug = app && app.slug;
    }

    return (
      <div className="tab-inherit">
        <FormGroup controlId="inherit_app_id">
          <FormControl
            bsSize="large"
            componentClass="select"
            value={formData.inherit_app_id || defaultInheritApp}
            onChange={this.handleFieldChange}
          >
            <option disabled value={defaultInheritApp}>
              Select an app to copy the settings from
            </option>
            {this.props.apps.map((app, i) => (
              <option key={i} value={app.appId}>
                {app.slug}
              </option>
            ))}
          </FormControl>
          {selectedSlug && (
            <div className="inherit-copy">
              <p>
                The following will be copied from <b>{selectedSlug}</b>:
              </p>
              <ul className="fa-ul">
                <li>
                  <i className="fa-li fa fa-check"></i>
                  Stages
                </li>
                <li>
                  <i className="fa-li fa fa-check"></i>
                  CI settings
                </li>
                <li>
                  <i className="fa-li fa fa-check"></i>
                  IAM settings
                </li>
                <li>
                  <i className="fa-li fa fa-check"></i>
                  Stage IAM settings
                </li>
                <li>
                  <i className="fa-li fa fa-check"></i>
                  Stage post-deploy phase
                </li>
                <li>
                  <i className="fa-li fa fa-check"></i>
                  Stage notification settings
                </li>
                <li>
                  <i className="fa-li fa fa-check"></i>
                  Stage environment variables
                </li>
              </ul>
            </div>
          )}
        </FormGroup>
      </div>
    );
  }

  render() {
    const { configOption } = this.state;
    const { apps, disabled, isCreating } = this.props;

    const isFirstApp = apps.length === 0;
    const disabledCs = disabled ? "disabled" : "";

    return (
      <form
        onSubmit={this.handleCreateClick}
        className={`NewAppConfigForm ${disabledCs}`}
      >
        {/* Case 1: adding first app */}
        {isFirstApp && this.renderIamForms()}

        {/* Case 2: adding non-first app */}
        {!isFirstApp && (
          <>
            <p className="copy">
              Do you want to configure the settings manually or copy them from
              another app?
            </p>
            <div className="form-container">
              {this.renderConfigOptions()}
              {configOption === configOptionInherit &&
                this.renderAppInheritForm()}
              {configOption === configOptionManual && this.renderIamForms()}
            </div>
          </>
        )}

        <div className="controls">
          <LoaderButton
            type="submit"
            bsSize="large"
            loading={isCreating}
            onClick={this.handleCreateClick}
            disabled={disabled || !this.canSubmit()}
            bsStyle={disabled ? undefined : "warning"}
          >
            Add a New App
          </LoaderButton>
        </div>
      </form>
    );
  }
}

NewAppConfigForm.defaultProps = defaultProps;
