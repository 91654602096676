const isProd = process.env.REACT_APP_STAGE === "production";

const lambdaRuntimes = {
  "python2.7": "Python 2.7",
  "python3.6": "Python 3.6",
  "python3.7": "Python 3.7",
  "python3.8": "Python 3.8",
  "ruby2.5": "Ruby 2.5",
  "ruby2.7": "Ruby 2.7",
  "go1.x": "Go",
  "dotnetcore2.1": ".NET Core 2.1",
  "dotnetcore3.1": ".NET Core 3.1",
  java8: "Java 8",
  java11: "Java 11",
  "nodejs6.10": "Node.js 6.10",
  "nodejs8.10": "Node.js 8.10",
  "nodejs10.x": "Node.js 10.x",
  "nodejs12.x": "Node.js 12.x",
};

const activePlanIds = ["101", "109", "enterprise_v2", "108"];
const pricingPlans = {
  101: {
    name: "Individual",
    desc: "Free forever",
    baseUsage: 500,
    active: true,
  },
  102: {
    name: "Individual",
    desc: "$7 / month",
    active: false,
  },
  103: {
    name: "Standard",
    desc: "$47 / month",
    active: false,
  },
  109: {
    name: "Team",
    desc:
      "Starts at $30 / month. Includes 3 users, add a user for $10 / month.",
    baseSeats: 3,
    baseAmount: 30,
    baseUsage: 4500,
    perSeatAmount: 10,
    perSeatUsage: 1500,
    active: true,
  },
  104: {
    name: "Growth",
    desc: "$197 / month",
    active: false,
  },
  105: {
    name: "Pro",
    desc: "$997 / month",
    active: false,
  },
  106: {
    name: "Startup",
    desc: "$95 / month",
    active: false,
  },
  107: {
    name: "Growth",
    desc: "$390 / month",
    active: false,
  },
  enterprise: {
    name: "Enterprise",
    desc:
      "Starts at $300 / month. Includes 5 users, add a user for $15 / month.",
    baseSeats: 5,
    baseAmount: 300,
    baseUsage: 15000,
    perSeatAmount: 15,
    perSeatUsage: 1500,
    active: false,
  },
  enterprise_v2: {
    name: "Enterprise",
    desc:
      "Starts at $600 / month. Includes 10 users, add a user for $15 / month.",
    baseSeats: 10,
    baseAmount: 600,
    baseUsage: 30000,
    perSeatAmount: 15,
    perSeatUsage: 1500,
    active: true,
  },
  108: {
    name: "Custom",
    desc: "Custom pricing",
    disabledDesc:
      "Contact us and we can create a custom plan tailored to your needs.",
    baseSeats: 5,
    active: true,
  },
};
const activePlanAddons = [
  "GitHubEnterprise",
  "GitLabEnterprise",
  "SingleSignOn",
  "PrioritySupport",
  "SupportSLA_v2",
  "UptimeSLA",
];
const planAddons = {
  GitHubEnterprise: {
    name: "GitHub Enterprise",
    amount: 100,
    active: true,
  },
  GitLabEnterprise: {
    name: "GitLab Self-Managed",
    amount: 100,
    active: true,
  },
  SingleSignOn: {
    name: "Single-sign on",
    amount: 500,
    active: true,
  },
  PrioritySupport: {
    name: "Priority Support",
    amount: 500,
    active: true,
  },
  SupportSLA: {
    name: "Support SLA",
    amount: 1000,
    active: false,
  },
  SupportSLA_v2: {
    name: "Support SLA",
    amount: 4000,
    active: true,
  },
  UptimeSLA: {
    name: "Uptime SLA",
    amount: 2000,
    active: true,
  },
};

const userPermissions = {
  admin: { name: "Admin", value: "admin" },
  billing: { name: "Billing", value: "billing" },
  dev_manage: { name: "Manage", value: "dev_manage" },
  dev_deploy: { name: "Deploy", value: "dev_deploy" },
  staging_manage: { name: "Manage", value: "staging_manage" },
  staging_deploy: { name: "Deploy", value: "staging_deploy" },
  staging_promote: { name: "Promote", value: "staging_promote" },
  prod_manage: { name: "Manage", value: "prod_manage" },
  prod_deploy: { name: "Deploy", value: "prod_deploy" },
  prod_promote: { name: "Promote", value: "prod_promote" },
};

const serviceSearchErrors = {
  SLS_NOT_FOUND: 1023,
  SLS_CANNOT_PARSE: 1024,
  SLS_INVALID_SERVICE_NAME: 1026,
  SLS_JS_NOT_SUPPORTED: 1027,
  GENERIC_NOT_FOUND: "project_definition_file_not_found",
  SST_NOT_FOUND: "project_sst_file_not_found",
  SST_CANNOT_PARSE: "project_sst_file_cannot_parse",
  SST_INVALID_SERVICE_NAME: "project_sst_file_invalid_service_name",
  UNKNOWN: 9999,
};

const gitAuthUrls = {
  github: {
    domain: "github.com",
    path: "/login/oauth/authorize?response_type=code&scope=repo",
  },
  bitbucket: {
    domain: "bitbucket.org",
    path: "/site/oauth2/authorize?response_type=code",
  },
  gitlab: {
    domain: "gitlab.com",
    path: "/oauth/authorize?response_type=code",
  },
};

const dev = {
  stripeKey: "pk_test_qjMfH75wPRZDtszXc7KflXUs",
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://cmq8acqs32.execute-api.us-east-1.amazonaws.com/staging",
  },
  stagesApiGateway: {
    REGION: "us-east-1",
    URL: "https://3sgrslik76.execute-api.us-east-1.amazonaws.com/staging",
  },
  appsApiGateway: {
    REGION: "us-east-1",
    URL: "https://flw5alwtu1.execute-api.us-east-1.amazonaws.com/staging",
  },
  v1ApiGateway: {
    URL: "https://api.staging.seed.run",
  },
  v2ApiGateway: {
    REGION: "us-east-1",
    URL: "https://apiv2.staging.seed.run",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Jw5lUuxG2",
    APP_CLIENT_ID: "4hbt6k63ttvuoccfq1gumui2i2",
    APP_WEB_DOMAIN: "auth-staging.seed.run",
    IDENTITY_POOL_ID: "us-east-1:699c0263-7ab5-427f-9387-4c149b926774",
    TOKEN_SCOPES_ARRAY: [
      "openid",
      "email",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    REDIRECT_URI_SIGNIN: "/login/sso-callback",
    REDIRECT_URI_SIGNOUT: "/login/sso-callback",
  },
  github: {
    client_id: "Iv1.9ce42345b9e9beb1",
    //client_id: 'dc4bd5eacf17877e02a4', // old github OAuth app
    install_url: "https://github.com/apps/test-seed-deploy/installations/new",
  },
  bitbucket: {
    client_id: "WqbwmvXa8gve99hK7G",
  },
  gitlab: {
    client_id:
      "8ee50d5f2e63e7d6725d87c7adec86a8f56dacd255acc65d638903fbc44ca1e9",
    redirect_uri: `${window.location.origin}/new/callback/gitlab`,
  },
  gitlabEE: {
    redirect_uri: `${window.location.origin}/new/callback/gitlab-enterprise`,
  },
  sentryDsn: "https://54fa16781f18476192498f02b27461ed@sentry.io/1405180",
};

const prod = {
  stripeKey: "pk_live_kDP9ID9pBjgpbGXOyPWxFYeu",
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://y70m3ridv5.execute-api.us-east-1.amazonaws.com/prod",
  },
  stagesApiGateway: {
    REGION: "us-east-1",
    URL: "https://unl0iohash.execute-api.us-east-1.amazonaws.com/prod",
  },
  appsApiGateway: {
    REGION: "us-east-1",
    URL: "https://ckjv8kmm1f.execute-api.us-east-1.amazonaws.com/prod",
  },
  v1ApiGateway: {
    URL: "https://api.seed.run",
  },
  v2ApiGateway: {
    REGION: "us-east-1",
    URL: "https://apiv2.seed.run",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_44AK9Jk0I",
    APP_CLIENT_ID: "l1t7f7la2lh57m8e40v9gb5ts",
    APP_WEB_DOMAIN: "auth.seed.run",
    IDENTITY_POOL_ID: "us-east-1:7750a6bc-adb7-480d-9d5b-1a0ff55b8001",
    TOKEN_SCOPES_ARRAY: [
      "openid",
      "email",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    REDIRECT_URI_SIGNIN: "/login/sso-callback",
    REDIRECT_URI_SIGNOUT: "/login/sso-callback",
  },
  github: {
    client_id: "Iv1.edb07eee2aa50a4e",
    //client_id: 'a5e0df2c59dbe212b2e8', // old github OAuth app
    install_url: "https://github.com/apps/seed-deploy/installations/new",
  },
  bitbucket: {
    client_id: "SmbqM68XhCys59eFsL",
  },
  gitlab: {
    client_id:
      "32e0878304a328b71b6955dbe48b19e2bac3a3ba896378567175211725f5786f",
    redirect_uri: "https://console.seed.run/new/callback/gitlab",
  },
  gitlabEE: {
    redirect_uri: "https://console.seed.run/new/callback/gitlab-enterprise",
  },
  sentryDsn: "https://fd6b95cb91be44a1b20c2ae7b465221b@sentry.io/255071",
};

const config = isProd ? prod : dev;

export default {
  isProd,
  planAddons,
  gitAuthUrls,
  pricingPlans,
  activePlanIds,
  lambdaRuntimes,
  userPermissions,
  activePlanAddons,
  serviceSearchErrors,
  postMessageSrc: "SEED.run",
  contactEmail: "contact@seed.run",
  ...config,
};
