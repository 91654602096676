import React from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { MenuItem } from "react-bootstrap";
import FaIcon from "./FaIcon";
import RightChevron from "./RightChevron";
import PipelineDropdownButton from "./PipelineDropdownButton";
import { shortHash } from "../lib/gitLib";
import { getAppStageBuildServiceUrl } from "../lib/urlLib";
import "./PipelineTableBuildCell.css";

function statusToGlyphicon(statusState) {
  const textArray = {
    skipped: "minus-circle",
    completed: "check-circle",
    in_progress: "cog fa-spin",
    failed: "times-circle",
  };

  return textArray[statusState];
}

export default ({
  service,
  appSlug,
  appStage,
  onPromote,
  ownerName,
  empty = false,
  dropup = false,
  downstreamStages,
  onManualDeploy,
  inactive = false,
  deployment = null,
}) => {
  const inactiveCs = inactive ? "inactive" : "";

  const buildId = deployment && deployment.build_id;
  const stageName = appStage && appStage.name;
  const buildUrl =
    deployment &&
    getAppStageBuildServiceUrl(
      ownerName,
      appSlug,
      stageName,
      buildId,
      service.name
    );

  return (
    <div className={`PipelineTableBuildCell ${inactiveCs}`}>
      <div className="status">
        {(!deployment || empty) && <p className="empty">&#8212;</p>}
        {deployment && (
          <>
            <Link to={buildUrl}>
              <FaIcon
                className="icon"
                name={statusToGlyphicon(deployment.status)}
              />
              <span className="id">v{buildId}</span>
            </Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={deployment.commit.url}
            >
              <span className="commit">{shortHash(deployment.commit.id)}</span>
            </a>
          </>
        )}
      </div>
      {!empty && (
        <PipelineDropdownButton
          noCaret
          pullRight
          dropup={dropup}
          id={`build-dropdown-${buildId}`}
          title={<FaIcon name="chevron-down" />}
        >
          <MenuItem eventKey="1" onClick={onManualDeploy}>
            Deploy Service
          </MenuItem>
          {downstreamStages.length > 1 && <MenuItem divider />}
          {!appStage.is_production &&
            downstreamStages.map((stage) => (
              <MenuItem
                key={stage.name}
                eventKey={stage.name}
                disabled={deployment === null}
                onSelect={() => onPromote(appStage, stage, service)}
              >
                Promote to {stage.name}
              </MenuItem>
            ))}
          {deployment && (
            <>
              <MenuItem divider />
              <LinkContainer to={buildUrl}>
                <MenuItem eventKey="3">
                  View Build Logs <RightChevron />
                </MenuItem>
              </LinkContainer>
            </>
          )}
        </PipelineDropdownButton>
      )}
    </div>
  );
};
