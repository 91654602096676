import React from "react";
import { Bar, YAxis, Tooltip, BarChart, ResponsiveContainer } from "recharts";
import StyledControlLabel from "./StyledControlLabel";
import "./IssuesBarGraph.css";

export const STYLES = {
  NORMAL: "normal",
  WIDE: "wide",
};

const height = {
  normal: 26,
  wide: 40,
};
const tooltipPos = {
  normal: 37,
  wide: 51,
};
const margins = { top: 0, right: 0, left: 0, bottom: 0 };

function areEqual(props, newProps) {
  const data = props.data;
  const newData = newProps.data;

  return (
    data.length === newData.length &&
    data[0].t === newData[0].t &&
    data[0].v === newData[0].v &&
    data[data.length - 1].t === newData[newData.length - 1].t &&
    data[data.length - 1].v === newData[newData.length - 1].v
  );
}

function IssuesBarGraph({
  data = [],
  duration = "day",
  style = STYLES.NORMAL,
}) {
  const tooltipProps =
    style === STYLES.WIDE
      ? {
          wrapperStyle: { left: 0, zIndex: 1 },
        }
      : {
          wrapperStyle: { left: "-70px", zIndex: 1 },
          allowEscapeViewBox: { x: true, y: true },
        };

  function renderTooltip({ active, label, payload }) {
    if (!active || payload.length === 0) {
      return null;
    }

    const { t, v } = payload[0].payload;

    const date = new Date(t);
    const dayCopy = date.toLocaleString("en-us", {
      day: "numeric",
      month: "short",
      year: "numeric",
      timeZone: "UTC",
    });
    const hourCopy =
      duration === "hour"
        ? date.toLocaleString("en-us", {
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
          }) +
          " — " +
          new Date(t + 59 * 60 * 1000).toLocaleString("en-us", {
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
          })
        : null;

    return (
      <div className="tip">
        <div className="duration">
          <p className="day">{dayCopy}</p>
          {hourCopy && <p className="hour">{hourCopy}</p>}
        </div>
        <p className="count">
          <StyledControlLabel>Errors</StyledControlLabel>
          <span>{v}</span>
        </p>
      </div>
    );
  }

  return (
    <div className={`IssuesBarGraph ${style}`}>
      <ResponsiveContainer width="100%" height={height[style]}>
        <BarChart data={data} margin={margins} barCategoryGap="2.941%">
          <defs>
            <linearGradient id="colorDefault" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#53437D" stopOpacity={0.5} />
              <stop offset="95%" stopColor="#53437D" stopOpacity={0.35} />
            </linearGradient>
          </defs>
          <Tooltip
            cursor={false}
            content={renderTooltip}
            isAnimationActive={false}
            position={{ y: tooltipPos[style] }}
            {...tooltipProps}
          />
          <YAxis hide domain={[0, 5]} />
          <Bar
            dataKey="v"
            minPointSize={1}
            fill="url(#colorDefault)"
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default React.memo(IssuesBarGraph, areEqual);
