import React from "react";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import StyledButtonGroup from "./StyledButtonGroup";
import "./IssuesFilterSelect.css";

export const MODES = {
  ACTIVE: "active",
  MUTED: "muted",
  RESOLVED: "resolved",
};

export default function IssuesFilterSelect({ getUrl, currentMode }) {
  const urls = Object.keys(MODES).map((key) => getUrl(MODES[key]));

  return (
    <StyledButtonGroup className="IssuesFilterSelect">
      <LinkContainer isActive={() => currentMode === MODES.ACTIVE} to={urls[0]}>
        <Button>Active</Button>
      </LinkContainer>
      <LinkContainer isActive={() => currentMode === MODES.MUTED} to={urls[1]}>
        <Button>Ignored</Button>
      </LinkContainer>
      <LinkContainer
        isActive={() => currentMode === MODES.RESOLVED}
        to={urls[2]}
      >
        <Button>Resolved</Button>
      </LinkContainer>
    </StyledButtonGroup>
  );
}
