import { useRef } from "react";
import { errorHandler } from "../lib/errorLib";

export default function ErrorAlert({ error }) {
  const shown = useRef(false);

  if (!shown.current) {
    errorHandler(error);
    shown.current = true;
  }

  return null;
}
