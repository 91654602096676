import ReactDOM from "react-dom";
import React, { useRef, useMemo, useState } from "react";
import { Form, HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import TextButton from "./TextButton";
import CaretIcon from "./CaretIcon";
import FaIcon from "./FaIcon";
import { useFormReducer, useBlurDropdown } from "../lib/hooksLib";
import { parseDateString } from "../lib/timeLib";
import "./LogDurationSelect.css";

function pad(num) {
  return num.toString().padStart(2, "0");
}

export default function LogDurationSelect({ onChange, value = "" }) {
  const selectEl = useRef(null);
  const buttonEl = useRef(null);

  const [open, setOpen] = useState(false);
  const [
    {
      values: formData,
      isDirty: { time: isDirty },
      validation,
    },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ time: value });

  useBlurDropdown({
    toggleEl: () => ReactDOM.findDOMNode(buttonEl.current),
    selectEl: () => selectEl.current,
    onClick: () => setOpen(false),
  });

  const options = useMemo(() => {
    const date = new Date();

    const day = date.getDate();
    const hours = date.getHours();
    const month = date.getMonth();
    const minutes = date.getMinutes();
    const year = date.getFullYear();

    return [
      "15mins",
      "5 hours",
      // 7:10 pm
      date
        .toLocaleString("en-us", {
          hour: "numeric",
          minute: "numeric",
        })
        .toLowerCase(),
      // Aug 22 7:10pm
      date
        .toLocaleString("en-us", {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        .replace(/ AM$/, "am")
        .replace(/ PM$/, "pm")
        .replace(/,/, ""),
      // 2019/08/22 19:10
      `${year}/${pad(month + 1)}/${pad(day)} ${pad(hours)}:${pad(minutes)}`,
      // Aug 22 7:10pm - 7:15pm
      `${date.toLocaleString("en-us", {
        month: "short",
        day: "numeric",
      })} 7:10pm - 7:15pm`,
      // Aug 22, 2019 07:10:53 PM UTC
      date.toLocaleString("en-us", {
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        year: "numeric",
        timeZone: "UTC",
        timeZoneName: "short",
      }),
      // 1503443453
      Math.floor(date.getTime() / 1000),
    ];
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    setNewSpan(formData.time);
  }

  function handleOptionClick(e) {
    formDispatch({
      id: "time",
      type: "edit",
      value: e.target.innerText,
    });
  }

  function setNewSpan(span) {
    const parsed = parseDateString(span);

    if (!parsed && span !== "") {
      formDispatch({
        id: "time",
        type: "validate",
      });
      return;
    }

    setOpen(false);

    // Ignore if the value hasn't changed
    if (isDirty) {
      onChange(span);
    }
  }

  function renderOptions() {
    return options.map((option) => (
      <LoaderButton key={option} bsSize="xsmall" onClick={handleOptionClick}>
        {option}
      </LoaderButton>
    ));
  }

  return (
    <div className="LogDurationSelect">
      <LoaderButton
        bsSize="large"
        active={open}
        onClick={() => setOpen(!open)}
        ref={(el) => (buttonEl.current = el)}
      >
        <FaIcon name="calendar-o" />
        <span className="copy">{value !== "" ? value : "Live"}</span>
        <CaretIcon direction={open ? "up" : "down"} />
      </LoaderButton>
      {open && (
        <div className="select" ref={(el) => (selectEl.current = el)}>
          <Form onSubmit={handleSubmit}>
            <FormGroup controlId="time" validationState={validation.time}>
              <FormControl
                autoFocus
                type="text"
                autoComplete="off"
                value={formData.time}
                onChange={handleFieldChange}
                placeholder="Select a date, time, or range"
              />
              {validation.time && (
                <HelpBlock>Please enter a valid date.</HelpBlock>
              )}
            </FormGroup>
            <div className="options">{renderOptions()}</div>
            <hr />
            <div className="controls">
              <TextButton onClick={() => setOpen(false)}>Cancel</TextButton>
              <LoaderButton type="submit" onClick={handleSubmit}>
                Select
              </LoaderButton>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}
