import React from "react";
import "./TextButton.css";

const noop = () => {};

export default function TextButton({
  onClick,
  className = "",
  disabled = false,
  ...props
}) {
  const disabledCs = disabled ? "disabled" : "";

  return (
    <span
      onClick={disabled ? noop : onClick}
      className={`TextButton ${className} ${disabledCs}`}
      {...props}
    >
      {props.children}
    </span>
  );
}
