import React from "react";
import { Link } from "react-router-dom";
import { Glyphicon } from "react-bootstrap";
import RightChevron from "./RightChevron";
import SectionHeader from "./SectionHeader";
import MiniPipelineStageCell from "./MiniPipelineStageCell";
import { getAppPipelineUrl, getAppPipelineEditUrl } from "../lib/urlLib";
import "./MiniPipelineTable.css";

export default function MiniPipelineTable({
  onPromote,
  pathParams,
  emptyPipeline,
  appStages = [],
  onManualDeploy,
  onCancelDeploy,
  cancellingBuilds = {},
}) {
  const { ownerId, appId } = pathParams;

  // Sort stages by dev/staging/prod
  const { dev, staging, prod } = appStages.reduce(
    (sortedStages, stage) => {
      if (stage.is_production) {
        sortedStages.prod.push(stage);
      } else if (stage.is_staging) {
        sortedStages.staging.push(stage);
      } else {
        sortedStages.dev.push(stage);
      }

      return sortedStages;
    },
    { dev: [], staging: [], prod: [] }
  );

  const stageCountCs =
    Math.min(dev.length, 1) +
    Math.min(staging.length, 1) +
    Math.min(prod.length, 1);

  function renderStages(stages, downstreamStages = []) {
    if (stages.length === 0) {
      return null;
    }

    const label = stages[0].is_production
      ? "Production"
      : stages[0].is_staging
      ? "Staging"
      : "Development";

    return (
      <div key={label} className="part">
        <div className="title">
          <SectionHeader>{label}</SectionHeader>
          {!stages[0].is_production && <Glyphicon glyph="arrow-right" />}
        </div>
        <div className="body">
          {stages.map((stage) => (
            <MiniPipelineStageCell
              key={stage.name}
              appStage={stage}
              status={stage.status}
              pathParams={pathParams}
              emptyPipeline={emptyPipeline}
              onPromote={onPromote}
              downstreamStages={downstreamStages}
              cancelling={cancellingBuilds[stage.name]}
              onManualDeploy={() => onManualDeploy(stage)}
              onCancelDeploy={() => onCancelDeploy(stage)}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={`MiniPipelineTable count-${stageCountCs}`}>
      <h4>
        <span>
          Pipeline
          <Link to={getAppPipelineEditUrl(ownerId, appId)}>Edit</Link>
        </span>
        <Link to={getAppPipelineUrl(ownerId, appId)}>
          View Full Pipeline <RightChevron />
        </Link>
      </h4>
      <div className="list">
        {renderStages(dev, staging.length > 0 ? staging : prod)}
        {renderStages(staging, prod)}
        {renderStages(prod)}
      </div>
    </div>
  );
}
