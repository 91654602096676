import React from "react";
import Modal from "./Modal";
import FaIcon from "./FaIcon";
import LoaderButton from "./LoaderButton";
import "./EnforceTwoFactorModal.css";

export default function EnforceTwoFactorModal({ org, show, onCloseClick }) {
  return (
    <Modal show={show} onHide={onCloseClick} className="EnforceTwoFactorModal">
      <Modal.Body>
        <FaIcon name="exclamation-triangle" />
        <p>
          <b>{org}</b> requires two-factor authentication to be enabled. Please
          enable this in your settings.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <LoaderButton bsSize="large" onClick={onCloseClick}>
          Got it
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
