import React, { useEffect, useReducer } from "react";
import { Link, Redirect } from "react-router-dom";
import SettingsToggle from "./widgets/SettingsToggle";
import withCancel from "../components/ComponentWithCancel";
import withAppHeader from "../components/ComponentWithAppHeader";
import MachineSettingsPanel from "../components/MachineSettingsPanel";
import BuildImageSettingsPanel from "../components/BuildImageSettingsPanel";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import SectionGroupPanel from "../components/SectionGroupPanel";
import ServiceNameForm from "../components/ServiceNameForm";
import ServicePathForm from "../components/ServicePathForm";
import LoadingSpinner from "../components/LoadingSpinner";
import ScreenHeader from "../components/ScreenHeader";
import ErrorAlert from "../components/ErrorAlert";
import ItemDelete from "./widgets/ItemDelete";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import { errorHandler, getLoadError } from "../lib/errorLib";
import { appSettingsBreadcrumb } from "../lib/breadcrumbLib";
import {
  getAppUrl,
  getOrgBillingUrl,
  getAppPipelineUrl,
  getServiceRemoveUrl,
} from "../lib/urlLib";
import "./Services.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
  1001: "SERVICE_NOT_FOUND",
};

const settingDefaultState = { key: 0, updated: false, updating: false };

function settingReducer(state, action) {
  switch (action.type) {
    case "update":
      return { ...state, updating: true };
    case "update-failed":
      return { ...state, updating: false };
    case "update-done":
      return { ...state, updating: false, updated: true, key: state.key + 1 };
    default:
      return state;
  }
}

function Services(props) {
  let isLoading = true;
  let loadError = null;
  let serviceInfo = null;
  let isRemovingOrRemoveFailed = false;

  const { ownerId, appId, serviceId } = props.match.params;

  const [nameState, nameDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [pathState, pathDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [buildImageState, buildImageDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );

  const [machineState, machineDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );

  useEffect(() => {
    document.title = title(serviceId);
  }, [serviceId]);

  const {
    data,
    error,
    reload: reloadServiceInfo,
  } = useAPILoad(`/${ownerId}/${appId}/services/${serviceId}`, (path) =>
    props.invokeV2Apig({ path })
  );

  if (data !== null) {
    isLoading = false;

    // Case 1: deleting or delete_failed => redirect to status page
    if (
      data.service.status === "deleting" ||
      data.service.status === "delete_failed"
    ) {
      isRemovingOrRemoveFailed = true;
    }
    // Case 2: normal case
    else {
      serviceInfo = data;
    }
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  /////////
  // API //
  /////////

  function getServiceAPI() {
    return `/${ownerId}/${appId}/services/${serviceId}`;
  }

  function updateServiceInfo(data) {
    return props.invokeV2Apig({
      path: getServiceAPI(),
      method: "PUT",
      body: data,
    });
  }

  function removeService(fullRemove) {
    return props.invokeApig({
      path: getServiceAPI(),
      method: "DELETE",
      body: { mode: fullRemove ? undefined : "quick" },
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleServiceRemove(event, fullRemove) {
    await removeService(fullRemove);
    props.history.push(getAppPipelineUrl(ownerId, appId));
  }

  async function handleNameSaveClick(name) {
    nameDispatch({ type: "update" });

    try {
      await updateServiceInfo({ name });
      // Reloads the page in place with new route
      props.history.replace(`${getAppUrl(ownerId, appId)}/services/${name}`);
      nameDispatch({ type: "update-done" });
    } catch (e) {
      nameDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handlePathSaveClick(path) {
    pathDispatch({ type: "update" });

    try {
      await updateServiceInfo({ path });
      await reloadServiceInfo();
      pathDispatch({ type: "update-done" });
    } catch (e) {
      pathDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleBuildImageUpdateClick(buildImage) {
    buildImageDispatch({ type: "update" });
    try {
      await updateServiceInfo({ buildImage });
      await reloadServiceInfo();
      buildImageDispatch({ type: "update-done" });
    } catch (e) {
      buildImageDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleBuildMachineUpdateClick(buildMachine) {
    machineDispatch({ type: "update" });
    try {
      await updateServiceInfo({ buildMachine });
      await reloadServiceInfo();
      machineDispatch({ type: "update-done" });
    } catch (e) {
      machineDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleBuildDockerUpdated(value) {
    await updateServiceInfo({ buildDockerEnabled: value });
    await reloadServiceInfo();
  }

  return (
    <div className="Services">
      <ScreenHeader border breadcrumb={appSettingsBreadcrumb(props)}>
        {serviceId}
      </ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {!isLoading && isRemovingOrRemoveFailed && (
        <Redirect to={getServiceRemoveUrl(ownerId, appId, serviceId)} />
      )}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="service"
          code={loadError}
          context={{
            appName: appId,
            name: serviceId,
          }}
        />
      )}

      {error && !loadError && <ErrorAlert error={error} />}

      {!isLoading && serviceInfo && (
        <>
          <ServiceNameForm
            saving={nameState.updating}
            updated={nameState.updated}
            key={`name-${nameState.key}`}
            name={serviceInfo.service.name}
            onSaveClick={handleNameSaveClick}
            type={serviceInfo.service.serviceType}
          />
          <hr />
          <ServicePathForm
            saving={pathState.updating}
            updated={pathState.updated}
            key={`path-${pathState.key}`}
            onSaveClick={handlePathSaveClick}
            type={serviceInfo.service.serviceType}
            path={
              serviceInfo.service.git_path === ""
                ? "/"
                : serviceInfo.service.git_path
            }
          />
          <hr />
          <BuildImageSettingsPanel
            updated={buildImageState.updated}
            updating={buildImageState.updating}
            key={`image-${buildImageState.key}`}
            onUpdateClick={handleBuildImageUpdateClick}
            buildImage={serviceInfo.service.buildImage}
            activeBuildImages={serviceInfo.activeBuildImages}
            deprecatedBuildImages={serviceInfo.deprecatedBuildImages}
            customBuildImages={serviceInfo.customBuildImages}
          />
          <hr />
          <MachineSettingsPanel
            updated={machineState.updated}
            updating={machineState.updating}
            key={`machine-${machineState.key}`}
            onUpdateClick={handleBuildMachineUpdateClick}
            buildMachine={serviceInfo.service.buildMachine}
            showV2Only={serviceInfo.service.serviceType === "sst/resources"}
          />
          {serviceInfo.service.serviceType !== "sst/resources" && (
            <>
              <hr />
              <SettingsToggle
                sectionLabel="Docker"
                controlLabel="Enable Docker"
                helperDescription="Learn more about running Docker commands."
                helperLink="https://seed.run/docs/docker-commands-in-your-builds"
                sectionDescription="Allow Docker commands to be run as a part of the build process."
                available={serviceInfo.isBuildDockerAvailable}
                unavailableLabel={
                  <>
                    Please&nbsp;
                    <Link to={getOrgBillingUrl(ownerId)}>
                      upgrade to the Team plan
                    </Link>
                    &nbsp;to enable Docker.
                  </>
                }
                enabled={!!serviceInfo.service.buildDockerEnabled}
                onUpdated={handleBuildDockerUpdated}
              />
            </>
          )}
          <SectionGroupPanel important title="Admin">
            <ItemDelete
              type="service"
              onDelete={handleServiceRemove}
              itemName={serviceInfo.service.name}
            />
          </SectionGroupPanel>
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(Services));
