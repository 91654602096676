import React from "react";

const noop = () => {};

export function makeCancelable(promise) {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      // Don't do anything if cancelled
      (val) => (hasCanceled_ ? noop() : resolve(val)),
      (error) => (hasCanceled_ ? noop() : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}

/**
 * Promise.finally is missing on some older browsers
 * https://sentry.io/organizations/anomaly-innovations/issues/929607510
 * so adding a simple "polyfill" to handle it.
 * https://thecodebarbarian.com/using-promise-finally-in-node-js.html
 */
export function onFinally(promise, callback) {
  return promise.then(
    (result) => Promise.resolve(callback()).then(() => result),
    (err) =>
      Promise.resolve(callback()).then(() => {
        throw err;
      })
  );
}

export function delay(interval) {
  return new Promise((res) => {
    window.setTimeout(res, interval);
  });
}

export function delayLazy(fnImport) {
  return React.lazy(
    () =>
      new Promise((resolve) => {
        setTimeout(() => resolve(fnImport), 5000);
      })
  );
}
