import React from "react";
import FaIcon from "./FaIcon";
import TextButton from "./TextButton";
import SectionHeader from "./SectionHeader";
import config from "../config";
import "./PricingAddonsPanel.css";

const noop = () => {};

export default function PricingAddonsPanel({
  addons = {},
  addonIds = null,
  onSelect = noop,
}) {
  return (
    <div className="PricingAddonsPanel">
      <div className="header">
        <SectionHeader>Enterprise Add-Ons</SectionHeader>
        <a href={`mailto:${config.contactEmail}`}>Need help? Contact us</a>
      </div>
      <ul>
        {addonIds.map((addon) => (
          <li
            key={addon}
            onClick={() => onSelect(addon, !addons[addon])}
            className={`${addon} ${addons[addon] ? "selected" : ""}`}
          >
            <div>
              <div>
                <p>
                  <TextButton>{config.planAddons[addon].name}</TextButton>
                </p>
                <p>${config.planAddons[addon].amount} / month</p>
              </div>
              <div>{addons[addon] && <FaIcon name="check-circle" />}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
