import React, { useState, useEffect } from "react";
import TextButton from "./TextButton";
import LoaderToggle from "./LoaderToggle";
import AppBranchModal from "./AppBranchModal";
import { useFormReducer } from "../lib/hooksLib";
import "./AppBranchPanel.css";

export default function AppBranchPanel({
  editing,
  stages,
  enabling,
  disabling,
  gitProvider,
  branchEnabled,
  branchCleanupEnabled,
  branchInheritStageId,

  resetKey,
  onEditClick,
  onSaveClick,
  onCancelClick,
  ...props
}) {
  const [modalKey, setModalKey] = useState(0);

  const [
    { values: formData },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ branchEnabled });

  useEffect(() => {
    formDispatch({ type: "reset" });
  }, [resetKey, formDispatch]);

  useEffect(() => {
    if (editing) {
      setModalKey((modalKey) => modalKey + 1);
    }
  }, [editing]);

  function handleChange(e) {
    handleFieldChange(e);

    if (e.target.checked) {
      onEditClick();
    } else {
      onSaveClick({ branchEnabled: false });
    }
  }

  return (
    <div className="AppBranchPanel">
      <LoaderToggle
        id="branchEnabled"
        loading={disabling}
        onChange={handleChange}
        disabled={!gitProvider}
        checked={formData.branchEnabled}
      >
        Auto-deploy branches
      </LoaderToggle>
      {branchEnabled && (
        <span className="edit">
          .&nbsp;
          <TextButton onClick={onEditClick} disabled={enabling || disabling}>
            Edit settings
          </TextButton>
          .
        </span>
      )}
      {!gitProvider && (
        <span className="current">. Not connected to a Git repository.</span>
      )}

      <AppBranchModal
        key={`AppBranchModal-${modalKey}`}
        show={editing}
        stages={stages}
        saving={enabling}
        gitProvider={gitProvider}
        branchCleanupEnabled={branchCleanupEnabled}
        branchInheritStageId={branchInheritStageId}
        onCloseClick={onCancelClick}
        onEnableClick={onSaveClick}
      />
    </div>
  );
}
