import React, { useState } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import TextButton from "./TextButton";
import SectionInfo from "./SectionInfo";
import LoaderButton from "./LoaderButton";
import SectionDescriptionLabel from "./SectionDescriptionLabel";
import { useFormReducer } from "../lib/hooksLib";
import "./StageRegionPanel.css";

const defaultRegion = "default";
const regions = {
  "divider-0": true,
  "us-east-1": "US East (N. Virginia)",
  "us-east-2": "US East (Ohio)",
  "us-west-1": "US West (N. California)",
  "us-west-2": "US West (Oregon)",
  "ca-central-1": "Canada (Central)",
  "divider-1": true,
  "af-south-1": "Africa (Cape Town)",
  "divider-2": true,
  "ap-east-1": "Asia Pacific (Hong Kong)",
  "ap-south-1": "Asia Pacific (Mumbai)",
  "ap-northeast-1": "Asia Pacific (Tokyo)",
  "ap-northeast-2": "Asia Pacific (Seoul)",
  "ap-northeast-3": "Asia Pacific (Osaka-Local)",
  "ap-southeast-1": "Asia Pacific (Singapore)",
  "ap-southeast-2": "Asia Pacific (Sydney)",
  "divider-3": true,
  "eu-central-1": "Europe (Frankfurt)",
  "eu-west-1": "Europe (Ireland)",
  "eu-west-2": "Europe (London)",
  "eu-west-3": "Europe (Paris)",
  "eu-north-1": "Europe (Stockholm)",
  "divider-4": true,
  "me-south-1": "Middle East (Bahrain)",
  "divider-5": true,
  "sa-east-1": "South America (São Paulo)",
};

const helpUrl = "https://seed.run/docs/multi-region-deployments";

export default function StageRegionPanel({
  region = defaultRegion,

  updated = false,
  saving = false,
  onSaveClick,
}) {
  const [showStatus, setShowStatus] = useState(updated);
  const [
    { values: formData, isDirty },
    formDispatch,
    handleFieldChange,
  ] = useFormReducer({ region });

  const editing = isDirty.region;

  function handleCancelClick() {
    setShowStatus(false);
    formDispatch({ type: "reset" });
  }

  function handleUpdateClick(event) {
    event.preventDefault();

    const value = formData.region === defaultRegion ? null : formData.region;
    onSaveClick(value);
  }

  function renderForm() {
    return (
      <>
        {!editing && formData.region === defaultRegion && (
          <SectionDescriptionLabel>
            Deploying to the <b>default region</b> set in your config.
          </SectionDescriptionLabel>
        )}
        <Form className="form" onSubmit={handleUpdateClick}>
          <FormGroup controlId="region">
            <FormControl
              bsSize="large"
              disabled={saving}
              componentClass="select"
              value={formData.region}
              onChange={handleFieldChange}
            >
              <option value={defaultRegion}>Default region</option>
              {Object.keys(regions).map((key, i) =>
                key.indexOf("divider-") === 0 ? (
                  <option key={key} disabled>
                    ----------------------------------------
                  </option>
                ) : (
                  <option
                    key={key}
                    value={key}
                  >{`${regions[key]} ${key}`}</option>
                )
              )}
            </FormControl>
          </FormGroup>
          {!editing && showStatus && (
            <p className="status">Deployed region updated!</p>
          )}
          {editing && (
            <div className="controls">
              <LoaderButton
                type="submit"
                bsSize="large"
                loading={saving}
                onClick={handleUpdateClick}
              >
                Update
              </LoaderButton>
              <TextButton onClick={handleCancelClick}>Cancel</TextButton>
            </div>
          )}
        </Form>
      </>
    );
  }

  return (
    <div className="StageRegionPanel">
      <SectionInfo
        label="Deployed Region"
        description={
          <span>
            Edit the region this stage is deployed to.&nbsp;
            <a target="_blank" href={helpUrl} rel="noopener noreferrer">
              Learn about multi-region deployments.
            </a>
          </span>
        }
      >
        {renderForm()}
      </SectionInfo>
    </div>
  );
}
