import React, { useRef } from "react";
import "./DropdownSearchItem.css";

export default function DropdownSearchItem({ elRef, onHover, ...props }) {
  const hover = useRef(false);

  function handleOnMouseEnter(e) {
    if (!hover.current) {
      hover.current = true;
      onHover(e, true);
    }
  }

  function handleOnMouseLeave(e) {
    hover.current = false;
  }

  return (
    <span
      ref={elRef}
      className="DropdownSearchItem"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      {...props}
    >
      {props.children}
    </span>
  );
}
