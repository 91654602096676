import React, { useEffect, useReducer } from "react";
import { LinkContainer } from "react-router-bootstrap";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import ReportSettingsPanel from "../components/ReportSettingsPanel";
import withAppHeader from "../components/ComponentWithAppHeader";
import SectionGroupPanel from "../components/SectionGroupPanel";
import withCancel from "../components/ComponentWithCancel";
import LoadingSpinner from "../components/LoadingSpinner";
import RightChevron from "../components/RightChevron";
import ScreenHeader from "../components/ScreenHeader";
import LoaderButton from "../components/LoaderButton";
import OrgNameForm from "../components/OrgNameForm";
import OrgGitPanel from "../components/OrgGitPanel";
import SectionInfo from "../components/SectionInfo";
import ErrorAlert from "../components/ErrorAlert";
import OrgSSH from "./widgets/OrgSSH";
import OrgToken from "./widgets/OrgToken";
import ItemDelete from "./widgets/ItemDelete";
import title from "../lib/titleLib";
import useAPILoad from "../lib/apiLoadLib";
import { getOrgSettingsUrl } from "../lib/urlLib";
import { errorHandler, getLoadError } from "../lib/errorLib";
import "./OrgSettings.css";

const loadErrorCodes = {
  OrgNotExist: "OWNER_NOT_FOUND",
  4006: "OWNER_NO_PERMISSION",
};

const settingDefaultState = {
  key: 0,
  editing: false,
  updated: false,
  updating: false,
};

function settingReducer(state, action) {
  switch (action.type) {
    case "edit":
      return { ...state, editing: true };
    case "edit-done":
      return { ...state, editing: false };
    case "update":
      return { ...state, updating: true };
    case "update-done":
      return {
        ...state,
        updating: false,
        updated: true,
        editing: false,
        key: state.key + 1,
      };
    case "update-failed":
      return { ...state, updating: false };
    default:
      return state;
  }
}

const modalSettingDefaultState = {
  key: 0,
  editing: false,
  updated: false,
  enabling: false,
  disabling: false,
};

function modalSettingReducer(state, action) {
  switch (action.type) {
    case "edit":
      return { ...state, editing: true };
    case "edit-done":
      return { ...state, editing: false, key: state.key + 1 };
    case "enable":
      return { ...state, enabling: true };
    case "disable":
      return { ...state, disabling: true };
    case "update-done":
      return {
        ...state,
        enabling: false,
        disabling: false,
        updated: true,
        editing: false,
        key: state.key + 1,
      };
    case "update-failed":
      return {
        ...state,
        enabling: false,
        disabling: false,
        key: state.key + 1,
      };
    default:
      return state;
  }
}

function OrgSettings(props) {
  let owner = null;
  let isLoading = true;
  let loadError = null;

  const { ownerId } = props.match.params;

  const [nameState, nameDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [reportState, reportDispatch] = useReducer(
    settingReducer,
    settingDefaultState
  );
  const [githubState, githubDispatch] = useReducer(
    modalSettingReducer,
    modalSettingDefaultState
  );
  const [gitlabState, gitlabDispatch] = useReducer(
    modalSettingReducer,
    modalSettingDefaultState
  );

  useEffect(() => {
    document.title = title("Settings");
  }, []);

  const { data, error, reload: reloadOrgInfo } = useAPILoad(`/orgs/${ownerId}`);

  if (data !== null) {
    isLoading = false;

    owner = data.org;
  }

  if (error) {
    loadError = getLoadError(error, loadErrorCodes);
    if (loadError) {
      isLoading = false;
    }
  }

  const isReady = !isLoading && !loadError && owner;

  /////////
  // API //
  /////////

  function removeOrg() {
    return props.invokeApig({
      path: `/orgs/${ownerId}`,
      method: "DELETE",
    });
  }

  function updateOrg(data) {
    return props.invokeApig({
      method: "PUT",
      body: data,
      path: `/orgs/${ownerId}`,
    });
  }

  //////////////
  // Handlers //
  //////////////

  async function handleSshSave(event, { ssh_key }) {
    await updateOrg({ ssh_key });
    await reloadOrgInfo();
  }

  async function handleTokenSave({ token }) {
    await updateOrg({ token });
    await reloadOrgInfo();
  }

  async function handleOrgRemove(event) {
    await removeOrg();
    props.history.push("/");
  }

  async function handleNameSave(name) {
    nameDispatch({ type: "update" });

    try {
      await updateOrg({ name });
      // Reloads the page in place with new route
      props.history.replace(getOrgSettingsUrl(name));
      nameDispatch({ type: "update-done" });
    } catch (e) {
      nameDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleReportSave(reportType) {
    reportDispatch({ type: "update" });
    try {
      await updateOrg({ reportType });
      await reloadOrgInfo();
      reportDispatch({ type: "update-done" });
    } catch (e) {
      reportDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleGithubEdit() {
    githubDispatch({ type: "edit" });
  }
  async function handleGithubCancel() {
    githubDispatch({ type: "edit-done" });
  }
  async function handleGithubSave({ domain, clientId, clientSecret }) {
    githubDispatch({ type: domain ? "enable" : "disable" });

    try {
      await updateOrg({
        githubEnterpriseDomain: domain,
        githubEnterpriseClientId: clientId,
        githubEnterpriseClientSecret: clientSecret,
      });
      await reloadOrgInfo();
      githubDispatch({ type: "update-done" });
    } catch (e) {
      githubDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  async function handleGitlabEdit() {
    gitlabDispatch({ type: "edit" });
  }
  async function handleGitlabCancel() {
    gitlabDispatch({ type: "edit-done" });
  }
  async function handleGitlabSave({ domain, clientId, clientSecret }) {
    gitlabDispatch({ type: domain ? "enable" : "disable" });

    try {
      await updateOrg({
        gitlabEnterpriseDomain: domain,
        gitlabEnterpriseClientId: clientId,
        gitlabEnterpriseClientSecret: clientSecret,
      });
      await reloadOrgInfo();
      gitlabDispatch({ type: "update-done" });
    } catch (e) {
      gitlabDispatch({ type: "update-failed" });
      errorHandler(e);
    }
  }

  ////////////
  // Render //
  ////////////

  return (
    <div className="OrgSettings">
      <ScreenHeader border>Org Settings</ScreenHeader>

      {isLoading && <LoadingSpinner />}

      {error && !loadError && <ErrorAlert error={error} />}

      {!isLoading && loadError && (
        <ContainerErrorPanel
          type="owner"
          code={loadError}
          context={{
            owner: ownerId,
          }}
        />
      )}

      {isReady && (
        <>
          <OrgNameForm
            saving={nameState.updating}
            updated={nameState.updated}
            key={`name-${nameState.key}`}
            name={ownerId}
            isDefaultOrg={owner.isDefaultOrg}
            isMyDefaultOrg={owner.isMyDefaultOrg}
            onSaveClick={handleNameSave}
          />
          <hr />
          <SectionInfo
            label="Billing"
            description="Upgrade your plan and view usage details."
            button={
              <LinkContainer exact to={`/orgs/${ownerId}/settings/billing`}>
                <LoaderButton bsSize="large">
                  View Billing Info
                  <RightChevron />
                </LoaderButton>
              </LinkContainer>
            }
          />
          <hr />
          <OrgSSH onSave={handleSshSave} sshData={owner.sshData} />
          <hr />
          <OrgToken onSave={handleTokenSave} token={owner.token} />
          <hr />
          <ReportSettingsPanel
            resetKey={`ReportSettingsPanel-${reportState.key}`}
            email={props.authenticatedUser}
            reportType={owner.reportType}
            updating={reportState.updating}
            onUpdateClick={handleReportSave}
          />
          <hr />
          <OrgGitPanel
            type={"github"}
            owner={ownerId}
            domain={owner.githubEnterpriseDomain}
            clientId={owner.githubEnterpriseClientId}
            available={owner.isGithubEnterpriseAvailable}
            resetKey={`GithubPanel-${githubState.key}`}
            editing={githubState.editing}
            enabling={githubState.enabling}
            disabling={githubState.disabling}
            onEditClick={handleGithubEdit}
            onSaveClick={handleGithubSave}
            onCancelClick={handleGithubCancel}
          />
          <hr />
          <OrgGitPanel
            type={"gitlab"}
            resetKey={`GitlabPanel-${gitlabState.key}`}
            owner={ownerId}
            available={owner.isGitlabEnterpriseAvailable}
            domain={owner.gitlabEnterpriseDomain}
            clientId={owner.gitlabEnterpriseClientId}
            editing={gitlabState.editing}
            enabling={gitlabState.enabling}
            disabling={gitlabState.disabling}
            onEditClick={handleGitlabEdit}
            onSaveClick={handleGitlabSave}
            onCancelClick={handleGitlabCancel}
          />
          <SectionGroupPanel important title="Admin">
            <ItemDelete
              type="organization"
              itemName={ownerId}
              onDelete={handleOrgRemove}
              disabled={owner.isDefaultOrg}
              disabledMessage={
                owner.isMyDefaultOrg
                  ? "You cannot delete your personal organization."
                  : "You cannot delete a user's personal organization."
              }
            />
          </SectionGroupPanel>
        </>
      )}
    </div>
  );
}

export default withAppHeader(withCancel(OrgSettings));
