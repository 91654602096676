import React from "react";
import SectionHeader from "../components/SectionHeader";
import { formatDollars } from "../lib/stringLib";
import { dateToFullDateNoYear } from "../lib/timeLib";
import "./CurrentUsagePanel.css";

export default function CurrentUsagePanel({
  endDate,
  startDate,
  usageLimit,
  currentUsage,
  billAmount = null,
}) {
  const now = new Date();
  const timeInterval = endDate - startDate;
  const remainTime = Math.max(24 * 60 * 60 * 1000, now.getTime() - startDate);
  const projectedUsage = Math.round(currentUsage * (timeInterval / remainTime));

  const overLimit = currentUsage >= usageLimit;
  const usageCs = overLimit ? "warning" : "";

  return (
    <div className="CurrentUsagePanel">
      <div className="minutes">
        <div className="header">
          <SectionHeader>Build Minutes</SectionHeader>
          <p>
            {dateToFullDateNoYear(startDate)}
            &nbsp;&ndash;&nbsp;
            {dateToFullDateNoYear(endDate)}
          </p>
        </div>
        <div className="tiles">
          <div className={usageCs}>
            <SectionHeader>Used</SectionHeader>
            <p>{currentUsage}</p>
          </div>
          <div>
            <SectionHeader>Limit</SectionHeader>
            <p>{usageLimit}</p>
          </div>
          <div>
            <SectionHeader>Projected</SectionHeader>
            <p>{projectedUsage}</p>
          </div>
        </div>
      </div>
      {billAmount !== null && (
        <div className="amount">
          <SectionHeader>Amount</SectionHeader>
          <div>
            <p>
              <span>$</span>
              {formatDollars(billAmount)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
