import React, { Component } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { testUsername } from "../lib/regexLib";
import { errorHandler, isAPIErrorWithCode } from "../lib/errorLib";
import StyledControlLabel from "./StyledControlLabel";
import LoaderButton from "./LoaderButton";
import "./UsernameForm.css";

const USERNAME_TAKEN = 4005;
const INVALID_USERNAME = 4004;

const noop = () => {};

const defaultProps = {
  username: "",
  saving: false,
  onSaveClick: noop,
};

const defaultState = {
  formData: {
    username: "",
  },
  errorMessage: "",
  validation: {},
  isFieldDirty: {},
};

class UsernameForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      formData: {
        ...defaultState.formData,
        username: props.username,
      },
    };
  }

  handleFieldChange = (event) => {
    const { id, value } = event.target;
    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
      isFieldDirty: {
        ...this.state.isFieldDirty,
        [id]: true,
      },
      validation: {
        ...this.state.validation,
        [id]: null,
      },
      errorMessage: "",
    });
  };

  getEdittedFields() {
    const { formData, isFieldDirty } = this.state;

    if (isFieldDirty.username) {
      return {
        username: formData.username.trim(),
      };
    }

    return null;
  }

  validateFields(formData) {
    const isUsernameValid = testUsername(formData.username);

    if (!isUsernameValid) {
      const validation = { username: "error" };
      this.setState({
        validation: {
          ...this.state.validation,
          ...validation,
        },
        errorMessage: "invalid-username",
      });

      return false;
    }

    return formData;
  }

  handleSaveClick = async (event) => {
    event.preventDefault();

    const edittedFields = this.getEdittedFields();

    if (edittedFields === null) {
      return;
    }

    const validatedFields = this.validateFields(edittedFields);
    if (!validatedFields || !validatedFields.username) {
      return;
    }

    const { username } = validatedFields;

    try {
      await this.props.onSaveClick(event, username);
    } catch (e) {
      const validation = { username: "error" };
      let errorMessage = "";
      // Username doesn't follow requirements
      if (isAPIErrorWithCode(e, INVALID_USERNAME)) {
        errorMessage = "invalid-username";
      }
      // Username is taken
      else if (isAPIErrorWithCode(e, USERNAME_TAKEN)) {
        errorMessage = "username-taken";
      } else {
        errorHandler(e);
      }
      this.setState({
        validation: {
          ...this.state.validation,
          ...validation,
        },
        errorMessage,
      });
    }
  };

  render() {
    const { formData, validation, errorMessage, isFieldDirty } = this.state;
    const { saving, username } = this.props;

    return (
      <form className="UsernameForm" onSubmit={this.handleSaveClick}>
        <FormGroup
          bsSize="large"
          controlId="username"
          validationState={validation.username}
        >
          <StyledControlLabel>Username</StyledControlLabel>
          <FormControl
            autoFocus
            type="username"
            value={formData.username}
            placeholder="joe-smith"
            onChange={this.handleFieldChange}
          />
          {errorMessage === "invalid-username" && (
            <HelpBlock>
              Usernames must start and end with a letter or number and can only
              contain lowercase letters, numbers, and hyphens.
            </HelpBlock>
          )}
          {errorMessage === "username-taken" && (
            <HelpBlock>
              This username is already in use. Please pick another.
            </HelpBlock>
          )}
        </FormGroup>
        <div className="controls">
          <LoaderButton
            type="submit"
            bsSize="large"
            loading={saving}
            onClick={this.handleSaveClick}
            disabled={isFieldDirty.username !== true}
          >
            {username && "Update Username"}
            {!username && "Select Username"}
          </LoaderButton>
        </div>
      </form>
    );
  }
}

UsernameForm.defaultProps = defaultProps;

export default UsernameForm;
