import React, { Component } from "react";
import withAppHeader from "../components/ComponentWithAppHeader";
import withPolling from "../components/ComponentWithPolling";
import withCancel from "../components/ComponentWithCancel";
import ContainerErrorPanel from "../components/ContainerErrorPanel";
import CollapsableLogTextarea from "../components/CollapsableLogTextarea";
import ScreenHeader from "../components/ScreenHeader";
import SectionHeader from "../components/SectionHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import BuildInfoPanel from "../components/BuildInfoPanel";
import {
  appStageRemoveBreadcrumb,
  appServiceRemoveBreadcrumb,
} from "../lib/breadcrumbLib";
import { errorHandler, loadErrorHandler } from "../lib/errorLib";
import title from "../lib/titleLib";
import "./ServiceStageRemove.css";

const loadErrorCodes = {
  AppNotExist: "APP_NOT_FOUND",
  StageNotExist: "STAGE_NOT_FOUND",
  1001: "SERVICE_NOT_FOUND",
  1301: "SERVICE_STAGE_NOT_FOUND",
};

const defaultState = {
  isLoading: true,
  loadError: null,
  removeInfo: null,
};

class ServiceStageRemove extends Component {
  removeType = null;

  initialLoad = true;

  state = { ...defaultState };

  constructor(props) {
    super(props);

    const { appStageId, serviceId } = props.match.params;
    const removeType =
      props.match.path.indexOf("/status/stages/:appStageId") !== -1
        ? "service-remove-stage"
        : "stage-remove-service";

    document.title = title(
      removeType === "service-remove-stage"
        ? `Removing ${appStageId}`
        : `Removing ${serviceId}`
    );

    this.removeType = removeType;
  }

  async componentDidMount() {
    await this.props.poll.start(this.loadInfo);

    this.initialLoad = false;
    this.setState({ isLoading: false });
  }

  loadInfo = async () => {
    try {
      // load build info
      const removeInfo = await this.props.poll.register(this.getRemoveInfo());
      this.setState({ removeInfo });

      //Stop polling if logs are done loading
      if (!removeInfo.hasMore) {
        return false;
      }
    } catch (e) {
      if (
        loadErrorHandler({
          error: e,
          codes: loadErrorCodes,
          setState: this.setState.bind(this),
        })
      ) {
        return false;
      }

      if (this.initialLoad) {
        errorHandler(e);
      }
    }
  };

  getAppStageServiceRemoveLogAPI() {
    const { ownerId, appId, appStageId, serviceId } = this.props.match.params;
    return `/${ownerId}/${appId}/stages/${appStageId}/services/${serviceId}/delete_log`;
  }

  /////////
  // API //
  /////////

  getRemoveInfo() {
    return this.props.invokeAppsApig({
      path: this.getAppStageServiceRemoveLogAPI(),
    });
  }

  //////////////
  // Handlers //
  //////////////

  ////////////
  // Render //
  ////////////

  render() {
    const removeInfo = this.state.removeInfo;
    const { appId, appStageId, serviceId } = this.props.match.params;
    const { isLoading, loadError } = this.state;

    return (
      <div className="ServiceStageRemove">
        <ScreenHeader
          breadcrumb={
            this.removeType === "service-remove-stage"
              ? appServiceRemoveBreadcrumb(this.props)
              : appStageRemoveBreadcrumb(this.props)
          }
        >
          {this.removeType === "service-remove-stage" && (
            <>Removing {appStageId}</>
          )}
          {this.removeType === "stage-remove-service" && (
            <>Removing {serviceId}</>
          )}
        </ScreenHeader>

        {isLoading && <LoadingSpinner />}

        {!isLoading && loadError && (
          <ContainerErrorPanel
            type="serviceStageRemove"
            code={loadError}
            context={{
              appName: appId,
              name: serviceId,
              stageName: appStageId,
            }}
          />
        )}

        {!isLoading && !loadError && removeInfo && (
          <div>
            <BuildInfoPanel stage={removeInfo.stage} />

            <div className="build-log">
              <SectionHeader>Remove Log</SectionHeader>
              <CollapsableLogTextarea log={removeInfo.logs} loading={false} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withAppHeader(withPolling(withCancel(ServiceStageRemove)));
