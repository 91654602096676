import React, { Component } from "react";
import EnvVarsPanel from "../../components/EnvVarsPanel";
import { errorHandler } from "../../lib/errorLib";

const noop = () => {};

const defaultProps = {
  onLoadAll: noop,
  onGetOne: noop,
  onAdd: noop,
  onSave: noop,
  onRemove: noop,
};

const defaultState = {
  showing: false,
  loading: false,
  adding: false,
  keys: [],
  values: {},
  showings: {},
  savings: {},
  deletings: {},
};

class EnvVars extends Component {
  constructor(props) {
    super(props);

    this.state = { ...defaultState };
  }

  handleShowClick = async (event) => {
    this.setState({ loading: true });

    try {
      const results = await this.props.onLoadAll();
      const keys = results.names;
      const values = {};
      const showings = {};
      const savings = {};
      const deletings = {};
      keys.forEach((key) => {
        values[key] = null;
        showings[key] = false;
        savings[key] = false;
        deletings[key] = false;
      });

      this.setState({
        showing: true,
        loading: false,
        keys,
        values,
        showings,
        savings,
        deletings,
      });
    } catch (e) {
      errorHandler(e);
      this.setState({ loading: false });
    }
  };

  handleHideClick = (event) => {
    this.setState({ ...defaultState });
  };

  handleAddVarClick = async (event, { key, value }) => {
    this.setState({ adding: true });

    try {
      await this.props.onAdd(key, value);
      this.setState((prevState) => ({
        keys: [...prevState.keys, key],
        values: { ...prevState.values, [key]: null },
        showings: { ...prevState.showings, [key]: false },
        savings: { ...prevState.savings, [key]: false },
        deletings: { ...prevState.deletings, [key]: false },
      }));

      this.setState({ adding: false });
      return true;
    } catch (e) {
      errorHandler(e);
      this.setState({ adding: false });
      return false;
    }
  };

  handleShowVarClick = async (event, key, index) => {
    this.setState((prevState) => ({
      showings: { ...prevState.showings, [key]: true },
    }));

    let value = null;
    try {
      const results = await this.props.onGetOne(key);
      value = results.value;

      this.setState((prevState) => ({
        values: { ...prevState.values, [key]: value },
      }));
    } catch (e) {
      errorHandler(e);
    }

    this.setState((prevState) => ({
      showings: { ...prevState.showings, [key]: false },
    }));

    return value;
  };

  handleSaveVarClick = async (event, key, value, index) => {
    this.setState((prevState) => ({
      savings: { ...prevState.savings, [key]: true },
    }));

    try {
      await this.props.onSave(key, value);

      this.setState((prevState) => ({
        values: { ...prevState.values, [key]: null },
      }));
    } catch (e) {
      errorHandler(e);
    }

    this.setState((prevState) => ({
      savings: { ...prevState.savings, [key]: false },
    }));
  };

  handleDeleteVarClick = async (event, key, index) => {
    this.setState((prevState) => ({
      deletings: { ...prevState.deletings, [key]: true },
    }));

    try {
      await this.props.onRemove(key);

      this.setState((prevState) => {
        const keys = prevState.keys.filter((e) => e !== key);
        const values = { ...prevState.values };
        const showings = { ...prevState.showings };
        const savings = { ...prevState.savings };
        const deletings = { ...prevState.deletings };
        delete values[key];
        delete showings[key];
        delete savings[key];
        delete deletings[key];
        return { keys, values, showings, savings, deletings };
      });
    } catch (e) {
      errorHandler(e);

      this.setState((prevState) => ({
        deletings: { ...prevState.deletings, [key]: false },
      }));
    }
  };

  render() {
    const {
      showing,
      loading,
      adding,
      keys,
      values,
      showings,
      savings,
      deletings,
    } = this.state;
    return (
      <EnvVarsPanel
        showing={showing}
        loading={loading}
        adding={adding}
        keys={keys}
        values={values}
        showings={showings}
        savings={savings}
        deletings={deletings}
        onShowClick={this.handleShowClick}
        onHideClick={this.handleHideClick}
        onAddVarClick={this.handleAddVarClick}
        onShowVarClick={this.handleShowVarClick}
        onSaveVarClick={this.handleSaveVarClick}
        onDeleteVarClick={this.handleDeleteVarClick}
      />
    );
  }
}

EnvVars.defaultProps = defaultProps;

export default EnvVars;
