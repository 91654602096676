import React from "react";
import LoaderButton from "./LoaderButton";
import CollapsableLogEntry from "./CollapsableLogEntry";
import "./CollapsableLogTextarea.css";

function buildEntries(log) {
  let entries = [];
  let currentFold = null;
  log.split("\n").forEach((line) => {
    const type = line.substr(0, 10);

    // Handle error
    const hasError = type === "seed_erro:";

    // Transform line
    if (type === "seed_erro:") {
      if (line.startsWith("seed_erro:start:")) {
        line = "ERROR: " + line.substring(16);
      } else if (line.startsWith("seed_erro:continue:")) {
        line = "       " + line.substring(19);
      }
    } else if (type === "seed_info:") {
      if (line.startsWith("seed_info:start:")) {
        line = "INFO: " + line.substring(16);
      } else if (line.startsWith("seed_info:continue:")) {
        line = "      " + line.substring(19);
      }
    }

    // Case 1: fold
    if (type === "seed_fold:") {
      const parts = line.split(":");
      if (parts[1] === "start") {
        const metadata = parseMetadata(parts[2]);

        // Handle already in a fold => discard fold and render unclosed fold as individual lines
        // (ie. printed fold start w/o ending previous fold)
        if (currentFold) {
          entries = entries.concat(currentFold.lines);
        }

        currentFold = {
          type: "fold",
          start: metadata.ts,
          error: false,
          lines: [],
        };
      } else if (parts[1] === "end") {
        const metadata = parseMetadata(parts[2]);

        // Case Corner: not in fold (ie. printed fold end w/o priting fold start) => do nothing
        if (!currentFold) {
        } else {
          entries.push({ ...currentFold, end: metadata.ts });
        }
        currentFold = null;
      }
    }
    // Case 2: message in fold
    else if (currentFold) {
      currentFold.error = hasError ? hasError : currentFold.error;
      currentFold.lines.push({ type: "line", error: hasError, message: line });
    }
    // Case 3: message not in fold
    else {
      entries.push({ type: "line", error: hasError, message: line });
    }
  });

  // Handle unclosed fold (ie. build in progress)
  if (currentFold) {
    entries = entries.concat(currentFold.lines);
  }

  return entries;
}

function parseMetadata(raw) {
  // handle fold without timestamp
  if (!raw) {
    return {};
  }

  const parts = raw.split(",");
  const metadata = {};
  parts.forEach((part) => {
    const [k, v] = part.split("=");
    metadata[k] = v;
  });

  return metadata;
}

export default function CollapsableLogTextarea({
  log = null,
  cleanupLog = null,
  loading,
  onLoadClick,
}) {
  log = log === null ? null : buildEntries(log);
  cleanupLog = cleanupLog === null ? null : buildEntries(cleanupLog);

  const hasEntries = log !== null || cleanupLog !== null;

  return (
    <div className="CollapsableLogTextarea">
      {!hasEntries && (
        <LoaderButton loading={loading} onClick={onLoadClick}>
          Load Build Log
        </LoaderButton>
      )}
      {log !== null &&
        log.map((entry, i) => (
          <CollapsableLogEntry key={`${entry.type}-${i}`} entry={entry} />
        ))}
      {cleanupLog !== null &&
        cleanupLog.map((entry, i) => (
          <CollapsableLogEntry
            key={`${entry.type}-${i}`}
            secondary
            entry={entry}
          />
        ))}
      {hasEntries && <div className="left-margin"></div>}
    </div>
  );
}
